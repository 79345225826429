import { Alert } from 'lib/components/Alert';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import { Form } from 'lib/components/Form';
import { TextField } from 'lib/components/TextField';
import { ProductPublishingSettingModel } from 'lib/model/objects/productPublishingSettingModel';
import { safeAsync } from 'lib/safeWrappers';
import { capitalize } from 'lodash';
import { useState } from 'react';

type PublishingMediumFormProps = {
  productPublishingSetting: ProductPublishingSettingModel;
};

function PublishingMediumsForm({
  productPublishingSetting
}: PublishingMediumFormProps) {
  const [userError, setUserError] = useState('');

  const { editable, onChange, disabled, onSubmitWrapper, formSettings } =
    useEditableCard(productPublishingSetting.modelData);

  const handleSave = () =>
    onSubmitWrapper(async () => {
      setUserError('');
      const [saveError] = await safeAsync(() =>
        productPublishingSetting.ref.update(formSettings)
      )();
      if (saveError) {
        setUserError(saveError.message);
        return { success: false };
      }
      return { success: true };
    });

  return (
    <Form onSubmit={handleSave}>
      {userError && <Alert status="error" id="save-error" title={userError} />}
      <CardGridLayout
        header={{
          title: 'Publishing Medium Settings',
          description:
            'Configure how your publishing medium offerings will be shown to customers in the placement flow'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <TextField
            id="publishing-medium-custom-label"
            labelText="Custom Label"
            placeholder={capitalize(formSettings.publishingMedium)}
            disabled={disabled}
            value={formSettings.customLabel}
            onChange={customLabel => onChange({ ...formSettings, customLabel })}
          />
        </GridInput>
        <GridInput fullWidth>
          <TextField
            id="publishing-medium-custom-description"
            labelText="Custom Description"
            placeholder="Delivered to doorsteps and mailboxes across the country"
            disabled={disabled}
            value={formSettings.customDescription}
            onChange={customDescription =>
              onChange({ ...formSettings, customDescription })
            }
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}

export default PublishingMediumsForm;
