import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  IdentificationIcon,
  CreditCardIcon,
  CalendarIcon,
  HashtagIcon,
  WalletIcon,
  UserIcon,
  CheckCircleIcon,
  TagIcon
} from '@heroicons/react/24/outline';
import { NoticeStatusType, ELabels, InvoiceStatus } from 'lib/enums';
import {
  ESnapshotExists,
  ENotice,
  EOrganization,
  EUser,
  FirebaseTimestamp,
  exists,
  EInvoice,
  EResponseTypes
} from 'lib/types';
import { Help } from '@material-ui/icons';
import {
  getAccountNumberForNotice,
  getCustomer,
  getCustomerName,
  getCustomerOrganization,
  getCustomerOrganizationName
} from 'lib/notice/customer';
import {
  isPastDueInNewspaperTimezone,
  getDueDateAndTimeString,
  getNoticeType
} from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { TextField } from '@material-ui/core';
import api from 'api';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Badge } from 'lib/components/Badge';
import { Tooltip } from 'lib/components/Tooltip';
import { BillingService } from 'services';
import { isUnpaidInvoice } from 'lib/utils/invoices';
import { useSyncExportSettings } from 'lib/frontend/hooks/useSyncExportSettings';
import { useGetNoticeSyncEvents } from 'routes/placeScroll/hooks/useGetNoticeSyncEvents';
import { useGetNoticeRequiresUpfrontPayment } from 'routes/placeScroll/hooks/useGetNoticeRequiresUpfrontPayment';
import { getModelFromSnapshot } from 'lib/model';
import { UserNoticeModel } from 'lib/model/objects/userNoticeModel';
import { PublicationDateStatusBadge } from './PublicationDateStatusBadge';
import ConfirmationStatusBadge from './ConfirmationStatusBadge';

type BasicInforRowProps = {
  Icon: React.ReactNode;
  heading: React.ReactNode;
  caption: string;
  id?: string;
  showEdit?: boolean;
  headingLink?: string;
  filerName?: boolean;
  notice?: ESnapshotExists<ENotice>;
  invoice?: ESnapshotExists<EInvoice>;
};

function BasicInfoRow({
  Icon,
  heading,
  caption,
  id,
  showEdit,
  headingLink,
  filerName,
  notice,
  invoice
}: BasicInforRowProps) {
  const [editMode, setEditMode] = useState<boolean>();
  const [orderNumber, setOrderNumber] = useState(heading?.toString() || '');
  const [currentOrderNumber, setCurrentOrderNumber] = useState<string>();

  const updateNotice = async (
    notice: ESnapshotExists<ENotice>,
    invoice?: ESnapshotExists<EInvoice>
  ) => {
    await notice.ref.update({
      customId: orderNumber
    });

    if (exists(invoice)) {
      await api.post(`documents/${notice.id}/regenerate`, {
        docType: 'INVOICE'
      });

      // we need to update the order number in the invoice memo too
      let customMemo = invoice.data().customMemo || '';
      customMemo = customMemo.replace(
        `Order Number: ${currentOrderNumber}`,
        `Order Number: ${orderNumber}`
      );
      // TODO: Move this to the back-end so we can prevent all invoice updates on the FE
      await invoice.ref.update({
        customMemo
      });
    }
  };

  return (
    <div className="flex items-center mx-6" id={id}>
      <div className="text-column-gray-400">{Icon}</div>
      <section className="mx-5">
        <div className="flex inline-block">
          {/* Row label */}
          <div className="text-column-gray-400">{caption}</div>

          {showEdit && (
            <div
              className={`inline-block items-center px-2 text-base font-medium text-primary-500 cursor-pointer`}
              onClick={() => {
                if (
                  editMode &&
                  currentOrderNumber !== orderNumber &&
                  exists(notice)
                ) {
                  void updateNotice(notice, invoice);
                } else {
                  setCurrentOrderNumber(orderNumber);
                }
                setEditMode(!editMode);
              }}
              data-testid="edit-button"
            >
              {editMode ? 'Save' : 'Edit'}
            </div>
          )}
        </div>
        <div>
          {(!editMode || (editMode && id !== 'notice-custom-id')) && (
            <div
              className={`font-medium text-column-gray-500 inline-block ${
                headingLink
                  ? `${
                      filerName ? 'underline' : 'hover:underline'
                    } cursor-pointer hover:blue-800`
                  : ''
              }`}
              id={`${id}-sub-heading`}
              onClick={() => headingLink && window.open(headingLink)}
            >
              {heading}
            </div>
          )}
          {editMode && caption === ELabels.publisher_id.label && (
            <TextField
              id="order-number"
              className="text-base text-gray-800"
              value={orderNumber}
              onChange={e => setOrderNumber(e.target.value)}
            />
          )}
        </div>
      </section>
    </div>
  );
}

type NoticeDetailBasicInfoProps = {
  notice: ESnapshotExists<ENotice>;
  invoice?: ESnapshotExists<EInvoice>;
  invoiceOverdue: boolean;
  setInvoiceOverdue: (overdue: boolean) => void;
  newspaper: ESnapshotExists<EOrganization>;
  isPublisher: boolean;
  filer: ESnapshotExists<EUser>;
  isInvoicedOutsideColumn?: boolean;
};

export default function NoticeDetailBasicInfo({
  notice,
  invoice,
  invoiceOverdue,
  setInvoiceOverdue,
  newspaper,
  isPublisher,
  filer,
  isInvoicedOutsideColumn
}: NoticeDetailBasicInfoProps) {
  const [filerName, setFilerName] = useState('');
  const [invoiceStatusOrDueDate, setInvoiceStatusOrDueDate] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerArchived, setCustomerArchived] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [noticeAwaitingBulkInvoice, setNoticeAwaitingBulkInvoice] =
    useState(false);

  // Used to determine publication status pill color
  const exportSettings = useSyncExportSettings(newspaper || undefined);
  const noticeSyncEvents = useGetNoticeSyncEvents(notice);
  const noticeRequiresUpfrontPayment =
    useGetNoticeRequiresUpfrontPayment(notice);
  const noticeSyncedAtLeastOnce = noticeSyncEvents.length > 0;

  const ctx = getFirebaseContext();

  const enableOrderNumberEditing =
    useBooleanFlag(LaunchDarklyFlags.ENABLE_ORDER_NUMBER_EDITING) &&
    isPublisher;

  const billingStatus = BillingService.getStatusEnum(
    notice,
    invoice,
    isPublisher
  ).label;

  useEffect(() => {
    const fetchFilerName = async () => {
      const { filedBy } = notice.data();
      const customer = await getCustomer(ctx, filer, newspaper);
      setCustomerArchived(!!customer?.data().archived);
      const userName = getCustomerName(customer, filer, true);
      const filedBySnap = await filedBy?.get();

      const customerOrganization = await getCustomerOrganization(
        ctx,
        filedBySnap,
        newspaper
      );

      const customerOrganizationName = getCustomerOrganizationName(
        customerOrganization,
        customer
      );

      setCustomerId(customer?.id || '');

      // TODO: Should we be showing the pending registration message?
      // if (filedBy) {
      //   /**
      //    * Show the user's name as 'User Pending Registration' if
      //    * they have been invited to the advertising org and do not have
      //    * a name saved
      //    */
      //   const pendingInvite = (
      //     await getOpenInvitesAssociatedWithEmail(ctx, email, filedBy.id)
      //   )[0];
      //   if (exists(pendingInvite) && !userName) {
      //     setFilerName(
      //       `User Pending Registration${
      //         pendingInvite.data().organizationName
      //           ? `- ${pendingInvite.data().organizationName}`
      //           : ''
      //       }`
      //     );
      //     return;
      //   }
      // }
      if (customerOrganizationName) {
        setFilerName(`${userName} - ${customerOrganizationName}`);
        return;
      }
      return setFilerName(userName);
    };

    if (filer && exists(filer)) {
      void fetchFilerName();
    }
  }, [filer?.id]);

  useEffect(() => {
    const getInvoiceStatusOrDueDate = async () => {
      if (!exists(invoice)) {
        setInvoiceStatusOrDueDate('Awaiting Invoice Creation');
      } else if (
        invoice.data().isWithinBulkInvoice ||
        notice.data().bulkInvoice_v2
      ) {
        setInvoiceStatusOrDueDate('Monthly Invoice');
      } else if (noticeAwaitingBulkInvoice) {
        setInvoiceStatusOrDueDate('Awaiting Bulk Invoice');
      } else if (invoice.data().invoiceOutsideColumn) {
        setInvoiceStatusOrDueDate('Invoiced Outside Column');
      } else {
        const dueDate = moment(invoice.data().due_date * 1000);
        setInvoiceOverdue(
          isPastDueInNewspaperTimezone(
            dueDate,
            newspaper.data().iana_timezone,
            moment()
          ) && isUnpaidInvoice(invoice)
        );
        if (notice.data().requireUpfrontPayment) {
          setInvoiceStatusOrDueDate(
            getDueDateAndTimeString(invoice, newspaper)
          );
          return;
        }
        setInvoiceStatusOrDueDate(dueDate.format('MMM D'));
      }
    };

    if (exists(notice)) {
      void getInvoiceStatusOrDueDate();
    }
  }, [invoice?.id, noticeAwaitingBulkInvoice]);

  useEffect(() => {
    const fetchAndSetAccountNumber = async () => {
      if (!exists(notice)) return;
      const fetchedAccountNumber = await getAccountNumberForNotice(
        getFirebaseContext(),
        notice
      );
      if (fetchedAccountNumber) {
        setAccountNumber(fetchedAccountNumber.id);
      }
    };

    void fetchAndSetAccountNumber();
  }, [notice.id]);

  useEffect(() => {
    const getNoticeAwaitingBulkInvoice = async () => {
      try {
        const res: EResponseTypes['notices/awaiting-bulk-invoice'] =
          await api.get(`notices/${notice.id}/awaiting-bulk-invoice`);

        if (res.success) {
          setNoticeAwaitingBulkInvoice(res.noticeAwaitingBulkInvoice);
        }
      } catch (err) {
        setNoticeAwaitingBulkInvoice(false);
      }
    };

    void getNoticeAwaitingBulkInvoice();
  }, [notice.id]);

  const publicationMoments = notice
    .data()
    .publicationDates.map((fbDate: FirebaseTimestamp) =>
      moment(fbDate.toDate()).tz(
        newspaper.data().iana_timezone || 'America/Chicago'
      )
    );

  const isPublicationDateComplete = (publicationMoment: moment.Moment) => {
    const currentDateAfterPubDate = moment().isAfter(publicationMoment);

    // 1. If the notice is in an integration paper and did not sync, return false
    if (currentDateAfterPubDate && exportSettings && !noticeSyncedAtLeastOnce) {
      return false;
    }

    // 2. If the notice requiresUpfrontPayment but the invoice hasn't been paid, return false
    if (
      currentDateAfterPubDate &&
      noticeRequiresUpfrontPayment &&
      !(invoice?.data().status === InvoiceStatus.paid.value)
    ) {
      return false;
    }

    // 3. Otherwise, just return whether the current date is after the publication date.
    // We assume that if the above constraints were not present
    // then the publisher has run the notice if the date has passed.
    return currentDateAfterPubDate;
  };

  const model = getModelFromSnapshot(UserNoticeModel, ctx, notice);
  const showAmountPaid = !!isInvoicedOutsideColumn && !!invoice;

  const noticeStatusText = `${
    model.isCancelled && !invoice?.data()?.refund_id
      ? NoticeStatusType.cancelled.label
      : billingStatus
  }
  ${
    showAmountPaid
      ? ` ($${((invoice?.data().amount_paid || 0) / 100).toFixed(2)})`
      : ''
  }`;

  const showNoticeStatusTooltip =
    !isPublisher &&
    (isInvoicedOutsideColumn || billingStatus === 'Awaiting Invoice Creation');

  const noticeStatusTooltipText = isInvoicedOutsideColumn
    ? 'This publisher handles invoices outside of Column.'
    : billingStatus === 'Awaiting Invoice Creation'
    ? 'You will be notified once the publisher creates an invoice. No action is required in the meantime.'
    : '';

  const noticeType = getNoticeType(notice, newspaper, {
    skipDisplayType: true
  })?.label;

  return (
    <div className="bg-white py-6 shadow-column-2 rounded-md border xl:mx-0 gap-6 flex-col flex h-full">
      {isPublisher ? (
        <BasicInfoRow
          heading={filerName}
          id="notice-filer"
          caption="Customer Name"
          Icon={<UserIcon className="w-6 h-6" />}
          headingLink={
            customerId && !customerArchived
              ? `/settings/organization/?tab=customers&customerId=${customerId}`
              : undefined
          }
          filerName
        />
      ) : (
        <BasicInfoRow
          id="notice-publisher"
          heading={newspaper.data().name}
          caption="Newspaper"
          Icon={<UserIcon className="w-6 h-6" />}
          headingLink={
            newspaper.data().hyperlinkPaperName
              ? newspaper.data().website
              : undefined
          }
        />
      )}
      <BasicInfoRow
        heading={
          <ConfirmationStatusBadge
            confirmationStatus={model.confirmationStatus}
            isCancelled={model.isCancelled}
          />
        }
        id="confirmation-status"
        caption="Confirmation Status"
        Icon={<CheckCircleIcon className="w-6 h-6" />}
      />
      <BasicInfoRow
        heading={
          <div className="flex flex-wrap gap-2 mt-1">
            {publicationMoments.map((publicationMoment, i) => {
              const isComplete = isPublicationDateComplete(publicationMoment);

              return (
                <PublicationDateStatusBadge
                  publicationMoment={publicationMoment}
                  notice={notice}
                  newspaper={newspaper}
                  isComplete={isComplete}
                  key={i}
                />
              );
            })}
          </div>
        }
        id="notice-dates"
        caption={`Publication Date${publicationMoments.length > 1 ? 's' : ''}`}
        Icon={<CalendarIcon className="w-6 h-6" />}
      />
      <BasicInfoRow
        heading={
          <section className="flex">
            <span className="w-max mr-2">{noticeStatusText}</span>
            {showNoticeStatusTooltip && (
              <span>
                <Tooltip
                  classes="inline-block flex align-middle font-normal mt-1 text-gray-600 z-50 absolute"
                  helpText={noticeStatusTooltipText}
                  position="bottom"
                >
                  <Help fontSize="small" className="p-0.5 pt-0"></Help>
                </Tooltip>
              </span>
            )}
          </section>
        }
        id="notice-payment-status"
        caption="Status"
        Icon={<WalletIcon className="w-6 h-6" />}
      />
      <BasicInfoRow
        id="invoice-due"
        heading={
          <div className="flex gap-2 mt-1">
            <span>{invoiceStatusOrDueDate}</span>
            {invoiceOverdue && (
              <Badge status="critical" size="md">
                Past Due
              </Badge>
            )}
          </div>
        }
        caption={`Invoice Due${isPublisher ? ' by Advertiser' : ''}`}
        Icon={<CreditCardIcon className="w-6 h-6" />}
      />
      {notice.data().customId && (
        <BasicInfoRow
          id="notice-custom-id"
          heading={notice.data().customId}
          caption={ELabels.publisher_id.label}
          Icon={<HashtagIcon className="w-6 h-6" />}
          showEdit={enableOrderNumberEditing}
          notice={notice}
          invoice={invoice}
        />
      )}
      {accountNumber && (
        <BasicInfoRow
          id={ELabels.publisher_customer_id.key}
          heading={accountNumber}
          caption={ELabels.publisher_customer_id.label}
          Icon={<IdentificationIcon className="w-6 h-6" />}
        />
      )}
      {isPublisher && noticeType && (
        <BasicInfoRow
          id="notice-type"
          heading={noticeType}
          caption="Notice Type"
          Icon={<TagIcon className="w-6 h-6" />}
        />
      )}
    </div>
  );
}
