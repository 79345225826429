import { useState } from 'react';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { AdjudicationFilters } from 'routes/placeScroll/ConfirmPublisher/ConfirmPublisherStep';
import { searchPublisherOrganizations } from '../routes/placeScroll/helpers';

export type PublisherFilters = {
  restrictedSingleState: number | undefined;
  isUserPublisher: boolean;
  restrictedPublisherIds: string[];
  product: Product;
  adjudicationFilters?: AdjudicationFilters;
};

export function useFetchAvailablePublishers({
  restrictedSingleState,
  isUserPublisher,
  restrictedPublisherIds,
  product,
  adjudicationFilters
}: PublisherFilters) {
  const [stateFilter, setStateFilter] = useState<number | undefined>(
    restrictedSingleState
  );

  // If we're in a state restricted flow, we only show publishers in that state
  const activeStateFilter = restrictedSingleState || stateFilter;

  let stateOptions = [] as number[];

  const fetchData = async () => {
    // NOTE: `searchPublisherOrganizations` handles error logging internally and returns empty lists on a failed request
    const { states, publisherOrganizations } =
      await searchPublisherOrganizations({
        isUserPublisher,
        stateFilter: activeStateFilter,
        restrictedPublisherIds,
        product,
        adjudicationFilters
      });

    // Only set the available states on the first load
    if (!stateOptions.length) {
      stateOptions = states.sort((a, b) => a - b);
    }

    const sortedPublisherOptions = publisherOrganizations.sort((a, b) =>
      a.name.replace('The ', '').localeCompare(b.name.replace('The ', ''))
    );

    return { stateOptions, sortedPublisherOptions };
  };

  const { value, isLoading } = useAsyncEffect({
    fetchData,
    dependencies: [
      isUserPublisher,
      activeStateFilter,
      adjudicationFilters?.stateFilter?.id,
      adjudicationFilters?.countyFilter?.id,
      adjudicationFilters?.cityFilter?.id
    ]
  });

  stateOptions = value?.stateOptions ?? [];

  const publisherOptions = value?.sortedPublisherOptions ?? [];

  return {
    loading: isLoading,
    stateOptions,
    stateFilter,
    setStateFilter,
    publisherOptions
  };
}
