import { ClockIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import { EOrganization, ESnapshotExists } from 'lib/types';
import moment from 'moment';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { capitalize } from 'lodash';
import classNames from 'classnames';

function PublishingDateBadge({ date }: { date: string }) {
  return (
    <Badge startIcon={<ClockIcon className="h-5 w-5" />} size="md">
      {moment(date).format('ddd DD MMM, YYYY')}
    </Badge>
  );
}

type NewspaperOrderSummaryProps = {
  newspaper: ESnapshotExists<EOrganization>;
  publishingMedium: PublishingMedium;
  publishingDates: string[];
  filingTypeLabel: string;
};

function NewspaperOrderSummary({
  newspaper,
  publishingMedium,
  publishingDates,
  filingTypeLabel
}: NewspaperOrderSummaryProps) {
  return (
    <div className="flex flex-col md:flex-row gap-2 justify-between">
      <div className="flex gap-4 flex items-center">
        <span>{newspaper.data().name}</span>
        <Badge status="info" size="md">
          {filingTypeLabel}
        </Badge>
        <Badge size="md">{capitalize(publishingMedium)}</Badge>
      </div>
      <div
        className={classNames('grid gap-1', {
          'grid-cols-1': publishingDates.length === 1,
          'grid-cols-2': publishingDates.length > 1,
          'lg:grid-cols-3': publishingDates.length > 2
        })}
      >
        {publishingDates.map(date => (
          <PublishingDateBadge key={date} date={date} />
        ))}
      </div>
    </div>
  );
}

export default NewspaperOrderSummary;
