import { OrderImage } from 'lib/orders/images';
import classNames from 'classnames';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { Alert } from 'lib/components/Alert';
import ClipArt from './ClipArt';
import { useClipArt } from './clipArtHelpers';

type ClipArtLibraryProps = {
  organizationId: string;
  onSelect: (orderImage: OrderImage) => void;
  uploads?: string[];
};

function ClipArtLibrary({
  organizationId,
  onSelect,
  uploads
}: ClipArtLibraryProps) {
  const {
    value: images,
    isLoading,
    isError
  } = useClipArt(organizationId, uploads);

  if (isError) {
    return (
      <Alert
        id="clip-art-error"
        title="Loading failure"
        description="Failed to load clip art library. If the error persists, please contact Column support."
        status="error"
      />
    );
  }

  if (isLoading) {
    return (
      <LoadingState
        context={{
          location: window.location.pathname,
          tags: { organizationId },
          service: ColumnService.FILE_STORAGE
        }}
      />
    );
  }

  return (
    <div
      className={classNames('grid md:grid-cols-3 overflow-auto gap-1 p-1', {
        border: images?.length
      })}
      style={{ maxHeight: images?.length ? '40vh' : 0 }}
    >
      {images?.map(image => (
        <ClipArt
          key={image.imageUrl}
          src={image.imageUrl}
          onSelect={() => {
            onSelect(image);
          }}
        />
      ))}
    </div>
  );
}

export default ClipArtLibrary;
