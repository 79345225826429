import moment from 'moment';
import { XMLReplacementTagType } from '../enums';
import { headers } from '../indesign/helpers';
import { FirebaseTimestamp } from '../types';
import { EHandlebars } from './shared';
import { CustomNoticeFilingType } from '../types/filingType';

const castXMLReplacementTagType: typeof XMLReplacementTagType =
  XMLReplacementTagType;
type replaceTypeProps = {
  numRuns: number | any;
  publicationDate: Date;
};

const formatStrReplace = (
  formatString: string,
  { numRuns, publicationDate }: replaceTypeProps
) => {
  const { replacements } = castXMLReplacementTagType.dynamicHeader;
  const numRunsMatcher = new RegExp(replacements.numRuns.regexMatch, 'g');
  const dateMatcher = new RegExp(replacements.dateString.regexMatch, 'g');

  return formatString
    .replace('&', '&amp;')
    .replace(numRunsMatcher, numRuns)
    .replace(dateMatcher, (a, b) => moment(publicationDate).format(b));
};

/**
 * @param {headerFormatString} string conversion format
 * @param {publicationDates} Date[] list of dates
 * @returns A list containing formated dates Array<string>
 */
const generateV1Headers = (
  headerFormatString: string,
  publicationDates: Date[]
): Array<string> => {
  if (!headerFormatString) return [];
  const result: Array<string> = new Array<string>();

  publicationDates.forEach((date: Date) => {
    const headerFilledString = formatStrReplace(headerFormatString, {
      numRuns: publicationDates.length,
      publicationDate: date
    });

    result.push(headerFilledString);
  });

  return result;
};

export const { addXML } = headers;

export const generate = (
  format: string,
  dates: FirebaseTimestamp[],
  noticeType: CustomNoticeFilingType | null
): string[] => {
  if (!format) return [''];

  const isV1Header = format.slice(0, 3) === 'V1:';
  if (isV1Header)
    return generateV1Headers(
      format.slice(3, format.length),
      dates.map(date => date.toDate())
    ).map(addXML);

  // TODO(IT-4464): Add a type to this handlebars
  const compiled = EHandlebars.compile(format);
  return [addXML(compiled({ dates, noticeType: noticeType || {} }))];
};
