import React, { useState, useEffect } from 'react';
import Validator from 'validator';
import { EOrganization, ERequestTypes, ESnapshotExists } from 'lib/types';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import AdvertiserRegistrationFooter from 'routes/register/AdvertiserRegistrationFooter';
import api from 'api';
import { InvitedUserValues } from 'lib/types/invite';
import { RoleType } from 'lib/enums';
import TailwindModal from 'components/TailwindModal';
import { safeStringify } from 'lib/utils/stringify';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { UsersInviteResponse } from 'lib/types/api';
import InviteMembersInputRow from './InviteMembersInputRow';
import CreateInvitesResult from './CreateInvitesResult';

type AdvertisersInvitesFormProps = {
  onInviteCompleted: () => void;
  organization: ESnapshotExists<EOrganization>;
};

export default function AdvertisersInvitesForm({
  onInviteCompleted,
  organization
}: AdvertisersInvitesFormProps) {
  const [errors, setErrors] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const [showInvitationFailedModal, setShowInvitationFailedModal] =
    useState(false);
  const [invitesData, setInvitesData] = useState<UsersInviteResponse>();

  const DUPLICATE_EMAIL_ERROR = 'Do not enter duplicate emails.';
  const [values, setValues] = useState<InvitedUserValues[]>([
    { email: null, role: null }
  ]);

  const disable = values.some(val => {
    return val.email === null || val.email === '' || val.role === null;
  });

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    setErrors(values.map(_ => ''));
  }, [safeStringify(values)]);

  const confirmInvites = async () => {
    const emails = values.map(val => val.email?.toLowerCase());
    const isEmailValid = values.map(val =>
      val.email ? Validator.isEmail(val.email) : true
    );
    const errorsFound = values.map((v, i) => {
      if (!isEmailValid[i]) return 'Email is invalid';
      const duplicateEmail = emails.filter(email => email === v.email).length;
      if (duplicateEmail > 1) return DUPLICATE_EMAIL_ERROR;
      return '';
    });

    if (errorsFound.some(e => !!e)) {
      setErrors(errorsFound);
      return;
    }
    setLoading(true);
    const req: ERequestTypes['users/invite'] = {
      inviteData: values,
      organizationId: organization.id,
      isExistingUserInvite: false
    };
    const { response: inviteResponseData, error: inviteUsersError } =
      await api.safePost('users/invite', req);

    if (inviteUsersError) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        inviteUsersError,
        'Failed to invite users'
      );
      return;
    }

    if (!inviteResponseData) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        new Error('No response data returned from users/invite'),
        'Failed to invite users'
      );
      return;
    }

    setInvitesData(inviteResponseData);
    setLoading(false);

    const isInvitedSuccessfully =
      (inviteResponseData.invited.length ||
        inviteResponseData.invitesUnsnoozed) &&
      !inviteResponseData.alreadyHaveInvitesToCurrentOrg.length &&
      !inviteResponseData.alreadyMembersOfInvitedOrg.length &&
      !inviteResponseData.alreadyLinkedToIncompatibleOrganization.length &&
      !inviteResponseData.alreadyInvitedToIncompatibleOrganization.length;

    if (isInvitedSuccessfully) {
      onInviteCompleted();
    } else {
      setShowInvitationFailedModal(true);
    }
  };

  const onAddClick = () => {
    setValues([...values, { email: null, role: null }]);
  };

  const onRoleSelect = (i: number, roleValue: string) => {
    const currValues = [...values];
    currValues[i].role = RoleType.by_label(roleValue)?.value ?? null;
    setValues(currValues);
  };

  const onChange = (i: number, value: string) => {
    const currValues = [...values];
    currValues[i].email = value;
    setValues(currValues);
  };

  const onRemoveClick = (i: number) => {
    const currValues = [...values];
    currValues.splice(i, 1);
    setValues(currValues);
  };

  return (
    <div>
      <div className="mt-8 text-center">
        <p className="text-column-gray-500 font-semibold leading-8 text-2xl">
          Get your teammates onboard
        </p>
        <p className="text-column-gray-400 font-medium text-sm leading-6 mt-4">
          One last step. Invite anyone responsible for managing your
          organization's public notices and payments.
        </p>
      </div>
      <div className="mt-10 border rounded-md shadow bg-white py-4 px-8 max-h-1/4 overflow-y-scroll">
        {values.map((el, i) => (
          <InviteMembersInputRow
            key={`user-invite-${i}`}
            index={i}
            value={el}
            loading={loading}
            onValueChange={(newEmail: string) => onChange(i, newEmail)}
            onRoleSelect={roleValue => onRoleSelect(i, roleValue)}
            onRemoveClick={() => onRemoveClick(i)}
            error={errors && errors[i]}
          />
        ))}

        <div
          className="flex items-center mt-6 mb-6 text-column-primary-500"
          onClick={() => onAddClick()}
        >
          <PlusCircleIcon className="w-5" />
          <div className="ml-2 cursor-pointer not-italic font-medium text-sm flex items-center">
            Add another team member
          </div>
        </div>
      </div>
      <AdvertiserRegistrationFooter
        id={'confirm-invite'}
        backButtonText={'Skip for now'}
        nextButtonText="Send invitation"
        onBackButtonClick={onInviteCompleted}
        onNextButtonClick={confirmInvites}
        loading={loading}
        disableNextButton={disable}
      />
      {showInvitationFailedModal && invitesData && (
        <TailwindModal
          noExitOutsideModal
          header={'Oops! The invitation was not sent.'}
          close={() => setShowInvitationFailedModal(false)}
        >
          <CreateInvitesResult
            invitesData={invitesData}
            onHandleTryAgainClick={() => setShowInvitationFailedModal(false)}
            emailsLinkedToNotices={false}
          />
        </TailwindModal>
      )}
    </div>
  );
}
