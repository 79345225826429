
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Pagination`}</h1>
    <h2>{`Set up`}</h2>
    <p>{`Enabling the pagination header for a newspaper or newspaper group currently requires dev work. Ensure that the newspaper has `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` = false and `}<inlineCode parentName="p">{`organizationStatus`}</inlineCode>{` = 1 (live).`}</p>
    <ol>
      <li parentName="ol">{`Flip on the flag `}<inlineCode parentName="li">{`usesCustomPagination`}</inlineCode>{` for parent org or for the individual paper`}</li>
      <li parentName="ol">{`One time migration to generate publication issues for upcoming deadlines that have already been created.`}</li>
    </ol>
    <h2>{`Definitions`}</h2>
    <ul>
      <li parentName="ul">{`Issue: the combination of a run date and newspaper`}</li>
      <li parentName="ul">{`Issue statuses`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`Upcoming`}</inlineCode>{`: issues that have not been reviewed by Column Reps to ensure each notice has an invoice and has been confirmed`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Ready for pagination:`}</inlineCode>{` issues ready to paginated into modular blocks by Column’s pagination specialists`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Awaiting Approval`}</inlineCode>{`: issues pending approval or feedback from Lee liaisons.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Approved`}</inlineCode>{`: issues ready for Lee’s design/pagination team to layout with the rest of the paper.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Ready for print`}</inlineCode>{`: issues where layout is confirmed by Lee’s design/pagination team`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Archived`}</inlineCode>{` - issues where the publication date is in the past`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`Disabled`}</inlineCode>{` - issues we didn’t want to view anymore? This status is only visible to Column users.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Issue drawer: where you can view modular blocks (and their sizes), a CSV of the manifest, associated notices, and notes for a given issue`}</li>
    </ul>
    <h2>{`Downloading notices`}</h2>
    <p>{`Each issue will allow you to download notices in a zip file. The pagination specialist will use these files to create modular blocks in InDesign.`}</p>
    <h2>{`Uploading notices`}</h2>
    <p>{`Clicking on the upload icon will allow you to upload modular blocks, a CSV manifest, and indicate a DPI for the issue. Once you submit this will change the status of the issue to `}<inlineCode parentName="p">{`Awaiting Approval`}</inlineCode>{`.`}</p>
    <h2>{`Filters`}</h2>
    <p>{`In each tab a user can use the search to filter by newspaper name. They can also filter by ad deadline or publication date by today, tomorrow, or today and tomorrow. You can also use the organizations dropdown in the top left to filter which newspaper’s issues you’re viewing. If you’re in the All Organizations view then you will see all issues associated with that parent org.`}</p>
    <h2>{`Logic Associated with Statuses`}</h2>
    <ul>
      <li parentName="ul">{`Uploading modular blocks and a manifest to an issue will automatically move the issue to `}<inlineCode parentName="li">{`Awaiting Approval`}</inlineCode></li>
      <li parentName="ul">{`When the status of an issue moves to `}<inlineCode parentName="li">{`Approved`}</inlineCode>{` we send the modular blocks to Lee’s SFTP folder and send an automated email to create a Trello card for Lee’s design team. This card will include all the details outlined in the Jotform.`}</li>
      <li parentName="ul">{`If there are no notices for a given issue the issue will automatically move to `}<inlineCode parentName="li">{`Archived`}</inlineCode>{` and an email is sent to the Lee escalation email associated with that newspaper.`}</li>
      <li parentName="ul">{`Issues in `}<inlineCode parentName="li">{`Ready for print`}</inlineCode>{` will automatically move to `}<inlineCode parentName="li">{`Archived`}</inlineCode>{` when the publication date is in the past.`}</li>
      <li parentName="ul">{`Issues have a `}<inlineCode parentName="li">{`Disabled`}</inlineCode>{` status if there is no deadline associated`}</li>
      <li parentName="ul">{`Issues have an `}<inlineCode parentName="li">{`Upcoming`}</inlineCode>{` status in the UI (`}<inlineCode parentName="li">{`pending`}</inlineCode>{` in the database) if there is a deadline`}</li>
      <li parentName="ul">{`We only show issues within the next 30 days for each newspaper`}</li>
    </ul>
    <h2>{`Request Changes`}</h2>
    <p>{`If changes need to be made on an issue, click on the notes tab in the issue and indicate changes via text or upload a file and submit. Navigate to the table and update the status from “Awaiting Approval” to “Changes Requested.” This will move the issue to “Ready for Pagination” and will be prioritized by Column’s pagination specialists. Once updates have been made the issue will move back to “Awaiting Approval.”`}</p>
    <h2>{`Pagination Builder`}</h2>
    <p>{`TBD because I don’t think this is used at the moment.`}</p>
    <h2>{`Users + Process`}</h2>
    <ol>
      <li parentName="ol">{`Column Reps: responsible for ensuring all notices for a given issue are confirmed and have invoices and updating issues to `}<inlineCode parentName="li">{`Ready for pagination`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Column pagination specialists: responsible for laying out notices to fit modular sizes and updating issues to `}<inlineCode parentName="li">{`Awaiting Approval`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Lee liaisons: responsible for reviewing completed blocks and sharing feedback or approving as is prior to layout.`}</li>
      <li parentName="ol">{`Lee design/pagination team: responsible for confirming approved blocks are `}<inlineCode parentName="li">{`Ready for print`}</inlineCode>{`.`}</li>
    </ol>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;