import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Tooltip, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Help } from '@material-ui/icons';
import { ESnapshotExists, EOrganization, ENotice } from 'lib/types';
import {
  getDeadlineTimeForPaper,
  isNoticeAfterPublicationDeadline
} from 'lib/utils/deadlines';
import {
  firstNoticePublicationDate,
  getNoticeRequiresUpfrontPayment,
  getNoticeIsInvoicedOutsideColumn,
  getDueDate
} from 'lib/helpers';
import { CalendarIcon } from 'icons';
import { getFirebaseContext } from 'utils/firebase';
import classNames from 'classnames';
import { useInheritedProperty } from 'lib/frontend/hooks/useInheritedProperty';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';

interface ToggleSwitchProps {
  id: string;
  value: boolean;
  disabled?: boolean;
  onChange: (val: boolean) => unknown;
}

function ToggleSwitch({ id, value, disabled, onChange }: ToggleSwitchProps) {
  return (
    <button
      type="button"
      data-testid={id}
      id={id}
      className={classNames(
        'flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-4 w-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        value ? 'bg-blue-650 bg-opacity-75' : 'bg-gray-200',
        {
          'cursor-pointer': !disabled,
          'opacity-25': disabled
        }
      )}
      disabled={disabled}
      onClick={() => {
        if (disabled) {
          return;
        }
        onChange(!value);
      }}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-400 ${
          value && 'bg-blue-650 bg-opacity-75'
        }`}
      ></span>
      <span
        aria-hidden="true"
        className={`bg-blue-650 translate-x-0 absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200 ${
          value && 'translate-x-5'
        }`}
      ></span>
    </button>
  );
}

type InvoiceFormDueDateProps = {
  newspaper: ESnapshotExists<EOrganization>;
  dueDate?: number;
  setDueDate: (dueDate: number) => void;
  noticeSnap: ESnapshotExists<ENotice>;
  requireUpfrontPayment?: boolean;
  setRequireUpfrontPayment: (requireUpfrontPayment: boolean) => void;
  ianaTimezone?: string;
  invoiceOutsideColumn: boolean;
  setInvoiceOutsideColumn: (invoiceOutsideColumn: boolean) => void;
  isWithinBulkPayments: boolean;
};

function InvoiceFormDueDate({
  newspaper,
  dueDate,
  setDueDate,
  noticeSnap,
  requireUpfrontPayment,
  setRequireUpfrontPayment,
  ianaTimezone,
  invoiceOutsideColumn,
  setInvoiceOutsideColumn,
  isWithinBulkPayments
}: InvoiceFormDueDateProps) {
  const [disableRequireUpfrontPayment, setDisableRequireUpfrontPayment] =
    useState(false);
  const [deadline, setDeadline] = useState<Date>();

  useEffect(() => {
    const {
      deadlines,
      deadlineOverrides = {},
      iana_timezone
    } = newspaper.data();
    if (!deadlines) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        new Error('No deadlines found for newspaper'),
        'Missing deadlines in InvoiceFormDueDate',
        {
          newspaperId: newspaper.id
        }
      );
      return;
    }
    const closestPublicationDate: Date = firstNoticePublicationDate(noticeSnap);
    const deadline: moment.Moment = getDeadlineTimeForPaper(
      closestPublicationDate,
      deadlines,
      deadlineOverrides,
      iana_timezone,
      noticeSnap.data(),
      newspaper
    );
    setDeadline(deadline.toDate());
  }, [noticeSnap, newspaper]);

  useEffect(() => {
    const checkDeadline = async () => {
      if (deadline) {
        if (isWithinBulkPayments) {
          // Upfront payment should always be false
          // if a notice is within a bulk invoice
          setDisableRequireUpfrontPayment(true);
          setRequireUpfrontPayment(false);
        } else {
          const isAfterDeadline = await isNoticeAfterPublicationDeadline(
            noticeSnap
          );
          const { iana_timezone } = newspaper.data();
          if (!isAfterDeadline) {
            setDisableRequireUpfrontPayment(false);
          } else if (!isWithinBulkPayments) {
            setDisableRequireUpfrontPayment(true);
            setRequireUpfrontPayment(false);
            setDueDate(
              moment().tz(iana_timezone).add(1, 'M').toDate().getTime() / 1000 -
                2
            );
          }
        }
      }
    };

    void checkDeadline();
  }, [deadline, isWithinBulkPayments]);

  // When the organization has the disableControlUpfrontPayment setting,
  // require upfront payment and invoiceOutsideColumn are mutually exclusive.
  // We use IOC as the "controlling" variable and derive RUP accordingly.
  const disableControlUpfrontPayment = useInheritedProperty(
    newspaper.ref,
    'disableControlUpfrontPayment'
  );

  // In the case where RUP is not an option (after deadline) and the newspaper has
  // the setting which makes IOC and RUP mutally exclusive, they cannot control either
  const isAfterDeadlineAndIOCRUPMutuallyExclusive =
    !requireUpfrontPayment &&
    disableRequireUpfrontPayment &&
    disableControlUpfrontPayment;

  const blockNonIOCInvoices = useInheritedProperty(
    newspaper.ref,
    'blockNonIOCInvoices'
  );

  const forceInvoiceOutsideColumn =
    blockNonIOCInvoices || isAfterDeadlineAndIOCRUPMutuallyExclusive;

  useEffect(() => {
    if (forceInvoiceOutsideColumn) {
      setInvoiceOutsideColumn(true);
    }
  }, [forceInvoiceOutsideColumn]);

  useEffect(() => {
    if (
      disableControlUpfrontPayment &&
      requireUpfrontPayment === invoiceOutsideColumn
    ) {
      setInvoiceOutsideColumn(!requireUpfrontPayment);
    }
  }, [
    disableControlUpfrontPayment,
    requireUpfrontPayment,
    invoiceOutsideColumn
  ]);

  const handleToggleInvoiceOutsideColumn = (val: boolean) => {
    setInvoiceOutsideColumn(val);
    if (disableControlUpfrontPayment && val === requireUpfrontPayment) {
      setRequireUpfrontPayment(!val);
    }
  };

  useEffect(() => {
    void (async () => {
      const ctx = getFirebaseContext();
      const requireUpfrontPayment = await getNoticeRequiresUpfrontPayment(
        ctx,
        noticeSnap
      );
      const shouldInvoiceOutsideColumn = await getNoticeIsInvoicedOutsideColumn(
        ctx,
        noticeSnap
      );

      setRequireUpfrontPayment(!isWithinBulkPayments && requireUpfrontPayment);
      if (shouldInvoiceOutsideColumn) {
        setInvoiceOutsideColumn(true);
      }
    })();
  }, []);

  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="flex justify-between items-center px-4 pt-5 pb-3 border-b border-gray-300">
              <div className="flex items-center text-sm leading-5 font-bold text-gray-800">
                <div className="mr-2">Require upfront payment?</div>
                <Tooltip
                  title={`When enabled, upfront payment will be required such that invoice will have a due date one day prior the deadline`}
                >
                  <Help
                    color="disabled"
                    style={{ width: '1rem', fill: '#2A394A' }}
                  />
                </Tooltip>
              </div>
              <div className="flex mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0">
                <Tooltip
                  title={
                    disableRequireUpfrontPayment
                      ? isWithinBulkPayments
                        ? `This publisher is set to pay invoices in bulk at the end of the month.`
                        : `The ad deadline for this notice has already passed, and the advertiser will not have enough time to fulfill the invoice before publication.`
                      : ``
                  }
                >
                  <ToggleSwitch
                    id="require-upfront-payment"
                    value={!!requireUpfrontPayment}
                    onChange={val => setRequireUpfrontPayment(val)}
                    disabled={
                      disableControlUpfrontPayment ||
                      disableRequireUpfrontPayment
                    }
                  />
                </Tooltip>
              </div>
            </div>
            {newspaper.data().allowInvoiceOutsideColumn && (
              <div className="flex justify-between items-center px-4 pt-5 pb-3 border-b border-gray-300">
                <div className="flex items-center text-sm leading-5 font-bold text-gray-800">
                  <div className="mr-2">Invoice outside of Column?</div>
                  <Tooltip
                    title={`When enabled, invoices will be sent outside of the Column System`}
                  >
                    <Help
                      color="disabled"
                      style={{ width: '1rem', fill: '#2A394A' }}
                    />
                  </Tooltip>
                </div>
                <div className="flex mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0">
                  <Tooltip
                    title={
                      invoiceOutsideColumn
                        ? `This customer will receive their invoice outside of Column`
                        : 'This customer will receive their invoice inside of Column'
                    }
                  >
                    <ToggleSwitch
                      id="invoice-outside-column"
                      value={!!invoiceOutsideColumn}
                      onChange={handleToggleInvoiceOutsideColumn}
                      disabled={forceInvoiceOutsideColumn}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
            <div className="flex justify-between items-center px-4 pt-3 pb-5">
              <div className="text-sm leading-5 font-bold text-gray-800">
                Invoice Due Date
              </div>
              <div className="w-32 mt-1 text-right text-sm leading-5 text-gray-900 sm:mt-0">
                <Tooltip
                  title={
                    isWithinBulkPayments
                      ? `This publisher is set to pay invoices in bulk at the end of the month.`
                      : ``
                  }
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      data-testid="datePicker"
                      label=""
                      value={
                        dueDate &&
                        ianaTimezone &&
                        moment(dueDate * 1000)
                          .tz(ianaTimezone)
                          .toDate()
                          .toLocaleString('en-US')
                      }
                      placeholder="MMM dd, YYYY"
                      format="MMM dd, yyyy"
                      className={'text-xs'}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarIcon className="text-xs p-0.25" />
                          </InputAdornment>
                        ),
                        className: 'text-xs'
                      }}
                      shouldDisableDate={date => {
                        if (!date) {
                          return true;
                        }

                        const now = new Date();
                        if (date.getTime() < now.getTime()) return true;
                        if (
                          requireUpfrontPayment &&
                          deadline &&
                          date.getTime() > deadline.getTime()
                        ) {
                          return true;
                        }
                        return false;
                      }}
                      autoOk
                      onChange={async date => {
                        if (ianaTimezone && date) {
                          if (requireUpfrontPayment) {
                            const dueDate = await getDueDate(
                              getFirebaseContext(),
                              noticeSnap,
                              requireUpfrontPayment,
                              moment(date.getTime()).tz(ianaTimezone).toDate()
                            );
                            setDueDate(dueDate);
                          } else {
                            setDueDate(
                              moment(date.getTime())
                                .tz(ianaTimezone)
                                .toDate()
                                .getTime() /
                                1000 -
                                2
                            );
                          }
                        }
                      }}
                      minDateMessage="Selected date after publication deadline"
                      disabled={isWithinBulkPayments}
                    />
                  </MuiPickersUtilsProvider>
                </Tooltip>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default InvoiceFormDueDate;
