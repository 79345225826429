import React, { useEffect, useState } from 'react';
import {
  ESnapshotExists,
  EOrganization,
  CustomerOrganization,
  ESnapshot
} from 'lib/types';
import { useAppDispatch } from 'redux/hooks';
import { BuildingOffice2Icon } from '@heroicons/react/24/solid';
import { getFirebaseContext } from 'utils/firebase';
import { fuzzyStringContains } from 'lib/utils/strings';
import { getNewspaperRefForCustomer } from 'lib/notice/customer';
import ToastActions from 'redux/toast';

import { buildingStyles } from 'lib/components/UserIDCard';
import { TableLayout } from 'lib/components/TableLayout';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import {
  CustomerOrganizationTableData,
  extractCustomerOrganizationDataFromOrganization
} from './utils';

type CustomerOrganizationTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  setCustomerOrg: (customerOrg?: CustomerOrganizationTableData) => void;
  setShowCustomerOrgDrawer: (show: boolean) => void;
  setShowEditCustomerOrgModal: (show: boolean) => void;
};

type CustomerOrgRowProps = {
  customerOrg: CustomerOrganizationTableData;
};

/**
 * Customer organization table row on the customers settings page
 */
function CustomerOrgRow({ customerOrg }: CustomerOrgRowProps) {
  const name =
    customerOrg?.customerOrganization?.data()?.name ||
    customerOrg?.clientOrganization.data()?.name ||
    '';

  return (
    <>
      <td>
        <div className="flex items-center">
          <div
            className={`${
              buildingStyles[
                customerOrg.customerOrganization.id.charCodeAt(0) %
                  buildingStyles.length
              ]
            } w-10 h-10 rounded-full flex items-center justify-center`}
          >
            <BuildingOffice2Icon className="w-5 h-5" />
          </div>
          <div className="pl-3 text-sm leading-6 font-medium text-column-gray-800">
            {name}
          </div>
        </div>
      </td>
      <td>
        {`${
          customerOrg?.customerOrganization?.data()?.internalID
            ? customerOrg?.customerOrganization?.data()?.internalID
            : '--'
        }`}
      </td>
    </>
  );
}

type shouldShowTableItemProps = {
  customerOrg: CustomerOrganizationTableData;
  filter: string;
};

/**
 * Filter function for the customer organizations table
 */
function customerOrgFilter({ customerOrg, filter }: shouldShowTableItemProps) {
  const matchString = filter.toLowerCase();

  if (
    fuzzyStringContains(
      customerOrg.customerOrganization?.data()?.name || '',
      matchString
    )
  ) {
    return true;
  }

  if (
    fuzzyStringContains(
      customerOrg.clientOrganization?.data()?.name || '',
      matchString
    )
  ) {
    return true;
  }

  if (
    fuzzyStringContains(
      customerOrg.customerOrganization?.data()?.internalID?.toLowerCase() || '',
      matchString
    )
  ) {
    return true;
  }
  return false;
}

/**
 * Wrapper component for the Customer Organizations table page.
 */
export default function CustomerOrganizationTableTab({
  activeOrganization,
  setCustomerOrg,
  setShowCustomerOrgDrawer,
  setShowEditCustomerOrgModal
}: CustomerOrganizationTableProps) {
  const dispatch = useAppDispatch();
  const [customerOrgs, setCustomerOrgs] =
    useState<CustomerOrganizationTableData[]>();
  const [loadingData, setLoadingData] = useState(true);
  const newspaperRef = getNewspaperRefForCustomer(activeOrganization);

  useEffect(() => {
    const unsubscribe = getFirebaseContext()
      .customerOrganizationsRef()
      .where('organization', '==', newspaperRef)
      .onSnapshot(async result => {
        const shallowCustomerOrgRows =
          result.docs as unknown as ESnapshot<CustomerOrganization>[];
        const customerOrgsWithData = await Promise.all(
          shallowCustomerOrgRows.map(
            extractCustomerOrganizationDataFromOrganization
          )
        );
        const validCustomerOrgsWithData = customerOrgsWithData.filter(Boolean);
        setCustomerOrgs(
          validCustomerOrgsWithData as CustomerOrganizationTableData[]
        );
        setLoadingData(false);
      });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <TableLayout<CustomerOrganizationTableData>
        header={{
          subtitle:
            'Governments, legal services, and agencies using your publication.',
          title: 'Organizations'
        }}
        columns={['Name', 'ID']}
        renderRow={customerOrg => CustomerOrgRow({ customerOrg })}
        data={customerOrgs || []}
        loading={loadingData}
        id="customer-organization-settings"
        clickable={{
          onClick: customerOrg => {
            setCustomerOrg(customerOrg);
            setShowCustomerOrgDrawer(true);
          }
        }}
        filterable={{
          shouldShowTableItem: (customerOrg, filter) =>
            customerOrgFilter({ customerOrg, filter })
        }}
        editable={{
          editTooltip: 'Edit this organization.',
          onEdit: customerOrg => {
            setCustomerOrg(customerOrg);
            setShowEditCustomerOrgModal(true);
          }
        }}
        archivable={{
          renderWarning: () => ({
            header: `Delete Organization?`,
            body: 'Are you sure you want to delete this organization? Once an organization is deleted, it will no longer be accessible. Please click “Delete” to confirm this action or “Cancel” to go back.',
            buttonText: 'Delete'
          }),
          onArchive: async customerOrg => {
            try {
              const customerOrgRef = getFirebaseContext()
                .customerOrganizationsRef()
                .doc(customerOrg.customerOrganization.id);
              await customerOrgRef.delete();
              dispatch(
                ToastActions.toastSuccess({
                  headerText: 'Organization Deleted',
                  bodyText: `${
                    customerOrg.clientOrganization.data()?.name
                  } has been successfully deleted from your organization list.`
                })
              );
            } catch (e) {
              logAndCaptureException(
                ColumnService.AUTH_AND_USER_MANAGEMENT,
                e,
                'Error deleting customer org',
                {
                  customerId: customerOrg.customerOrganization.id
                }
              );
            }
          },
          isArchiveDisabled: false,
          enabledArchiveTooltip: 'Delete this organization.'
        }}
      />
    </>
  );
}
