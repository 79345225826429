import api from 'api';

import { ERequestTypes, EResponseTypes } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { ColumnService } from 'lib/services/directory';

export const handleOrderPaginationDownload = async (
  data: ERequestTypes['pagination/pagination-download']
): Promise<ResponseOrError<string | undefined>> => {
  try {
    const res: EResponseTypes['pagination/pagination-download'] =
      await api.post('pagination/pagination-download', data);
    if (res.success) {
      return wrapSuccess(res.url);
    }
    return wrapError(new Error('Error downloading obit pagination'));
  } catch (err) {
    logAndCaptureException(
      ColumnService.PAGINATION,
      err,
      'Error downloading obit pagination'
    );
    return wrapError(err as Error);
  }
};
