import React from 'react';
import { Ad, CategoryChoiceOption } from 'lib/types/ad';
import { EOrganization } from 'lib/types/organization';
import { ESnapshotExists } from 'lib/types/firebase';
import QuestionForm from 'routes/madlib/components/QuestionForm';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { Alert } from 'lib/components/Alert';
import { getAccessibilityLinks } from 'lib/components/InputAccessories';
import { uniqBy } from 'lodash';
import { isDefined } from 'lib/helpers';
import { useMadlibs } from '../../hooks/useMadlibs';
import { PublisherProductFilingTypeModelMap } from '../../contexts/NewspapersContextProvider/filingTypeMaps';
import { NewspaperOrdersFormData } from '../../PlacementFlowStepSelector';

const MISSING_PUBLISHER_ERROR = new Error(
  'Publisher not found. Publishers should always be available for placement in downstream steps.'
);

/**
 * Warning: complex function ahead!
 * Returns the madlib path for the filing type that should be used for the ad.
 * If there is a conflict, it returns an error message. We throw in the following cases:
 * 1. One paper has madlibs and the other doesn't.
 * 2. Multiple papers have madlibs, but they are different.
 * This function differs from our responseOrError pattern in that it returns a madlib path
 * when there is an error, as we want to preview for the user the madlib that is required.
 *
 * { madlibPath: madllib1 }, { madlibPath: madlib2 } => { madlibPath: madlib1, madlibError: '...have different formatting requirements...' }
 * { madlibPath: madllib1 }, { madlibPath: null } => { madlibPath: madlib1, madlibError: '...requires special formatting...' }
 * { madlibPath: null }, { madlibPath: null } => { madlibPath: null, madlibError: null }
 */
export const getMadlibConfigForPublisherCard = (
  filingTypeByPublisherAndPublishingMedium: PublisherProductFilingTypeModelMap,
  publishersAvailableForPlacement: ESnapshotExists<EOrganization>[],
  inputData: Partial<Ad>,
  newspaperOrdersFormData: NewspaperOrdersFormData
):
  | { madlibError: null; madlibPath: null; madlibFilingType: null }
  | {
      madlibError: string | null;
      madlibPath: string;
      madlibFilingType: FilingTypeModel;
    } => {
  // build a cache of madlib paths mapping to the filing type and the publishers that have that madlib
  // sample bad case:
  // {
  //   madlibPath1: { filingType: filingTypeModel1, publishers: [publisher1, publisher2] },
  //   madlibPath2: { filingType: filingTypeModel2, publishers: [publisher3] },
  // }
  // sample good case:
  // {
  //   madlibPath1: { filingType: filingTypeModel1, publishers: [publisher1, publisher2] },
  // }
  const filingTypesInOrderWithMadlibs: Record<
    string,
    {
      filingType: FilingTypeModel;
      publishers: ESnapshotExists<EOrganization>[];
    }
  > = {};
  for (const newspaperOrder of newspaperOrdersFormData) {
    if (!newspaperOrder.newspaper) continue;
    if (!newspaperOrder.publishingMedium) continue;
    const publisherDataForFilingType =
      filingTypeByPublisherAndPublishingMedium[newspaperOrder.newspaper.id]?.[
        inputData.filingTypeName as CategoryChoiceOption
      ]?.[newspaperOrder.publishingMedium];

    if (!publisherDataForFilingType?.filingType.modelData.madlib) continue;
    const relevantPublisher = publishersAvailableForPlacement.find(
      publisher => publisher.id === newspaperOrder.newspaper?.id
    );
    if (!relevantPublisher) {
      throw MISSING_PUBLISHER_ERROR;
    }

    let orderMadlibData =
      filingTypesInOrderWithMadlibs[
        publisherDataForFilingType.filingType.modelData.madlib
      ];
    if (!orderMadlibData) {
      orderMadlibData = {
        filingType: publisherDataForFilingType.filingType,
        publishers: [relevantPublisher]
      };
    } else {
      orderMadlibData.publishers.push(relevantPublisher);
    }
    filingTypesInOrderWithMadlibs[
      publisherDataForFilingType.filingType.modelData.madlib
    ] = orderMadlibData;
  }

  // if there are no madlibs, we don't need to show the madlib editor
  if (Object.keys(filingTypesInOrderWithMadlibs).length === 0) {
    return { madlibError: null, madlibPath: null, madlibFilingType: null };
  }

  const firstMadlib = Object.keys(filingTypesInOrderWithMadlibs)[0];

  // if there are multiple madlibs, that's a problem
  if (Object.keys(filingTypesInOrderWithMadlibs).length > 1) {
    const publisher1 = filingTypesInOrderWithMadlibs[firstMadlib].publishers[0];
    const publisher2 =
      filingTypesInOrderWithMadlibs[
        Object.keys(filingTypesInOrderWithMadlibs)[1]
      ].publishers[0];

    return {
      madlibError: `${publisher1.data().name} and ${
        publisher2.data().name
      } have different requirements for this filing type. Please remove one of them from your order.`,
      madlibPath: firstMadlib,
      madlibFilingType: filingTypesInOrderWithMadlibs[firstMadlib].filingType
    };
  }

  const publishersWithMadlib = filingTypesInOrderWithMadlibs[firstMadlib];

  // all publishers with the first madlib
  const uniquePublishersWithMadlib = uniqBy(
    publishersWithMadlib.publishers,
    'id'
  ).filter(isDefined);

  // all publishers in the order, regardless of whether or not they have a madlib
  const publishersInOrder = uniqBy(
    newspaperOrdersFormData.map(o => o.newspaper),
    'id'
  ).filter(isDefined);

  const publisherRefWithoutMadlib = publishersInOrder.find(
    publisher => !uniquePublishersWithMadlib.find(p => p.id === publisher.id)
  );

  // if there are some publishers that don't have a madlib, that's a problem
  if (publisherRefWithoutMadlib) {
    const publisherWithoutMadlib = publishersAvailableForPlacement.find(
      publisher => publisher.ref.id === publisherRefWithoutMadlib.id
    );
    if (!publisherWithoutMadlib) {
      throw MISSING_PUBLISHER_ERROR;
    }
    return {
      madlibError: `${
        publishersWithMadlib.publishers[0].data().name
      } requires special formatting, but ${
        publisherWithoutMadlib.data().name
      } does not. Please remove one of them from your order to continue.`,
      madlibPath: firstMadlib,
      madlibFilingType: publishersWithMadlib.filingType
    };
  }

  // if we made it here, we have a single madlib for all the publishers
  return {
    madlibError: null,
    madlibPath: firstMadlib,
    madlibFilingType: publishersWithMadlib.filingType
  };
};

type PublisherMadlibQuestionsProps = {
  madlibFilingType: FilingTypeModel;
  madlibError: string | null;
  inputData: Partial<Ad>;
  onUpdateAd: (update: Partial<Ad>) => void;
};

export default function PublisherMadlibQuestions({
  madlibFilingType,
  madlibError,
  inputData,
  onUpdateAd
}: PublisherMadlibQuestionsProps) {
  const { madlibData, madlibProps, setMadlibData, madlibPropsError } =
    useMadlibs({
      filingType: madlibFilingType.ref,
      adData: inputData,
      onChange: () => null
    });

  const { madlibConfigPath, madlibTemplate, questions } = madlibProps ?? {};
  const showMadlibEditor =
    !!madlibConfigPath && !!madlibTemplate && !!questions;

  if (madlibPropsError) {
    return (
      <Alert
        id="madlib-error"
        description="Failed to fetch template data for publishing category."
      />
    );
  }

  return (
    <div className="col-span-12 gap-2 flex flex-col">
      {madlibError && (
        <>
          <Alert id="madlib-error" description={madlibError} status="error" />
          <input
            type="hidden"
            name="madlib"
            {...getAccessibilityLinks({
              id: 'madlib',
              errorMessage: madlibError
            })}
          />
        </>
      )}
      {showMadlibEditor && (
        <>
          <h2 className="font-semibold">Additional Questions</h2>
          <p className="text-sm">
            Publishing {inputData.filingTypeName} requires additional questions
            to be answered.
          </p>
          <QuestionForm
            questions={questions}
            questionTemplateData={madlibData.questionTemplateData}
            onChange={e => {
              setMadlibData({ ...madlibData, questionTemplateData: e.values });
              onUpdateAd({
                ...inputData,
                madlibData: { ...madlibData, questionTemplateData: e.values }
              });
            }}
          />
        </>
      )}
    </div>
  );
}
