import React, { useState, useEffect } from 'react';
import Firebase, { FirebaseAuth } from 'EnoticeFirebase';
import TailwindModal from 'components/TailwindModal';
import { FirebaseUser, ESnapshotExists, EUser, EOrganization } from 'lib/types';
import { PasswordField } from 'components/PasswordField';
import {
  dwollaInvalidPasswordMessage,
  dwollaPasswordValidation,
  passwordValidation
} from 'lib/passwordValidators';

import ToastActions from 'redux/toast';
import { ColumnButton } from 'lib/components/ColumnButton';
import { userIsAdminOrHigher, userIsBilling } from 'utils/permissions';
import { useAppDispatch } from 'redux/hooks';

type ChangePasswordProps = {
  userAuth: FirebaseUser;
  user: ESnapshotExists<EUser>;
  closeChangePasswordModal: () => void;
};
export default function ChangePassword({
  userAuth,
  user,
  closeChangePasswordModal
}: ChangePasswordProps) {
  const dispatch = useAppDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorAuth, setErrorAuth] = useState('');
  const [errorMatch, setErrorMatch] = useState(false);
  const [errorNewPassword, setErrorNewPassword] = useState('');
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [userActiveOrg, setUserActiveOrg] =
    useState<ESnapshotExists<EOrganization>>();

  const getDwollaUser = async () => {
    const userOrg = (await user
      ?.data()
      ?.activeOrganization?.get()) as ESnapshotExists<EOrganization>;
    setUserActiveOrg(userOrg);
  };

  useEffect(() => {
    void getDwollaUser();
  }, []);

  // TODO(IT-4361): Rather than checking the user's role here we should instead
  // introduce a permission that reflects this and grant that permission to the
  // right roles (in this case, Billing+)
  const isDwollaUser =
    (userIsAdminOrHigher(user) || userIsBilling(user)) &&
    (user?.data()?.dwollaPasswordSecurityEnabled ||
      userActiveOrg?.data()?.dwollaPaymentEnabled);

  const matchPassword = () => {
    if (newPassword && confirmNewPassword) {
      if (isDwollaUser && !dwollaPasswordValidation.test(newPassword)) {
        setDisabled(true);
        return;
      }
      if (!passwordValidation.test(newPassword)) {
        setDisabled(true);
        return;
      }
      if (
        newPassword.length === confirmNewPassword.length &&
        newPassword === confirmNewPassword
      ) {
        setErrorMatch(false);
        if (newPassword.length > 8) {
          setDisabled(false);
          return true;
        }
      } else {
        setErrorMatch(true);
        setDisabled(true);
        return false;
      }
    }
  };
  const changePassword = () => {
    if (matchPassword()) {
      const { currentUser } = Firebase.auth();
      if (!currentUser || !currentUser.email) {
        console.error(`Current user does not exist ${currentUser?.uid}`);
        return;
      }

      setLoading(true);
      setDisabled(true);

      const credentials = FirebaseAuth.EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      );

      userAuth
        .reauthenticateWithCredential(credentials)
        .then(() => {
          currentUser
            .updatePassword(newPassword)
            .then(() => {
              setPasswordUpdated(true);
              setLoading(false);
            })
            .catch((error: Error) => {
              setErrorAuth(error.message);
              setLoading(false);
              setDisabled(false);
            });
        })
        .catch((error: Error) => {
          setErrorAuth(error.message);
          setLoading(false);
          setDisabled(false);
        });
    }
  };

  useEffect(() => {
    matchPassword();
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    if (passwordUpdated) {
      closeChangePasswordModal();
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success!',
          bodyText: 'Password changed successfully'
        })
      );
    }
  }, [passwordUpdated]);

  useEffect(() => {
    if (newPassword) {
      if (isDwollaUser) {
        if (!dwollaPasswordValidation.test(newPassword)) {
          setErrorNewPassword(dwollaInvalidPasswordMessage);
          setDisabled(true);
          return;
        }
      } else {
        if (newPassword.length < 9) {
          setErrorNewPassword('Password must be at least 9 characters');
          return;
        }
        if (!passwordValidation.test(newPassword)) {
          setErrorNewPassword(
            'Password must contain a number or a special character'
          );
          return;
        }
      }
      setErrorNewPassword('');
    }
  }, [newPassword]);

  return (
    <TailwindModal
      header="Change your password"
      close={() => closeChangePasswordModal()}
      widthPct={30}
    >
      <div className="space-y-4">
        <PasswordField
          id="currentPassword"
          required
          value={currentPassword}
          placeHolderText="Current password *"
          onValueChange={(value: string) => setCurrentPassword(value)}
          data-testid="password"
        />
        {errorAuth && (
          <div className="text-red-600 text-sm ml-1">{errorAuth}</div>
        )}
        <PasswordField
          id="password"
          required
          value={newPassword}
          placeHolderText="New password *"
          onValueChange={(value: string) => setNewPassword(value)}
          data-testid="newPassword"
        />
        {errorNewPassword && (
          <div className="text-red-600 text-sm ml-1">{errorNewPassword}</div>
        )}
        <PasswordField
          id="confirmNewPassword"
          required
          value={confirmNewPassword}
          placeHolderText="Confirm new password *"
          onValueChange={(value: string) => setConfirmNewPassword(value)}
          data-testid="confirm-new-password"
        />
        {errorMatch && (
          <div className="text-red-600 text-sm ml-1">
            Passwords do not match
          </div>
        )}

        <ColumnButton
          id="change-password"
          type="button"
          disabled={isDisabled || loading}
          onClick={changePassword}
          loading={loading}
          buttonText={'Set New Password'}
          primary
        />
      </div>
    </TailwindModal>
  );
}
