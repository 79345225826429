import { ESnapshot, EUser, exists } from 'lib/types';
import {
  filerOrgHasCustomerOrgWithBulkPaymentV2Enabled,
  userHasCustomerWithBulkPaymentV2Enabled
} from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import { getOrThrow } from 'lib/utils/refs';
import { isPublisher } from 'lib/utils/users';

/**
 * Determines whether the user is on bulk invoice v1 service or v2 or neither
 * @param {ESnapshotExists<EUser>} user current user snapshot
 */
export const getBulkInvoiceServicesStatus = async (
  user: ESnapshot<EUser> | undefined | null
): Promise<boolean> => {
  if (!exists(user)) {
    return false;
  }

  let currentUser = user;
  const shadowUserId = user.data().shadowUser;
  if (shadowUserId) {
    currentUser = await getOrThrow(
      getFirebaseContext().usersRef().doc(shadowUserId)
    );
  }

  const activeOrganization = currentUser.data().activeOrganization
    ? await getOrThrow(currentUser.data().activeOrganization)
    : null;

  if (
    activeOrganization?.data().bulkPaymentEnabled_v2 ||
    currentUser.data().bulkPaymentEnabled_v2
  ) {
    return true;
  }

  const parentOrganization = await activeOrganization?.data().parent?.get();
  if (
    isPublisher(currentUser) &&
    activeOrganization?.data().bulkPaymentEnabled_v2 === undefined &&
    parentOrganization?.data()?.bulkPaymentEnabled_v2
  ) {
    return true;
  }

  const userHasCustomerObjectWithBulkPaymentV2Enabled =
    await userHasCustomerWithBulkPaymentV2Enabled(
      getFirebaseContext(),
      currentUser
    );
  const userHasCustomerOrganizationWithBulkPaymentV2Enabled =
    !!activeOrganization &&
    (await filerOrgHasCustomerOrgWithBulkPaymentV2Enabled(
      getFirebaseContext(),
      activeOrganization
    ));

  if (
    userHasCustomerObjectWithBulkPaymentV2Enabled ||
    userHasCustomerOrganizationWithBulkPaymentV2Enabled
  ) {
    return true;
  }

  return false;
};
