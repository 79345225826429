/**
 * Note: this file is complicated!
 * The alternative to keeping everything in caches in the publication context
 * is to fetch everything dynamically when needed in the select publication step.
 * This is a much simpler approach, but the time it takes to fetch everything
 * is sufficient that it creates a bad user experience.
 *
 * If we are able to materially simplify the relationship between filing types,
 * publication mediums and publishers available in that step, it's possible that
 * we could remove that file and revert to dynamic fetching.
 */
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';

import { ESnapshotExists, EOrganization, ERef, ETemplate } from 'lib/types';

import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { NotFoundError } from 'lib/errors/ColumnErrors';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { ProductPublishingSettingModel } from 'lib/model/objects/productPublishingSettingModel';
import { CategoryChoiceOption } from 'lib/types/ad';
import { Product } from 'lib/enums/Product';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { getFirebaseContext } from 'utils/firebase';
import { asyncFilter } from 'lib/helpers';
import { getLogger } from 'utils/logger';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { getPublisherOrgOrderTemplate } from 'lib/utils/templates';
import { safeGetModelFromRef } from 'lib/model/getModel';

/**
 * Retreives the valid product publishing settings for a given publisher and product.
 * Imporantly: this includes filing types that will not be visible to the
 * current user, but are still valid for the publisher. Additional filtering must
 * be done to filter out filing types the user does not have access to.
 *
 * Filtering is currently done in getFilingTypeMapForPublisherAndProduct
 */
const getValidProductPublishingSettings = async (
  newspaper: ESnapshotExists<EOrganization>,
  product: Product,
  isUserPublisher: boolean
): Promise<ResponseOrError<ProductPublishingSettingModel[]>> => {
  const productPublishingSettingService = new ProductPublishingSettingsService(
    getFirebaseContext()
  );

  const mediums = await asyncFilter(
    Object.values(PublishingMedium),
    async medium => {
      const result =
        await productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(
          newspaper.ref,
          product,
          medium,
          {
            shouldCreate: false
          }
        );

      // It's fine if the settings aren't there; we only care about other errors.
      if (result.error && !(result.error instanceof NotFoundError)) {
        return result;
      }

      const detailedProductPublishingSetting = result.response;

      if (
        !detailedProductPublishingSetting ||
        detailedProductPublishingSetting.filingTypes.every(
          filingType => !filingType.isVisibleToUser(isUserPublisher)
        ) ||
        detailedProductPublishingSetting.publishingSetting.modelData.deadlines.every(
          deadline => !deadline.publish
        )
      ) {
        return wrapSuccess(null);
      }

      return wrapSuccess(result.response?.productPublishingSetting || null);
    }
  );

  return mediums;
};

/**
 * A map of filing types for a given publisher and product.
 * This enables looking up key details for any combination of: publisher, product, publishing medium, and filing type.
 */
export type PublisherProductFilingTypeModelMap = Record<
  string,
  Partial<
    Record<
      CategoryChoiceOption,
      Partial<
        Record<
          PublishingMedium,
          {
            filingType: FilingTypeModel;
            adTemplate: ERef<ETemplate>;
            productPublishingSetting: ProductPublishingSettingModel;
          }
        >
      >
    >
  >
>;

/**
 * Retreives the filing type map for a given publisher and product. Importantly, it
 * onlyt returns the filing types that are visible to the user.
 *
 * This returns a record in the format:
 * {
 *  [publishingCategory]: {
 *    [publishingMedium]: {
 *      filingType: FilingTypeModel;
 *      adTemplate: ERef<ETemplate>;
 *      productPublishingSetting: ProductPublishingSettingModel;
 *    }
 *  }
 * }
 */
export const getFilingTypeMapForPublisherAndProduct = async (
  publisher: ESnapshotExists<EOrganization>,
  product: Product,
  isPublisher: boolean
): Promise<
  ResponseOrError<
    [ESnapshotExists<EOrganization>, PublisherProductFilingTypeModelMap[string]]
  >
> => {
  const [getPublishingSettingsError, validProductPublishingSettingsForPaper] =
    await getValidProductPublishingSettings(publisher, product, isPublisher);

  if (getPublishingSettingsError) {
    getLogger().error('Error getting valid publishing settings', {
      error: getPublishingSettingsError
    });
    return wrapError(getPublishingSettingsError);
  }

  /**
   * These are in the format:
   * {
   *  [publishingCategory]: {
   *    [publishingMedium]: {
   *      filingType: FilingTypeModel;
   *      adTemplate: ERef<ETemplate>;
   *      productPublishingSetting: ProductPublishingSettingModel;
   *    }
   *  }
   * }[]
   * This map only has one value for the publishing medium from the productPublishingSetting
   */
  const publishingSettingMapResponses = await Promise.all(
    validProductPublishingSettingsForPaper.map(
      async productPublishingSetting => {
        const [publishingSettingError, publishingSetting] =
          await safeGetModelFromRef(
            PublishingSettingModel,
            getFirebaseContext(),
            productPublishingSetting.modelData.publishingSetting
          );
        if (publishingSettingError) {
          getLogger().error('Error fetching publishing setting', {
            error: publishingSettingError
          });
          return wrapError(publishingSettingError);
        }
        const [adTemplateError, adTemplate] =
          await getPublisherOrgOrderTemplate(
            getFirebaseContext(),
            publisher.ref,
            product,
            productPublishingSetting.modelData.publishingMedium
          );
        if (adTemplateError) {
          getLogger().error('Error fetching ad template', {
            error: adTemplateError
          });
          return wrapError(adTemplateError);
        }

        const [filingTypesError, filingTypes] =
          await publishingSetting.fetchFilingTypes();
        if (filingTypesError) {
          getLogger().error('Error fetching filing types', {
            error: filingTypesError
          });
          return wrapError(filingTypesError);
        }

        const filingTypeData: PublisherProductFilingTypeModelMap[CategoryChoiceOption] =
          {};
        filingTypes.forEach(filingType => {
          const publishingCategory = filingType.modelData
            .label as CategoryChoiceOption;
          let dataForPublishingCategory = filingTypeData[publishingCategory];
          if (!dataForPublishingCategory) {
            dataForPublishingCategory = {};
          }

          // Filter out filing types that are not visible to the user, as not all valid filing types are visible
          if (!filingType.isVisibleToUser(isPublisher)) {
            return;
          }

          dataForPublishingCategory[
            productPublishingSetting.modelData.publishingMedium
          ] = {
            productPublishingSetting,
            adTemplate,
            filingType
          };
          filingTypeData[publishingCategory] = dataForPublishingCategory;
        });
        return wrapSuccess(filingTypeData);
      }
    )
  );

  /**
   * Combine the filing type data for the publishing mediums into a single map.
   *
   * This returns a record in the format:
   * {
   *  [publishingCategory]: {
   *    [publishingMedium]: {
   *      filingType: FilingTypeModel;
   *      adTemplate: ERef<ETemplate>;
   *      productPublishingSetting: ProductPublishingSettingModel;
   *    }
   *  }
   * }
   * this combines the data from all publishing mediums for a given publishing category.
   *
   * {
   *  [Services]: {
   *    [print]: {
   *      filingType: FilingTypeModel;
   *      adTemplate: ERef<ETemplate>;
   *      productPublishingSetting: ProductPublishingSettingModel;
   *    }
   *  }
   * }
   * +
   * {
   *  [Services]: {
   *    [online]: {
   *      filingType: FilingTypeModel;
   *      adTemplate: ERef<ETemplate>;
   *      productPublishingSetting: ProductPublishingSettingModel;
   *    }
   *  }
   * }
   * =>
   * {
   *  [Services]: {
   *    [print]: {
   *      filingType: FilingTypeModel;
   *      adTemplate: ERef<ETemplate>;
   *      productPublishingSetting: ProductPublishingSettingModel;
   *    }
   *    [online]: {
   *      filingType: FilingTypeModel;
   *      adTemplate: ERef<ETemplate>;
   *      productPublishingSetting: ProductPublishingSettingModel;
   *    }
   *  }
   * }
   */
  const combinedFilingTypeData: PublisherProductFilingTypeModelMap[string] = {};
  for (const {
    response: publishingSettingData,
    error: filingTypeDataError
  } of publishingSettingMapResponses) {
    if (filingTypeDataError || !publishingSettingData) {
      getLogger().error('Error getting filing type data', {
        error: filingTypeDataError
      });
      return wrapError(filingTypeDataError);
    }
    for (const publishingCategoryString of Object.keys(publishingSettingData)) {
      const publishingCategory =
        publishingCategoryString as CategoryChoiceOption;
      let filingDataForCategory = combinedFilingTypeData[publishingCategory];
      if (!filingDataForCategory) {
        filingDataForCategory = {};
      }
      filingDataForCategory = {
        ...filingDataForCategory,
        ...publishingSettingData[publishingCategory]
      };
      combinedFilingTypeData[publishingCategory] = filingDataForCategory;
    }
  }
  return wrapSuccess([publisher, combinedFilingTypeData]);
};
