import { TableLayout } from 'lib/components/TableLayout';
import React, { useState } from 'react';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { ColumnButton } from 'lib/components/ColumnButton';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { safeStringify } from 'lib/utils/stringify';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getQueryResultsWhereKeyInArray } from 'lib/utils/firebase';
import { getFirebaseContext } from 'utils/firebase';
import AutomatedAffidavitsTableRow from './automatedAffidavitsTableRow';
import {
  PublicationIssueAndRuns,
  useGetPublicationIssuesForAffidavits
} from './hooks/useGetPublicationIssuesForAffidavits';
import AutomatedAffidavitsTableDrawer from './automatedAffidavitsTableDrawer';
import VerifyNoticesModal from './verifyNoticesModal';
import DownloadReportButton from './downloadReportButton';
import AutomatedAffidavitsTableFilterDialog, {
  AutomatedAffidavitTableFilter
} from './automatedAffidavitsTableFilterDialog';
import { shouldShowPublicationIssueRow } from './helpers';

const DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER: AutomatedAffidavitTableFilter =
  {};

type AutomatedAffidavitTableProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onShowEEditionUploadModalForPubIssue: (
    publicationIssue: PublicationIssueModel
  ) => void;
  setSuccessMessage: ({
    header,
    body
  }: {
    header: string;
    body: string;
  }) => void;
};

export default function AutomatedAffidavitsTable({
  activeOrganization,
  onShowEEditionUploadModalForPubIssue,
  setSuccessMessage
}: AutomatedAffidavitTableProps) {
  const columns = [
    <th key="publication-date" className="w-48 font-medium">
      Publication Date
    </th>,
    <th key="upload-method" className="w-64 font-medium">
      Upload Method
    </th>,
    <th key="file-name" className="w-80 font-medium">
      File Name(s)
    </th>,
    <th key="verified" className="text-center font-medium">
      Verified Notices
    </th>,
    <th key="unverified" className="text-center font-medium">
      Unverified Notices
    </th>
  ];

  const [showVerifyNoticesModal, setShowVerifyNoticesModal] = useState(false);

  // Used to force a refresh of the publication issues
  // TODO: maybe implement in row component similar to pagination table
  const [refreshTimestamp] = useState(new Date());
  const [rowFilter, setRowFilter] = useState(
    DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER
  );
  const [editedRowFilter, setEditedRowFilter] = useState(rowFilter);

  const [drawerData, setDrawerData] = useState<PublicationIssueAndRuns>();

  const {
    publicationIssuesLoading,
    // publicationIssuesError,
    publicationIssuesAndRuns
  } = useGetPublicationIssuesForAffidavits(
    activeOrganization,
    refreshTimestamp
  );

  const { value: allEEditions } = useAsyncEffect({
    fetchData: async () => {
      if (!publicationIssuesAndRuns) {
        return [];
      }

      const eeditions = await getQueryResultsWhereKeyInArray(
        getFirebaseContext().eeditionsRef(),
        'publicationIssue',
        publicationIssuesAndRuns.map(
          pubIssueAndRuns => pubIssueAndRuns.publicationIssue.ref
        )
      );
      return eeditions;
    },
    dependencies: [
      publicationIssuesAndRuns
        ?.map(pubIssueAndRuns => pubIssueAndRuns.publicationIssue.id)
        .join('')
    ]
  });

  // Consolidate loading conditions here
  const loading = publicationIssuesLoading;

  return (
    <div
      id="automated-affidavit-table"
      className="bg-white sm:rounded-lg border border-column-gray-100 mb-24 shadow-column-2"
    >
      <TableLayout
        filterable={{
          shouldShowTableItem: (rowData, search) =>
            shouldShowPublicationIssueRow(
              rowData,
              allEEditions,
              search,
              rowFilter
            ),
          additionalFilters: {
            applyFilterChanges: () => setRowFilter(editedRowFilter),
            resetFilters: () => {
              setRowFilter(DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER);
              setEditedRowFilter(DEFAULT_AUTOMATED_AFFIDAVIT_TABLE_FILTER);
            },
            filterHasChanges:
              safeStringify(rowFilter) !== safeStringify(editedRowFilter),
            numFiltersActive: Object.keys(editedRowFilter).length,
            renderDialog: () => (
              <AutomatedAffidavitsTableFilterDialog
                activeOrganization={activeOrganization}
                editedRowFilter={editedRowFilter}
                setEditedRowFilter={setEditedRowFilter}
              />
            )
          }
        }}
        loading={loading}
        renderRow={rowData => <AutomatedAffidavitsTableRow rowData={rowData} />}
        header={{
          title: 'Notice Verification',
          subtitle:
            'Track your e-edition uploads and manually kick off notice verification.'
        }}
        columns={columns}
        data={publicationIssuesAndRuns || []}
        clickable={{
          onClick: rowData => setDrawerData(rowData)
        }}
        actionable={
          <div className="flex">
            <div className="mr-3">
              <DownloadReportButton
                activeOrganization={activeOrganization}
                publicationIssuesAndRuns={publicationIssuesAndRuns}
                setSuccessMessage={setSuccessMessage}
              />
            </div>
            <ColumnButton
              secondary
              type="button"
              id="automated-affidavits-verify-notices-button"
              startIcon={
                <CheckCircleIcon
                  className="w-5 h-5 -ml-0.5 mr-1.5"
                  strokeWidth="1.25"
                />
              }
              buttonText={'Verify notices'}
              onClick={() => setShowVerifyNoticesModal(true)}
            />
          </div>
        }
        pagination={{ pageSize: 6 }}
      />
      {drawerData && (
        <AutomatedAffidavitsTableDrawer
          activeOrganization={activeOrganization}
          drawerData={drawerData}
          onClose={() => setDrawerData(undefined)}
          onUploadEEditionClick={onShowEEditionUploadModalForPubIssue}
        />
      )}
      {showVerifyNoticesModal && (
        <VerifyNoticesModal
          activeOrganization={activeOrganization}
          publicationIssuesAndRuns={publicationIssuesAndRuns}
          onClose={() => setShowVerifyNoticesModal(false)}
          setSuccessMessage={setSuccessMessage}
        />
      )}
    </div>
  );
}
