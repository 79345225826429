import { EFirebaseContext, FirebaseTimestamp } from '../types';
import { SerializedFirebaseTimestamp, Serializer } from './types';
import { hasProperties, isObject, isSerializedField } from './typeCheckers';

export class TimestampSerializer
  implements Serializer<FirebaseTimestamp, SerializedFirebaseTimestamp>
{
  canSerialize(val: unknown): val is FirebaseTimestamp {
    return (
      isObject(val) &&
      hasProperties(
        val,
        { name: 'seconds', type: 'number' },
        { name: 'nanoseconds', type: 'number' }
      )
    );
  }

  canDeserialize(val: unknown): val is SerializedFirebaseTimestamp {
    return (
      isObject(val) &&
      isSerializedField(val, 'timestamp') &&
      hasProperties(
        val,
        { name: 'seconds', type: 'number' },
        { name: 'nanoseconds', type: 'number' }
      )
    );
  }

  serialize(val: FirebaseTimestamp): SerializedFirebaseTimestamp {
    return {
      __type: 'timestamp',
      seconds: val.seconds,
      nanoseconds: val.nanoseconds
    };
  }

  deserialize(
    ctx: EFirebaseContext,
    val: SerializedFirebaseTimestamp
  ): FirebaseTimestamp {
    return ctx.timestamp(val);
  }
}
