import { createReducer, createActions } from 'reduxsauce';
import { createSelector } from 'reselect';
import { Action } from 'redux';
import { getSubdomain } from 'utils/urls';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { getFirebaseContext } from 'utils/firebase';
import { OccupationType, OrganizationType, Product } from 'lib/enums';
import { OrgContextState } from 'lib/types/organization';
import {
  EOrganization,
  ESnapshot,
  EUser,
  FirebaseUser,
  ESnapshotExists,
  exists
} from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { EReduxState } from './types';

type AUTH_ACTION_TYPES = {
  LOGIN: string;
  ANONYMOUS_LOGIN: string;
  LOGOUT: string;
  SET_USER: string;
  START_AUTH: string;
  END_AUTH: string;
  RESET_PASSWORD: string;
  SET_USER_AUTH: string;
  LOGIN_TOKEN: string;
  REGISTER: string;
  SET_ORGANIZATION: string;
  SET_ACTIVE_ORGANIZATION: string;
  SET_AVAILABLE_ORGANIZATIONS: string;
  SHOW_ALL_ORGS_NOTICES: string;
  SET_ORG_CONTEXT: string;
  LOGOUT_SUCCESS: string;
  SET_AUTH_ERROR: string;
  SET_SHOW_PASSWORD_RESET: string;
  SET_TEMPORARY_PASSWORD: string;
  SET_IMPERSONATING: string;
  SET_CLAIM_LOGIN: string;
  ADD_ORDER_ID_CLAIM: string;
};
type AUTH_CREATOR_TYPES = {
  anonymousLogin: () => Action;
  logout: () => Action;
  setUser: (user: ESnapshotExists<EUser>) => Action;
  startAuth: () => Action;
  endAuth: () => Action;
  resetPassword: (email: string) => Action;
  setUserAuth: (userAuth: FirebaseUser) => Action;
  loginToken: (token: string) => Action;
  register: () => Action;
  setOrganization: (organization: ESnapshot<EOrganization>) => Action;
  setActiveOrganization: (
    activeOrganization: ESnapshot<EOrganization> | null | undefined
  ) => Action;
  setAvailableOrganizations: (
    availableOrganizations: ESnapshotExists<EOrganization>[]
  ) => Action;
  showAllOrgsNotices: (showAllOrgsNotices: boolean) => Action;
  setOrgContext: (orgContext: OrgContextState) => Action;
  logoutSuccess: () => Action;
  setAuthError: (error: string) => Action;
  setShowPasswordReset: (showPasswordReset: boolean) => Action;
  setTemporaryPassword: (temporaryPassword: string) => Action;

  setImpersonating: (impersonating: boolean) => Action;
  setClaimLogin: (isClaimLogin: boolean) => Action;
  addOrderIdClaim: (orderId: string) => Action;
};

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions<
  AUTH_ACTION_TYPES,
  AUTH_CREATOR_TYPES
>({
  anonymousLogin: [],
  logout: [],
  setUser: ['user'],

  startAuth: [],
  endAuth: [],

  resetPassword: ['email'],

  setUserAuth: ['userAuth'],
  loginToken: ['token'],
  register: [],

  setOrganization: ['organization'],
  setActiveOrganization: ['activeOrganization'],
  setAvailableOrganizations: ['availableOrganizations'],
  showAllOrgsNotices: ['showAllOrgsNotices'],

  setOrgContext: ['orgContext'],

  logoutSuccess: [],

  setAuthError: ['error'],

  setShowPasswordReset: ['showPasswordReset'],

  setTemporaryPassword: ['temporaryPassword'],

  setImpersonating: ['impersonating'],

  setClaimLogin: ['isClaimLogin'],

  addOrderIdClaim: ['orderIdClaim']
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export type AuthState = {
  previousUser: ESnapshotExists<EUser> | null;
  user: ESnapshotExists<EUser> | null;
  userAuth: FirebaseUser | null;
  isPublisher: boolean;
  organization: ESnapshot<EOrganization> | null;
  activeOrganization: ESnapshot<EOrganization> | null;
  availableOrganizations: ESnapshotExists<EOrganization>[];
  alwaysAllowAffidavitDownload: boolean;
  error: string;
  orgContext: OrgContextState;
  loading: boolean;
  showAllOrgsNotices?: boolean;
  showPasswordReset?: boolean;
  temporaryPassword?: string;

  /** Is the current session impersonating another user */
  impersonating?: boolean;

  /** Is the current session authenticated via custom token (e.g. obits flow) */
  isClaimLogin: boolean;

  /** The current order ids we have claims for */
  orderIdClaims: string[];
};

export const INITIAL_STATE: AuthState = {
  previousUser: null,
  user: null,
  userAuth: null,
  isPublisher: false,
  organization: null,
  activeOrganization: null,
  availableOrganizations: [],
  alwaysAllowAffidavitDownload: false,
  error: '',
  orgContext: null,
  showPasswordReset: false,
  loading: true,
  impersonating: false,
  isClaimLogin: false,
  orderIdClaims: []
};

export const authSelector = (state: EReduxState) => state.auth;
export const selectIsPublisher = createSelector(
  [authSelector],
  auth => auth.isPublisher
);

export const selectTemporaryPassword = createSelector(
  [authSelector],
  auth => auth.temporaryPassword
);
export const selectIsColumnRep = createSelector(
  [authSelector],
  auth => !!auth.user?.data().isColumnRep
);
export const selectUser = createSelector([authSelector], auth => auth.user);
export const selectAuthLoading = createSelector(
  [authSelector],
  auth => auth.loading
);
export const selectAuthError = createSelector(
  [authSelector],
  auth => auth.error
);
export const selectUserAuth = createSelector(
  [authSelector],
  auth => auth.userAuth
);
export const selectActiveOrganization = createSelector(
  [authSelector],
  auth => auth.activeOrganization
);
export const selectUserAuthIsSet = createSelector([selectUserAuth], userAuth =>
  Boolean(userAuth)
);

export const selectAnonymousUserId = createSelector(
  [selectUserAuth],
  userAuth => (userAuth?.isAnonymous ? userAuth?.uid : null)
);

export const selectAvailableOrganizations = createSelector(
  [authSelector],
  auth => auth.availableOrganizations
);
export const selectShowAllOrgsNotices = createSelector(
  [authSelector],
  auth => auth.showAllOrgsNotices
);
export const selectOrgContext = createSelector(
  [authSelector],
  auth => auth.orgContext
);
export const selectContextOrganizationId = createSelector(
  [selectOrgContext],
  orgContext => orgContext?.id
);
export const selectOrgContextRef = createSelector(
  [selectContextOrganizationId],
  orgContextId => {
    if (!orgContextId) return null;
    return getFirebaseContext().organizationsRef().doc(orgContextId);
  }
);
export const selectContextOrganizationName = createSelector(
  [selectOrgContext],
  orgContext => orgContext?.name
);
export const selectIsContextOrganizationPublisher = createSelector(
  [selectOrgContext],
  orgContext =>
    orgContext?.organizationType === OrganizationType.newspaper.value
);

export const selectIsOnSubdomain = createSelector(
  [selectOrgContext],
  orgContext => {
    return getSubdomain() === orgContext?.subdomain;
  }
);
export const selectOrganizationHeaderLogo = createSelector(
  [selectOrgContext, selectIsOnSubdomain],
  (orgContext, isOnSubdomain) => {
    const subdomainLogo = getLocationParams().get('logo');
    if (subdomainLogo) return subdomainLogo;
    return isOnSubdomain ? orgContext?.filingFlowSubdomainImage : null;
  }
);

export const selectIsUserLoggedOut = createSelector(
  [selectAuthLoading, selectUserAuthIsSet],
  (loading, userAuthIsSet) => {
    // If the auth state is completely done loading and there's no userAuth, we're not authenticated in Firebase via email or anonymous login
    return !loading && !userAuthIsSet;
  }
);

export const selectIsUserLoggedIn = createSelector(
  [selectAuthLoading, selectAuthError, selectUserAuthIsSet],
  (loading, error, userAuthIsSet) => {
    // If the auth state is completely done loading and there is a userAuth, we have a valid Firebase authentication
    return Boolean((!loading || error) && userAuthIsSet);
  }
);

export const selectIsImpersonating = createSelector(
  [authSelector],
  auth => !!auth.impersonating
);

export const selectIsClaimLogin = createSelector(
  [authSelector],
  auth => !!auth.isClaimLogin
);

export const selectOrderIdClaims = createSelector(
  [authSelector],
  auth => auth.orderIdClaims
);

export const selectActiveOrganizationModel = createSelector(
  [selectActiveOrganization],
  activeOrganization => {
    return exists(activeOrganization)
      ? getModelFromSnapshot(
          OrganizationModel,
          getFirebaseContext(),
          activeOrganization
        )
      : null;
  }
);

export const selectIsObituariesActiveOnSomeAvailableOrg = createSelector(
  [selectAvailableOrganizations],
  availableOrganizations =>
    !!availableOrganizations
      .map(org =>
        getModelFromSnapshot(OrganizationModel, getFirebaseContext(), org)
      )
      .some(orgModal => orgModal.hasAdTypeActive(Product.Obituary))
);

export const selectIsClassifiedsActiveOnSomeAvailableOrg = createSelector(
  [selectAvailableOrganizations],
  availableOrganizations =>
    !!availableOrganizations
      .map(org =>
        getModelFromSnapshot(OrganizationModel, getFirebaseContext(), org)
      )
      .some(orgModal => orgModal.hasAdTypeActive(Product.Classified))
);

export const selectHasObituariesActive = createSelector(
  [
    selectShowAllOrgsNotices,
    selectActiveOrganizationModel,
    selectIsObituariesActiveOnSomeAvailableOrg
  ],
  (
    showAllOrgsNotices,
    activeOrganization,
    isObituariesActiveOnSomeAvailableOrg
  ): boolean => {
    if (showAllOrgsNotices) {
      return isObituariesActiveOnSomeAvailableOrg;
    }
    return !!activeOrganization?.hasAdTypeActive(Product.Obituary);
  }
);

export const selectHasClassifiedsActive = createSelector(
  [
    selectIsPublisher,
    selectShowAllOrgsNotices,
    selectActiveOrganizationModel,
    selectIsClassifiedsActiveOnSomeAvailableOrg
  ],
  (
    isPublisher,
    showAllOrgsNotices,
    activeOrganization,
    isClassifiedsActiveOnSomeAvailableOrg
  ): boolean => {
    if (showAllOrgsNotices) {
      return isClassifiedsActiveOnSomeAvailableOrg;
    }
    if (isPublisher) {
      return !!activeOrganization?.hasAdTypeActive(Product.Classified);
    }

    return (
      !activeOrganization ||
      activeOrganization?.hasAdTypeActive(Product.Classified)
    );
  }
);

export const selectHasPublicNoticesActive = createSelector(
  [selectAvailableOrganizations, selectActiveOrganization],
  (availableOrganizations, activeOrganization) =>
    !activeOrganization ||
    !!availableOrganizations
      .map(org =>
        getModelFromSnapshot(OrganizationModel, getFirebaseContext(), org)
      )
      .some(orgModal => orgModal.hasAdTypeActive(Product.Notice))
);

export const selectDefaultOrdersRoute = createSelector(
  [selectActiveOrganizationModel],
  activeOrganization =>
    !activeOrganization || activeOrganization.hasAdTypeActive(Product.Notice)
      ? '/notices'
      : activeOrganization.hasAdTypeActive(Product.Obituary)
      ? '/obituaries'
      : '/classifieds'
);

/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: (state, { user }) => ({
    ...state,
    isPublisher: user.data().occupation === OccupationType.publishing.value,
    alwaysAllowAffidavitDownload:
      user.data().alwaysAllowAffidavitDownload || false,
    previousUser: state.user,
    user
  }),

  [Types.SET_USER_AUTH]: (state, { userAuth }) => ({
    ...state,
    userAuth
  }),

  [Types.START_AUTH]: state => ({
    ...state,
    loading: true
  }),

  [Types.END_AUTH]: state => ({
    ...state,
    loading: false
  }),

  [Types.SET_AVAILABLE_ORGANIZATIONS]: (state, { availableOrganizations }) => ({
    ...state,
    availableOrganizations
  }),
  [Types.SET_ACTIVE_ORGANIZATION]: (state, { activeOrganization }) => ({
    ...state,
    activeOrganization
  }),

  [Types.SHOW_ALL_ORGS_NOTICES]: (state, { showAllOrgsNotices }) => ({
    ...state,
    showAllOrgsNotices
  }),

  [Types.SET_AUTH_ERROR]: (state, { error }) => ({ ...state, error }),

  [Types.LOGOUT_SUCCESS]: () => INITIAL_STATE,
  [Types.SET_ORG_CONTEXT]: (state, { orgContext }) => ({
    ...state,
    orgContext
  }),
  [Types.SET_SHOW_PASSWORD_RESET]: (state, { showPasswordReset }) => ({
    ...state,
    showPasswordReset
  }),
  [Types.SET_TEMPORARY_PASSWORD]: (state, { temporaryPassword }) => ({
    ...state,
    temporaryPassword
  }),
  [Types.SET_IMPERSONATING]: (state, { impersonating }) => ({
    ...state,
    impersonating
  }),
  [Types.SET_CLAIM_LOGIN]: (state, { isClaimLogin }) => ({
    ...state,
    isClaimLogin
  }),
  [Types.ADD_ORDER_ID_CLAIM]: (state, { orderIdClaim }) => ({
    ...state,
    orderIdClaims: [...state.orderIdClaims, orderIdClaim]
  })
});
