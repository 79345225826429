import { SnapshotModel, getModelFromRef } from '..';
import {
  ORDER_OBITUARY_VERIFIED,
  OrderObituaryVerifiedEvent
} from '../../types/events';
import { Collections } from '../../constants';
import { EOrganization } from '../../types';
import { Obituary } from '../../types/obituary';
import { isAnonymousOrder } from '../../types/order';
import { getOrThrow } from '../../utils/refs';
import { OrderModel } from './orderModel';

export class ObituaryModel extends SnapshotModel<
  Obituary,
  typeof Collections.obituaries
> {
  get type() {
    return Collections.obituaries;
  }

  get isVerified() {
    const { deathVerification } = this.modelData;
    return !!deathVerification.verifiedAt;
  }

  async getOrder() {
    return getModelFromRef(OrderModel, this.ctx, this.modelData.order);
  }

  async setVerificationStatus(
    isVerified: boolean,
    verificationData: OrderObituaryVerifiedEvent['data']
  ) {
    const order = await this.getOrder();

    const verifiedAtValue = isVerified
      ? this.ctx.fieldValue().serverTimestamp()
      : this.ctx.fieldValue().delete();

    if (isAnonymousOrder(order.modelData)) {
      await this.update({
        'deathVerification.verifiedAt': verifiedAtValue
      } as any);
    } else {
      const advertiserOrganizationSnap = await getOrThrow<EOrganization>(
        order.modelData.advertiserOrganization
      );

      const advertiserOrganization = advertiserOrganizationSnap.data();

      await this.update({
        deathVerification: {
          funeralHomeName: advertiserOrganization.name,
          funeralHomePhone: advertiserOrganization.phone,
          verifiedAt: verifiedAtValue as any
        }
      });
    }

    if (isVerified) {
      const { error: eventCreationError } =
        await order.createEvent<OrderObituaryVerifiedEvent>(
          ORDER_OBITUARY_VERIFIED,
          {
            ...verificationData
          }
        );
      if (eventCreationError) {
        throw eventCreationError;
      }
    }
  }
}
