import { TextField } from 'lib/components/TextField';
import { AdRate } from 'lib/types/rates';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';
import { GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { centsToExtendedCurrency } from 'lib/utils/rates';
import { getRateUnit } from '../rateUpdateFormUtils';

type OffsetWithFlatFeeProps = {
  value: Pick<
    AdRate,
    'offsetFlatRateInCents' | 'offset' | 'rateType' | 'rate_0' | 'product'
  >;
  initialValue: Pick<AdRate, 'offsetFlatRateInCents' | 'offset'>;
  onChange: (changes: Pick<AdRate, 'offsetFlatRateInCents' | 'offset'>) => void;
};

export function OffsetWithFlatFee({
  value,
  initialValue,
  onChange
}: OffsetWithFlatFeeProps) {
  const offsetUnit = getRateUnit(value.product, value.rateType);

  return (
    <SwitchControlledCard
      labelProps={{
        label: `Charge a flat fee for the first few ${offsetUnit?.plural}?`,
        description: `If enabled, you can configure a "flat fee with open rate" structure. Example: Charge $10 for the first 2 ${offsetUnit?.plural}, then $2 per ${offsetUnit?.singular} after that.`,
        value: typeof value.offset === 'number',
        onChange: shouldOffset =>
          onChange({
            offset: shouldOffset ? initialValue.offset ?? 0 : null,
            offsetFlatRateInCents: shouldOffset
              ? initialValue.offsetFlatRateInCents ?? 0
              : null
          })
      }}
      header="Rate Offsets"
    >
      <GridInput fullWidth>
        <CurrencyTextField
          initialValue={value.offsetFlatRateInCents ?? 0}
          onChange={amount =>
            onChange({
              ...value,
              offsetFlatRateInCents: amount
            })
          }
          id="rate-offset-fee"
          labelText="Flat fee amount"
        />
      </GridInput>

      <GridInput fullWidth>
        <TextField
          labelText={`How many ${offsetUnit?.plural} are charged the flat fee?`}
          noteText={`After this initial amount, every additional ${
            offsetUnit?.singular
          } will cost $${
            value.rate_0 ? centsToExtendedCurrency(value.rate_0) : 0
          }`}
          id="rate-offset"
          value={`${value.offset ?? 0}`}
          onChange={stringOffset => {
            onChange({
              ...value,
              offset: parseFloat(stringOffset)
            });
          }}
          type="number"
          max={1000}
          min={0}
        />
      </GridInput>
    </SwitchControlledCard>
  );
}
