import api from 'api';
import { Product } from 'lib/enums';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { ColumnService } from 'lib/services/directory';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { SearchableOrderRecord } from 'lib/types/searchable';
import { logAndCaptureException } from 'utils';

export const getPaginationReadyOrdersForPublicationIssue = async (
  publicationIssue: PublicationIssueModel,
  product: Product,
  publishingMedium: PublishingMedium
): Promise<ResponseOrError<SearchableOrderRecord[]>> => {
  const { error: apiError, response: orders } =
    await api.safePost<'pagination/get-pagination-ready-orders'>(
      'pagination/get-pagination-ready-orders',
      {
        publicationIssueId: publicationIssue.id,
        product,
        publishingMedium
      }
    );
  if (apiError !== null) {
    logAndCaptureException(
      ColumnService.PAGINATION,
      apiError,
      'Unable to get pagination-ready orders from client',
      {
        publicationIssueId: publicationIssue.id,
        product,
        publishingMedium
      }
    );
    return wrapError(new Error(apiError));
  }
  return wrapSuccess(orders);
};
