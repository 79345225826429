import React, { useEffect, useState, ChangeEvent } from 'react';
import { logAndCaptureException } from 'utils';
import { FormControlLabel, IconButton } from '@material-ui/core';
import FreeformCModal from 'components/modals/FreeFormCModal';
import SelectDropdown from 'routes/placeScroll/SelectDropdown';
import { State as States } from 'lib/enums';
import lobApi from 'lob';
import api from 'api';
import * as EmailValidator from 'email-validator';
import { ESnapshotExists, ESnapshot } from 'lib/types/firebase';
import { ENotice, EUser, MailDelivery, exists } from 'lib/types';
import { EOrganization } from 'lib/types/organization';
import { getRecipientName } from 'lib/utils/invoices';
import { AddIcon, DeleteIcon } from 'icons';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { LOB_CONFIG, ENV, PROD } from '../../constants';

type EMailWithoutStatus = Pick<
  MailDelivery,
  'address' | 'description' | 'name'
>;

export default function SendReminderModal({
  filer,
  notice,
  invoiceId,
  newspaper,
  onCloseModal
}: {
  filer?: ESnapshotExists<EUser>;
  notice?: ESnapshotExists<ENotice>;
  invoiceId?: string;
  newspaper: ESnapshot<EOrganization>;
  onCloseModal: () => void;
}) {
  const dispatch = useAppDispatch();

  const DEFAULT_MAIL: EMailWithoutStatus = {
    address: {
      address_line1: '',
      address_line2: '',
      address_city: '',
      address_state: '',
      address_zip: ''
    },
    description: '',
    name: ''
  };

  const invoiceRecipient = notice?.data()?.invoiceRecipient;
  const defaultEmail =
    invoiceRecipient?.type === 'email'
      ? invoiceRecipient?.email
      : filer?.data()?.email || '';

  const defaultMailChecked = invoiceRecipient?.type === 'mail';
  const defaultEmailChecked = !defaultMailChecked;

  const zipRegex = /^(([0-9]{5})|([0-9]{5}-[0-9]{4}))$/g;
  const DEFAULT_ERROR: string[] = Array(5).fill('');
  const MAX_ADDRESSEES = 5;
  const [emailChecked, setEmailChecked] = useState(defaultEmailChecked);
  const [mailChecked, setMailChecked] = useState(defaultMailChecked);
  const [emails, setEmails] = useState<string[]>([defaultEmail]);
  const [mails, setMails] = useState<EMailWithoutStatus[]>([DEFAULT_MAIL]);
  const [emailError, setEmailError] = useState(DEFAULT_ERROR);
  const [mailError, setMailError] = useState(DEFAULT_ERROR);
  const [showErrors, setShowErrors] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const prefillNoticeAddress = async () => {
    if (invoiceRecipient && invoiceRecipient.type === 'mail') {
      const address_state =
        States.by_label(`${invoiceRecipient.address.address_state}`)?.value ||
        '';

      setMails([
        {
          name: getRecipientName(invoiceRecipient),
          address: {
            ...invoiceRecipient.address,
            address_state
          },
          description: 'Prefilled from invoice recipient'
        }
      ]);
      return;
    }

    const organizationRef = notice?.data()?.filedBy;
    if (organizationRef) {
      try {
        const organization = await organizationRef.get();
        const { address, addressLine2, city, state, zipCode } =
          organization.data() || {};
        setMails([
          {
            address: {
              address_line1: address || DEFAULT_MAIL.address.address_line1,
              address_line2: addressLine2 || DEFAULT_MAIL.address.address_line2,
              address_city: city || DEFAULT_MAIL.address.address_city,
              address_state: state || DEFAULT_MAIL.address.address_state,
              address_zip: zipCode || DEFAULT_MAIL.address.address_zip
            },
            name: filer?.data()?.name || DEFAULT_MAIL.name,
            description: 'Prefilled from organization info'
          }
        ]);
      } catch (err) {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          err,
          'failed to load organization',
          {
            orgId: organizationRef.id
          }
        );
        setMails([DEFAULT_MAIL]);
      }
    } else if (filer && exists(filer)) {
      const { address, addressLine2, city, state, zipCode, name } =
        filer.data();
      setMails([
        {
          address: {
            address_line1: address || DEFAULT_MAIL.address.address_line1,
            address_line2: addressLine2 || DEFAULT_MAIL.address.address_line2,
            address_city: city || DEFAULT_MAIL.address.address_city,
            address_state: state || DEFAULT_MAIL.address.address_state,
            address_zip: zipCode || DEFAULT_MAIL.address.address_zip
          },
          name: name || DEFAULT_MAIL.name,
          description: 'Prefilled from user info'
        }
      ]);
    }
  };

  useEffect(() => {
    if (mailChecked && filer) {
      void prefillNoticeAddress();
    }
  }, [mailChecked]);

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 500px)').matches);
  }, []);

  const addEmails = () => {
    if (emails && emails.length >= MAX_ADDRESSEES) return;
    setEmails(emails.concat(''));
  };

  const addMails = () => {
    if (mails && mails.length >= MAX_ADDRESSEES) return;
    setMails(mails.concat(DEFAULT_MAIL));
  };
  const renderEmails = () => (
    <div>
      <div className="ml-8">
        {emails.map((e: string, i: number) => {
          return (
            <div key={`${i}-email-container`} className="mb-1">
              <div key={`${i}-email-item`}>
                <div className="w-11/12 inline-block">
                  <input
                    id={`email-${i}`}
                    placeholder="Email"
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setEmails(
                        emails.map((e2, j) => (i === j ? e.target.value : e2))
                      );
                    }}
                    value={e}
                  />
                </div>
                {i > 0 && (
                  <div className="w-1/12 inline-block">
                    <IconButton
                      id={`delete-email-{i}`}
                      onClick={() => {
                        const newEmails = emails
                          .slice(0, i)
                          .concat(emails.slice(i + 1, emails.length));
                        setEmails(newEmails);
                      }}
                    >
                      <DeleteIcon className="w-5 h-5 text-grey-700" />
                    </IconButton>
                  </div>
                )}
                {showErrors && emailError[i] && (
                  <div>
                    <div className="justify-start items-start text-red-600 text-sm font-normal pt-2">
                      {emailError[i]}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        id="addEmails"
        onClick={addEmails}
        className="ml-4 inline-flex items-center px-3 py-2 text-sm leading-4 font-medium text-sm text-gray-700 rounded-full bg-white hover:bg-gray-100"
      >
        <AddIcon className="w-5 h-5 mr-1" />
        Add Email
      </button>
    </div>
  );
  const renderMails = () => (
    <div>
      <div className="ml-8">
        {mails.map((m, i) => {
          return (
            <div key={`${i}-mail-container`} className="mb-2">
              <div key={`${i}-mail-row-1`}>
                <div className="w-11/12 inline-block">
                  <input
                    id={`name-${i}`}
                    placeholder="Name"
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setMails(
                        mails.map((m2, j) =>
                          i === j ? { ...m2, name: e.target.value } : m2
                        )
                      );
                    }}
                    value={m.name}
                  />
                </div>
                {i > 0 && (
                  <div className="w-1/12 inline-block">
                    <IconButton
                      id={`delete-mail-${i}`}
                      onClick={() => {
                        const newMails = mails
                          .slice(0, i)
                          .concat(mails.slice(i + 1, mails.length));
                        setMails(newMails);
                      }}
                    >
                      <DeleteIcon className="w-5 h-5 text-grey-700" />
                    </IconButton>
                  </div>
                )}
              </div>
              <div
                key={`${i}-mail-row-2`}
                className="w-11/12 flex justify-between"
              >
                <div className="w-8/12 inline-block">
                  <input
                    id={`addressLine1${i}`}
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Address"
                    value={m.address.address_line1}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setMails(
                        mails.map((m2, j) =>
                          i === j
                            ? {
                                ...m2,
                                address: {
                                  ...m2.address,
                                  address_line1: e.target.value
                                }
                              }
                            : m2
                        )
                      )
                    }
                  />
                </div>
                <div className="ml-1 w-4/12 inline-block">
                  <input
                    id={`addressLine2${i}`}
                    value={m.address.address_line2}
                    placeholder="Apt."
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setMails(
                        mails.map((m2, j) =>
                          i === j
                            ? {
                                ...m2,
                                address: {
                                  ...m2.address,
                                  address_line2: e.target.value
                                }
                              }
                            : m2
                        )
                      );
                    }}
                  />
                </div>
              </div>
              <div
                key={`${i}-mail-row-3`}
                className=" w-11/12 flex justify-between"
              >
                <div className="w-5/12">
                  <input
                    id={`city${i}`}
                    value={m.address.address_city}
                    placeholder="City"
                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setMails(
                        mails.map((m2, j) =>
                          i === j
                            ? {
                                ...m2,
                                address: {
                                  ...m2.address,
                                  address_city: e.target.value
                                }
                              }
                            : m2
                        )
                      );
                    }}
                  />
                </div>
                <div className="w-4/12 mt-1 mx-1">
                  <SelectDropdown
                    id={`state${i}`}
                    className="state-select h-full leading-7 md:leading-6"
                    placeholder="State"
                    onChange={(selected: { id: number }) => {
                      if (selected) {
                        const state = selected.id;
                        setMails(
                          mails.map((m2, j) =>
                            i === j
                              ? {
                                  ...m2,
                                  address: {
                                    ...m2.address,
                                    address_state: state
                                  }
                                }
                              : m2
                          )
                        );
                      }
                    }}
                    selected={
                      m.address.address_state
                        ? {
                            id: `${m.address.address_state}`,
                            label: States.by_value(m.address.address_state)
                              ?.label
                          }
                        : null
                    }
                    value={
                      m.address.address_state
                        ? States.by_value(m.address.address_state)
                        : ''
                    }
                    options={States.items().map(
                      (state: Record<string, any>) => ({
                        id: state.value,
                        label: state.label
                      })
                    )}
                    borderColor={'#e2e8f0'}
                    placeholderText={'#a0aec0'}
                  />
                </div>
                <div className="w-3/12">
                  <input
                    id={`zipcode${i}`}
                    value={m.address.address_zip}
                    placeholder={isMobile ? 'Zip' : 'Zipcode'}
                    className="mt-1 form-input block w-full ≈border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const regex = /^(([0-9]{0,5})|([0-9]{5}-[0-9]{0,4}))$/g;
                      if (e.target.value.match(regex))
                        setMails(
                          mails.map((m2, j) =>
                            i === j
                              ? {
                                  ...m2,
                                  address: {
                                    ...m2.address,
                                    address_zip: e.target.value
                                  }
                                }
                              : m2
                          )
                        );
                    }}
                  />
                </div>
              </div>
              {showErrors && mailError[i] && (
                <div>
                  <div className="justify-start items-start text-red-600 text-sm font-normal pt-2">
                    {mailError[i]}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <button
        type="button"
        id="addMails"
        onClick={addMails}
        className="ml-4 inline-flex items-center px-3 py-2 text-sm leading-4 font-medium text-sm text-gray-700 rounded-full bg-white hover:bg-gray-100"
      >
        <AddIcon className="w-5 h-5 mr-1" />
        Add Mailing Address
      </button>
    </div>
  );
  const validateEmail = () => {
    const newError = [...DEFAULT_ERROR];
    if (emailChecked) {
      for (const [i, e] of emails.entries()) {
        const previous = emails.slice(0, i);
        const validEmail = EmailValidator.validate(e);
        if (!e) newError[i] = 'Email is missing';
        else if (!validEmail) newError[i] = 'Invalid email';
        else if (previous.indexOf(e) > -1) newError[i] = 'Duplicate email';
      }
    }
    setEmailError([...newError]);
  };
  const validateMail = () => {
    const newError = [...DEFAULT_ERROR];
    if (mailChecked) {
      for (const [i, m] of mails.entries()) {
        if (!m.name) newError[i] = 'Recipient name is missing';
        else if (m.name.length > 40)
          newError[i] = 'Recipient name must be less than 40 characters';
        else if (!m.address.address_line1)
          newError[i] = 'Address line 1 is missing';
        else if (!m.address.address_city) newError[i] = 'City is missing';
        else if (!m.address.address_state) newError[i] = 'State is missing';
        else if (!m.address.address_zip) newError[i] = 'Zip code is missing';
        else if (!m.address.address_zip.match(zipRegex))
          newError[i] = 'Zip code is invalid';
        else newError[i] = '';
      }
    }
    setMailError([...newError]);
  };
  useEffect(() => {
    validateMail();
  }, [mails]);

  useEffect(() => {
    validateEmail();
  }, [emails]);

  useEffect(() => {
    if (mailChecked) {
      validateMail();
    }
  }, [mailChecked]);

  useEffect(() => {
    if (emailChecked) {
      validateEmail();
    }
  }, [emailChecked]);

  const verifyAddresses = async (m: any) => {
    await new Promise<void>((resolve, reject) => {
      lobApi(LOB_CONFIG[ENV].key).usVerifications.verify(
        {
          primary_line:
            ENV === PROD
              ? m.address.address_line1
              : LOB_CONFIG[ENV].address.primary_line,
          secondary_line: m.address.address_line2,
          city: m.address.address_city,
          state: m.address.address_state,
          zip_code: m.address.address_zip
        },
        (err: any, res: any) => {
          if (err) {
            logAndCaptureException(
              ColumnService.PAYMENTS,
              err,
              'failed to verify address',
              {
                address: m.address
              }
            );
            reject();
          }
          if (res && res.deliverability === 'undeliverable') {
            void api.post('notifications/slack', {
              message: `Wrong address was submitted for mailed invoice reminder to: ${
                m.name
              }. 
                address line 1: ${m.address.address_line1}, 
                address line 2: ${m.address.address_line2}, 
                city: ${m.address.address_city}, 
                state: ${States.by_value(m.address.address_state)?.label}, 
                zip code: ${m.address.address_zip}`
            });
          }
          resolve();
        }
      );
    });
  };
  const onExit = async () => {
    if (emailChecked && !emailError.every(e => !e)) {
      setShowErrors(true);
      return false;
    }
    if (mailChecked && !mailError.every(e => !e)) {
      setShowErrors(true);
      return false;
    }
    if (mailChecked) await Promise.all([mails.map(verifyAddresses)]);
    return true;
  };

  const isComplete = () => {
    if (!emailChecked && !mailChecked) return false;
    if (showErrors) {
      if (emailChecked) {
        return emailError.every(e => !e);
      }
      if (mailChecked) {
        return mailError.every(e => !e);
      }
    }
    return true;
  };

  const sendReminders = async () => {
    if (!invoiceId) return;

    if (emailChecked) {
      await Promise.all([
        emails.map(email =>
          api.post('notifications/invoice-reminder', {
            noticeId: notice?.id,
            invoiceId,
            newspaperName: newspaper.data()?.name,
            email
          })
        )
      ]);
    }
    if (mailChecked) {
      await Promise.all([
        mails.map(mailSnap =>
          api.post('notifications/mail-invoice-reminder', {
            invoiceId,
            mailSnap
          })
        )
      ]);
    }
  };
  const isPlural = () => {
    let recipients = 0;
    if (mailChecked) recipients += mails.length;
    if (emailChecked) recipients += emails.length;
    return recipients > 1;
  };
  const handleSubmit = async () => {
    if (isComplete() && (await onExit())) {
      await sendReminders();
      onCloseModal();
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: `Success! Your invoice reminder${
            isPlural() ? 's have' : ' has'
          } been sent.`
        })
      );
    }
  };
  return (
    <FreeformCModal
      header={'How would you like to send this reminder?'}
      body={'A copy of the invoice will be attached.'}
      id={'send-reminder-modal'}
      setOpen={() => onCloseModal()}
      noExitOutsideModal
    >
      <section className="mt-3">
        <div>
          <FormControlLabel
            control={
              <input
                checked={emailChecked}
                onChange={() => setEmailChecked(!emailChecked)}
                value="checkedA"
                id="sendEmailCheckBox"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3"
              />
            }
            label={'Send reminder by email'}
          />
        </div>
        {emailChecked && renderEmails()}
        <div className="mt-2">
          <FormControlLabel
            control={
              <input
                checked={mailChecked}
                onChange={() => setMailChecked(!mailChecked)}
                value="checkedA"
                id="sendMailCheckBox"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-gray-700 transition duration-150 ease-in-out mx-3"
              />
            }
            label={'Send reminder by mail'}
          />
        </div>
        {mailChecked && renderMails()}
        <div className="flex justify-center md:justify-start">
          <button
            id={`confirm-send-reminder`}
            onClick={() => handleSubmit()}
            className={`bg-blue-200 w-auto border border-transparent duration-150 ease-in-out focus:border-blue-500 focus:outline-none focus:shadow-outline-blue font-medium leading-6 mt-3 px-4 py-2 rounded-md shadow-sm sm:leading-5 sm:text-sm text-base text-blue-700 transition w-full md:w-auto mt-4 ${
              !emailChecked && !mailChecked
                ? 'cursor-not-allowed pointer-events-none bg-opacity-50 opacity-50'
                : 'hover:bg-blue-600 hover:text-white'
            }`}
          >
            Send Reminder
          </button>
        </div>
        <style>{`
            .MuiFormControlLabel-label {
              color: #6B7280 !important;
            }
            #send-reminder-modal-inner {
              max-height: 500px;
              overflow: scroll;
            }
          `}</style>
      </section>
    </FreeformCModal>
  );
}
