import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import {
  FilingType,
  isOrderFilingType,
  PublicationDateSettings
} from 'lib/types/filingType';

function validatePublicationDateSettings(settings: PublicationDateSettings) {
  const {
    initialPublicationDates,
    minimumPublicationDates,
    maximumPublicationDates
  } = settings;

  const isInitialPublicationDatesWithinLowerLimit =
    !initialPublicationDates ||
    !minimumPublicationDates ||
    initialPublicationDates >= minimumPublicationDates;
  const isInitialPublicationDatesWithinUpperLimit =
    !initialPublicationDates ||
    !maximumPublicationDates ||
    initialPublicationDates <= maximumPublicationDates;
  const isMinimumPublicationDatesWithinUpperLimit =
    !minimumPublicationDates ||
    !maximumPublicationDates ||
    minimumPublicationDates <= maximumPublicationDates;

  return {
    isInitialPublicationDatesWithinLowerLimit,
    isInitialPublicationDatesWithinUpperLimit,
    isMinimumPublicationDatesWithinUpperLimit
  };
}

type OrderFilingTypePublicationDatesCardProps<T> = {
  updatedFilingType: T;
  onUpdateFilingType: (filingType: T) => void;
};

function OrderFilingTypePublicationDatesCard<T extends FilingType>({
  updatedFilingType,
  onUpdateFilingType
}: OrderFilingTypePublicationDatesCardProps<T>) {
  const orderFilingType = isOrderFilingType(updatedFilingType);

  if (!orderFilingType) {
    return null;
  }

  const initialPublicationDates =
    updatedFilingType.publicationDateSettings?.initialPublicationDates;
  const minimumPublicationDates =
    updatedFilingType.publicationDateSettings?.minimumPublicationDates;
  const maximumPublicationDates =
    updatedFilingType.publicationDateSettings?.maximumPublicationDates;

  const {
    isInitialPublicationDatesWithinLowerLimit,
    isInitialPublicationDatesWithinUpperLimit,
    isMinimumPublicationDatesWithinUpperLimit
  } = validatePublicationDateSettings(
    updatedFilingType.publicationDateSettings || {}
  );

  return (
    <CardGridLayout
      header={{
        title: 'Publication Schedule',
        description:
          'Configure publication schedule requirements for this category.'
      }}
    >
      <GridInput fullWidth>
        <TextField
          id="default-publication-dates"
          labelText="Default number of publication dates"
          noteText="The number of publication dates that will be selected by default when creating a new order."
          type="number"
          min={1}
          placeholder={
            minimumPublicationDates ? String(minimumPublicationDates) : '1'
          }
          value={initialPublicationDates ? String(initialPublicationDates) : ''}
          onChange={value => {
            onUpdateFilingType({
              ...updatedFilingType,
              publicationDateSettings: {
                ...updatedFilingType.publicationDateSettings,
                initialPublicationDates: value ? Number(value) : null
              }
            });
          }}
          errorText={(() => {
            if (!isInitialPublicationDatesWithinLowerLimit) {
              return 'Default number of publication dates cannot be less than the minimum number of publication dates.';
            }

            if (!isInitialPublicationDatesWithinUpperLimit) {
              return 'Default number of publication dates cannot be greater than the maximum number of publication dates.';
            }
          })()}
        />
      </GridInput>
      <TextField
        id="minimum-publication-dates"
        labelText="Minimum number of publication dates"
        type="number"
        min={1}
        placeholder="1"
        value={minimumPublicationDates ? String(minimumPublicationDates) : ''}
        onChange={value => {
          onUpdateFilingType({
            ...updatedFilingType,
            publicationDateSettings: {
              ...updatedFilingType.publicationDateSettings,
              minimumPublicationDates: value ? Number(value) : null
            }
          });
        }}
        errorText={(() => {
          if (!isMinimumPublicationDatesWithinUpperLimit) {
            return 'Minimum number of publication dates cannot be greater than the maximum number of publication dates.';
          }

          if (!isInitialPublicationDatesWithinLowerLimit) {
            return 'Minimum number of publication dates cannot be greater than the default number of publication dates.';
          }
        })()}
      />
      <TextField
        id="maximum-publication-dates"
        labelText="Maximum number of publication dates"
        placeholder="Unlimited"
        type="number"
        min={1}
        value={maximumPublicationDates ? String(maximumPublicationDates) : ''}
        onChange={value => {
          onUpdateFilingType({
            ...updatedFilingType,
            publicationDateSettings: {
              ...updatedFilingType.publicationDateSettings,
              maximumPublicationDates: value ? Number(value) : null
            }
          });
        }}
        errorText={(() => {
          if (!isMinimumPublicationDatesWithinUpperLimit) {
            return 'Maximum number of publication dates cannot be less than the minimum number of publication dates.';
          }

          if (!isInitialPublicationDatesWithinUpperLimit) {
            return 'Maximum number of publication dates cannot be less than the default number of publication dates.';
          }
        })()}
      />
    </CardGridLayout>
  );
}

export default OrderFilingTypePublicationDatesCard;
