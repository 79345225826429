import React, { useEffect, useState } from 'react';

import { EOrganization, ESnapshotExists, EUser, exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import Firebase from 'EnoticeFirebase';
import AppLayoutHeader from './AppLayoutHeader';

type AppLayoutFrameProps = {
  children: React.ReactNode;
};

export default function AppLayoutFrame({ children }: AppLayoutFrameProps) {
  const [user, setUser] = useState<ESnapshotExists<EUser> | null>(null);
  const [activeOrganization, setActiveOrganization] =
    useState<ESnapshotExists<EOrganization> | null>(null);

  /**
   * Listen for auth state changes and update the user state accordingly.
   */
  useEffect(() => {
    const authSubscription = Firebase.auth().onAuthStateChanged(
      async userAuth => {
        const userID = userAuth?.uid;
        if (userID) {
          const userSnapshot = await getFirebaseContext()
            .usersRef()
            .doc(userID)
            .get();
          if (exists(userSnapshot)) {
            setUser(userSnapshot);
            const activeOrganizationSnapshot =
              (await userSnapshot.data()?.activeOrganization?.get()) || null;
            if (exists(activeOrganizationSnapshot)) {
              setActiveOrganization(activeOrganizationSnapshot);
            }
          }
        } else {
          setUser(null);
          setActiveOrganization(null);
        }
      }
    );
    return () => authSubscription();
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <AppLayoutHeader activeOrganization={activeOrganization} user={user} />
      <main className="bg-column-gray-25 flex-1 w-screen overflow-auto">
        {children}
      </main>
    </div>
  );
}
