import moment from 'moment';
import { FeeSplit, isFeeSplitTypeFullWaiver } from '../types/feeSplit';
import {
  FirebaseTimestamp,
  MailDelivery,
  EFirebaseContext,
  ESnapshotExists,
  EOrganization,
  ENotice,
  exists
} from '../types';
import { getMailDataFromNoticeOrDraft } from '../mail';
import {
  getNoticeTypeFromNoticeData,
  sortNoticePublicationDatesChronologically
} from '../helpers';
import { getCustomer, getCustomerOrganization } from '../notice/customer';
import { AffidavitReconciliationSettings } from '../types/organization';
import { AffidavitPricingData } from '.';
import { getOrThrow } from '../utils/refs';
import { AAPendingQueryNotice } from '../affidavits/types';
import { getModelFromSnapshot } from '../model';
import { UserNoticeModel } from '../model/objects/userNoticeModel';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from '../services/directory';

const DEFAULT_AFFIDAVIT_FEE_IN_CENTS = 0;

/**
 * Returns the reconciliation settings affiliated with the newspaper. Goes in precendence
 * order of:
 * 1. What is set on the newspaper (if anything)
 * 2. What is set on the parent (if anything)
 * 3. Returns undefined if no settings on either
 */
export const getAffidavitSettingsForNewspaper = async (
  newspaperSnap: ESnapshotExists<EOrganization> | undefined
): Promise<AffidavitReconciliationSettings | undefined> => {
  if (!exists(newspaperSnap)) return undefined;
  if (newspaperSnap.data()?.affidavitReconciliationSettings) {
    return newspaperSnap.data()?.affidavitReconciliationSettings || undefined;
  }

  const parentSnap = await newspaperSnap.data()?.parent?.get();
  if (parentSnap?.data()?.affidavitReconciliationSettings) {
    return parentSnap?.data()?.affidavitReconciliationSettings || undefined;
  }

  return undefined;
};

/**
 * Determines the affidavit reconciliation settings for notice data. This can be used
 * in the placement flow before we have a full notice object.
 * @param {EFirebaseContext} ctx context object
 * @param {ENotice} noticeData notice data
 * @returns {Promise<AffidavitReconciliationSettings | undefined>} affidavit reconciliation settings
 */
export type NoticeWithAffidavitData = Partial<
  Pick<
    ENotice,
    | 'newspaper'
    | 'affidavitReconciliationSettings'
    | 'filer'
    | 'previousNoticeType'
    | 'filedBy'
  >
>;
export const getAffidavitSettingsForNoticeData = async (
  ctx: EFirebaseContext,
  noticeData: NoticeWithAffidavitData,
  options?: {
    /**
     * If true, we pull only settings from the newspaper (plus notice type or customer-specific
     * settings), ignoring any notice-level settings. This is useful when a notice gets invoiced
     * for the second time, and needs to have its settings refreshed.
     */
    skipNoticeLevelSettings?: boolean;
  }
): Promise<AffidavitReconciliationSettings | undefined> => {
  const newspaper = await getOrThrow(noticeData.newspaper);
  const newspaperLevelAffidavitSettings =
    await getAffidavitSettingsForNewspaper(newspaper);

  // If the newspaper doesn't do anything with verification, don't do anything!
  if (!newspaperLevelAffidavitSettings) return;

  // Allow for notice-specific settings for affidavit reconciliation
  const noticeLevelAffidavitSettings =
    noticeData.affidavitReconciliationSettings || {};

  // Allow for customer-specific settings to override newspaper settings for affidavit reconciliation
  let customerLevelAffidavitSettings = {};
  const filer = await noticeData.filer?.get();
  if (exists(filer)) {
    const customerSnap = await getCustomer(ctx, filer, newspaper);
    customerLevelAffidavitSettings =
      customerSnap?.data().affidavitReconciliationSettings || {};
  }

  // Allow for customer organization-specific settings to override newspaper settings for affidavit reconciliation
  let customerOrganizationLevelAffidavitSettings = {};
  const filerOrg = await noticeData.filedBy?.get();
  if (exists(filerOrg)) {
    const customerSnap = await getCustomerOrganization(
      ctx,
      filerOrg,
      newspaper
    );
    customerOrganizationLevelAffidavitSettings =
      customerSnap?.data().affidavitReconciliationSettings || {};
  }

  // Allow for notice-type-specific settings
  const noticeType = await getNoticeTypeFromNoticeData(noticeData, newspaper);
  const noticeTypeLevelAffidavitSettings =
    noticeType?.affidavitReconciliationSettings || {};

  return {
    ...newspaperLevelAffidavitSettings,
    ...noticeTypeLevelAffidavitSettings,
    ...customerOrganizationLevelAffidavitSettings,
    ...customerLevelAffidavitSettings,
    ...(options?.skipNoticeLevelSettings ? {} : noticeLevelAffidavitSettings)
  };
};

/**
 * Returns the reconciliation settings affiliated with the notice. Goes in precendence
 * order of:
 * 1. What is set on the notice (if anything)
 * 2. What is set on the customer (if anything)
 * 3. What is set on the notice type (if anything)
 * 4. What is set on the publisher
 */
export const getAffidavitSettingsForNotice = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<AAPendingQueryNotice>,
  options?: {
    skipNoticeLevelSettings: boolean;
  }
): Promise<AffidavitReconciliationSettings | undefined> => {
  return getAffidavitSettingsForNoticeData(ctx, notice.data(), options);
};

/**
 * Notices may have an affidavit fee as well as per-mailed-affidavit fees.
 */
export const getAffidavitPricingData = async (
  ctx: EFirebaseContext,
  notice: ESnapshotExists<ENotice>
): Promise<AffidavitPricingData> => {
  const settings = await getAffidavitSettingsForNotice(ctx, notice);
  const mail = await getMailDataFromNoticeOrDraft(notice.ref);
  const noticeModel = getModelFromSnapshot(UserNoticeModel, ctx, notice);
  const requiresAffidavit = await noticeModel.areAffidavitsEnabled();

  return {
    settings,
    mail,
    requiresAffidavit
  };
};

const getAffidavitFeeIsNumber = (
  affidavitFeeInCents: number | undefined
): affidavitFeeInCents is number => {
  return (
    typeof affidavitFeeInCents === 'number' &&
    !Number.isNaN(affidavitFeeInCents)
  );
};

export const applyAffidavitFeeSplitToColumnAffidavitFeeInCents = (
  grossColumnAffidavitFeeInCents: number,
  feeSplit: FeeSplit | undefined
) => {
  if (!feeSplit || !feeSplit.type) {
    return {
      columnAffidavitFeeInCents: grossColumnAffidavitFeeInCents,
      feeSplit: 0
    };
  }

  const splitType = feeSplit.type;
  if (isFeeSplitTypeFullWaiver(feeSplit)) {
    return {
      columnAffidavitFeeInCents: 0,
      feeSplit: 0,
      amountWaived: grossColumnAffidavitFeeInCents
    };
  }

  const splitAmount = feeSplit.amount;

  if (splitAmount && typeof splitAmount !== 'number') {
    throw new Error(
      `Invalid split amount type: feeSplit is a ${typeof splitAmount} and should be a number`
    );
  }

  if (splitType === 'flat') {
    const columnAffidavitFeeInCents = Math.max(
      0,
      grossColumnAffidavitFeeInCents - splitAmount
    );
    return {
      columnAffidavitFeeInCents,
      feeSplit: grossColumnAffidavitFeeInCents - columnAffidavitFeeInCents
    };
  }

  if (splitType === 'percent') {
    const splitCap = feeSplit.cap;
    const splitAmountInCents = Math.round(
      grossColumnAffidavitFeeInCents * (splitAmount / 100)
    );
    if (splitCap && splitAmountInCents > splitCap) {
      const columnAffidavitFeeInCents = Math.max(
        0,
        grossColumnAffidavitFeeInCents - splitCap
      );
      return {
        columnAffidavitFeeInCents,
        feeSplit: grossColumnAffidavitFeeInCents - columnAffidavitFeeInCents
      };
    }
    const columnAffidavitFeeInCents = Math.max(
      0,
      grossColumnAffidavitFeeInCents - splitAmountInCents
    );
    return {
      columnAffidavitFeeInCents,
      feeSplit: grossColumnAffidavitFeeInCents - columnAffidavitFeeInCents
    };
  }

  return {
    columnAffidavitFeeInCents: grossColumnAffidavitFeeInCents,
    feeSplit: 0
  };
};

export const getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail = (
  reconciliationSettings: Partial<AffidavitReconciliationSettings> | undefined,
  mail: MailDelivery[]
) => {
  const {
    affidavitsManagedByColumn,
    automatedAffidavitFeeInCents,
    notarizationVendor,
    affidavitFeeSplit
  } = reconciliationSettings || {};

  const hasSpecifiedFee = getAffidavitFeeIsNumber(automatedAffidavitFeeInCents);
  const unitFee = hasSpecifiedFee
    ? automatedAffidavitFeeInCents
    : DEFAULT_AFFIDAVIT_FEE_IN_CENTS;

  if (affidavitsManagedByColumn) {
    // If we are manually sending affidavits, we charge per copy. Otherwise just charge once!
    const unitQuantity =
      notarizationVendor === 'manual'
        ? (mail || []).reduce((a, m) => a + (m.copies || 0), 0)
        : 1;

    // apply feeSplit if exists to column affidavit fee in cents
    let finalColumnAffidavitUnitFeeInCents = unitFee;
    let feeSplitAmountInCents = 0;
    let amountWaived;

    if (affidavitFeeSplit?.feeSplit && affidavitFeeSplit?.feeSplit.type) {
      const affidavitFeeWithFeeSplitAppliedResult =
        applyAffidavitFeeSplitToColumnAffidavitFeeInCents(
          unitFee,
          affidavitFeeSplit?.feeSplit
        );

      finalColumnAffidavitUnitFeeInCents =
        affidavitFeeWithFeeSplitAppliedResult.columnAffidavitFeeInCents;
      feeSplitAmountInCents = affidavitFeeWithFeeSplitAppliedResult.feeSplit;
      amountWaived = affidavitFeeWithFeeSplitAppliedResult.amountWaived;
    }

    const invoiceTrackedAffidavitFeeSplitResult = affidavitFeeSplit?.feeSplit
      ? {
          feeSplit: affidavitFeeSplit?.feeSplit,
          amountInCents: feeSplitAmountInCents,
          ...(amountWaived && { amountWaived })
        }
      : undefined;

    return {
      affidavitFeeInCents: unitQuantity * finalColumnAffidavitUnitFeeInCents,
      invoiceTrackedAffidavitFeeSplitResult
    };
  }

  return {
    affidavitFeeInCents: 0,
    invoiceTrackedAffidavitFeeSplitResult: undefined
  };
};

export const getAffidavitFeeInCentsFromSettingsAndMail = (
  reconciliationSettings: Partial<AffidavitReconciliationSettings> | undefined,
  mail: MailDelivery[]
): number => {
  const { affidavitFeeInCents } =
    getAffidavitFeeInCentsAndFeeSplitFromSettingsAndMail(
      reconciliationSettings,
      mail
    );
  return affidavitFeeInCents;
};

export const getAffidavitFeeInCentsForNoticeData = async (
  ctx: EFirebaseContext,
  noticeData: NoticeWithAffidavitData,
  mail: MailDelivery[]
): Promise<number> => {
  const affidavitSettings = await getAffidavitSettingsForNoticeData(
    ctx,
    noticeData
  );
  return getAffidavitFeeInCentsFromSettingsAndMail(affidavitSettings, mail);
};

export const getAffidavitFeeInCentsForNotice = async (
  ctx: EFirebaseContext,
  noticeSnap: ESnapshotExists<ENotice>
): Promise<number> => {
  const mail = await getMailDataFromNoticeOrDraft(noticeSnap.ref);
  return getAffidavitFeeInCentsForNoticeData(ctx, noticeSnap.data(), mail);
};

export const getHasAffidavitServiceStartedByLastPubDate = (
  reconciliationStartDate: FirebaseTimestamp,
  noticePublicationDates: ENotice['publicationDates']
) => {
  const sortedPublicationDates = sortNoticePublicationDatesChronologically(
    noticePublicationDates
  );
  const lastPublicationDate =
    sortedPublicationDates[sortedPublicationDates.length - 1].toDate();

  return moment(lastPublicationDate)
    .startOf('day')
    .isSameOrAfter(moment(reconciliationStartDate.toDate()).startOf('day'));
};

export const getShouldApplyColumnManagedAffidavitFees = (
  affidavitReconciliationSettings: AffidavitReconciliationSettings | undefined,
  noticePublicationDates: ENotice['publicationDates'] | undefined,
  requiresAffidavit: boolean
) => {
  if (!affidavitReconciliationSettings) {
    return false;
  }

  if (!affidavitReconciliationSettings.affidavitsManagedByColumn) {
    return false;
  }

  /**
   * TODO(column-pro): This is a bad settings configuration; I'm only logging it for now, but we may want to add a roadmap item for preventing this from happening in the future
   */
  if (!requiresAffidavit) {
    getErrorReporter().logWarn(
      'Notice has affidavits managed by Column but does not require affidavits',
      {
        service: ColumnService.AFFIDAVITS,
        managedAffidavitTemplateId:
          affidavitReconciliationSettings.managedAffidavitTemplate?.id ?? 'N/A',
        managedAffidavitTemplateStoragePath:
          affidavitReconciliationSettings.managedAffidavitTemplateStoragePath ??
          'N/A'
      }
    );
    return false;
  }

  if (!noticePublicationDates?.length) {
    return false;
  }

  const { reconciliationStartDate } = affidavitReconciliationSettings;

  /* If we have ARS but don't specific a reconciliationStartDate, we assume the fee applies to
  new invoices created regardless of last publication date */

  if (!reconciliationStartDate || !reconciliationStartDate.toDate()) {
    return true;
  }

  return getHasAffidavitServiceStartedByLastPubDate(
    reconciliationStartDate,
    noticePublicationDates
  );
};
