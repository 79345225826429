import React, { useState } from 'react';
import { OrderModel } from 'lib/model/objects/orderModel';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations } from 'redux/auth';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import LoadingState from 'components/LoadingState';
import { ColumnButton } from 'lib/components/ColumnButton';
import { capitalize, isError } from 'lodash';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid';
import { cdnIfy } from 'lib/helpers';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { Alert } from 'lib/components/Alert';

type PreviewTabProps = {
  order: OrderModel;
};

type PublisherAndProofData = {
  publisher: OrganizationModel;
  pdfStoragePath: string | undefined;
  newspaperOrder: NewspaperOrderModel;
};

export default function PreviewTab({ order }: PreviewTabProps) {
  const availableOrganizations = useAppSelector(selectAvailableOrganizations);
  const [activeProofIndex, setActiveProofIndex] = useState(0);

  const { value: publishersAndProofs, isLoading } = useAsyncEffect({
    fetchData: async () => {
      const newspaperOrders = await order.getNewspaperOrders();
      const relevantNewspaperOrders = newspaperOrders.filter(newspaperOrder =>
        availableOrganizations.find(
          organization =>
            organization.id === newspaperOrder.modelData.newspaper.id
        )
      );
      const publishersAndProofs = await Promise.all(
        relevantNewspaperOrders.map(async newspaperOrder => {
          const [publisherError, publisher] =
            await newspaperOrder.getNewspaper();
          if (publisherError) {
            return wrapError(publisherError);
          }

          return wrapSuccess({
            publisher,
            pdfStoragePath: newspaperOrder.modelData.pdfStoragePath,
            newspaperOrder
          });
        })
      );

      const successfullyLoadedPublishersAndProofs = publishersAndProofs
        .filter(publisherAndProof => !isError(publisherAndProof))
        .map(
          publisherAndProof => publisherAndProof.response
        ) as PublisherAndProofData[];

      setActiveProofIndex(0);
      return successfullyLoadedPublishersAndProofs;
    },
    dependencies: [order?.id]
  });

  if (isLoading) {
    return <LoadingState />;
  }

  const activePublisherAndProof = publishersAndProofs?.[activeProofIndex];
  if (!activePublisherAndProof) {
    return <div className="p-8">No publishers and proofs found</div>;
  }

  const { publisher, pdfStoragePath, newspaperOrder } = activePublisherAndProof;

  return (
    <div className="flex flex-col gap-4 px-8 py-4">
      <Alert
        id="preview-warning"
        status="warning"
        title="Preview tab in testing, available to Column users only."
      />
      <div className="flex items-center justify-between">
        <ColumnButton
          onClick={() => {
            setActiveProofIndex(activeProofIndex - 1);
          }}
          disabled={!publishersAndProofs || activeProofIndex === 0}
          buttonText={<ArrowLeftIcon className="w-4 h-4" />}
          type="button"
        />
        <div className="font-medium text-center max-w-64">
          {publisher.modelData.name}{' '}
          {capitalize(newspaperOrder.modelData.publishingMedium)}
        </div>
        <ColumnButton
          onClick={() => {
            setActiveProofIndex(activeProofIndex + 1);
          }}
          buttonText={<ArrowRightIcon className="w-4 h-4" />}
          disabled={
            !publishersAndProofs ||
            activeProofIndex === (publishersAndProofs?.length ?? 0) - 1
          }
          type="button"
        />
      </div>

      {pdfStoragePath && (
        <div>
          <img
            className="w-full h-full object-contain"
            src={cdnIfy(pdfStoragePath, {
              useImgix: true,
              imgixTransformations: { fm: 'png', dpr: '2' }
            })}
            alt="Preview"
          />
        </div>
      )}
      {
        <div className="text-sm flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              <div className="text-column-gray-400">Words</div>
              <div>{newspaperOrder.modelData.displayParams?.words ?? ''}</div>
            </div>
            <div className="flex justify-between">
              <div className="text-column-gray-400">Lines</div>
              <div>{newspaperOrder.modelData.displayParams?.lines ?? ''}</div>
            </div>
          </div>
        </div>
      }
      {pdfStoragePath && (
        <div className="flex justify-between">
          <ColumnButton
            buttonText="Download PDF"
            onClick={() => {
              window.open(cdnIfy(pdfStoragePath), '_blank');
            }}
            type="button"
            fullWidth
            secondary
          />
        </div>
      )}
    </div>
  );
}
