import React, { useEffect, useState } from 'react';
import {
  Customer,
  EOrganization,
  ERate,
  ESnapshot,
  ESnapshotExists,
  EUser,
  exists
} from 'lib/types';
import { getDisplayName, removeUndefinedFields } from 'lib/helpers';
import ToastActions from 'redux/toast';
import {
  getCustomer,
  getOrCreateCustomer,
  getOrCreateCustomerOrganization
} from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import * as EmailValidator from 'email-validator';
import { logAndCaptureException } from 'utils';
import { getUserByEmail, createOrUpdateAnonymousFiler } from 'utils/users';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { BillingTermType } from 'lib/enums';
import HorizontalDivider from '../HorizontalDivider';
import CreateOrEditModal from './CreateOrEditModal';
import {
  CreateOrEditModalDetails,
  CustomerObjDataFields,
  NewCustomerAlertType
} from './CreateOrEditModalDetails';
import { CreateOrEditCustomerModalSettings } from './CreateOrEditCustomerModalSettings';

type CreateCustomerModalProps = {
  activeOrganization: ESnapshot<EOrganization>;
  closeModal: () => void;
  rates?: ESnapshotExists<ERate>[];
};

function CreateCustomerModal({
  activeOrganization,
  closeModal,
  rates
}: CreateCustomerModalProps) {
  const dispatch = useAppDispatch();
  /**
   * Intiailize fields to defaults.
   */
  const initialCustomerData: CustomerObjDataFields = {
    billingTerm: BillingTermType.default.value,
    linerRateSnap: rates?.find(
      rate => rate.id === activeOrganization.data()?.defaultLinerRate?.id
    ),
    displayRateSnap: rates?.find(
      rate => rate.id === activeOrganization.data()?.defaultDisplayRate?.id
    ),
    enableAffidavitsBeforePayment: false,
    allowAffidavitEmail: false,
    organization: activeOrganization.ref,
    organizationsToAdd: []
  };
  const [updatedCustomerData, setUpdatedCustomerData] =
    useState<CustomerObjDataFields>(initialCustomerData);
  const [user, setUser] = useState<ESnapshot<EUser> | null>(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState<NewCustomerAlertType>();

  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (!updatedCustomerData.email || !updatedCustomerData.firstName) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [updatedCustomerData]);

  /**
   *  On valid email update, attempt to find an existing user for that email.
   *  If found, check for an existing customer<newspaper, user>.
   *  Update all form fields from that user.
   */
  const handleEmailChange = async (email: string) => {
    setAlert(undefined);
    const validEmail = EmailValidator.validate(email);
    if (email && validEmail) {
      const existingUser = await getUserByEmail(email);

      setUser(existingUser);
      let existingCustomer: ESnapshotExists<Customer> | null = null;
      if (exists(existingUser)) {
        existingCustomer = await getCustomer(
          getFirebaseContext(),
          existingUser,
          activeOrganization
        );
        if (existingCustomer) {
          if (existingCustomer.data().archived) {
            setAlert('deletedCustomer');
          } else {
            setAlert('existingCustomer');
          }
        } else if (existingUser.data().anonymous) {
          setAlert('existingAnonymousUser');
        } else {
          setAlert('existingUser');
        }
      } else {
        setAlert('none');
      }
      /**
       * Auto-fill info from the existing customer or user
       */
      const customerData = existingCustomer?.data();
      const userData = existingUser?.data();
      setUpdatedCustomerData({
        ...updatedCustomerData,
        firstName: customerData?.firstName || userData?.firstName,
        lastName: customerData?.lastName || userData?.lastName,
        address: customerData?.address || userData?.address,
        addressLine2: customerData?.addressLine2 || userData?.addressLine2,
        city: customerData?.city || userData?.city,
        state: customerData?.state || userData?.state,
        zipCode: customerData?.zipCode || userData?.zipCode,
        phone: customerData?.phone || userData?.phone,
        internalID: customerData?.internalID,
        organizationName: customerData?.organizationName,
        enableAffidavitsBeforePayment:
          customerData?.enableAffidavitsBeforePayment,
        allowAffidavitEmail: customerData?.allowAffidavitEmail,
        billingTerm: customerData?.billingTerm,
        linerRateSnap:
          rates?.find(rate => rate.id === customerData?.linerRate?.id) ||
          rates?.find(
            rate => rate.id === activeOrganization.data()?.defaultLinerRate?.id
          ),
        displayRateSnap:
          rates?.find(rate => rate.id === customerData?.displayRate?.id) ||
          rates?.find(
            rate =>
              rate.id === activeOrganization.data()?.defaultDisplayRate?.id
          ),
        customerOrgAddOptions: userData?.allowedOrganizations
      });
    }
  };

  const debouncedEmail = useDebounce(updatedCustomerData.email, 500);
  useEffect(() => {
    void handleEmailChange(updatedCustomerData.email || '');
  }, [debouncedEmail]);

  /**
   * If there are no pending invites to this email, send an individual
   * invite and register them as an anonymous user.
   */
  const createUser = async (
    newCustomerInfo: Partial<Customer>,
    options: { invitedBy: ESnapshot<EOrganization> }
  ) => {
    if (!updatedCustomerData.email) {
      throw new Error('Cannot create a user without a valid email');
    }

    const email = updatedCustomerData.email.toLowerCase();
    const newFilerInfo = {
      ...newCustomerInfo,
      email
    } as Partial<EUser>;

    const newFiler = await createOrUpdateAnonymousFiler(
      activeOrganization,
      newFilerInfo,
      {
        invitedBy: options.invitedBy.ref
      }
    );
    return newFiler.get();
  };

  /**
   * On saving the form, create the new customer.
   * If no user was found with this email, first create the user.
   */
  const onSave = async () => {
    setLoading(true);
    let newUser = user;
    let newCustomer;
    /**
     * Convert CustomerDataFields to an object that more closely resembles the
     * Customer type
     */
    const updates: Partial<Customer> = {
      firstName: updatedCustomerData.firstName,
      lastName: updatedCustomerData.lastName,
      address: updatedCustomerData.address,
      addressLine2: updatedCustomerData.addressLine2,
      city: updatedCustomerData.city,
      state: updatedCustomerData.state,
      zipCode: updatedCustomerData.zipCode,
      phone: updatedCustomerData.phone,
      internalID: updatedCustomerData.internalID,
      organizationName: updatedCustomerData.organizationName,
      enableAffidavitsBeforePayment:
        updatedCustomerData.enableAffidavitsBeforePayment,
      allowAffidavitEmail: updatedCustomerData.allowAffidavitEmail,
      billingTerm: updatedCustomerData.billingTerm,
      bulkPaymentEnabled_v2: updatedCustomerData.bulkPaymentEnabled_v2,
      defaultColumns: updatedCustomerData.defaultColumns,
      defaultNoticeType: updatedCustomerData.defaultNoticeType,
      /**
       * Only set the rates if they have been edited from the org's defaults
       */
      linerRate:
        updatedCustomerData.linerRateSnap?.id ===
        activeOrganization.data()?.defaultLinerRate?.id
          ? undefined
          : updatedCustomerData.linerRateSnap?.ref,
      displayRate:
        updatedCustomerData.displayRateSnap?.id ===
        activeOrganization.data()?.defaultDisplayRate?.id
          ? undefined
          : updatedCustomerData.displayRateSnap?.ref,
      discountConfig:
        updatedCustomerData.discountConfig?.subtotal?.amount === 0
          ? {}
          : updatedCustomerData.discountConfig,
      archived: false
    };
    removeUndefinedFields(updates);
    try {
      if (!newUser || newUser.data()?.anonymous) {
        newUser = await createUser(updates, { invitedBy: activeOrganization });
        setUser(newUser);
      }
      if (!newUser) throw new Error('Failed to create user');
      newCustomer = await getOrCreateCustomer(
        getFirebaseContext(),
        newUser,
        activeOrganization,
        updates
      );
      if (!newCustomer) throw new Error('Failed to create customer');
      if (updatedCustomerData.organizationsToAdd?.length) {
        await Promise.all(
          updatedCustomerData.organizationsToAdd.map(async org => {
            const orgSnapshot = await org.get();
            await getOrCreateCustomerOrganization(
              getFirebaseContext(),
              orgSnapshot,
              activeOrganization
            );
          })
        );
      }
    } catch (e) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        e,
        'Failure in new customer modal.'
      );
      setLoading(false);
      return;
    }
    setLoading(false);
    closeModal();
    dispatch(
      ToastActions.toastSuccess({
        headerText: `Customer added 🎉`,
        bodyText: `${getDisplayName(
          newCustomer.data().firstName,
          newCustomer.data().lastName || ''
        )} has been added to your customer list. Click on the customer to view more details.`
      })
    );
  };

  const onClose = () => {
    closeModal();
  };

  const formId = 'create-customer-modal-form';
  return (
    <CreateOrEditModal
      headerText="New"
      buttonText="Create customer"
      onClose={onClose}
      loading={loading}
      onSubmit={onSave}
      alert={alert}
      disableSave={disableSave}
      id={formId}
    >
      <CreateOrEditModalDetails
        value={updatedCustomerData}
        onChange={data => {
          setUpdatedCustomerData(data);
        }}
        activeOrganization={activeOrganization}
        alert={alert}
        isNewCustomer
      />
      <div className="py-6 max-w-4xl m-auto">
        <HorizontalDivider />
      </div>
      <CreateOrEditCustomerModalSettings
        value={updatedCustomerData}
        onChange={setUpdatedCustomerData}
        activeOrganization={activeOrganization}
        rates={rates}
        advertiser={user?.data() || null}
      />
    </CreateOrEditModal>
  );
}

export default CreateCustomerModal;
