import { ERef, ESnapshotExists, EUser } from 'lib/types';
import { Order } from 'lib/types/order';
import { removeUndefinedFields } from 'lib/helpers';
import { getFirebaseContext } from 'utils/firebase';
import { Classified } from 'lib/types/classified';
import { OrderModel } from 'lib/model/objects/orderModel';
import { ClassifiedModel } from 'lib/model/objects/classifiedModel';
import { ClassifiedService } from 'lib/services/classifiedService';
import { isClassifiedModel } from 'lib/model/objects/adModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';
import { getModelFromRef } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { Product } from 'lib/enums';
import IPlacementFlowHelper from '.';
import { MINIMUM_ORDER } from '../../steps/PersonalDetail';

class ClassifiedPlacementFlowHelper
  implements IPlacementFlowHelper<Classified, 'classifieds'>
{
  private context = getFirebaseContext();

  private classifiedService = new ClassifiedService(this.context);

  async createAd(
    user: ESnapshotExists<EUser>
  ): Promise<ResponseOrError<ERef<Order>, Error>> {
    let activeOrganizationModel: OrganizationModel | undefined;
    const orgRef = user.data().activeOrganization;
    if (orgRef) {
      activeOrganizationModel = await getModelFromRef(
        OrganizationModel,
        getFirebaseContext(),
        orgRef
      );
    }

    const isPublisherOrganization =
      !!activeOrganizationModel?.isPublisherOrganization;
    const orgSupportsClassifiedPlacement =
      !!activeOrganizationModel?.hasAdTypeActive(Product.Classified);

    const shouldSetAdvertiserInfo =
      !activeOrganizationModel ||
      (!isPublisherOrganization && orgSupportsClassifiedPlacement);

    const extraInfo = shouldSetAdvertiserInfo
      ? {
          advertiserOrganization: activeOrganizationModel
            ? activeOrganizationModel.ref
            : null,
          advertiser: user.ref
        }
      : { ...MINIMUM_ORDER };

    const classifiedModel = await this.classifiedService.create(
      removeUndefinedFields({
        user: user.ref,
        authorizedOrganization: activeOrganizationModel
          ? activeOrganizationModel.ref
          : null,
        ...extraInfo
      })
    );

    return wrapSuccess(classifiedModel.modelData.order);
  }

  async getAdModel(
    orderModel: OrderModel
  ): Promise<ResponseOrError<ClassifiedModel>> {
    const adResult = await orderModel.getAdByVersion();

    if (adResult.error) {
      return adResult;
    }

    const adModel = adResult.response;

    if (!isClassifiedModel(adModel)) {
      const errorMessage = 'Not a classified';

      const error = Error(errorMessage);

      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        error,
        errorMessage,
        { orderId: orderModel.id }
      );

      return wrapError(error);
    }

    return wrapSuccess(adModel);
  }
}

export default ClassifiedPlacementFlowHelper;
