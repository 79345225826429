import React, { useEffect, useState } from 'react';

import { logAndCaptureException } from 'utils';
import { removeUndefinedFields } from 'lib/helpers';
import { NoticeStatusType } from 'lib/enums';
import { isPublisher } from 'lib/utils/users';
import Firebase from 'EnoticeFirebase';
import api from 'api';
import {
  ESnapshotExists,
  EOrganization,
  EInvoice,
  ENotice,
  exists,
  EUser
} from 'lib/types';

import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import AffidavitActionSectionHeader from './AffidavitActionSectionHeader';
import AffidavitDownloadSection from './AffidavitDownloadSection';
import ReplaceAffidavitModal from './ReplaceAffidavitModal';
import UploadConfirmation from './UploadConfirmation';
import PayInvoiceModal from './PayInvoiceModal';
import MailTable from './MailTable';
import AdDetailsCard from '../AdDetailsCard';

type AffidavitActionsProps = {
  alwaysAllowAffidavitDownload: boolean;
  invoiceSnap: ESnapshotExists<EInvoice> | undefined;
  newspaper: ESnapshotExists<EOrganization>;
  notice: ESnapshotExists<ENotice>;
  user: ESnapshotExists<EUser>;
};

export default function AffidavitActions({
  alwaysAllowAffidavitDownload,
  invoiceSnap,
  newspaper,
  notice,
  user
}: AffidavitActionsProps) {
  const [showReplaceAffidavitModal, setShowReplaceAffidavitModal] =
    useState(false);
  const [showPayInvoiceModal, setShowPayInvoiceModal] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [affidavitUpdates, setAffidavitUpdates] = useState<Partial<ENotice>>(
    {}
  );
  const [affidavitURL, setAffidavitURL] = useState(notice.data().affidavitURL);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const updateAffidavitValuesOnNotice = async () => {
    await notice.ref.update(affidavitUpdates);
    const updated = await notice.ref.get();
    if (exists(updated)) {
      await setPersistentAffidavitURL(updated);
    }
    setShowUploadFileModal(false);
  };

  const setPersistentAffidavitURL = async (
    updated: ESnapshotExists<ENotice>
  ) => {
    const url = await Firebase.storage()
      .ref(updated.data().affidavit)
      .getDownloadURL();

    await notice.ref.update({
      affidavitURL: url
    });

    setAffidavitURL(url);
  };

  const setPersistentGeneratedAffidavitURL = async (
    notice: ESnapshotExists<ENotice>
  ) => {
    if (
      notice.data().generatedAffidavitURL ||
      !notice.data().generatedAffidavitStoragePath
    ) {
      return;
    }

    const url = await Firebase.storage()
      .ref(notice.data().generatedAffidavitStoragePath!)
      .getDownloadURL();
    await notice.ref.update({
      generatedAffidavitURL: url
    });
  };

  const uploadFile = async (file: File) => {
    try {
      setLoading(true);
      const snapshot = await Firebase.storage()
        .ref()
        .child(`affidavits/${notice.id}/${file.name}`)
        .put(file);

      /**
       * We should never change the status of a cancelled notice upon affidavit upload.
       * If the notice still requires a transfer (e.g., in the event of a partial refund),
       * the affidavit upload should still trigger a transfer (see logic in onNoticeUpdate and createNoticeTransferIfNotExists)
       */
      const shouldNotUpdateNoticeStatus =
        notice.data().noticeStatus === NoticeStatusType.cancelled.value;

      const firstTimeAffidavitUploadedToNotice =
        !notice.data().affidavitFirstUploadedAt;

      setAffidavitUpdates(
        removeUndefinedFields({
          affidavit: snapshot.ref.fullPath,
          noticeStatus: shouldNotUpdateNoticeStatus
            ? undefined
            : NoticeStatusType.affidavit_submitted.value,
          affidavitUploadedBy: user.ref,
          ...(firstTimeAffidavitUploadedToNotice
            ? {
                affidavitFirstUploadedAt: getFirebaseContext()
                  .fieldValue()
                  .serverTimestamp()
              }
            : {}),
          affidavitLastUploadedAt: getFirebaseContext()
            .fieldValue()
            .serverTimestamp()
        }) as ENotice
      );

      setFileName(snapshot.ref.fullPath);
      setAffidavitURL(await snapshot.ref.getDownloadURL());
      setShowUploadFileModal(true);
      setLoading(false);
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFIDAVITS,
        err,
        'Failed to upload affidavit file',
        {
          noticeId: notice.id
        }
      );
    }
  };

  useEffect(() => {
    const regenerateAffidavit = async () => {
      try {
        await api.post(`documents/${notice.id}/regenerate`, {
          docType: 'AFFIDAVIT'
        });
      } catch (e) {
        // This can happen sometimes if the invoice is removed from the notice
        // while the affidavit is regenerating.
        logAndCaptureException(
          ColumnService.AFFIDAVITS,
          e,
          'Failed to regenerate affidavit for notice',
          {
            noticeId: notice.id
          }
        );
      }
    };

    if (!exists(notice)) return;

    if (notice.data().invoice && !notice.data().generatedAffidavitStoragePath) {
      void regenerateAffidavit();
    }

    if (notice.data().affidavit && !notice.data().affidavitURL) {
      void setPersistentAffidavitURL(notice);
    }

    if (
      notice.data().generatedAffidavitStoragePath &&
      !notice.data().generatedAffidavitURL
    ) {
      void setPersistentGeneratedAffidavitURL(notice);
    }
  }, [notice?.id]);

  return (
    <>
      <AdDetailsCard
        id="affidavit-action"
        header={{
          title: 'Affidavit',
          actions: (
            <AffidavitActionSectionHeader
              alwaysAllowAffidavitDownload={alwaysAllowAffidavitDownload}
              uploadFile={uploadFile}
              newspaper={newspaper}
              invoice={invoiceSnap}
              loading={loading}
              notice={notice}
              user={user}
            />
          )
        }}
      >
        <div className="mb-6">
          <AffidavitDownloadSection
            setShowReplaceAffidavitModal={setShowReplaceAffidavitModal}
            setShowPayInvoiceModal={setShowPayInvoiceModal}
            affidavitURL={affidavitURL}
            isPublisher={isPublisher(user)}
            newspaper={newspaper}
            notice={notice}
          />
        </div>
        <MailTable
          isPublisher={isPublisher(user)}
          invoiceSnap={invoiceSnap}
          notice={notice}
        />
      </AdDetailsCard>

      {showReplaceAffidavitModal && (
        <ReplaceAffidavitModal
          saveNCloseModal={updateAffidavitValuesOnNotice}
          uploadFile={uploadFile}
          setOpen={setShowReplaceAffidavitModal}
          open={showReplaceAffidavitModal}
        />
      )}

      {showUploadFileModal && (
        <UploadConfirmation
          saveNCloseModal={updateAffidavitValuesOnNotice}
          downloadURL={affidavitURL}
          fileName={fileName}
          setOpen={setShowUploadFileModal}
          open={showUploadFileModal}
        />
      )}

      {showPayInvoiceModal && invoiceSnap && (
        <PayInvoiceModal
          setShowPayInvoiceModal={setShowPayInvoiceModal}
          newspaper={newspaper}
          invoice={invoiceSnap}
        />
      )}
    </>
  );
}
