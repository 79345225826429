import { FileType } from '../types/mime';

export const ALLOWED_FINALIZED_DISPLAY_TYPES = [
  FileType.JPG,
  FileType.PDF
] as const;

export type AllowedFinalizedDisplayType =
  (typeof ALLOWED_FINALIZED_DISPLAY_TYPES)[number];

export const isAllowedFinalizedDisplayType = (
  fileType: FileType | undefined
): fileType is AllowedFinalizedDisplayType => {
  if (!fileType) {
    return false;
  }
  return ALLOWED_FINALIZED_DISPLAY_TYPES.includes(fileType as any);
};
