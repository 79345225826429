import React from 'react';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Day, enumItemToSelectInput } from 'lib/enums';
import { Autocomplete } from 'lib/components/Autocomplete';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import classNames from 'classnames';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { publishingDayEnumValuesFromDeadlines } from 'lib/utils/deadlines';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import DeadlineOffsetForm from './DeadlineOffsetForm';

const PUBLICATION_DATE_OPTIONS = [
  {
    value: 'default',
    label: `Let customers select the number of publications`
  },
  {
    value: '1',
    label: 'One'
  },
  {
    value: '2',
    label: 'Two'
  },
  {
    value: '3',
    label: 'Three'
  },
  {
    value: '4',
    label: 'Four'
  },
  {
    value: '5',
    label: 'Five'
  },
  {
    value: '6',
    label: 'Six'
  }
];

type PublicationDatesCardProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onUpdateNoticeType: (newValue: CustomNoticeFilingType) => void;
  updatedNoticeType: CustomNoticeFilingType;
};

export default function PublicationDatesCard({
  activeOrganization,
  onUpdateNoticeType,
  updatedNoticeType
}: PublicationDatesCardProps) {
  const { deadlines } = activeOrganization.data();
  if (!deadlines) throw new Error('No deadlines found for newspaper');
  const publishingDays = publishingDayEnumValuesFromDeadlines(deadlines);

  const restrictedPublicationDaysOptions = Day.items()
    .filter(day => publishingDays.includes(day.value))
    .filter(
      day => !updatedNoticeType.restrictedPublicationDays?.includes(day.value)
    )
    .map(day => enumItemToSelectInput(day));

  return (
    <CardGridLayout
      header={{
        title: 'Publication Schedule',
        description:
          'Configure publication schedule requirements for this notice type.'
      }}
    >
      <GridInput fullWidth>
        <ColumnSelect
          id="notice-type-num-publications"
          labelText="Number of publication dates"
          options={PUBLICATION_DATE_OPTIONS}
          value={
            updatedNoticeType.requiredPublications?.toString() || 'default'
          }
          onChange={newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (newValue === 'default') {
              delete newNoticeType.requiredPublications;
            } else {
              newNoticeType.requiredPublications = parseInt(newValue, 10);
            }
            onUpdateNoticeType(newNoticeType);
          }}
        />
      </GridInput>
      {updatedNoticeType.requiredPublications && (
        <GridInput fullWidth>
          <LabeledSwitch
            value={updatedNoticeType.canAddBeyondRequired || false}
            onChange={newValue =>
              onUpdateNoticeType({
                ...updatedNoticeType,
                canAddBeyondRequired: newValue
              })
            }
            label="Can customers add more publication dates?"
            description={`If enabled, customers can schedule this notice type for more than ${
              updatedNoticeType.requiredPublications
            } publication date${
              updatedNoticeType.requiredPublications > 1 ? 's' : ''
            }.`}
          />
        </GridInput>
      )}
      <GridInput>
        <TextField
          labelText="Days between publication dates"
          step={1}
          min={1}
          max={100}
          type="number"
          placeholder="7"
          value={updatedNoticeType.defaultDaysBetweenPublication?.toString()}
          onChange={newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (newValue === '') {
              delete newNoticeType.defaultDaysBetweenPublication;
            } else {
              newNoticeType.defaultDaysBetweenPublication = parseInt(
                newValue,
                10
              );
            }
            onUpdateNoticeType(newNoticeType);
          }}
          id="days-between-publications"
        />
      </GridInput>
      <GridInput>
        <Autocomplete
          labelText="What days of the week is this notice type available?"
          value={undefined}
          placeholder={
            !updatedNoticeType.restrictedPublicationDays?.length
              ? 'All publication days'
              : ''
          }
          onChange={newValue => {
            onUpdateNoticeType({
              ...updatedNoticeType,
              restrictedPublicationDays: (
                updatedNoticeType.restrictedPublicationDays || []
              ).concat(parseInt(newValue, 10))
            });
          }}
          options={restrictedPublicationDaysOptions}
          prefix={
            <div
              className={classNames({
                'w-44':
                  updatedNoticeType.restrictedPublicationDays &&
                  updatedNoticeType.restrictedPublicationDays?.length === 2,
                'w-72':
                  updatedNoticeType.restrictedPublicationDays &&
                  updatedNoticeType.restrictedPublicationDays?.length > 2
              })}
            >
              {updatedNoticeType.restrictedPublicationDays?.map(
                publicationDay => (
                  <button
                    key={publicationDay}
                    onClick={() => {
                      const newPublicationDays =
                        updatedNoticeType.restrictedPublicationDays?.filter(
                          day => day !== publicationDay
                        );
                      onUpdateNoticeType({
                        ...updatedNoticeType,
                        restrictedPublicationDays: newPublicationDays
                      });
                    }}
                  >
                    <Badge
                      startIcon={<XMarkIcon className="w-4 h-4" />}
                      status="info"
                    >
                      {Day.by_value(publicationDay)?.label}
                    </Badge>
                  </button>
                )
              )}
            </div>
          }
          id="publication-dates-available"
        />
      </GridInput>
      <DeadlineOffsetForm
        onUpdateNoticeType={onUpdateNoticeType}
        updatedNoticeType={updatedNoticeType}
      />
    </CardGridLayout>
  );
}
