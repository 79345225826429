import moment from 'moment';
import { SnapshotModel, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import { getColumnInches } from '../../pricing';
import { getDistributedLineItems } from '../../pricing/distributeFee';
import { EInvoice, EQuery, ERef, EUser } from '../../types';
import { OrderInvoice } from '../../types/invoices';
import {
  NewspaperOrder,
  NewspaperOrderStatus,
  validateCompleteNewspaperOrder
} from '../../types/newspaperOrder';
import {
  ConsolidatedOrderPricing,
  Order,
  OrderStatus,
  isAdvertiserWithOrganizationOrder,
  isAnonymousOrder,
  isPublisherAsAdvertiserWithOrganizationOrder
} from '../../types/order';
import { DistributeSettings } from '../../types/organization';
import { getErrorReporter } from '../../utils/errors';
import { getOrThrow } from '../../utils/refs';
import { InvoiceModel } from './invoiceModel';
import {
  NewspaperOrderEditableData,
  NewspaperOrderModel
} from './newspaperOrderModel';
import { OrganizationModel } from './organizationModel';
import { getDateForDateStringInTimezone } from '../../utils/dates';
import {
  ResponseOrColumnError,
  ResponseOrError,
  getErrors,
  wrapError,
  wrapSuccess
} from '../../types/responses';
import { Product } from '../../enums';
import { UserModel } from './userModel';
import { ColumnService } from '../../services/directory';
import { NewspaperOrderService } from '../../services/newspaperOrderService';
import { InvoiceService } from '../../services/invoiceService';
import { ObituaryService } from '../../services/obituaryService';
import { ClassifiedService } from '../../services/classifiedService';
import { AdModel, isClassifiedModel, isObituaryModel } from './adModel';
import {
  InternalServerError,
  NotFoundError,
  wrapErrorAsColumnError
} from '../../errors/ColumnErrors';
import { safeAsync, safeGetOrThrow } from '../../safeWrappers';
import { OrderDetailService } from '../../services/orderDetailService';
import { OrderDetailModel } from './orderDetailModel';
import { OrderPricing } from '../../types/orderDetail';
import { ORDER_EDITED, OrderEditedEvent, OrderEvent } from '../../types/events';
import { EventService } from '../../services/eventService';
import { ObituaryModel } from './obituaryModel';
import { PublishingMedium } from '../../enums/PublishingMedium';
import { asyncMap, stripHtmlTags } from '../../helpers';
import { ClassifiedModel } from './classifiedModel';

/**
 * A record where the key is the newspaperOrder ID
 */
export type OrderEditableData = Record<string, NewspaperOrderEditableData>;

export class OrderModel extends SnapshotModel<
  Order,
  typeof Collections.orders
> {
  get type() {
    return Collections.orders;
  }

  private invoiceService: InvoiceService = new InvoiceService(this.ctx);

  private obituaryService: ObituaryService = new ObituaryService(this.ctx);

  private classifiedService: ClassifiedService = new ClassifiedService(
    this.ctx
  );

  private orderDetailService: OrderDetailService = new OrderDetailService(
    this.ctx
  );

  private newspaperOrderService: NewspaperOrderService =
    new NewspaperOrderService(this.ctx);

  private advertiserOrganization: OrganizationModel | null = null;

  private get adService() {
    return this.isClassifiedOrder
      ? this.classifiedService
      : this.obituaryService;
  }

  private eventService: EventService = new EventService(this.ctx);

  // TODO (goodpaul): Move existing calls to advertiserOrganization to this method
  async getAdvertiserOrganization(): Promise<
    ResponseOrColumnError<OrganizationModel | null>
  > {
    if (
      !this.advertiserOrganization &&
      (isAdvertiserWithOrganizationOrder(this.modelData) ||
        isPublisherAsAdvertiserWithOrganizationOrder(this.modelData))
    ) {
      const { response: advertiserOrganizationSnap, error: getError } =
        await safeGetOrThrow(this.modelData.advertiserOrganization);
      if (getError) {
        return wrapErrorAsColumnError(getError, NotFoundError);
      }
      this.advertiserOrganization = getModelFromSnapshot(
        OrganizationModel,
        this.ctx,
        advertiserOrganizationSnap
      );
    }
    return wrapSuccess(this.advertiserOrganization);
  }

  getOrderVersion(
    specifiedVersion: number | null | undefined
  ): ResponseOrColumnError<number> {
    const version = specifiedVersion ?? this.modelData.activeVersion;
    if (!version) {
      const error = new InternalServerError('Order has no active version');
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.OBITS,
        error,
        'Failed to get default order version',
        {
          orderId: this.id
        }
      );
      return wrapError(error);
    }
    return wrapSuccess(version);
  }

  getNewspaperOrdersQuery({
    includeDeleted = false,
    specifiedVersion = null,
    allVersions = false
  }: Partial<{
    includeDeleted: boolean;
    specifiedVersion: number | null;
    allVersions: boolean;
  }> = {}): ResponseOrColumnError<EQuery<NewspaperOrder>> {
    let version: number | null = null;
    if (!allVersions) {
      const { response: responseVersion, error: versionError } =
        this.getOrderVersion(specifiedVersion);
      if (versionError) {
        return wrapError(versionError);
      }
      version = responseVersion;
    }

    let newspaperOrderQuery: EQuery<NewspaperOrder> =
      this.ctx.orderNewspaperOrdersRef(this.ref);

    if (version) {
      newspaperOrderQuery = newspaperOrderQuery.where(
        'orderVersion',
        '==',
        version
      );
    }

    if (!includeDeleted) {
      newspaperOrderQuery = newspaperOrderQuery
        .orderBy('status', 'asc')
        .where('status', '!=', NewspaperOrderStatus.DELETED);
    }

    return wrapSuccess(newspaperOrderQuery);
  }

  public async getNewspaperOrders({
    includeDeleted = false,
    specifiedVersion = null,
    allVersions = false // Gets all versions, useful when syncing to elastic
  }: Partial<{
    includeDeleted: boolean;
    specifiedVersion: number | null;
    allVersions: boolean;
  }> = {}): Promise<NewspaperOrderModel[]> {
    const { response: newspaperOrderQuery, error: queryError } =
      this.getNewspaperOrdersQuery({
        includeDeleted,
        specifiedVersion,
        allVersions
      });
    if (queryError) {
      throw queryError;
    }
    const newspaperOrderSnaps = await newspaperOrderQuery.get();
    const newspaperOrders = newspaperOrderSnaps.docs.map(doc =>
      getModelFromSnapshot(NewspaperOrderModel, this.ctx, doc)
    );
    return newspaperOrders;
  }

  async getNewspaperOrderForPublisherAndPublishingMedium({
    publisherId,
    publishingMedium
  }: {
    publisherId: string;
    publishingMedium: PublishingMedium;
  }): Promise<ResponseOrError<NewspaperOrderModel>> {
    const [newspaperOrdersError, newspaperOrders] = await safeAsync(() =>
      this.getNewspaperOrders()
    )();
    if (newspaperOrdersError) {
      return wrapError(newspaperOrdersError);
    }
    const relevantNewspaperOrder = newspaperOrders.find(
      no =>
        no.modelData.publishingMedium === publishingMedium &&
        no.modelData.newspaper.id === publisherId
    );
    if (!relevantNewspaperOrder) {
      return wrapError(new NotFoundError('No relevant newspaper order found'));
    }
    return wrapSuccess(relevantNewspaperOrder);
  }

  async updateNewspaperOrders(
    newspaperOrders: Partial<NewspaperOrder>[],
    version: number
  ): Promise<void> {
    const newspaperOrderModels = await this.getNewspaperOrders({
      includeDeleted: true,
      specifiedVersion: version
    });
    const updatePromises = newspaperOrders?.map(async newspaperOrder => {
      const newspaperOrderModel = newspaperOrderModels.find(
        model =>
          model.modelData.newspaper.id === newspaperOrder?.newspaper?.id &&
          model.modelData.publishingMedium === newspaperOrder.publishingMedium
      );

      if (newspaperOrderModel) {
        if (
          newspaperOrderModel.modelData.status !== NewspaperOrderStatus.DRAFT
        ) {
          const error = new Error(
            'Cannot update a non draft newspaper order to be draft'
          );
          getErrorReporter().logAndCaptureError(
            ColumnService.OBITS,
            error,
            undefined,
            { newspaperOrderId: newspaperOrderModel.id }
          );
          throw error;
        }
        return newspaperOrderModel.ref.update({
          ...newspaperOrder,
          status: NewspaperOrderStatus.DRAFT
        });
      }
      if (validateCompleteNewspaperOrder(newspaperOrder)) {
        return this.ctx
          .orderNewspaperOrdersRef(this.ref)
          .add({ ...newspaperOrder, orderVersion: version });
      }

      // Log incomplete order
      getErrorReporter().logAndCaptureWarning(
        'Incomplete newspaper order in updateModel',
        {
          newspaperId: newspaperOrder.newspaper?.id ?? '',
          orderId: this.id
        }
      );
    });

    await Promise.all(updatePromises);

    // Identify and soft-delete any newspaperOrderModels not present in newspaperOrdersFormData
    const softDeletePromises = newspaperOrderModels
      .filter(
        model =>
          !newspaperOrders.some(
            newspaperOrder =>
              newspaperOrder?.newspaper?.id === model.modelData.newspaper.id &&
              model.modelData.publishingMedium ===
                newspaperOrder.publishingMedium
          )
      )
      .map(model => model.ref.update({ status: NewspaperOrderStatus.DELETED }));

    await Promise.all(softDeletePromises);
  }

  async getBillingName(): Promise<string> {
    if (isAnonymousOrder(this.modelData)) {
      if (this.modelData.firstName && this.modelData.lastName) {
        const name =
          `${this.modelData.firstName} ${this.modelData.lastName}`.trim();
        return name;
      }
      return '';
    }

    if (this.modelData.advertiser) {
      const { error, response: advertiserSnap } = await safeGetOrThrow(
        this.modelData.advertiser
      );
      if (error) {
        getErrorReporter().logAndCaptureError(
          ColumnService.OBITS,
          error,
          'Failed to get advertiser snap to get billing name'
        );
        return '';
      }

      return advertiserSnap.data().name;
    }

    const advertiserOrganizationSnap = await getOrThrow(
      this.modelData.advertiserOrganization
    );

    return advertiserOrganizationSnap.data().name;
  }

  /** Will return the first item on this list that's available:
   * - The contact email on the order
   * - The advertiser organization email
   * - The advertiser customer user's email
   * - The filing user's email
   */
  async getBillingEmail(): Promise<string> {
    if (isAnonymousOrder(this.modelData)) {
      return this.modelData.contactEmail;
    }

    if (this.modelData.advertiser) {
      const { error, response: advertiserSnap } = await safeGetOrThrow(
        this.modelData.advertiser
      );
      if (error) {
        getErrorReporter().logAndCaptureError(
          ColumnService.OBITS,
          error,
          'Failed to get advertiser snap to get billing email'
        );
        return '';
      }

      return advertiserSnap.data().email;
    }

    const advertiserOrganizationSnap = await getOrThrow(
      this.modelData.advertiserOrganization
    );

    const { email } = advertiserOrganizationSnap.data();

    if (email) {
      return email;
    }

    if (isPublisherAsAdvertiserWithOrganizationOrder(this.modelData)) {
      const customerSnap = await getOrThrow(this.modelData.advertiserCustomer);

      const userSnap = await getOrThrow(customerSnap.data().user);

      return userSnap.data().email;
    }

    const userSnap = await getOrThrow(this.modelData.user);

    return userSnap.data().email;
  }

  public async calculateDueDateForInvoice(
    invoiceCreateTime: EInvoice['created'],
    version: number
  ): Promise<ResponseOrError<number>> {
    const [newspaperOrdersError, newspaperOrders] = await safeAsync(() =>
      this.getNewspaperOrders({ specifiedVersion: version })
    )();
    if (newspaperOrdersError) {
      return wrapError(newspaperOrdersError);
    }

    const [getDueDateError, newspaperOrderDueDates] = await asyncMap(
      newspaperOrders,
      newspaperOrder =>
        newspaperOrder.calculateInvoiceDueDateByBillingTerm(invoiceCreateTime)
    );
    if (getDueDateError) {
      return wrapError(getDueDateError);
    }

    const earliestDueDate = Math.min(...newspaperOrderDueDates);
    return wrapSuccess(earliestDueDate);
  }

  public async getSaveCardName(): Promise<
    ResponseOrColumnError<string | null>
  > {
    if (isAnonymousOrder(this.modelData)) {
      // TODO: To support individuals using saved cards, we need to check the advertiser too
      return wrapSuccess(null);
    }

    const { response: advertiserOrganization, error: getError } =
      await this.getAdvertiserOrganization();
    if (getError) {
      return wrapError(getError);
    }
    if (!advertiserOrganization) {
      // TODO: To support individuals using saved cards, we need to check the advertiser too
      return wrapSuccess(null);
    }
    return wrapSuccess(advertiserOrganization.modelData.name);
  }

  public async sumNewspaperOrderPricing(specifiedVersion: number): Promise<{
    subtotalInCents: number;
    totalInCents: number;
    convenienceFeeInCents: number;
    totalDiscountInCents: number;
  }> {
    const newspaperOrders = await this.getNewspaperOrders({
      includeDeleted: false,
      specifiedVersion
    });

    // Calculate new order totals
    const initialPricing = {
      subtotalInCents: 0,
      totalInCents: 0,
      convenienceFeeInCents: 0,
      totalDiscountInCents: 0
    };

    const result = newspaperOrders.reduce((acc, newspaperOrder) => {
      const { pricing } = newspaperOrder.modelData;
      if (!pricing) {
        throw Error(
          `Missing pricing data for newspaperOrder ${newspaperOrder.id}`
        );
      }
      return {
        subtotalInCents: acc.subtotalInCents + pricing.subtotalInCents,
        totalInCents: acc.totalInCents + pricing.totalInCents,
        convenienceFeeInCents:
          acc.convenienceFeeInCents + pricing.convenienceFeeInCents,
        totalDiscountInCents:
          acc.totalDiscountInCents + (pricing.totalDiscountInCents ?? 0)
      };
    }, initialPricing);

    return result;
  }

  /**
   * Gets the top-level pricing only for the order
   */
  async getOrderPricing(
    specifiedVersion: number | undefined = undefined
  ): Promise<ResponseOrColumnError<OrderPricing>> {
    const { response: version, error: versionError } =
      this.getOrderVersion(specifiedVersion);
    if (versionError) {
      return wrapError(versionError);
    }
    const { response: orderDetail, error: detailError } =
      await this.getOrCreateOrderDetail(version);
    if (detailError) {
      return wrapError(detailError);
    }
    const { pricing } = orderDetail.modelData;
    if (!pricing) {
      return wrapError(new NotFoundError('Missing pricing data for order'));
    }
    return wrapSuccess(pricing);
  }

  /**
   * Gets all line items for all newspaper orders associated with this order
   *
   * @param distributeFee Whether to distribute the newspaper's processing
   * fee across that newspaper's line items. Used when displaying pricing to
   * the customer, not when storing pricing data on the invoice
   */
  async getConsolidatedPricing({
    distributeFee,
    version
  }: {
    distributeFee: boolean;
    version: number;
  }): Promise<ConsolidatedOrderPricing> {
    const newspaperOrders = await this.getNewspaperOrders({
      includeDeleted: false,
      specifiedVersion: version
    });
    const consolidatedNewspaperLineItems = (
      await Promise.all(
        newspaperOrders.map(async newspaperOrder => {
          const { pricing } = newspaperOrder.modelData;
          if (!pricing) {
            throw Error(
              `Missing pricing data for newspaperOrder ${newspaperOrder.id}`
            );
          }
          const { response: rate, error: rateError } =
            await newspaperOrder.getRate();
          if (rateError) {
            throw rateError;
          }

          // Orders are opinionated about how to distribute fees
          const distributeEnoticeFee: DistributeSettings = {
            proportionally: true
          };
          const { convenienceFeeInCents, subtotalInCents } = pricing;
          const lineItems = distributeFee
            ? getDistributedLineItems(
                pricing.lineItems,
                distributeEnoticeFee,
                rate.data(),
                {
                  feeToDistribute: convenienceFeeInCents,
                  expectedSubtotal: subtotalInCents
                }
              )
            : pricing.lineItems;
          const { displayParams } = newspaperOrder.modelData;
          const columnInches = displayParams
            ? getColumnInches(
                displayParams.height,
                displayParams.columns,
                rate.data().roundOff
              )
            : undefined;

          const [newspaperError, newspaper] =
            await newspaperOrder.getNewspaper();
          if (newspaperError) {
            throw newspaperError;
          }

          // Display params and column inches are used to render ad size information in the draft content step
          return {
            newspaperId: newspaperOrder.modelData.newspaper.id,
            newspaperOrderId: newspaperOrder.id,
            displayParams: newspaperOrder.modelData.displayParams,
            columnInches,
            publishingMedium: newspaperOrder.modelData.publishingMedium,
            lineItems,
            newspaperTimezone: newspaper.modelData.iana_timezone
          };
        })
      )
    ).flat();

    const newspaperOrderPricing = await this.sumNewspaperOrderPricing(version);

    // Opting to handle this manually instead of bringing in the DBPricingObj
    if (distributeFee) {
      newspaperOrderPricing.subtotalInCents +=
        newspaperOrderPricing.convenienceFeeInCents;
      newspaperOrderPricing.convenienceFeeInCents = 0;
    }

    const { response: orderPricing, error: orderPricingError } =
      await this.getOrderPricing(version);
    if (orderPricingError) {
      throw orderPricingError;
    }
    return {
      ...orderPricing,
      ...newspaperOrderPricing,
      newspaperOrderPublishingDataGroup: consolidatedNewspaperLineItems
    };
  }

  async getOrderDetail(
    specifiedVersion?: number
  ): Promise<ResponseOrColumnError<OrderDetailModel | null>> {
    const { response: version, error: versionError } =
      this.getOrderVersion(specifiedVersion);
    if (versionError) {
      return wrapError(versionError);
    }
    return this.orderDetailService.getByOrderAndVersion(this.ref, version);
  }

  async getOrCreateOrderDetail(
    specifiedVersion?: number
  ): Promise<ResponseOrColumnError<OrderDetailModel>> {
    const { response: version, error: versionError } =
      this.getOrderVersion(specifiedVersion);
    if (versionError) {
      return wrapError(versionError);
    }
    return this.orderDetailService.getOrCreateByOrderAndVersion(
      this.ref,
      version
    );
  }

  async getInvoice(
    specifiedVersion?: number
  ): Promise<ResponseOrError<InvoiceModel<OrderInvoice> | null>> {
    const { response: version, error: versionError } =
      this.getOrderVersion(specifiedVersion);
    if (versionError) {
      return wrapError(versionError);
    }
    return this.invoiceService.getByOrderAndVersion(this.ref, version);
  }

  async getInvoiceMemo(): Promise<ResponseOrError<string>> {
    const adResult = await this.getAdByVersion();

    if (adResult.error) {
      return adResult;
    }

    const ad = adResult.response;

    if (isObituaryModel(ad)) {
      if (!ad.modelData.deceasedName) {
        const errorMessage = 'No deceased name found on obituary';

        const error = Error(errorMessage);

        getErrorReporter().logAndCaptureError(
          ColumnService.OBITS,
          error,
          errorMessage,
          { orderId: this.id, adId: ad.id }
        );

        return wrapError(error);
      }

      return wrapSuccess(`Obituary for ${ad.modelData.deceasedName}`);
    }

    const title = stripHtmlTags(ad.modelData.title ?? '');

    if (!title) {
      const errorMessage = 'No title found on classified';

      const error = Error(errorMessage);

      getErrorReporter().logAndCaptureError(
        ColumnService.OBITS,
        error,
        errorMessage,
        { orderId: this.id, adId: ad.id }
      );

      return wrapError(error);
    }

    return wrapSuccess(`Classified for ${title}`);
  }

  async getAdByVersion(
    specifiedVersion: number | undefined = undefined
  ): Promise<ResponseOrError<AdModel>> {
    const { response: version, error: versionError } =
      this.getOrderVersion(specifiedVersion);
    if (versionError) {
      return wrapError(versionError);
    }
    const { response: ad, error: adError } =
      await this.adService.getByOrderAndVersion(this.ref, version);
    if (adError) {
      const zeroAdsFound = adError.message.includes('instead got 0');
      const orderIsDraft = this.modelData.status === OrderStatus.DRAFT;
      if (zeroAdsFound && !orderIsDraft) {
        getErrorReporter().logAndCaptureCriticalError(
          ColumnService.OBITS,
          adError,
          'Non-draft order has no ad associated',
          {
            orderId: this.id,
            version: `${version}`,
            orderType: this.modelData.product
          }
        );
      }
      return wrapError(adError);
    }
    return wrapSuccess(ad);
  }

  /**
   * TODO(classifieds): Generalize this to `getAd()` and require the caller
   * to use new ad type guards to narrow the ad type if needed.  Also may
   * need to share universal props between ads
   */
  public async getObituary(
    specifiedVersion?: number
  ): Promise<ResponseOrError<ObituaryModel>> {
    const { response: version, error: versionError } =
      this.getOrderVersion(specifiedVersion);
    if (versionError) {
      return wrapError(versionError);
    }
    const { response: obituary, error: obituaryError } =
      await this.obituaryService.getByOrderAndVersion(this.ref, version);
    if (obituaryError) {
      return wrapError(obituaryError);
    }

    return wrapSuccess(obituary);
  }

  public async getClassified(
    specifiedVersion?: number
  ): Promise<ResponseOrError<ClassifiedModel>> {
    const { response: version, error: versionError } =
      this.getOrderVersion(specifiedVersion);
    if (versionError) {
      return wrapError(versionError);
    }
    const { response: classified, error: classifiedError } =
      await this.classifiedService.getByOrderAndVersion(this.ref, version);
    if (classifiedError) {
      return wrapError(classifiedError);
    }

    return wrapSuccess(classified);
  }

  get isObituaryOrder() {
    return this.modelData.product === Product.Obituary;
  }

  get isClassifiedOrder() {
    return this.modelData.product === Product.Classified;
  }

  /**
   * Status update helper that updates the status of all associated newspaper orders if required
   */
  async statusUpdate(
    status: OrderStatus,
    isVersionUpdate?: boolean
  ): Promise<void> {
    let newspaperOrderStatus: NewspaperOrderStatus | undefined;
    const oldStatus = this.modelData.status;
    const isInitialPlacement =
      status === OrderStatus.PENDING && oldStatus === OrderStatus.DRAFT;
    const shouldResetConfirmationStatus = isVersionUpdate || isInitialPlacement;
    const isComplete = status === OrderStatus.COMPLETE;

    // Translate status change into potential newspaper order status change
    if (shouldResetConfirmationStatus) {
      newspaperOrderStatus = NewspaperOrderStatus.AWAITING_REVIEW;
    }
    if (status === OrderStatus.CANCELLED) {
      newspaperOrderStatus = NewspaperOrderStatus.CANCELLED;
    }

    const newspaperOrders = await this.getNewspaperOrders();
    if (newspaperOrderStatus) {
      await Promise.all(
        newspaperOrders.map(async newspaperOrder => {
          await newspaperOrder.ref.update({
            status: newspaperOrderStatus
          });
        })
      );
    }

    if (isComplete) {
      const canMarkAsComplete = newspaperOrders.every(no => no.isComplete);
      if (!canMarkAsComplete) {
        throw new Error(
          'Cannot mark order as complete because not all newspaper orders are complete'
        );
      }
    }

    await this.update({ status });
  }

  private async userInPublisherOrgCanCancelOrder(
    newspaper: OrganizationModel
  ): Promise<
    ResponseOrError<{
      canCancel: boolean;
      reason?: string;
    }>
  > {
    let newspaperOrders: NewspaperOrderModel[];
    try {
      newspaperOrders = await this.getNewspaperOrders();
    } catch (err) {
      return wrapError(err as Error);
    }

    const relevantNewspaperOrderForPublisher = newspaperOrders.find(
      no => no.modelData.newspaper.id === newspaper.id
    );

    // If the order is not placed in the newspaper that the publisher is part of, don't allow cancellation
    if (!relevantNewspaperOrderForPublisher) {
      return wrapSuccess({
        canCancel: false
      });
    }

    // If the order is only placed in the publisher's newspaper, allow cancellation whenever
    if (newspaperOrders.length === 1) {
      return wrapSuccess({
        canCancel: true
      });
    }

    const relevantNewspaperOrderPubDate = getDateForDateStringInTimezone({
      dayString:
        relevantNewspaperOrderForPublisher.modelData.publishingDates[0],
      timezone: newspaper.modelData.iana_timezone
    });
    const relevantNewspaperOrderIsPastPublication = moment().isSameOrAfter(
      moment(relevantNewspaperOrderPubDate),
      'day'
    );

    // If the order has already published in the relevant newspaper, don't allow cancellation
    if (relevantNewspaperOrderIsPastPublication) {
      return wrapSuccess({
        canCancel: false
      });
    }

    const otherNewspaperOrders = newspaperOrders.filter(
      no => no.id !== relevantNewspaperOrderForPublisher.id
    );
    const {
      response: orderIsPastDeadlineInAnotherNewspaper,
      error: orderIsPastDeadlineError
    } = await this.newspaperOrderService.isAnyNewspaperOrderPastDeadline(
      otherNewspaperOrders
    );
    if (orderIsPastDeadlineError) {
      return wrapError(orderIsPastDeadlineError);
    }

    // If the order is past deadline in any other newspaper, don't allow cancellation
    if (orderIsPastDeadlineInAnotherNewspaper) {
      return wrapSuccess({
        canCancel: false
      });
    }

    // If the order is not past deadline in any other newspaper, allow cancellation
    return wrapSuccess({
      canCancel: true
    });
  }

  private async advertiserUserCanCancelOrder(): Promise<
    ResponseOrError<{
      canCancel: boolean;
      reason?: string;
    }>
  > {
    let newspaperOrders: NewspaperOrderModel[];
    try {
      newspaperOrders = await this.getNewspaperOrders();
    } catch (err) {
      return wrapError(err as Error);
    }

    const { response: orderIsPastDeadline, error: orderIsPastDeadlineError } =
      await this.newspaperOrderService.isAnyNewspaperOrderPastDeadline(
        newspaperOrders
      );
    if (orderIsPastDeadlineError) {
      return wrapError(orderIsPastDeadlineError);
    }

    // If the order is past deadline in any newspaper, don't allow cancellation
    if (orderIsPastDeadline) {
      return wrapSuccess({
        canCancel: false,
        reason:
          'This order cannot be cancelled because the ad deadline has passed.'
      });
    }

    // If the order is not past deadline in any newspaper, allow cancellation
    return wrapSuccess({
      canCancel: true
    });
  }

  private async anonymousCanCancelOrder(): Promise<
    ResponseOrError<{
      canCancel: boolean;
      reason?: string;
    }>
  > {
    let newspaperOrders: NewspaperOrderModel[];
    try {
      newspaperOrders = await this.getNewspaperOrders();
    } catch (err) {
      return wrapError(err as Error);
    }

    const { response: orderIsPastDeadline, error: orderIsPastDeadlineError } =
      await this.newspaperOrderService.isAnyNewspaperOrderPastDeadline(
        newspaperOrders
      );
    if (orderIsPastDeadlineError) {
      return wrapError(orderIsPastDeadlineError);
    }

    // If the order is past deadline in any newspaper, don't allow cancellation
    if (orderIsPastDeadline) {
      return wrapSuccess({
        canCancel: false,
        reason:
          'This order cannot be cancelled because the ad deadline has passed.'
      });
    }

    // If the order is not past deadline in any newspaper, allow cancellation
    return wrapSuccess({
      canCancel: true
    });
  }

  // TODO: should this eventually be combined with the edit logic below?
  public async getUserCanCancelOrder(userInfo: {
    activeOrganization?: OrganizationModel | null;
    isAnonymousUser?: boolean;
  }): Promise<
    ResponseOrError<{
      canCancel: boolean;
      reason?: string;
    }>
  > {
    // If the order is already cancelled, it can't be cancelled again
    if (this.modelData.status === OrderStatus.CANCELLED) {
      return wrapSuccess({
        canCancel: false
      });
    }

    const { activeOrganization, isAnonymousUser } = userInfo;

    if (isAnonymousUser) {
      return this.anonymousCanCancelOrder();
    }

    if (activeOrganization?.isPublisherOrganization) {
      return this.userInPublisherOrgCanCancelOrder(activeOrganization);
    }

    return this.advertiserUserCanCancelOrder();
  }

  public async getEditableDataForNewspapers(
    user: UserModel | null
  ): Promise<ResponseOrError<OrderEditableData>> {
    let newspaperOrders: NewspaperOrderModel[];
    try {
      newspaperOrders = await this.getNewspaperOrders();
    } catch (err) {
      return wrapError(err as Error);
    }

    const orderEditableData: OrderEditableData = {};
    const result = await Promise.all(
      newspaperOrders.map(async no => {
        const { response: editableData, error: editableDataError } =
          await no.getEditableDataForUser(user);
        if (editableDataError) {
          return wrapError(editableDataError);
        }

        orderEditableData[no.id] = editableData;
        return wrapSuccess(undefined);
      })
    );
    const errors = getErrors(result);
    if (errors.length) {
      return wrapError(errors[0]);
    }

    return wrapSuccess(orderEditableData);
  }

  public async setActiveVersion(version: number, editedBy?: ERef<EUser>) {
    const { activeVersion: currentActiveVersion } = this.modelData;
    const isVersionUpdate = version !== currentActiveVersion;
    if (isVersionUpdate) {
      const currentActiveNewspaperOrders = await this.getNewspaperOrders();
      // soft-delete newspaper orders from outdated version
      const softDeletePromises = currentActiveNewspaperOrders.map(model =>
        model.updateStatus(NewspaperOrderStatus.DELETED)
      );
      await Promise.all(softDeletePromises);

      // update active version on order
      await this.update({ activeVersion: version });
      await this.refreshData();
      await this.createEvent<OrderEditedEvent>(ORDER_EDITED, {
        previousVersion: currentActiveVersion,
        newVersion: version,
        editedBy
      });
    }

    /**
     * Even if the status on this order is already pending
     * (e.g., if we went through the edit flow), we still want
     * to run this status update so that the new newspaper orders
     * are set to AWAITING_REVIEW
     */
    await this.statusUpdate(OrderStatus.PENDING, true);
  }

  public async generateNewVersionOfOrderItems(): Promise<
    ResponseOrError<{
      version: number;
    }>
  > {
    try {
      const {
        response: currentActiveOrderDetail,
        error: currentActiveOrderDetailError
      } = await this.getOrderDetail();
      if (currentActiveOrderDetailError) {
        return wrapError(currentActiveOrderDetailError);
      }
      if (!currentActiveOrderDetail) {
        throw new Error('No order detail found');
      }
      const { response: currentActiveAd, error: currentActiveAdError } =
        await this.getAdByVersion();
      if (currentActiveAdError) {
        return wrapError(currentActiveAdError);
      }
      const currentActiveNewspaperOrders = await this.getNewspaperOrders();
      const newVersion = this.ctx.timestamp().toMillis();

      if (isObituaryModel(currentActiveAd)) {
        await this.obituaryService.cloneForEditOrDuplicateFlow(
          currentActiveAd.id,
          currentActiveAd.modelData,
          newVersion
        );
      } else if (isClassifiedModel(currentActiveAd)) {
        await this.classifiedService.cloneForEditOrDuplicateFlow(
          currentActiveAd.id,
          currentActiveAd.modelData,
          newVersion
        );
      } else {
        throw new Error('Unknown ad type');
      }
      await this.orderDetailService.cloneForEditOrDuplicateFlow(
        this.ref,
        currentActiveOrderDetail,
        newVersion
      );
      await this.newspaperOrderService.cloneForEditOrDuplicateFlow(
        this.ref,
        currentActiveNewspaperOrders,
        newVersion
      );

      return wrapSuccess({
        version: newVersion
      });
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  public async deleteDraftOrder(
    cancelInvoiceCallback: (
      invoice: InvoiceModel<OrderInvoice>
    ) => Promise<ResponseOrColumnError<void>>
  ): Promise<ResponseOrError<void>> {
    if (!this.isDraft) {
      return wrapError(
        new Error(
          'Only draft orders can be deleted. This order is not a draft.'
        )
      );
    }

    try {
      const result = await this.ctx.runTransaction(async transaction => {
        const newspaperOrders = await this.getNewspaperOrders({
          includeDeleted: true
        });

        const [invoiceError, invoice] = await this.getInvoice();

        if (invoiceError) {
          return wrapError(invoiceError);
        }

        if (invoice) {
          const isUnpaid = invoice.isUnpaid();

          if (!isUnpaid) {
            return wrapError(
              new Error(
                'Cannot delete draft order with an invoice that is not unpaid'
              )
            );
          }

          const { error: cancelInvoiceError } = await cancelInvoiceCallback(
            invoice
          );

          if (cancelInvoiceError) {
            return wrapError(
              new Error('Failed to cancel invoice before deleting draft order')
            );
          }
        }

        const orderDetailResult = await this.getOrderDetail();

        if (orderDetailResult.error) {
          return orderDetailResult;
        }

        const adResult = await this.getAdByVersion();

        if (adResult.error) {
          return adResult;
        }

        newspaperOrders.forEach(no => {
          transaction.delete(no.ref);
        });

        if (orderDetailResult.response) {
          transaction.delete(orderDetailResult.response.ref);
        }

        if (adResult.response) {
          transaction.delete(adResult.response.ref);
        }

        transaction.delete(this.ref);
        return wrapSuccess(undefined);
      });

      return result;
    } catch (err) {
      return wrapError(err as Error);
    }
  }

  get isDraft() {
    return this.modelData.status === OrderStatus.DRAFT;
  }

  public async createEvent<T extends OrderEvent>(
    type: T['type'],
    data: T['data']
  ) {
    return this.eventService.createOrderEvent(this.ref, type, data);
  }
}
