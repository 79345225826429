import { ERef, ESnapshotExists, exists } from 'lib/types';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { selectIsUserLoggedIn } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';

// TODO: Merge with `useFirestoreDocumentListener`
export function useFirestoreListener<T>(
  docRef?: ERef<T>
): [
  ESnapshotExists<T> | null,
  Dispatch<SetStateAction<ESnapshotExists<T> | null>>
] {
  const [existingSnapshot, setExistingSnapshot] =
    useState<ESnapshotExists<T> | null>(null);

  const userLoggedIn = useAppSelector(selectIsUserLoggedIn);

  const setInitialSnapshot = async (ref: ERef<T>) => {
    const refSnapshot = await ref.get();
    if (!exists(refSnapshot)) {
      return;
    }
    setExistingSnapshot(refSnapshot);
  };

  useEffect(() => {
    // Don't attempt to fetch if the user is not logged in
    if (!userLoggedIn) return;

    if (!existingSnapshot) {
      if (docRef) {
        void setInitialSnapshot(docRef);
      }
      return;
    }

    const unsubscribe = existingSnapshot.ref.onSnapshot(nextSnap => {
      if (!exists(nextSnap)) {
        setExistingSnapshot(null);
        return;
      }

      if (!isEqual(nextSnap.data(), existingSnapshot.data())) {
        setExistingSnapshot(nextSnap);
      }
    });

    return unsubscribe;
  }, [existingSnapshot?.id, docRef?.id, userLoggedIn]);

  return [existingSnapshot, setExistingSnapshot];
}
