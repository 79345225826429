import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { apiPost } from 'api/typed';
import { Alert } from 'lib/components/Alert';
import { DataTable } from 'lib/components/DataTable';
import { Form } from 'lib/components/Form';
import { useLoading } from 'lib/components/hooks/useLoading';
import { Modal } from 'lib/components/Modal';
import { dbToUICurrency } from 'lib/pricing/ui';
import { SearchableInvoiceRecord } from 'lib/types/searchable';
import { useState } from 'react';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';

type BulkPaymentCardInputFormProps = {
  onClose: () => void;
  onContinue: (ledgerItemId: string) => void;
  invoices: SearchableInvoiceRecord[];
};

export function BulkPaymentCardInputForm({
  onClose,
  onContinue,
  invoices
}: BulkPaymentCardInputFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const user = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const sumInvoices = (firstN: number) =>
    invoices
      .slice(0, firstN)
      .reduce((acc, invoice) => acc + invoice.nettotal, 0);

  const formId = 'bulk-pay-invoices-modal-form';
  const [errorMessage, setErrorMessage] = useState('');
  const [submitLoading, submitWithLoading] = useLoading();
  const handleSubmit = async () => {
    setErrorMessage('');

    if (!stripe || !elements || !user) {
      setErrorMessage('Unable to load payment processing functions');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setErrorMessage('Unable to load card element');
      return;
    }

    const { error: errorCreatingPaymentMethod, paymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });

    if (errorCreatingPaymentMethod) {
      setErrorMessage(
        errorCreatingPaymentMethod.message ||
          'An error occurred processing payment'
      );
      return;
    }

    const { response: ledgerItemId, error: errorPayingInvoices } =
      await apiPost('ledger/bulk-pay-invoices', {
        userId: user.id,
        organizationId: activeOrganization?.id,
        stripePaymentMethodId: paymentMethod.id,
        invoiceIds: invoices.map(invoice => invoice.id)
      });

    if (errorPayingInvoices !== null) {
      setErrorMessage(
        errorPayingInvoices || 'An error occurred processing payment'
      );
      return;
    }

    onContinue(ledgerItemId);
  };

  return (
    <Modal
      id="bulk-pay-invoices-modal"
      onClose={onClose}
      title="Pay Invoices"
      primaryAction={{
        formId,
        loading: !stripe || !elements || submitLoading,
        buttonText: 'Pay Invoices',
        type: 'submit'
      }}
      size="3xl"
    >
      <Form id={formId} onSubmit={() => submitWithLoading(handleSubmit)}>
        <div className="m-4 flex flex-col gap-4">
          <DataTable
            id="bulk-payment-invoice-list"
            labelText="Review selected invoices"
            columns={['invoice', 'notice', 'publisher', 'amount']}
            headers={{
              invoice: 'Invoice',
              notice: 'Notice',
              publisher: 'Publisher',
              amount: 'Amount'
            }}
            columnAlignment={{
              amount: 'right'
            }}
            data={invoices.map(invoice => ({
              invoice: invoice.invoicenumber,
              notice: invoice.noticename ?? 'Legal Notice',
              publisher: invoice.publishername,
              amount: `$${dbToUICurrency(invoice.nettotal).toFixed(2)}`
            }))}
            totals={{
              invoice: 'Total',
              amount: `$${dbToUICurrency(sumInvoices(invoices.length)).toFixed(
                2
              )}`
            }}
          />
          <div>
            <div className="pb-2 text-sm font-medium text-column-gray-500">
              Enter payment information
              <span className="text-column-gray-400"> *</span>
            </div>
            <div className="border border-column-gray-200 rounded-lg shadow p-3">
              <CardElement options={{ hidePostalCode: true }} />
            </div>
          </div>
          {errorMessage && (
            <Alert
              id="bulk-payment-error"
              title={errorMessage}
              status="error"
              icon={<ExclamationCircleIcon className="w-6 h-6" />}
            />
          )}
        </div>
      </Form>
    </Modal>
  );
}
