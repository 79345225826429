import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import api from 'api';
import { GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { getFulfilled, isDefined } from 'lib/helpers';
import {
  Customer,
  ERef,
  ERequestTypes,
  EResponseTypes,
  exists
} from 'lib/types';
import {
  SearchableCustomerRecord,
  SearchableCustomerRecordFilter
} from 'lib/types/searchable';
import { useState } from 'react';
import { selectActiveOrganization } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { FuneralHomeCustomerInformation } from '../funeralHomeCustomerInformation';
import CustomerCreation from './CustomerCreation';
import FuneralHomeSearchResults from './FuneralHomeSearchResults';
import getFuneralHomeCustomerInformationFromCustomer from './getFuneralHomeCustomerInformationFromCustomer';

const getFuneralHomeCustomerInformationFromSearchableCustomerRecord = async (
  result: SearchableCustomerRecord
) => {
  const customersRef = getFirebaseContext().customersRef();

  const customerRef = customersRef.doc(result.id);

  return getFuneralHomeCustomerInformationFromCustomer(customerRef);
};

const getDefaultResult = async (chosenCustomer: ERef<Customer> | undefined) => {
  if (chosenCustomer) {
    const defaultResult = await getFuneralHomeCustomerInformationFromCustomer(
      chosenCustomer
    );

    return defaultResult ?? null;
  }

  return null;
};

const search = async (activeOrganizationId: string, searchString: string) => {
  const filters: SearchableCustomerRecordFilter[] = [
    { organizationid: activeOrganizationId },
    { archived: Number(false) }
  ];

  const reqBody: ERequestTypes['search/customers'] = {
    search: searchString,
    filters
  };

  const searchCustomersResponse: EResponseTypes['search/customers'] =
    await api.post('search/customers', reqBody);

  if (!searchCustomersResponse.success) {
    throw searchCustomersResponse.error;
  }

  return searchCustomersResponse.results;
};

type FuneralHomeSearchProps = {
  chosenCustomer: ERef<Customer> | undefined;
  onChosenCustomerChange: (advertiser: FuneralHomeCustomerInformation) => void;
};

function FuneralHomeCustomerSearch({
  chosenCustomer,
  onChosenCustomerChange
}: FuneralHomeSearchProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  /* Used to force a customer to be present and selected in the results (as when returning from a previous
    screen or creating a new customer) */
  const [forcedCustomerRef, setForcedCustomerRef] = useState(chosenCustomer);

  const [searchboxValue, setSearchboxValue] = useState('');

  const searchString = useDebounce(searchboxValue, 250);

  const { value: searchResults, isLoading } = useAsyncEffect({
    fetchData: async () => {
      const defaultResult = await getDefaultResult(forcedCustomerRef);

      if (defaultResult) {
        setSearchboxValue(defaultResult.name);

        onChosenCustomerChange(defaultResult);

        return [defaultResult];
      }

      if (!activeOrganization) {
        return [];
      }

      const results = await search(activeOrganization.id, searchString);

      const customerPromises = results.map(
        getFuneralHomeCustomerInformationFromSearchableCustomerRecord
      );

      const settledCustomers = await Promise.allSettled(customerPromises);

      const customers = getFulfilled(settledCustomers).filter(isDefined);

      return customers;
    },
    dependencies: [searchString, forcedCustomerRef?.id]
  });

  return (
    <div className="w-full inline-block">
      <div className="grid gap-4">
        <div className="font-semibold text-lg text-column-gray-500">
          Funeral home details
        </div>
        <div className="grid gap-4">
          <GridInput fullWidth>
            <TextField
              id="search-field"
              labelText=""
              placeholder="Search by name"
              prefix={
                <MagnifyingGlassIcon
                  className="w-5 text-column-gray-300"
                  aria-hidden="true"
                />
              }
              validationMessages={{
                valueMissing:
                  'Select a funeral home below or enter a name to search.'
              }}
              value={searchboxValue}
              onChange={newValue => {
                setForcedCustomerRef(undefined);
                setSearchboxValue(newValue);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              required={!chosenCustomer}
              errorText={
                searchboxValue && !chosenCustomer
                  ? 'Choose an existing funeral home or create a new one to continue'
                  : ''
              }
            />
          </GridInput>
          <GridInput fullWidth>
            {exists(activeOrganization) && (
              <CustomerCreation
                onCreate={customer => {
                  setForcedCustomerRef(customer.ref);
                }}
                activeOrganization={activeOrganization}
              />
            )}
          </GridInput>
          <GridInput fullWidth>
            <FuneralHomeSearchResults
              searchResults={searchResults?.length ? searchResults : []}
              onSelect={customer => {
                onChosenCustomerChange(customer);
              }}
              selection={chosenCustomer}
              isLoading={isLoading}
            />
          </GridInput>
        </div>
      </div>
    </div>
  );
}

export default FuneralHomeCustomerSearch;
