import React, { useEffect, useState } from 'react';

import { logAndCaptureCriticalError, logAndCaptureException } from 'utils';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { getFirebaseContext } from 'utils/firebase';
import FullScreenModal from 'components/FullScreenModal';
import {
  ETemplateStyles,
  ESnapshotExists,
  EOrganization,
  ETemplate,
  ERef,
  exists
} from 'lib/types';
import { TemplateTypes } from 'lib/types/templates';
import {
  cdnIfy,
  isColumnUser,
  removeUndefinedFields,
  replaceUndefinedWithDelete
} from 'lib/helpers';
import { PublisherOrgSharingCard } from 'routes/settings/publisher/sharing/PublisherOrgSharingCard';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { ColumnService } from 'lib/services/directory';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import TemplateFileUpload from './TemplateFileUpload';
import TemplateOverviewCard from './TemplateOverviewCard';
import {
  TemplateConfiguration,
  extractTemplateConfigurationFromActiveOrganization
} from './templateSettingsUpdateFormUtils';
import { getTemplateStylesFromURL } from '../../templateSettingsUtils';
import TemplateBulkDownloadUpdateCard from './TemplateBulkDownloadUpdateCard';
import { TemplateStyles } from '../shared/TemplateStyles';

type TemplateUpdateFormProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  templateRef: ERef<ETemplate> | undefined;
  template: ETemplate;
  isNoticeProduct: boolean;
  onClose: () => void;
};

/**
 * Form component to update the configuration settings of a newspaper pagination template
 */
export default function TemplateUpdateForm({
  activeOrganization,
  templateRef,
  template,
  isNoticeProduct,
  onClose
}: TemplateUpdateFormProps) {
  const user = useAppSelector(selectUser);

  const [updatedTemplateConfiguration, setUpdatedTemplateConfiguration] =
    useState<TemplateConfiguration>(
      extractTemplateConfigurationFromActiveOrganization(
        activeOrganization,
        templateRef,
        template
      )
    );
  const [enableBulkDownload, setEnableBulkDownload] = useState(
    !!updatedTemplateConfiguration.bulkDownloadStoragePath
  );
  const [templateStyles, setTemplateStyles] = useState<ETemplateStyles>();
  const [saving, setSaving] = useState(false);

  /**
   * Loads template styles from the template's download URL
   */
  useEffect(() => {
    void (async () => {
      const { downloadUrl } = updatedTemplateConfiguration;
      try {
        setTemplateStyles(await getTemplateStylesFromURL(downloadUrl));
      } catch (err) {
        logAndCaptureException(
          ColumnService.INDESIGN,
          err,
          'Error loading template styles',
          {
            organizationId: activeOrganization.id,
            downloadUrl
          }
        );
      }
    })();
  }, [updatedTemplateConfiguration.downloadUrl]);

  const updateTemplateProperties = async () => {
    if (!templateStyles) return;
    setSaving(true);

    const { publisherOrganizations, bulkDownloadStoragePath, downloadUrl } =
      updatedTemplateConfiguration;

    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    const templateUpdate: ETemplate = {
      name: updatedTemplateConfiguration.name,
      type: TemplateTypes.legal,
      downloadUrl,
      publisherOrganizations,
      columnsWide: 1,
      fullPageTemplate: bulkDownloadStoragePath
        ? cdnIfy(bulkDownloadStoragePath, { useColumnCDN })
        : undefined,
      styles: templateStyles
    };

    let editedRef: ERef<ETemplate>;

    if (templateRef) {
      editedRef = templateRef;
      const existingTemplateSnapshot = await templateRef.get();
      if (!existingTemplateSnapshot.data()?.organization) {
        templateUpdate.organization = activeOrganization.ref;
        logAndCaptureCriticalError(
          ColumnService.PAGINATION,
          new Error('Template missing organization'),
          'The organization ref was not properly set up on obits template - No action required, this has been fixed',
          {
            organizationId: activeOrganization.id,
            templateId: templateRef.id
          }
        );
      }

      await templateRef.update(
        replaceUndefinedWithDelete(getFirebaseContext(), templateUpdate)
      );
    } else {
      templateUpdate.organization = activeOrganization.ref;
      editedRef = await getFirebaseContext()
        .adTemplatesRef()
        .add(removeUndefinedFields(templateUpdate));
    }

    if (updatedTemplateConfiguration.useAsDefaultTemplate) {
      await activeOrganization.ref.update({
        adTemplate: editedRef
      });
    }

    setSaving(false);
    onClose();
  };

  const editedTemplateConfig = !columnObjectsAreEqual(
    updatedTemplateConfiguration,
    extractTemplateConfigurationFromActiveOrganization(
      activeOrganization,
      templateRef,
      template
    )
  );
  const edited = editedTemplateConfig;
  const invalidBulkDownload =
    enableBulkDownload && !updatedTemplateConfiguration.bulkDownloadStoragePath;
  const saveDisabled =
    saving ||
    invalidBulkDownload ||
    (!!templateRef && (!templateStyles || !edited));

  return (
    <FullScreenModal
      headerText={`${templateRef ? 'Edit' : 'Create'} Template`}
      submittable={{
        buttonText: 'Save',
        onSubmit: updateTemplateProperties,
        disabled: saveDisabled
      }}
      onClose={onClose}
      id="template-settings-update-form"
      previewable={
        isNoticeProduct
          ? {
              header: {
                title: 'Design Settings',
                description: 'Review the styles in the IDML for this template.'
              },
              renderPreview: () => (
                <TemplateStyles templateStyles={templateStyles} />
              ),
              withBorder: true
            }
          : undefined
      }
    >
      {/* Update template details */}
      <TemplateOverviewCard
        onChange={overviewDetails =>
          setUpdatedTemplateConfiguration({
            ...updatedTemplateConfiguration,
            ...overviewDetails
          })
        }
        isNoticeProduct={isNoticeProduct}
        value={updatedTemplateConfiguration}
      />

      {exists(user) && isColumnUser(user) && isNoticeProduct && (
        <PublisherOrgSharingCard
          resourceNoun="template"
          value={updatedTemplateConfiguration.publisherOrganizations ?? []}
          owner={template.organization}
          onChange={value => {
            setUpdatedTemplateConfiguration({
              ...updatedTemplateConfiguration,
              publisherOrganizations: value
            });
          }}
        />
      )}

      {/* Update settings on your template */}
      <TemplateFileUpload
        onChange={downloadUrl =>
          setUpdatedTemplateConfiguration({
            ...updatedTemplateConfiguration,
            downloadUrl
          })
        }
        value={updatedTemplateConfiguration.downloadUrl}
        isNoticeProduct={isNoticeProduct}
      />

      {isNoticeProduct && activeOrganization.data().fullPageTemplate && (
        <TemplateBulkDownloadUpdateCard
          onChange={({ bulkDownloadStoragePath, enableBulkDownload }) => {
            setUpdatedTemplateConfiguration({
              ...updatedTemplateConfiguration,
              bulkDownloadStoragePath
            });
            setEnableBulkDownload(enableBulkDownload);
          }}
          value={{
            bulkDownloadStoragePath:
              updatedTemplateConfiguration.bulkDownloadStoragePath,
            enableBulkDownload
          }}
        />
      )}
      <div className="pb-20 w-full" />
    </FullScreenModal>
  );
}
