import React, { useContext, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import NoticesActions from 'redux/notices';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  MagnifyingGlassIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline';
import {
  useGlobalFilter,
  usePagination,
  useFilters,
  useSortBy,
  useTable,
  Row,
  Column
} from 'react-table';
import { canPublisherUserSeeNewspaperSelect } from 'sagas/helpers';
import { publisherReadyToUpload } from 'lib/publishers';
import { EOrganization, ESnapshot, ESnapshotExists, exists } from 'lib/types';
import { OccupationType } from 'lib/enums';
import PlaceNoticeButton from 'components/PlaceNoticeButton';
import { ColumnButton } from 'lib/components/ColumnButton';
import { EReduxState } from 'redux/types';
import { TextField } from 'lib/components/TextField';
import { EUser } from 'lib/types/user';
import { Tooltip } from 'lib/components/Tooltip';
import LinkTo from 'components/LinkTo';
import {
  SORT_DESCENDING,
  SORT_ASCENDING,
  SearchableRecord
} from 'lib/types/searchable';
import { Popover, PopoverContext } from 'lib/components/Popover';

type NoticeFilterPopoverItemProps = {
  description: string;
  onClick: () => void;
};
function NoticeFilterPopoverItem({
  description,
  onClick
}: NoticeFilterPopoverItemProps) {
  return (
    <div
      id="show-all-notices"
      className="p-3 hover:bg-column-primary-25 text-column-gray-500 hover:text-column-primary-500 cursor-pointer rounded-md font-medium text-sm"
      onClick={onClick}
    >
      {description}
    </div>
  );
}

function NoticeFilterMenu({
  onSetShowUserOnlyNotices
}: {
  onSetShowUserOnlyNotices: (newPreference: boolean) => void;
}) {
  const { setOpen } = useContext(PopoverContext);
  return (
    <div className="w-40 p-2">
      <NoticeFilterPopoverItem
        onClick={() => {
          onSetShowUserOnlyNotices(false);
          setOpen(false);
        }}
        description="All Notices"
      />
      <NoticeFilterPopoverItem
        onClick={() => {
          onSetShowUserOnlyNotices(true);
          setOpen(false);
        }}
        description="Only my notices"
      />
    </div>
  );
}

type GlobalFilterProps = {
  actions: any;
  user: ESnapshotExists<EUser>;
  setSearchValue: (newSearchValue: string) => void;
  setShowUserOnlyNotices: (newPreference: boolean) => void;
  showUserOnlyNotices: boolean;
  searchValue: string;
};
function GlobalFilter({
  actions,
  user,
  setSearchValue,
  setShowUserOnlyNotices,
  showUserOnlyNotices,
  searchValue
}: GlobalFilterProps) {
  const showDropdownMenu = () => {
    if (!user) return false;
    if (!user.data()?.activeOrganization) return false;
    if (
      [
        OccupationType.lawyer.value,
        OccupationType.government_official.value,
        OccupationType.other_organization.value
      ].includes(user.data().occupation)
    )
      return true;
    return false;
  };

  const shouldShowDropdownMenu = showDropdownMenu();

  return (
    <div className="inline-flex float-right h-18 gap-3 items-center">
      <div className="flex-1" />
      {shouldShowDropdownMenu && (
        <Popover
          id="user-notice-list-filter-popover"
          activator={
            <ColumnButton
              buttonText={
                showUserOnlyNotices ? 'Only my notices' : 'All notices'
              }
              endIcon={
                <ChevronDownIcon className="h-5 w-5 text-column-gray-400 bold mt-1" />
              }
              type="button"
            />
          }
          alignment="right"
        >
          <NoticeFilterMenu onSetShowUserOnlyNotices={setShowUserOnlyNotices} />
        </Popover>
      )}
      <div
        className={classNames('rounded-md h-18 flex items-center', {
          'ml-10': !actions
        })}
      >
        <div className="w-64">
          <TextField
            id={'notices-search'}
            labelText={''}
            placeholder="Search Notices..."
            value={searchValue || ''}
            size="small"
            onChange={newValue => setSearchValue(newValue)}
            prefix={
              <MagnifyingGlassIcon
                className="w-5 text-column-gray-300 py-1"
                aria-hidden="true"
              />
            }
          />
        </div>
      </div>
      {actions && actions}
    </div>
  );
}

const mapStateToPropsForGlobalFilter = (state: any) => ({
  user: state.auth.user
});

const CGlobalFilter = connect(mapStateToPropsForGlobalFilter)(GlobalFilter);

type TableProps = {
  columns: Column<object>[];
  data: SearchableRecord[];
  id: string;
  actions: React.ReactNode;
  loading: boolean;
  tableState: {
    currentPage: number;
    rowCount: number;
    total: number;
  };
  sort: {
    direction: string | null;
    field: string | null;
  };
  onPageChange: (newPage: number) => void;
  onRowsChange: (newRow: number) => void;
  onSort: (newSort: any) => void;
  setSearchValue: (newSearchValue: string) => void;
  setShowUserOnlyNotices: (newPreference: boolean) => void;
  showUserOnlyNotices: boolean;
  searchValue: string;
  user: ESnapshotExists<EUser> | null;
  activeOrganization: ESnapshot<EOrganization> | null;
  showAllOrgsNotices: boolean;
  emptyHeaderText: string;
  emptySubtitleText: string;
};

function Table({
  columns,
  data,
  actions,
  id,
  loading,
  tableState,
  sort,
  onPageChange,
  onRowsChange,
  onSort,
  setSearchValue,
  setShowUserOnlyNotices,
  showUserOnlyNotices,
  searchValue,
  user,
  activeOrganization,
  showAllOrgsNotices,
  emptyHeaderText,
  emptySubtitleText
}: TableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state: { pageSize, sortBy },
    setPageSize,
    setSortBy
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: tableState.currentPage,
        pageSize: tableState.rowCount || 5,
        globalFilter: '',
        sortBy: sort.field
          ? [
              {
                id: sort.field,
                desc: sort.direction === SORT_DESCENDING
              }
            ]
          : []
      },
      manualSortBy: true,
      manualPagination: true,
      autoResetSortBy: false,
      autoResetGlobalFilter: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Triggered when the sort is changed in the UI of the tab at hand
  useEffect(() => {
    onSort({
      field: sortBy[0] ? sortBy[0].id : null,
      direction: sortBy[0]
        ? sortBy[0].desc
          ? SORT_DESCENDING
          : SORT_ASCENDING
        : null
    });
  }, [sortBy]);

  // Triggered when the sort is changed in redux (the UI of a different tab than the one at hand)
  useEffect(() => {
    if (!sort.field) {
      if (sortBy[0]) {
        setSortBy([]);
      }
    } else if (
      sortBy[0]?.id !== sort.field ||
      sortBy[0]?.desc !== (sort.direction === SORT_DESCENDING)
    ) {
      setSortBy([{ id: sort.field, desc: sort.direction === SORT_DESCENDING }]);
    }
  }, [sort]);

  useEffect(() => {
    if (loading) return;
    setPageSize(tableState.rowCount);
  }, [loading, tableState.rowCount]);

  const [showFilter, setShowFilter] = useState<{ id: string | null }>({
    id: null
  });

  if (!exists(user)) return null;

  const isPublisher =
    user.data().occupation === OccupationType.publishing.value ||
    user.data().occupation === OccupationType.press_association_manager.value;

  const publisherCanSeeNewspaperSelectinPlacement =
    canPublisherUserSeeNewspaperSelect(user, true);

  const tableTitleHeadStyles = 'rounded-t-md bg-white mx-3 my-5 px-3 text-left';
  const tableHeadStyles =
    'bg-base-2 px-6 border-b py-3 bg-white text-left text-sm leading-4 text-column-gray-400 text-md font-medium border-t tracking-wider';
  const tableBodyStyles = 'bg-white divide-y divide-gray-200';

  const tableRowStyles = 'p-0';

  let pageHeight = 'h-32';

  switch (page.length) {
    case 1:
      pageHeight = 'h-56 border-none';
      break;
    case 2:
      pageHeight = 'h-48 border-none';
      break;
    case 3:
      pageHeight = 'h-32 border-none';
      break;
    case 4:
      pageHeight = 'h-16 border-none';
      break;
    default:
      break;
  }

  return (
    <div className="min-w-full inline-block shadow-column-2 border border-column-gray-100 rounded-lg">
      <table
        id={id}
        className="min-w-full divide-y divide-gray-200 pt-4 border-b table-fixed"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup, titleIndex) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              id={`thead-${titleIndex}`}
              key={`thead-${titleIndex}`}
              className="h-18"
            >
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  scope="col"
                  id={`thead-column-${index}`}
                  key={`thead-column-${index}`}
                  className={
                    titleIndex === 0
                      ? tableTitleHeadStyles
                      : `${tableHeadStyles} ${column.width && column.width} ${
                          index === 0 && 'whitespace-no-wrap'
                        }`
                  }
                  onMouseEnter={() => setShowFilter({ id: column.id })}
                  onMouseLeave={() => setShowFilter({ id: null })}
                >
                  {column.render('Header')}
                  {titleIndex === 0 && (
                    <CGlobalFilter
                      setSearchValue={setSearchValue}
                      actions={actions}
                      setShowUserOnlyNotices={setShowUserOnlyNotices}
                      showUserOnlyNotices={showUserOnlyNotices}
                      searchValue={searchValue}
                    />
                  )}
                  {titleIndex === 1 && !column.disableSortBy && (
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="pl-2">&uarr;</span>
                        ) : (
                          <span className="pl-2">&darr;</span>
                        )
                      ) : (
                        <span
                          className={`${
                            showFilter.id === column.id
                              ? 'text-column-gray-400 pl-2'
                              : 'text-transparent pl-2'
                          }`}
                        >
                          &darr;
                        </span>
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && (
          <tbody {...getTableBodyProps()} className={tableBodyStyles}>
            {page.map((row: Row<any>, i: number) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={`tr-${i}`}
                  id={row.original.id}
                  className="table-row hover:bg-base-2 h-18"
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={`td-${index}`}
                        id={`td-${index}`}
                        className={tableRowStyles}
                      >
                        {cell.column.Header !== 'ACTIONS' ? (
                          <LinkTo
                            className="block"
                            href={`/${
                              row.original.isdraft ? 'place' : 'notice'
                            }/${
                              row.original.orginalnoticeid || row.original.id
                            }`}
                          >
                            {cell.render('Cell')}
                          </LinkTo>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {loading && (
        <span className="bg-white h-56 m-auto pt-24 text-center block">
          <div className="flex justify-center align-center w-full">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-column-gray-50 h-6 w-6" />
          </div>
        </span>
      )}
      {!loading && rows.length === 0 && (
        <>
          <div id="empty-table-banner" className="bg-white w-full">
            <div className="m-auto text-center pb-32 pt-20 w-100">
              <div
                style={{
                  clipPath: 'circle()',
                  marginBottom: '14px'
                }}
                id="empty-table-icon"
                className="inline-block justify-center bg-column-yellow-400 w-24 h-24 overflow-hidden"
              >
                <img
                  src="https://enotice-production.imgix.net/custom-documents/permalink/58d1.a511f-postman.gif"
                  className="scale-125"
                ></img>
              </div>
              <div className="mb-3 text-column-gray-700 text-lg font-semibold">
                {emptyHeaderText}
              </div>
              <div className="mb-6 text-column-gray-400 text-sm font-medium">
                {emptySubtitleText}
              </div>
              <Tooltip
                position="bottom"
                helpText={
                  showAllOrgsNotices &&
                  !publisherCanSeeNewspaperSelectinPlacement
                    ? 'Select a specific organization to upload notice'
                    : isPublisher &&
                      exists(activeOrganization) &&
                      !publisherReadyToUpload(activeOrganization, true) &&
                      !showAllOrgsNotices
                    ? 'Your account implementation is still being finalized. A member of the Column team will reach out.'
                    : ''
                }
              >
                <PlaceNoticeButton id="place-notice-button" />
              </Tooltip>
            </div>
          </div>
        </>
      )}
      {rows.length > 0 && page.length !== pageSize && page.length < 5 && (
        <p className={`bg-white ${pageHeight} m-auto pt-12 text-center`}></p>
      )}
      <footer className="px-5 py-0.5 bg-column-gray-25 h-18 flex items-center w-full border-t rounded-b-lg">
        <nav className="px-4 flex items-center justify-between sm:px-0 w-full">
          <div className="sm:block flex-1 flex">
            <p className="text-sm text-column-gray-400">
              Showing
              <span className="font-medium mx-1">
                {page.length > 0 ? tableState.currentPage * pageSize + 1 : 0}
              </span>
              to
              <span className="font-medium mx-1">
                {tableState.currentPage
                  ? tableState.currentPage * pageSize + page.length
                  : rows.length}
              </span>
              of
              <span id="total-results" className="font-medium mx-1">
                {tableState.total}
              </span>
              results
            </p>
          </div>
          <div className="-mt-px w-0 flex-1 flex justify-end">
            <select
              aria-invalid="false"
              value={pageSize}
              className="select select-main bg-transparent text-column-gray-300 mr-2 focus:outline-none font-medium text-sm"
              onChange={e => {
                setPageSize(Number(e.target.value));
                onRowsChange && onRowsChange(Number(e.target.value));
                onPageChange(0);
              }}
            >
              {[5, 10, 20, 50, 100].map(rowSize => (
                <option key={rowSize} value={rowSize}>
                  {rowSize} rows
                </option>
              ))}
            </select>
            <ReactPaginate
              previousLabel={<ChevronLeftIcon className="h-6 w-6" />}
              previousClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400"
              nextLabel={<ChevronRightIcon className="h-6 w-6" />}
              nextClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400"
              breakLabel={'...'}
              forcePage={tableState.currentPage}
              breakClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400"
              pageClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-column-gray-300 hover:text-column-gray-400 hover:border-column-gray-100"
              activeLinkClassName="focus:outline-none outline-none text-column-primary-500 border-column-primary-500"
              pageLinkClassName="px-4 text-sm"
              pageCount={Math.ceil(
                Math.min(tableState.total, pageSize * 100) / pageSize
              )}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pageTo => {
                onPageChange && onPageChange(pageTo.selected);
              }}
              containerClassName={'pagination flex'}
              activeClassName={
                'text-column-primary-500 border-column-primary-500 outline-none'
              }
            />
          </div>
        </nav>
      </footer>
    </div>
  );
}
const mapStateToProps = (state: EReduxState) => ({
  activeOrganization: state.auth.activeOrganization,
  showAllOrgsNotices: !!state.auth.showAllOrgsNotices,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch: any) => ({
  noticeActions: bindActionCreators(NoticesActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);
