export const NotarizationPreconditionData = {
  notice_has_ARS: {
    label: 'The notice must have affidavit reconciliation settings.',
    noticePassesLabel: 'This notice has affidavit reconciliation settings.',
    noticeDoesNotPassLabel:
      'This notice does not have affidavit reconciliation settings.',
    key: 'notice_has_ARS',
    value: 7,
    canBeOverridden: false
  },
  notice_has_invoice: {
    label: 'The notice must have an invoice.',
    noticePassesLabel: 'This notice has an invoice.',
    noticeDoesNotPassLabel: 'This notice does not have an invoice.',
    key: 'notice_has_invoice',
    value: 1,
    canBeOverridden: false
  },
  notice_is_pending: {
    label: 'The notice must have a pending status.',
    noticePassesLabel: 'This notice has a pending status.',
    noticeDoesNotPassLabel: 'This notice does not have a pending status.',
    key: 'notice_is_pending',
    value: 8,
    canBeOverridden: false
  },
  notice_does_not_have_affidavit: {
    label: 'The notice must not have an affidavit.',
    noticePassesLabel: 'This notice does not yet have an affidavit.',
    noticeDoesNotPassLabel: 'This notice already has an affidavit.',
    key: 'notice_does_not_have_affidavit',
    value: 2,
    canBeOverridden: true
  },
  reconciliation_start_date_is_before_last_publication_date: {
    label:
      'The reconciliation start date must be on or before the last publication date.',
    noticePassesLabel:
      'The reconciliation start date is on or before the last publication date.',
    noticeDoesNotPassLabel:
      'The reconciliation start date is after the last publication date.',
    key: 'reconciliation_start_date_is_before_last_publication_date',
    value: 3,
    canBeOverridden: true
  },
  last_publication_date_is_past: {
    label: "The notice's last publication date must be in the past.",
    noticePassesLabel: "All of this notice's publication dates have passed.",
    noticeDoesNotPassLabel:
      "Not all of this notice's publication dates have passed.",
    key: 'last_publication_date_is_past',
    value: 4,
    canBeOverridden: false
  },
  affidavits_enabled: {
    label: 'Affidavits must be enabled for the notice.',
    noticePassesLabel: 'Affidavits are enabled for this notice.',
    noticeDoesNotPassLabel: 'Affidavits are disabled for this notice.',
    key: 'affidavits_enabled',
    value: 5,
    canBeOverridden: true
  },
  all_runs_verified: {
    label: "All of the notice's runs must have been verified.",
    noticePassesLabel: "All of this notice's runs have been verified.",
    noticeDoesNotPassLabel: "Not all of this notice's runs have been verified.",
    key: 'all_runs_verified',
    value: 6,
    canBeOverridden: false
  }
};

export type NotarizationPreconditionType = typeof NotarizationPreconditionData;

export type NotarizationPreconditionKey = keyof NotarizationPreconditionType;

export const isNotarizationPreconditionKey = (
  key: string
): key is NotarizationPreconditionKey => {
  return !!NotarizationPreconditionData[key as NotarizationPreconditionKey];
};

export const getTypedNotarizationPreconditionKey = (
  key: string
): NotarizationPreconditionKey | null => {
  return isNotarizationPreconditionKey(key) ? key : null;
};

export type NotarizationPreconditionEnum =
  (typeof NotarizationPreconditionData)[NotarizationPreconditionKey];
