import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useAppSelector } from 'redux/hooks';
import { Push, push } from 'connected-react-router';
import { BellIcon } from '@heroicons/react/24/outline';
import AuthActions from 'redux/auth';
import { connect } from 'react-redux';

import { RoleType, InviteStatus } from 'lib/enums';
import { OPEN_INVITE_STATUSES } from 'lib/users';
import { getFirebaseContext } from 'utils/firebase';
import { safeStringify } from 'lib/utils/stringify';
import { ModalRequest } from 'types/requests';
import { ModalInvite } from 'types/invites';

import {
  calculateTimeDifferenceFromTimestamp,
  logAndCaptureException
} from 'utils';
import {
  ESnapshotExists,
  ENotification,
  EJoinRequest,
  EInvite,
  exists,
  EUser
} from 'lib/types';
import HeaderBarClickableIcon from 'layouts/appLayout/HeaderBarClickableIcon';
import { JoinRequestItem } from 'components/types';
import {
  acceptInvitesHelper,
  acceptRequestHelper,
  declineInviteHelper,
  declineRequestHelper,
  transformInvitesToActionCardInvites,
  transformRequestsToActionCard
} from 'components/modals/JoinOrganizationModals/helpers';
import InviteActionCard from 'components/invitesComponent/InviteActionCard';
import RequestActionCard from 'components/requestsComponents/RequestActionCard';
import Drawer from 'lib/components/Drawer';
import HorizontalDivider from 'components/HorizontalDivider';
import { ColumnButton } from 'lib/components/ColumnButton';
import { ColumnService } from 'lib/services/directory';
import EmptyNotificationTray from './EmptyNotificationTray';
import {
  getNotificationsEmoji,
  getNotificationTypeStyling
} from './NotificationTypesStyles';

type NotificationProps = {
  user: ESnapshotExists<EUser>;
  authActions: typeof AuthActions;
  push: Push;
};

const mapDispatchToProps = (dispatch: any) => ({
  push: (path: any) => dispatch(push(path)),
  authActions: bindActionCreators(AuthActions, dispatch)
});

function Notifications({ authActions, user, push }: NotificationProps) {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState<
    ESnapshotExists<ENotification>[]
  >([]);
  const [invites, setInvites] = useState<ESnapshotExists<EInvite>[]>([]);
  const [requests, setRequests] = useState<JoinRequestItem[]>([]);
  const [clearingNotifications, setClearingNotifications] = useState(false);
  const [decliningInvitesLoading, setDecliningInvitesLoading] = useState(false);
  const [acceptingInvitesLoading, setAcceptingInvitesLoading] = useState(false);
  const [transformedInvites, setTransformedInvites] = useState<
    ModalInvite[] | null
  >();
  const [transformedOrganizationRequests, setTransformedOrganizationRequests] =
    useState<ModalRequest[] | null>();
  const [joinRequests, setJoinRequests] = useState<
    ESnapshotExists<EJoinRequest>[]
  >([]);
  const [title, setTitle] = useState('General');
  const activeOrganization = useAppSelector(
    state => state.auth.activeOrganization || undefined
  );

  const tableTitleHeadStyles =
    'inline-block my-3 text-lg text-black text-left pt-3 focus:outline-none font-medium pb-3 border-b-2 border-transparent hover:border-b-2 hover:border-column-primary-600 hover:text-column-primary-900';
  const highlight =
    'border-b-2 border-column-primary-600 text-column-primary-900';
  const highlightCount = 'bg-column-primary-100 text-column-primary-900';
  const tableTitleTabStyles = 'ml-2 rounded-full px-3 py-0.25 text-sm';
  const emptyNotificationTrayHeight = 'my-48';

  const allNotifications =
    notifications.length +
    invites.length +
    requests.length +
    joinRequests.length;
  const invitesAndRequestsNotifications =
    invites.length + requests.length + joinRequests.length;

  const ctx = getFirebaseContext();
  const getUserNotifications = () => {
    return ctx
      .notificationsRef()
      .where('user', '==', user.ref)
      .where('inApp.read', '==', false)
      .orderBy('created', 'desc')
      .limit(51)
      .onSnapshot(notificationsSnaps => {
        setNotifications(notificationsSnaps.docs);
      });
  };

  const getInvites = () => {
    return ctx
      .invitesRef()
      .where('email', '==', user.data()?.email)
      .where('status', '==', InviteStatus.snoozed.value)
      .orderBy('createdAt', 'desc')
      .onSnapshot(invitesSnaps => {
        setInvites(invitesSnaps.docs);
      });
  };

  // Requests that individual send to organization at the time of registration,
  // this query get results for individual users
  const getIndividualRequests = () => {
    return ctx
      .joinRequestsRef()
      .where('email', '==', user.data().email)
      .where('status', 'in', OPEN_INVITE_STATUSES)
      .orderBy('createdAt', 'desc')
      .onSnapshot(async requestsSnaps => {
        await transformRequests(requestsSnaps.docs);
      });
  };

  // Requests that individual send to organization at the time of registration,
  // this query works to show notification for organization users
  const getOrganizationRequests = () => {
    if (
      exists(activeOrganization) &&
      user.data().roles?.[activeOrganization.id] === RoleType.admin.value
    ) {
      return ctx
        .joinRequestsRef()
        .where('organization', '==', activeOrganization.ref)
        .where('status', '==', InviteStatus.snoozed.value)
        .orderBy('createdAt', 'desc')
        .onSnapshot(async requestsSnaps => {
          setJoinRequests(requestsSnaps.docs);
        });
    }
    return () => {};
  };

  const transformOrganizationRequests = async () => {
    const transformedRequests = await transformRequestsToActionCard(
      joinRequests,
      ctx
    );
    setTransformedOrganizationRequests(transformedRequests);
  };

  const transformRequests = async (
    requests: ESnapshotExists<EJoinRequest>[]
  ) => {
    const requestItemsList: JoinRequestItem[] = [];
    await Promise.all(
      requests.map(async doc => {
        const organizationSnap = await doc.data().organization.get();
        if (!exists(organizationSnap)) return;
        const reqObject = {
          joinRequestRef: doc.ref,
          organizationName: organizationSnap.data().name
        };
        requestItemsList.push(reqObject);
      })
    );
    setRequests(requestItemsList);
  };

  const transformedUserInvites = async () => {
    const transformedInvites = await transformInvitesToActionCardInvites(
      invites
    );
    setTransformedInvites(transformedInvites);
  };

  useEffect(() => {
    const notificationsUnsub = getUserNotifications();
    const invitesUnsub = getInvites();
    const requestsUnsub = getIndividualRequests();
    return () => {
      notificationsUnsub();
      invitesUnsub();
      requestsUnsub();
    };
  }, []);

  useEffect(() => {
    if (exists(activeOrganization)) {
      const orgRequestsUnsub = getOrganizationRequests();
      return () => {
        orgRequestsUnsub();
      };
    }
  }, [activeOrganization?.id]);

  useEffect(() => {
    void transformedUserInvites();
  }, [safeStringify(invites)]);

  useEffect(() => {
    void transformOrganizationRequests();
  }, [safeStringify(joinRequests)]);

  const markNotificationAsRead = async (
    notificationSnap: ESnapshotExists<ENotification>
  ) => {
    const { inApp } = notificationSnap.data();
    const updateObj = { ...inApp };
    updateObj.read = true;
    if (exists(notificationSnap))
      await notificationSnap.ref.update({ inApp: updateObj });
  };

  // Render list of all General notifications
  const listOfGeneralNotifications = () =>
    notifications.map((docSnapshot, index) => {
      const { inApp, created, notice } = docSnapshot.data();
      const { img, styles } = getNotificationTypeStyling(inApp.key);
      const link =
        (inApp && inApp.link) || (notice && `/notice/${notice.id}`) || '';

      return (
        <div
          key={index}
          // inApp.key is not related to design -- it's purpose is to differentiate notifications in pendo
          className={`${inApp.key} flex py-5 px-6 cursor-pointer`}
          onClick={() => {
            setOpen(false);
            push(link);
            void markNotificationAsRead(docSnapshot);
          }}
        >
          <div
            className={`${styles} w-12 h-12 rounded-full flex items-center justify-center p-4`}
          >
            {img}
          </div>
          <div className={`${!inApp.body && 'flex items-center'} ml-5 w-full`}>
            <div className="flex w-full justify-between">
              <div className="text-column-primary-800 text-sm font-medium">
                {inApp.text}
                {getNotificationsEmoji(inApp.key)}
              </div>
              <div className="text-column-gray-300 text-xs font-medium">
                <p>{calculateTimeDifferenceFromTimestamp(created)}</p>
              </div>
            </div>
            <p className="mt-1 text-column-gray-400 text-xs font-medium">
              {inApp.body}
            </p>
          </div>
        </div>
      );
    });

  const updateUserRoles = (roleValue: string, index: number) => {
    if (transformedOrganizationRequests) {
      const currTransformRequests = [...transformedOrganizationRequests];

      currTransformRequests[index].role = RoleType.by_label(roleValue)!.value;
      setTransformedOrganizationRequests(currTransformRequests);
    }
  };

  // Render list of all snoozed invites
  const renderSnoozedInvitesList = () =>
    transformedInvites &&
    transformedInvites.map((invite, index) => {
      return (
        <div key={`user-invite-${invite.userInvite.id}`}>
          <InviteActionCard
            invite={invite}
            onAcceptClick={() => acceptInvite(invite.userInvite)}
            onDeclineClick={() => declineInvite(invite.userInvite)}
            organization={activeOrganization}
            index={index}
            className={'border-b'}
            type="invite"
          />
        </div>
      );
    });

  const renderJoinOrganizationRequests = () =>
    transformedOrganizationRequests &&
    transformedOrganizationRequests.map((request, index) => {
      return (
        <div key={`user-request-${request.userRequest.id}`}>
          <InviteActionCard
            request={request}
            index={index}
            type={'request'}
            className={'border-b'}
            organization={activeOrganization}
            updateUserRole={roleValue => updateUserRoles(roleValue, index)}
            onAcceptClick={() => acceptRequest(request)}
            onDeclineClick={() => declineRequest(request)}
          />
        </div>
      );
    });

  // Render pending/snoozed requests list
  const renderRequestsList = () =>
    requests.length > 0 &&
    requests.map((request, index) => {
      return (
        <div key={`user-request-${request.joinRequestRef.id}`}>
          <RequestActionCard
            request={request}
            index={index}
            className={'border-b'}
          />
        </div>
      );
    });

  const clearAllNotifications = async () => {
    await Promise.all(
      notifications.map(async notification => {
        await markNotificationAsRead(notification);
      })
    );
  };

  const declineInvite = async (inviteSnap: ESnapshotExists<EInvite>) => {
    await declineInviteHelper(user, inviteSnap);
  };

  const acceptInvite = async (inviteSnap: ESnapshotExists<EInvite>) => {
    const { organizationId, email } = inviteSnap.data();
    try {
      await acceptInvitesHelper(ctx, [inviteSnap], user, authActions);
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Failed to join organization from invite',
        {
          userEmail: email || '',
          orgId: organizationId || '',
          inviteId: inviteSnap.id || ''
        }
      );
    }
  };

  const acceptAllInvites = async () => {
    if (!transformedInvites?.length) {
      return;
    }
    try {
      await Promise.all(
        transformedInvites.map(async invite => {
          const inviteSnap = invite.userInvite;
          await acceptInvite(inviteSnap);
        })
      );
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error accepting all invites',
        {
          userId: user.id
        }
      );
    }
  };

  const declineAllInvites = async () => {
    if (!transformedInvites?.length) {
      return;
    }
    try {
      await Promise.all(
        transformedInvites.map(async invite => {
          const inviteSnap = invite.userInvite;
          await declineInvite(inviteSnap);
        })
      );
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error declining all invites',
        {
          userId: user.id
        }
      );
    }
  };

  const acceptRequest = async (joinRequest: ModalRequest) => {
    await acceptRequestHelper(ctx, [joinRequest]);
  };

  const declineRequest = async (joinRequest: ModalRequest) => {
    await declineRequestHelper([joinRequest]);
  };

  const acceptAllRequests = async () => {
    if (!transformedOrganizationRequests?.length) return;
    await acceptRequestHelper(ctx, transformedOrganizationRequests);
  };

  const declineAllRequests = async () => {
    if (!transformedOrganizationRequests?.length) return;
    await declineRequestHelper(transformedOrganizationRequests);
  };

  const closeDrawer = () => {
    setOpen(!open);
  };

  const header = (
    <div className="text-column-gray-500 font-semibold text-xl">
      Notifications
    </div>
  );

  return (
    <>
      <div className="relative">
        <div>
          <div className="flex inline-block relative bg-white p-1 rounded-full text-white hover:text-column-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <HeaderBarClickableIcon
              id="notifications-icon"
              icon={
                <>
                  {allNotifications > 0 && (
                    <span
                      className={`pt-0.25 absolute top-0 right-0 block h-4 w-4 rounded-circle bg-yellow-350 items-center justify-center text-column-gray-500 font-bold text-xxs -mt-1 -mr-1 ${
                        notifications.length > 50 && 'w-6'
                      }`}
                    >
                      {allNotifications > 50 ? '50+' : allNotifications}
                    </span>
                  )}
                  <BellIcon className="w-6 h-6" />
                </>
              }
              onClick={() => setOpen(!open)}
              label="Notifications"
            />
          </div>
        </div>
        {open && (
          <Drawer open={open} onClose={closeDrawer} header={header}>
            <>
              {/* Tabs for Notification Drawer begins */}
              <div className="h-16">
                <div className="inline-block">
                  <div className={'inline-block mx-6'}>
                    <button
                      onClick={() => setTitle('General')}
                      id="general"
                      className={`${tableTitleHeadStyles} ${
                        title === 'General'
                          ? highlight
                          : 'text-column-primary-800'
                      }`}
                    >
                      <div className="flex">
                        General
                        <p
                          className={`${tableTitleTabStyles} ${
                            title === 'General'
                              ? highlightCount
                              : 'bg-column-gray-50 text-column-primary-800'
                          }`}
                        >
                          {notifications.length > 50
                            ? '50+'
                            : notifications.length}
                        </p>
                      </div>
                    </button>
                  </div>
                  <div className={'inline-block mx-1'}>
                    <button
                      onClick={() => setTitle('Team Invites')}
                      id="team-invites"
                      className={`${tableTitleHeadStyles} ${
                        title === 'Team Invites'
                          ? highlight
                          : 'text-column-gray-500'
                      }`}
                    >
                      <div className="flex">
                        Team Invites
                        <p
                          className={`${tableTitleTabStyles} ${
                            title === 'Team Invites'
                              ? highlightCount
                              : 'bg-column-gray-50 text-column-primary-800'
                          }`}
                        >
                          {invitesAndRequestsNotifications > 50
                            ? '50+'
                            : invitesAndRequestsNotifications}
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <HorizontalDivider />
              {/* Tabs for Notification Drawer ends */}

              {/* list of notification designs */}
              <div>
                {title === 'General' &&
                  (notifications.length === 0 ? (
                    <div className={emptyNotificationTrayHeight}>
                      <EmptyNotificationTray />
                    </div>
                  ) : (
                    <div className="h-75vh max-h-full overflow-y-scroll hide-scrollbar md:pb-5 divide-y">
                      {listOfGeneralNotifications()}
                    </div>
                  ))}
                {title === 'Team Invites' &&
                  (invitesAndRequestsNotifications === 0 ? (
                    <div className={emptyNotificationTrayHeight}>
                      <EmptyNotificationTray />
                    </div>
                  ) : (
                    <div className="h-75vh max-h-full overflow-y-scroll hide-scrollbar md:pb-5">
                      {renderSnoozedInvitesList()}
                      {renderRequestsList()}
                      {renderJoinOrganizationRequests()}
                    </div>
                  ))}
              </div>

              {title === 'General' && notifications.length > 0 && (
                <div className="absolute bottom-0 px-6 py-3 shadow flex w-full justify-start border-t bg-white">
                  <ColumnButton
                    id="clear-all-notifications"
                    size="lg"
                    buttonText="Clear all"
                    loading={clearingNotifications}
                    onClick={async () => {
                      setClearingNotifications(true);
                      try {
                        await clearAllNotifications();
                      } finally {
                        setClearingNotifications(false);
                      }
                    }}
                    type="button"
                  />
                </div>
              )}

              {title === 'Team Invites' &&
                (invites.length > 0 || joinRequests.length > 0) && (
                  <div className="absolute bottom-0 px-6 py-3 shadow w-full flex justify-between border-t bg-white">
                    <ColumnButton
                      id="decline-all-invites"
                      size="lg"
                      buttonText="Decline all"
                      loading={decliningInvitesLoading}
                      onClick={async () => {
                        setDecliningInvitesLoading(true);
                        try {
                          await declineAllInvites();
                          await declineAllRequests();
                        } finally {
                          setDecliningInvitesLoading(false);
                        }
                      }}
                      type="button"
                    />
                    <ColumnButton
                      id="accept-all-invites"
                      primary
                      size="lg"
                      buttonText="Accept all"
                      loading={acceptingInvitesLoading}
                      onClick={async () => {
                        setAcceptingInvitesLoading(true);
                        try {
                          await acceptAllInvites();
                          await acceptAllRequests();
                        } finally {
                          setAcceptingInvitesLoading(false);
                        }
                      }}
                      type="button"
                    />
                  </div>
                )}
            </>
          </Drawer>
        )}
      </div>
      <style>{`
              button#team-invites:hover p {
                background-color: #EAF5FB;
                color: #2D9BDB;
              }
              
              button#general:hover p {
                background-color: #EAF5FB;
                color: #2D9BDB;
              }
            `}</style>
    </>
  );
}

export default connect(null, mapDispatchToProps)(Notifications);
