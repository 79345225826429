import React, { useState } from 'react';

import { EOrganization, ESnapshotExists } from 'lib/types';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { removeUndefinedFields } from 'lib/helpers';
import FullScreenModal from 'components/FullScreenModal';
import { TextField } from 'lib/components/TextField';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { getFirebaseContext } from 'utils/firebase';
import {
  PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED,
  PublisherGlobalRateSettingsUpdated
} from 'lib/types/events';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';

type GlobalRateSettingsType = Pick<EOrganization, 'taxPct'>;

const getGlobalRateSettingsFromActiveOrganization = (
  activeOrganization: ESnapshotExists<EOrganization>
): GlobalRateSettingsType => {
  const { taxPct } = activeOrganization.data();
  return {
    taxPct
  };
};

type GlobalRateSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onClose: () => void;
};

export default function GlobalRateSettings({
  activeOrganization,
  onClose
}: GlobalRateSettingsProps) {
  const user = useAppSelector(selectUser);
  const [globalRateSettings, setGlobalRateSettings] = useState(
    getGlobalRateSettingsFromActiveOrganization(activeOrganization)
  );
  const [saving, setSaving] = useState(false);
  const edited = !columnObjectsAreEqual(
    globalRateSettings,
    getGlobalRateSettingsFromActiveOrganization(activeOrganization)
  );

  const { taxPct } = globalRateSettings;

  return (
    <FullScreenModal
      headerText="Edit Settings"
      onClose={onClose}
      submittable={{
        buttonText: 'Save',
        disabled: !edited || saving,
        onSubmit: async () => {
          setSaving(true);

          if (user) {
            await getFirebaseContext()
              .eventsRef<PublisherGlobalRateSettingsUpdated>()
              .add(
                removeUndefinedFields({
                  type: PUBLISHER_GLOBAL_RATE_SETTINGS_UPDATED,
                  publisher: activeOrganization.ref,
                  createdAt: getFirebaseContext().timestamp(),
                  data: {
                    before:
                      getGlobalRateSettingsFromActiveOrganization(
                        activeOrganization
                      ),
                    after: globalRateSettings,
                    changedBy: user?.ref
                  }
                })
              );
          }

          await activeOrganization.ref.update(
            removeUndefinedFields({
              taxPct
            })
          );
          setSaving(false);
          onClose();
        }
      }}
      id="edit-global-rate-settings-form"
    >
      <CardGridLayout
        header={{
          title: 'Global Rate Settings',
          description: `Configure settings that apply by default to all rates for ${
            activeOrganization.data().name
          }.`
        }}
      >
        <GridInput fullWidth>
          <TextField
            type="number"
            value={taxPct ? taxPct.toString() : ''}
            onChange={newValue =>
              setGlobalRateSettings({
                ...globalRateSettings,
                taxPct: parseFloat(newValue)
              })
            }
            placeholder="0.000"
            step={0.001}
            max={25}
            min={0}
            labelText="What tax will Column apply by default to rates?"
            id="tax-amount"
          />
        </GridInput>
      </CardGridLayout>
    </FullScreenModal>
  );
}
