import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { OrderImage } from 'lib/orders/images';
import Firebase from 'EnoticeFirebase';
import { cdnIfy } from 'lib/helpers';
import { ColumnService } from 'lib/services/directory';

export const getClipArtStoragePathForOrganization = (organizationId: string) =>
  `documentcloud/clipArt/${organizationId}`;

export const useClipArt = (organizationId: string, uploads?: string[]) =>
  useAsyncEffect({
    fetchData: async (): Promise<OrderImage[]> => {
      const orgContents = await Firebase.storage()
        .ref(getClipArtStoragePathForOrganization(organizationId))
        .listAll();

      const folderContents = await Promise.all(
        orgContents.prefixes.flatMap(prefix => prefix.listAll())
      );

      const images = folderContents.flatMap(contents => contents.items);

      return images.map(item => ({
        imageUrl: cdnIfy(item.fullPath, {
          imgixTransformations: { fm: 'jpg' }
        }),
        sourcePath: item.fullPath
      }));
    },
    dependencies: [uploads?.length],
    errorConfig: {
      service: ColumnService.SETTINGS_MANAGEMENT,
      message: 'Failed to load clip art',
      tags: {
        organizationId
      }
    }
  });
