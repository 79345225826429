import api from 'api';
import { logAndCaptureException } from 'utils';
import {
  InvoiceType,
  OrderInvoice,
  PayInvoiceData,
  PublicNoticeInvoice
} from 'lib/types/invoices';
import { UserModel } from 'lib/model/objects/userModel';
import { getFirebaseContext } from 'utils/firebase';
import { InvoiceModel } from 'lib/model/objects/invoiceModel';
import { ColumnService } from 'lib/services/directory';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';

export async function getPayInvoicePaymentData(
  invoiceId: string
): Promise<ResponseOrError<PayInvoiceData>> {
  const { error, response } =
    await api.safeGetWithParams<'invoices/:invoiceId/pay-invoice-data'>(
      `invoices/${invoiceId}/pay-invoice-data`,
      undefined,
      { Authorization: 'Aloh0mor4' }
    );
  if (error !== null) {
    return wrapError(new Error(error));
  }

  const { serializedInvoice } = response;
  if (response.type === InvoiceType.PUBLIC_NOTICE) {
    const invoice = new InvoiceModel<PublicNoticeInvoice>(
      getFirebaseContext(),
      {
        serialized: serializedInvoice
      }
    );
    const { serializedAdvertiser } = response;
    const advertiser = new UserModel(getFirebaseContext(), {
      serialized: serializedAdvertiser
    });
    return wrapSuccess({
      ...response,
      advertiser,
      invoice
    });
  }

  if (response.type === InvoiceType.ORDER) {
    const invoice = new InvoiceModel<OrderInvoice>(getFirebaseContext(), {
      serialized: serializedInvoice
    });
    const { serializedAdvertiser } = response;

    const advertiser = serializedAdvertiser
      ? new UserModel(getFirebaseContext(), {
          serialized: serializedAdvertiser
        })
      : undefined;
    return wrapSuccess({
      ...response,
      invoice,
      advertiser,
      orderVersion: invoice.modelData.orderVersion
    });
  }
  const err = new Error('Unexpected invoice type in pay invoice data');
  logAndCaptureException(
    ColumnService.PAYMENTS,
    err,
    'Unexpected invoice type in pay invoice data',
    {
      invoiceId
    }
  );
  return wrapError(err);
}
