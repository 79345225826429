import moment from 'moment';
import { FilingTypeModel } from 'lib/model/objects/filingTypeModel';
import { PublishingSettingModel } from 'lib/model/objects/publishingSettingModel';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getClosestFuturePublishingDay } from 'lib/utils/deadlines';

export const getNextPublishingDate = (
  newspaper: ESnapshotExists<EOrganization>,
  publishingSetting: PublishingSettingModel,
  date: Date
) => {
  const { deadlines, deadlineOverrides } = publishingSetting.modelData;
  const { iana_timezone: newspaperTimezone } = newspaper.data();
  const nextPotentialPublishingDate = moment(date).add(1, 'days').toDate();
  const nextPublishingDate = getClosestFuturePublishingDay(
    deadlines || [],
    deadlineOverrides || {},
    newspaperTimezone,
    {},
    newspaper,
    nextPotentialPublishingDate
  );
  return moment(nextPublishingDate).format('YYYY-MM-DD');
};

export function calculateDefaultPublishingDates({
  newspaperSnap,
  publishingSetting,
  filingType,
  startingDate = new Date()
}: {
  newspaperSnap: ESnapshotExists<EOrganization>;
  publishingSetting: PublishingSettingModel;
  filingType?: FilingTypeModel;
  startingDate?: Date;
}): string[] {
  const newPublishingDates: string[] = [
    getNextPublishingDate(newspaperSnap, publishingSetting, startingDate)
  ];

  const applyFilingTypePublishingDateSettings = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_ORDER_FILING_TYPE_PUBLICATION_DATE_SETTINGS
  );

  if (filingType && applyFilingTypePublishingDateSettings) {
    const targetPublicationDateCount = filingType.initialPublicationDateCount;

    for (
      let i = newPublishingDates.length;
      i < targetPublicationDateCount;
      i++
    ) {
      const previousPublishingDateString =
        newPublishingDates[newPublishingDates.length - 1];

      const previousPublishingDate = moment(previousPublishingDateString)
        .tz(newspaperSnap.data().iana_timezone)
        .toDate();

      newPublishingDates.push(
        getNextPublishingDate(
          newspaperSnap,
          publishingSetting,
          previousPublishingDate
        )
      );
    }
  }

  return newPublishingDates;
}
