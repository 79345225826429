import { ColumnButton } from 'lib/components/ColumnButton';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { OrderModel } from 'lib/model/objects/orderModel';
import React, { useState } from 'react';
import { selectActiveOrganizationModel } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { logAndCaptureException } from 'utils';
import { Tooltip } from 'lib/components/Tooltip';
import { ResponseOrError } from 'lib/types/responses';
import { ColumnService } from 'lib/services/directory';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { ConfirmCancellationModal } from './ConfirmCancellationModal';

type CancelOrderButtonProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  onCancelOrder: (cancellationReason: string) => Promise<ResponseOrError<void>>;
  isAnonymousUser: boolean;
};

export function CancelOrderButton({
  order,
  orderDetail,
  onCancelOrder,
  isAnonymousUser
}: CancelOrderButtonProps) {
  const activeOrganization = useAppSelector(selectActiveOrganizationModel);
  const [showConfirmCancellationModal, setShowConfirmCancellationModal] =
    useState(false);
  const refundAmount = orderDetail.modelData.pricing?.totalInCents ?? 0;

  const { value: canCancelData } = useAsyncEffect({
    fetchData: async () => {
      const { response: canCancelOrder, error: canCancelOrderError } =
        await order.getUserCanCancelOrder({
          activeOrganization,
          isAnonymousUser
        });
      if (canCancelOrderError) {
        logAndCaptureException(
          ColumnService.ORDER_MANAGEMENT,
          canCancelOrderError,
          'Error determining whether user can cancel order',
          {
            orderId: order.id,
            activeOrganizationId: activeOrganization?.id,
            service: ColumnService.ORDER_MANAGEMENT
          }
        );
        return {
          canCancel: false
        };
      }

      return canCancelOrder;
    },
    dependencies: [order.id, order.modelData.status, activeOrganization?.id],
    initialData: {
      canCancel: false,
      reason: undefined
    }
  });

  return (
    <>
      <Tooltip helpText={canCancelData?.reason ?? ''} position="bottom">
        <ColumnButton
          id="cancel-order-button"
          type="button"
          onClick={() => setShowConfirmCancellationModal(true)}
          buttonText={'Cancel order'}
          disabled={!canCancelData?.canCancel}
        />
      </Tooltip>
      {showConfirmCancellationModal && (
        <ConfirmCancellationModal
          onClose={() => setShowConfirmCancellationModal(false)}
          onCancelOrder={onCancelOrder}
          orderId={order.id}
          refundAmount={refundAmount}
        />
      )}
    </>
  );
}
