import { isRateWithWeeklyPricingPeriod } from '../types/rates';
import { isNoticePricingParameters, PricingParameters } from '.';
import { NoticeType } from '../enums';
import { AdRate, EDisplayParams, EOrganization } from '../types';
import { calculateDBPrice } from './calculateDbPrice';
import calculateFee from './calculateFee';
import findDayRate from './findDayRate';
import { getPricingPeriodsFromPublicationDates } from './periodBasedPricing';

export const calculateBoldPrices = (
  rateRecord: AdRate,
  displayParameters: EDisplayParams
) => {
  if (rateRecord?.bold_words && displayParameters?.boldWords) {
    return displayParameters.boldWords * rateRecord.bold_words;
  }

  // if we have a flat bold rate tied to the notice add that on top
  // of the price charged if the notice contains a bold word
  if (rateRecord?.flatBoldPricing && displayParameters?.boldWords) {
    return rateRecord.flatBoldPricing;
  }

  if (
    rateRecord?.line_with_bold_words &&
    displayParameters?.nonTableBoldedLines
  ) {
    return (
      displayParameters.nonTableBoldedLines * rateRecord.line_with_bold_words
    );
  }

  return 0;
};

/**
 * The one true pricing function is createDBPricingObjectFromData, so if you're using this outside
 * of `lib/pricing`, you're probably doing something wrong!
 *
 * Calculate the "db price" in cents for a single pricing period of a notice, classified order, or obits order. This is
 * the default price unless it is modified in the invoice step.
 *
 * NOTE: this used to be 'calculateSingleRunPrice' but we modified this to be on a Pricing Period basis
 */

const calculateSinglePeriodPrice = (
  pricingParameters: PricingParameters,
  displayParameters: EDisplayParams,
  newspaper: EOrganization,
  rate: AdRate,
  periodNumber: number
) => {
  const pricingPeriods = getPricingPeriodsFromPublicationDates(
    pricingParameters.publicationDates,
    rate,
    newspaper.iana_timezone
  );
  const numPeriods = pricingPeriods.numberOfPricingPeriods;

  if (periodNumber >= numPeriods) {
    throw new Error(
      `Cannot compute pricing for pricing period #${periodNumber} of notice with ${numPeriods} pricing periods`
    );
  }

  const isWeeklyPeriodRate = isRateWithWeeklyPricingPeriod(rate);

  const publicationDate = pricingParameters.publicationDates[periodNumber];

  const { dayRate } = isWeeklyPeriodRate
    ? { dayRate: 0 }
    : findDayRate(rate, publicationDate);

  const columns =
    displayParameters.columns ||
    (isNoticePricingParameters(pricingParameters) &&
      pricingParameters.columns) ||
    1;
  const baseDbPrice = calculateDBPrice(
    pricingParameters,
    rate,
    displayParameters,
    numPeriods,
    columns,
    dayRate,
    periodNumber
  );

  // Display ads can't have bold pricing
  const boldPrice =
    isNoticePricingParameters(pricingParameters) &&
    pricingParameters.noticeType === NoticeType.display_ad.value
      ? 0
      : calculateBoldPrices(rate, displayParameters);

  const fee = calculateFee(newspaper, numPeriods, periodNumber) ?? 0;

  const imageFee =
    (displayParameters.images?.length ?? 0) * (rate.images?.flatRate ?? 0);

  // Round to the nearest cent at the end
  const priceInCents = Math.round(baseDbPrice + boldPrice + fee + imageFee);
  if (rate.runMinimumInCents && priceInCents < rate.runMinimumInCents) {
    return rate.runMinimumInCents;
  }
  return priceInCents;
};

export default calculateSinglePeriodPrice;
