import handlebars from 'handlebars';
import moment, { Moment } from 'moment';
import { FirebaseTimestamp } from '../types';
import { dateTimeLikeToDate, dateTimeLikeToTimestamp } from '../date';
import {
  DateParseError,
  MissingParameters
} from '../errors/NoticePreviewErrors';

type Conjunctions = {
  default: string;
  final: string;
  months?: string;
  spaces?: string;
};

const default_conjunctions: Conjunctions = { default: ',', final: ' and' };

const getDelimiter = (
  conjunctions: Conjunctions,
  index: number,
  lastIndex: number
) => {
  if (index === lastIndex) {
    return '';
  }

  const delimiter =
    index === lastIndex - 1 && conjunctions.final
      ? conjunctions.final
      : conjunctions.default;

  return delimiter
    ? `${delimiter}${conjunctions.spaces === 'false' ? '' : ' '}`
    : '';
};

const getConjunction = (
  conjunctions: Conjunctions | undefined,
  index: number,
  dates: Moment[]
) => {
  if (!conjunctions) {
    return '';
  }

  const lastIndex = dates.length - 1;

  if (
    index !== lastIndex &&
    dates[index].month() !== dates[index + 1].month() &&
    conjunctions.months
  ) {
    return conjunctions.months;
  }

  return getDelimiter(conjunctions, index, lastIndex);
};

const getMonthFormatAndDecorators = (footerTag: string) => {
  const monthTokens = /(Mo|MM?M?M?)/g;
  let monthDecorators = false;

  try {
    let [monthFormat] = footerTag.match(monthTokens) as RegExpMatchArray;

    // ..{(***}... -> ***
    const monthAndDecorators = footerTag.match(/\{.*?\}/);
    if (
      Array.isArray(monthAndDecorators) &&
      typeof monthAndDecorators[0] === 'string'
    ) {
      monthDecorators = true;
      monthFormat = monthAndDecorators[0].slice(1, -1);
    }

    return { monthFormat, monthDecorators };
  } catch (err) {
    throw new DateParseError('month', footerTag);
  }
};

const getDayFormat = (footerTag: string) => {
  const dayTokens = /(Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?)/g;

  try {
    const [dayFormat] = footerTag.match(dayTokens) as RegExpMatchArray;

    return dayFormat;
  } catch (err) {
    throw new DateParseError('day', footerTag);
  }
};
const getYearFormat = (footerTag: string) => {
  const yearTokens = /(YYYYYY|YYYYY|YYYY|YY)/g;

  let yearFormat: string | null;
  try {
    [yearFormat] = footerTag.match(yearTokens) as RegExpMatchArray;
  } catch (err) {
    yearFormat = null;
  }
  return yearFormat;
};

export const squash = (
  footerTag: string,
  publicationDates: FirebaseTimestamp[],
  conjunctions?: Conjunctions
) => {
  if (!publicationDates || publicationDates.length === 0) return '';

  const dayFormat = getDayFormat(footerTag);

  const { monthFormat, monthDecorators } =
    getMonthFormatAndDecorators(footerTag);
  const yearFormat = getYearFormat(footerTag);

  const dates = publicationDates.map(timestamp => moment(timestamp.toDate()));

  const lastIndex = dates.length - 1;

  let formattedString = '';

  let previousMonth = -1;

  for (let i = 0; i < dates.length; i++) {
    const currentDate = dates[i];

    if (currentDate.month() !== previousMonth) {
      formattedString += `${currentDate.format(monthFormat)}${
        monthDecorators ? '' : ' '
      }`;

      previousMonth = currentDate.month();
    }

    formattedString += currentDate.format(dayFormat);

    const nextDate = dates[i + 1];

    if (
      yearFormat &&
      (i === lastIndex || currentDate.year() !== nextDate.year())
    ) {
      formattedString += `, ${currentDate.format(yearFormat)}`;
    }

    formattedString += getConjunction(conjunctions, i, dates);
  }

  return formattedString.trim();
};

export const date = (
  footerTag: string,
  publicationDates: FirebaseTimestamp[],
  conjunctions?: Conjunctions
) => {
  let format: string;
  let separator: string;

  if (!publicationDates || publicationDates.length === 0) return '';

  try {
    // ...(***)... -> ***
    format = footerTag.match(/\(.*?\)/)![0].slice(1, -1);
  } catch (err) {
    throw new DateParseError('date', footerTag);
  }
  try {
    // ...[***]... -> ***
    separator = footerTag.match(/\[.*?\]/)![0].slice(1, -1);
  } catch (err) {
    separator = '';
  }

  if (conjunctions) {
    const dates = publicationDates.map(
      (timestamp, i) =>
        `${moment(timestamp.toDate()).format(format)}${getDelimiter(
          conjunctions,
          i,
          publicationDates.length - 1
        )}`
    );

    return dates.join('');
  }

  return publicationDates
    .map(t => `${moment(t.toDate()).format(format)}`)
    .join(separator);
};

handlebars.registerHelper('ARRAY', function (...args: any[]) {
  return Array.prototype.slice.call(args, 0, -1);
});

handlebars.registerHelper(
  'SLICE',
  function (arr: string[], start: number, finish: number) {
    return (arr || []).slice(
      start,
      typeof finish === 'number' ? finish : undefined
    );
  }
);

handlebars.registerHelper('SQUASH_DATES', (a: any) => {
  const {
    hash: { dates, format, conjunctions }
  } = a;

  if (!dates || !dates.length) return '';

  const parsedConjuct = conjunctions ? JSON.parse(conjunctions) : null;

  return squash(format, dates, parsedConjuct || default_conjunctions);
});

handlebars.registerHelper('FORMAT_DATES', (a: any) => {
  if (!a) throw new MissingParameters();
  const {
    hash: { dates, format, conjunctions }
  } = a;

  if (!dates || dates.length === 0) return '';

  if (dates && dates.length === 1)
    return moment(dateTimeLikeToDate(dates[0])!).format(format);

  const parsedConjuct = conjunctions ? JSON.parse(conjunctions) : null;

  return date(`(${format})`, dates, parsedConjuct || default_conjunctions);
});

handlebars.registerHelper(
  'ADD_DATES',
  (dates: any, days: string, months: string, years: string) => {
    return (dates || []).map((d: any) => {
      const m = moment(dateTimeLikeToDate(d) as Date);
      if (days) m.add(days, 'days');
      if (months) m.add(months, 'months');
      if (years) m.add(years, 'years');
      return dateTimeLikeToTimestamp(m.toDate());
    });
  }
);

handlebars.registerHelper('FORMAT_MONEY', (value: number) => {
  if (!value) return '';
  return parseFloat(`${value}`).toFixed(2);
});

handlebars.registerHelper('ROUND', (value: number, digits: number) => {
  if (!digits) return value;
  return parseFloat(`${value}`).toFixed(digits);
});

handlebars.registerHelper(
  'FORMAT_DATE',
  function (dateTimeLike: any, format: any) {
    if (!dateTimeLike) return '';
    const date = dateTimeLikeToDate(dateTimeLike);
    return moment(date!).format(format);
  }
);

handlebars.registerHelper('GET_INDEX', (array: any[], index: number) => {
  if (!array?.length) return null;
  return array[index];
});

handlebars.registerHelper('LENGTH', (a: any) => {
  return (a || []).length;
});

handlebars.registerHelper(
  'MATH',
  function (lv: string, operator: string, rv: string) {
    const lvalue = parseFloat(lv);
    const rvalue = parseFloat(rv);
    return (
      {
        '+': lvalue + rvalue,
        '-': lvalue - rvalue,
        '*': lvalue * rvalue,
        '/': lvalue / rvalue,
        '%': lvalue % rvalue
      } as string | any
    )[operator];
  }
);

handlebars.registerHelper('UPPERCASE', (a: string) => {
  return a ? a.toUpperCase() : a;
});

handlebars.registerHelper('FIRST', (...a: any[]) => {
  return a.find(Boolean) || '';
});

handlebars.registerHelper('LAST', (a: any[]) => {
  return [a[a.length - 1]];
});

handlebars.registerHelper('times', function (n: any, block: any) {
  let accum = '';
  for (let i = 0; i < n; ++i) accum += block.fn(i);
  return accum;
});

handlebars.registerHelper('isdefined', function (value: any) {
  return value !== undefined;
});

handlebars.registerHelper('equals', function (arg1: any, arg2: any) {
  return arg1 === arg2;
});

handlebars.registerHelper('startswith', function (arg1: string, arg2: string) {
  return arg1 && typeof arg1 === 'string' ? arg1.startsWith(arg2) : false;
});

handlebars.registerHelper('any', function (...args: any[]) {
  /* The last argument of a destructured iterative argument list
  in a registered handlebar is the options object, which will always
  be truthy, so we have to ignore it in the function below */
  return args.some(arg => args.indexOf(arg) !== args.length - 1 && !!arg);
});

// Helper to implement the and/or handlebars helper
// Taken from https://gist.github.com/servel333/21e1eedbd70db5a7cfff327526c72bc5
const reduceOp = function (args: any, reducer: any) {
  // eslint-disable-next-line no-param-reassign
  args = Array.from(args);
  args.pop(); // => options
  const first = args.shift();
  return args.reduce(reducer, first);
};

handlebars.registerHelper({
  and() {
    // eslint-disable-next-line prefer-rest-params
    return reduceOp(arguments, (a: any, b: any) => a && b);
  },
  or() {
    // eslint-disable-next-line prefer-rest-params
    return reduceOp(arguments, (a: any, b: any) => a || b);
  }
});

export const EHandlebars = handlebars;
