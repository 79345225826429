import classNames from 'classnames';
import React from 'react';

type FileUploadFormattingOptionProps = {
  id: string;
  headerText: string;
  descriptionText: string;
  onHandleClick: () => void;
  disabled?: boolean;
};

export function FileUploadFormattingOption({
  id,
  headerText,
  descriptionText,
  onHandleClick,
  disabled
}: FileUploadFormattingOptionProps) {
  return (
    <button
      id={id}
      className={classNames(
        'group mt-5 h-20 py-4 px-5 flex items-center justify-between rounded border shadow-2 w-full focus:outline-none',
        {
          'cursor-not-allowed opacity-75 bg-column-gray-25 border-column-gray-100':
            disabled,
          'cursor-pointer hover:border-column-primary-500 bg-white border-column-gray-150':
            !disabled
        }
      )}
      onClick={onHandleClick}
      disabled={disabled}
    >
      <div className="flex flex-col items-start">
        <p
          className={classNames('font-medium text-sm', {
            'text-column-gray-300': disabled,
            'text-column-gray-600 group-hover:text-column-primary-500':
              !disabled
          })}
        >
          {headerText}
        </p>
        <p
          className={classNames('mt-2 font-medium text-xs', {
            'text-column-gray-300': disabled,
            'text-column-gray-400': !disabled
          })}
        >
          {descriptionText}
        </p>
      </div>
      <div
        className={classNames(
          'h-4 w-4 rounded-full border border-column-gray-200',
          {
            'bg-column-gray-50': disabled,
            'bg-white group-hover:bg-column-primary-100 group-hover:border-column-primary-500':
              !disabled
          }
        )}
      />
    </button>
  );
}
