import { Popover } from 'lib/components/Popover';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { NewspaperOrdersFormData } from 'routes/ads/place/PlacementFlowStepSelector';
import NewspaperOrderInfoRow from './NewspaperOrderInfoRow';

type NewspaperOrderInfoProps = {
  newspaperOrderFormData: NewspaperOrdersFormData;
  filingTypeName: string;
};

export function NewspaperOrderInfo({
  newspaperOrderFormData,
  filingTypeName
}: NewspaperOrderInfoProps) {
  const [firstNewspaperOrder, ...popoverNewspaperOrders] =
    newspaperOrderFormData;
  return (
    <div className="flex flex-row items-center gap-3">
      <NewspaperOrderInfoRow
        filingTypeName={filingTypeName}
        newspaperOrder={firstNewspaperOrder}
      />
      {popoverNewspaperOrders.length > 0 && (
        <>
          <div className="font-medium text-sm">
            +{popoverNewspaperOrders.length}
          </div>
          <Popover
            id="newspaper-order-info-popover"
            activator={<ChevronDownIcon className="w-4 h-4 cursor-pointer" />}
            alignment="right"
          >
            <div className="flex flex-col m-2 gap-2">
              {popoverNewspaperOrders.map((newspaperOrder, index) => (
                <div
                  className={classNames('w-full', {
                    'border-t border-column-gray-100 pt-2': !!index
                  })}
                  key={`${newspaperOrder.newspaper?.id}-${newspaperOrder.publishingMedium}-popover-item`}
                >
                  <NewspaperOrderInfoRow
                    filingTypeName={filingTypeName}
                    newspaperOrder={newspaperOrder}
                    inPopover
                  />
                </div>
              ))}
            </div>
          </Popover>
        </>
      )}
    </div>
  );
}
