import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';
import api from 'api';
import ToastActions from 'redux/toast';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import {
  ENotice,
  ERef,
  ERequestTypes,
  EResponseTypes,
  ESnapshotExists
} from 'lib/types';
import { ManualBuildAdRequestEvent } from 'lib/types/events';
import React, { useState } from 'react';
import { logAndCaptureException } from 'utils';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { AffinityXSyncStatus } from './helpers';

const createCancelBuildAdRequestEvent = async ({
  noticeRef,
  initialBuildAdRequestEvent
}: {
  noticeRef: ERef<ENotice>;
  initialBuildAdRequestEvent: ERef<ManualBuildAdRequestEvent>;
}) => {
  const reqBody: ERequestTypes['integrations/async-design/notice/:noticeId/cancel-build-ad-request'] =
    {
      buildAdRequestEventId: initialBuildAdRequestEvent.id
    };

  try {
    const resp: EResponseTypes['integrations/async-design/notice/:noticeId/cancel-build-ad-request'] =
      await api.post(
        `integrations/async-design/notice/${noticeRef.id}/cancel-build-ad-request`,
        reqBody
      );
    if (!resp.success) {
      throw new Error(resp.error);
    }
  } catch (err) {
    logAndCaptureException(
      ColumnService.AFFINITY_X,
      err,
      'Problem creating CancelBuildAdRequestEvent on client',
      {
        noticeId: noticeRef.id,
        buildAdRequestEventId: initialBuildAdRequestEvent.id
      }
    );
  }
};

type AffinityXSyncHelpTextProps = {
  noticeSnap: ESnapshotExists<ENotice>;
  syncStatus: AffinityXSyncStatus | null;
  noticeIsAfterDeadline: boolean;
  orderNumber: string | null;
  mostRecentTriggerEvent:
    | ESnapshotExists<ManualBuildAdRequestEvent>
    | undefined;
  incrementOrderNumber: () => void;
};

type AffinityXSyncHelpTextInnerProps = {
  icon: React.ReactElement;
  text: React.ReactElement | string;
  messageType: 'info' | 'error';
};

function AffinityXSyncHelpTextInner({
  icon,
  text,
  messageType
}: AffinityXSyncHelpTextInnerProps) {
  const MESSAGE_TYPE_STYLES: Record<
    AffinityXSyncHelpTextInnerProps['messageType'],
    string
  > = {
    info: 'text-column-gray-400',
    error: 'text-red-600'
  };
  const messageTypeStyle = MESSAGE_TYPE_STYLES[messageType];

  return (
    <div
      className={`flex flex-row items-center mt-4 ${messageTypeStyle} text-sm`}
    >
      {icon}
      <div className="pl-1">{text}</div>
    </div>
  );
}

export default function AffinityXSyncHelpText({
  syncStatus,
  noticeIsAfterDeadline,
  orderNumber,
  noticeSnap,
  mostRecentTriggerEvent,
  incrementOrderNumber
}: AffinityXSyncHelpTextProps) {
  const dispatch = useAppDispatch();
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [showGenerateNewOrderNumberModal, setShowGenerateNewOrderNumberModal] =
    useState(false);

  const handleCancelOrder = async () => {
    if (!mostRecentTriggerEvent || !orderNumber) {
      return;
    }

    try {
      await createCancelBuildAdRequestEvent({
        noticeRef: noticeSnap.ref,
        initialBuildAdRequestEvent: mostRecentTriggerEvent.ref
      });
    } catch (err) {
      logAndCaptureException(
        ColumnService.AFFINITY_X,
        err,
        'Error creating cancel build ad request event',
        { noticeId: noticeSnap.id }
      );
      return;
    }

    setShowCancelOrderModal(false);
    dispatch(
      ToastActions.toastSuccess({
        headerText: `You've successfully cancelled the order`,
        bodyText: `You can sync again by clicking on the help text to generate a new order number.`
      })
    );
  };
  return (
    <>
      {showCancelOrderModal && (
        <CancelOrSubmitModal
          onClose={() => setShowCancelOrderModal(false)}
          header="Cancel sync to AffinityX?"
          primaryButtonText="Cancel"
          destructive
          tertiaryButtonText="Back"
          onSubmit={handleCancelOrder}
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            {noticeIsAfterDeadline &&
              syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS && (
                <p>
                  The ad deadline for this notice has passed. If you cancel this
                  sync to AffinityX, you may not have time to re-sync without
                  editing the notice's publication dates.
                </p>
              )}
            {!noticeIsAfterDeadline &&
              syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS && (
                <p>
                  Upon cancellation you may edit the notice and re-sync to
                  AffinityX.
                </p>
              )}
            {syncStatus ===
              AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION && (
              <p>
                Clicking "Cancel" will delete this order in AffinityX. Are you
                sure you want to cancel?
              </p>
            )}
          </div>
        </CancelOrSubmitModal>
      )}
      {showGenerateNewOrderNumberModal && (
        <CancelOrSubmitModal
          onClose={() => setShowGenerateNewOrderNumberModal(false)}
          header="Generate new order number?"
          primaryButtonText="Continue"
          tertiaryButtonText="Back"
          onSubmit={() => {
            incrementOrderNumber();
            setShowGenerateNewOrderNumberModal(false);
          }}
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            Clicking "Continue" will generate a new order number to sync to
            AffinityX. Do you wish to continue?
          </div>
        </CancelOrSubmitModal>
      )}
      {syncStatus === AffinityXSyncStatus.READY_TO_SYNC && (
        <AffinityXSyncHelpTextInner
          icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
          text="Set layout and confirm notice to start sync."
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_IN_PROGRESS && (
        <AffinityXSyncHelpTextInner
          icon={<CheckCircleIcon className="h-4 w-4" />}
          text={
            <p>
              Sync in progress.{' '}
              <span
                className="text-primary-500 cursor-pointer hover:underline"
                onClick={() => setShowCancelOrderModal(true)}
              >
                Click here to cancel it.
              </span>
            </p>
          }
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED && (
        <AffinityXSyncHelpTextInner
          icon={
            <QuestionMarkCircleIcon className="h-4 w-4 self-start mt-1 -mr-2" />
          }
          text={
            <p className="max-w-80 text-center">
              Sync cancelled.{' '}
              <span
                className="text-primary-500 cursor-pointer hover:underline"
                onClick={() => setShowGenerateNewOrderNumberModal(true)}
              >
                Click here
              </span>{' '}
              to generate a new order number and sync again.
            </p>
          }
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_SUCCESSFUL && (
        <AffinityXSyncHelpTextInner
          icon={<QuestionMarkCircleIcon className="h-4 w-4" />}
          text="You can update the order by clicking the sync button."
          messageType="info"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION && (
        <AffinityXSyncHelpTextInner
          icon={<ExclamationTriangleIcon className="h-4 w-4" />}
          text="Your sync to AffinityX failed. Please try again."
          messageType="error"
        />
      )}
      {syncStatus === AffinityXSyncStatus.SYNC_FAILED_AFTER_ORDER_CREATION && (
        <AffinityXSyncHelpTextInner
          icon={
            <ExclamationTriangleIcon className="h-4 w-4 self-start mt-1 -mr-2" />
          }
          text={
            <p className="max-w-80 text-center">
              Sync failed. See the Activity Log for details or{' '}
              <span
                className="text-primary-500 cursor-pointer hover:underline"
                onClick={() => setShowCancelOrderModal(true)}
              >
                click here to cancel the order.
              </span>
            </p>
          }
          messageType="error"
        />
      )}
    </>
  );
}
