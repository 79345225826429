import { EResponseTypes } from 'lib/types';
import {
  ResponseOrColumnError,
  wrapError,
  wrapSuccess
} from 'lib/types/responses';
import { ProductSiteSetting } from 'lib/types/productSiteSetting';
import { ProductSiteSettingModel } from 'lib/model/objects/productSiteSettingModel';
import { logWarn } from 'utils/logger';
import api from 'api';
import {
  BadRequestError,
  InternalServerError,
  NotFoundError,
  wrapErrorAsColumnError
} from 'lib/errors/ColumnErrors';
import { getFirebaseContext } from 'utils/firebase';
import { getModelFromSnapshot } from 'lib/model';
import { safeGetOrThrow } from 'lib/safeWrappers';

export const updateSiteSetting = async (
  newspaperId: string,
  siteSettingModel: ProductSiteSettingModel,
  siteSettingData: Partial<ProductSiteSetting>
): Promise<ResponseOrColumnError<ProductSiteSettingModel>> => {
  const { error: updateError } =
    await api.safePostWithParams<'site-settings/:siteSettingId'>(
      `site-settings/${siteSettingModel.id}`,
      {
        newspaperId,
        productSiteSetting: siteSettingData
      }
    );
  if (updateError !== null) {
    logWarn(`Failed to save site settings: ${updateError}`, {
      newspaperId,
      productLine: siteSettingModel.modelData.product
    });
    return wrapError(new BadRequestError(updateError));
  }
  await siteSettingModel.refreshData();
  return wrapSuccess(siteSettingModel);
};

export const createSiteSetting = async (
  newspaperId: string,
  siteSettingData: ProductSiteSetting
): Promise<ResponseOrColumnError<ProductSiteSettingModel>> => {
  const newspaperRef = getFirebaseContext().organizationsRef().doc(newspaperId);
  const logData = {
    newspaperId,
    productLine: siteSettingData.product
  };
  const {
    response: createResult,
    error: createError
  }: EResponseTypes['site-settings'] = await api.safePost('site-settings', {
    newspaperId,
    productSiteSetting: siteSettingData
  });
  if (createError !== null) {
    logWarn(`Failed to save site settings: ${createError}`, logData);
    return wrapError(new InternalServerError(createError));
  }
  const { response: productSiteSettingSnap, error: getError } =
    await safeGetOrThrow(
      getFirebaseContext()
        .organizationProductSiteSettingsRef(newspaperRef)
        .doc(createResult.productSiteSettingId)
    );
  if (getError) {
    logWarn(`Failed to get site settings: ${getError.message}`, logData);
    return wrapErrorAsColumnError(getError, NotFoundError);
  }
  const newSiteSetting = getModelFromSnapshot(
    ProductSiteSettingModel,
    getFirebaseContext(),
    productSiteSettingSnap
  );
  return wrapSuccess(newSiteSetting);
};
