import { useState } from 'react';
import { getWordsFromNumber } from 'lib/helpers';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { EOrganization, ESnapshotExists, ETemplate, exists } from 'lib/types';
import { userIsSuper } from 'utils/permissions';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import {
  getColumnRangeConfigForPublisher,
  ColumnRangeConfig
} from 'lib/notice/columns';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import {
  MADLIB_ID_PATTERN,
  TYPEFORM_ID_PATTERN,
  getEntryMethodFromNoticeType,
  getOrganizationDefaultColumnDescription
} from '../noticeTypesHelpers';

/**
 * Converts a set of templates into a set of options for a select input
 */
const templatesToTemplateSelectOptions = (
  templates: ESnapshotExists<ETemplate>[]
) => {
  const templateOptions = [
    {
      label: 'Default template',
      value: 'default'
    }
  ];
  templates.forEach(template => {
    templateOptions.push({
      label: template.data().name,
      value: template.id
    });
  });
  return templateOptions;
};

const booleanToString = (
  val: boolean | undefined,
  trueString: string,
  falseString: string,
  undefinedString: string
) => {
  if (val === true) {
    return trueString;
  }

  if (val === false) {
    return falseString;
  }

  return undefinedString;
};

type NoticeFormatCardProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onUpdateNoticeType: (newValue: CustomNoticeFilingType) => void;
  updatedNoticeType: CustomNoticeFilingType;
  templates: ESnapshotExists<ETemplate>[];
};

export default function NoticeFormatCard({
  activeOrganization,
  onUpdateNoticeType,
  updatedNoticeType,
  templates
}: NoticeFormatCardProps) {
  const user = useAppSelector(selectUser);
  const [entryMethod, setEntryMethod] = useState(
    getEntryMethodFromNoticeType(updatedNoticeType)
  );
  const linerPublisherColumnConfig = getColumnRangeConfigForPublisher(
    activeOrganization,
    false
  );
  const displayPublisherColumnConfig = getColumnRangeConfigForPublisher(
    activeOrganization,
    true
  );
  const linerColumnOptions = getColumnRangeOptions(linerPublisherColumnConfig);
  const displayColumnOptions = getColumnRangeOptions(
    displayPublisherColumnConfig
  );

  const { allowImagesInLiners: organizationAllowImagesInliners } =
    activeOrganization.data();

  // TODO: Show warning messages for conflicting min, max and default column settings

  return (
    <CardGridLayout
      header={{
        title: 'Notice Format',
        description:
          'Configure special formatting settings for this notice type.'
      }}
    >
      <GridInput fullWidth>
        <ColumnSelect
          id="notice-type-template-select"
          labelText="InDesign template"
          options={templatesToTemplateSelectOptions(templates)}
          value={updatedNoticeType.template?.id || 'default'}
          onChange={newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (newValue !== 'default') {
              newNoticeType.template = templates.find(
                r => r.id === newValue
              )?.ref;
            } else {
              delete newNoticeType.template;
            }
            onUpdateNoticeType(newNoticeType);
          }}
        />
      </GridInput>
      <ColumnSelect
        id="min-liner-cols"
        labelText="Minimum column width for liner ads"
        value={updatedNoticeType.linerMinColumns?.toString() || 'none'}
        options={[
          {
            value: 'none',
            label: `Organization default (${getWordsFromNumber(
              linerPublisherColumnConfig.minColumns
            )})`
          },
          ...linerColumnOptions
        ]}
        onChange={newValue => {
          const newNoticeType = { ...updatedNoticeType };
          if (newValue === 'none') {
            delete newNoticeType.linerMinColumns;
          } else {
            newNoticeType.linerMinColumns = Number(newValue);
          }
          onUpdateNoticeType(newNoticeType);
        }}
      />
      <ColumnSelect
        id="max-liner-cols"
        labelText="Maximum column width for liner ads"
        value={updatedNoticeType.linerMaxColumns?.toString() || 'none'}
        options={[
          {
            value: 'none',
            label: `Organization default (${getWordsFromNumber(
              linerPublisherColumnConfig.maxColumns
            )})`
          },
          ...linerColumnOptions
        ]}
        onChange={newValue => {
          const newNoticeType = { ...updatedNoticeType };
          if (newValue === 'none') {
            delete newNoticeType.linerMaxColumns;
          } else {
            newNoticeType.linerMaxColumns = Number(newValue);
          }
          onUpdateNoticeType(newNoticeType);
        }}
      />
      <ColumnSelect
        id="min-display-cols"
        labelText="Minimum column width for display ads"
        value={updatedNoticeType.displayMinColumns?.toString() || 'none'}
        options={[
          {
            value: 'none',
            label: `Organization default (${getWordsFromNumber(
              displayPublisherColumnConfig.minColumns
            )})`
          },
          ...displayColumnOptions
        ]}
        onChange={newValue => {
          const newNoticeType = { ...updatedNoticeType };
          if (newValue === 'none') {
            delete newNoticeType.displayMinColumns;
          } else {
            newNoticeType.displayMinColumns = Number(newValue);
          }
          onUpdateNoticeType(newNoticeType);
        }}
      />
      <ColumnSelect
        id="max-display-cols"
        labelText="Maximum column width for display ads"
        value={updatedNoticeType.displayMaxColumns?.toString() || 'none'}
        options={[
          {
            value: 'none',
            label: `Organization default (${getWordsFromNumber(
              displayPublisherColumnConfig.maxColumns
            )})`
          },
          ...displayColumnOptions
        ]}
        onChange={newValue => {
          const newNoticeType = { ...updatedNoticeType };
          if (newValue === 'none') {
            delete newNoticeType.displayMaxColumns;
          } else {
            newNoticeType.displayMaxColumns = Number(newValue);
          }
          onUpdateNoticeType(newNoticeType);
        }}
      />
      <GridInput>
        <ColumnSelect
          id="notice-type-columns"
          labelText="Default number of columns"
          noteText="If set, notices of this type will default to the specified number of columns and be editable within the range specified above."
          options={[
            {
              value: 'none',
              label: getOrganizationDefaultColumnDescription(activeOrganization)
            },
            ...linerColumnOptions
          ]}
          value={updatedNoticeType.defaultColumns?.toString() || 'none'}
          onChange={newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (newValue === 'none') {
              delete newNoticeType.defaultColumns;
            } else {
              newNoticeType.defaultColumns = Number(newValue);
            }
            onUpdateNoticeType(newNoticeType);
          }}
        />
      </GridInput>

      <GridInput>
        <ColumnSelect
          id="notice-type-images-in-liners"
          labelText="Allow images in liners"
          noteText="If set, notices of this type will allow images and text."
          options={[
            {
              value: 'default',
              label: `Organization default (${booleanToString(
                organizationAllowImagesInliners,
                'Yes',
                'No',

                // TODO: If we change images in liners in the future to be
                // available to all publishers we will want to update this
                // wording. Right now unset === No.
                'No'
              )})`
            },
            {
              value: 'true',
              label: 'Yes'
            },
            {
              value: 'false',
              label: 'No'
            }
          ]}
          value={booleanToString(
            updatedNoticeType.allowImagesInLiners,
            'true',
            'false',
            'default'
          )}
          onChange={newValue => {
            const newNoticeType = { ...updatedNoticeType };
            if (newValue === 'default') {
              delete newNoticeType.allowImagesInLiners;
            } else {
              newNoticeType.allowImagesInLiners = newValue === 'true';
            }
            onUpdateNoticeType(newNoticeType);
          }}
        />
      </GridInput>

      {exists(user) && userIsSuper(user) && (
        <>
          <GridInput fullWidth={entryMethod === 'text'}>
            <ColumnSelect
              id="notice-type-entry-method"
              labelText="Content creation method"
              options={[
                {
                  value: 'text',
                  label: 'Enter text or upload file using default text editor'
                },
                {
                  value: 'typeform',
                  label:
                    'Complete questionnaire and auto-generate results (Typeform)'
                },
                {
                  value: 'madlib',
                  label: 'Complete fill-in-the-blank template (Madlib)'
                }
              ]}
              value={entryMethod}
              onChange={newValue => {
                const newNoticeType = { ...updatedNoticeType };
                if (newValue === 'text') {
                  delete newNoticeType.typeform;
                  delete newNoticeType.madlib;
                  setEntryMethod('text');
                } else if (newValue === 'typeform') {
                  delete newNoticeType.madlib;
                  setEntryMethod('typeform');
                } else if (newValue === 'madlib') {
                  setEntryMethod('madlib');
                }
                onUpdateNoticeType(newNoticeType);
              }}
            />
          </GridInput>
          {entryMethod === 'typeform' && (
            <GridInput>
              <TextField
                id="notice-type-typeform-id"
                value={updatedNoticeType.typeform || ''}
                labelText="Typeform ID"
                pattern={TYPEFORM_ID_PATTERN}
                validationMessages={{
                  valueMissing: 'Please enter a typeform ID.'
                }}
                required
                onChange={newValue => {
                  onUpdateNoticeType({
                    ...updatedNoticeType,
                    typeform: newValue
                  });
                }}
                placeholder="typeformID"
                errorText={
                  !RegExp(TYPEFORM_ID_PATTERN).test(
                    updatedNoticeType.typeform || ''
                  )
                    ? 'Please enter a valid Typeform ID.'
                    : ''
                }
              />
            </GridInput>
          )}
          {entryMethod === 'madlib' && (
            <GridInput>
              <TextField
                id="notice-type-madlib-id"
                value={updatedNoticeType.madlib || ''}
                labelText="Madlib storage path"
                pattern={MADLIB_ID_PATTERN}
                validationMessages={{
                  valueMissing: 'Please enter a path to a madlib config.'
                }}
                errorText={
                  !RegExp(MADLIB_ID_PATTERN).test(
                    updatedNoticeType.madlib || ''
                  )
                    ? 'Please enter a valid Madlib path.'
                    : ''
                }
                required
                onChange={newValue => {
                  onUpdateNoticeType({
                    ...updatedNoticeType,
                    madlib: newValue
                  });
                }}
                placeholder="madlibs/publisher/folder"
              />
            </GridInput>
          )}
        </>
      )}
    </CardGridLayout>
  );
}

function getColumnRangeOptions(columnConfig: ColumnRangeConfig) {
  const options = [];
  for (let i = columnConfig.minColumns; i <= columnConfig.maxColumns; i++) {
    options.push({
      value: `${i}`,
      label: `${getWordsFromNumber(i)}`
    });
  }
  return options;
}
