import api from 'api';
import { push } from 'connected-react-router';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { EResponseTypes } from 'lib/types';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/hooks';

export default function UpdateEmail() {
  const dispatch = useAppDispatch();
  const [accountEmailUpdateError, setAccountEmailUpdateError] = useState('');

  useEffect(() => {
    const sendRequestToChangeAccountEmail = async (token: string) => {
      const { success, error }: EResponseTypes['users/change-email'] =
        await api.post('users/change-email', {
          token
        });
      if (success) {
        // Since the session has been expired we redirect user to re-login with new email
        // https://firebase.google.com/docs/auth/admin/manage-sessions
        dispatch(push('/login/'));
        // Reload is required here so that all states of app (mainly related to user) can be reset because the current user token has been expired when the account email updated.
        window.location.reload();
      }
      if (error === 'Token expired') {
        setAccountEmailUpdateError(
          'This link has expired. Please go to your Column account to change your email address. Note, that the link will expire after 2 hours.'
        );
      } else if (error === 'Link already used') {
        setAccountEmailUpdateError(
          'The link might have been used because the email update exists in the system.'
        );
      } else if (error) {
        setAccountEmailUpdateError(
          'The update did not go through successfully. Please try again by clicking the link from your email.'
        );
      }
    };
    const token = getLocationParams().get('token');
    if (!token) return;
    void sendRequestToChangeAccountEmail(token);
  }, []);

  if (accountEmailUpdateError)
    return (
      <p className="justify-center text-column-gray-500 text-center mt-20">
        {accountEmailUpdateError}
      </p>
    );

  return (
    <>
      {!accountEmailUpdateError && (
        <div className="flex flex-col justify-center space-y-6 mt-20">
          <LoadingSpinner />
          <p className="text-column-gray-500 text-center">
            Please wait and do not close this window while we are updating
            account email.
          </p>
        </div>
      )}
    </>
  );
}
