import { useState, useEffect } from 'react';
import { useAppSelector } from 'redux/hooks';

import { ERequestTypes, EResponseTypes } from 'lib/types';
import { SearchableCustomerRecordFilter } from 'lib/types/searchable';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { selectActiveOrganization } from 'redux/auth';
import { getNewspaperRefForCustomer } from 'lib/notice/customer';
import { ColumnService } from 'lib/services/directory';
import {
  CustomerTableData,
  extractCustomerDataFromSearchableCustomerRecord
} from './utils';

export function useGetCustomerTableData(searchQuery: string) {
  const newspaper = useAppSelector(selectActiveOrganization);
  const [customers, setCustomers] = useState<CustomerTableData[]>();
  const [loading, setLoading] = useState(false);
  const [refreshCustomersList, setRefreshCustomersList] = useState(false);

  const getCustomersList = async () => {
    if (!newspaper) return;
    setLoading(true);
    try {
      const parentNewspaperRef = getNewspaperRefForCustomer(newspaper);
      const filters: SearchableCustomerRecordFilter[] = [
        { organizationid: parentNewspaperRef?.id },
        { archived: Number(false) }
      ];
      const reqBody: ERequestTypes['search/customers'] = {
        search: searchQuery,
        filters
      };
      const searchCustomersResponse: EResponseTypes['search/customers'] =
        await api.post('search/customers', reqBody);

      if (!searchCustomersResponse.success) {
        logAndCaptureException(
          ColumnService.AUTH_AND_USER_MANAGEMENT,
          searchCustomersResponse.error,
          "Unable to load publication's customers in settings tab",
          {
            publicationId: parentNewspaperRef?.id
          }
        );
        setCustomers([]);
        setLoading(false);
        return;
      }
      const shallowCustomerRows = searchCustomersResponse.results;
      const customersWithData = await Promise.all(
        shallowCustomerRows.map(extractCustomerDataFromSearchableCustomerRecord)
      );
      const validCustomersWithData = customersWithData.filter(Boolean);
      setCustomers(validCustomersWithData as CustomerTableData[]);
      setLoading(false);
      setRefreshCustomersList(false);
    } catch (error) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        error,
        "Unable to load publication's customers in settings tab",
        {
          publicationId: newspaper.id
        }
      );
      setCustomers([]);
      setLoading(false);
      setRefreshCustomersList(false);
    }
  };

  useEffect(() => {
    void getCustomersList();
  }, [newspaper?.id, searchQuery]);

  // Refresh the customer table after 3 seconds to allow changes to be reflected in Elastic
  useEffect(() => {
    if (refreshCustomersList) {
      const id = setTimeout(() => getCustomersList(), 3000);
      return () => clearTimeout(id);
    }
  }, [refreshCustomersList]);

  return { customers, loading, setRefreshCustomersList };
}
