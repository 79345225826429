import api from 'api';
import { MceContentOptions } from 'components/noticePreview/mceHelpers';
import sanitize from 'sanitize-html';
import { getCloudConvertOnCallFn } from './callableFunctions';
import { createTreeWalker } from './treeWalker';

const MAX_WORDS_IN_CELL = 25;
const originalElements: Record<string, HTMLElement> = {};
let id = 0;

export const convertWord = getCloudConvertOnCallFn();

const isEmptyRow = (r: HTMLTableRowElement | null) => {
  /* r?.textContent can be empty. We need to test string against empty space as well that is why checking with type.
  Embedding null type because isEmptyRow is calling from some placese where adding nested condiotions
  can be eerror prone */
  if (typeof r?.textContent !== 'string') return false;
  return !/\S/g.test(r.textContent);
};

type WordToHtmlType = {
  html: string;
  hasImage: boolean;
};
/**
 * - Trim whitespaces in front of all paragraph elements
 * - Delete whitespaces between dollar signs and their neighboring numbers (needed when inferring tables)
 * - Removes empty paragraphs at the beginning of the document
 * - Removes paragraphs with parentheses only (in lawyer notices)
 * @param fakeDOM Document to preprocess
 */
export const preprocess = (fakeDOM: Document) => {
  // tells us if we haven't encountered non-white-space characters yet
  let noContent = true;

  const trimParagraph = (paragraphElem: HTMLParagraphElement) => {
    let lastText: Text | null = null;

    const walker = createTreeWalker(
      fakeDOM,
      paragraphElem,
      NodeFilter.SHOW_TEXT,
      null,
      false
    );

    let text: Node | null;
    let done = false;

    while ((text = walker.nextNode())) {
      // delete spaces between dollar signs and numbers
      if (!text.nodeValue) continue;
      text.nodeValue = text.nodeValue.replace(/\$\s+/g, '$');

      if (
        lastText &&
        lastText.textContent &&
        /\S$/.test(lastText.textContent)
      ) {
        lastText.nodeValue += ' ';
      }
      if (!done) {
        text.nodeValue = text.nodeValue.replace(/^\s+/g, '');
        done = true;
      }
      lastText = text as Text;
    }

    if (lastText && lastText.textContent)
      lastText.nodeValue = lastText.textContent.replace(/\s+$/g, '');
  };

  const isEmpty = (paragraphElem: HTMLParagraphElement) => {
    /* With paragraphElem.textContent we are considering empty strings as well; with paragraphElem?.textContent might come with
    null and we cannot compare then. */
    return (
      typeof paragraphElem.textContent === 'string' &&
      !/\S/g.test(paragraphElem.textContent)
    );
  };

  const isParenthesesOnly = (textContent: string | null) => {
    return (
      textContent &&
      !/[^()]/.test(textContent.replace(/\s+/g, '')) &&
      /\S/.test(textContent)
    );
  };

  const isParagraph = (ele: HTMLElement): ele is HTMLParagraphElement =>
    ele.tagName === 'P';

  const isTable = (ele: HTMLElement): ele is HTMLTableElement =>
    ele.tagName === 'TABLE';

  const handleChild = (ele: HTMLElement) => {
    if (isParagraph(ele)) {
      trimParagraph(ele);
      // remove lines with parentheses only
      if (isParenthesesOnly(ele.textContent)) ele.remove();

      // remove empty paragraphs at the beginning of the file
      if (isEmpty(ele)) {
        if (noContent) ele.remove();
      } else noContent = false;
    } else if (isTable(ele)) {
      // eslint-disable-next-line no-labels
      LL: for (const tr of ele.rows)
        for (const td of tr.cells)
          if (isParenthesesOnly(td.textContent)) {
            ele.setAttribute('lawyer', 'true');
            // eslint-disable-next-line no-labels
            break LL;
          }
    } else if (ele.children.length) {
      const children = [...ele.children];
      for (const child of children) handleChild(child as HTMLElement);
    }
  };

  handleChild(fakeDOM.body);
};

export const inferTables = (fakeDOM: Document) => {
  let tableElement: HTMLTableElement | null = null;
  let elementsToBeRemoved: HTMLElement[] = [];
  let columns = 0;

  const getRegex = (innerText: string, created: boolean) => {
    const normal = /\u00A0{2,}\s*/g;
    const special = /\u00A0{1,}\s*/g;

    const lastRow =
      tableElement &&
      tableElement.rows[tableElement.rows.length - (created ? 2 : 1)];

    // Needs more refining
    let specialCaseSplit = false;
    for (const s of innerText.split(special))
      specialCaseSplit = specialCaseSplit || s.split(' ').length < 10;

    specialCaseSplit = !!(
      specialCaseSplit &&
      tableElement &&
      tableElement.rows.length > 1 &&
      !isEmptyRow(lastRow) &&
      innerText.split(special).length === columns
    );

    return specialCaseSplit ? special : normal;
  };

  const hasTooManyWords = (cells: string[]) => {
    const t = cells.filter(cell => cell.split(' ').length > MAX_WORDS_IN_CELL);
    return !!t.length;
  };

  const isTableRow = (innerText: string) => {
    const cleanedText = innerText.replace(/^\s+/, '').replace(/\s+$/, '');
    const cells = cleanedText.split(getRegex(cleanedText, false));
    return (
      (cells.length > 1 &&
        !hasTooManyWords(cells) &&
        !/^\([a-zA-Z0-9]\)/.test(cells[0]) &&
        !/^[a-zA-Z0-9]\./.test(cells[0]) &&
        !/^Section [0-9]/.test(cells[0])) ||
      (tableElement && !/\S/g.test(cleanedText)) ||
      (tableElement && /^[a-z]/.test(cleanedText))
    );
  };

  const splitText = (innerText: string) => {
    const cleanedText = innerText.replace(/^\s+/, '').replace(/\s+$/, '');
    const regex = getRegex(cleanedText, true);

    const texts = cleanedText.split(regex);

    if (!columns) {
      columns = texts.length;
    } else if (texts.length < columns) {
      const spaces = [];
      let match;

      while ((match = regex.exec(cleanedText))) {
        spaces.push({ length: match[0].length, i: spaces.length });
      }

      spaces.sort((a, b) => b.length - a.length);

      spaces.splice(columns - texts.length);

      spaces.sort((a, b) => b.i - a.i);

      for (const space of spaces) {
        texts.splice(space.i + 1, 0, '');
      }

      while (texts.length < columns) texts.push('');
    }

    return texts;
  };

  const constructRow = (paragraphElem: HTMLParagraphElement) => {
    const row = tableElement?.insertRow();

    // remember original element
    row?.setAttribute('id', `${id}`);
    originalElements[`${id}`] = paragraphElem;
    id++;
    /* For paragraphElem.textContent we are dealing with emptty string as well that is why compare with type
    string to avoid null. */
    const cellsText =
      typeof paragraphElem?.textContent === 'string'
        ? splitText(paragraphElem.textContent)
        : [];
    let badCells = 0;
    for (const cellText of cellsText) {
      if (cellText.split(' ').length > 10) {
        if (badCells > 0) {
          tableElement?.remove();
          tableElement = null;
          elementsToBeRemoved = [];
          break;
        }
        badCells++;
      }
      if (/^(\(|\))+$/.test(cellText))
        tableElement?.setAttribute('lawyer', 'true');

      const cell = row?.insertCell();

      const clone = paragraphElem.cloneNode() as HTMLParagraphElement;
      let cur = paragraphElem.firstElementChild;
      let deepestChild = clone as HTMLElement;
      while (cur) {
        deepestChild = clone.insertBefore(cur.cloneNode(), null) as HTMLElement;
        cur = cur.firstElementChild;
      }
      deepestChild.insertAdjacentHTML('afterbegin', cellText);
      cell?.insertBefore(clone, null);
    }
  };

  const startTable = (ele: HTMLElement) => {
    tableElement = fakeDOM.createElement('table');
    tableElement.setAttribute('parsed', 'true');
    ele.insertAdjacentElement('afterend', tableElement);
  };

  const endTable = () => {
    tableElement = null;
    columns = 0;
    for (const ele of elementsToBeRemoved) ele.remove();
  };

  const handleChild = (ele: HTMLElement) => {
    if (ele.tagName === 'P') {
      const elemText = ele?.textContent;
      /* For the elemText we can pass an empty string as well and !elemText condition
      falsy for empty string that is why comparing with string type here. */
      if (typeof elemText === 'string' && isTableRow(elemText)) {
        if (!tableElement) {
          startTable(ele);
        }
        constructRow(ele as HTMLParagraphElement);
        elementsToBeRemoved.push(ele);
      } else if (tableElement) {
        endTable();
      }
    } else if (ele.tagName === 'TABLE') {
      endTable();
    } else if (ele.children.length > 0) {
      const children = [...ele.children];
      for (const child of children) handleChild(child as HTMLElement);
    }

    if (tableElement && ele.tagName !== 'P') {
      endTable();
    }
  };

  handleChild(fakeDOM.body);

  if (tableElement) endTable();
};

export const postprocess = (fakeDOM: Document) => {
  const addMissedRows = () => {
    for (const paragraphElem of fakeDOM.querySelectorAll('p')) {
      let pre: Node | null = paragraphElem;
      while ((pre = pre.previousSibling)) {
        if (pre.nodeType === Node.ELEMENT_NODE) break;
      }

      let next: Node | null = paragraphElem;
      let lines = 0;
      while ((next = next.nextSibling)) {
        const htmlElem = next as HTMLElement;
        if (
          htmlElem?.textContent &&
          next.nodeType === Node.ELEMENT_NODE &&
          /\S/.test(htmlElem.textContent)
        )
          break;
        if (next.nodeType === Node.ELEMENT_NODE) lines++;
      }

      if (
        pre &&
        (pre as HTMLElement).tagName === 'TABLE' &&
        next &&
        (next as HTMLElement).tagName === 'TABLE' &&
        (pre as HTMLElement).getAttribute('parsed') &&
        (next as HTMLElement).getAttribute('parsed')
      ) {
        if (!paragraphElem.textContent) continue;
        const match = paragraphElem.textContent.match(/\s\S+$/);

        if (!match) continue;

        const newRowTexts = [
          paragraphElem.textContent.substring(0, match.index),
          match[0].substring(1)
        ];

        if (
          newRowTexts[0].split(' ').length > MAX_WORDS_IN_CELL ||
          newRowTexts[1].split(' ').length > MAX_WORDS_IN_CELL
        )
          continue;

        const newRow: HTMLTableRowElement | null = (
          pre as HTMLTableElement
        ).insertRow();
        newRow
          .insertCell()
          .insertAdjacentElement('afterbegin', fakeDOM.createElement('p'))
          ?.insertAdjacentText('afterbegin', newRowTexts[0]);

        newRow
          .insertCell()
          .insertAdjacentElement('afterbegin', fakeDOM.createElement('p'))
          ?.insertAdjacentText('afterbegin', newRowTexts[1]);

        while (lines-- > 0)
          (pre as HTMLTableElement)
            .insertRow()
            .insertCell()
            .insertAdjacentElement('afterend', fakeDOM.createElement('td'));

        for (const r of (next as HTMLTableElement).rows) {
          (pre as HTMLTableElement).insertRow().replaceWith(r.cloneNode(true));
        }

        paragraphElem.remove();
        (next as HTMLTableElement).remove();
      }
    }
  };
  // detects weird parentheses string in a cell
  const isParenthesesCell = (td: HTMLTableCellElement) => {
    return (
      (td.textContent &&
        /^(\(|\))+$/.test(td.textContent.replace(/\s/g, ''))) ||
      !!td.getAttribute('parenthesis')
    );
  };

  const isEmptyCell = (td: HTMLTableCellElement) => {
    /* if there will be space it will be returning true otherwisee return false.
    Returning true when there is no value under td.textContent */
    if (!td?.textContent) return true;
    return !/\S/g.test(td.textContent);
  };

  const trimTable = (table: HTMLTableElement) => {
    const { rows } = table;
    for (let i = rows.length - 1; i > -1 && isEmptyRow(rows[i]); i--) {
      const { children } = rows[i].cells[0];
      for (const ch of children) table.insertAdjacentElement('afterend', ch);
      rows[i].remove();
    }
  };

  const removeFalseTable = (table: HTMLTableElement) => {
    if (table.textContent && /^\s*$/.test(table.textContent)) {
      table.remove();
      return;
    }

    const badTable =
      table.rows.length <= 1 || table.getAttribute('lawyer') === 'true';

    if (badTable) {
      const replacements: Node[] = [];
      if (table.rows.length <= 1) {
        const elem = table.rows[0]?.getAttribute('id');
        const original = elem && originalElements[elem];
        if (original) replacements.push(original);
        else
          for (const td of table.rows[0].cells) {
            if (!isParenthesesCell(td) && !isEmptyCell(td)) {
              replacements.push(...td.children);
              replacements.push(fakeDOM.createElement('br'));
            }
          }
      } else if (table.getAttribute('lawyer') === 'true') {
        const parties: HTMLElement[] = [];
        const meta: HTMLElement[] = [];

        for (const tr of table.rows) {
          const before: string[] = [];
          const after: string[] = [];

          for (const td of tr.cells) {
            if (isParenthesesCell(td)) break;
            if (!isEmptyCell(td) && td?.textContent)
              before.push(td.textContent);
          }
          for (const td of [...tr.cells].reverse()) {
            if (isParenthesesCell(td)) break;
            if (!isEmptyCell(td) && td?.textContent)
              after.unshift(td.textContent);
          }

          if (before.length) {
            const paragraphElem = fakeDOM.createElement('p');
            paragraphElem.insertAdjacentText(
              'afterbegin',
              before.join('\u00A0'.repeat(2))
            );
            parties.push(paragraphElem);
            parties.push(fakeDOM.createElement('br'));
          }

          if (after.length) {
            const paragraphElem = fakeDOM.createElement('p');
            paragraphElem.insertAdjacentText(
              'afterbegin',
              after.join('\u00A0'.repeat(2))
            );
            meta.push(paragraphElem);
            meta.push(fakeDOM.createElement('br'));
          }
        }

        replacements.push(...parties, ...meta);
      }
      table.replaceWith(...replacements);
    }
  };

  const adjustShortRows = (table: HTMLTableElement) => {
    let maxCells = 0;

    for (const row of table.rows)
      maxCells = Math.max(maxCells, row.cells.length);

    for (const row of table.rows)
      while (row.cells.length < maxCells) row.insertCell();
  };

  const removeEmptyColumns = (table: HTMLTableElement) => {
    const allCells = [...table.rows].map(row => {
      const cellNotEmpty = [];

      for (const td of row.cells)
        if (td.textContent && /\S/.test(td.textContent))
          cellNotEmpty.push(true);
        else cellNotEmpty.push(false);

      return cellNotEmpty;
    });

    const colNotEmpty = allCells.reduce((pre, cur) => {
      return pre.map((val, i) => val || cur[i]);
    });

    for (const tr of table.rows)
      for (const [i, td] of [...tr.cells].entries()) {
        if (!colNotEmpty[i]) td.remove();
      }
  };

  const trimParantheses = (text: Text) => {
    if (text && text?.nodeValue)
      // eslint-disable-next-line no-param-reassign
      text.nodeValue = text.nodeValue.replace(/^\)+/g, '');
  };

  const removeNonBreakingSpaces = (node: Text) => {
    if (node && node.nodeValue)
      // eslint-disable-next-line no-param-reassign
      node.nodeValue = node.nodeValue.replace(/\u00A0{1,}/g, ' ');
  };

  const tables: HTMLTableElement[] = [];

  const walker = createTreeWalker(
    fakeDOM,
    fakeDOM.body,
    NodeFilter.SHOW_ALL,
    null,
    false
  );

  let node: Node | null;
  while ((node = walker.nextNode())) {
    if (node.nodeType === Node.TEXT_NODE) {
      removeNonBreakingSpaces(node as Text);
      trimParantheses(node as Text);
    } else if ((node as HTMLElement).tagName === 'TD') {
      if (isParenthesesCell(node as HTMLTableCellElement))
        (node as HTMLElement).setAttribute('parenthesis', 'true');
    } else if ((node as HTMLElement).tagName === 'TABLE') {
      tables.push(node as HTMLTableElement);
    }
  }

  for (const table of tables) {
    if (!table.isConnected) continue;
    trimTable(table);
    adjustShortRows(table);
    removeFalseTable(table);
  }

  addMissedRows();

  for (const table of tables) {
    if (!table.isConnected) continue;
    adjustShortRows(table);
    removeEmptyColumns(table);
  }
};

const removeEmptySpacesfromEnd = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const body = doc.body.lastElementChild?.children;
  if (!body) return;
  for (let x = body.length - 1; x >= 0; x--) {
    const elem = body.item(x)?.textContent;
    if (elem && elem.length > 0) break;
    if (elem?.length === 0) {
      /*
        1. This way of removing an element is ugly but is compatible across all browsers.
        2. Added non-null assertion (!) here safely because we are already doing a null check at start
        and it will not get null inside removeChild(body.item(x)).
      */
      body.item(x)?.parentNode?.removeChild(body.item(x)!);
    }
  }
  return doc.body.outerHTML;
};

const addSanitize = (html: string, options?: MceContentOptions) => {
  const sanitizedHtml = removeEmptySpacesfromEnd(html);
  if (!sanitizedHtml) return '';

  const allowedAttributes: Record<string, string[]> = {
    '*': ['style']
  };
  if (options?.allowImages) {
    allowedAttributes.img = ['src', 'data-mce-src', 'width', 'height'];
  }

  return sanitize(sanitizedHtml, {
    allowedTags: false,
    allowedAttributes,
    allowedStyles: {
      '*': {
        'text-align': [/^.*$/],
        'text-transform': [/^.*$/]
      }
    },
    transformTags: {
      pre: 'p',
      sub: 'span',
      sup: 'span',
      h1: 'p',
      h2: 'p',
      h3: 'p',
      h4: 'p',
      h5: 'p',
      h6: 'p'
    },
    exclusiveFilter: frame => {
      return !options?.allowImages && frame.tag === 'img';
    }
  });
};

const squashWord = (fakeDOM: Document) => {
  try {
    const tables = fakeDOM.querySelectorAll('table');
    Array.from(tables).forEach((table: HTMLElement) => {
      const cells = table.querySelectorAll('td');
      const outer = fakeDOM.createElement('div');
      Array.from(cells).forEach(cell => {
        if (!cell.innerText) return;
        const tag = fakeDOM.createElement('p');
        const text = document.createTextNode(cell.innerText);
        tag.appendChild(text);
        outer.appendChild(tag);
      });
      table.parentNode?.replaceChild(outer, table);
    });
  } catch (err) {
    console.error((err as any).toString());
  }
};

const stripBlankLines = (fakeDOM: Document) => {
  // remove empty paragraphs
  for (const paragraphElem of fakeDOM.querySelectorAll('p')) {
    if (!paragraphElem.innerText?.trim()) {
      paragraphElem.remove();
    }
  }

  // remove newlines
  for (const br of fakeDOM.querySelectorAll('br')) {
    br.remove();
  }
};

const checkImage = (fakeDOM: Document) => {
  const hasImage = fakeDOM.querySelector('img');
  return !!hasImage;
};

export const parseHtml = (
  html: string,
  cleanVariant: string | undefined,
  isTableContentSquashable: boolean,
  options: MceContentOptions | undefined
) => {
  const fakeDOM = new DOMParser().parseFromString(html, 'text/html');
  preprocess(fakeDOM);
  inferTables(fakeDOM);
  postprocess(fakeDOM);
  if (cleanVariant === 'squash' || isTableContentSquashable)
    squashWord(fakeDOM);
  if (cleanVariant === 'strip') stripBlankLines(fakeDOM);
  return {
    html: addSanitize(fakeDOM.body.outerHTML, options),
    hasImage: checkImage(fakeDOM)
  };
};

export const wordOrPDFToHtml = async (
  storageId: string,
  cleanVariant: string | undefined,
  isTableContentSquashable: boolean,
  options: MceContentOptions | undefined
): Promise<WordToHtmlType> => {
  const { html } = await api.post('documents/convert-word', {
    storageId,
    allowImages: !!options?.allowImages
  });
  return parseHtml(html, cleanVariant, isTableContentSquashable, options);
};
