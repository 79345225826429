import { ConfirmationStatus, NoticeType } from '../enums';
import {
  ESnapshotExists,
  ENotice,
  EUser,
  EFirebaseContext,
  EOrganization,
  ETransaction
} from '../types';
import { getOrThrowTransaction } from '../utils/refs';
import { getCustomerForNotice } from './customer';
import { removeUndefinedFields } from '../helpers';

function getShouldAutoGenerateOrderNumsForNotice(
  newspaper: ESnapshotExists<EOrganization>,
  parent: ESnapshotExists<EOrganization> | undefined,
  notice: ESnapshotExists<ENotice>
): boolean {
  const noticeAlreadyHasOrderNumber = !!notice.data().customId;
  if (noticeAlreadyHasOrderNumber) {
    return false;
  }

  const { shouldAutoGenOrderNums: childShouldAutoGenOrderNums } =
    newspaper.data();
  const { shouldAutoGenOrderNums: parentShouldAutoGenOrderNums } =
    parent?.data() || {};

  const shouldAutoGenOrderNums =
    childShouldAutoGenOrderNums ?? parentShouldAutoGenOrderNums ?? false;

  if (!shouldAutoGenOrderNums) {
    return false;
  }

  if (typeof shouldAutoGenOrderNums === 'boolean') {
    return shouldAutoGenOrderNums;
  }

  const isDisplay = notice.data().noticeType === NoticeType.display_ad.value;

  if (isDisplay) {
    return shouldAutoGenOrderNums.display;
  }

  return shouldAutoGenOrderNums.liners;
}

export function getNoticeNumberAndCustomIdFromNewspaper(
  newspaper: ESnapshotExists<EOrganization>
): { currentNumber: number; customId: string } {
  const currentNumber = newspaper.data().numberOfOrders || 1;
  const numberOfDigits =
    newspaper?.data()?.orderNumberGeneration?.orderNumDigitCount || 4;
  const numOfZeros =
    numberOfDigits - `${currentNumber}`.length > 0
      ? numberOfDigits - `${currentNumber}`.length
      : 0;
  const prefix = newspaper?.data()?.orderNumberGeneration?.orderNumPrefix || '';
  const suffix = newspaper?.data()?.orderNumberGeneration?.orderNumSuffix || '';
  const customId = `${prefix}${'0'.repeat(
    numOfZeros
  )}${currentNumber}${suffix}`;

  return {
    currentNumber,
    customId
  };
}

async function generateCustomIdForNotice(
  transaction: ETransaction,
  notice: ESnapshotExists<ENotice>
) {
  const publisherOrganization = await getOrThrowTransaction(
    transaction,
    notice.data().newspaper
  );

  const parentOrganizationRef = publisherOrganization.data().parent;
  const parentOrganization = parentOrganizationRef
    ? await getOrThrowTransaction(transaction, parentOrganizationRef)
    : undefined;
  const shouldAutoGenOrderNums = getShouldAutoGenerateOrderNumsForNotice(
    publisherOrganization,
    parentOrganization,
    notice
  );

  const orgToUpdate = publisherOrganization.data()?.shouldAutoGenOrderNums
    ? publisherOrganization
    : parentOrganization;

  let generatedCustomId;

  if (shouldAutoGenOrderNums && orgToUpdate) {
    const { customId, currentNumber } =
      getNoticeNumberAndCustomIdFromNewspaper(orgToUpdate);
    generatedCustomId = customId;
    transaction.update(orgToUpdate.ref, {
      numberOfOrders: currentNumber + 1
    });
  }

  return generatedCustomId;
}

async function updateNoticeWithConfirmation(
  ctx: EFirebaseContext,
  {
    notice,
    user
  }: {
    notice: ESnapshotExists<ENotice>;
    user: ESnapshotExists<EUser>;
  }
) {
  await ctx.runTransaction(async transaction => {
    const updates: Partial<ENotice> = {
      confirmationStatus: ConfirmationStatus.Confirmed,
      confirmedReceipt: true,
      confirmedBy: user.ref,
      confirmedReceiptTime: ctx.fieldValue().serverTimestamp() as any,
      customId: await generateCustomIdForNotice(transaction, notice)
    };

    transaction.update(notice.ref, removeUndefinedFields(updates));
  });
}
/** Instead of calling this directly, consider using the `confirm` method of `UserNoticeModel`. */
export async function confirmNotice(
  ctx: EFirebaseContext,
  {
    notice,
    user
  }: {
    notice: ESnapshotExists<ENotice>;
    user: ESnapshotExists<EUser>;
  }
) {
  await updateNoticeWithConfirmation(ctx, { notice, user });

  const customer = await getCustomerForNotice(ctx, notice);
  if (customer && !customer.data().verified) {
    await customer.ref.update({
      verified: true
    });
  }
}

export const __private = {
  getShouldAutoGenerateOrderNumsForNotice
};
