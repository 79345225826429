import React, { useEffect, useState } from 'react';
import {
  ESnapshotExists,
  EUser,
  CustomerOrganization,
  exists
} from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { TableLayout } from 'lib/components/TableLayout';
import { Customer } from 'lib/types/customer';
import { fuzzyStringContains } from 'lib/utils/strings';
import { OccupationType } from 'lib/enums';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import PublisherSettingsTableRow from './PublisherSettingsTableRow';
import { CustomerInfoData } from './types';

type UsersPublisherSettingsProps = {
  user: ESnapshotExists<EUser>;
  onUpdatePublisherSettingsClick: (info: CustomerInfoData) => void;
};

export default function UsersPublisherSettings({
  user,
  onUpdatePublisherSettingsClick
}: UsersPublisherSettingsProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization) || null;
  const [loadingCustomerOrgInfo, setLoadingCustomerOrgInfo] = useState(false);
  const [customersOrgData, setCustomersOrgData] =
    useState<CustomerInfoData[]>();
  const userIsIndividual =
    user.data().occupation === OccupationType.individual.value;

  const getCustomerOrgsPublicationSettings = async (
    customersOrg: ESnapshotExists<CustomerOrganization>[],
    customers: ESnapshotExists<Customer>[]
  ) => {
    const data: CustomerInfoData[] = [];
    await Promise.all(
      customersOrg.map(async cusOrg => {
        const {
          organization,
          bulkPaymentEnabled_v2,
          enableAffidavitsBeforePayment,
          internalID
        } = cusOrg.data();
        const orgSnap = await organization.get();
        if (!exists(orgSnap)) return;
        const customerOfCustomerOrg = customers.find(
          cus => cus.data().organization.id === organization.id
        );
        data.push({
          organization: orgSnap,
          customerOrg: cusOrg.ref,
          isBillingEnabled: bulkPaymentEnabled_v2,
          isEarlyAffidavitsEnabled:
            enableAffidavitsBeforePayment ||
            customerOfCustomerOrg?.data().enableAffidavitsBeforePayment,
          internalID
        });
      })
    );
    return data;
  };

  const getCustomer = async () => {
    return await getFirebaseContext()
      .customersRef()
      .where('user', '==', user.ref)
      .get();
  };

  const getCustomersPublicationSettings = async () => {
    setLoadingCustomerOrgInfo(true);
    const customers = await getCustomer();
    if (!customers.docs.length) return;
    const data: CustomerInfoData[] = [];
    await Promise.all(
      customers.docs.map(async customer => {
        const {
          organization,
          bulkPaymentEnabled_v2,
          enableAffidavitsBeforePayment,
          internalID
        } = customer.data();
        const orgSnap = await organization.get();
        if (!exists(orgSnap)) return;

        data.push({
          organization: orgSnap,
          isBillingEnabled: bulkPaymentEnabled_v2,
          isEarlyAffidavitsEnabled: enableAffidavitsBeforePayment,
          internalID
        });
      })
    );
    setCustomersOrgData(data);
    setLoadingCustomerOrgInfo(false);
  };

  const getCustomersOrgInfo = async (
    customersOrgSnaps: ESnapshotExists<CustomerOrganization>[] | undefined
  ) => {
    if (!customersOrgSnaps) return;
    setLoadingCustomerOrgInfo(true);
    const customersSnaps = await getCustomer();
    const customersOrgData = await getCustomerOrgsPublicationSettings(
      customersOrgSnaps,
      customersSnaps.docs
    );
    setCustomersOrgData(customersOrgData);
    setLoadingCustomerOrgInfo(false);
  };

  const activeOrgRef = activeOrganization ? activeOrganization.ref : null;
  const query = getFirebaseContext()
    .customerOrganizationsRef()
    .where('client', '==', activeOrgRef);
  const customersOrgs = useFirestoreQueryListener(query, []);

  useEffect(() => {
    if (
      user.data().allowedOrganizations &&
      exists(activeOrganization) &&
      customersOrgs?.docs.length
    ) {
      void getCustomersOrgInfo(customersOrgs?.docs);
    } else {
      void getCustomersPublicationSettings();
    }
  }, [customersOrgs]);

  const customersOrCustomerOrgsPublisherInfoTableFilter = (
    customersOrgData: CustomerInfoData,
    matchString: string
  ) => {
    if (
      fuzzyStringContains(
        customersOrgData.organization.data().name,
        matchString
      )
    ) {
      return true;
    }

    if (fuzzyStringContains(customersOrgData.internalID || '', matchString)) {
      return true;
    }

    return false;
  };

  return (
    <>
      <TableLayout<CustomerInfoData>
        id="publishers-info-settings"
        header={{
          title: 'Publisher Settings',
          subtitle: 'View and manage all settings with publishers'
        }}
        columns={[
          'Publisher Name',
          'Account Number',
          'Bulk Invoicing',
          'Affidavit Pre-payment'
        ]}
        data={customersOrgData || []}
        filterable={{
          shouldShowTableItem: (customersOrgData, searchTerm) =>
            customersOrCustomerOrgsPublisherInfoTableFilter(
              customersOrgData,
              searchTerm
            )
        }}
        renderRow={item => (
          <PublisherSettingsTableRow publisherSettingsInfo={item} />
        )}
        loading={loadingCustomerOrgInfo}
        editable={
          !userIsIndividual
            ? {
                editTooltip: 'Edit this publisher`s settings.',
                onEdit: item => {
                  onUpdatePublisherSettingsClick(item);
                }
              }
            : undefined
        }
      />
    </>
  );
}
