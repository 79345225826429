import { ColumnSelect } from 'lib/components/ColumnSelect';
import {
  NewspaperOrderStatus,
  PAGINATION_READY_STATUSES,
  PENDING_STATUSES
} from 'lib/types/newspaperOrder';
import { capitalizeWord } from 'lib/utils/strings';
import React from 'react';
import { OrderFilter, SHOW_ALL_OPTION_VALUE } from './helpers';

type ConfirmationStatusFilterProps = {
  confirmationStatuses: NewspaperOrderStatus[];
  editedRowFilter: OrderFilter;
  onSelectNewFilterValue: (newValue: string) => void;
};

const getStatusLabel = (status: NewspaperOrderStatus): string => {
  switch (status) {
    case NewspaperOrderStatus.AWAITING_REVIEW:
      return 'Pending (Awaiting Review)';
    case NewspaperOrderStatus.IN_REVIEW:
      return 'In Review';
    case NewspaperOrderStatus.CONFIRMED:
      return 'Confirmed (Ready for Pagination)';
    case NewspaperOrderStatus.COMPLETE:
      return 'Complete (Paginated)';
    case NewspaperOrderStatus.CANCELLED:
      return 'Cancelled';
    case NewspaperOrderStatus.DRAFT:
      return 'Draft';
    case NewspaperOrderStatus.ARCHIVED:
      return 'Archived';
    case NewspaperOrderStatus.DELETED:
      return 'Deleted';
    default:
      return capitalizeWord(
        (status as string).toLowerCase().replace(/_/g, ' ')
      );
  }
};

export function ConfirmationStatusFilter({
  confirmationStatuses,
  editedRowFilter,
  onSelectNewFilterValue
}: ConfirmationStatusFilterProps) {
  const allStatuses = [
    ...new Set([
      ...confirmationStatuses,
      ...PENDING_STATUSES,
      ...PAGINATION_READY_STATUSES
    ])
  ];

  const statusOptions = [
    {
      value: SHOW_ALL_OPTION_VALUE,
      label: 'Show All'
    },
    ...allStatuses.map(status => ({
      value: status,
      label: getStatusLabel(status)
    }))
  ];

  return (
    <ColumnSelect
      id="confirmation-status-filter"
      labelText="Filter by confirmation status"
      size="small"
      options={statusOptions}
      value={editedRowFilter.status ?? SHOW_ALL_OPTION_VALUE}
      onChange={onSelectNewFilterValue}
    />
  );
}
