import React from 'react';
import { ESnapshotExists, EOrganization, ENoticeDraft, EUser } from 'lib/types';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import { Form } from 'lib/components/Form';
import ScrollStep from './ScrollStep';
import { SCHEDULE_NOTICE } from './helpers/calculatePlacementSteps';
import { ReferenceIdForm, useReferenceIdState } from './ReferenceIdForm';
import {
  InvoiceRecipientForm,
  useInvoiceRecipientFormState
} from './InvoiceRecipientForm';
import {
  ConfirmScheduleForm,
  useConfirmScheduleState
} from './ConfirmScheduleForm';
import { AffidavitRecipientsForm } from './AffidavitRecipients/AffidavitRecipientsForm';
import { useAffidavitRecipientsState } from './AffidavitRecipients/useAffidavitRecipientsState';
import SectionDivider from './SectionDivider';
import { useFetchCustomer } from './hooks/useFetchCustomer';
import { useFetchCustomerOrganization } from './hooks/useFetchCustomerOrganization';

type ScheduleNoticeStepProps = {
  publisherOrganization?: ESnapshotExists<EOrganization>;
  notice: ESnapshotExists<ENoticeDraft>;
  filer?: ESnapshotExists<EUser>;
  next: () => void;
  previous: () => void;
  onDisabledStepClick: (stepId: string) => void;
};

export function ScheduleNoticeStep({
  publisherOrganization,
  notice,
  filer,
  next,
  previous,
  onDisabledStepClick
}: ScheduleNoticeStepProps) {
  const stepId = SCHEDULE_NOTICE;
  const isPublisher = useAppSelector(selectIsPublisher);
  const customer = useFetchCustomer();
  const customerOrganization = useFetchCustomerOrganization();

  const { referenceId, setReferenceId, saveReferenceId } =
    useReferenceIdState();
  const {
    isStepComplete: invoiceRecipientComplete,
    onExit: updateInvoiceRecipientInformation,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    organizationName,
    setOrganizationName,
    email,
    setEmail,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    sendToEmail,
    setSendToEmail,
    sendToSomeoneElse,
    setSendToSomeoneElse,
    billTo,
    setBillTo
  } = useInvoiceRecipientFormState();
  const { onExit: confirmSchedule, complete: confirmScheduleComplete } =
    useConfirmScheduleState({
      newspaper: publisherOrganization,
      notice
    });
  const {
    state: affidavitFormState,
    dispatch: affidavitStateDispatch,
    validateAffidavitForm,
    complete: affidavitMailingComplete,
    doesNotRequireEmailAffidavit,
    hideEmailAffidavitOption,
    customerMustReceiveMailedAffidavit
  } = useAffidavitRecipientsState({
    stepId,
    publisherOrganization,
    draft: notice,
    filer
  });

  const title = 'Tell us a bit more about your notice';
  const caption = `Provide details to help ${
    publisherOrganization?.data().name
  } publish this notice correctly.`;

  const complete =
    confirmScheduleComplete &&
    invoiceRecipientComplete &&
    affidavitMailingComplete;

  async function handleNext() {
    saveReferenceId();
    updateInvoiceRecipientInformation();
    confirmSchedule();
    const { success: affidavitAddressValidationSuccess } =
      await validateAffidavitForm();

    if (affidavitAddressValidationSuccess) {
      next();
    }
  }

  const formId = `${stepId}-referenceId-form`;
  return (
    <ScrollStep
      id={stepId}
      formId={formId}
      title={title}
      caption={caption}
      previous={previous}
      onDisabledStepClick={onDisabledStepClick}
      complete={complete}
    >
      <div className="space-y-6">
        <Form id={formId} onSubmit={handleNext}>
          <ReferenceIdForm
            referenceId={referenceId}
            setReferenceId={setReferenceId}
          />
        </Form>
        <ConfirmScheduleForm
          notice={notice}
          newspaper={publisherOrganization}
          customer={customer}
          customerOrganization={customerOrganization}
        />

        <InvoiceRecipientForm
          isPublisher={isPublisher}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          organizationName={organizationName}
          setOrganizationName={setOrganizationName}
          email={email}
          setEmail={setEmail}
          addressLine1={addressLine1}
          setAddressLine1={setAddressLine1}
          addressLine2={addressLine2}
          setAddressLine2={setAddressLine2}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zip={zip}
          setZip={setZip}
          sendToEmail={sendToEmail}
          setSendToEmail={setSendToEmail}
          sendToSomeoneElse={sendToSomeoneElse}
          setSendToSomeoneElse={setSendToSomeoneElse}
          billTo={billTo}
          setBillTo={setBillTo}
        />
      </div>

      <div className="uppercase my-8">
        <SectionDivider text="Affidavit Preferences" />
      </div>
      <AffidavitRecipientsForm
        state={affidavitFormState}
        dispatch={affidavitStateDispatch}
        publisherOrganization={publisherOrganization}
        doesNotRequireEmailAffidavit={doesNotRequireEmailAffidavit}
        hideEmailAffidavitOption={hideEmailAffidavitOption}
        customerMustReceiveMailedAffidavit={customerMustReceiveMailedAffidavit}
      />
    </ScrollStep>
  );
}
