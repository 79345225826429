import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import {
  dateObjectToDay,
  getClosestFuturePublishingDay,
  getDeadlineString,
  getIsAfterPublishingDeadline
} from 'lib/utils/deadlines';
import { DataWithNoticeType, getNoticeType } from 'lib/helpers';
import {
  ESnapshot,
  EOrganization,
  ENoticeDraft,
  FirebaseTimestamp,
  ESnapshotExists,
  exists,
  CustomerOrganization,
  Customer
} from 'lib/types';
import { NoticeType } from 'lib/enums';
import PlacementActions, {
  placementSelector,
  syncDynamicHeadersChange
} from 'redux/placement';
import { selectIsColumnRep, selectIsPublisher, selectUser } from 'redux/auth';
import { safeStringify } from 'lib/utils/stringify';
import {
  ExclamationCircleIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline';
import { InputAccessories } from 'lib/components/InputAccessories';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Alert, AlertProps } from 'lib/components/Alert';
import {
  ApplyMinutesOffsetSettings,
  getDateForDateStringInTimezone,
  getDateStringForDateInTimezone
} from 'lib/utils/dates';
import { DeadlineSettings } from 'lib/types/deadlines';
import { Buddy } from 'lib/components/gifs';
import ConfirmScheduleRow from './ConfirmScheduleRow';
import {
  handlePubDateChangeForWeekendEdition,
  removeRowClickForWeekendEdition,
  SATURDAY_DAY_INDEX,
  shouldDisableDate,
  SUNDAY_DAY_INDEX
} from './helpers';
import {
  getFirebaseContext,
  timestampOrDateToTimestamp
} from '../../utils/firebase';
import {
  getDeadlineBufferConfigFromMinutes,
  getDeadlineBufferMinutesForNoticePlacement
} from './helpers/deadlineBuffer';

/**
 * This component is in need of significant refactoring.
 */

type ConfirmScheduleFormProps = {
  notice: ESnapshot<ENoticeDraft>;
  newspaper: ESnapshotExists<EOrganization> | undefined;
  customer: ESnapshotExists<Customer> | null | undefined;
  customerOrganization:
    | ESnapshotExists<CustomerOrganization>
    | null
    | undefined;
};

export function ConfirmScheduleForm({
  notice,
  newspaper,
  customer,
  customerOrganization
}: ConfirmScheduleFormProps) {
  const placement = useAppSelector(placementSelector);
  const user = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);

  const {
    canAddMoreDates,
    cannotFileWPaper,
    deadlines,
    deadlineOverrides,
    publicationDates,
    newspaperTimezone,
    handleAddMoreClick,
    handlePubDateChange,
    handleRemoveRowClick,
    handleCustomNoticeTypesFlowDisable
  } = useConfirmScheduleState({
    notice,
    newspaper
  });
  const [deadlineBufferSettings, setDeadlineBufferSettings] =
    useState<ApplyMinutesOffsetSettings>();

  const deadlineConfig =
    newspaper && deadlines && newspaperTimezone
      ? getDeadlineText({
          publicationDate: publicationDates[0],
          deadlines,
          deadlineOverrides,
          newspaperTimezone,
          placement,
          newspaper,
          isPublisher,
          deadlineBufferSettings
        })
      : null;

  const emphasizeDeadlineAlert = useAppSelector(selectIsColumnRep);

  useEffect(() => {
    async function getDeadlineBufferMinutes() {
      const bufferMinutes = await getDeadlineBufferMinutesForNoticePlacement(
        notice,
        newspaper,
        customer,
        customerOrganization,
        isPublisher
      );
      const bufferSettings = getDeadlineBufferConfigFromMinutes(bufferMinutes);
      setDeadlineBufferSettings(bufferSettings);
    }
    void getDeadlineBufferMinutes();
  }, [newspaper?.id, notice?.id, isPublisher]);

  const isDuplicateDate = (
    publicationDate: Date,
    publicationDates: Date[],
    timezone: string
  ) => {
    return (
      publicationDates
        .map(date =>
          getDateStringForDateInTimezone({
            date,
            timezone
          })
        )
        .filter(
          dateString =>
            dateString ===
            getDateStringForDateInTimezone({
              date: publicationDate,
              timezone
            })
        ).length > 1
    );
  };

  const [someDateIsDuplicate, setSomeDateIsDuplicate] = useState(false);
  useEffect(() => {
    if (!publicationDates || !newspaperTimezone) {
      return;
    }

    setSomeDateIsDuplicate(
      publicationDates.some(pd =>
        isDuplicateDate(pd, publicationDates, newspaperTimezone)
      )
    );
  }, [safeStringify(publicationDates), newspaperTimezone]);

  const showAddMoreDates = !cannotFileWPaper && canAddMoreDates;
  return (
    <>
      <InputAccessories
        id={'schedule-notice'}
        labelText={'When should your notice be published?'}
        errorText={
          someDateIsDuplicate
            ? 'Cannot select the same publication date twice'
            : ''
        }
      >
        {cannotFileWPaper && (
          <div>This paper has not set up a publication schedule yet.</div>
        )}
        {!cannotFileWPaper && (
          <>
            {deadlineConfig && (
              <div className="mb-3">
                <Alert
                  id="notice-schedule-deadline"
                  title={deadlineConfig.text}
                  status={deadlineConfig.status}
                  icon={<ExclamationCircleIcon className="h-5 w-5" />}
                >
                  {emphasizeDeadlineAlert &&
                    deadlineConfig?.status === 'error' && (
                      <div className="flex">
                        <img className="w-16" src={Buddy} />
                        <p className="text-xl pt-4">
                          <b>Are you sure you want to file this notice?</b>
                        </p>
                        <img className="w-16" src={Buddy} />
                      </div>
                    )}
                </Alert>
              </div>
            )}
            <div className={'grid grid-cols-2 gap-x-6 gap-y-3'}>
              {newspaper &&
                !!publicationDates?.length &&
                !!deadlines?.length &&
                newspaperTimezone && (
                  <>
                    {publicationDates.map((publicationDate, index) => {
                      const isDuplicate = isDuplicateDate(
                        publicationDate,
                        publicationDates,
                        newspaperTimezone
                      );

                      return (
                        <div
                          className={
                            showAddMoreDates || publicationDates.length > 1
                              ? 'col-span-1'
                              : 'col-span-2'
                          }
                          key={index}
                        >
                          <ConfirmScheduleRow
                            index={index}
                            publicationDate={publicationDate}
                            isPublisher={isPublisher}
                            user={user}
                            deadlines={deadlines}
                            newspaperTimezone={newspaperTimezone}
                            newspaper={newspaper}
                            notice={notice}
                            handlePubDateChange={handlePubDateChange}
                            handleRemoveRowClick={handleRemoveRowClick}
                            disabled={handleCustomNoticeTypesFlowDisable(
                              publicationDate
                            )}
                            showDelete={publicationDates.length !== 1}
                            isDuplicateDate={isDuplicate}
                          />
                        </div>
                      );
                    })}
                    {showAddMoreDates && (
                      <div className="col-span-1">
                        <ColumnButton
                          id="add-more-dates"
                          secondary
                          buttonText="Add another publication date"
                          startIcon={<PlusCircleIcon className="w-5 h-5" />}
                          onClick={handleAddMoreClick}
                          fullWidth
                          type="button"
                        />
                      </div>
                    )}
                  </>
                )}
            </div>
          </>
        )}
      </InputAccessories>
    </>
  );
}

const shouldAutoSetDates = (
  notice: ESnapshot<ENoticeDraft>,
  newspaper: ESnapshot<EOrganization>,
  publicationDates: Date[]
) => {
  // don't auto-set if there isn't an allowed notice array
  if (!newspaper?.data()?.allowedNotices) return false;

  // don't auto-set if it is a default notice type
  if (
    notice?.data()?.noticeType === NoticeType.custom.value ||
    notice?.data()?.noticeType === NoticeType.display_ad.value
  )
    return false;

  // get the associated notice type
  const noticeType = getNoticeType(notice, newspaper);
  if (
    noticeType?.requiredPublications &&
    publicationDates.length <= noticeType?.requiredPublications
  )
    return true;

  return false;
};

/**
 * This logic was previously nested inside of ConfirmScheduleStep.
 * It was moved here to modularize the schedule form and make it
 * reusable in ScheduleNoticeStep.
 * TODO: Write characterization tests for this form
 * TODO: Refactor action-based functions into thunks and derived state into selectors
 */
export function useConfirmScheduleState({
  notice,
  newspaper
}: {
  notice: ESnapshot<ENoticeDraft>;
  newspaper: ESnapshotExists<EOrganization> | undefined;
}) {
  const dispatch = useAppDispatch();
  const placement = useAppSelector(placementSelector);
  const user = useAppSelector(selectUser);
  const isPublisher = useAppSelector(selectIsPublisher);
  const [complete, setComplete] = useState(false);
  const [initialPublicationDate, setInitialPublicationDate] = useState(
    placement.publicationDates?.[0]?.toDate()
  );
  const noticeType = getNoticeType(notice, newspaper);

  const deadlines = exists(newspaper) ? newspaper.data().deadlines : null;
  const deadlineOverrides = exists(newspaper)
    ? newspaper.data().deadlineOverrides ?? {}
    : {};
  const newspaperTimezone = exists(newspaper)
    ? newspaper.data().iana_timezone
    : null;
  const cannotFileWPaper = !deadlines || !newspaperTimezone;

  const publicationDates: Date[] = placement.publicationDates
    ? placement.publicationDates.map((timestamp: FirebaseTimestamp) =>
        timestamp.toDate()
      )
    : [];

  const setPlacementPublicationDates = (
    ...dates: Date[] | FirebaseTimestamp[]
  ) => {
    dispatch(
      PlacementActions.setPublicationDates(
        dates.map(timestampOrDateToTimestamp)
      )
    );
  };

  const isComplete = (publicationDates: Date[]) => {
    const areRepeatedDateSelections = (publicationDates: Date[]) => {
      const dateStrings = publicationDates
        .map(date => {
          if (!date) return false;
          return date.toDateString();
        })
        .filter(Boolean);
      return new Set(dateStrings).size !== dateStrings.length;
    };
    const areAnyDaysDisabled = (publicationDates: Date[]) => {
      return publicationDates.find(day =>
        shouldDisableDate({
          day,
          newspaper,
          user,
          notice: placement,
          noticeType,
          isPublisher
        })
      );
    };
    return (
      !areRepeatedDateSelections(publicationDates) &&
      (!areAnyDaysDisabled(publicationDates) || isPublisher)
    );
  };

  const onExit = (updatedPublicationDates?: Date[]) => {
    const sortedPublicationDates = (updatedPublicationDates || publicationDates)
      .sort((a: Date, b: Date) => a.getTime() - b.getTime())
      .map(d => {
        /**
         * Setting the publication date time to 12:00pm in the newspaper's timezone
         * Eventually, publication dates should be strings ('YYYY-MM-DD'), not dates/timestamps,
         * or replaced with `runs`
         */
        return getDateForDateStringInTimezone({
          dayString: getDateStringForDateInTimezone({
            date: d,
            timezone: newspaperTimezone || 'America/Chicago'
          }),
          timezone: newspaperTimezone || 'America/Chicago',
          time: '12:00'
        });
      });

    const fbDates = sortedPublicationDates.map(
      getFirebaseContext().timestampFromDate
    );

    dispatch(
      PlacementActions.confirmSchedule({
        publicationDates: fbDates,
        dynamicFooter: null,
        footerFormatString: null
      })
    );
    dispatch(syncDynamicHeadersChange(newspaper));
  };

  const handleAddMoreClick = () => {
    if (!deadlines || !newspaperTimezone) {
      return;
    }

    let newPublicationDates = [...publicationDates];
    const DEFAULT_DAYS_TO_ADD = 7;
    const daysToAdd =
      noticeType?.weekendEditionEnabled &&
      noticeType?.defaultDaysBetweenPublication
        ? noticeType?.defaultDaysBetweenPublication
        : DEFAULT_DAYS_TO_ADD;
    const nextPotentialPublishingDate = moment(
      publicationDates[publicationDates.length - 1]
    )
      .add(daysToAdd, 'days')
      .toDate();
    const nextPublishingDate = getClosestFuturePublishingDay(
      deadlines,
      deadlineOverrides,
      newspaperTimezone,
      placement,
      newspaper as ESnapshotExists<EOrganization>,
      nextPotentialPublishingDate
    );

    // Adds both next Saturday and next Sunday if next publishing day is a weekend
    if (
      noticeType?.weekendEditionEnabled &&
      moment(nextPublishingDate).day() === SATURDAY_DAY_INDEX
    ) {
      const sunday = moment(nextPublishingDate).day(7).toDate();
      newPublicationDates.push(sunday);
    } else if (
      noticeType?.weekendEditionEnabled &&
      moment(nextPublishingDate).day() === SUNDAY_DAY_INDEX
    ) {
      const saturday = moment(nextPublishingDate).day(-1).toDate();
      newPublicationDates.push(saturday);
    }

    newPublicationDates.push(nextPublishingDate);
    /**
     * Using a unary + operator to coerce Date to Number avoids ts(2362) error
     * https://github.com/microsoft/TypeScript/issues/5710
     */
    newPublicationDates = newPublicationDates.sort(
      (a: Date, b: Date) => +a - +b
    );

    dispatch(PlacementActions.setPublicationDatesUpdated(true));
    setPlacementPublicationDates(...newPublicationDates);
  };

  const handleRemoveRowClick = async (i: number) => {
    let newDates: Date[] = [];
    if (noticeType?.weekendEditionEnabled) {
      newDates = removeRowClickForWeekendEdition(publicationDates, i);
    } else {
      newDates = publicationDates.filter(
        (s: Date, sIndex: number) => i !== sIndex
      );
    }

    // If the array is empty add the initial date to prevent error
    if (newDates.length === 0 && initialPublicationDate) {
      newDates.push(initialPublicationDate);
    }

    dispatch(PlacementActions.setPublicationDatesUpdated(true));
    setPlacementPublicationDates(...newDates);
  };

  const handlePubDateChange = (pubDate: Date, i: number) => {
    /**
     * NOTE: This is a legacy fix, and I don't think setting the hours to noon in
     * the user's device timezone is a reliable fix for so-called "late night filing" issues.
     * We should probably be setting the date and time based on the newspaper's timezone
     */
    pubDate.setHours(12);
    let newPubDates: Date[];

    if (!initialPublicationDate) {
      setInitialPublicationDate(publicationDates[0]);
    }

    if (
      exists(newspaper) &&
      shouldAutoSetDates(notice, newspaper, publicationDates) &&
      !isPublisher
    ) {
      newPubDates = [pubDate];
    } else {
      newPubDates = [...publicationDates];
      newPubDates[i] = pubDate;
    }

    if (noticeType?.weekendEditionEnabled) {
      newPubDates = handlePubDateChangeForWeekendEdition(
        pubDate,
        publicationDates,
        i
      );
    }
    /**
     * Using a unary + operator to coerce Date to Number avoids ts(2362) error
     * https://github.com/microsoft/TypeScript/issues/5710
     */
    newPubDates = newPubDates.sort((a: Date, b: Date) => +a - +b);
    setPlacementPublicationDates(...newPubDates);
  };

  /**
   * Set the publication dates to the next valid one for the chosen newspaper.
   */
  const resetPublicationDates = () => {
    if (!exists(newspaper) || !deadlines || !newspaperTimezone) {
      return;
    }
    setPlacementPublicationDates(
      getClosestFuturePublishingDay(
        deadlines,
        deadlineOverrides,
        newspaperTimezone,
        placement,
        newspaper
      )
    );
  };

  useEffect(() => {
    if (isComplete(publicationDates)) {
      onExit(publicationDates);
    }
  }, [isComplete(publicationDates)]);

  useEffect(() => {
    if (
      !exists(newspaper) ||
      !exists(notice) ||
      !deadlines ||
      !newspaperTimezone
    ) {
      return;
    }

    if (!publicationDates?.length) {
      resetPublicationDates();
    }
  }, [newspaper?.id, notice?.id, publicationDates?.length]);

  useEffect(() => {
    if (!publicationDates.length || publicationDates.find(d => d === null))
      return;
    setComplete(isComplete(publicationDates));
  }, [safeStringify(placement.publicationDates), deadlines, newspaperTimezone]);

  useEffect(() => {
    if (!publicationDates.length || publicationDates.find(d => d === null)) {
      return;
    }

    setComplete(isComplete(publicationDates));
  }, []);

  // TODO: Merge with other disabled logic in ConfirmScheduleRow
  const handleCustomNoticeTypesFlowDisable = (publicationDate: Date) => {
    // publishers can always edit
    if (isPublisher) return false;

    if (publicationDates.indexOf(publicationDate) === 0) return false;

    // only disable on custom notice types with preset runs and that do not allow for
    // additional runs to be added beyond the required ones
    if (
      exists(newspaper) &&
      shouldAutoSetDates(notice, newspaper, publicationDates) &&
      !noticeType?.canAddBeyondRequired
    )
      return true;

    // by default allow editing
    return false;
  };

  const canAddMoreDates =
    isPublisher ||
    (exists(newspaper) &&
      !(
        newspaper.data().allowedNotices &&
        placement.noticeType !== NoticeType.custom.value &&
        noticeType?.requiredPublications &&
        !noticeType?.canAddBeyondRequired
      ));

  return {
    onExit,
    complete,
    canAddMoreDates,
    cannotFileWPaper,
    deadlines,
    deadlineOverrides,
    publicationDates,
    newspaperTimezone,
    handleAddMoreClick,
    handlePubDateChange,
    handleRemoveRowClick,
    handleCustomNoticeTypesFlowDisable
  };
}

type DeadlineConfig = {
  text: string;
  status: AlertProps['status'];
};

export const getDeadlineText = ({
  publicationDate,
  deadlines,
  deadlineOverrides,
  newspaperTimezone,
  placement,
  newspaper,
  isPublisher,
  deadlineBufferSettings
}: {
  publicationDate: Date;
  deadlines: DeadlineSettings[];
  deadlineOverrides: Record<string, DeadlineSettings>;
  newspaperTimezone: string;
  placement: DataWithNoticeType;
  newspaper: ESnapshotExists<EOrganization>;
  isPublisher: boolean;
  deadlineBufferSettings?: ApplyMinutesOffsetSettings;
}): DeadlineConfig => {
  if (!publicationDate) {
    return {
      text: 'Deadline information will appear once a date has been selected.',
      status: 'info'
    };
  }

  const isAfterPublishingDeadline = getIsAfterPublishingDeadline(
    publicationDate,
    deadlines,
    deadlineOverrides,
    newspaperTimezone,
    placement,
    newspaper,
    undefined
  );
  const isWithinDeadlineBuffer =
    !isAfterPublishingDeadline &&
    getIsAfterPublishingDeadline(
      publicationDate,
      deadlines,
      deadlineOverrides,
      newspaperTimezone,
      placement,
      newspaper,
      deadlineBufferSettings
    );

  if (isPublisher && isAfterPublishingDeadline) {
    return {
      text: `Your deadline for ${dateObjectToDay(
        publicationDate
      )} has passed, but you can still upload this notice.`,
      status: 'error'
    };
  }

  let text: string;
  const status = isAfterPublishingDeadline ? 'error' : 'warning';
  const deadlineString = getDeadlineString(
    deadlines,
    deadlineOverrides,
    publicationDate,
    placement,
    newspaper,
    undefined
  );
  const deadlineBufferedString = getDeadlineString(
    deadlines,
    deadlineOverrides,
    publicationDate,
    placement,
    newspaper,
    deadlineBufferSettings
  );

  if (isWithinDeadlineBuffer) {
    text = `The deadline for this paper is ${deadlineString}, and pre-payment is required. To ensure timely invoice processing, submit your notice before ${deadlineBufferedString} to avoid order cancellation`;
  } else if (!isAfterPublishingDeadline) {
    text = `To meet the deadline for this publication, please submit it no later than ${deadlineString}`;
  } else {
    text = `The deadline for advertisements for ${dateObjectToDay(
      publicationDate
    )} was ${deadlineString}`;
  }

  return {
    text: `${text}.`,
    status
  };
};
