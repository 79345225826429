import classNames from 'classnames';
import { html, css } from 'lit';
import { property, state } from 'lit/decorators.js';
import { Colors, ColorsCss } from './styles';
import { MadlibElement } from './MadlibElement';

// TODO:
// - Click outside
// - This is not accessible! We should find a way to make this tabbable
export class MadlibMultipleChoiceInput extends MadlibElement {
  static TAG_NAME = 'madlib-multiple-choice-input';

  static styles = css`
    .main {
      display: inline-flex;
    }

    .main-selector {
      position: relative;
      margin-left: 2px;
      margin-bottom: 2px;
    }

    .main-block {
      cursor: pointer;
      border-bottom: 1px solid ${ColorsCss.LIGHT_BLUE};
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .main-block.valid {
      border-bottom: 1px solid ${ColorsCss.DARK_BLUE};
    }

    .main-block.invalid {
      border-bottom: 1px solid ${ColorsCss.RED};
    }

    .options-list {
      position: absolute;
      background-color: #fff;
      border-radius: 0.125rem;
      border: 1px solid #e2e8f0;
      z-index: 50;
      padding: 4px 4px;
      margin: 2px 0 0 0;
      min-height: 40px;
      max-height: 5em;
      overflow-y: auto;
      min-width: 75%;
      max-width: 200%;
    }

    .main-block.invalid {
      color: ${ColorsCss.RED};
    }

    .options-list::-webkit-scrollbar {
      width: 4px;
    }

    .options-list::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.025);
    }

    .options-list::-webkit-scrollbar-thumb {
      background: rgba(193, 193, 193);
      border-radius: 4px;
    }

    .options-list::-webkit-scrollbar-thumb:hover {
      background: rgba(125, 125, 125);
    }

    .list-item {
      cursor: pointer;
      display: block;
      padding: 2px 4px;
      border-radius: 0.125em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 0.9em;
    }

    .list-item:hover {
      color: #2d9bdb;
      background-color: #f5fafd;
    }

    li {
      display: list-item;
    }

    ul {
      list-style-type: none;
    }

    .selected-value {
      margin-left: 0.1rem;
      line-height: 1;
      color: ${ColorsCss.LIGHT_BLUE};
    }

    .valid .selected-value {
      color: unset;
    }

    .invalid .selected-value {
      color: ${ColorsCss.RED};
    }

    .drop-down-icon {
      margin-left: auto;
    }

    .noHeight {
      height: 0px;
    }
  `;

  @property()
  options = '';

  @property()
  open = false;

  @state()
  _parsingError: boolean | undefined;

  private _clickOutsideCallback: undefined | ((e: MouseEvent) => void) =
    undefined;

  handleMenuOption(selectedValue: string) {
    this.value = selectedValue;

    const customEvent = new CustomEvent('template-updated', {
      bubbles: true,
      composed: true
    });
    this.dispatchEvent(customEvent);
    this.toggleMenu();
  }

  toggleMenu() {
    this.open = !this.open;
    this._handleBlur();
  }
  // TODO: Make it work for single quotes and any characters in string or array element

  parsingOptions() {
    try {
      const optionsArr = JSON.parse(this.options);
      return optionsArr;
    } catch (err) {
      console.log('error while parsing options JSON');
      this._parsingError = true;
      return [];
    }
  }

  async connectedCallback() {
    await super.connectedCallback();

    this._clickOutsideCallback = (event: MouseEvent) => {
      if (this.open && !event.composedPath().includes(this)) {
        this.open = false;
      }
    };
    document.addEventListener('click', this._clickOutsideCallback);
  }

  disconnectedCallback() {
    if (this._clickOutsideCallback) {
      document.removeEventListener('click', this._clickOutsideCallback);
    }
    super.disconnectedCallback();
  }

  render() {
    const optionsListWidthClass = classNames({ noHeight: this._parsingError });
    const valid = this._dirty && !this._isInvalid();
    const invalid = this._dirty && this._isInvalid();

    return html`<span class="main"
      ><madlib-hint text="${this.hint}"></madlib-hint>
      <div class="main-selector">
        <div
          class="${classNames('main-block', {
            valid,
            invalid
          })}"
          @click=${this.toggleMenu}
        >
          <span class="selected-value"
            >${this.value ? this.value : 'Select option'}</span
          >
          <svg
            height="12"
            width="12"
            fill="${valid
              ? Colors.DARK_BLUE
              : invalid
              ? Colors.RED
              : Colors.LIGHT_BLUE}"
            viewBox="0 0 20 20"
            class="drop-down-icon"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <ul class="options-list ${optionsListWidthClass}" ?hidden=${!this.open}>
          ${this.parsingOptions().map(
            (option: string) =>
              html`<li
                class="list-item"
                title=${option}
                @click="${() => this.handleMenuOption(option)}"
              >
                <span>${option}</span>
              </li>`
          )}
        </ul>
      </div>
    </span>`;
  }
}
