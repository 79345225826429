import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { clearMadlibData } from 'redux/placement';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { syncNoticeTypeChange } from 'redux/placement/placementActions';

import {
  selectValidNoticeTypes,
  selectCurrentlySelectedNoticeType
} from './placeScrollSelectors';

type SelectNoticeTypeProps = {
  newspaper?: ESnapshotExists<EOrganization>;
  onChange?: () => void;
};

export function SelectNoticeType({
  newspaper,
  onChange
}: SelectNoticeTypeProps) {
  const dispatch = useAppDispatch();
  const validNoticeTypes = useAppSelector(state =>
    selectValidNoticeTypes(state, newspaper)
  );
  const selectedNoticeType = useAppSelector(state =>
    selectCurrentlySelectedNoticeType(state, newspaper)
  );
  const [contentWarningNoticeType, setContentWarningNoticeType] =
    useState<CustomNoticeFilingType | null>(null);

  /**
   * We only want to set the label and id when there is a valid selected notice type, otherwise set it to empty string.
   * This works for notice types like "Other".
   */
  const formattedSelectedOption =
    selectedNoticeType && selectedNoticeType.value
      ? {
          id: String(selectedNoticeType.value),
          label: selectedNoticeType.label
        }
      : '';

  const syncNewNoticeType = (noticeType: CustomNoticeFilingType) => {
    dispatch(syncNoticeTypeChange(noticeType));
    if (onChange) onChange();
  };

  const onChangeNoticeType = (noticeType: number) => {
    const newNoticeType = validNoticeTypes?.find(
      validNoticeType => validNoticeType.value === noticeType
    );

    /**
     * Show a warning modal
     * if there is a previously selected notice type, And
     * if notice type switched from any non-madlib to madlib or madlib to madlib
     */
    const shouldShowWarning =
      !!selectedNoticeType?.value &&
      !!selectedNoticeType?.madlib &&
      !!newNoticeType &&
      newNoticeType.value !== selectedNoticeType?.value &&
      newNoticeType.madlib !== selectedNoticeType?.madlib;

    if (shouldShowWarning) {
      return setContentWarningNoticeType(newNoticeType);
    }

    if (newNoticeType) {
      syncNewNoticeType(newNoticeType);
    }
  };

  return (
    <>
      <ColumnSelect
        labelText="What type of notice would you like to place?"
        placeholder="Select a notice type"
        id="selectNoticeType"
        options={(validNoticeTypes || []).map(validNoticeType => ({
          value: String(validNoticeType.value),
          label: validNoticeType.label
        }))}
        value={(formattedSelectedOption || {}).id}
        allowUndefined
        onChange={newNoticeTypeId => {
          onChangeNoticeType(Number(newNoticeTypeId));
        }}
      />

      {contentWarningNoticeType && (
        <CancelOrSubmitModal
          header="Confirm Notice Type Change"
          onClose={() => setContentWarningNoticeType(null)}
          tertiaryButtonText="Cancel"
          primaryButtonText="Continue"
          overrideTertiaryClose={() => {
            setContentWarningNoticeType(null);
          }}
          onSubmit={() => {
            dispatch(clearMadlibData());
            syncNewNoticeType(contentWarningNoticeType);
            setContentWarningNoticeType(null);
          }}
        >
          <div className="my-8 mx-1 text-gray-600 text-md">
            Are you sure you want to change the notice type? If you proceed,
            your notice draft will be lost. Please click "Continue" to confirm
            or click "Cancel" to go back.
          </div>
        </CancelOrSubmitModal>
      )}
    </>
  );
}
