/* eslint-disable no-await-in-loop */
import { getErrorReporter } from '../../utils/errors';
import {
  EFirebaseContext,
  ENotice,
  ENoticeDraft,
  EOrganization,
  ERef,
  ESnapshotExists,
  EUser,
  MailDelivery,
  NoticeSubmittedEvent
} from '../../types';
import { getModelFromRef, getModelFromSnapshot } from '../../model';
import { UserNoticeModel } from '../../model/objects/userNoticeModel';
import { UserModel } from '../../model/objects/userModel';
import { NoticeType } from '../../enums';
import { paperIsValidForCustomerPlacementFlow } from '../../publishers';
import { getOrThrow } from '../../utils/refs';
import {
  getNoticeTypeFromNoticeData,
  removeUndefinedFields
} from '../../helpers';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import { getNoticeMail } from '../../mail';
import { getOrCreateCustomer } from '../../notice/customer';
import { validateNotice } from './noticeValidation';
import { safeGetOrThrow } from '../../safeWrappers';
import { NOTICE_SUBMITTED_EVENT } from '../../types/events';

type CreateNewNoticeRequest = {
  asUser: ESnapshotExists<EUser> | null;
  withAnonymousFilerId: string | null;
  inPublisherOrganization: ERef<EOrganization> | null;
  withInitialData?: Partial<ENotice>;
};

function getPublisherOrganizationFieldsForNotice(
  publisherOrganization: ESnapshotExists<EOrganization> | null
) {
  if (!publisherOrganization) return {};

  const { adTemplate, defaultLinerRate, defaultNoticeType } =
    publisherOrganization.data();

  const noticeType = defaultNoticeType || NoticeType.custom.value;

  /**
   * NOTE: Consider refactoring how we populate initial madlib data to not depend on this
   * field being set on notice creation if the default notice type is a madlib
   * */
  const customNoticeType = getNoticeTypeFromNoticeData(
    { noticeType },
    publisherOrganization,
    { skipDisplayType: true }
  );
  const madlibData = customNoticeType?.madlib
    ? {
        templateData: {},
        questionTemplateData: {}
      }
    : undefined;

  return {
    newspaper: publisherOrganization.ref,
    adTemplate,
    rate: defaultLinerRate,
    noticeType,
    madlibData
  };
}

function getUserFieldsForNotice(user: UserModel | null) {
  if (!user) return {};

  const { isPublisher } = user;
  const createdBy = isPublisher ? user.ref : undefined;
  const filer = !isPublisher ? user.ref : undefined;
  const filedBy = !isPublisher ? user.modelData.activeOrganization : undefined;

  return {
    createdBy,
    filer,
    filedBy,
    userId: user.id // TODO: Deprecate this field fully
  };
}

export type NoticePublicationSource =
  | 'placement'
  | 'inbox_automation'
  | 'registered_agents';
export type NoticeServiceAgent = {
  isPublisher: boolean;
  user: ERef<EUser> | undefined;
  source: NoticePublicationSource;
};

export class NoticeService {
  private firebaseContext: EFirebaseContext;

  constructor(firebaseContext: EFirebaseContext) {
    this.firebaseContext = firebaseContext;
  }

  /**
   * Create an incomplete starter notice document with an associated draft
   */
  async createInitialNoticeWithDraft({
    asUser,
    withAnonymousFilerId,
    inPublisherOrganization,
    withInitialData
  }: CreateNewNoticeRequest) {
    const initialNoticeFields = await this.buildInitialNoticeFields({
      asUser,
      withAnonymousFilerId,
      inPublisherOrganization,
      withInitialData
    });
    const notice = await this.saveNotice(initialNoticeFields);
    const userRef = asUser ? asUser.ref : null;
    const draftRef = await notice.createDraft({ asUser: userRef });
    const draft = await getOrThrow(draftRef);
    return {
      notice,
      draft
    };
  }

  public async updateWithDraftData(
    noticeRef: ERef<ENotice>,
    draftRef: ERef<ENoticeDraft>,
    agent: NoticeServiceAgent
  ) {
    const noticeModel = await getModelFromRef(
      UserNoticeModel,
      this.firebaseContext,
      noticeRef
    );
    const { error, response } = await noticeModel.updateWithDraftData({
      draftRef,
      agent
    });
    if (error) return wrapError(error);
    return wrapSuccess(response);
  }

  /**
   * Publishes a notice using the given notice reference, draft reference, notice service agent, and account number.
   *
   * @param {ERef<ENotice>} noticeRef - The reference to the notice entity.
   * @param {ERef<ENoticeDraft>} draftRef - The reference to the notice draft entity.
   * @param {NoticeServiceAgent} agent - The notice service agent that will act for publishing the notice.
   * @param {boolean} isNew - Whether the notice is new or not. This is used to determine if a NOTICE_SUBMITTED_EVENT should be created.
   * @param {string | undefined} accountNumber - The account number to be updated on the notice (optional).
   * @returns {Promise<ResponseOrError<ENotice>>} - A promise that resolves with the updated notice or an error.
   */
  async publishNoticeFromDraft(
    noticeRef: ERef<ENotice>,
    draftRef: ERef<ENoticeDraft>,
    agent: NoticeServiceAgent,
    accountNumber: string | undefined
  ): Promise<ResponseOrError<UserNoticeModel>> {
    const noticeSnapshot = await safeGetOrThrow(noticeRef);
    if (noticeSnapshot.error) return wrapError(noticeSnapshot.error);
    const isNew = !noticeSnapshot.response.data().noticeStatus;
    const { response: noticeModel, error: updateError } =
      await this.updateWithDraftData(noticeRef, draftRef, agent);
    if (updateError) return wrapError(updateError);
    await draftRef.update(
      removeUndefinedFields({
        editedAt: this.firebaseContext.timestamp(),
        lastEditedBy: agent.user
      })
    );
    const { response: publishResponse, error: publishError } =
      await this.publishNotice(noticeModel, agent, isNew);
    if (publishError) return wrapError(publishError);
    // post publication actions
    const { error: customerUpdateError } = await this.updateCustomerForNotice(
      noticeModel
    );
    if (customerUpdateError) wrapError(customerUpdateError);
    if (accountNumber) {
      await noticeModel.maybeUpdateAccountNumberOnNotice(accountNumber);
    }
    return wrapSuccess(publishResponse);
  }

  async publishNotice(
    noticeModel: UserNoticeModel,
    agent: NoticeServiceAgent,
    isNew: boolean
  ) {
    const { error: validationError } = await validateNotice(
      noticeModel.modelData,
      this.firebaseContext
    );
    if (validationError) {
      return wrapError(
        new Error(`Failed to validate notice: ${validationError.message}`, {
          cause: validationError
        })
      );
    }
    const { error: noticePublishError } = await noticeModel.publish();
    if (noticePublishError) return wrapError(noticePublishError);
    if (isNew) {
      await this.firebaseContext.eventsRef<NoticeSubmittedEvent>().add({
        type: NOTICE_SUBMITTED_EVENT,
        createdAt: this.firebaseContext.fieldValue().serverTimestamp(),
        notice: noticeModel.ref,
        data: removeUndefinedFields({
          newspaper: noticeModel.modelData.newspaper,
          filer: noticeModel.modelData.filer,
          publicationDates: noticeModel.modelData.publicationDates,
          source: agent.source,
          submittedNoticeData: noticeModel.modelData
        })
      });
    }
    return wrapSuccess(noticeModel);
  }

  private async saveNotice(noticeData: Partial<ENotice>) {
    const noticeRef = this.firebaseContext.userNoticesRef().doc();
    await noticeRef.set(noticeData);
    return await getModelFromRef(
      UserNoticeModel,
      this.firebaseContext,
      noticeRef
    );
  }

  private async buildInitialNoticeFields({
    asUser,
    withAnonymousFilerId,
    inPublisherOrganization,
    withInitialData
  }: CreateNewNoticeRequest) {
    const publisherOrganization = inPublisherOrganization
      ? await getOrThrow(inPublisherOrganization)
      : null;

    if (publisherOrganization) {
      const publisherEnabledForPlacement = paperIsValidForCustomerPlacementFlow(
        publisherOrganization
      );
      if (!publisherEnabledForPlacement) {
        getErrorReporter().logInfo(
          'Placing notice for paper not enabled for customer placement flow',
          {
            publisherOrganizationId: publisherOrganization.id
          }
        );
      }
    }

    const user = asUser
      ? getModelFromSnapshot(UserModel, this.firebaseContext, asUser)
      : null;

    const initialNotice: Partial<ENotice> = {
      createTime: this.firebaseContext.fieldValue().serverTimestamp() as any,
      isArchived: false,
      ...getUserFieldsForNotice(user),
      ...getPublisherOrganizationFieldsForNotice(publisherOrganization),
      anonymousFilerId: withAnonymousFilerId || undefined,
      ...withInitialData
    };
    return removeUndefinedFields(initialNotice);
  }

  /**
   * Adds any mail delivery requests on Notice to the corresponding filer document.
   *
   * @param {UserNoticeModel} notice - The UserNoticeModel object to update the filer for.
   * @return {Promise<ResponseOrError<null>>} - ResponseOrError object with null response if op was successful.
   */
  public async addMailDeliveryRequestsToFiler(
    notice: UserNoticeModel
  ): Promise<ResponseOrError<null>> {
    const { filer, newspaper } = notice.modelData;
    let mailDocs: ESnapshotExists<MailDelivery>[];
    try {
      mailDocs = await getNoticeMail(notice.ref);
    } catch (e) {
      return wrapError(e as Error);
    }
    const mailsToBeSaved = [];
    if (mailDocs.length > 0) {
      for (const mail of mailDocs) {
        const {
          name,
          copies,
          address,
          isCourthouse,
          isNoticeTypeDefault,
          courtHouse
        } = mail.data() as MailDelivery;
        if (!isNoticeTypeDefault) {
          mailsToBeSaved.push({
            name,
            copies,
            address,
            ...(isCourthouse && { isCourthouse }),
            ...(courtHouse && { courtHouse })
          });
        }
      }
    }
    try {
      await filer.update({
        savedInfo: {
          newspaper,
          ...(mailsToBeSaved.length && { mails: mailsToBeSaved })
        }
      });
      return wrapSuccess(null);
    } catch (e) {
      return wrapError(e as Error);
    }
  }

  /**
   * Updates the customer for a notice.
   *
   * @param {UserNoticeModel} notice - The notice to update the customer for.
   * @return {Promise<ResponseOrError<null>>} - A promise that resolves to a null response if successful.
   */
  private async updateCustomerForNotice(
    notice: UserNoticeModel
  ): Promise<ResponseOrError<null>> {
    try {
      const user = await getOrThrow(notice.modelData.filer);
      const newspaper = await getOrThrow(notice.modelData.newspaper);
      const customer = await getOrCreateCustomer(
        this.firebaseContext,
        user,
        newspaper
      );
      if (customer.data().archived) {
        await customer.ref.update({ archived: false });
      }
      return wrapSuccess(null);
    } catch (e) {
      return wrapError(e as Error);
    }
  }

  public getNoticeEventsQuery(notice: UserNoticeModel) {
    return this.firebaseContext
      .eventsRef()
      .where('notice', '==', notice.ref)
      .orderBy('createdAt');
  }
}
