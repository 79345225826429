import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import api from 'api';
import { PasswordField } from 'components/PasswordField';
import Firebase from 'EnoticeFirebase';
import { EResponseTypes } from 'lib/types';
import {
  dwollaInvalidPasswordMessage,
  dwollaPasswordValidation,
  passwordValidation
} from 'lib/passwordValidators';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import { Modal } from 'lib/components/Modal';
import { TextField } from 'lib/components/TextField';
import { Form } from 'lib/components/Form';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { logAndCaptureException } from 'utils';
import { Alert } from 'lib/components/Alert';

export function ResetPassword() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMatch, setErrorMatch] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');
  const [codeVerified, setCodeVerified] = useState(true);
  const [oobCode, setOobCode] = useState('');
  const [dwollaEnabledUser, setDwollaEnabledUser] = useState(false);
  const [validationLoading, setValidationLoading] = useState(true);

  useEffect(() => {
    async function verifyPasswordResetCode() {
      const code = new URLSearchParams(window.location.search)?.get('oobCode');
      if (code) {
        setOobCode(code);
        try {
          const email = await Firebase.auth().verifyPasswordResetCode(code);
          setEmail(email);
          setCodeVerified(true);
        } catch (error) {
          setCodeVerified(false);
          setValidationLoading(false);
          logAndCaptureException(
            ColumnService.AUTH_AND_USER_MANAGEMENT,
            error,
            'Error verifying password reset code',
            {
              code
            }
          );
        }
      }
    }

    void verifyPasswordResetCode();
  }, []);

  const getUser = async () => {
    try {
      const data: EResponseTypes['users/get-required-password-security'] =
        await api.post('users/get-required-password-security', {
          email
        });

      if (data.success) {
        setDwollaEnabledUser(data.dwollaPasswordSecurityEnabled);
      }
      setValidationLoading(false);
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error getting user data',
        {
          email
        }
      );
    }
  };

  useEffect(() => {
    if (email) {
      void getUser();
    }
  }, [email]);

  const matchPassword = () => {
    if (password && confirmPassword) {
      if (dwollaEnabledUser && !dwollaPasswordValidation.test(password)) {
        return;
      }
      if (!passwordValidation.test(password)) {
        return;
      }
      if (
        password.length === confirmPassword.length &&
        password === confirmPassword
      ) {
        setErrorMatch(false);
        if (password.length > 8) {
          return true;
        }
      } else {
        setErrorMatch(true);
        return false;
      }
    }
  };

  useEffect(() => {
    if (password) {
      if (dwollaEnabledUser) {
        if (!dwollaPasswordValidation.test(password)) {
          setNewPasswordError(dwollaInvalidPasswordMessage);
          return;
        }
      } else {
        if (password.length < 9) {
          setNewPasswordError('Password must be at least 9 characters');
          return;
        }
        if (!passwordValidation.test(password)) {
          setNewPasswordError(
            'Password must contain a number or a special character'
          );
          return;
        }
      }
      setNewPasswordError('');
    }
  }, [password]);

  useEffect(() => {
    matchPassword();
  }, [password, confirmPassword]);

  function goToLogin() {
    dispatch(push('/login'));
  }

  const handleReset = async (oobCode: string | null) => {
    if (matchPassword() && oobCode) {
      try {
        setLoading(true);
        await Firebase.auth().confirmPasswordReset(oobCode, password);
        await api.post('users/update-temporary-password-status', {
          email
        });
        dispatch(
          ToastActions.toastSuccess({
            headerText: 'Success!',
            bodyText:
              'Your password is reset. Please log in with your updated credentials.'
          })
        );
        setTimeout(() => {
          goToLogin();
        }, 2000);
      } catch (error) {
        logAndCaptureException(
          ColumnService.AUTH_AND_USER_MANAGEMENT,
          error,
          'Error resetting password',
          {
            email
          }
        );
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="hidden md:block h-0 float-right">
        <img
          className="-mt-1/3 -mr-20"
          src="https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff"
        />
      </div>
      <div className="hidden md:block h-0 float-left">
        <img
          className="-ml-8 mt-6/12"
          src="https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff"
        />
      </div>

      {validationLoading && (
        <LoadingState
          context={{
            service: ColumnService.AUTH_AND_USER_MANAGEMENT,
            location: '/reset-password'
          }}
        />
      )}

      {!codeVerified && !validationLoading && (
        <Modal
          id="not-verified"
          onClose={goToLogin}
          title="Try resetting your password again"
          primaryAction={{
            buttonText: 'Return to login',
            type: 'button',
            onClick: goToLogin
          }}
        >
          <p>
            Your request to reset your password has expired or the link has
            already been used.
          </p>
        </Modal>
      )}

      {codeVerified && !validationLoading && (
        <Modal
          id="reset-password"
          onClose={goToLogin}
          title="Reset your password"
          showCloseButton={false}
          allowCloseOutsideModal={false}
          loading={loading}
          primaryAction={{
            buttonText: 'Set New Password',
            formId: 'reset-password-form',
            type: 'submit'
          }}
        >
          {error && (
            <Alert
              id={'reset-password-error'}
              status="error"
              title="Sorry, something went wrong"
              description="We were unable to reset your password. Please try again."
            />
          )}
          <Form id="reset-password-form" onSubmit={() => handleReset(oobCode)}>
            <div className="space-y-4">
              <TextField
                id="email"
                type="email"
                labelText="Email address"
                value={email}
                disabled
              />
              <PasswordField
                id="new-password"
                labelText="New password"
                required
                value={password}
                placeHolderText="New password"
                onValueChange={(value: string) => setPassword(value)}
                errorText={newPasswordError}
              />
              <PasswordField
                id="confirm-password"
                labelText="Confirm new password"
                required
                value={confirmPassword}
                placeHolderText="Confirm new password"
                onValueChange={(value: string) => setConfirmPassword(value)}
                errorText={errorMatch ? 'Passwords do not match' : ''}
              />
            </div>
          </Form>
        </Modal>
      )}
    </div>
  );
}
