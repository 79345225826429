import { useEffect } from 'react';
import { phoneFormattersByRegion } from './phoneFormat';
import { postalCodeFormattersByRegion } from './postalCodeFormat';

// TODO: Add support for other phone format regions for internationalization. Consider using a library for i18n standards.
export type SupportedRegions = 'us';
export const VALIDATION_CHECK_EVENT = 'validation-check';

/**
 * Dispatches a custom validation event to child fields of the given form
 * Child fields can listen to the validation event to determine when to show a validation status message
 * @param toForm
 */
export function dispatchValidationEvent({
  toForm
}: {
  toForm: HTMLFormElement;
}) {
  const event = new Event(VALIDATION_CHECK_EVENT);
  const formElements = Array.from(toForm.elements);
  formElements.forEach(element => {
    element.dispatchEvent(event);
  });
}

type UseValidationEventListenerParams = {
  onValidationEvent: () => void;
  inputRef: React.MutableRefObject<
    | HTMLButtonElement
    | HTMLInputElement
    | HTMLTextAreaElement
    | HTMLSelectElement
    | null
  >;
};

export function useValidationEventListener({
  onValidationEvent,
  inputRef
}: UseValidationEventListenerParams) {
  useEffect(() => {
    if (inputRef?.current) {
      const inputElement = inputRef.current;
      // Display the validation status for this input when it received a custom validation check event
      inputElement.addEventListener(VALIDATION_CHECK_EVENT, onValidationEvent);

      return () => {
        inputElement.removeEventListener(
          VALIDATION_CHECK_EVENT,
          onValidationEvent
        );
      };
    }
  }, [Boolean(inputRef.current), onValidationEvent]);
}

type ValidationConfig = {
  props?: {
    min?: string;
    step?: string;
    pattern?: string;
    validationMessages?: ValidationMessageConfig;
  };
  format?: (value: string) => string;
};

export function getInputTypeValidationConfig(
  type?: string,
  region: SupportedRegions = 'us'
): ValidationConfig {
  if (type === 'currency') {
    return {
      props: {
        min: '0',
        step: '0.01'
      }
    };
  }

  if (type === 'tel') {
    return phoneFormattersByRegion[region];
  }

  if (type === 'postal-code') {
    return postalCodeFormattersByRegion[region];
  }

  return {};
}

const validityStatuses = [
  'badInput',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
  'typeMismatch',
  'valueMissing'
] as const;

type ValidityStatusKey = (typeof validityStatuses)[number];
export type ValidationMessageConfig = Partial<
  Record<ValidityStatusKey, string>
>;

export function getCustomValidationMessage(
  validity: ValidityState,
  validationMessages: ValidationMessageConfig
) {
  const currentStatus = validityStatuses.find(status => validity[status]);
  return currentStatus ? validationMessages[currentStatus] : null;
}
