import { z } from 'zod';
import { EHandlebars } from '../headers_footers/shared';
import { ETemplateStyles } from '../types';
import { Obituary, ObituaryFilingTypeNames } from '../types/obituary';

import { Layout } from '../types/layout';
import { wrapError, wrapSuccess } from '../types/responses';
import { getInlineCSSStylesFromCrop } from './images';
import { OrderFilingType } from '../types/filingType';
import {
  ClassifiedFilingTypeNames,
  ForSaleClassified,
  isForSaleClassified,
  isMilestoneClassified,
  MilestonesClassified
} from '../types/classified';
import { DEFAULT_TEMPLATE_STYLES } from '../types/templates';
import { Ad } from '../types/ad';
import { NewspaperOrderModel } from '../model/objects/newspaperOrderModel';

const bodyContentMarkup = `
  {{#if title }}
    <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
  {{/if}}
  <section style="{{{textStyles}}}; font-weight:normal;">
    {{{content}}}
    {{#if logo }}
      <img src="{{{logo}}}" style="height: 0.5in; width: auto;" />
    {{/if}}
  </section>
`;

// TODO(goodpaul): Move this to the db
export const ONE_COL_DEATH_NOTICE: Layout = {
  tag: 'default',
  id: 'one-col-death-notice',
  handlebarTemplate: bodyContentMarkup,
  columns: 1,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716673316156/template_a_normal.svg',
  photoAlignment: 'none',
  aspectRatio: 'none',
  photoWidth: 'none'
};

const TWO_COL_DEATH_NOTICE: Layout = {
  tag: 'default',
  id: 'two-col-death-notice',
  handlebarTemplate: bodyContentMarkup,
  columns: 2,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716673363185/template_g_normal.svg',
  photoAlignment: 'none',
  aspectRatio: 'none',
  photoWidth: 'none'
};

export const DEFAULT_DEATH_NOTICE_LAYOUTS = [
  ONE_COL_DEATH_NOTICE,
  TWO_COL_DEATH_NOTICE
];

export const ONE_COL_ONE_PHOTO_LAYOUT: Layout = {
  tag: 'default',
  id: 'one-col-3-4-image-1-photo',
  handlebarTemplate: `
    {{#if headerImage }}
      <div>
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" />
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 1,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478716163/template_sv_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

export const TEMPLATE_S_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-4-3-image-1-photo',
  handlebarTemplate: `
    {{#if headerImage }}
      <div>
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" />
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716474500733/template_s_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%'
};

const TEMPLATE_SV_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-3-4-image-1-photo',
  handlebarTemplate: `
    {{#if headerImage }}
      <div>
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" />
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478716163/template_sv_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

export const TEMPLATE_T_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-4-3-image-2-photo',
  handlebarTemplate: `
    {{#if headerImage }}
      <div style="display: flex;">
        <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
        <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 3,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478847918/template_t_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%'
};

const TEMPLATE_TV_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-3-4-image-2-photo',
  handlebarTemplate: `
    {{#if headerImage }}
      <div style="display: flex;">
        <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
        <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 3,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478958991/template_tv_normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

const TEMPLATE_U_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-4-3-image-1-photo-variant-2-big-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 60%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716479060868/template_u_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '50%'
};

const TEMPLATE_UV_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-3-4-image-1-photo-variant-2-big-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 60%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716479145916/template_uv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '50%'
};

const TEMPLATE_V_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-4-3-image-1-photo-variant-2-small-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 40%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716479425509/template_v_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '30%'
};

const TEMPLATE_VV_LAYOUT: Layout = {
  tag: 'default',
  id: 'three-col-3-4-image-1-photo-variant-2-small-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 40%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 3,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716555885314/template_vv_normal__1_.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '30%'
};

// FOUR COLS
const TEMPLATE_VC4NP_LAYOUT: Layout = {
  tag: 'default',
  id: 'four-col-no-photo',
  handlebarTemplate: bodyContentMarkup,
  columns: 4,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559894275/template_vc5np_normal.svg',
  photoAlignment: 'none',
  aspectRatio: 'none',
  photoWidth: 'none'
};

const TEMPLATE_VC41PV_LAYOUT: Layout = {
  tag: 'default',
  id: 'four-col-3-4-image-1-photo-skinny-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 30%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 4,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559512112/template_vc41pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '30%'
};

const TEMPLATE_VC41PH_LAYOUT: Layout = {
  tag: 'default',
  id: 'four-col-4-3-image-1-photo-skinny-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 30%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 4,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559576942/template_vc41ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '30%'
};

const TEMPLATE_VC42PV_LAYOUT: Layout = {
  tag: 'default',
  id: 'four-col-3-4-image-2-photo',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      {{#if secondHeaderImage }}
        <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 4,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559710646/template_vc42pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '25%'
};

const TEMPLATE_VC42PH_LAYOUT: Layout = {
  tag: 'default',
  id: 'four-col-4-3-image-2-photo',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      {{#if secondHeaderImage }}
        <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 4,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560593311/template_vc42ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '25%'
};

// FIVE COLS
const TEMPLATE_VC51PV_LAYOUT: Layout = {
  tag: 'default',
  id: 'five-col-3-4-image-1-photo-skinny-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 5,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559983071/template_vc51pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '20%'
};

const TEMPLATE_VC51PH_LAYOUT: Layout = {
  tag: 'default',
  id: 'five-col-4-3-image-1-photo-skinny-rhs',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 5,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560055432/template_vc51ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '20%'
};

const TEMPLATE_VC52PV_LAYOUT: Layout = {
  tag: 'default',
  id: 'five-col-3-4-image-2-photo',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      {{#if secondHeaderImage }}
        <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 20%; aspect-ratio: 3 / 4; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 5,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560101529/template_vc52pv_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '20%'
};

const TEMPLATE_VC52PH_LAYOUT: Layout = {
  tag: 'default',
  id: 'five-col-4-3-image-2-photo',
  handlebarTemplate: `
    <div style="font-weight:normal; overflow: hidden;">
      {{#if headerImage }}
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      {{#if secondHeaderImage }}
        <img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; width: 25%; aspect-ratio: 4 / 3; float: right;" />
      {{/if}}
      ${bodyContentMarkup}
    </div>`,
  columns: 5,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716560152267/template_vc52ph_normal.svg',
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '25%'
};

const TEMPLATE_VC5NP_LAYOUT: Layout = {
  tag: 'default',
  id: 'five-col-no-photo',
  handlebarTemplate: bodyContentMarkup,
  columns: 5,
  photos: 0,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716559894275/template_vc5np_normal.svg',
  photoAlignment: 'center',
  aspectRatio: 'none',
  photoWidth: 'none'
};

const TEMPLATE_2COL1PH_LAYOUT: Layout = {
  tag: 'default',
  id: 'two-col-one-photo',
  handlebarTemplate: `
    {{#if headerImage }}
      <div>
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" />
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 2,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F367f.d4956-Template-S-Normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: 'none'
};

export const TEMPLATE_2COL1PHV2_LAYOUT: Layout = {
  tag: 'default',
  id: 'two-col-one-photo-3-4-image',
  handlebarTemplate: `
    {{#if headerImage }}
      <div>
        <img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4; width: 50%; float: right;" />
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 2,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F7bd6.404dc-Template-H-Normal.svg',
  photoAlignment: 'right',
  aspectRatio: '3 / 4',
  photoWidth: '50%'
};

const TEMPLATE_2COL2PHV1_LAYOUT: Layout = {
  tag: 'default',
  id: 'two-col-2-photo-3-4-image',
  handlebarTemplate: `
    {{#if headerImage }}
      <div style="display: flex;">
        <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
        <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 3 / 4;" /></div>
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 2,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fef82.19618-Template-J-Normal.svg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

const TEMPLATE_2COL2PHV2_LAYOUT: Layout = {
  tag: 'default',
  id: 'two-col-2-photo-4-3-image',
  handlebarTemplate: `
    {{#if headerImage }}
      <div style="display: flex;">
        <div style="flex:1;"><img src="{{{headerImage}}}" style="{{{headerImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
        <div style="flex:1;"><img src="{{{secondHeaderImage}}}" style="{{{secondImageStyles}}}; aspect-ratio: 4 / 3;" /></div>
      </div>
    {{/if}}
    ${bodyContentMarkup}`,
  columns: 2,
  photos: 2,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F4eb6.02e6b-Template-K-Normal.svg',
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%'
};

/**
 * Base display layouts
 * These are used for the display ad layouts below, with the only change being the image and id
 */
const DISPLAY_ONE_3_4_IMAGE_ONLY_LAYOUT: Layout = {
  tag: 'display',
  id: 'display-one-3-4-image-only',
  hideContent: true,
  titlePlaceholder: 'Ad title (for accessibility)',
  handlebarTemplate: `
    <img src="{{{headerImage}}}" style="aspect-ratio: 3 / 4; object-fit: cover; width: 100%;">
`,
  description: 'Image only, one column wide',
  columns: 1,
  photos: 1,
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%',
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1729780980453/one_col_3_4_template.svg'
};
const DISPLAY_TWO_COL_4_3_IMAGE_ONLY_LAYOUT: Layout = {
  tag: 'display',
  id: 'display-two-col-4-3-image-only',
  description: 'Image only, two columns wide',
  hideContent: true,
  titlePlaceholder: 'Ad title (used for SEO)',
  handlebarTemplate: `
    <img src="{{{headerImage}}}" style="aspect-ratio: 4 / 3; object-fit: cover; width: 100%;">`,
  columns: 2,
  photos: 1,
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%',
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1730126534716/onecol_43.svg'
};

/**
 * Modular sizing layouts that are:
 * 1) Image only
 * 2) Set to predefined heights in relation to the total template
 * 3) Have a single photo
 */
const getStandardImageOnlyLayoutTemplate = (
  size: keyof z.infer<
    typeof OrderContentHandlebarDataSchema
  >['standardOrderSizes']
) => `
  <img src="{{{headerImage}}}" style="object-fit: contain; width: 100%; height: {{standardOrderSizes.${size}}}px">;
`;
const BASE_STANDARD_IMAGE_ONLY_LAYOUT: Pick<
  Layout,
  | 'tag'
  | 'hideContent'
  | 'photos'
  | 'iconSvgUrl'
  | 'photoAlignment'
  | 'aspectRatio'
  | 'photoWidth'
> = {
  tag: 'display',
  hideContent: true,
  photos: 1,
  iconSvgUrl:
    'https://enotice-production.imgix.net/custom-documents/permalink/1716478716163/template_sv_normal.svg',
  photoAlignment: 'center',
  aspectRatio: 'none',
  photoWidth: 'none'
};

const EIGHT_COL_FULL_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'eight-col-full-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('pageHeightInPixels'),
  columns: 8,
  description: 'Eight columns full page'
};
const SIX_COL_FULL_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'six-col-full-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('pageHeightInPixels'),
  columns: 6,
  description: 'Six columns full page'
};
const FOUR_COL_FULL_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'four-col-full-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('pageHeightInPixels'),
  columns: 4,
  description: 'Four columns full page'
};
const THREE_COL_FULL_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'three-col-full-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('pageHeightInPixels'),
  columns: 3,
  description: 'Three columns full page'
};
const TWO_COL_FULL_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'two-col-full-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('pageHeightInPixels'),
  columns: 2,
  description: 'Two columns full page'
};

const EIGHT_COL_HALF_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'eight-col-half-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('halfHeightInPixels'),
  columns: 8,
  description: 'Eight columns half page'
};
const SIX_COL_HALF_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'six-col-half-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('halfHeightInPixels'),
  columns: 6,
  description: 'Six columns half page'
};
const FOUR_COL_HALF_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'four-col-half-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('halfHeightInPixels'),
  columns: 4,
  description: 'Four columns half page'
};
const THREE_COL_HALF_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'three-col-half-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('halfHeightInPixels'),
  columns: 3,
  description: 'Three columns half page'
};
const TWO_COL_HALF_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'two-col-half-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate('halfHeightInPixels'),
  columns: 2,
  description: 'Two columns half page'
};
const TWO_COL_QUARTER_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'two-col-quarter-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate(
    'quarterHeightInPixels'
  ),
  columns: 2,
  description: 'Two columns quarter page'
};
const THREE_COL_QUARTER_HEIGHT_LAYOUT: Layout = {
  ...BASE_STANDARD_IMAGE_ONLY_LAYOUT,
  id: 'three-col-quarter-height',
  handlebarTemplate: getStandardImageOnlyLayoutTemplate(
    'quarterHeightInPixels'
  ),
  columns: 3,
  description: 'Three columns quarter page'
};

const IMAGE_ONLY_DISPLAY_LAYOUTS = [
  DISPLAY_TWO_COL_4_3_IMAGE_ONLY_LAYOUT,
  DISPLAY_ONE_3_4_IMAGE_ONLY_LAYOUT,
  EIGHT_COL_FULL_HEIGHT_LAYOUT,
  SIX_COL_FULL_HEIGHT_LAYOUT,
  FOUR_COL_FULL_HEIGHT_LAYOUT,
  THREE_COL_FULL_HEIGHT_LAYOUT,
  TWO_COL_FULL_HEIGHT_LAYOUT,
  EIGHT_COL_HALF_HEIGHT_LAYOUT,
  SIX_COL_HALF_HEIGHT_LAYOUT,
  FOUR_COL_HALF_HEIGHT_LAYOUT,
  THREE_COL_HALF_HEIGHT_LAYOUT,
  TWO_COL_HALF_HEIGHT_LAYOUT,
  TWO_COL_QUARTER_HEIGHT_LAYOUT,
  THREE_COL_QUARTER_HEIGHT_LAYOUT
];

const DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Omit<
  Layout,
  'id' | 'iconSvgUrl'
> = {
  tag: 'display',
  description: 'One column, image with text bottom right',
  handlebarTemplate: `
  <div>
    <img
      src="{{{headerImage}}}"
      style="aspect-ratio: 3 / 4; object-fit: cover; width: 100%;">
    <div style="max-width: 80%; margin-left: 10%; border-radius: 2px; overflow: hidden; background-color: #fafafa; position: absolute; bottom: 0; right: 0; margin: 10px; padding: 5px;">
      <div style="border-radius: 5px; overflow: hidden;">
        <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
        <div style="{{{textStyles}}};font-weight:normal">
          {{{content}}}
        </div>
      </div>
    </div>
  </div>`,
  columns: 1,
  photos: 1,
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};
const TWO_COL_SIDEWAYS_DISPLAY_LAYOUT: Omit<Layout, 'id' | 'iconSvgUrl'> = {
  tag: 'display',
  description: 'Two columns, dark background',
  handlebarTemplate: `
    <div style="background-color: black; display: flex;">
      <div
        style="color: #fafafa; flex: 1; display: flex; flex-direction: column; justify-content: center; gap: 3; padding: 10px; background: linear-gradient(135deg, #1e3c72, #2c3e50, #2f3640)">
        <h1 style="{{{titleStyles}}};">
          {{{title}}}
        </h1>
        <div style="{{{textStyles}}};font-weight:normal; color: white;">
          {{{content}}}
        </div>
      </div>
      <div style="flex: 2;">
        <img
          src="{{{headerImage}}}"
          style="aspect-ratio: 4 / 3; object-fit: cover; width: 100%; height: 100%;"/>
      </div>
    </div>
  </div>`,
  columns: 2,
  photos: 1,
  photoAlignment: 'center',
  photoWidth: '100%',
  aspectRatio: '4 / 3'
};
const TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT: Omit<Layout, 'id' | 'iconSvgUrl'> = {
  tag: 'display',
  description: 'Two columns, image with text bottom right',
  handlebarTemplate: `
  <div>
    <img
      src="{{{headerImage}}}"
      style="aspect-ratio: 4 / 3; object-fit: cover; width: 100%;">
    <div style="max-width: 70%; border-radius: 2px; overflow: hidden; background-color: #fafafa; position: absolute; bottom: 0; right: 0; margin: 10px; padding: 5px;">
      <div style="border-radius: 5px; overflow: hidden;">
        <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
        <div style="{{{textStyles}}};font-weight:normal">
          {{{content}}}
        </div>
      </div>
    </div>
  </div>`,
  columns: 2,
  photos: 1,
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%'
};

/**
 * Vehicles
 */
const VEHICLE_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'vehicle-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729013042917/car1col.jpg'
};
const VEHICLE_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'vehicle-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729004522662/car.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const VEHICLE_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'vehicle-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729013013469/car_2col.jpg'
};
export const VEHICLE_SALE_DISPLAY_LAYOUTS = [
  VEHICLE_TWO_COL_SIDEWAYS_LAYOUT,
  VEHICLE_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  VEHICLE_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Furniture
 */
const COUCH_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'furniture-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034236970/couchone.jpg'
};
const COUCH_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'vehicle-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034255605/couchside.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const COUCH_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'couch-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034269567/2colright.jpg'
};
const FURNITURE_DISPLAY_LAYOUTS = [
  COUCH_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  COUCH_TWO_COL_SIDEWAYS_LAYOUT,
  COUCH_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Electronics
 */
const ELECTRONICS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'electronics-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034182181/onecollaptop.jpg'
};
const ELECTRONICS_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'electronics-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034207895/split2collaptop.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const ELECTRONICS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'electronics-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034220782/laptopbottomright.jpg'
};
const ELECTRONICS_DISPLAY_LAYOUTS = [
  ELECTRONICS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  ELECTRONICS_TWO_COL_SIDEWAYS_LAYOUT,
  ELECTRONICS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Home appliances
 */
const HOME_APPLIANCES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'electronics-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034141222/washingmachineonecol.jpg'
};
const HOME_APPLIANCES_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'electronics-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034157204/washingmachine2colsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const HOME_APPLIANCES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'electronics-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034169966/wash2colbottomright.jpg'
};
const HOME_APPLIANCES_DISPLAY_LAYOUTS = [
  HOME_APPLIANCES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  HOME_APPLIANCES_TWO_COL_SIDEWAYS_LAYOUT,
  HOME_APPLIANCES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Clothing and apparel
 */
const CLOTHING_AND_APPAREL_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'apparel-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034078095/apparelonecol.jpg'
};
const CLOTHING_AND_APPAREL_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'apparel-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034103160/twocolapparelsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const CLOTHING_AND_APPAREL_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'apparel-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034122260/twocolbottomright.jpg'
};
const CLOTHING_AND_APPAREL_DISPLAY_LAYOUTS = [
  CLOTHING_AND_APPAREL_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  CLOTHING_AND_APPAREL_TWO_COL_SIDEWAYS_LAYOUT,
  CLOTHING_AND_APPAREL_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Sporting goods
 */
const SPORTING_GOODS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'sporting-goods-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034030257/sportsonecol.jpg'
};
const SPORTING_GOODS_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'sporting-goods-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034047349/sports2colsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const SPORTING_GOODS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'sporting-goods-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034059843/sports2colbottomright.jpg'
};
const SPORTING_GOODS_DISPLAY_LAYOUTS = [
  SPORTING_GOODS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  SPORTING_GOODS_TWO_COL_SIDEWAYS_LAYOUT,
  SPORTING_GOODS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Tools
 */
const TOOLS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'tools-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033983109/toolsonecol.jpg'
};
const TOOLS_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'tools-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033997937/tools2colsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const TOOLS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'tools-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729034012399/tools2colbottomright.jpg'
};
const TOOLS_DISPLAY_LAYOUTS = [
  TOOLS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  TOOLS_TWO_COL_SIDEWAYS_LAYOUT,
  TOOLS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Toys and games
 */
const TOYS_AND_GAMES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'toys-and-games-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033939188/toysonecol.jpg'
};
const TOYS_AND_GAMES_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'toys-and-games-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033950464/toys2colsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const TOYS_AND_GAMES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'toys-and-games-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033962489/toys2colbottomright.jpg'
};
const TOYS_AND_GAMES_DISPLAY_LAYOUTS = [
  TOYS_AND_GAMES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  TOYS_AND_GAMES_TWO_COL_SIDEWAYS_LAYOUT,
  TOYS_AND_GAMES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Musical instruments
 */
const MUSICAL_INSTRUMENTS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'musical-instruments-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033906936/musiconecol.jpg'
};
const MUSICAL_INSTRUMENTS_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'musical-instruments-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033916700/pianotwocolsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const MUSICAL_INSTRUMENTS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'musical-instruments-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033927684/pianotwocolbottomright.jpg'
};
const MUSICAL_INSTRUMENTS_DISPLAY_LAYOUTS = [
  MUSICAL_INSTRUMENTS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  MUSICAL_INSTRUMENTS_TWO_COL_SIDEWAYS_LAYOUT,
  MUSICAL_INSTRUMENTS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Books
 */
const BOOKS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'books-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033858745/collegetextbooksonecol.jpg'
};
const BOOKS_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'books-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033870991/twocolsplitbooks.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const BOOKS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'books-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033884500/twocolbottomrightbooks.jpg'
};
const BOOKS_DISPLAY_LAYOUTS = [
  BOOKS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  BOOKS_TWO_COL_SIDEWAYS_LAYOUT,
  BOOKS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Jewelry and Accessories
 */
const JEWELRY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'jewe-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033799077/onecolwatches.jpg'
};
const JEWELRY_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'jewe-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033820005/twocolwatchsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const JEWELRY_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'jewe-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033842746/watchestwocolbottomright.jpg'
};
const JEWELRY_DISPLAY_LAYOUTS = [
  JEWELRY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  JEWELRY_TWO_COL_SIDEWAYS_LAYOUT,
  JEWELRY_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Art and collectibles
 */
const ART_AND_COLLECTIBLES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'art-and-collectibles-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033753599/onecolart.jpg'
};
const ART_AND_COLLECTIBLES_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'art-and-collectibles-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033772283/arttwocolsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const ART_AND_COLLECTIBLES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'art-and-collectibles-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033786385/arttwocolbottomright.jpg'
};
const ART_AND_COLLECTIBLES_DISPLAY_LAYOUTS = [
  ART_AND_COLLECTIBLES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  ART_AND_COLLECTIBLES_TWO_COL_SIDEWAYS_LAYOUT,
  ART_AND_COLLECTIBLES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Camera and photography equipment
 */
const CAMERA_AND_PHOTOGRAPHY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'camera-and-photography-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033715486/cameraonecol.jpg'
};
const CAMERA_AND_PHOTOGRAPHY_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'camera-and-photography-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033729619/cameratwocolsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const CAMERA_AND_PHOTOGRAPHY_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'camera-and-photography-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033741463/twocolcamerabottomright.jpg'
};
const CAMERA_AND_PHOTOGRAPHY_DISPLAY_LAYOUTS = [
  CAMERA_AND_PHOTOGRAPHY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  CAMERA_AND_PHOTOGRAPHY_TWO_COL_SIDEWAYS_LAYOUT,
  CAMERA_AND_PHOTOGRAPHY_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Home decor
 */
const HOME_DECOR_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'home-decor-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033672731/decoronecolumn.jpg'
};
const HOME_DECOR_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'home-decor-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033692237/twocoldecorsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const HOME_DECOR_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'home-decor-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033704048/decortwocolbottomright.jpg'
};
const HOME_DECOR_DISPLAY_LAYOUTS = [
  HOME_DECOR_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  HOME_DECOR_TWO_COL_SIDEWAYS_LAYOUT,
  HOME_DECOR_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Baby items
 */
const BABY_ITEMS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'baby-items-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033632782/babybundleonecol.jpg'
};
const BABY_ITEMS_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'baby-items-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033647312/babytwocolsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const BABY_ITEMS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'baby-items-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033658053/babytwocolbottomright.jpg'
};
const BABY_ITEMS_DISPLAY_LAYOUTS = [
  BABY_ITEMS_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  BABY_ITEMS_TWO_COL_SIDEWAYS_LAYOUT,
  BABY_ITEMS_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Health and beauty products
 */
const HEALTH_BEAUTY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'health-beauty-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729018327010/healthbeauty.jpg'
};
const HEALTH_BEAUTY_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'health-beauty-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729018327010/healthbeauty.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const HEALTH_BEAUTY_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'health-beauty-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729018327010/healthbeauty.jpg'
};
const HEALTH_BEAUTY_DISPLAY_LAYOUTS = [
  HEALTH_BEAUTY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  HEALTH_BEAUTY_TWO_COL_SIDEWAYS_LAYOUT,
  HEALTH_BEAUTY_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Outdoor equipment
 */
const OUTDOOR_EQUIPMENTONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'outdoor-equipment-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033593216/hikingonecol.jpg'
};
const OUTDOOR_EQUIPMENTTWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'outdoor-equipment-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033602705/hikingtwocolsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const OUTDOOR_EQUIPMENTTWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'outdoor-equipment-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033614442/hiking2colbottomright.jpg'
};
const OUTDOOR_EQUIPMENT_DISPLAY_LAYOUTS = [
  OUTDOOR_EQUIPMENTONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  OUTDOOR_EQUIPMENTTWO_COL_SIDEWAYS_LAYOUT,
  OUTDOOR_EQUIPMENTTWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

/**
 * Antiques
 */
const ANTIQUES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...DISPLAY_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  id: 'antiques-one-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033541989/antiqueonecol.jpg'
};
const ANTIQUES_TWO_COL_SIDEWAYS_LAYOUT: Layout = {
  id: 'antiques-two-col-sideways-dark-background',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033569949/antiquetwocolsplit.jpg',
  ...TWO_COL_SIDEWAYS_DISPLAY_LAYOUT
};
const ANTIQUES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'antiques-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729033580072/antiquetwocolbottomright.jpg'
};
const ANTIQUES_DISPLAY_LAYOUTS = [
  ANTIQUES_ONE_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  ANTIQUES_TWO_COL_SIDEWAYS_LAYOUT,
  ANTIQUES_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT
];

const FOR_SALE_DISPLAY_LAYOUTS = [
  ...VEHICLE_SALE_DISPLAY_LAYOUTS,
  ...FURNITURE_DISPLAY_LAYOUTS,
  ...ELECTRONICS_DISPLAY_LAYOUTS,
  ...HOME_APPLIANCES_DISPLAY_LAYOUTS,
  ...CLOTHING_AND_APPAREL_DISPLAY_LAYOUTS,
  ...SPORTING_GOODS_DISPLAY_LAYOUTS,
  ...TOOLS_DISPLAY_LAYOUTS,
  ...TOYS_AND_GAMES_DISPLAY_LAYOUTS,
  ...MUSICAL_INSTRUMENTS_DISPLAY_LAYOUTS,
  ...BOOKS_DISPLAY_LAYOUTS,
  ...JEWELRY_DISPLAY_LAYOUTS,
  ...ART_AND_COLLECTIBLES_DISPLAY_LAYOUTS,
  ...CAMERA_AND_PHOTOGRAPHY_DISPLAY_LAYOUTS,
  ...HOME_DECOR_DISPLAY_LAYOUTS,
  ...BABY_ITEMS_DISPLAY_LAYOUTS,
  ...HEALTH_BEAUTY_DISPLAY_LAYOUTS,
  ...OUTDOOR_EQUIPMENT_DISPLAY_LAYOUTS,
  ...ANTIQUES_DISPLAY_LAYOUTS
];

/**
 * Housing and property
 */
const HOUSING_TWO_COL_SPLIT: Layout = {
  tag: 'display',
  id: 'housing-two-col-split',
  handlebarTemplate: `
    <div style="background-color: #fafafa; display: flex;">
      <div
        style="color: #4a4a4a; flex: 1; display: flex; flex-direction: column; justify-content: center; gap: 3; padding: 10px; background: linear-gradient(135deg, #f0f0f0, #e0e0e0, #d0d0d0);">
        <h1 style="{{{titleStyles}}};">
          {{{title}}}
        </h1>
        <div style="{{{textStyles}}};font-weight:normal; color: #4a4a4a;">
          {{{content}}}
        </div>
      </div>
      <div style="flex: 2;">
        <img
          src="{{{headerImage}}}"
          style="aspect-ratio: 4 / 3; object-fit: cover; width: 100%; height: 100%;"/>
      </div>
    </div>
  </div>`,
  columns: 2,
  photos: 1,
  photoAlignment: 'center',
  aspectRatio: '4 / 3',
  photoWidth: '100%',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729089285836/housing2colsplit.jpg'
};
const HOUSING_DISPLAY_LAYOUTS = [HOUSING_TWO_COL_SPLIT];

/**
 * Services
 */
const DARK_BACKGROUND_BUSINESS_CARD_LAYOUT: Layout = {
  tag: 'display',
  id: 'dark-background-services-two-col',
  description: 'Two column dark background',
  handlebarTemplate: `
    <div style="background-color: #fafafa; display: flex;">
      <div
        style="color: #4a4a4a; flex: 2; display: flex; flex-direction: column; justify-content: center; gap: 3; padding: 10px; background: linear-gradient(90deg, #f0fcff, #d0f4ff);">
        <h1 style="{{{titleStyles}}};">
          {{{title}}}
        </h1>
        <div style="{{{textStyles}}};font-weight:normal; color: #4a4a4a;">
          {{{content}}}
        </div>
      </div>
      <div style="flex: 1;">
        <img
          src="{{{headerImage}}}"
          style="aspect-ratio: 4 / 3; object-fit: cover; width: 100%; height: 100%; object-position: top;"/>
      </div>
    </div>
  </div>`,
  columns: 2,
  photos: 1,
  photoAlignment: 'right',
  aspectRatio: '4 / 3',
  photoWidth: '50%',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729093794767/cleaning2col.jpg'
};
const SERVICES_LAYOUTS = [
  ...IMAGE_ONLY_DISPLAY_LAYOUTS,
  DARK_BACKGROUND_BUSINESS_CARD_LAYOUT
];

/**
 * Engagements
 */
export const ENGAGEMENT_PAPER_CARD_LAYOUT: Layout = {
  tag: 'display',
  id: 'engagement-two-col-paper-card',
  handlebarTemplate: `
    <div style="background-color: #FBFAF5; display: flex;">
      <div
        style="color: #4a4a4a; flex: 1; display: flex; flex-direction: column; justify-content: center; gap: 3; padding: 20px;">
        <h1 style="{{{titleStyles}}}; text-align: center;">
          {{{title}}}
        </h1>
        <div style="{{{textStyles}}};font-weight:normal; color: #4a4a4a;">
          {{{content}}}
        </div>
      </div>
    </div>
  </div>`,
  columns: 2,
  photos: 0,
  photoAlignment: 'none',
  aspectRatio: 'none',
  photoWidth: 'none',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729090695926/engagement.jpg'
};

const MILESTONE_LAYOUTS = [ENGAGEMENT_PAPER_CARD_LAYOUT];

/**
 * Pet
 */
const PET_ONE_COL_IMAGE_CONTENT_SPLIT_TOP_BOTTOM: Layout = {
  tag: 'display',
  id: 'pet-one-col-image-text-top-bottom',
  handlebarTemplate: `
  <div>
    <div style="width: 80%; margin-left: 10%; border-radius: 2px; overflow: hidden; background-color: #fafafa; position: absolute; top: 0; margin-top: 10px; padding: 5px;">
      <div style="border-radius: 5px; overflow: hidden;">
        <h1 style="{{{titleStyles}}}">{{{title}}}</h1>
      </div>
    </div>
    <img
      src="{{{headerImage}}}"
      style="aspect-ratio: 3 / 4; object-fit: cover; width: 100%;">
    <div style="width: 80%; margin-left: 10%; border-radius: 2px; overflow: hidden; background-color: #fafafa; position: absolute; bottom: 0; margin-bottom: 10px; padding: 5px;">
      <div style="border-radius: 5px; overflow: hidden;">
        <div style="{{{textStyles}}};font-weight:normal">
          {{{content}}}
        </div>
      </div>
    </div>
  </div>`,
  columns: 1,
  photos: 1,
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729091068416/onecoldogsplit.jpg',
  photoAlignment: 'center',
  aspectRatio: '3 / 4',
  photoWidth: '100%'
};

const PET_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT: Layout = {
  ...TWO_COL_BOTTOM_RIGHT_DISPLAY_LAYOUT,
  id: 'pet-two-col-image-text-bottom-right',
  iconSvgUrl:
    'https://res.cloudinary.com/dgqq2xsfd/image/upload/enotice-production/custom-documents/permalink/1729090983645/dogtwocolbottomright.jpg'
};

export const DEFAULT_CLASSIFIED_LAYOUTS = [
  TEMPLATE_2COL1PHV2_LAYOUT,
  TEMPLATE_2COL1PH_LAYOUT,
  TEMPLATE_2COL2PHV1_LAYOUT,
  TEMPLATE_2COL2PHV2_LAYOUT,
  TEMPLATE_S_LAYOUT,
  TEMPLATE_T_LAYOUT
];

export const PET_DISPLAY_LAYOUTS = [
  PET_TWO_COL_IMAGE_CONTENT_BOTTOM_RIGHT,
  PET_ONE_COL_IMAGE_CONTENT_SPLIT_TOP_BOTTOM
];
export const CLASSIFIED_DISPLAY_LAYOUTS: Partial<
  Record<ClassifiedFilingTypeNames, Layout[]>
> = {
  [ClassifiedFilingTypeNames.ForSale]: VEHICLE_SALE_DISPLAY_LAYOUTS,
  [ClassifiedFilingTypeNames.Pets]: PET_DISPLAY_LAYOUTS
};

export const DEFAULT_OBITUARY_LAYOUTS = [
  TEMPLATE_SV_LAYOUT,
  TEMPLATE_TV_LAYOUT,
  TEMPLATE_U_LAYOUT,
  TEMPLATE_UV_LAYOUT,
  TEMPLATE_V_LAYOUT,
  TEMPLATE_VV_LAYOUT,
  TEMPLATE_VC4NP_LAYOUT,
  TEMPLATE_VC41PV_LAYOUT,
  TEMPLATE_VC41PH_LAYOUT,
  TEMPLATE_VC42PV_LAYOUT,
  TEMPLATE_VC42PH_LAYOUT,
  TEMPLATE_VC51PV_LAYOUT,
  TEMPLATE_VC51PH_LAYOUT,
  TEMPLATE_VC52PV_LAYOUT,
  TEMPLATE_VC52PH_LAYOUT,
  TEMPLATE_VC5NP_LAYOUT
];

export const SELECTABLE_LAYOUTS: Array<Layout> = [
  ...DEFAULT_DEATH_NOTICE_LAYOUTS,
  ONE_COL_ONE_PHOTO_LAYOUT,
  ...DEFAULT_CLASSIFIED_LAYOUTS,
  ...DEFAULT_OBITUARY_LAYOUTS,
  ...FOR_SALE_DISPLAY_LAYOUTS,
  ...PET_DISPLAY_LAYOUTS,
  ...HOUSING_DISPLAY_LAYOUTS,
  ...MILESTONE_LAYOUTS,
  ...SERVICES_LAYOUTS,
  ...IMAGE_ONLY_DISPLAY_LAYOUTS
];

const adobeInDesignJustificationToCSS = (justification: string | undefined) => {
  switch (justification) {
    case 'LeftAlign':
      return 'text-align:left';
    case 'CenterAlign':
      return 'text-align:center';
    case 'RightAlign':
      return 'text-align:right';
    case 'RightJustified':
      return ['text-align:justify', 'text-align-last:right'].join(';');
    case 'LeftJustified':
      return ['text-align:justify', 'text-align-last:left'].join(';');
    case 'CenterJustified':
      return ['text-align:justify', 'text-align-last:center'].join(';');
    case 'FullyJustified':
      return 'text-align:justify';
    default:
      return 'text-align:justify';
  }
};

const inDesignPtToPx = (pt: number) => {
  return (pt * DEFAULT_DPI_VALUES.PUPPETEER) / DEFAULT_DPI_VALUES.INDESIGN;
};

/**
 * Gets title styles based on the titleParagraphStyle replacing any missing values with content from the overall template
 */
const getTitleStyles = (templateStyle: ETemplateStyles) => {
  const { titleParagraphStyle } = templateStyle;
  const leading = titleParagraphStyle?.leading ?? templateStyle.leading;
  return [
    `min-height:${titleParagraphStyle?.fontSize ?? templateStyle.fontSize}pt`,
    `margin-left:${titleParagraphStyle?.marginLeft ?? 0}pt`,
    `margin-right:${titleParagraphStyle?.marginRight ?? 0}pt`,
    `padding:0pt`,
    adobeInDesignJustificationToCSS(titleParagraphStyle?.justification),
    `font-family:${titleParagraphStyle?.font ?? templateStyle.font}`,
    `font-size:${titleParagraphStyle?.fontSize ?? templateStyle.fontSize}pt`,
    `line-height:${leading ? `${leading}pt` : '120%'}`,
    'overflow-wrap:break-word',
    titleParagraphStyle?.decoration
      ? `text-decoration:${titleParagraphStyle.decoration}`
      : '',
    titleParagraphStyle?.underline ? 'text-decoration:underline' : '',
    'word-spacing:0.1em',
    'letter-spacing:0',
    'font-smooth:never',
    'word-break:break-word',
    `font-weight:${titleParagraphStyle?.fontWeight ?? 'bold'}`
  ]
    .filter(Boolean)
    .join(';');
};

/**
 * Applies any non-inherited styles to each paragraph in the ad content. inherited styles are applied to the parent in getTextStyles
 * @param {string} adContent - The ad content to apply styles to
 * @param {DOMParser} DOMparser - The DOMParser to use to parse
 * @param {ETemplateStyles} - The styles to apply to the paragraphs. The supported styles are padding, firstLineIndent, and hyphenation
 * @returns The ad content with the styles applied
 */
const applyStylesToParagraphs = async (
  adContent: string | undefined,
  DOMparser: typeof DOMParser,
  { padding, firstLineIndent, hyphenation }: ETemplateStyles
) => {
  if (!adContent) {
    return adContent;
  }
  const doc = new DOMparser().parseFromString(adContent, 'text/html');
  const paragraphs = doc.querySelectorAll('p');
  paragraphs.forEach((p: NodeListOf<HTMLElement>[any], index) => {
    // eslint-disable-next-line no-param-reassign
    p.style.padding = `
      ${index === 0 ? 0 : inDesignPtToPx(padding?.top ?? 0)}px 
      ${inDesignPtToPx(padding?.right ?? 0)}px
      ${inDesignPtToPx(padding?.bottom ?? 0)}px
      ${inDesignPtToPx(padding?.left ?? 0)}px`;
    // eslint-disable-next-line no-param-reassign
    p.style.margin = '0px';
    // eslint-disable-next-line no-param-reassign
    p.style.textIndent = `${firstLineIndent ?? 0}px`;
    if (hyphenation) {
      // eslint-disable-next-line no-param-reassign
      p.style.hyphens = 'auto';
    }
  });

  return doc.body.innerHTML;
};

const getTextStyles = ({
  fontSize,
  font,
  leading,
  textInset
}: ETemplateStyles) => {
  let marginValue = '0';
  if (textInset) {
    const textInsetPx = inDesignPtToPx(textInset);
    marginValue = `${textInsetPx}`;
  }
  return [
    `min-height:${fontSize}pt`,
    `margin:${marginValue}px`,
    `padding:0pt`,
    'text-align:justify',
    `font-family:${font}`,
    `font-size:${fontSize}pt`,
    `line-height:${leading ? `${leading}pt` : '120%'}`,
    'overflow-wrap:break-word',
    'word-spacing:0.1em',
    'letter-spacing:0',
    'font-smooth:never',
    'word-break:break-word'
  ].join(';');
};

const OrderContentHandlebarDataSchema = z.object({
  headerImage: z.string().optional().nullable(),
  headerImageStyles: z.string().optional().nullable(),

  secondHeaderImage: z.string().optional().nullable(),
  secondImageStyles: z.string().optional().nullable(),

  titleStyles: z.string(),
  textStyles: z.string(),
  content: z.string().optional(),
  adWidth: z.number(),
  title: z.string().optional(),
  logo: z.string().optional().nullable(),
  standardOrderSizes: z.object({
    pageHeightInPixels: z.number(),
    halfHeightInPixels: z.number(),
    quarterHeightInPixels: z.number(),
    eighthHeightInPixels: z.number()
  }),
  backgroundColor: z.string()
});
type OrderContentHandlebarData = z.infer<
  typeof OrderContentHandlebarDataSchema
>;

/**
 * Dynamically determine the template that we should use for a particular obituary order.
 * We re-determine the handlebar value instead of using the value cached on the object because
 * it may have changed since the object was created.
 */
const getHandlebarStringForLayout = (layout: Layout) => {
  const relevantTemplate = findLayoutById(layout.id);
  if (!relevantTemplate) {
    return wrapError(new Error('No template found for layout'));
  }
  return wrapSuccess(relevantTemplate.handlebarTemplate);
};

export const DEFAULT_DPI_VALUES = {
  INDESIGN: 72,
  PUPPETEER: 96
};
/**
 * Determine the width of an ad based on the layout and template styles. Consider the following layout:
 * | column |gutter| column |gutter| column |gutter| column |gutter| column |gutter| column |
 * Our templates don't have the column width directly, so we need to calculate it. In order to figure out
 * the width of a single column, we need to subtract the total gutter width from the page width, then divide by the
 * number of columns to get the width of a single column.
 * Once we have the width of a single column, we can multiply it by the number of columns to get the total width of the ad
 * *without gutters*. The final step is to add the total gutter width to the column width to get the final size.
 * Before exporting, we take the final step of scaling by the default DPI of puppeteer (96) over the default DPI of
 * Indesign (72).
 */
export const getAdWidth = (layout: Layout, templateStyles: ETemplateStyles) => {
  const { columns } = layout;
  if (columns < 1) {
    return wrapError(new Error('Invalid number of columns'));
  }
  if (
    !templateStyles.pageWidth ||
    !templateStyles.columnGutter ||
    !templateStyles.columnCount
  ) {
    return wrapError(new Error('Invalid template styles'));
  }

  const totalGutterWidth =
    templateStyles.columnGutter * (templateStyles.columnCount - 1);

  const pageWidthWithoutGutter = templateStyles.pageWidth - totalGutterWidth;

  const columnWidth = pageWidthWithoutGutter / templateStyles.columnCount;

  const adWidthFromColumns = columnWidth * layout.columns;
  const adWidthFromGutter = templateStyles.columnGutter * (columns - 1);

  const adWidth = adWidthFromColumns + adWidthFromGutter;

  const scaledAdWidth =
    adWidth * (DEFAULT_DPI_VALUES.PUPPETEER / DEFAULT_DPI_VALUES.INDESIGN);
  return wrapSuccess(scaledAdWidth);
};

const getWidthRestrictedUrl = (
  originalUrl: string,
  cdnTransformations: { maxImageWidth: number; isGrayscale: boolean }
) =>
  originalUrl.replace(
    '/q_auto:best/',
    `/q_auto:best/c_limit,w_${cdnTransformations.maxImageWidth}/${
      cdnTransformations.isGrayscale ? 'e_grayscale/' : ''
    }`
  );

/**
 * Exposes standardized styles that can be used in layouts to determine the size of the ad.
 * This makes it easy for publishers to enable a "half page ad" or a "quarter page ad"
 */
export const getStandardOrderHeights = (templateStyles: ETemplateStyles) => {
  const { pageHeight } = templateStyles;
  if (!pageHeight) {
    return wrapError(new Error('Invalid template styles'));
  }

  const pageHeightInInches = pageHeight / DEFAULT_DPI_VALUES.INDESIGN;

  const pageHeightInPixels = pageHeightInInches * DEFAULT_DPI_VALUES.PUPPETEER;

  const halfHeightInInches = Math.floor(pageHeightInInches) / 2;

  const halfHeightInPixels = halfHeightInInches * DEFAULT_DPI_VALUES.PUPPETEER;

  const quarterHeightInInches = Math.floor(halfHeightInInches) / 2;

  const quarterHeightInPixels =
    quarterHeightInInches * DEFAULT_DPI_VALUES.PUPPETEER;
  const eighthHeightInInches = Math.floor(quarterHeightInInches) / 2;

  const eighthHeightInPixels =
    eighthHeightInInches * DEFAULT_DPI_VALUES.PUPPETEER;

  return wrapSuccess({
    pageHeightInPixels,
    halfHeightInPixels,
    quarterHeightInPixels,
    eighthHeightInPixels
  });
};

// TODO: Figure out what we want to do around placeholders in previews
const PLACEHOLDER_IMG = 'https://placehold.co/600x400?text=Preview';
export const layoutOrderContent = async (
  newspaperOrder: NewspaperOrderModel,
  adContent: Partial<Obituary>,
  templateStyles: ETemplateStyles,
  parser: typeof DOMParser
) => {
  const { layout, colorOptions } = newspaperOrder.modelData;
  const { response: handlebarString, error: handlebarError } =
    getHandlebarStringForLayout(layout);
  if (handlebarError) {
    return wrapError(handlebarError);
  }
  const handlebarTemplate =
    EHandlebars.compile<OrderContentHandlebarData>(handlebarString);
  const { response: adWidth, error: adWidthError } = getAdWidth(
    layout,
    templateStyles
  );
  if (adWidthError) {
    return wrapError(adWidthError);
  }

  const [standardOrderSizesError, standardOrderSizes] =
    getStandardOrderHeights(templateStyles);
  if (standardOrderSizesError) {
    return wrapError(standardOrderSizesError);
  }

  const firstOrderImage = adContent.orderImages?.[0];
  const firstOrderImageUrl = firstOrderImage?.imageUrl || adContent.headerImage;
  const secondOrderImage = adContent.orderImages?.[1];
  const secondOrderImageUrl =
    secondOrderImage?.imageUrl || adContent.secondHeaderImage;

  const cdnTransformations = {
    maxImageWidth: Math.ceil(
      { ...DEFAULT_TEMPLATE_STYLES, ...templateStyles }.pageWidth
    ),
    isGrayscale: Boolean(colorOptions?.isGrayscale)
  };
  const htmlContent = handlebarTemplate({
    title: adContent.title,
    content: await applyStylesToParagraphs(
      adContent.content,
      parser,
      templateStyles
    ),
    secondHeaderImage: secondOrderImageUrl
      ? getWidthRestrictedUrl(secondOrderImageUrl, cdnTransformations)
      : PLACEHOLDER_IMG,
    headerImage: firstOrderImageUrl
      ? getWidthRestrictedUrl(firstOrderImageUrl, cdnTransformations)
      : PLACEHOLDER_IMG,
    adWidth,
    titleStyles: getTitleStyles(templateStyles),
    textStyles: getTextStyles(templateStyles),
    headerImageStyles: getInlineCSSStylesFromCrop(
      firstOrderImage?.crop || adContent.headerImageCrop,
      templateStyles.photoStyleProperties
    ),
    secondImageStyles: getInlineCSSStylesFromCrop(
      secondOrderImage?.crop || adContent.secondImageCrop,
      templateStyles.photoStyleProperties
    ),
    logo: adContent.funeralHomeLogo ?? null,
    standardOrderSizes,
    backgroundColor:
      (!colorOptions?.isGrayscale && colorOptions?.backgroundColor) ||
      'transparent'
  });
  return wrapSuccess({ htmlContent, adWidth });
};

/**
 * Sets the min-height of the text section to the height of the image to ensure
 * that we don't have a layout where the text section is shorter than the image.
 * Genesis: https://columnpbc.atlassian.net/browse/ONCALL-4611
 */
export function setMinTextSectionHeight() {
  const img = document.querySelector('img');
  const textSection = document.querySelector('section');
  if (!img || !textSection) return;
  const imgBoundingBox = img.getBoundingClientRect();
  const textSectionBoundingBox = textSection.getBoundingClientRect();
  textSection.style.minHeight = `${
    imgBoundingBox.bottom -
    textSectionBoundingBox.top -
    parseFloat(textSection.style.marginBottom || '0')
  }px`;
}

/**
 * Determines what javascript we need to run after the page loads to apply any fixes that we can't
 * do with CSS.
 */
export const createPostLoadScript = (layout: Layout) => {
  let scriptBody = '';

  // make sure that the photo doesn't overhang the text section
  if (layout.photos && layout.photoAlignment !== 'center') {
    scriptBody += `
    ${setMinTextSectionHeight.toString()}
    setMinTextSectionHeight();
  `;
  }

  if (!scriptBody) {
    return '';
  }

  return `
<script>
window.onload = function() {
  ${scriptBody}
}
</script>
`.trim();
};

export const getLayoutsForForSaleAd = (adData: Partial<ForSaleClassified>) => {
  switch (adData.forSaleType) {
    case 'antiques': {
      return ANTIQUES_DISPLAY_LAYOUTS;
    }
    case 'art-collectibles': {
      return ART_AND_COLLECTIBLES_DISPLAY_LAYOUTS;
    }
    case 'baby-items': {
      return BABY_ITEMS_DISPLAY_LAYOUTS;
    }
    case 'books': {
      return BOOKS_DISPLAY_LAYOUTS;
    }
    case 'cameras-photography': {
      return CAMERA_AND_PHOTOGRAPHY_DISPLAY_LAYOUTS;
    }
    case 'clothing-apparel': {
      return CLOTHING_AND_APPAREL_DISPLAY_LAYOUTS;
    }
    case 'electronics': {
      return ELECTRONICS_DISPLAY_LAYOUTS;
    }
    case 'furniture': {
      return FURNITURE_DISPLAY_LAYOUTS;
    }
    case 'sporting-goods': {
      return SPORTING_GOODS_DISPLAY_LAYOUTS;
    }
    case 'health-beauty': {
      return HEALTH_BEAUTY_DISPLAY_LAYOUTS;
    }
    case 'home-appliances': {
      return HOME_APPLIANCES_DISPLAY_LAYOUTS;
    }
    case 'home-decor': {
      return HOME_DECOR_DISPLAY_LAYOUTS;
    }
    case 'jewelry-accessories': {
      return JEWELRY_DISPLAY_LAYOUTS;
    }
    case 'musical-instruments': {
      return MUSICAL_INSTRUMENTS_DISPLAY_LAYOUTS;
    }
    case 'outdoor-equipment': {
      return OUTDOOR_EQUIPMENT_DISPLAY_LAYOUTS;
    }
    case 'vehicles': {
      return VEHICLE_SALE_DISPLAY_LAYOUTS;
    }
    case 'tools': {
      return TOOLS_DISPLAY_LAYOUTS;
    }
    case 'toys-games': {
      return TOYS_AND_GAMES_DISPLAY_LAYOUTS;
    }
    default: {
      return [];
    }
  }
};

export const getLayoutsForMilestoneAd = (
  adData: Partial<MilestonesClassified>
) => {
  switch (adData.milestoneType) {
    case 'wedding': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'adoption': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'anniversary': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'baby-shower': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'baptism-christening': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'bar-bat-mitzvah': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'birth': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'engagement': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'first-communion': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'graduation': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'housewarming': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'military-service-deployment': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'moving-relocation': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'new-job': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'promotion': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'quinceanera': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    case 'retirement': {
      return [ENGAGEMENT_PAPER_CARD_LAYOUT];
    }
    default: {
      return [];
    }
  }
};

export function getDefaultLayoutsByFilingType(
  filingType: OrderFilingType,
  adData: Partial<Ad>
): Layout[] {
  if (
    filingType.label === ObituaryFilingTypeNames.DeathNotice ||
    filingType.label === ClassifiedFilingTypeNames.CommunityEngagementLetters
  ) {
    return DEFAULT_DEATH_NOTICE_LAYOUTS;
  }
  if (isForSaleClassified(adData)) {
    return [...DEFAULT_CLASSIFIED_LAYOUTS, ...getLayoutsForForSaleAd(adData)];
  }
  if (filingType.label === ClassifiedFilingTypeNames.Pets) {
    return [...DEFAULT_CLASSIFIED_LAYOUTS, ...PET_DISPLAY_LAYOUTS];
  }

  if (filingType.label === ObituaryFilingTypeNames.Obituary) {
    return [
      TWO_COL_DEATH_NOTICE,
      ...DEFAULT_CLASSIFIED_LAYOUTS,
      ...DEFAULT_OBITUARY_LAYOUTS
    ];
  }
  if (isMilestoneClassified(adData)) {
    return [...DEFAULT_CLASSIFIED_LAYOUTS, ...getLayoutsForMilestoneAd(adData)];
  }
  if (filingType.label === ClassifiedFilingTypeNames.HousingProperty) {
    return [...DEFAULT_CLASSIFIED_LAYOUTS, ...HOUSING_DISPLAY_LAYOUTS];
  }
  if (filingType.label === ClassifiedFilingTypeNames.Services) {
    return [...DEFAULT_CLASSIFIED_LAYOUTS, ...SERVICES_LAYOUTS];
  }
  return [...DEFAULT_DEATH_NOTICE_LAYOUTS, ...DEFAULT_CLASSIFIED_LAYOUTS];
}

export function findLayoutById(layoutId: string): Layout | undefined {
  return SELECTABLE_LAYOUTS.find(layout => layout.id === layoutId);
}
