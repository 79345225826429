import { ColumnSelect } from 'lib/components/ColumnSelect';
import React from 'react';
import {
  PAGINATION_ISSUE_DATE_FILTERS,
  PaginationIssueFilter
} from './paginationTableUtils';

type PaginationTableFilterDialogProps = {
  setUpdatedFilter: (filter: PaginationIssueFilter) => void;
  updatedFilter: PaginationIssueFilter;
};

export default function PaginationTableFilterDialog({
  setUpdatedFilter,
  updatedFilter
}: PaginationTableFilterDialogProps) {
  return (
    <>
      <p className="uppercase text-column-gray-300 text-xs font-bold">Filter</p>
      {/* Filter by subset of publication date(s) */}
      <ColumnSelect
        id="publication-date"
        labelText="Publication date"
        size="small"
        options={PAGINATION_ISSUE_DATE_FILTERS.map(dateFilter => ({
          value: dateFilter,
          label: dateFilter
        }))}
        value={updatedFilter.publicationDate}
        onChange={newValue =>
          setUpdatedFilter({
            ...updatedFilter,
            publicationDate:
              newValue as PaginationIssueFilter['publicationDate']
          })
        }
      />
      {/* Filter by subset of ad deadlines */}
      <ColumnSelect
        id="ad-deadline"
        labelText="Deadline"
        size="small"
        options={PAGINATION_ISSUE_DATE_FILTERS.map(dateFilter => ({
          value: dateFilter,
          label: dateFilter
        }))}
        value={updatedFilter.deadline}
        onChange={newValue =>
          setUpdatedFilter({
            ...updatedFilter,
            deadline: newValue as PaginationIssueFilter['deadline']
          })
        }
      />
    </>
  );
}
