import api from 'api';
import { push } from 'connected-react-router';
import { NoticeType } from 'lib/enums';
import { NoticeServiceAgent } from 'lib/services/NoticeService/NoticeService';
import {
  Customer,
  ENoticeDraft,
  EOrganization,
  ERef,
  ESnapshot,
  ESnapshotExists,
  EUser,
  exists
} from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureException } from 'utils';
import { getOrThrow } from 'lib/utils/refs';
import { refreshDraftFiles } from 'lib/files';
import { getOrCreateCustomer } from 'lib/notice/customer';
import { PlacementError } from 'lib/errors/PlacementError';
import { AppAsyncThunk, AppThunk } from 'redux/types';
import {
  authSelector,
  selectAnonymousUserId,
  selectIsContextOrganizationPublisher,
  selectOrgContextRef,
  selectUser
} from 'redux/auth';
import ToastActions from 'redux/toast';
import PlacementActions, {
  placementSelector,
  selectIsDisplayNoticeType,
  selectNoticeType
} from 'redux/placement';
import {
  getRestrictedPublishersFromLocationParams,
  getStateFromLocationParams
} from 'routes/placeScroll/helpers';
import { publisherReadyToUpload } from 'lib/publishers';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { NoticeService } from 'lib/services/NoticeService';
import { getModelFromSnapshot } from 'lib/model';
import { UserModel } from 'lib/model/objects/userModel';
import { CustomerModel } from 'lib/model/objects/customerModel';
import { ColumnService } from 'lib/services/directory';

export function createNewNotice(): AppAsyncThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const user = selectUser(state);
    const anonymousFilerId = selectAnonymousUserId(state);

    try {
      const defaultPublisherOrganization = await dispatch(
        getDefaultPublisherOrganizationForPlacement(user)
      );

      const noticeService = new NoticeService(getFirebaseContext());
      const { draft: newDraft } =
        await noticeService.createInitialNoticeWithDraft({
          asUser: user,
          withAnonymousFilerId: anonymousFilerId,
          inPublisherOrganization: defaultPublisherOrganization
        });

      dispatch(setInitialDraftState(newDraft));
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'Placement: Error creating new notice'
      );
      dispatch(PlacementActions.setPlacementError(new PlacementError()));
    }
  };
}

function setInitialDraftState(draft: ESnapshotExists<ENoticeDraft>): AppThunk {
  return dispatch => {
    /**
     * NOTE: The initial draft may have some undefined fields
     * that are required on the actual draft type. We might
     * want to consider making these actually optional on the
     * type so they're more accurate and predictable.
     */
    const initialDraftData = draft.data() as Partial<ENoticeDraft>;

    /**
     * NOTE: We should not be storing non-serializable data in the Redux store
     * Firestore snapshots and refs are not serializable, so most of these
     * fields need to be refactored
     */
    dispatch(PlacementActions.setOriginal(initialDraftData.original));
    dispatch(PlacementActions.setDraft(draft.ref));
    dispatch(PlacementActions.setDraftSnap(draft));
    dispatch(PlacementActions.setOwner(initialDraftData.owner));
    dispatch(
      PlacementActions.setAnonymousFilerId(initialDraftData.anonymousFilerId)
    );
    dispatch(PlacementActions.setCreatedBy(initialDraftData.createdBy));

    if (initialDraftData.newspaper) {
      dispatch(PlacementActions.setNewspaper(initialDraftData.newspaper));
    }

    if (initialDraftData.adTemplate) {
      dispatch(PlacementActions.setTemplate(initialDraftData.adTemplate));
    }

    if (initialDraftData.rate) {
      dispatch(PlacementActions.setRate(initialDraftData.rate));
    }

    if (initialDraftData.filer) {
      dispatch(PlacementActions.setFiler(initialDraftData.filer));
    }

    if (initialDraftData.filedBy) {
      dispatch(PlacementActions.setFiledBy(initialDraftData.filedBy));
    }

    /**
     * NoticeType.custom.value is the default initial value;
     * only update placement if the newspaper has specified
     * a different default notice type
     * */
    if (initialDraftData.noticeType !== NoticeType.custom.value) {
      dispatch(PlacementActions.setNoticeType(initialDraftData.noticeType));
    }

    if (initialDraftData.madlibData) {
      dispatch(PlacementActions.setMadlibData(initialDraftData.madlibData));
    }
  };
}

function getDefaultPublisherOrganizationForPlacement(
  userSnap: ESnapshotExists<EUser> | null
): AppAsyncThunk<ERef<EOrganization> | null> {
  return async (dispatch, getState) => {
    const user = userSnap
      ? getModelFromSnapshot(UserModel, getFirebaseContext(), userSnap)
      : null;

    if (!user?.isPublisher) {
      const state = getState();
      const isContextOrganizationPublisher =
        selectIsContextOrganizationPublisher(state);

      const orgContextRef = selectOrgContextRef(state);
      if (isContextOrganizationPublisher && orgContextRef) {
        return orgContextRef;
      }

      return await getFilerSavedPublisherOrganization(userSnap);
    }

    if (user.isPublisher && user.modelData.activeOrganization) {
      return user.modelData.activeOrganization;
    }

    return null;
  };
}

async function getFilerSavedPublisherOrganization(
  filerSnap: ESnapshotExists<EUser> | null
): Promise<ERef<EOrganization> | null> {
  if (!filerSnap) return null;
  const restrictedSingleState = getStateFromLocationParams();
  const restrictedPapers = getRestrictedPublishersFromLocationParams();

  const { savedInfo } = filerSnap.data();

  if (!savedInfo) return null;

  const savedPublisher = await savedInfo.newspaper?.get();
  const canPublishWithPublisher =
    exists(savedPublisher) && publisherReadyToUpload(savedPublisher);

  if (!canPublishWithPublisher) {
    // If a filer has a saved newspaper that is disabled, remove it from the user
    await filerSnap.ref.update({
      'savedInfo.newspaper': getFirebaseContext().fieldValue().delete()
    } as any);
    return null;
  }

  const publisherIsAllowed =
    !restrictedPapers || restrictedPapers.includes(savedPublisher.id);

  const { state: publisherState } = savedPublisher.data();
  const stateIsAllowed =
    !restrictedSingleState || restrictedSingleState === publisherState;

  if (!publisherIsAllowed || !stateIsAllowed) {
    return null;
  }

  return savedPublisher.ref;
}

export function submitNoticeToPublisher(): AppAsyncThunk {
  return async (dispatch, getState) => {
    try {
      dispatch(PlacementActions.setConfirming(true));
      const state = getState();
      const placement = placementSelector(state);

      const auth = authSelector(state);
      const { draft, original, filer } = placement;
      if (!draft || !original || !filer) return;

      /* If a customer does not yet exist, then we create one at the point the notice is published */
      const customerRef = placement.customer;
      const { newspaper } = placement;
      const newspaperSnap = await getOrThrow(newspaper);
      let customerSnap: ESnapshotExists<Customer> | null;
      if (!customerRef) {
        const filerSnap: ESnapshot<EUser> = await getOrThrow(filer);
        customerSnap = await getOrCreateCustomer(
          getFirebaseContext(),
          filerSnap,
          newspaperSnap,
          placement.newCustomerInfo
        );
      } else {
        customerSnap = await getOrThrow(customerRef);
      }
      let accountNumberOnCustomer = '';
      if (customerSnap && placement.accountNumber) {
        const customerModel = getModelFromSnapshot(
          CustomerModel,
          getFirebaseContext(),
          customerSnap
        );
        accountNumberOnCustomer =
          await customerModel.maybeUpdateAccountNumberOnCustomer(
            placement.accountNumber
          );
      }

      const noticeService = new NoticeService(getFirebaseContext());
      const agent: NoticeServiceAgent = {
        isPublisher: auth.isPublisher,
        user: auth.user?.ref,
        source: 'placement'
      };
      // if the account number on the customer was not updated (due to an already existing number)
      // then we should update the notice with a one time override
      const shouldSetOverrideOnNotice =
        accountNumberOnCustomer !== placement.accountNumber;
      const noticeCreation = await noticeService.publishNoticeFromDraft(
        original,
        draft,
        agent,
        shouldSetOverrideOnNotice ? placement.accountNumber : undefined
      );

      if (noticeCreation.error) {
        throw noticeCreation.error;
      } else {
        const notice = noticeCreation.response.modelData;

        if (!placement.editing && notice.invoice && !auth.isPublisher) {
          dispatch(
            ToastActions.toastSuccess({
              headerText: 'Success!',
              bodyText: 'Your notice has been submitted.'
            })
          );
        }
        // Add the post-placement action to the URL if it exists
        const noticeDetailsUrl = placement.postPlacementAction
          ? `/notice/${original.id}?action=${placement.postPlacementAction}`
          : `/notice/${original.id}`;
        // Redirect the user to the notice details path
        dispatch(push(noticeDetailsUrl));
        sessionStorage.removeItem('startedFromAnonymousFlow');
        // After leaving the placement flow, reset state
        dispatch(PlacementActions.resetState());
      }
    } catch (e) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Placement: Error submitting notice to publisher'
      );
    } finally {
      dispatch(PlacementActions.setConfirming(false));
    }
  };
}

export function updateDraftFiles(): AppAsyncThunk {
  return async (dispatch, getState) => {
    const placement = placementSelector(getState());
    const { draft, filesToAttach } = placement;
    if (!draft) return;

    try {
      await refreshDraftFiles(
        getFirebaseContext(),
        draft,
        filesToAttach || [],
        placement.postWithoutFormatting
      );
    } catch (e) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Placement: Error updating draft files',
        {
          draftId: draft.id
        }
      );
      dispatch(PlacementActions.setPlacementError(new PlacementError()));
    }
  };
}

export function syncNoticeTypeChange(
  noticeType: CustomNoticeFilingType
): AppThunk {
  return (dispatch, getState) => {
    const state = getState();
    const isDisplayNoticeType = selectIsDisplayNoticeType(state);
    const currentNoticeTypeValue = selectNoticeType(state);

    // TODO: Investigate if there's another way to keep these values in sync or a better way to track display type vs. selected notice type
    if (isDisplayNoticeType) {
      dispatch(PlacementActions.setPreviousNoticeType(noticeType.value));
    } else {
      dispatch(PlacementActions.setPreviousNoticeType(currentNoticeTypeValue));
      dispatch(PlacementActions.setNoticeType(noticeType.value));
    }

    const isMadlibNoticeType = !!noticeType.madlib;
    if (isMadlibNoticeType) {
      // Set madlibData with empty objects when it is a new notice otherwise madlib template not loaded.
      const { madlibData } = placementSelector(state);
      if (!madlibData) {
        dispatch(
          PlacementActions.setMadlibData({
            templateData: {},
            questionTemplateData: {}
          })
        );
      }
    }
  };
}

/**
 * Reset and generate a new proof for the current draft
 * NOTE: Possible areas for improvement:
 * - Only regenerate if relevant fields have changed so we don't need to process and wait if nothing has changed
 * - Set the proofStoragePath from the API request response rather than waiting for the saga to update it
 * - Make sure there's a proper error state in the UI if this request fails
 * - Await the thunk for a loading state in the UI
 */
export function generateProofForDraft(): AppAsyncThunk {
  return async (dispatch, getState) => {
    const { draft } = placementSelector(getState());
    try {
      if (!draft) return;
      dispatch(PlacementActions.setProofStoragePath(null));

      const draftUpdate: Partial<ENoticeDraft> = {
        proofStoragePath: null,
        proofURL: null,
        jpgStoragePath: null,
        jpgURL: null
      };

      await draft.update(draftUpdate);

      const { error: generateProofError } = await api.safePost(
        'documents/generate-proof',
        {
          draftId: draft.id
        }
      );

      if (generateProofError) {
        throw generateProofError;
      }
    } catch (e) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        e,
        'Placement: Error in generateProofForDraft',
        { draftId: draft?.id }
      );
      dispatch(PlacementActions.setPlacementError(new PlacementError()));
    }
  };
}
