import moment from 'moment-timezone';

import { LineItemType, Product } from '../enums';
import { isRateWithDailyPricingPeriod } from '../types/rates';
import { AdRate, EOrganization, FirebaseTimestamp } from '../types';
import { DisplayParams, ENotice } from '../types/notice';
import { PricingPeriod, PricingPeriodType } from '../types/pricingPeriod';
import { getDescriptionForLineItem, PricingParameters } from '.';
import calculateSinglePeriodPrice from './calculateSinglePeriodPrice';

const groupDatesByWeek = (
  publicationDates: ENotice['publicationDates'],
  startDay: number,
  iana_timezone: string
): Map<number, ENotice['publicationDates']> => {
  const datesByWeek = new Map<number, ENotice['publicationDates']>();

  publicationDates.forEach(date => {
    const tzAdjustedDate = moment.tz(date.toDate(), iana_timezone);
    const weekStart = tzAdjustedDate
      .clone()
      .startOf('day')
      .subtract((tzAdjustedDate.day() - startDay + 7) % 7, 'days');

    const weekStartKey = weekStart.valueOf();
    const existingDates = datesByWeek.get(weekStartKey) || [];
    datesByWeek.set(weekStartKey, [...existingDates, date]);
  });

  return datesByWeek;
};

export type PricingPeriodsData = {
  numberOfPricingPeriods: number;
  pricingPeriodSchedule: PricingPeriod;
  publicationDatesByPricingPeriod: Map<number, ENotice['publicationDates']>;
};

export const getPricingPeriodsFromPublicationDates = (
  publicationDates: ENotice['publicationDates'],
  adRate: AdRate,
  iana_timezone: string
): PricingPeriodsData => {
  const { pricingPeriod } = adRate;
  const pricingPeriodSchedule = pricingPeriod ?? {
    type: PricingPeriodType.DAILY
  };

  const publicationDatesByPricingPeriod = new Map<
    number,
    ENotice['publicationDates']
  >();
  let periodCounter = 0;

  if (pricingPeriodSchedule.type === PricingPeriodType.WEEKLY) {
    const datesByWeek = groupDatesByWeek(
      publicationDates,
      pricingPeriodSchedule.startDay || 0,
      iana_timezone
    );

    // Convert from Unixtime weekStart indexing to simple number indexed map
    Array.from(datesByWeek.entries())
      .sort(([a], [b]) => a - b)
      .forEach(([, dates]) => {
        publicationDatesByPricingPeriod.set(periodCounter++, dates);
      });
  } else {
    // For daily pricing, each date is its own period
    publicationDates.forEach(date => {
      publicationDatesByPricingPeriod.set(periodCounter++, [date]);
    });
  }

  const numberOfPricingPeriods = publicationDatesByPricingPeriod.size;

  return {
    numberOfPricingPeriods,
    pricingPeriodSchedule,
    publicationDatesByPricingPeriod
  };
};

export const getDescriptionForPeriodBasedLineItem = (
  dates: FirebaseTimestamp[],
  newspaper: EOrganization,
  rate: AdRate
): string | undefined => {
  if (isRateWithDailyPricingPeriod(rate)) {
    const [date] = dates;
    return getDescriptionForLineItem(date, newspaper, rate);
  }

  // We would have multiple dates mapping to a given lineItem description iff the pricingPeriod is not daily (e.g., the rate charges by the week for possibly more than one pub date in such week)
  const datesString = `${dates
    .map(date => moment(date.toDate()).format('MM/DD/YYYY'))
    .join(', ')}`;

  if (
    rate.product === Product.Obituary ||
    rate.product === Product.Classified
  ) {
    return `${newspaper.name} Ad on ${datesString}`;
  }
  return `${newspaper.name} Notice on ${datesString}`;
};

export const getPublicationLineItemsForPeriodBasedRate = (
  publicationDates: FirebaseTimestamp[],
  rate: AdRate,
  newspaper: EOrganization,
  adDataForPricing: PricingParameters,
  displayParameters: DisplayParams
) => {
  const pricingPeriods: PricingPeriodsData =
    getPricingPeriodsFromPublicationDates(
      publicationDates,
      rate,
      newspaper.iana_timezone
    );

  const { publicationDatesByPricingPeriod } = pricingPeriods;

  const publicationLineItems = Array.from(
    publicationDatesByPricingPeriod.entries()
  ).map(([, dates], i) => {
    const amount = calculateSinglePeriodPrice(
      adDataForPricing,
      displayParameters,
      newspaper,
      rate,
      i
    );
    const description = getDescriptionForPeriodBasedLineItem(
      dates,
      newspaper,
      rate
    );
    const firstOrOnlyDate = dates[0];
    return {
      date: firstOrOnlyDate.toDate(), // for weekly periods, this date is the first date of the relevant week
      amount,
      ...(description && { description }),
      type: LineItemType.publication.value
    };
  });

  return publicationLineItems;
};
