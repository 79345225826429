/* eslint-disable no-await-in-loop */
import moment from 'moment';
import {
  getNoticeType,
  maybeGetXMLSyncExportSettings,
  removeUndefinedFields
} from '../../helpers';
import { SnapshotModel, getModelFromRef, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import {
  ConfirmationStatus,
  NoticeStatusType,
  NoticeType,
  OrganizationStatus
} from '../../enums';
import {
  ENotice,
  ENoticeDraft,
  ERef,
  ESnapshotExists,
  EUser,
  XMLSyncExportSettings
} from '../../types';
import { getOrThrow } from '../../utils/refs';
import * as affidavitHelpers from '../../affidavits';
import { NoticePreconditionStatuses } from '../../affidavits/types';
import { confirmNotice } from '../../notice/confirmation';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import { NoticeServiceAgent } from '../../services/NoticeService';
import { SyncData } from '../../types/integrations/sync';
import { getRunModelsFromQuery } from '../../services/runService';
import { createDesignNotesUpdatedEvent } from '../../utils/events';
import { AffidavitTemplateModel } from './affidavitTemplateModel';
import { getAffidavitSettingsForNotice } from '../../pricing/affidavits';
import { getCustomerForNotice } from '../../notice/customer';
import { noticeIsSubmitted } from '../../notice/helpers';
import { AffidavitReconciliationSettings } from '../../types/organization';
import { getErrorReporter } from '../../utils/errors';
import { getNoticeAAC } from '../../types/affidavits/convertARS';
import { CustomerModel } from './customerModel';
import { OrganizationModel } from './organizationModel';
import { SerializedModel } from '../types';
import { ColumnService } from '../../services/directory';

export type SerializedUserNoticeModel = SerializedModel<
  typeof Collections.userNotices
>;

export class UserNoticeModel extends SnapshotModel<
  ENotice,
  typeof Collections.userNotices
> {
  type = Collections.userNotices;

  // TODO: replace with model once one exists?
  private publisher: OrganizationModel | null = null;

  private affidavitNotarizationPreconditionStatuses: NoticePreconditionStatuses | null =
    null;

  public async getPublisher() {
    if (!this.publisher) {
      this.publisher = await getModelFromRef(
        OrganizationModel,
        this.ctx,
        this.modelData.newspaper
      );
    }
    return this.publisher;
  }

  /**
   * Checks whether the notice is being created for the first time or not based on whether
   * it has some properties.
   *
   * @return {boolean} Returns true if the notice is new, otherwise false.
   */
  public isNew(): boolean {
    return !this.modelData.noticeStatus;
  }

  public isDisplay() {
    return this.modelData.noticeType === NoticeType.display_ad.value;
  }

  private async getPublisherManagedAffidavitTemplate(): Promise<
    AffidavitTemplateModel | undefined
  > {
    const fromNotice = await AffidavitTemplateModel.fromSourceRef(
      this.ctx,
      this,
      moment().format('MM/DD/YYYY'),
      this.modelData.newspaper
    );
    if (fromNotice) return fromNotice;

    const user = await getOrThrow(this.modelData.filer);
    const fromUser = await AffidavitTemplateModel.fromSourceRef(
      this.ctx,
      user,
      user.data().name,
      this.modelData.newspaper
    );
    if (fromUser) return fromUser;

    const rate = await getOrThrow(this.modelData.rate);
    const fromRate = await AffidavitTemplateModel.fromSourceRef(
      this.ctx,
      rate,
      rate.data().description,
      this.modelData.newspaper
    );
    if (fromRate) return fromRate;

    const publisher = await getOrThrow(this.modelData.newspaper);

    const noticeType = getNoticeType(this, publisher, {
      skipDisplayType: true
    });

    const fromNoticeType = await AffidavitTemplateModel.fromNoticeType(
      this.ctx,
      noticeType,
      publisher
    );
    if (fromNoticeType) return fromNoticeType;

    return await AffidavitTemplateModel.fromSourceRef(
      this.ctx,
      publisher,
      publisher.data().name,
      publisher.ref
    );
  }

  private async getColumnManagedAffidavitTemplate(): Promise<
    AffidavitTemplateModel | undefined
  > {
    const settings = await getAffidavitSettingsForNotice(this.ctx, this);

    if (!settings) {
      return;
    }

    if (!settings.affidavitsManagedByColumn) {
      throw new Error('Affidavit settings are not managed by column');
    }

    const fromNotice = await AffidavitTemplateModel.fromSourceRefNotarized(
      this.ctx,
      this,
      moment().format('MM/DD/YYYY'),
      this.modelData.newspaper
    );
    if (fromNotice) return fromNotice;

    const customerSnap = await getCustomerForNotice(this.ctx, this);
    if (customerSnap) {
      const customer = getModelFromSnapshot(
        CustomerModel,
        this.ctx,
        customerSnap
      );
      const customerName = `${customerSnap.data().firstName} ${
        customerSnap.data().lastName
      }`;
      const fromCustomer = await AffidavitTemplateModel.fromSourceRefNotarized(
        this.ctx,
        customer,
        customerName,
        this.modelData.newspaper
      );
      if (fromCustomer) return fromCustomer;
    }

    const publisherSnap = await getOrThrow(this.modelData.newspaper);
    const publisher = getModelFromSnapshot(
      OrganizationModel,
      this.ctx,
      publisherSnap
    );
    const noticeType = getNoticeType(this, publisher);
    const fromNoticeType = await AffidavitTemplateModel.fromNoticeTypeNotarized(
      this.ctx,
      noticeType,
      publisher
    );
    if (fromNoticeType) return fromNoticeType;

    return await AffidavitTemplateModel.fromSourceRefNotarized(
      this.ctx,
      publisher,
      publisher.data().name,
      publisher.ref
    );
  }

  public async getAffidavitTemplate(options: {
    isColumnManaged: boolean;
  }): Promise<AffidavitTemplateModel | undefined> {
    return options.isColumnManaged
      ? this.getColumnManagedAffidavitTemplate()
      : this.getPublisherManagedAffidavitTemplate();
  }

  async createDraft({ asUser }: { asUser: ERef<EUser> | null }) {
    const originalData = { ...this.modelData };
    delete originalData.drafts;

    const draftFields: Partial<ENoticeDraft> = {
      ...originalData,
      original: this.ref,
      owner: asUser
    };

    delete draftFields.editedAt;
    delete draftFields.lastEditedBy;
    delete draftFields.proofStoragePath;

    const newDraftRef = this.ctx.userDraftsRef().doc();
    await newDraftRef.set(draftFields);

    const drafts = this.modelData.drafts || [];
    drafts.push(newDraftRef);
    await this.update({ drafts });

    return newDraftRef;
  }

  /**
   * Updates a notice with draft data.
   *
   * @param {Object} options - The options for updating the notice.
   * @param {ERef<ENoticeDraft>} options.draftRef - The reference to the draft notice.
   * @param {NoticeServiceAgent} options.agent - The agent responsible for the update.
   *
   * @returns {Promise<ResponseOrError<ENotice>>} - A promise that resolves to the updated notice or an error.
   */
  async updateWithDraftData({
    draftRef,
    agent
  }: {
    draftRef: ERef<ENoticeDraft>;
    agent: NoticeServiceAgent;
  }): Promise<ResponseOrError<UserNoticeModel>> {
    const draftSnap = await getOrThrow(draftRef);
    const draft = draftSnap.data();
    if (draft.inactive) {
      return wrapError(new Error('Draft is inactive'));
    }
    await this.addDraftFilesToNotice(draftRef);
    await this.addDraftMailToNotice(draftRef);

    const submissionTime = this.ctx.timestamp();
    const newspaper = await getOrThrow(draftSnap.data().newspaper);
    const cleanedDraft = await this.cleanDraftNoticeProperties(draft);
    const noticeObject: ENoticeDraft = {
      ...cleanedDraft,
      ...(newspaper.data().organizationStatus ===
      OrganizationStatus.in_implementation.value
        ? { testNotice: true }
        : {}),
      noticeStatus: [
        NoticeStatusType.affidavit_approved.value,
        NoticeStatusType.affidavit_submitted.value
      ].includes(this.modelData.noticeStatus)
        ? this.modelData.noticeStatus
        : NoticeStatusType.pending.value,
      confirmedBy: agent.isPublisher
        ? agent.user
        : this.modelData.confirmedBy || null,
      confirmedAt: this.modelData.confirmedAt || submissionTime,
      confirmed: true,
      confirmedReceiptTime: agent.isPublisher ? submissionTime : null,
      ...(this.isNew() ? {} : { editedAt: submissionTime }),
      ...(this.isNew() ? {} : { lastEditedBy: agent.user })
    };
    this.updateWithObjectData(noticeObject);
    // if design notes were edited, create a corresponding event
    if (draft.designNotes) {
      await createDesignNotesUpdatedEvent(
        this.ctx,
        this.ref,
        draft.designNotes
      );
    }
    return wrapSuccess(this);
  }

  public updateWithObjectData(noticeObject: ENotice) {
    Object.assign(this.modelData, noticeObject);
  }

  public async publish() {
    try {
      await this.update(this.modelData);
      return wrapSuccess(null);
    } catch (e) {
      return wrapError(e as Error);
    }
  }

  /**
   * Updates the account number on the notice if the publisher has exportSettings
   *
   * @param {string} accountNumber - The new account number to update.
   * @return {Promise<boolean>} A Promise that resolves to a boolean value indicating whether the notice was updated or not.
   */
  public async maybeUpdateAccountNumberOnNotice(
    accountNumber: string
  ): Promise<boolean> {
    const newspaper = await this.getPublisher();
    const exportSettings: XMLSyncExportSettings | undefined =
      await maybeGetXMLSyncExportSettings(newspaper);
    if (exportSettings) {
      const existingSyncData = this.modelData.syncData || { format: null };
      const updatedSyncData: SyncData = {
        ...existingSyncData,
        ...(exportSettings.format ? { format: exportSettings.format } : {}),
        syncCustomerId: accountNumber
      } as SyncData;
      await this.update({ syncData: updatedSyncData });
      return true;
    }
    return false;
  }

  public async getAffidavitNotarizationPreconditionStatuses() {
    if (!this.affidavitNotarizationPreconditionStatuses) {
      this.affidavitNotarizationPreconditionStatuses =
        await affidavitHelpers.getNoticePreconditionStatuses({
          ctx: this.ctx,
          notice: this
        });
    }
    return this.affidavitNotarizationPreconditionStatuses;
  }

  public async areAffidavitsEnabled() {
    const newspaperSnap = await this.getPublisher();
    return !affidavitHelpers.isAffidavitDisabled(this.modelData, newspaperSnap);
  }

  public async getRuns(
    options: {
      includeDisabled: boolean;
      includeCancelled: boolean;
      sortOrder: 'asc' | 'desc';
    } = {
      includeDisabled: false,
      includeCancelled: true,
      sortOrder: 'asc'
    }
  ) {
    const runQuery = this.ctx
      .runsRef()
      .where('notice', '==', this.ref)
      .orderBy('publicationDate', options.sortOrder);
    return await getRunModelsFromQuery(this.ctx, runQuery, options);
  }

  public get isConfirmed() {
    return (
      Boolean(this.modelData.confirmedReceipt) ||
      this.modelData.confirmationStatus === ConfirmationStatus.Confirmed
    );
  }

  public get isCancelled() {
    return this.modelData.noticeStatus === NoticeStatusType.cancelled.value;
  }

  public get isPending() {
    return this.modelData.noticeStatus === NoticeStatusType.pending.value;
  }

  public get isSubmitted() {
    return noticeIsSubmitted(this);
  }

  public get confirmationStatus() {
    return (
      this.modelData.confirmationStatus ??
      (this.isConfirmed
        ? ConfirmationStatus.Confirmed
        : ConfirmationStatus.Pending)
    );
  }

  public async confirm(confirmedBy: ESnapshotExists<EUser>) {
    await confirmNotice(this.ctx, {
      notice: this,
      user: confirmedBy
    });
  }

  public async markReviewing() {
    await this.update({
      confirmationStatus: ConfirmationStatus.AwaitingConfirmation
    });
  }

  /**
   * Adds the draft files from a notice draft to a notice by copying them
   * and deleting them from the draft.
   *
   * @param {ERef<ENoticeDraft>} draftRef - The reference to the draft notice.
   *
   * @return {Promise<void>} - A promise that resolves when the draft files have been added to the notice.
   */
  public async addDraftFilesToNotice(
    draftRef: ERef<ENoticeDraft>
  ): Promise<void> {
    const draftFilesCollectionRef = this.ctx.userNoticeFilesRef(draftRef);
    const draftFilesCollectionSnap = await draftFilesCollectionRef.get();

    const noticeFilesCollectionRef = this.ctx.userNoticeFilesRef(this.ref);
    const noticeFilesCollectionSnap = await noticeFilesCollectionRef.get();

    if (noticeFilesCollectionSnap.docs) {
      for (const noticeFileSnap of noticeFilesCollectionSnap.docs) {
        await noticeFileSnap.ref.delete();
      }
    }

    if (draftFilesCollectionSnap.docs) {
      for (const draftFileSnap of draftFilesCollectionSnap.docs) {
        await noticeFilesCollectionRef.add(draftFileSnap.data());
        getErrorReporter().logInfo(
          'Added draft file to notice files. Deleting draft...',
          {
            draftId: draftFileSnap.id
          }
        );
        await draftFileSnap.ref.delete();
      }
    }
  }

  /**
   * Adds the draft mails from a notice draft to a notice by copying them
   * and deleting them from the draft.
   *
   * @param {ERef<ENoticeDraft>} draftRef - The reference to the notice draft.
   * @return {Promise<void>} - A promise that resolves when the operation is complete.
   */
  public async addDraftMailToNotice(
    draftRef: ERef<ENoticeDraft>
  ): Promise<void> {
    const draftMailCollectionRef = draftRef.collection(Collections.mail);
    const draftMailCollectionSnap = await draftMailCollectionRef.get();

    const noticeMailCollectionRef = this.ref.collection(Collections.mail);
    const noticeMailCollectionSnap = await noticeMailCollectionRef.get();

    if (noticeMailCollectionSnap.docs) {
      for (const doc of noticeMailCollectionSnap.docs) {
        const mRef = doc.ref;
        await mRef.delete();
      }
    }

    if (draftMailCollectionSnap.docs) {
      for (const doc of draftMailCollectionSnap.docs) {
        await noticeMailCollectionRef.add(doc.data());
        const mRef = doc.ref;
        await mRef.delete();
      }
    }
  }

  /**
   * Clean properties associated with a draft notice in preparation of publication
   *
   * @param {ENoticeDraft} noticeObject - The draft notice object to be cleaned.
   * @returns {Promise<ENoticeDraft>} - The cleaned draft notice object.
   */
  private async cleanDraftNoticeProperties(
    noticeObject: ENoticeDraft
  ): Promise<ENoticeDraft> {
    const UpdatedNoticeObject = noticeObject;
    delete (UpdatedNoticeObject as any).anonymousFilerId;
    delete (UpdatedNoticeObject as any).inactive;
    delete (UpdatedNoticeObject as any).original;
    delete (UpdatedNoticeObject as any).confirming;
    delete (UpdatedNoticeObject as any).unusedDisplay;
    delete (UpdatedNoticeObject as any).generatedAffidavitURL;
    delete (UpdatedNoticeObject as any).generatedAffidavitStoragePath;
    delete (UpdatedNoticeObject as any).invoice;
    if (UpdatedNoticeObject.displayParams) {
      delete UpdatedNoticeObject.displayParams.imgs;
      delete (UpdatedNoticeObject as any).displayParams.err;
    }
    return UpdatedNoticeObject;
  }

  /**
   * Clears affidavit url and path on notice which forces regeneration.
   *
   * @return {Promise<void>} - A promise that resolves when the affidavit is regenerated successfully.
   */
  public async clearAffidavitOnNotice(): Promise<void> {
    await this.update({
      generatedAffidavitURL: '',
      generatedAffidavitStoragePath: ''
    });
  }

  public async updateAutomatedAffidavitConfiguration(
    affidavitReconciliationSettings: Partial<AffidavitReconciliationSettings>
  ): Promise<void> {
    const newAffidavitReconciliationSettings = removeUndefinedFields({
      ...this.modelData.affidavitReconciliationSettings,
      ...affidavitReconciliationSettings
    });

    const publisher = await this.getPublisher();
    const { response: automatedAffidavitConfiguration, error } =
      await getNoticeAAC(
        this.ctx,
        publisher,
        newAffidavitReconciliationSettings
      );

    if (error) {
      getErrorReporter().logAndCaptureError(
        ColumnService.AFFIDAVITS,
        error,
        'Failed to get automated affidavit configuration from affidavit reconciliation settings for notice',
        { noticeId: this.id }
      );

      return await this.update({
        affidavitReconciliationSettings: newAffidavitReconciliationSettings
      });
    }

    await this.update(
      removeUndefinedFields({
        affidavitReconciliationSettings: newAffidavitReconciliationSettings,
        automatedAffidavitConfiguration
      })
    );
  }
}
