import { EnumInputItem } from '../types/enums';
import { Product } from './Product';

type SpacerMetadata = {
  fileId: string;
  url: string;
  prefix: string;
};

export type SpacerItemType = EnumInputItem<{
  thick: SpacerMetadata;
  skinny: SpacerMetadata;
  super_skinny: SpacerMetadata;
}>;

// TODO(Ari): add spacers for obits & classifieds
export const SpacerData = {
  [Product.Notice]: {
    thick: {
      fileId: '3a5bf1da7517a8253e058fb455fe0bc48bb03d5f',
      prefix: 'thick-spacer',
      url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F9a86.d90a2-thick_header.jpg'
    },
    skinny: {
      prefix: 'skinny-spacer',
      fileId: '0aa16641ff3e9ee1e67bc0a74ced3887a95ae143',
      url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2Fd600.7a390-skinny_header.jpg'
    },
    super_skinny: {
      prefix: 'three-col-spacer',
      fileId: 'a3aa801891821493038afa229b0341640e479624',
      url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F1169.f2e48-3-col-spacer.jpg'
    },
    key: Product.Notice,
    label: 'Notice Spacers',
    value: 1
  }
};

export type SpacerItems = typeof SpacerData;
