import classNames from 'classnames';
import React from 'react';

export const RATE_PROPERTIES_TAB = 'rate-properties-tab';
export const ADDITIONAL_FEES_TAB = 'additional-fees-tab';
export const RATE_USERS_TAB = 'rate-users-tab';
export const RATE_ORGANIZATIONS_TAB = 'rate-organizations-tab';
export type RATE_DRAWER_SETTINGS_TAB =
  | typeof RATE_PROPERTIES_TAB
  | typeof ADDITIONAL_FEES_TAB
  | typeof RATE_USERS_TAB
  | typeof RATE_ORGANIZATIONS_TAB;

type DrawerTabButtonProps = {
  isActive: boolean;
  disabled: boolean;
  onClick: () => void;
  label: string;
};

/**
 * UI component for the buttons in the drawer
 */
function DrawerTabButton({
  disabled,
  isActive,
  label,
  onClick
}: DrawerTabButtonProps) {
  return (
    <button
      className={classNames(
        'ml-6 inline-block text-base text-black text-left py-6 focus:outline-none font-medium border-b-2 border-transparent border-b-2',
        {
          'border-blue-600 text-blue-950': isActive,
          'hover:border-b-2 hover:border-blue-600 hover:text-blue-950':
            !disabled,
          'cursor-not-allowed text-grey-400': disabled
        }
      )}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

type RateDrawerTabsProps = {
  setRateDrawerTab: React.Dispatch<
    React.SetStateAction<RATE_DRAWER_SETTINGS_TAB>
  >;
  rateDrawerTab: RATE_DRAWER_SETTINGS_TAB;
};

/**
 * Control the currently visible tab on the affidavit affidavit upload event drawer
 */
export default function RateDrawerTabs({
  setRateDrawerTab,
  rateDrawerTab
}: RateDrawerTabsProps) {
  return (
    <>
      <DrawerTabButton
        disabled={false}
        isActive={rateDrawerTab === RATE_PROPERTIES_TAB}
        label="Basic Properties"
        onClick={() => setRateDrawerTab(RATE_PROPERTIES_TAB)}
      />
      <DrawerTabButton
        disabled={false}
        isActive={rateDrawerTab === ADDITIONAL_FEES_TAB}
        label="Additional Fees"
        onClick={() => setRateDrawerTab(ADDITIONAL_FEES_TAB)}
      />
      {/* TODO: re-enable once we have cleaned up the relationship between customers and rates */}
      {/* <DrawerTabButton
        isActive={rateDrawerTab === RATE_USERS_TAB}
        disabled={customersOnRate.length === 0}
        label={`Users (${customersOnRate.length})`}
        onClick={() => setRateDrawerTab(RATE_USERS_TAB)}
      /> */}
      {/* TODO: re-enable once we've enable setting rates on customer orgs */}
      {/* <DrawerTabButton
        isActive={rateDrawerTab === RATE_ORGANIZATIONS_TAB}
        disabled={customerOrgsOnRate.length === 0}
        label={`Organizations (${customerOrgsOnRate.length})`}
        onClick={() => setRateDrawerTab(RATE_ORGANIZATIONS_TAB)}
      /> */}
    </>
  );
}
