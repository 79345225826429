import { Product } from 'lib/enums';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { safeGetModelFromRef } from 'lib/model/getModel';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { ColumnService } from 'lib/services/directory';
import { NewspaperOrder } from 'lib/types/newspaperOrder';
import {
  getErrors,
  getResponses,
  ResponseOrError,
  wrapError,
  wrapSuccess
} from 'lib/types/responses';
import { NewspaperOrdersFormData } from 'routes/ads/place/PlacementFlowStepSelector';
import { logAndCaptureException } from 'utils';
import { getFirebaseContext } from 'utils/firebase';

type NewspaperAndFilingType = {
  newspaperId: string;
  newspaperName: string;
  filingTypeLabel: string;
  madlibConfig?: string;
};

const fetchNewspaperAndFilingType = async ({
  newspaperOrder,
  selectedFilingTypeLabel,
  product
}: {
  newspaperOrder: Partial<NewspaperOrder>;
  selectedFilingTypeLabel: string;
  product: Product;
}): Promise<ResponseOrError<NewspaperAndFilingType>> => {
  const { newspaper: newspaperRef } = newspaperOrder;
  if (!newspaperRef) {
    return wrapError(new Error('Newspaper order is missing newspaper'));
  }
  if (!selectedFilingTypeLabel) {
    return wrapError(new Error('Selected filing type label is missing'));
  }

  const { response: newspaper, error: newspaperError } =
    await safeGetModelFromRef(
      OrganizationModel,
      getFirebaseContext(),
      newspaperRef
    );
  if (newspaperError) {
    return wrapError(newspaperError);
  }

  const { response: matchedFilingType, error: matchedFilingTypeError } =
    await newspaper.fetchFilingTypeForProductMedium({
      selectedFilingType: selectedFilingTypeLabel,
      product,
      publishingMedium:
        newspaperOrder.publishingMedium || PublishingMedium.Print
    });
  if (matchedFilingTypeError) {
    return wrapError(matchedFilingTypeError);
  }
  if (!matchedFilingType) {
    return wrapError(new Error('Filing type not available for newspaper'));
  }

  return wrapSuccess({
    newspaperId: newspaper.id,
    newspaperName: newspaper.modelData.name,
    filingTypeLabel: matchedFilingType.modelData.label,
    filingType: matchedFilingType.ref,
    madlibConfig: matchedFilingType.modelData.madlib
  });
};

type MadlibInvalidSelectedPublisherDetails = {
  papersWithTemplatedFilingTypes: string[];
};

type UnexpectedErrorInvalidSelectedPublisherDetails = {
  errors: Error[];
};

type InvalidSelectedPublisherDetails =
  | MadlibInvalidSelectedPublisherDetails
  | UnexpectedErrorInvalidSelectedPublisherDetails;

export const isTemplateValidationIssue = (
  details: InvalidSelectedPublisherDetails
): details is MadlibInvalidSelectedPublisherDetails => {
  return !!(details as MadlibInvalidSelectedPublisherDetails)
    .papersWithTemplatedFilingTypes;
};

const fetchNewspaperAndFilingTypes = async ({
  newspaperOrders,
  selectedFilingTypeLabel,
  product
}: {
  newspaperOrders: Partial<NewspaperOrder>[];
  selectedFilingTypeLabel: string;
  product: Product;
}) => {
  const results = await Promise.all(
    newspaperOrders.map(newspaperOrder =>
      fetchNewspaperAndFilingType({
        newspaperOrder,
        selectedFilingTypeLabel,
        product
      })
    )
  );

  return { errors: getErrors(results), responses: getResponses(results) };
};

type SelectedPublisherValidationStatus =
  | {
      isValid: true;
    }
  | {
      isValid: false;
      details: InvalidSelectedPublisherDetails;
    };

export const validateNewspapersAndFilingTypes = async ({
  newspaperOrders,
  selectedFilingTypeLabel,
  product
}: {
  newspaperOrders: NewspaperOrdersFormData;
  selectedFilingTypeLabel: string;
  product: Product;
}): Promise<SelectedPublisherValidationStatus> => {
  const { errors, responses: newspapersAndFilingTypes } =
    await fetchNewspaperAndFilingTypes({
      newspaperOrders,
      selectedFilingTypeLabel,
      product
    });
  if (errors.length > 0) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      errors,
      'Error validating publication selection in order placement flow'
    );
    return {
      isValid: false,
      details: {
        errors
      }
    };
  }

  const uniqueMadlibConfigs = Array.from(
    new Set(
      newspapersAndFilingTypes.map(({ madlibConfig }) => madlibConfig ?? '')
    )
  );
  const papersWithTemplatedFilingTypes = newspapersAndFilingTypes
    .filter(({ madlibConfig }) => madlibConfig)
    .map(({ newspaperName }) => newspaperName);
  if (uniqueMadlibConfigs.length > 1) {
    return {
      isValid: false,
      details: {
        papersWithTemplatedFilingTypes
      }
    };
  }

  return {
    isValid: true
  };
};

export const getOnlyValidNewspapersAndFilingTypes = async ({
  newspaperOrders,
  selectedFilingTypeLabel,
  product
}: {
  newspaperOrders: NewspaperOrdersFormData;
  selectedFilingTypeLabel: string;
  product: Product;
}): Promise<NewspaperOrdersFormData> => {
  const { errors, responses: newspapersAndFilingTypes } =
    await fetchNewspaperAndFilingTypes({
      newspaperOrders,
      selectedFilingTypeLabel,
      product
    });

  if (errors.length > 0) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      errors,
      'Error validating publication selection in order placement flow'
    );
    return [];
  }

  const uniqueMadlibConfigs = Array.from(
    new Set(
      newspapersAndFilingTypes.map(({ madlibConfig }) => madlibConfig ?? '')
    )
  );

  /**
   * If there is only one madlib config, this means either no newspaper
   * has a template for this category or they all have the same one,
   * so we can retain all of them
   */
  if (uniqueMadlibConfigs.length === 1) {
    return newspaperOrders;
  }

  const papersWithoutTemplatesForCategory = newspapersAndFilingTypes
    .filter(({ madlibConfig }) => !madlibConfig)
    .map(({ newspaperId }) =>
      newspaperOrders.find(({ newspaper }) => newspaper?.id === newspaperId)
    )
    .filter(
      (
        possibleNewspaperOrder
      ): possibleNewspaperOrder is Partial<NewspaperOrder> =>
        !!possibleNewspaperOrder
    );
  return papersWithoutTemplatesForCategory;
};
