import { ProductPublishingSettingsService } from '../services/productPublishingSettingsService';
import { Product } from '../enums';
import { EFirebaseContext, EOrganization, ETemplate, ERef } from '../types';
import { PublishingMedium } from '../enums/PublishingMedium';
import { wrapError, wrapSuccess } from '../types/responses';

export const getPublisherOrgTemplatesQueries = (
  ctx: EFirebaseContext,
  org: ERef<EOrganization>
) => {
  const ownedTemplatesQuery = ctx
    .adTemplatesRef()
    .where('organization', '==', org);

  const allowedTemplatesQuery = ctx
    .adTemplatesRef()
    .where('publisherOrganizations', 'array-contains', org);

  return {
    ownedTemplatesQuery,
    allowedTemplatesQuery
  };
};

export const getPublisherOrgTemplates = async (
  ctx: EFirebaseContext,
  org: ERef<EOrganization>
) => {
  const { ownedTemplatesQuery, allowedTemplatesQuery } =
    getPublisherOrgTemplatesQueries(ctx, org);

  const ownedTemplates = await ownedTemplatesQuery.get();
  const allowedTemplates = await allowedTemplatesQuery.get();

  return [...ownedTemplates.docs, ...allowedTemplates.docs];
};

export const isTemplateShared = (template: ETemplate) => {
  return !!template.publisherOrganizations?.length;
};

// Fetch the template of the given product line from the organization publishing settings
export const getPublisherOrgOrderTemplate = async (
  ctx: EFirebaseContext,
  org: ERef<EOrganization>,
  productLine: Product,
  publishingMedium: PublishingMedium
) => {
  const productPublishingSettingService = new ProductPublishingSettingsService(
    ctx
  );

  const { response, error } =
    await productPublishingSettingService.fetchOrCreateProductPublishingSetting(
      org,
      productLine,
      publishingMedium
    );

  if (error) {
    return wrapError(error);
  }

  const { response: publishingSetting, error: fetchPublishingSettingError } =
    await response.fetchPublishingSetting();

  if (fetchPublishingSettingError) {
    return wrapError(fetchPublishingSettingError);
  }

  return wrapSuccess(publishingSetting.modelData.adTemplate);
};
