import ExitConfirmationModal from 'components/modals/ExitWithoutSavingModal';
import { push } from 'connected-react-router';
import { Alert } from 'lib/components/Alert';
import { Form } from 'lib/components/Form';
import React, { RefObject, useState } from 'react';
import AuthActions, { selectUser } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import NavigationButtons from './NavigationButtons';
import PlacementNavbar from './components/PlacementNavbar';
import { NewspapersContextProvider } from './contexts/NewspapersContextProvider';
import { useAdForm } from './contexts/AdFormStatusProvider';
import { PlacementFlowStep } from './placementFlowStep';
import { NewspaperOrdersFormData } from './PlacementFlowStepSelector';

type FormWrapperProps = {
  children: React.ReactNode;
  submitText: string;
  onSubmit: () => Promise<void>;
  steps: PlacementFlowStep[];
  loading: boolean;
  newspaperOrdersFormData: NewspaperOrdersFormData;
  userError: string;
  contentRef?: RefObject<HTMLDivElement>;
  product: Product;
};

function FormWrapper({
  onSubmit,
  submitText,
  children,
  steps,
  loading,
  newspaperOrdersFormData,
  userError,
  contentRef,
  product
}: FormWrapperProps) {
  const dispatch = useAppDispatch();
  const { currentStep, updateCurrentStep } = useAdForm();

  const [showExitConfirmationModal, setShowExitConfirmationModal] =
    useState(false);

  const user = useAppSelector(selectUser);

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      const previousStep = currentStep - 1;
      updateCurrentStep(previousStep);
    }
  };

  function onExit() {
    setShowExitConfirmationModal(true);
  }

  return (
    <>
      <Form id="placement-step-form" onSubmit={onSubmit}>
        <div className="fixed inset-0 z-modal flex flex-col">
          <PlacementNavbar onExit={onExit} steps={steps} product={product} />
          {userError && (
            <Alert
              id="ad-placement-flow-error"
              status="error"
              title={userError}
            />
          )}

          <div ref={contentRef} className="flex-grow overflow-auto ">
            <NewspapersContextProvider
              newspaperOrdersFormData={newspaperOrdersFormData}
              product={product}
            >
              {children}
            </NewspapersContextProvider>
          </div>
          <NavigationButtons
            currentStep={currentStep}
            currentStepName={steps[currentStep - 1]}
            submitText={submitText}
            goToPreviousStep={handlePreviousStep}
            onExit={onExit}
            loading={loading}
            error={!!userError}
          />
        </div>
      </Form>
      {showExitConfirmationModal && (
        <ExitConfirmationModal
          keepEditing={() => setShowExitConfirmationModal(false)}
          exitWithoutSaving={() => {
            if (user) {
              dispatch(
                push(`/${PRODUCT_TO_NAME[product].plural.toLowerCase()}`)
              );
            } else {
              dispatch(AuthActions.logout());
              window.location.reload();
            }
          }}
          body={
            'Leaving this page will delete all unsaved changes. Are you sure you want to leave?'
          }
          disableOverflow
        />
      )}
    </>
  );
}

export default FormWrapper;
