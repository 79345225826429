import { isRateWithWeeklyPricingPeriod } from '../../types/rates';
import { AdRate, EDisplayParams } from '../../types';
import { getLinesPerInch } from '../math';
import { floatToP2Float } from '../ui';
import { PricingFunction } from './types';

export const oklahoma = {
  /**
   * Body words are currently calculated as words that are left
   * justified. This is the default setting in our TinyMCE editor
   */
  getBodyWords: (displayParameters: EDisplayParams) =>
    displayParameters?.justifications?.LEFT_JUSTIFIED?.words || 0,

  /**
   * Tabular lines are calculated as all lines that are not left
   * justified. This includes paragraphs that are explicitly "left" aligned
   * beyond the default alignment settings.
   *
   * NOTE: currently this calculation includes the header lines, but
   * there are outstanding questions that need to be resolved by the
   * Oklahoma press association to confirm that this is intended
   */
  getTabularLines: (displayParameters: EDisplayParams) =>
    (displayParameters.justifications?.LEFT_ALIGN?.lines || 0) +
    (displayParameters.justifications?.RIGHT_ALIGN?.lines || 0) +
    (displayParameters.justifications?.CENTER_ALIGN?.lines || 0),

  /**
   * For pricing images we use a strange formula:
   * (height in inches) x (# Lines per inch) x (# Columns) x (Legal rate per tabular line)
   *
   * This function only reports first half (height * lpi) and leaves the calculation of
   * columns and tabular line rate to the caller.
   */
  getImageLines: (displayParameters: EDisplayParams, rate: AdRate) => {
    const { images } = displayParameters;
    if (!images || !images.length) {
      return 0;
    }

    const imageHeights = images.map(i => i.height);
    const totalImageHeight = imageHeights.reduce((a, b) => a + b, 0);

    const linesPerInch = getLinesPerInch(rate, 'OK rate');

    return totalImageHeight * linesPerInch;
  }
};

export const priceOklahoma: PricingFunction = options => {
  const { displayParameters, columns, rateRecord, runNumber } = options;

  if (isRateWithWeeklyPricingPeriod(rateRecord)) {
    throw new Error(
      `Oklahoma pricing is not supported for rates that have non-daily (weekly or greater) pricing periods`
    );
  }

  // Words are 15c for the first run, 14xcfor all runs after
  const wordRate = runNumber === 0 ? 15 : 14;

  // Tabular lines are 70c for the first run, 65c for all runs after
  const tabularRate = runNumber === 0 ? 70 : 65;

  const wordTotal = floatToP2Float(
    oklahoma.getBodyWords(displayParameters) * wordRate
  );
  const tabularTotal = floatToP2Float(
    oklahoma.getTabularLines(displayParameters) * tabularRate * columns
  );
  const imageTotal = floatToP2Float(
    oklahoma.getImageLines(displayParameters, rateRecord) *
      tabularRate *
      columns
  );

  return wordTotal + tabularTotal + imageTotal;
};
