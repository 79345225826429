import FileUpload from 'lib/components/FileUpload';
import Firebase from 'EnoticeFirebase';
import ClipArtLibrary from 'routes/settings/publisher/generalOrderSettings/ClipArtSettings/ClipArtLibrary';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { getClipArtStoragePathForOrganization } from './ClipArtLibrary/clipArtHelpers';

const storage = Firebase.storage();

function ClipArtSettings() {
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const [recentlyUploaded, setRecentlyUploaded] = useState(new Array<string>());

  if (!activeOrganization) {
    return (
      <LoadingState
        context={{
          location: window.location.pathname,
          tags: { card: 'ClipArtSettings' },
          service: ColumnService.SETTINGS_MANAGEMENT
        }}
      />
    );
  }

  return (
    <CardGridLayout header={{ title: 'Clip Art Settings' }}>
      <GridInput fullWidth>
        <div className="flex flex-col gap-2">
          <ClipArtLibrary
            organizationId={activeOrganization.id}
            onSelect={() => {}}
            uploads={recentlyUploaded}
          />
          <FileUpload
            id="clip-art-upload"
            uploadFolder={getClipArtStoragePathForOrganization(
              activeOrganization.id
            )}
            storage={storage}
            onFileUpload={filesAndRefs =>
              setRecentlyUploaded([
                ...recentlyUploaded,
                ...filesAndRefs.map(o => o.uploadRef.fullPath)
              ])
            }
          />
        </div>
      </GridInput>
    </CardGridLayout>
  );
}

export default ClipArtSettings;
