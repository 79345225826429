import { useState } from 'react';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { Product } from 'lib/enums';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { getModelFromSnapshot } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { Alert } from 'lib/components/Alert';
import { ProductSiteSettingModel } from 'lib/model/objects/productSiteSettingModel';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { ProductSiteSetting } from 'lib/types/productSiteSetting';
import LoadingState from 'components/LoadingState';
import { SiteSettingForm } from './SiteSettingForm';
import { createSiteSetting, updateSiteSetting } from './writeSiteSettings';

type SiteSettingsProps = {
  newspaper: OrganizationModel;
  productLine: Product;
  loadedSetting: ProductSiteSettingModel | null;
};

function SiteSettings({
  newspaper,
  productLine,
  loadedSetting
}: SiteSettingsProps) {
  const [siteSettingModel, setSiteSettingModel] = useState(loadedSetting);
  const [loading, setLoading] = useState(false);
  const [saveError, setSaveError] = useState('');

  const handleSiteSettingChange = async (
    siteSettingData: ProductSiteSetting
  ): Promise<boolean> => {
    setSaveError('');
    setLoading(true);
    if (siteSettingModel) {
      const { response: updatedModel, error: updateError } =
        await updateSiteSetting(
          newspaper.id,
          siteSettingModel,
          siteSettingData
        );
      if (updateError !== null) {
        setSaveError(updateError.message);
        setLoading(false);
        return false;
      }
      setSiteSettingModel(updatedModel);
      setLoading(false);
      return true;
    }

    const { response: newSiteSetting, error: createError } =
      await createSiteSetting(newspaper.id, siteSettingData);
    if (createError !== null) {
      setSaveError(createError.message);
      setLoading(false);
      return false;
    }
    setSiteSettingModel(newSiteSetting);
    setLoading(false);
    return true;
  };

  return (
    <>
      {saveError && (
        <Alert id="save-error" status="error" description={saveError} />
      )}
      <SiteSettingForm
        onSave={handleSiteSettingChange}
        url={siteSettingModel?.url}
        siteSetting={siteSettingModel?.modelData}
        productLine={productLine}
        loading={loading}
      />
    </>
  );
}

type ProductSiteSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  productLine: Product;
};

function ProductSiteSettings({
  activeOrganization,
  productLine
}: ProductSiteSettingsProps) {
  const ctx = getFirebaseContext();
  const organizationModel = getModelFromSnapshot(
    OrganizationModel,
    ctx,
    activeOrganization
  );
  const {
    value: productSiteSetting,
    isLoading: productSiteSettingLoading,
    isError: productSiteSettingLoadFailed
  } = useSafeAsyncEffect({
    fetchData: async () =>
      await organizationModel.maybeFetchProductSiteSetting(productLine),
    initialData: undefined,
    dependencies: [productLine]
  });
  if (productLine === Product.Notice) {
    logAndCaptureCriticalError(
      ColumnService.DISPLAY_SITES,
      new Error('Invalid product line'),
      'Attempted to load a notice product from ProductLineFilingTypeSettings',
      {
        organizationId: activeOrganization.id,
        productLine
      }
    );
    return (
      <Alert
        id="invalid-product"
        description="Notices are not supported at this time."
      />
    );
  }
  if (productSiteSettingLoading) {
    return <LoadingState />;
  }

  if (productSiteSettingLoadFailed) {
    return (
      <Alert
        id="product-site-setting-load-failed"
        description="Failed to load product site settings."
      />
    );
  }

  return (
    <SiteSettings
      newspaper={organizationModel}
      productLine={productLine}
      loadedSetting={productSiteSetting}
    />
  );
}

export default ProductSiteSettings;
