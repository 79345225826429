/* eslint-disable no-await-in-loop */
import {
  ERef,
  ENoticeDraft,
  MailDelivery,
  EQuerySnapshot,
  ECollectionRef,
  ENotice
} from '../types';
import { Collections } from '../constants';

/* TODO: I'm currently setting the max number of mail documents per recipient at 5.
This should already be handled elsewhere when mail refs are being created, so the below is an extra check for additional safety.
There is currently no easy way to get more granular about limiting types of mails (i.e., by mail type instead of recipient),
but this could be introduced in a later change */
const MAX_MAILS_PER_NAME_PER_DRAFT = 5;

/**
 * Delete all mail documents on a draft and replace them with new ones.
 */
export const refreshDraftMail = async (
  draft: ERef<ENoticeDraft>,
  newMails: MailDelivery[]
): Promise<void> => {
  const mailRef: ECollectionRef<MailDelivery> = draft.collection(
    Collections.mail
  );
  const mailSnap: EQuerySnapshot<MailDelivery> = await mailRef.get();

  const oldMails = mailSnap.docs;
  if (oldMails && oldMails.length) {
    for (const oldMailSnap of oldMails) {
      await oldMailSnap.ref.delete();
    }
  }

  const mailsPerName: Record<string, number> = {};
  for (let i = 0; i < newMails.length; i++) {
    const countForName = mailsPerName[newMails[i].name] || 0;
    if (countForName >= MAX_MAILS_PER_NAME_PER_DRAFT) {
      continue;
    }

    await mailRef.add(newMails[i]);
    mailsPerName[newMails[i].name] = countForName + 1;
  }
};

export const addDraftMailToNotice = async (
  draftRef: ERef<ENoticeDraft>,
  noticeRef: ERef<ENotice>
): Promise<void> => {
  const draftMailCollectionRef = draftRef.collection(Collections.mail);
  const draftMailCollectionSnap = await draftMailCollectionRef.get();

  const noticeMailCollectionRef = noticeRef.collection(Collections.mail);
  const noticeMailCollectionSnap = await noticeMailCollectionRef.get();

  if (noticeMailCollectionSnap.docs) {
    for (const doc of noticeMailCollectionSnap.docs) {
      const mRef = doc.ref;
      await mRef.delete();
    }
  }

  if (draftMailCollectionSnap.docs) {
    for (const doc of draftMailCollectionSnap.docs) {
      await noticeMailCollectionRef.add(doc.data());
      const mRef = doc.ref;
      await mRef.delete();
    }
  }
};

export const getNoticeMail = async (
  noticeOrDraftRef: ERef<ENotice> | ERef<ENoticeDraft>
) => {
  const mailCollectionRef: ECollectionRef<MailDelivery> =
    noticeOrDraftRef.collection(Collections.mail);
  const mailCollectionSnap = await mailCollectionRef.get();

  return mailCollectionSnap.docs;
};

export const getNoticeMailAndSetOnDraft = async (
  noticeRef: ERef<ENotice>,
  draftRef: ERef<ENoticeDraft>
): Promise<MailDelivery[]> => {
  const draftMailCollectionRef: ECollectionRef<MailDelivery> =
    draftRef.collection(Collections.mail);
  const noticeMails = await getNoticeMail(noticeRef);

  const mailArray: MailDelivery[] = [];
  for (const mailSnap of noticeMails) {
    await draftMailCollectionRef.add(mailSnap.data());
    mailArray.push(mailSnap.data());
  }

  return mailArray;
};

export const getMailDataFromNoticeOrDraft = async (
  noticeOrDraftRef: ERef<ENotice> | ERef<ENoticeDraft>
): Promise<MailDelivery[]> => {
  const mails = await getNoticeMail(noticeOrDraftRef);
  return mails.map(m => m.data());
};
