import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { push } from 'connected-react-router';

import ToastActions from 'redux/toast';
import { createDBPricingObjectFromDataAndPublicationLineItems } from 'lib/pricing';
import { CreateInvoiceRequest } from 'lib/types/requests';
import { getFirebaseContext } from 'utils/firebase';
import { BillingService } from 'services';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import FullScreenModal from 'components/FullScreenModal';
import { getOrThrow } from 'lib/utils/refs';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { ESnapshotExists, ENotice, exists, ERate, EUser } from 'lib/types';
import { getAffidavitPricingData } from 'lib/pricing/affidavits';
import { getActiveDiscountConfigForNotice } from 'lib/notice/discounts';
import { Product } from 'lib/enums';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { safeAsync, safeGetOrThrow } from 'lib/safeWrappers';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import {
  INVOICE_CREATION_IN_PROGRESS_ERROR,
  NOTICE_HAS_INVOICE_ERROR
} from 'services/billing';
import { safeGetModelFromRef } from 'lib/model/getModel';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { RateModel } from 'lib/model/objects/rateModel';
import InvoicePreviewCard from './InvoicePreviewCard';
import InvoiceDetailsCard from './InvoiceDetailsCard';
import {
  getInitialInvoiceCreationDataFromNotice,
  getInvoiceDataInvalidReason
} from './createInvoiceUtils';

const ctx = getFirebaseContext();

const createInvoice = async (
  currentPublisherUser: ESnapshotExists<EUser>,
  notice: ESnapshotExists<ENotice>,
  invoiceCreationData: CreateInvoiceRequest
): Promise<ResponseOrError<undefined>> => {
  const { lineItems } = invoiceCreationData;
  const [publisherError, publisher] = await safeGetModelFromRef(
    OrganizationModel,
    ctx,
    notice.data().newspaper
  );

  if (publisherError) {
    return wrapError(publisherError);
  }

  const [rateError, rate] = await safeGetModelFromRef(
    RateModel,
    ctx,
    notice.data().rate
  );
  if (rateError) {
    return wrapError(rateError);
  }

  const [affidavitPricingDataError, affidavitPricingData] = await safeAsync(
    async () => await getAffidavitPricingData(ctx, notice)
  )();
  if (affidavitPricingDataError) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      affidavitPricingDataError,
      'Unable to get affidavit pricing data',
      {
        noticeId: notice.id
      }
    );
    return wrapError(affidavitPricingDataError);
  }

  const [discountConfigError, discountConfig] = await safeAsync(
    async () => await getActiveDiscountConfigForNotice(ctx, notice.data())
  )();
  if (discountConfigError) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      discountConfigError,
      'Unable to get active discount config for notice',
      {
        noticeId: notice.id
      }
    );
    return wrapError(discountConfigError);
  }

  let dbPricing;
  try {
    // TODO: This should probably use calculateInvoicePricing since
    //       we are in the invoicing flow.
    dbPricing = createDBPricingObjectFromDataAndPublicationLineItems(
      Product.Notice,
      notice.data(),
      publisher.data(),
      rate.data(),
      lineItems,
      affidavitPricingData,
      discountConfig
    );
  } catch (err) {
    logAndCaptureException(
      ColumnService.PAYMENTS,
      err,
      'Unable to get DB pricing object for notice',
      {
        noticeId: notice.id
      }
    );
    return wrapError(err as Error);
  }

  const [advertiserSnapError, advertiserSnap] = await safeGetOrThrow(
    notice.data().filer
  );
  if (advertiserSnapError) {
    return wrapError(advertiserSnapError);
  }

  const [error] = await BillingService.invoiceAdvertiser({
    publisherAmountInCents: dbPricing.subtotal,
    lineItems,
    noticeSnap: notice,
    newspaperSnap: publisher,
    advertiserSnap,
    user: currentPublisherUser,
    inAppTaxPct: dbPricing.taxPct
  });

  if (error) {
    return wrapError(error);
  }

  return wrapSuccess(undefined);
};

export default function CreateInvoice() {
  const [invoiceCreationData, setInvoiceCreationData] =
    useState<CreateInvoiceRequest>();
  const [notice, setNotice] = useState<ESnapshotExists<ENotice>>();
  const [rate, setRate] = useState<ESnapshotExists<ERate>>();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    const setInitialData = async () => {
      const path = matchPath<{ noticeID: string }>(window.location.pathname, {
        path: `/notice/:noticeID/invoice/create`
      });
      if (!path) return;
      const notice = await getFirebaseContext()
        .userNoticesRef()
        .doc(path.params.noticeID)
        .get();
      if (!exists(notice)) return;
      setNotice(notice);
      const rate = await getOrThrow(notice.data().rate);
      setRate(rate);
    };
    void setInitialData();
  }, []);

  useEffect(() => {
    void (async () => {
      if (!notice) return;
      const invoiceData = await getInitialInvoiceCreationDataFromNotice(notice);
      setInvoiceCreationData(invoiceData);
    })();
  }, [notice?.id]);

  const loadingComplete = !!invoiceCreationData && !!notice && !!rate && !!user;

  const invalidReason = getInvoiceDataInvalidReason(
    notice,
    rate,
    invoiceCreationData
  );

  return (
    <FullScreenModal
      headerText="Create Invoice"
      submittable={{
        buttonText: 'Create',
        onSubmit: async () => {
          if (!loadingComplete) return;
          const [error] = await createInvoice(
            user,
            notice,
            invoiceCreationData
          );

          if (error) {
            logAndCaptureException(
              ColumnService.PAYMENTS,
              error,
              '[New Create Invoice]: Failed to create a new invoice',
              { noticeId: notice.id }
            );
            dispatch(
              ToastActions.toastError({
                headerText: 'Failed to create an invoice',
                bodyText: [
                  INVOICE_CREATION_IN_PROGRESS_ERROR,
                  NOTICE_HAS_INVOICE_ERROR
                ].includes(error.message)
                  ? error.message
                  : 'Please try again or contact help@column.us'
              })
            );
          }

          dispatch(push(`/notice/${notice.id}`));
        },
        disabled: !!invalidReason
      }}
      onClose={() => dispatch(push(`/notice/${notice?.id}`))}
      id="create-invoice"
    >
      <div className="grid grid-cols-5">
        {!loadingComplete && <LoadingState />}

        {loadingComplete && (
          <>
            <div className="col-span-3 overflow-scroll">
              <div className="max-w-4xl mx-auto">
                <InvoiceDetailsCard
                  updateInvoiceCreationData={invoiceDataUpdates => {
                    setInvoiceCreationData({
                      ...invoiceCreationData,
                      ...invoiceDataUpdates
                    });
                  }}
                  invoiceCreationData={invoiceCreationData}
                />
              </div>
            </div>
            <div className="col-span-2 border-l">
              <InvoicePreviewCard
                invoiceCreationData={invoiceCreationData}
                notice={notice}
              />
            </div>
          </>
        )}
      </div>
    </FullScreenModal>
  );
}
