export enum PricingPeriodType {
  DAILY = 'daily',
  WEEKLY = 'weekly'
}

export type DailyPricingPeriod = {
  type: PricingPeriodType.DAILY;
};

export type WeeklyPricingPeriod = {
  type: PricingPeriodType.WEEKLY;
  startDay: number; // 0-6, with 0 = sunday
};

export type PricingPeriod = DailyPricingPeriod | WeeklyPricingPeriod;
