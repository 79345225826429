import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { FormHelperText } from '@material-ui/core';
import * as EmailValidator from 'email-validator';
import AuthActions, { selectIsPublisher } from 'redux/auth';
import {
  EUser,
  EOrganization,
  ESnapshotExists,
  exists,
  Customer,
  ERequestTypes
} from 'lib/types';
import { State } from 'lib/enums';

import { getCustomer } from 'lib/notice/customer';
import { getFirebaseContext } from 'utils/firebase';
import { removeUndefinedFields } from 'lib/helpers';

import PlacementActions, {
  selectAccountNumber,
  selectCurrentStepId,
  selectDraftSnap,
  selectFiledBy,
  selectFiler,
  selectNewspaper
} from 'redux/placement';
import { getAllowedOrganizationSnaps } from 'lib/users';
import { CustomerFetchMethods, CustomerValidationState } from 'types/customers';
import { SyncFormat } from 'lib/types/integrations/sync';
import { TextField } from 'lib/components/TextField';
import { logAndCaptureException } from 'utils';
import { safeStringify, columnObjectsAreEqual } from 'lib/utils/stringify';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import {
  IntegrationFeature,
  integrationHasFeature
} from 'lib/integrations/features';
import { Alert, AlertProps } from 'lib/components/Alert';
import {
  CheckCircleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';
import { Autocomplete } from 'lib/components/Autocomplete';
import {
  getUserByEmail,
  createOrUpdateAnonymousFiler,
  isEmailRegistered,
  getAnonymousUserRegistrationRequest,
  registerUser,
  loginUser
} from 'utils/users';
import { useFirestoreListener } from 'routes/placeScroll/hooks/useFirestoreListener';
import { PlacementError } from 'lib/errors/PlacementError';
import { phoneFormattersByRegion } from 'lib/components/helpers/phoneFormat';
import { useLoading } from 'lib/components/hooks/useLoading';
import { useInheritedProperty } from 'lib/frontend/hooks/useInheritedProperty';
import { ColumnService } from 'lib/services/directory';
import { useActivelyTyping } from '../hooks/useActivelyTyping';
import ScrollStep from '../ScrollStep';
import {
  fetchAccountNumber,
  fetchPublisherCustomer,
  isAnonymousFlow,
  getFirstAndLastNameFromFullName,
  shouldShowAccountIdFeatures
} from '../helpers';
import { CONFIRM_FILER } from '../helpers/calculatePlacementSteps';
import CustomerSearch from './CustomerSearch';

const placeholders = {
  email: 'Enter email address',
  firstName: 'Enter first name',
  lastName: 'Enter last name',
  phoneNumber: 'Enter phone number',
  address1: `Enter address 1`,
  address2: `Enter address 2`,
  city: 'Enter city',
  zip: 'Enter zip code',
  state: 'Select state',
  organization: 'Select organization',
  accountNumber: 'Enter account number'
};
const labels = {
  email: 'Email address',
  firstName: 'First name',
  lastName: 'Last name',
  phoneNumber: 'Phone number',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  zip: 'Zip code',
  accountNumber: 'Account number',
  state: 'State',
  organization: 'Organization'
};

export type ConfirmFilerStepProps = {
  next: () => void;
  previous?: () => void;
  onDisabledStepClick: (stepId: string) => void;
  placementActions: typeof PlacementActions;
  syncFormat: SyncFormat | undefined;
  filer: ESnapshotExists<EUser> | undefined;
  publisherOrganization: ESnapshotExists<EOrganization> | undefined;
  authActions: typeof AuthActions;
  setShowEnterPasswordModal: (show: boolean) => void;
  setUserEmail: (newEmail: string) => void;
};

export default function ConfirmFilerStep({
  next,
  previous,
  onDisabledStepClick,
  placementActions,
  syncFormat,
  publisherOrganization,
  authActions,
  setShowEnterPasswordModal,
  setUserEmail
}: ConfirmFilerStepProps) {
  const dispatch = useAppDispatch();
  const stepId = CONFIRM_FILER;
  const draftSnap = useAppSelector(selectDraftSnap);
  const activeStepId = useAppSelector(selectCurrentStepId);
  const isPublisher = useAppSelector(selectIsPublisher);
  const placementAccountNumber = useAppSelector(selectAccountNumber);
  const placementFiler = useAppSelector(selectFiler);
  const publisherRef = useAppSelector(selectNewspaper);
  const placementFiledBy = useAppSelector(selectFiledBy);
  const phoneFieldRequired = useInheritedProperty(
    publisherRef,
    'requirePhoneOnFilers'
  );
  const [filerInfo, setFilerInfo] = useState<Partial<EUser> | undefined>();
  const [existingCustomer, setExistingCustomer] =
    useFirestoreListener<Customer>();
  const [existingFiler, setExistingFiler] =
    useState<ESnapshotExists<EUser> | null>(null);
  const [filerOrgOptions, setFilerOrgOptions] =
    useState<ESnapshotExists<EOrganization>[]>();
  const [selectedFilerOrg, setSelectedFilerOrg] =
    useState<ESnapshotExists<EOrganization>>();

  const [accountNumberOnFileInColumn, setAccountNumberOnFileInColumn] =
    useState<string | null>(null);
  const [verifiedAccountNumber, setVerifiedAccountNumber] = useState<
    string | null
  >(null);
  const [activelyTypingEmail, setActivelyTypingEmail] = useActivelyTyping(3000);
  const [loadingPublisherCustomer, fetchAndSetPublisherCustomerWithLoading] =
    useLoading();
  const [loadingAccountNumber, fetchAndSetAccountNumberWithLoading] =
    useLoading();
  const [customerValidationValue, setCustomerValidationValue] = useState<
    number | null
  >(null);

  // Account ID fields are only ever shown to publishers in this step,
  // for advertisers they are asked at the end of the flow (if at all)
  const showAccountNumber = shouldShowAccountIdFeatures(syncFormat);

  const isAccountOnHold = !!existingCustomer?.data().isAccountOnHold;
  const userIsAnonymous = isAnonymousFlow();

  const canSearchCustomerByInfo = integrationHasFeature(
    syncFormat,
    IntegrationFeature.CUSTOMERS_FIND_BY_INFO
  );

  const showAccountNumberDiscrepancyAlert = !!(
    isPublisher &&
    canSearchCustomerByInfo &&
    showAccountNumber &&
    verifiedAccountNumber &&
    accountNumberOnFileInColumn &&
    verifiedAccountNumber !== accountNumberOnFileInColumn
  );

  /**
   * For advertisers who start placing a notice anonymously,
   * we show the confirm filer step with the customer fields disabled
   * to maintain the same steps as shown in the anonymous flow
   */
  const advertiserStartedPlacingAnonymously =
    sessionStorage.getItem('startedFromAnonymousFlow') === 'true' &&
    !isPublisher &&
    !userIsAnonymous;
  const shouldShowOrgDropdown =
    !userIsAnonymous && !!filerOrgOptions?.length && filerOrgOptions.length > 1;

  const shouldShowContactInformationForm =
    isPublisher || userIsAnonymous || advertiserStartedPlacingAnonymously;
  const filerHasExactlyOneOrg = filerOrgOptions?.length === 1;

  const shouldShowCustomerSearch = isPublisher && exists(publisherOrganization);

  const existingFilerOrg =
    selectedFilerOrg || (filerHasExactlyOneOrg ? filerOrgOptions[0] : null);

  useEffect(() => {
    if (!userIsAnonymous && !existingFiler && !!placementFiler?.id) {
      void (async () => {
        const advertiserSnap = await placementFiler.get();
        if (exists(advertiserSnap)) {
          setExistingFiler(advertiserSnap);
        }
      })();
    }
  }, [placementFiler?.id]);

  useEffect(() => {
    /* If the current notice was duplicated from an existing one, use the filer information from that old notice */
    if (getLocationParams().get('duplicate') === 'true' && filerInfo?.email) {
      setExistingFiler(null);
      void handleEmailChange(filerInfo.email);
    } else if (
      filerInfo?.email &&
      stepId === activeStepId &&
      EmailValidator.validate(filerInfo.email)
    ) {
      void setFilerState(filerInfo.email);
    }
  }, [activeStepId]);

  useEffect(() => {
    if (!existingFiler) {
      setFilerOrgOptions([]);
      setExistingCustomer(null);
      setFilerInfo({
        ...(filerInfo?.email ? { email: filerInfo.email } : {})
      });
      setCustomerValidationValue(null);
    }

    if (existingFiler && !userIsAnonymous) {
      void (async () => {
        const { allowedOrganizations } = existingFiler.data();
        if (allowedOrganizations) {
          const placementFilerOrg =
            (await placementFiledBy?.get()) as ESnapshotExists<EOrganization>;
          const allOrgs = await getAllowedOrganizationSnaps(existingFiler);
          setFilerOrgOptions(allOrgs);
          if (
            shouldShowOrgDropdown &&
            allOrgs.some(o => o.id === placementFilerOrg?.id)
          ) {
            setSelectedFilerOrg(placementFilerOrg);
          }
        }
        if (!allowedOrganizations) {
          setFilerOrgOptions([]);
        }

        const customer = publisherOrganization
          ? await getCustomer(
              getFirebaseContext(),
              existingFiler,
              publisherOrganization
            )
          : null;
        if (
          !columnObjectsAreEqual(customer?.data(), existingCustomer?.data())
        ) {
          setExistingCustomer(customer);
        }

        const newFilerInfo = getFilerInfo({
          customer: customer?.data(),
          user: existingFiler.data()
        });

        setFilerInfo(newFilerInfo);
      })();
    }

    if (showAccountNumber && !!filerInfo?.email) {
      void fetchAndSetAccountNumberWithLoading(fetchAndSetAccountNumber);
    }
  }, [
    safeStringify(existingFiler),
    safeStringify(existingCustomer),
    shouldShowOrgDropdown,
    draftSnap?.data()?.newspaper?.id,
    userIsAnonymous
  ]);

  /* We should disable all fields except for email & account number under the following circumstances:
    1. A valid email has not been entered
    2. A customer already exists for a given filer/newspaper */
  const shouldDisableFixedCustomerFields =
    activelyTypingEmail ||
    !EmailValidator.validate(filerInfo?.email || '') ||
    !!existingCustomer ||
    advertiserStartedPlacingAnonymously;

  const setFilerState = async (email: string | undefined) => {
    if (!email) {
      return setExistingFiler(null);
    }

    try {
      const filer = await getUserByEmail(email);
      setExistingFiler(filer);
      setUserEmail(email);
    } catch (error) {
      setExistingFiler(null);
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        error,
        'Error fetching user by email during placement flow',
        { userEmail: email }
      );
    }
  };

  const eraseAccountNumberAndProceed = async () => {
    dispatch(PlacementActions.setAccountNumber(null));
    setCustomerValidationValue(null);
    await onExit();
  };

  const handleFilerInfoFieldChange = (updates: Partial<EUser>) => {
    setFilerInfo({
      ...filerInfo,
      ...updates
    });
  };

  const handleAccountNumberChange = (accountNumber: string) => {
    dispatch(PlacementActions.setAccountNumber(accountNumber));
    if (
      !!accountNumber &&
      (accountNumber === verifiedAccountNumber ||
        accountNumber === accountNumberOnFileInColumn)
    ) {
      setCustomerValidationValue(
        CustomerValidationState.PUBLISHER_CUSTOMER_FOUND
      );
    } else {
      setCustomerValidationValue(null);
    }
  };

  const handleEmailChange = async (email: string) => {
    setActivelyTypingEmail(true);
    handleFilerInfoFieldChange({ email });
    const validEmail = EmailValidator.validate(email);
    if (email && validEmail) {
      await setFilerState(email);
    } else {
      setExistingFiler(null);
    }
  };

  const fetchAndSetPublisherCustomer = async (
    filerInfo:
      | ERequestTypes['integrations/customers/:newspaperId/find']
      | undefined
  ) => {
    const fetchResults = await fetchPublisherCustomer({
      accountNumber: placementAccountNumber || null,
      verifiedAccountNumber,
      customerInfo: filerInfo || null,
      newspaperSnap: publisherOrganization || null
    });

    if (fetchResults.success) {
      setCustomerValidationValue(
        CustomerValidationState.PUBLISHER_CUSTOMER_FOUND
      );
      if (
        fetchResults.method === CustomerFetchMethods.FROM_CUSTOMER_INFO ||
        fetchResults.method === CustomerFetchMethods.FROM_ACCOUNT_NUMBER
      ) {
        dispatch(PlacementActions.setAccountNumber(fetchResults.customer.id));
        setVerifiedAccountNumber(fetchResults.customer.id);
        const filerInfoEmpty = Object.keys(filerInfo || {}).length === 0;
        if (filerInfoEmpty) {
          const { firstName, lastName } = getFirstAndLastNameFromFullName(
            fetchResults.customer.info.name
          );
          handleFilerInfoFieldChange(
            removeUndefinedFields({
              ...fetchResults.customer.info,
              firstName,
              lastName
            })
          );

          if (!filerInfo?.email && fetchResults.customer.info.email) {
            await setFilerState(fetchResults.customer.info.email);
          }
        }
      }
    } else if (fetchResults.reason) {
      setCustomerValidationValue(fetchResults.reason);
    }
  };

  const fetchAndSetAccountNumber = async () => {
    const fetchResults = await fetchAccountNumber({
      noticeSnap: draftSnap || null,
      filerRef: existingFiler?.ref || null,
      filedByRef: existingFilerOrg?.ref || null
    });

    if (fetchResults.success) {
      setAccountNumberOnFileInColumn(fetchResults.accountNumber);
      if (!placementAccountNumber) {
        dispatch(PlacementActions.setAccountNumber(fetchResults.accountNumber));
      }
      setCustomerValidationValue(
        CustomerValidationState.PUBLISHER_CUSTOMER_FOUND
      );
    } else {
      setAccountNumberOnFileInColumn(null);
      setCustomerValidationValue(
        isPublisher
          ? CustomerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN
          : CustomerValidationState.DID_NOT_SEARCH
      );
    }
  };

  // TODO: Convert to a thunk action
  async function registerAndLoginNewUser(filerInfo: Partial<EUser>) {
    const { email = '', firstName = '', lastName } = filerInfo;

    const userRegistrationRequest = getAnonymousUserRegistrationRequest({
      email,
      firstName,
      lastName
    });
    const userRegistrationResponse = await registerUser(
      userRegistrationRequest
    );

    if (userRegistrationResponse.success) {
      placementActions.saveDraft();
      await loginUser(email, userRegistrationRequest.password);

      authActions.setTemporaryPassword(userRegistrationRequest.password);
      authActions.setShowPasswordReset(true);

      return next();
    }

    if (userRegistrationResponse.error) {
      // TODO: Fix error handling in user registration response (errors are serialized and not returned properly from API)
      throw userRegistrationResponse.error;
    }
  }

  // TODO: Convert to a thunk action
  async function setNoticeFiler(
    newspaper: ESnapshotExists<EOrganization>,
    filerInfo: Partial<EUser>,
    filerOrganization: ESnapshotExists<EOrganization> | null = null
  ) {
    /**
     * Set the contact information in the store to use when
     * creating a customer record at the end of placement
     */
    placementActions.setNewCustomerInfo(filerInfo);

    const userRef = await createOrUpdateAnonymousFiler(newspaper, filerInfo);
    placementActions.setFiler(userRef);
    placementActions.setFiledBy(filerOrganization?.ref || null);
  }

  const onExit = async () => {
    if (!exists(publisherOrganization) || !filerInfo?.email) {
      return;
    }

    try {
      /**
       * 1. Set the filer user on the placement state based on the entered contact info
       * and create a base anonymous user document if the user doesn't exist yet
       */
      await setNoticeFiler(publisherOrganization, filerInfo, existingFilerOrg);
    } catch (error) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        error,
        'Error creating and/or setting filer user during placement flow',
        { userEmail: filerInfo?.email }
      );
      return placementActions.setPlacementError(new PlacementError());
    }

    if (userIsAnonymous) {
      try {
        /**
         * 2. If the entered email is associated with an existing authentication account,
         * open the login modal and do not move onto the next step
         */
        const shouldLogIn = await isEmailRegistered(filerInfo.email);
        if (shouldLogIn) {
          setShowEnterPasswordModal(true);

          /**
           * TODO: Investigate if there's a better way to handle this.
           * Store the entered contact fields in case a filer with allowedOrgs that does not
           * have an affilliated customer object with the newspaper logs in.
           * Otherwise, their filing info will not be populated properly after login.
           */
          return sessionStorage.setItem('filerInfo', JSON.stringify(filerInfo));
        }

        /**
         * 3. If the user is anonymous and the user is "new" (just created in step 1),
         * complete registration of the account and log them in using a temporary password.
         */
        return await registerAndLoginNewUser(filerInfo);
      } catch (error) {
        logAndCaptureException(
          ColumnService.WEB_PLACEMENT,
          error,
          'Error registering and/or logging in anonymous user during placement flow',
          { userEmail: filerInfo?.email }
        );
        return placementActions.setPlacementError(new PlacementError());
      }
    }

    /**
     * If a user with allowedOrgs and no customer object on the newspaper
     * started in anonymous placement flow, entered their filer info, logged in,
     * and completes ConfirmFilerStep, we no longer need to store their contact info
     * in sessionStorage, so it can be removed. This will not error if the filer did not
     * go into the above flow in the first place.
     */
    sessionStorage.removeItem('filerInfo');

    /**
     * If selecting an organization in the placement flow, make it the active organization
     * for the logged in user so that the notice detail page loads properly after placement
     */
    if (!isPublisher && selectedFilerOrg) {
      authActions.setActiveOrganization(selectedFilerOrg);
    }

    next();
  };

  const addressFieldsRequired = useInheritedProperty(
    publisherOrganization?.ref,
    'requireAddressOnFilers'
  );

  const lastNameRequired = useInheritedProperty(
    publisherOrganization?.ref,
    'requireLastNameOnFilers'
  );

  const phoneFieldIsComplete =
    !phoneFieldRequired ||
    (filerInfo?.phone &&
      phoneFormattersByRegion.us.format(filerInfo.phone).length ===
        phoneFormattersByRegion.us.props.maxLength);

  const addressFieldsAreComplete =
    !addressFieldsRequired ||
    (filerInfo?.address &&
      filerInfo?.state &&
      filerInfo?.zipCode &&
      filerInfo?.city);

  const nameFieldsAreComplete =
    filerInfo?.firstName && (!lastNameRequired || filerInfo?.lastName);

  const filerInfoIsComplete =
    !shouldShowContactInformationForm ||
    Boolean(
      nameFieldsAreComplete &&
        filerInfo?.email &&
        EmailValidator.validate(filerInfo.email) &&
        phoneFieldIsComplete &&
        addressFieldsAreComplete
    );

  const stepCompleteWithoutAccountNumber =
    !isAccountOnHold &&
    filerInfoIsComplete &&
    (!shouldShowOrgDropdown || !!selectedFilerOrg) &&
    !loadingPublisherCustomer;

  const stepComplete =
    stepCompleteWithoutAccountNumber &&
    customerValidationValue !==
      CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO &&
    customerValidationValue !==
      CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID;

  const nextOrVerifyText =
    !showAccountNumber ||
    (verifiedAccountNumber && verifiedAccountNumber === placementAccountNumber)
      ? 'Next'
      : 'Verify';

  const isLoading = loadingAccountNumber || loadingPublisherCustomer;

  const isNextOrVerifyEnabled = {
    Next: stepComplete,
    Verify:
      stepComplete ||
      (!isAccountOnHold &&
        !!placementAccountNumber &&
        !isLoading &&
        customerValidationValue !==
          CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO &&
        customerValidationValue !==
          CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID)
  }[nextOrVerifyText];

  const handleNextOrVerify = {
    Next: onExit,
    Verify: () =>
      fetchAndSetPublisherCustomerWithLoading(
        async () =>
          await fetchAndSetPublisherCustomer(
            filerInfo as ERequestTypes['integrations/customers/:newspaperId/find']
          )
      )
  }[nextOrVerifyText];

  /**
   * Highlight the organization dropdown when all other (required) customer information is auto-filled.
   * */
  const shouldHightlightOrgFieldAndText =
    filerInfoIsComplete && shouldShowOrgDropdown && !selectedFilerOrg;

  /** Becomes true when all the required information filled */
  const showRequiredFieldTooltipText =
    !filerInfoIsComplete || (shouldShowOrgDropdown && !selectedFilerOrg);

  const nextOrVerifyTooltipText = {
    Next: showRequiredFieldTooltipText
      ? 'Please fill all required fields first.'
      : '',
    Verify: isNextOrVerifyEnabled
      ? ''
      : `Enter email, name, and phone number for this customer${
          showAccountNumber ? ', or enter an account number.' : '.'
        }`
  }[nextOrVerifyText];

  const publisherOrganizationName =
    publisherOrganization?.data()?.name || 'your newspaper';

  const accountNumberAlertPropsForPublisher =
    isPublisher && typeof customerValidationValue === 'number'
      ? getCustomerValidationAlertAndColorForPublisher({
          customerValidationValue,
          isLoading,
          canSearchCustomerByInfo,
          newspaperName: publisherOrganizationName
        })
      : undefined;

  const accountNumberAlertPropsForAdvertiser =
    !isPublisher && typeof customerValidationValue === 'number'
      ? getCustomerValidationAlertAndColorForAdvertiser({
          customerValidationValue,
          accountNumber: placementAccountNumber,
          newspaperName: publisherOrganizationName
        })
      : undefined;

  const accountNumberAlertProps =
    showAccountNumber &&
    (accountNumberAlertPropsForAdvertiser ||
      accountNumberAlertPropsForPublisher);

  const showCustomerArchivedAlert = !!existingCustomer?.data().archived;
  const editCustomerAction =
    isPublisher && existingCustomer && !showCustomerArchivedAlert
      ? {
          onClick: () =>
            window.open(
              `/settings/organization/?tab=customers&customerId=${existingCustomer.id}`,
              '_blank'
            ),
          buttonId: `${stepId}-edit-button`,
          buttonText: 'Edit customer',
          tooltipText: `This customer is already in ${publisherOrganizationName}'s customer list in Column. You will be directed to the customer page to edit their contact information.`
        }
      : undefined;

  return (
    <ScrollStep
      id={stepId}
      complete={isNextOrVerifyEnabled}
      next={handleNextOrVerify}
      nextText={nextOrVerifyText}
      nextTooltipText={nextOrVerifyTooltipText}
      previous={previous}
      secondaryAction={editCustomerAction}
      canSkip={stepCompleteWithoutAccountNumber}
      skip={showAccountNumber ? eraseAccountNumberAndProceed : null}
      skipText={'Skip account number'}
      skipTooltipText={`If you proceed without an account number, then a new account with ${publisherOrganizationName} will be created upon notice submission.`}
      onDisabledStepClick={onDisabledStepClick}
      title="Provide Customer Information"
      caption={getStepCaption({
        isPublisher,
        anonymousFlow: userIsAnonymous || advertiserStartedPlacingAnonymously,
        shouldShowOrgDropdown,
        showAccountNumber,
        publisherName: publisherOrganization?.data()?.name || ''
      })}
      onHoldText={
        isAccountOnHold
          ? 'This customer’s account is on hold. To remove the hold, navigate to the customer tab.'
          : undefined
      }
    >
      <div className="grid grid-cols-12 gap-6">
        {shouldShowContactInformationForm && (
          <>
            {shouldShowCustomerSearch && (
              <div className="col-span-12">
                <CustomerSearch
                  publicationSnap={publisherOrganization}
                  onSelect={(selectedUser: ESnapshotExists<EUser>) =>
                    setExistingFiler(selectedUser)
                  }
                  active={stepId === activeStepId}
                />
              </div>
            )}
            <div className="col-span-12">
              <TextField
                id="customer-email"
                required
                placeholder={placeholders.email}
                value={filerInfo?.email || ''}
                type="email"
                disabled={
                  loadingPublisherCustomer ||
                  advertiserStartedPlacingAnonymously
                }
                onChange={handleEmailChange}
                labelText={labels.email}
              />
              {showCustomerArchivedAlert && (
                <div className="my-5">
                  <Alert
                    id="customer-archived-alert"
                    status="warning"
                    icon={<ExclamationCircleIcon className="h-5 w-5" />}
                    description={`This customer was formerly deleted from your customers list.`}
                  />
                </div>
              )}
            </div>

            <div className="col-span-6">
              <TextField
                id="customer-first-name"
                required
                placeholder={placeholders.firstName}
                value={filerInfo?.firstName || ''}
                onChange={firstName =>
                  handleFilerInfoFieldChange({ firstName })
                }
                labelText={labels.firstName}
                disabled={shouldDisableFixedCustomerFields}
              />
            </div>
            <div className="col-span-6">
              <TextField
                id="customer-last-name"
                required={lastNameRequired}
                placeholder={placeholders.lastName}
                value={filerInfo?.lastName || ''}
                onChange={lastName => handleFilerInfoFieldChange({ lastName })}
                labelText={labels.lastName}
                disabled={shouldDisableFixedCustomerFields}
              />
            </div>
            <div className="col-span-4">
              <TextField
                type="tel"
                id="customer-phone-number"
                required={phoneFieldRequired}
                value={filerInfo?.phone || ''}
                onChange={phone => handleFilerInfoFieldChange({ phone })}
                placeholder={placeholders.phoneNumber}
                labelText={labels.phoneNumber}
                disabled={shouldDisableFixedCustomerFields}
              />
            </div>
            <div className="col-span-4">
              <TextField
                id="customer-address"
                placeholder={placeholders.address1}
                required={addressFieldsRequired}
                value={filerInfo?.address || ''}
                onChange={address => handleFilerInfoFieldChange({ address })}
                labelText={labels.address1}
                disabled={shouldDisableFixedCustomerFields}
              />
            </div>
            <div className="col-span-4">
              <TextField
                id="customer-address-2"
                placeholder={placeholders.address2}
                value={filerInfo?.addressLine2 || ''}
                onChange={addressLine2 =>
                  handleFilerInfoFieldChange({ addressLine2 })
                }
                labelText={labels.address2}
                disabled={shouldDisableFixedCustomerFields}
              />
            </div>
            <div className="col-span-4">
              <TextField
                id="customer-city"
                placeholder={placeholders.city}
                required={addressFieldsRequired}
                value={filerInfo?.city || ''}
                onChange={city => handleFilerInfoFieldChange({ city })}
                labelText={labels.city}
                disabled={shouldDisableFixedCustomerFields}
              />
            </div>
            <div className="col-span-4">
              <TextField
                id="customer-zip"
                type="postal-code"
                placeholder={placeholders.zip}
                required={addressFieldsRequired}
                value={filerInfo?.zipCode || ''}
                onChange={zipCode => handleFilerInfoFieldChange({ zipCode })}
                labelText={labels.zip}
                disabled={shouldDisableFixedCustomerFields}
              />
            </div>
            <div className="col-span-4">
              <Autocomplete
                disabled={shouldDisableFixedCustomerFields}
                required={addressFieldsRequired}
                id="state"
                labelText={labels.state}
                placeholder={placeholders.state}
                onChange={value =>
                  handleFilerInfoFieldChange({
                    state: Number(value)
                  })
                }
                options={State.items().map((state: Record<string, any>) => ({
                  value: String(state.value),
                  label: state.label
                }))}
                value={String(filerInfo?.state) || ''}
              />
            </div>
          </>
        )}
        {shouldShowOrgDropdown && (
          <div className="col-span-12">
            <ColumnSelect
              id="customer-organization-select"
              required
              labelText={labels.organization}
              noteText={
                isPublisher
                  ? `${
                      filerInfo?.email || 'This user'
                    } is associated with multiple organizations on Column. Select the organization that is placing the notice.`
                  : ''
              }
              placeholder={placeholders.organization}
              options={filerOrgOptions
                .map(org => ({
                  value: org.id,
                  label: org.data().name
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              value={selectedFilerOrg?.id || ''}
              onChange={newValue => {
                if (!newValue) return;
                setSelectedFilerOrg(
                  filerOrgOptions.find(org => org.id === newValue)
                );
              }}
              allowUndefined
              allowAutoFocus={shouldHightlightOrgFieldAndText}
            />
          </div>
        )}

        {!shouldShowOrgDropdown && shouldShowContactInformationForm && (
          <div className="col-span-12">
            <TextField
              id="customer-organization-name"
              placeholder="Organization"
              value={
                filerHasExactlyOneOrg
                  ? filerOrgOptions[0].data().name
                  : filerInfo?.organizationName || ''
              }
              onChange={organizationName =>
                handleFilerInfoFieldChange({ organizationName })
              }
              labelText="Organization name"
              disabled={
                filerHasExactlyOneOrg || shouldDisableFixedCustomerFields
              }
            />
          </div>
        )}

        {showAccountNumber && (
          <div className="col-span-12">
            <TextField
              id="account-number"
              placeholder="Account Number"
              value={placementAccountNumber || ''}
              onChange={handleAccountNumberChange}
              labelText={labels.accountNumber}
              noteText={
                !isPublisher
                  ? getAccountNumberNoteText({
                      publisherName: publisherOrganization?.data()?.name || '',
                      supportEmail: publisherOrganization?.data()?.supportEmail
                    })
                  : ''
              }
              errorText={
                (
                  [
                    CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID,
                    CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO
                  ] as number[]
                ).includes(customerValidationValue || NaN)
                  ? ' '
                  : ''
              }
            />
          </div>
        )}
        {isPublisher && !!existingCustomer && !filerInfoIsComplete && (
          <div className="col-span-12">
            <FormHelperText
              data-testid="edit-customer-message"
              style={{ marginTop: 0 }}
            >
              {`${
                filerInfo?.email || 'This user'
              } is in your customers list, but they are missing contact information. Please click "Edit customer" to provide this customer's details.`}
            </FormHelperText>
          </div>
        )}
        {accountNumberAlertProps && (
          <div className="col-span-12">
            <Alert {...accountNumberAlertProps} />
          </div>
        )}
        {showAccountNumberDiscrepancyAlert && (
          <div className="col-span-12">
            <Alert
              id="account-number-discrepancy"
              status="warning"
              icon={<ExclamationCircleIcon className="h-5" />}
              description={`This customer has account number #${
                existingCustomer?.data().internalID
              } stored in Column, but we found account number #${verifiedAccountNumber} in Adpoint. If you’d like to update the saved account number in Column, click ‘Edit customer.’`}
            />
          </div>
        )}
        {advertiserStartedPlacingAnonymously && !showAccountNumber && (
          <div className="col-span-12">
            <Alert
              id="update-account-details"
              title={`If you need to update your contact information, you can do so later in “My Account” settings.`}
            />
          </div>
        )}
      </div>
    </ScrollStep>
  );
}

function getStepCaption({
  isPublisher,
  anonymousFlow,
  shouldShowOrgDropdown,
  showAccountNumber,
  publisherName
}: {
  isPublisher: boolean;
  anonymousFlow: boolean;
  shouldShowOrgDropdown: boolean;
  showAccountNumber: boolean;
  publisherName: string;
}) {
  if (anonymousFlow) {
    return `The publisher will reach out if they have questions about your notice.`;
  }

  if (isPublisher) {
    return `Who's placing this notice? Please enter contact information below.`;
  }

  // User is not a publisher but has multiple organizations
  if (shouldShowOrgDropdown) {
    return 'Please select the organization that is placing this notice.';
  }

  // User is not a publisher and does not have multiple organizations, but does have account number field
  if (showAccountNumber) {
    return `Provide your ${publisherName} account number. If you don't have an account with ${publisherName}, skip this step.`;
  }
}

function getFilerInfo({
  customer,
  user
}: {
  customer: Customer | undefined;
  user: EUser;
}) {
  const userData = customer || user;
  // If a user exists in column but does not have
  // a customer account with the current newspaper,
  // populate the customer information step with
  // information from the user. If the user exists in Column,
  // has allowedOrgs, and no customer, and initializes filing
  // from anonymous placement flow, pull filerInfo from anonymous
  // placement flow once they log in.
  // TODO: Determine if there's a better way to handle this
  const filerInfoFromAnonPlacementFlow: Partial<EUser> | undefined = JSON.parse(
    sessionStorage.getItem('filerInfo') || '{}'
  );

  return removeUndefinedFields({
    email: user.email,
    firstName: userData.firstName || '',
    lastName: userData.lastName || undefined,
    organizationName: userData.organizationName,
    address: userData.address || filerInfoFromAnonPlacementFlow?.address || '',
    addressLine2:
      userData.addressLine2 ||
      filerInfoFromAnonPlacementFlow?.addressLine2 ||
      '',
    city: userData.city ?? filerInfoFromAnonPlacementFlow?.city ?? undefined,
    state: userData.state || filerInfoFromAnonPlacementFlow?.state || undefined,
    zipCode:
      userData.zipCode ?? filerInfoFromAnonPlacementFlow?.zipCode ?? undefined,
    phone: userData.phone ?? filerInfoFromAnonPlacementFlow?.phone ?? undefined
  });
}

const getAccountNumberNoteText = ({
  publisherName,
  supportEmail = 'the newspaper'
}: {
  publisherName: string;
  supportEmail?: string;
}) =>
  `Account numbers only apply for billable customers with ${publisherName}. If you forget your account number or want to create an account with ${publisherName}, please contact ${supportEmail}.`;

function getCustomerValidationAlertAndColorForAdvertiser({
  customerValidationValue,
  accountNumber,
  newspaperName
}: {
  customerValidationValue: number;
  accountNumber: string | undefined;
  newspaperName: string;
}): AlertProps | undefined {
  switch (customerValidationValue) {
    case CustomerValidationState.PUBLISHER_CUSTOMER_FOUND:
      return {
        id: 'customer-account-found',
        description:
          'Account number verified. The notice will be placed in this account.',
        status: 'success',
        icon: <CheckCircleIcon className="h-5" />
      };
    case CustomerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN:
    case CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO:
      return {
        id: 'customer-invalid-info',
        description: `We couldn't find an account with ${newspaperName} that matches your information in Column. If you believe you have an account with ${newspaperName}, please enter your account number.`,
        status: 'warning',
        icon: <ExclamationCircleIcon className="h-5" />
      };
    case CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID:
      return {
        id: 'customer-invalid-id',
        description: `Account #${accountNumber} is invalid. Please check again or skip account number.`,
        status: 'error',
        icon: <ExclamationCircleIcon className="h-5" />
      };
    case CustomerValidationState.DID_NOT_SEARCH:
      // In this case we don't want to show any message
      return undefined;
    default:
      console.error('Invalid customer validation state value');
  }
}

function getCustomerValidationAlertAndColorForPublisher({
  customerValidationValue,
  isLoading,
  canSearchCustomerByInfo,
  newspaperName
}: {
  customerValidationValue: number;
  isLoading: boolean;
  canSearchCustomerByInfo: boolean;
  newspaperName: string;
}): AlertProps | undefined {
  if (isLoading) {
    return {
      id: 'customer-loading',
      description: 'Loading...',
      status: 'warning'
    };
  }

  switch (customerValidationValue) {
    case CustomerValidationState.PUBLISHER_CUSTOMER_FOUND: {
      return {
        id: 'customer-account-found',
        description:
          'Account number found. Please edit the account number or customer information if you want to place this notice in a different account.',
        status: 'success',
        icon: <CheckCircleIcon className="h-5" />
      };
    }
    case CustomerValidationState.NO_PUBLISHER_CUSTOMER_FOUND_IN_COLUMN: {
      const description = canSearchCustomerByInfo
        ? `This customer doesn't have an account number saved in Column. Click the verify button to find a matching account in ${newspaperName}'s database, or enter an account number.`
        : `This customer doesn't have an account number saved in Column.`;
      return {
        id: 'customer-no-account-number',
        description,
        status: 'warning',
        icon: <ExclamationCircleIcon className="h-5" />
      };
    }
    case CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_INFO:
      return {
        id: 'customer-invalid-info',
        description: `We couldn't find a matching account with ${newspaperName}. Please check your information again or proceed without an account number.`,
        status: 'error',
        icon: <ExclamationCircleIcon className="h-5" />
      };
    case CustomerValidationState.INVALID_PUBLISHER_CUSTOMER_ID:
      return {
        id: 'customer-invalid-id',
        description: `The account ID is invalid. Please check your information again or proceed without an account number.`,
        status: 'error',
        icon: <ExclamationCircleIcon className="text-red-700 h-5" />
      };
    case CustomerValidationState.DID_NOT_SEARCH:
      return undefined;
    default:
      console.error('Invalid customer validation state value');
  }
}
