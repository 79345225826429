import classNames from 'classnames';
import React from 'react';

import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import ExistingUserRegistrationHeader from './ExistingUserRegistrationHeader';
import NewUserRegistrationHeader from './NewUserRegistrationHeader';
import BackgroundImg from './backgroundImg.svg';
import {
  shouldShowPublisherRegistrationOption,
  hasUserCompletedRegistration
} from './registrationLayoutHelpers';

type RegistrationLayoutFrameProps = {
  children: React.ReactNode;
};

export default function RegistrationLayoutFrame({
  children
}: RegistrationLayoutFrameProps) {
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const user = useAppSelector(selectUser);
  const userHasCompletedRegistration = hasUserCompletedRegistration(
    user,
    activeOrganization
  );
  const showPublisherRegistrationOption =
    shouldShowPublisherRegistrationOption(user);
  return (
    <div
      className="bg-base-2 overflow-y-scroll h-screen w-screen"
      style={{ backgroundImage: `url(${BackgroundImg})` }}
    >
      {userHasCompletedRegistration ? (
        <ExistingUserRegistrationHeader />
      ) : (
        <NewUserRegistrationHeader />
      )}
      <div
        className={classNames('mx-auto', {
          'max-w-6xl': showPublisherRegistrationOption,
          'max-w-4xl': !showPublisherRegistrationOption,
          'mt-32': !userHasCompletedRegistration,
          'mt-24': userHasCompletedRegistration
        })}
      >
        {children}
      </div>
    </div>
  );
}
