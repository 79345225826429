import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import ReactPaginate from 'react-paginate';
import {
  ESnapshotExists,
  EOrganization,
  ESnapshot,
  EUser,
  EResponseTypes,
  EPayout
} from 'lib/types';
import LoadingState from 'components/LoadingState';
import api from 'api';
import { PayoutStatusType } from 'lib/enums';
import TailwindModal from 'components/TailwindModal';
import { getFirebaseContext } from 'utils/firebase';
import { DwollaPayout, PayoutGroup } from 'lib/types/payout';
import { ERequestTypes } from 'lib/types/api';
import { makeCsvContent } from 'lib/utils/csv';
import { ColumnButton } from 'lib/components/ColumnButton';
import {
  ArrowDownTrayIcon,
  ArrowTopRightOnSquareIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CreditCardIcon
} from '@heroicons/react/24/outline';
import ToastActions from 'redux/toast';
import { downloadFileContentsInBrowser } from 'lib/frontend/utils/browser';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import SettingsHeader from '../../SettingsHeader';
import DwollaCustomerModal from './DwollaCustomerModal';
import ManageDwollaAccounts from './ManageDwollaAccounts';
import PayoutGroupRows from './PayoutGroupRows';

type PaymentSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  user: ESnapshot<EUser>;
};
export default function DwollaSettings({
  activeOrganization
}: PaymentSettingsProps) {
  const dispatch = useAppDispatch();
  const [payoutsLoading, setPayoutsLoading] = useState(false);
  const [payoutGroups, setPayoutGroups] = useState<PayoutGroup[]>([]);
  const [infoLoading, setInfoLoading] = useState(false);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [customerData, setCustomerData] = useState();
  const [showDwollaCustomerModal, setShowDwollaCustomerModal] = useState(false);
  const [manageAccounts, setManageAccounts] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const [isPending, setIsPending] = useState(false);
  const [pendingPayoutsAmount, setPendingPayoutsAmount] = useState(0);
  const [dwollaAccountSettingsModal, setDwollaAccountSettingsModal] =
    useState(false);
  const [stripeInvoicesLink, setStripeInvoicesLink] = useState('');
  const [pageNumber, setPageNumber] = useState(0);

  const PAGE_SIZE = 10;

  const startIndex = pageNumber * PAGE_SIZE;
  const endIndex = payoutGroups.length
    ? Math.min(startIndex + PAGE_SIZE, payoutGroups.length) - 1
    : 0;

  useEffect(() => {
    const loadStripeInvoicesLink = async (customerId: string) => {
      try {
        const { url } = await api.post('subscription/stripe-billing-session', {
          customerId,
          returnUrl: window.location.href,
          configuration: 'organization_invoices'
        });

        setStripeInvoicesLink(url);
      } catch (err) {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          err,
          'Error while getting stripe billing url',
          {
            customerId
          }
        );
      }
    };

    setStripeInvoicesLink('');

    // Note: this will not be visible if the setting is inherited from the
    // parent org.
    const enabled = activeOrganization?.data()?.payColumnInvoices?.enabled;
    const stripeCustomerId =
      activeOrganization?.data()?.payColumnInvoices?.stripeCustomerId;

    if (enabled && stripeCustomerId) {
      void loadStripeInvoicesLink(stripeCustomerId);
    }
  }, [activeOrganization?.id]);

  // retrieve customer data
  const getCustomer = async () => {
    const customerId = activeOrganization.data()?.dwolla?.dwollaCustomer;
    try {
      const response = await api.post('dwolla/get-dwolla-customer', {
        customerId
      });
      setInfoLoading(false);
      setDwollaAccountSettingsModal(false);
      if (response.success) {
        setCustomerData(response.customer);
        setShowDwollaCustomerModal(true);
      }
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to retrieve customer data',
        {
          customerId
        }
      );
    }
  };

  // retrieve bank accounts
  const getBankAccounts = async () => {
    const customerId = activeOrganization.data()?.dwolla?.dwollaCustomer;
    try {
      const response = await api.post('dwolla/get-bank-accounts', {
        customerId
      });
      setAccountsLoading(false);
      if (response.success) {
        const bankAccountsData = [];
        if (response.bankAccounts[0])
          bankAccountsData.push(response.bankAccounts[0]);
        setBankAccounts(bankAccountsData);
        setDwollaAccountSettingsModal(false);
        setManageAccounts(true);
      }
    } catch (err) {
      setAccountsLoading(false);
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to retrieve bank accounts',
        {
          customerId
        }
      );
    }
  };

  const loadPendingPayouts = async () => {
    const statuses = [
      PayoutStatusType.open.value,
      PayoutStatusType.pending_ready_to_send_to_dwolla.value,
      PayoutStatusType.pending_sent_to_dwolla.value
    ];

    const pendingPayoutsSnap = await getFirebaseContext()
      .payoutsRef<DwollaPayout>()
      .where('newspaper', '==', activeOrganization.ref)
      .where('status', 'in', statuses)
      .get();

    const pendingPayouts =
      pendingPayoutsSnap.docs as unknown as ESnapshotExists<EPayout>[];

    let pendingAmount = 0;
    for (const p of pendingPayouts) {
      pendingAmount += p.data().amount;
    }

    setPendingPayoutsAmount(pendingAmount);
  };

  const loadPayouts = async () => {
    const response: EResponseTypes['payments/dwolla-payout-data'] =
      await api.post(
        'payments/dwolla-payout-data',
        activeOrganization.data()?.dwolla
      );

    if (!response.success) {
      setPayoutGroups([]);
      return;
    }

    const { payouts } = response;

    const anyPending = payouts.some(payoutGroup =>
      payoutGroup.children.some(payout => payout.status === 'pending')
    );
    if (!isPending && anyPending) {
      setIsPending(true);
    }

    setPayoutGroups(payouts);
  };

  const downloadCSV = async (payoutId: string) => {
    const relevantPayout = payoutGroups.find(
      g => g.parent.id === payoutId
    )?.parent;
    if (!relevantPayout) {
      console.error(`unable to find the relevant payout ${payoutId}`);
      return;
    }

    const req: ERequestTypes['payments/generate-dwolla-payout'] = {
      payout: relevantPayout
    };

    const { csvRows, csvHeaders, error } = await api.post(
      'payments/generate-dwolla-payout',
      req
    );
    if (error) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        error,
        'An error occurred when downloading payout',
        {
          payoutId: relevantPayout.id
        }
      );
      return;
    }

    await Promise.all(csvRows).then((results: any) => {
      const csvContent = makeCsvContent(csvHeaders, results);
      const arrivalDate = new Date(relevantPayout.created);

      downloadFileContentsInBrowser(
        `Payout_${arrivalDate.toISOString().split('T')[0]}.csv`,
        csvContent,
        'text/csv'
      );
    });
  };

  useEffect(() => {
    setPayoutGroups([]);
    setPayoutsLoading(true);
    loadPayouts().finally(() => setPayoutsLoading(false));

    setPendingPayoutsAmount(0);
    void loadPendingPayouts();
  }, [activeOrganization && activeOrganization.id]);

  if (payoutsLoading) {
    return (
      <LoadingState
        message="Loading payment data..."
        timeout={60}
        context={{ service: ColumnService.PAYMENTS }}
      />
    );
  }

  function renderHeader({
    header,
    width
  }: {
    header?: string;
    width?: string;
  }) {
    return (
      <th
        className={`py-3 ${
          width && `w-${width}`
        } bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider`}
      >
        {header}
      </th>
    );
  }

  const updateValues = () => {
    setShowDwollaCustomerModal(false);
  };

  const customerId = activeOrganization.data()?.dwolla?.dwollaCustomer;

  return (
    <div className="bg-white sm:rounded-lg border border-gray-300 shadow overflow-hidden">
      <SettingsHeader
        header="Payouts"
        description="Expand to see a breakdown of each payout to your bank."
      >
        <div className="space-x-2 flex items-center">
          {stripeInvoicesLink && (
            <ColumnButton
              tertiary
              id="stripe-invoices"
              size="lg"
              buttonText={'Invoices'}
              onClick={() => window.open(stripeInvoicesLink)}
              startIcon={<CreditCardIcon className="w-5 mr-1" />}
              type="button"
            />
          )}
          <ColumnButton
            tertiary
            id="update-account"
            size="lg"
            buttonText={'Update Account'}
            onClick={() => {
              setAccountsLoading(true);
              void getBankAccounts();
            }}
            startIcon={<ArrowTopRightOnSquareIcon className="w-5" />}
            type="button"
          />
          <ColumnButton
            tertiary
            id="download-report"
            size="lg"
            buttonText={'Download Report'}
            onClick={() => setOpenDownloadModal(true)}
            startIcon={<ArrowDownTrayIcon className="w-5" />}
            type="button"
          />
        </div>
      </SettingsHeader>

      {pendingPayoutsAmount > 0 && (
        <div className="flex flex-row items-center gap-2 px-7 py-2 text-sm">
          <CalendarIcon className="w-5" />
          <p>
            In addition to the payouts below, you have{' '}
            <b>${(pendingPayoutsAmount / 100).toFixed(2)}</b> in future payouts
            pending.
          </p>
        </div>
      )}

      <table className="min-w-full divide-y divide-gray-200" id="payout-table">
        <thead>
          <tr>
            {/* Empty header to leave space for expand button */}
            {renderHeader({})}
            {/* Empty header to leave space for negative indication */}
            {renderHeader({})}
            {renderHeader({ header: 'amount' })}
            {/* Empty header to leave space child payment type chip */}
            {renderHeader({ width: '1/5' })}
            {renderHeader({ header: 'created' })}
            {renderHeader({ header: 'arrived' })}
            {renderHeader({ header: 'actions' })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 rounded-b-lg">
          {payoutGroups.slice(startIndex, endIndex + 1).map(group => (
            <PayoutGroupRows
              key={group.parent.id}
              group={group}
              payoutSource={'dwolla'}
              handleDownloadCsvClicked={id => downloadCSV(id)}
            />
          ))}
        </tbody>
      </table>
      <footer className="pr-5 py-0.5 bg-gray-50 border border-gray-300">
        <nav className="px-4 flex items-center justify-between sm:px-0">
          <div className="pl-6 sm:block flex-1 flex">
            <p className="text-sm text-gray-700 font-medium mx-1">
              {payoutGroups.length > 1
                ? `Showing ${startIndex + 1} to ${endIndex + 1} of ${
                    payoutGroups.length
                  } payouts`
                : 'Showing all payouts'}
            </p>
          </div>
          <div className="-mt-px w-0 flex-1 flex justify-end">
            <ReactPaginate
              previousLabel={<ChevronLeftIcon className="w-5" />}
              previousClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              nextLabel={<ChevronRightIcon className="w-5" />}
              nextClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              breakLabel={'...'}
              initialPage={pageNumber}
              breakClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
              pageClassName="focus:outline-none border-b-2 border-transparent py-4 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              activeLinkClassName="focus:outline-none outline-none text-blue-500 border-blue-500"
              pageLinkClassName="px-4 text-sm"
              pageCount={Math.ceil(payoutGroups.length / PAGE_SIZE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={PAGE_SIZE}
              onPageChange={pageTo => {
                setPageNumber(pageTo.selected);
              }}
              containerClassName={'pagination flex'}
              activeClassName={'text-blue-500 border-blue-500 outline-none'}
            />
          </div>
        </nav>
      </footer>
      {showDwollaCustomerModal && (
        <DwollaCustomerModal
          showCustomerModal={() => setShowDwollaCustomerModal(false)}
          updateOnSuccess={() => updateValues()}
          customerId={activeOrganization?.data()?.dwolla?.dwollaCustomer}
          customerData={customerData}
          organization={activeOrganization}
        />
      )}
      {dwollaAccountSettingsModal && (
        <TailwindModal
          header={'Account Settings'}
          close={() => setDwollaAccountSettingsModal(false)}
          noExitOutsideModal
          widthPct={30}
        >
          <div className="flex flex-col mr-4">
            <div className="font-normal text-sm text-gray-700 mb-8">
              Update your account information by selecting an option below.
            </div>
            <button
              className={`flex justify-center w-100 rounded-md font-semibold bg-blue-500 bg-opacity-25 text-blue-600 text-sm items-center py-2 px-2`}
              type="button"
              id="update-personal-info"
              onClick={() => {
                if (activeOrganization?.data()?.dwolla?.dwollaCustomer) {
                  setInfoLoading(true);
                  void getCustomer();
                }
              }}
            >
              {infoLoading && (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
              )}
              View personal information
            </button>
            <button
              className={`flex justify-center mt-2 w-100 rounded-md font-semibold border border-blue-500 text-blue-600 text-sm items-center py-2 px-2`}
              type="button"
              onClick={() => {
                setAccountsLoading(true);
                void getBankAccounts();
              }}
            >
              {accountsLoading && (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
              )}
              {'Manage bank account'}
            </button>
          </div>
        </TailwindModal>
      )}
      {manageAccounts && (
        <ManageDwollaAccounts
          closeModal={() => setManageAccounts(false)}
          bankAccounts={bankAccounts}
          isPendingTransaction={isPending}
          activeOrganization={activeOrganization}
          showToastOnVerifyMicroDeposits={() => {
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Account has been successfuly verified.'
              })
            );
          }}
        />
      )}
      {openDownloadModal && customerId && (
        <PayoutsDownloadModal
          close={() => setOpenDownloadModal(false)}
          customerId={customerId}
        />
      )}
    </div>
  );
}

function PayoutsDownloadModal({
  close,
  customerId
}: {
  close: Function;
  customerId: string;
}) {
  const [loadingPayouts, setLoadingPayouts] = useState(false);
  const [period, setPeriod] = useState('lastMonth');
  const [error, setError] = useState('');

  const downloadDwollaPayouts = async () => {
    setLoadingPayouts(true);
    setError('');
    try {
      const { url } = await api.post(`payments/download-dwolla-payouts`, {
        period,
        customerId
      });
      if (url) window.open(url);
      else setError('There are no relevant payouts in the given period');
    } catch (err) {
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'An error occurred when downloading payout',
        {
          customerId,
          period
        }
      );
      setError('There was an error downloading your payouts.');
    }

    setLoadingPayouts(false);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-1 text-center">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Download Payouts
              </h3>
              <div className="my-5">
                <div className="col-span-6 sm:col-span-3">
                  <div className="mb-2 text-left block text-sm font-medium leading-5 text-gray-700">
                    Download payouts from
                  </div>
                  <select
                    value={period}
                    onChange={e => setPeriod(e.target.value)}
                    id="country"
                    className="mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  >
                    <option value="currMonth">Current month</option>
                    <option value="lastMonth">Previous month</option>
                    <option value="currYear">Current year</option>
                    <option value="lastYear">Previous year</option>
                    <option value="all">Download all</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {error && (
            <div className="my-5">
              <div className="text-red-400 text-sm">{error}</div>
            </div>
          )}

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense mb-2">
            <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
              <button
                type="button"
                className={`text-white shadow inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                onClick={() => downloadDwollaPayouts()}
                disabled={loadingPayouts}
              >
                <span className="flex">
                  {loadingPayouts && (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                  )}
                  Download
                </span>
              </button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={() => close()}
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
