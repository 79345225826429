import {
  Customer,
  CustomerOrganization,
  EFirebaseContext,
  EInvoice,
  ESnapshotExists,
  exists
} from '../types';

export const getLastInvoiceForCustomerOrganization = async (
  firebaseContext: EFirebaseContext,
  customerOrganization: ESnapshotExists<CustomerOrganization> | null | undefined
): Promise<ESnapshotExists<EInvoice> | null> => {
  if (!exists(customerOrganization)) {
    return null;
  }
  const { client: advertiserOrganization, organization: newspaper } =
    customerOrganization.data();
  const invoices = await firebaseContext
    .invoicesRef()
    .where('advertiserOrganization', '==', advertiserOrganization)
    .where('organization', '==', newspaper)
    .orderBy('created', 'desc')
    .limit(1)
    .get();
  if (invoices.empty) {
    return null;
  }
  return invoices.docs[0];
};

export const getLastInvoiceForCustomer = async (
  firebaseContext: EFirebaseContext,
  customer: ESnapshotExists<Customer> | null | undefined
): Promise<ESnapshotExists<EInvoice> | null> => {
  if (!exists(customer)) {
    return null;
  }
  const { user: advertiser, organization: newspaper } = customer.data();
  const invoices = await firebaseContext
    .invoicesRef()
    .where('advertiser', '==', advertiser)
    .where('organization', '==', newspaper)
    .orderBy('created', 'desc')
    .limit(1)
    .get();
  if (invoices.empty) {
    return null;
  }
  return invoices.docs[0];
};
