import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import React from 'react';

import { InvoiceStatus, NoticeStatusType } from 'lib/enums';
import { hasPaymentOrPartialRefund } from 'lib/utils/invoices';
import { getNoticeType } from 'lib/helpers';
import {
  ESnapshotExists,
  EOrganization,
  EInvoice,
  ENotice,
  exists
} from 'lib/types';

/**
 * Determines whether or not we should show the up front payment banner in the notice detail page
 * @param {ESnapshotExists<ENotice>} notice notice we are rendering on the notice details screen
 * @param {ESnapshotExists<EOrganization>} newspaper publisher that is publishing the notice
 * @param {ESnapshotExists<EInvoice> | undefined} invoice invoice associated with the notice (or undefined if it doesn't exist yet)
 * @param {boolean} isInvoicedOutsideColumn whether or not this notice should be invoiced outside Column
 * @returns {boolean} whether or not we should show the up front payment banner
 */
export const getShouldShowUpFrontPaymentBanner = (
  notice: ESnapshotExists<ENotice>,
  newspaper: ESnapshotExists<EOrganization>,
  invoice: ESnapshotExists<EInvoice> | undefined,
  isInvoicedOutsideColumn: boolean | undefined
) => {
  // do not show if invoice hasn't been created or if notice is invoiced outside Column
  if (!exists(invoice) || isInvoicedOutsideColumn === undefined) return false;

  if (isInvoicedOutsideColumn) return false;

  // don't run if notice has been cancelled
  if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
    return false;

  // don't run if the invoice has already been paid
  // TODO: Should this include InvoiceStatus.initiated.value? https://columnpbc.atlassian.net/browse/IT-4424
  if (
    [
      InvoiceStatus.paid.value,
      InvoiceStatus.partially_refunded.value,
      InvoiceStatus.unpayable.value
    ].includes(invoice.data().status)
  )
    return false;

  if (notice.data().requireUpfrontPayment !== undefined) {
    return (
      notice.data().requireUpfrontPayment ||
      notice.data().requireUpfrontPaymentModifiedByPublisher
    );
  }

  const noticeType = getNoticeType(notice, newspaper);

  // short circuit if we are specifically setting the property
  if (noticeType?.upFrontPayment !== undefined)
    return noticeType.upFrontPayment;

  if (newspaper.data().requireUpfrontPayment)
    return newspaper.data().requireUpfrontPayment;

  if (hasPaymentOrPartialRefund(invoice)) return false;

  return false;
};

type NoticeDetailUpFrontPaymentBannerProps = {
  setShowSendReminderModal: (show: boolean) => void;
  invoiceOverdue: boolean;
  isPublisher: boolean;
  invoiceSnap: ESnapshotExists<EInvoice> | undefined;
  noticeSnap: ESnapshotExists<ENotice> | null;
};

/**
 * Banner at the very top of the notice detail page that shows quick actions
 */
export default function NoticeDetailUpFrontPaymentBanner({
  setShowSendReminderModal,
  invoiceOverdue,
  isPublisher,
  invoiceSnap,
  noticeSnap
}: NoticeDetailUpFrontPaymentBannerProps) {
  return (
    <div
      className={classNames(
        noticeSnap?.data().requireUpfrontPayment
          ? 'bg-primary-100'
          : 'bg-white border border-column-gray-150',
        'relative mb-6 rounded-md'
      )}
    >
      <div className="flex justify-between items-center max-w-screen-xl mx-auto h-20 md:h-14 text-sm text-gray-800">
        <div className="flex items-center">
          <div className="w-4 md:w-2 h-20 md:h-14 mr-4" />
          <div className="flex items-center">
            {isPublisher && (
              <div>
                <Switch
                  checked={!!noticeSnap?.data().requireUpfrontPayment}
                  onChange={async () => {
                    await noticeSnap?.ref.update({
                      requireUpfrontPayment:
                        !noticeSnap.data().requireUpfrontPayment,
                      requireUpfrontPaymentModifiedByPublisher: true
                    });
                  }}
                  className={classNames(
                    noticeSnap?.data().requireUpfrontPayment
                      ? 'bg-blue-900'
                      : 'bg-gray-200',
                    'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                  style={{
                    width: '36px'
                  }}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      noticeSnap?.data().requireUpfrontPayment
                        ? 'translate-x-4'
                        : 'translate-x-0',
                      'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            )}
            <span
              className={classNames('ml-2 mr-1 font-medium text-sm leading-6', {
                'text-column-gray-500':
                  noticeSnap?.data().requireUpfrontPayment,
                'text-column-gray-400':
                  !noticeSnap?.data().requireUpfrontPayment
              })}
            >
              Upfront payment required.{' '}
              {isPublisher
                ? 'The advertiser has not yet fulfilled the invoice for this notice.'
                : invoiceOverdue
                ? 'Because your invoice is overdue, your notice might not be published.'
                : 'If you don’t pay your invoice on time, your notice might not be published.'}
            </span>
          </div>
        </div>
        {isPublisher ? (
          <div
            className="uppercase mr-6 cursor-pointer font-medium text-sm leading-6 text-primary-600 flex items-center"
            onClick={() => setShowSendReminderModal(true)}
          >
            <span>send reminder</span>
            <ArrowRightIcon className="ml-1 w-4 h-4 stroke-2" />
          </div>
        ) : (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              exists(invoiceSnap)
                ? `${window.location.origin}/invoices/${invoiceSnap.id}/pay`
                : ''
            }
            className="uppercase mx-4 cursor-pointer font-bold"
          >
            pay invoice
          </a>
        )}
      </div>
    </div>
  );
}
