import api from 'api';
import { EResponseTypes } from 'lib/types';
import { sanitize } from 'lib/helpers';
import firebase from 'firebase';
import { FileType, getVerifiedExtensionFromFileName } from 'lib/types/mime';

const getDocumentProperties = async (
  fileRef: firebase.storage.Reference,
  fileType: FileType
) => {
  const sanitizedName = sanitize(fileRef.name);

  const { verifiedExtension: originalFormat } =
    getVerifiedExtensionFromFileName(sanitizedName);

  const filePath = fileRef.fullPath;
  const resp: EResponseTypes['documents/properties'] = await api.post(
    'documents/properties',
    {
      filePath,
      fileType,
      fileFormat: originalFormat
    }
  );

  if (!resp.success) {
    throw new Error('API call to documents/properties returned false');
  }

  return resp.properties;
};

export default getDocumentProperties;
