import { AdRate, EOrganization, ERate, ERef, ESnapshotExists } from 'lib/types';
import {
  ColumnSelect,
  enumKeyToSelectInput
} from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { RateType, State } from 'lib/enums';

import { isRateShared } from 'lib/utils/rates';
import { SharedResourceAlert } from 'routes/settings/publisher/sharing/SharedResourceAlert';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import {
  isNoticeRate,
  isRateWithWeeklyPricingPeriod,
  isWordCountRangeRate,
  WordCountRangeRate
} from 'lib/types/rates';
import { PricingPeriod, PricingPeriodType } from 'lib/types/pricingPeriod';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { hideRatePricingDetailsWhenEditing } from '../../../ratesUtils';
import {
  rateSupportsDisplayAdsForPublisher,
  getDefaultBehaviorOptions,
  DEFAULT_RATE_BEHAVIOR,
  getSupportedAdTypes,
  rateIsFlat,
  getRateAmountLabel
} from '../rateUpdateFormUtils';
import { RunBasedRates } from './RunBasedRates';
import ImageRateSettings from './ImageRateSettings';
import WordCountRateSettings, {
  WORD_COUNT_RATE_TYPES
} from './WordCountRateSettings';

/**
 * Determines the value for the "How do you want to charge per run?" select
 * @param {AdRate} rate rate to get the select value for
 * @returns {string} select value
 */
const getPerRunChargeSelectValue = (rate: AdRate) => {
  if (rate.rateType === RateType.flat.value) return 'flat';
  if (rate.rateType === RateType.per_run.value) return 'per_run';
  if (isWordCountRangeRate(rate)) {
    return rate.perRun ? RateType.per_run.key : RateType.flat.key;
  }
  if (rate.runBased) return 'run-based';
  return 'non-run-based';
};

/**
 * We collapse "per run" and "flat" rates in the UI into one option
 * @param {AdRate} rate rate to get the display value for
 * @returns {number} the rate type value to display in the UI
 */
const getDisplayRateTypeValueFromRateData = (rate: AdRate): number => {
  if (rate.rateType === RateType.per_run.value) return RateType.flat.value;
  if (rate.rateType === RateType.word_count_range.value)
    return RateType.word_count.value;
  return rate.rateType;
};

/**
 * Show different options for rate types to publishers based on which state they are in
 */
const getRateTypeOptionsForPublisher = (
  publisher: ESnapshotExists<EOrganization>
) => {
  const rateOptions = [
    enumKeyToSelectInput(RateType.flat),
    enumKeyToSelectInput(RateType.folio),
    enumKeyToSelectInput(RateType.word_count),
    enumKeyToSelectInput(RateType.line),
    enumKeyToSelectInput(RateType.column_inch)
  ];
  if (publisher.data().state === State.oklahoma.value) {
    rateOptions.push(enumKeyToSelectInput(RateType.oklahoma));
  }
  if (publisher.data().state === State.iowa.value) {
    rateOptions.push(enumKeyToSelectInput(RateType.iowa_form));
  }
  return rateOptions;
};

type BasicPropertiesCardProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  setDefaultBehavior: (newDefaultBehavior: DEFAULT_RATE_BEHAVIOR) => void;
  defaultBehavior: DEFAULT_RATE_BEHAVIOR;
  onUpdateRateData: (newRateData: AdRate | ERate | WordCountRangeRate) => void;
  updatedRateData: AdRate | ERate;
  rateRef: ERef<AdRate> | ERef<ERate> | undefined;
};

export default function BasicPropertiesCard({
  activeOrganization,
  onUpdateRateData,
  setDefaultBehavior,
  updatedRateData,
  defaultBehavior,
  rateRef
}: BasicPropertiesCardProps) {
  const enablePeriodBasedOrderPricing = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_PERIOD_BASED_ORDER_PRICING
  );

  const isEditingNoticeRate = isNoticeRate(updatedRateData);

  const supportedAdTypes = getSupportedAdTypes(
    rateRef?.id,
    activeOrganization,
    updatedRateData
  );

  const defaultBehaviorOptions = getDefaultBehaviorOptions(
    activeOrganization,
    updatedRateData,
    rateRef?.id
  );

  const showPricingDetails =
    !hideRatePricingDetailsWhenEditing(updatedRateData);

  const isFlatRate = rateIsFlat(updatedRateData);
  const shouldChargeFlatRateByWordCountRange =
    isWordCountRangeRate(updatedRateData);

  const isWeeklyPricingPeriodRate =
    isRateWithWeeklyPricingPeriod(updatedRateData);
  const showRunBased =
    !(
      isFlatRate ||
      isWeeklyPricingPeriodRate ||
      shouldChargeFlatRateByWordCountRange
    ) && !hideRatePricingDetailsWhenEditing(updatedRateData);

  const showRoundOff = updatedRateData.rateType === RateType.column_inch.value;

  const runOrWeekLabel = isWeeklyPricingPeriodRate ? 'week' : 'run';

  return (
    <CardGridLayout
      header={{
        title: 'Basic Properties',
        description: 'Configure basic properties for this rate.'
      }}
    >
      {isRateShared(updatedRateData) && (
        <GridInput fullWidth>
          <SharedResourceAlert
            resourceNoun="rate"
            numOrganizations={
              (updatedRateData.publisherOrganizations?.length ?? 0) + 1
            }
          />
        </GridInput>
      )}
      <GridInput>
        <TextField
          id="rate-name"
          labelText="Rate name"
          required
          value={updatedRateData.description}
          onChange={newValue =>
            onUpdateRateData({
              ...updatedRateData,
              description: newValue
            })
          }
        />
      </GridInput>
      <GridInput>
        <ColumnSelect
          id="rate-type"
          labelText="How do you calculate this rate?"
          options={getRateTypeOptionsForPublisher(activeOrganization)}
          onChange={newValue => {
            const newRateType = RateType.by_value(parseInt(newValue, 10));
            if (!newRateType) return;
            onUpdateRateData({
              ...updatedRateData,
              rateType: newRateType.value,
              ...(newRateType === RateType.flat ? { runBased: false } : {}),
              ...(newRateType !== RateType.column_inch
                ? { roundOff: null }
                : {})
            });
          }}
          value={getDisplayRateTypeValueFromRateData(
            updatedRateData
          ).toString()}
        />
      </GridInput>
      {!isEditingNoticeRate &&
        WORD_COUNT_RATE_TYPES.map(type => type.value).includes(
          updatedRateData.rateType
        ) && (
          <WordCountRateSettings
            updatedRateData={updatedRateData}
            onUpdateRateData={onUpdateRateData}
          />
        )}
      {isEditingNoticeRate && (
        <>
          <GridInput>
            <ColumnSelect
              id="display-ad-support"
              labelText="Does this rate support display notices?"
              options={supportedAdTypes}
              onChange={newValue => {
                onUpdateRateData({
                  ...updatedRateData,
                  supportsDisplay: newValue === 'true'
                });
              }}
              value={rateSupportsDisplayAdsForPublisher(
                rateRef?.id,
                activeOrganization,
                updatedRateData
              ).toString()}
            />
          </GridInput>
          <GridInput>
            <ColumnSelect
              id="rate-default-behavior-select"
              labelText="Is this the newspaper's default rate?"
              options={defaultBehaviorOptions}
              onChange={newValue =>
                setDefaultBehavior(newValue as DEFAULT_RATE_BEHAVIOR)
              }
              value={defaultBehavior}
            />
          </GridInput>
        </>
      )}
      {showPricingDetails && (
        <>
          {enablePeriodBasedOrderPricing && (
            <GridInput fullWidth>
              <ColumnSelect
                id="pricing-period-type"
                labelText="Charge on the basis of"
                options={[
                  {
                    label: 'Number of Runs (most common)',
                    value: PricingPeriodType.DAILY
                  },
                  {
                    label:
                      'Number of weeks (Sunday - Saturday) in which at least one run is published (uncommon)',
                    value: PricingPeriodType.WEEKLY
                  }
                ]}
                value={
                  updatedRateData.pricingPeriod?.type || PricingPeriodType.DAILY
                }
                onChange={newValue => {
                  const changingToWeeklyPricingPeriod =
                    newValue === PricingPeriodType.WEEKLY;

                  const newPricingPeriod: PricingPeriod =
                    changingToWeeklyPricingPeriod
                      ? { type: PricingPeriodType.WEEKLY, startDay: 0 } // Sunday start
                      : { type: PricingPeriodType.DAILY };

                  const rateUpdates = {
                    ...(changingToWeeklyPricingPeriod && {
                      runBased: false, // Weekly pricing period rates can't be run-based
                      dayRates: null // Clear day rates when switching to weekly pricing
                    }),
                    pricingPeriod: newPricingPeriod
                  };

                  onUpdateRateData({
                    ...updatedRateData,
                    ...rateUpdates
                  });
                }}
              />
            </GridInput>
          )}
          <GridInput>
            <CurrencyTextField
              id="minimum-all-runs"
              labelText={`Minimum charge (all ${runOrWeekLabel}s)`}
              initialValue={updatedRateData.minimum}
              onChange={newValue =>
                onUpdateRateData({
                  ...updatedRateData,
                  minimum: newValue
                })
              }
            />
          </GridInput>

          <GridInput>
            <CurrencyTextField
              id="minimum-per-run"
              labelText={`Minimum charge (per ${runOrWeekLabel})`}
              initialValue={updatedRateData.runMinimumInCents}
              onChange={newValue =>
                onUpdateRateData({
                  ...updatedRateData,
                  runMinimumInCents: newValue
                })
              }
            />
          </GridInput>
          {/*
           * When dealing with a flat rate, we use a select here to switch between the `flat` and
           * `per_run` rate type.
           */}
          {(isFlatRate || shouldChargeFlatRateByWordCountRange) && (
            <GridInput>
              <ColumnSelect
                id="flat-rate-run-based"
                labelText={`Charge the flat rate per ${runOrWeekLabel}?`}
                options={[
                  {
                    value: 'flat',
                    label: 'No — charge the flat rate once'
                  },
                  {
                    value: 'per_run',
                    label: `Yes — charge the flat rate per ${runOrWeekLabel}`
                  }
                ]}
                onChange={newValue => {
                  if (isFlatRate) {
                    onUpdateRateData({
                      ...updatedRateData,
                      rateType:
                        newValue === 'per_run'
                          ? RateType.per_run.value
                          : RateType.flat.value,
                      additionalRates: null
                    });
                  }
                  if (shouldChargeFlatRateByWordCountRange) {
                    const newRateData = {
                      ...updatedRateData,
                      perRun: newValue === RateType.per_run.key
                    };
                    onUpdateRateData(newRateData);
                  }
                }}
                value={getPerRunChargeSelectValue(updatedRateData)}
              />
            </GridInput>
          )}
          {/*
           * When dealing with a variable rate that supports a runBased structure, we use this
           * select to toggle the `runBased` flag on the rate
           */}
          {showRunBased && (
            <GridInput fullWidth>
              <ColumnSelect
                id="run-based"
                labelText="Does the price vary per run?"
                options={[
                  {
                    value: 'non-run-based',
                    label: 'No — charge the same price per run'
                  },
                  {
                    value: 'run-based',
                    label: 'Yes — charge different prices per run'
                  }
                ]}
                onChange={newValue =>
                  onUpdateRateData({
                    ...updatedRateData,
                    runBased: newValue === 'run-based',
                    rate_1: updatedRateData.rate_0,
                    rate_2: updatedRateData.rate_0,
                    additionalRates: null
                  })
                }
                value={getPerRunChargeSelectValue(updatedRateData)}
              />
            </GridInput>
          )}
          {!updatedRateData.runBased &&
            !shouldChargeFlatRateByWordCountRange && (
              // fullWidth is conditional in order for components to fit
              // as closely to each other as possible within the grid
              <GridInput fullWidth={showRunBased && !showRoundOff}>
                <CurrencyTextField
                  id="fixed-rate-rate-input"
                  labelText={getRateAmountLabel(
                    updatedRateData.product,
                    updatedRateData.rateType,
                    updatedRateData.pricingPeriod?.type
                  )}
                  initialValue={updatedRateData.rate_0}
                  onChange={newValue =>
                    onUpdateRateData({
                      ...updatedRateData,
                      rate_0: newValue,
                      rate_1: newValue,
                      rate_2: newValue
                    })
                  }
                />
              </GridInput>
            )}
          {updatedRateData.runBased && (
            <RunBasedRates
              rateType={updatedRateData.rateType}
              value={updatedRateData}
              onChange={updates =>
                onUpdateRateData({ ...updatedRateData, ...updates })
              }
            />
          )}
        </>
      )}
      {showRoundOff && (
        <GridInput>
          <TextField
            id="rate-round-off"
            labelText={
              <>
                Round off (to the nearest <i>n</i> column inch)
              </>
            }
            value={updatedRateData.roundOff?.toString() ?? undefined}
            type="number"
            min={0}
            step={0.01}
            onChange={newValue => {
              onUpdateRateData({
                ...updatedRateData,
                roundOff: Number(newValue)
              });
            }}
          />
        </GridInput>
      )}
      <ImageRateSettings
        activeOrganization={activeOrganization}
        updatedRateData={updatedRateData}
        onUpdateRateData={onUpdateRateData}
      />
    </CardGridLayout>
  );
}
