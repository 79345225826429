import { Product } from '../enums';
import { AdRate, ECollectionRef, EOrganization, EQuery, ERef } from '../types';

export interface IRateService<T extends AdRate> {
  getPublisherOrgRatesQueries(org: ERef<EOrganization>): {
    ownedRatesQuery: EQuery<T>;
    allowedRatesQuery: EQuery<T>;
  };
}

export class AdRateService<T extends AdRate = AdRate> {
  constructor(private collection: ECollectionRef<T>, private adType: Product) {}

  restrictQueryToRatesOfType<T>(query: EQuery<T>) {
    return query.where('product', '==', this.adType);
  }

  getNewspaperOwnedRatesQuery(newspaper: ERef<EOrganization>) {
    const query = this.collection.where('organization', '==', newspaper);

    return this.restrictQueryToRatesOfType(query);
  }

  getNewspaperOwnedRates(newspaper: ERef<EOrganization>) {
    return this.getNewspaperOwnedRatesQuery(newspaper).get();
  }

  getPublisherOrgRatesQueries(org: ERef<EOrganization>): {
    ownedRatesQuery: EQuery<T>;
    allowedRatesQuery: EQuery<T>;
  } {
    const ownedRatesQuery = this.getNewspaperOwnedRatesQuery(org);

    let allowedRatesQuery = this.collection.where(
      'publisherOrganizations',
      'array-contains',
      org
    );

    allowedRatesQuery = this.restrictQueryToRatesOfType(allowedRatesQuery);

    return {
      ownedRatesQuery,
      allowedRatesQuery
    };
  }

  async getPublisherOrgRates(org: ERef<EOrganization>) {
    const { ownedRatesQuery, allowedRatesQuery } =
      this.getPublisherOrgRatesQueries(org);

    const ownedRates = await ownedRatesQuery.get();
    const allowedRates = await allowedRatesQuery.get();

    return [...ownedRates.docs, ...allowedRates.docs];
  }
}
