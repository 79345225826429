import { useEffect, useState } from 'react';
import {
  AttachmentDimensions,
  getJpgFileDimensions
} from 'lib/pagination/helpers';
import { FileType, getFileTypeFromFileName } from 'lib/types/mime';

export function useGetBlockDimensions(storagePath: string | undefined) {
  const [attachmentDimensions, setAttachmentDimensions] =
    useState<AttachmentDimensions>();

  useEffect(() => {
    if (storagePath) {
      const fileType = getFileTypeFromFileName(storagePath);
      if (fileType !== FileType.JPG) {
        return;
      }
      void (async () => {
        const { height, width } = await getJpgFileDimensions(storagePath);
        setAttachmentDimensions({
          height,
          width
        });
      })();
    }
  }, [storagePath]);

  return { attachmentDimensions };
}
