import React, { useState } from 'react';

import { EOrganization, ESnapshotExists, EUser } from 'lib/types';
import FullScreenModal from 'components/FullScreenModal';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { userIsSuper } from 'utils/permissions';
import { removeUndefinedFields } from 'lib/helpers';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { getFirebaseContext } from 'utils/firebase';
import { extractGlobalAffidavitControls } from './globalAffidavitControlHelpers';
import BasicSettingsCard from './BasicSettingsCard';
import ColumnManagedAffidavitsCard from './ColumnManagedAffidavitSettings';
import SuperUserSettingsCard from './SuperUserSettingsCard';

type GlobalControlsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  onClose: () => void;
  user: ESnapshotExists<EUser>;
};

export default function GlobalControls({
  activeOrganization,
  onClose,
  user
}: GlobalControlsProps) {
  const [globalAffidavitControls, setGlobalAffidavitControls] = useState(
    extractGlobalAffidavitControls(activeOrganization)
  );
  const edited = !columnObjectsAreEqual(
    globalAffidavitControls,
    extractGlobalAffidavitControls(activeOrganization)
  );

  const onSave = async () => {
    const { affidavitReconciliationSettings, ...otherProps } =
      removeUndefinedFields(globalAffidavitControls);
    const organizationModel = getModelFromSnapshot(
      OrganizationModel,
      getFirebaseContext(),
      activeOrganization
    );
    if (affidavitReconciliationSettings) {
      await organizationModel.updateAutomatedAffidavitConfiguration(
        affidavitReconciliationSettings
      );
    }
    await activeOrganization.ref.update(otherProps);
    onClose();
  };

  return (
    <FullScreenModal
      submittable={{
        buttonText: 'Save',
        onSubmit: onSave,
        disabled: !edited
      }}
      headerText="Edit Affidavit Settings"
      onClose={onClose}
      id="affidavit-settings-global-controls"
    >
      <BasicSettingsCard
        onChangeGlobalAffidavitControls={setGlobalAffidavitControls}
        globalAffidavitControls={globalAffidavitControls}
      />
      {userIsSuper(user) && (
        <SuperUserSettingsCard
          onChangeGlobalAffidavitControls={setGlobalAffidavitControls}
          globalAffidavitControls={globalAffidavitControls}
        />
      )}

      {/* Always show column managed affidavit status, but gate fields in the card */}
      <ColumnManagedAffidavitsCard
        onChangeGlobalAffidavitControls={setGlobalAffidavitControls}
        globalAffidavitControls={globalAffidavitControls}
      />
    </FullScreenModal>
  );
}
