import { useState } from 'react';
import { Product } from 'lib/enums';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { getContextKey } from 'sagas/AuthSaga';
import api from 'api';
import { EResponseTypes } from 'lib/types';
import { logError } from 'utils/logger';
import { RelatedPaperOption } from '../../lib/types/api';

export function useFetchSubdomainAssociatedPapers(
  product: Product,
  stateFilter: number | undefined
) {
  const [relatedPublisherOptions, setRelatedPublisherOptions] = useState<
    RelatedPaperOption[] | undefined
  >();
  const [autoSelectedPaper, setAutoSelectedPaper] = useState<
    RelatedPaperOption | null | undefined
  >();
  const subdomain = getContextKey();

  const applyStateFilter = (
    paperOptions: RelatedPaperOption[]
  ): RelatedPaperOption[] => {
    return stateFilter
      ? paperOptions.filter(paper => paper.state === stateFilter)
      : paperOptions;
  };

  const fetchData = async () => {
    // We only need to fetch the papers (call the api) once. Filtering by state is applied on the already fetched papers
    if (relatedPublisherOptions) {
      const filteredPapers = applyStateFilter(relatedPublisherOptions);
      setRelatedPublisherOptions(filteredPapers);
      return;
    }

    // Fetch related papers
    const { response, error }: EResponseTypes['organizations/related-papers'] =
      await api.post(`organizations/related-papers`, {
        product,
        subdomain: subdomain ?? ''
      });

    if (!response) {
      logError(error);
      return;
    }

    const { relatedPapers, subdomainPaper, publisherActivePaper } = response;

    // Apply state filter if any
    const filteredPapers = applyStateFilter(relatedPapers);

    // Sort the papers alphabetically
    const sortedRelatedPublisherOptions = filteredPapers.sort((a, b) =>
      a.label.replace('The ', '').localeCompare(b.label.replace('The ', ''))
    );

    setRelatedPublisherOptions(sortedRelatedPublisherOptions);
    setAutoSelectedPaper(publisherActivePaper || subdomainPaper);
  };

  const { isLoading } = useAsyncEffect({
    fetchData,
    dependencies: [subdomain, stateFilter]
  });

  return {
    loading: isLoading,
    relatedPublisherOptions: relatedPublisherOptions
      ? relatedPublisherOptions.map(paper => ({
          label: paper.label,
          value: paper.value
        }))
      : [],
    autoSelectedPaper: autoSelectedPaper
      ? { label: autoSelectedPaper.label, value: autoSelectedPaper.value }
      : null
  };
}
