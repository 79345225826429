import Firebase from 'EnoticeFirebase';
import { getLogger } from 'utils/logger';
import {
  ApiEndpoint,
  ApiGetEndpoint,
  HEADER_SESSION_ID_KEY
} from 'lib/types/api';
import { ApiResponseError, wrapApiError } from 'lib/types/responses';
import { isResponseOrError } from 'lib/helpers';
import { ERequestTypes, EResponseTypes } from 'lib/types';

class Api {
  getRestUrl(path: string) {
    return (Firebase.functions() as any)._url(`api/${path}`);
  }

  /**
   * When calling our API we send a Firebase ID token which has a 1-hour lifetime.
   * The Firebase Auth SDK automatically refreshes tokens when they're within 5m of
   * expiring and makes sure tokens returned from getIdTokenI() have at least 30s
   * remaining.
   *
   * However if the client and server have significant clock skew then the Firebase
   * Auth SDK can fail to detect that the token is actually expired and the server
   * will reject it.
   */
  private checkForClockSkew(resp: Response) {
    const dateHeader = resp?.headers?.get('date');
    if (!dateHeader) {
      return;
    }

    const serverTime = new Date(dateHeader).getTime();
    if (isNaN(serverTime)) {
      return;
    }

    const clientTime = new Date().getTime();
    const diff = clientTime - serverTime;
    if (Math.abs(diff) > 60000) {
      console.error(
        `Client time and server time differ by more than 1m (${diff}ms)`
      );
    }
  }

  private async getToken() {
    const userAuth = Firebase.auth().currentUser;
    if (!userAuth) throw new Error('No userauth set');

    // If the ID token has less than 5 minutes of validity remaining, we
    // forcibly refresh it. This should normally be hanled by the Firebase
    // SDK but some users were still getting expired tokens.
    const idTokenRes = await userAuth.getIdTokenResult();
    const expirationDate = new Date(idTokenRes.expirationTime);
    const timeToExpiry = expirationDate.getTime() - new Date().getTime();
    if (timeToExpiry < 5 * 60 * 1000) {
      return await userAuth.getIdToken(true);
    }

    return idTokenRes.token;
  }

  async getCommonHeaders() {
    const headers: Record<string, string> = {};
    try {
      const token = await this.getToken();
      headers.Authorization = `Bearer ${token}`;
    } catch (err) {}

    const sessionId = getLogger().getSessionId();
    if (sessionId) {
      headers[HEADER_SESSION_ID_KEY] = sessionId;
    }

    return headers;
  }

  async get(
    path: string,
    params?: Record<string, any>,
    headersOverride?: Record<string, string>
  ) {
    const headers = await this.getCommonHeaders();
    let apiUrl = this.getRestUrl(path);

    if (params) {
      const searchParams = new URLSearchParams({
        ...params
      });
      apiUrl += `?${searchParams.toString()}`;
    }

    const resp = await fetch(apiUrl, {
      headers: { ...headers, ...headersOverride }
    });

    this.checkForClockSkew(resp);
    if (resp.status !== 200)
      throw new Error(`${resp.status} error: ${await resp.text()}`);

    return await resp.json();
  }

  /**
   * @deprecated
   *
   * use api.safePost() instead
   */
  async post(path: string, data?: Record<string, any>) {
    const headers = await this.getCommonHeaders();
    headers['Content-Type'] = 'application/json';

    const url = this.getRestUrl(path);
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data || {}),
      headers
    });
    this.checkForClockSkew(resp);
    const json = await resp.json();
    if (resp.status !== 200) {
      if (isResponseOrError(json)) {
        return json;
      }
      throw new Error(`${resp.status} error: ${JSON.stringify(json)}`);
    }
    return json;
  }

  /**
   * This function wraps the post function in a safe manner
   * In case of an error response from the code, it will also extract the nested
   * error message from the ApiResponseOrError type and rewrap it for FE
   * use.
   */
  async safePost<T extends ApiEndpoint>(
    path: T,
    data?: ERequestTypes[T]
  ): Promise<EResponseTypes[T] | ApiResponseError> {
    try {
      const result = await this.post(path, data);
      // For ApiResponseOrError, this is already in the format of { response, error }
      return result;
    } catch (error) {
      let errorMessage = (error as Error).message;

      try {
        const errorResponse = JSON.parse(errorMessage.split('error: ')[1]);
        if (errorResponse.error) {
          errorMessage = errorResponse.error;
          return wrapApiError(errorMessage);
        }
        return wrapApiError('Unknown error');
      } catch (e) {
        return wrapApiError(errorMessage);
      }
    }
  }

  /**
   * This function wraps the post function in a safe manner
   * In case of an error response from the code, it will also extract the nested
   * error message from the ApiResponseOrError type and rewrap it for FE
   * use.
   */
  async safePostWithParams<T extends ApiEndpoint>(
    path: string,
    data?: ERequestTypes[T]
  ): Promise<EResponseTypes[T] | ApiResponseError> {
    const res = await this.safePost(path as T, data);
    return res;
  }

  async safeGet<T extends ApiGetEndpoint>(
    path: T,
    params?: Record<string, any>,
    headersOverride?: Record<string, string>
  ): Promise<EResponseTypes[T] | ApiResponseError> {
    try {
      const result = await this.get(path as string, params, headersOverride);
      // For ApiResponseOrError, this is already in the format of { response, error }
      return result;
    } catch (error) {
      let errorMessage = (error as Error).message;

      try {
        const errorResponse = JSON.parse(errorMessage.split('error: ')[1]);
        if (errorResponse.error) {
          errorMessage = errorResponse.error;
          return wrapApiError(errorMessage);
        }
        return wrapApiError('Unknown error');
      } catch (e) {
        return wrapApiError(errorMessage);
      }
    }
  }

  async safeGetWithParams<T extends ApiGetEndpoint>(
    path: string,
    params?: Record<string, any>,
    headersOverride?: Record<string, string>
  ): Promise<EResponseTypes[T] | ApiResponseError> {
    const res = await this.safeGet(path as T, params, headersOverride);
    return res;
  }
}

export default new Api();
