import api from 'api';
import {
  PublishingDateMetadata,
  getNoticeFiltersWhereNoticesWerePublishedAndUseAffidavits
} from 'lib/affidavits';
import { AALimitedQueryOrganization } from 'lib/affidavits/types';
import { ESnapshotExists } from 'lib/types';
import {
  MAX_ELASTIC_FACETS_LENGTH,
  SearchableNoticeRecordFacet
} from 'lib/types/searchable';
import moment from 'moment';

/**
 * Determines the dates that the currently active organization is publishing on
 * on that day. If no notices were published on that day, we return null.
 * @param activeOrganization currently active organization
 * @returns {PublishingDateMetadata[]}
 *
 * TODO: refactor to use runs schema?
 */
export const getAffidavitUploadDatesForNewspaper = async (
  activeOrganization: ESnapshotExists<AALimitedQueryOrganization>,
  limit = MAX_ELASTIC_FACETS_LENGTH
): Promise<PublishingDateMetadata[]> => {
  const facets: SearchableNoticeRecordFacet = {
    publicationtimestamps: {
      type: 'value',
      name: 'dates',
      size: limit,
      sort: { value: 'desc' }
    }
  };

  const reconciliationStartDate =
    activeOrganization.data().affidavitReconciliationSettings
      ?.reconciliationStartDate;

  const facetFilters =
    getNoticeFiltersWhereNoticesWerePublishedAndUseAffidavits(
      reconciliationStartDate,
      activeOrganization.data().iana_timezone
    );

  const {
    facets: { publicationtimestamps }
  } = await api.post('search/usernotices/facets', {
    search: '',
    showAllOrgsNotices: false,
    facets,
    filters: facetFilters
  });

  const allPublishingDays: PublishingDateMetadata[] =
    publicationtimestamps[0].data.map(
      (timestamp: { value: number; count: number }) => {
        const publishingMoment = moment.utc(timestamp.value);
        publishingMoment.set('hours', 12);
        return {
          numberOfNotices: timestamp.count,
          timestamp: timestamp.value,
          date: publishingMoment
        };
      }
    );

  const relevantPublishingDays = allPublishingDays.filter(publishingDay =>
    moment(publishingDay.date).isSameOrBefore(moment(), 'day')
  );

  return relevantPublishingDays;
};
