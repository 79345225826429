import React, { useEffect, useState } from 'react';
import CustomerDrawer from 'components/CustomerDrawer/CustomerDrawer';
import CustomerOrganizationDrawer from 'components/CustomerDrawer/CustomerOrganizationDrawer';
import { EOrganization, ESnapshotExists, exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import EditCustomerModal from 'components/CustomerDrawer/EditCustomerModal';
import EditCustomerOrganizationModal from 'components/CustomerDrawer/EditCustomerOrganizationModal';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import SettingsPage from 'routes/settings/SettingsPage';
import { NoticeRateService } from 'lib/services/noticeRateService';
import CustomerOrganizationTable from './customerOrganizationTable';
import CustomerTable from './customerTable';
import { CustomerOrganizationTableData } from './customerOrganizationTable/utils';
import { useGetCustomerTableData } from './customerTable/useGetCustomerTableData';
import { usePublisherOrgRates } from '../rates/hooks/usePublisherOrgRates';

type CustomersSettingsProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
};

const CUSTOMERS_TABLE_TAB: TabOption = {
  label: 'Customers',
  enabled: true,
  id: 'customers-settings-tab'
};

const CUSTOMER_ORGANIZATIONS_TABLE_TAB: TabOption = {
  label: 'Organizations',
  enabled: true,
  id: 'customer-organizations-settings-tab'
};

export default function CustomersSettings({
  activeOrganization
}: CustomersSettingsProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedsearchQuery = useDebounce(searchQuery, 500);
  const { customers, loading, setRefreshCustomersList } =
    useGetCustomerTableData(debouncedsearchQuery);
  const [activeTab, setActiveTab] = useState(CUSTOMERS_TABLE_TAB);
  const [customerId, setCustomerId] = useState<string | null>();
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
  const [customerOrg, setCustomerOrg] =
    useState<CustomerOrganizationTableData>();
  const [showCustomerOrgDrawer, setShowCustomerOrgDrawer] = useState(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [showEditCustomerOrgModal, setShowEditCustomerOrgModal] =
    useState(false);

  useEffect(() => {
    const customerIdFromUrl = getLocationParams().get('customerId');
    if (customerIdFromUrl) {
      setCustomerId(customerIdFromUrl);
      setShowCustomerDrawer(true);
    }
  }, [getLocationParams().get('customerId')]);

  const context = getFirebaseContext();

  /*
   * Load the customer and user snaps from Firestore anytime the
   *  target customerId changes
   */
  const customerRef =
    customerId !== null && customerId !== undefined
      ? context.customersRef().doc(customerId)
      : null;
  const customerSnap = useFirestoreSnapshot(customerRef);
  const userSnap = useFirestoreSnapshot(customerSnap?.data().user);

  const rateService = new NoticeRateService(context);

  const { orgRates } = usePublisherOrgRates(
    rateService,
    activeOrganization.ref,
    {
      includeArchived: true
    }
  );

  return (
    <>
      <SettingsPage>
        <TabGroup
          onClickTab={tab => setActiveTab(tab)}
          activeTab={activeTab}
          tabs={[CUSTOMERS_TABLE_TAB, CUSTOMER_ORGANIZATIONS_TABLE_TAB]}
          id="customers-settings-tabs"
        />
        {activeTab.label === CUSTOMERS_TABLE_TAB.label && (
          <CustomerTable
            data={customers ?? []}
            loading={loading}
            updateTableData={() => setRefreshCustomersList(true)}
            activeOrganization={activeOrganization}
            onClickRow={id => {
              setCustomerId(id);
              setShowCustomerDrawer(true);
            }}
            onClickEdit={id => {
              setCustomerId(id);
              setShowEditCustomerModal(true);
            }}
            onChangeSearch={query => setSearchQuery(query)}
            rates={orgRates}
          />
        )}
        {activeTab.label === CUSTOMER_ORGANIZATIONS_TABLE_TAB.label && (
          <CustomerOrganizationTable
            activeOrganization={activeOrganization}
            setCustomerOrg={org => setCustomerOrg(org)}
            setShowCustomerOrgDrawer={show => setShowCustomerOrgDrawer(show)}
            setShowEditCustomerOrgModal={show =>
              setShowEditCustomerOrgModal(show)
            }
          />
        )}
      </SettingsPage>
      {showCustomerDrawer && exists(customerSnap) && exists(userSnap) && (
        <CustomerDrawer
          onCloseDrawer={() => {
            setShowCustomerDrawer(false);
            setCustomerId(null);
          }}
          onEditCustomer={() => {
            setShowCustomerDrawer(false);
            setShowEditCustomerModal(true);
          }}
          setShowCustomerOrganizationDrawer={show =>
            setShowCustomerOrgDrawer(show)
          }
          setCustomerOrganization={org => setCustomerOrg(org)}
          customerSnap={customerSnap}
          userSnap={userSnap}
          activeOrganization={activeOrganization}
        />
      )}
      {showCustomerOrgDrawer && customerOrg && (
        <CustomerOrganizationDrawer
          closeDrawer={() => {
            setShowCustomerOrgDrawer(false);
          }}
          setShowCustomerDrawer={show => setShowCustomerDrawer(show)}
          setCustomerId={id => setCustomerId(id)}
          setCustomerOrg={org => setCustomerOrg(org)}
          customerOrganizationData={customerOrg}
          activeOrganization={activeOrganization}
          rates={orgRates}
          open
        />
      )}
      {showEditCustomerModal && userSnap && customerSnap && (
        <EditCustomerModal
          closeModal={edited => {
            setShowEditCustomerModal(false);
            setCustomerId(null);
            if (edited) {
              setRefreshCustomersList(true);
            }
          }}
          userSnap={userSnap}
          customerSnap={customerSnap}
          activeOrganization={activeOrganization}
          rates={orgRates}
        />
      )}
      {showEditCustomerOrgModal && customerOrg && (
        <EditCustomerOrganizationModal
          onClose={() => {
            setShowEditCustomerOrgModal(false);
            setShowCustomerOrgDrawer(false);
            setCustomerOrg(undefined);
          }}
          customerOrganizationData={customerOrg}
          activeOrganization={activeOrganization}
          rates={orgRates}
        />
      )}
    </>
  );
}
