import { isOrderRate, OrderRate } from '../../types/rates';
import { FilingTypeVisibility } from '../../enums';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import {
  BadRequestError,
  ColumnError,
  NotFoundError,
  wrapErrorAsColumnError
} from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { ESnapshotExists } from '../../types';
import { OrderFilingType } from '../../types/filingType';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import {
  findLayoutById,
  getDefaultLayoutsByFilingType
} from '../../orders/layouts';
import { Layout } from '../../types/layout';
import { Ad } from '../../types/ad';

/**
 * Sort by:
 * 1) Default layouts before display layouts
 * 2) Number of columns, smallest number of columns first
 * 3) Number of photos, smallest number of photos first
 */
const compareLayouts = (a: Layout, b: Layout) => {
  if (a.tag !== b.tag) {
    if (a.tag === 'default') {
      return -1;
    }
    if (b.tag === 'default') {
      return 1;
    }
  }
  if (a.columns !== b.columns) {
    return a.columns - b.columns;
  }

  if (a.photos !== b.photos) {
    return a.photos - b.photos;
  }

  return 0;
};

function populateLayoutsForFilingType(
  filingType: OrderFilingType
): Layout[] | null {
  const { supportedLayoutIds } = filingType;
  const hasSupportedLayouts =
    supportedLayoutIds && supportedLayoutIds?.length > 0;

  if (hasSupportedLayouts) {
    const populatedLayouts = supportedLayoutIds
      .map(findLayoutById)
      .filter((layout): layout is Layout => !!layout);

    if (populatedLayouts.length > 0) {
      return populatedLayouts;
    }
  }

  return null;
}
export class FilingTypeModel extends SnapshotModel<
  OrderFilingType,
  typeof Collections.filingTypes
> {
  get type() {
    return Collections.filingTypes;
  }

  public async getRate(): Promise<
    ResponseOrError<ESnapshotExists<OrderRate>, ColumnError>
  > {
    const { response: rateSnap, error: rateError } = await safeGetOrThrow(
      this.modelData.rate
    );
    if (rateError) {
      return wrapErrorAsColumnError(rateError, NotFoundError);
    }

    const rate = rateSnap.data();

    if (!isOrderRate(rate)) {
      return wrapError(
        new BadRequestError('Received rate inapplicable to order')
      );
    }

    return wrapSuccess(rateSnap as ESnapshotExists<OrderRate>);
  }

  get initialPublicationDateCount() {
    const initialPublicationDateCount =
      this.modelData.publicationDateSettings?.initialPublicationDates ||
      this.modelData.publicationDateSettings?.minimumPublicationDates ||
      1;

    return initialPublicationDateCount;
  }

  getSortedSupportedLayouts(adData: Partial<Ad>): Layout[] {
    const populatedLayouts = populateLayoutsForFilingType(this.modelData);

    const supportedLayouts =
      populatedLayouts || getDefaultLayoutsByFilingType(this.modelData, adData);

    return supportedLayouts.sort(compareLayouts);
  }

  public isVisibleToUser(isUserPublisher: boolean) {
    const availableVisibilities = [FilingTypeVisibility.all_users.value];

    if (isUserPublisher) {
      availableVisibilities.push(FilingTypeVisibility.publisher_only.value);
    }

    return availableVisibilities.includes(
      this.modelData.visibility || FilingTypeVisibility.disabled.value
    );
  }
}
