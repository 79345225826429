import React, { useMemo, useState } from 'react';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import { OrderModel } from 'lib/model/objects/orderModel';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import PreviewTab from './PreviewTab';
import OrderActivityLog from './OrderActivityLog';

type OrderDetailDrawerTabsProps = {
  order: OrderModel;
};

const ACTIVITY_TAB: TabOption = {
  label: 'Activity',
  enabled: true,
  id: 'activity-tab'
};
const PREVIEW_TAB: TabOption = {
  label: 'Preview',
  enabled: true,
  id: 'preview-tab'
};

export default function OrderDetailDrawerTabs({
  order
}: OrderDetailDrawerTabsProps) {
  const [activeTab, setActiveTab] = useState(ACTIVITY_TAB);
  const showOrderDetailDrawerPreviewTab = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_PREVIEW_TAB_ON_ORDER_DETAIL_DRAWER
  );
  const tabs = useMemo(() => {
    const tabs = [];
    if (showOrderDetailDrawerPreviewTab) {
      tabs.push(PREVIEW_TAB);
      setActiveTab(PREVIEW_TAB);
    }
    tabs.push(ACTIVITY_TAB);
    return tabs;
  }, [showOrderDetailDrawerPreviewTab]);

  return (
    <div className="h-full flex flex-col">
      <TabGroup
        onClickTab={setActiveTab}
        activeTab={activeTab}
        tabs={tabs}
        id="order-detail-drawer-tabs"
      />
      <div className="flex-1 flex flex-col">
        {activeTab.id === 'activity-tab' && <OrderActivityLog order={order} />}
        {activeTab.id === 'preview-tab' && <PreviewTab order={order} />}
      </div>
    </div>
  );
}
