import Papa from 'papaparse';

export const csvToHtml = async (file: File) => {
  const rows: string[][] = await new Promise(resolve => {
    Papa.parse<string[]>(file, {
      complete: results => resolve(results.data)
    });
  });

  let parsedHtml = '<table>';

  rows.forEach(
    row => (parsedHtml += `<tr>${row.map(c => `<td>${c}</td>`).join('')}</tr>`)
  );

  parsedHtml += '</table>';

  return parsedHtml;
};
