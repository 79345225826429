import {
  EDocumentData,
  EFirebaseContext,
  ERef,
  ESnapshotExists,
  Event
} from '../types';
import { DocumentEventsSearchRequest, OrderEvent } from '../types/events';
import { getOrThrow } from '../utils/refs';
import { CollectionName } from '../constants';
import { ResponseOrError } from '../types/responses';
import { Order } from '../types/order';
import { safeAsync } from '../safeWrappers';
import { removeUndefinedFields } from '../helpers';

export const eventRefDocumentTypes = {
  advertiserOrganization: 'Advertiser Organization',
  invite: 'Invite',
  invoice: 'Invoice',
  newspaper: 'Newspaper',
  notice: 'Notice',
  publicationIssue: 'Publication Issue',
  user: 'User'
};

export type EventRefDocumentType =
  (typeof eventRefDocumentTypes)[keyof typeof eventRefDocumentTypes];

export const documentTypeToCollection = (
  documentType: EventRefDocumentType
): CollectionName => {
  switch (documentType) {
    case 'invoice':
      return 'invoices';
    case 'notice':
      return 'usernotices';
    case 'newspaper':
      return 'organizations';
    case 'user':
      return 'users';
    case 'advertiserOrganization':
      return 'organizations';
    case 'publicationIssue':
      return 'publicationIssues';
    case 'invite':
      return 'invites';
    default:
      throw new Error(`Unknown document type: ${documentType}`);
  }
};

const documentTypeToQueryProp = (
  documentType: EventRefDocumentType
): string => {
  switch (documentType) {
    case 'invoice':
      return 'data.invoice';
    case 'notice':
      return 'notice';
    case 'newspaper':
      return 'newspaper';
    case 'user':
      return 'user';
    case 'advertiserOrganization':
      return 'organization';
    case 'publicationIssue':
      return 'publicationIssue';
    case 'invite':
      return 'invite';
    default:
      throw new Error(`Unknown document type: ${documentType}`);
  }
};

export class EventService {
  constructor(private context: EFirebaseContext) {}

  public async getEventsForDocument(
    query: DocumentEventsSearchRequest
  ): Promise<ESnapshotExists<Event>[]> {
    const { documentId, documentType } = query;
    const queryProp = documentTypeToQueryProp(documentType);
    const collectionName = documentTypeToCollection(documentType);
    const ref = this.context.doc(`${collectionName}/${documentId}`);
    await getOrThrow(ref);
    const eventQuery = this.context.eventsRef().where(queryProp, '==', ref);
    const queryResults = await eventQuery.get();
    return queryResults.docs;
  }

  public async createOrderEvent<T extends OrderEvent>(
    order: ERef<Order>,
    type: T['type'],
    data: T['data']
  ): Promise<ResponseOrError<ERef<T>>> {
    return safeAsync(async () =>
      this.context.eventsRef<T>().add({
        createdAt: this.context.fieldValue().serverTimestamp(),
        order,
        type,
        data: removeUndefinedFields(data)
      } as EDocumentData<T>)
    )();
  }
}
