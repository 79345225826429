import React, { useEffect, useState } from 'react';

import { getFirebaseContext } from 'utils/firebase';
import FullScreenModal from 'components/FullScreenModal';
import {
  AffidavitTemplate,
  ESnapshotExists,
  EOrganization,
  ERef,
  exists
} from 'lib/types';
import { isColumnUser } from 'lib/helpers';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { AffidavitTemplateV1 } from 'lib/types/affidavitTemplate';
import AffidavitTemplateEditorCard from './AffidavitContentEditor';
import {
  AffidavitTemplateEditData,
  extractAffidavitTemplateData
} from './affidavitTemplateEditorHelpers';
import { htmlToStoragePath } from '../htmlHelpers';
import BasicPropertiesCard from '../BasicPropertiesCard';
import { updateTemplateDefaultStatus } from '../statusHelpers';

type AffidavitTemplateEditFormProps = {
  affidavitTemplateRef: ERef<AffidavitTemplate> | undefined;
  activeOrganization: ESnapshotExists<EOrganization>;
  affidavitTemplate: AffidavitTemplate;
  onClose: () => void;
};

export default function AffidavitTemplateEditForm({
  affidavitTemplateRef,
  activeOrganization,
  affidavitTemplate,
  onClose
}: AffidavitTemplateEditFormProps) {
  const [affidavitTemplateEditData, setAffidavitTemplateEditData] =
    useState<AffidavitTemplateEditData>();
  const [initialTemplateData, setInitialTemplateData] =
    useState<AffidavitTemplateEditData>();
  const user = useAppSelector(selectUser);

  const { value: initialAffidavitTemplateEditData } = useAsyncEffect({
    fetchData: () =>
      extractAffidavitTemplateData(
        activeOrganization,
        affidavitTemplateRef,
        affidavitTemplate
      ),
    dependencies: [affidavitTemplateRef?.id, affidavitTemplate.storagePath]
  });

  useEffect(() => {
    if (!initialAffidavitTemplateEditData) return;
    setInitialTemplateData(initialAffidavitTemplateEditData);
    setAffidavitTemplateEditData(initialAffidavitTemplateEditData);
  }, [Boolean(initialAffidavitTemplateEditData)]);

  const onSave = async () => {
    if (!affidavitTemplateEditData) return;

    const {
      name,
      publisher,
      defaultStatus,
      isColumnManaged,
      affidavitTemplateHTML
    } = affidavitTemplateEditData;

    const newStoragePath = await htmlToStoragePath(
      activeOrganization,
      affidavitTemplateHTML,
      isColumnManaged ? 'column' : 'custom'
    );

    const affidavitTemplateUpdate: AffidavitTemplateV1 = {
      version: 'v2020-01-01',
      storagePath: newStoragePath,
      publisher,
      name,
      isColumnManaged
    };

    let editedAffidavitTemplateRef: ERef<AffidavitTemplate>;

    if (affidavitTemplateRef) {
      await affidavitTemplateRef.update(affidavitTemplateUpdate);
      editedAffidavitTemplateRef = affidavitTemplateRef;
    } else {
      editedAffidavitTemplateRef = await getFirebaseContext()
        .affidavitTemplatesRef()
        .add(affidavitTemplateUpdate);
    }

    await updateTemplateDefaultStatus(
      activeOrganization,
      editedAffidavitTemplateRef,
      {
        defaultStatus,
        isColumnManaged,
        newStoragePath
      }
    );

    onClose();
  };

  const edited = !columnObjectsAreEqual(
    initialTemplateData,
    affidavitTemplateEditData
  );

  const templateExistsAndIsUnedited = !!affidavitTemplateRef && !edited;

  const disabled = !!templateExistsAndIsUnedited;

  return (
    <FullScreenModal
      headerText={
        affidavitTemplateRef
          ? 'Edit Affidavit Template'
          : 'New Affidavit Template'
      }
      submittable={{
        buttonText: 'Save',
        onSubmit: onSave,
        disabled
      }}
      onClose={onClose}
      id="edit-affidavit-template"
    >
      {affidavitTemplateEditData ? (
        <>
          <BasicPropertiesCard
            affidavitTemplateEditData={affidavitTemplateEditData}
            onChangeAffidavitTemplateEditData={setAffidavitTemplateEditData}
          />
          {exists(user) && isColumnUser(user) && (
            <AffidavitTemplateEditorCard
              onChangeAffidavitTemplateEditData={setAffidavitTemplateEditData}
              affidavitTemplateEditData={affidavitTemplateEditData}
            />
          )}
        </>
      ) : (
        <LoadingState />
      )}
    </FullScreenModal>
  );
}
