import { EOrganization, DistributeSettings } from './organization';
import { EUser } from './user';
import { ERef } from './firebase';
import { FeeSplit } from './feeSplit';
import { AffidavitTemplate } from './affidavitTemplate';
import { Product, RateType } from '../enums';
import { PricingPeriod, PricingPeriodType } from './pricingPeriod';

export type AdditionalFeeBase = {
  type: 'flat' | 'percent';
  description: string;
};

export type FlatAdditionalFee = AdditionalFeeBase & {
  type: 'flat';
  amount: number;
  /**
   * Apply the fee to each run of the notice?
   */
  perRun?: boolean;
  /**
   * Apply the fee to each copy of a mailed affidavit for the notice?
   */
  perAffidavitFee?: boolean;
  /**
   * A flag used to identify the additional fee that we use to configure "flat fee with open rate"
   * structures (combined with the `ERate.offset` property). Used purely in rate settings UI to
   * control when and how this additional fee can be managed by the create/update rate form.
   */
  isOffsetFee?: boolean;
};

export type PercentAdditionalFee = AdditionalFeeBase & {
  type: 'percent';

  /**
   * The percent value, from 0 to 100, that should be calculated from and added
   * to a notice's subtotal (see getInvoiceAmountsBreakdown for definition of subtotal)
   */
  feePercentage: number;
};

export type AdditionalFee = FlatAdditionalFee | PercentAdditionalFee;

export const isFlatAdditionalFee = (
  additionalFee: AdditionalFee
): additionalFee is FlatAdditionalFee => {
  return additionalFee.type === 'flat';
};

export const isPercentAdditionalFee = (
  additionalFee: AdditionalFee
): additionalFee is PercentAdditionalFee => {
  return additionalFee.type === 'percent';
};

export type DayRate = {
  day: number; // dayEnum
  rate: number;
};

export type AdRate = {
  product: Product;

  /** Rate name */
  description: string;

  /**
   * Column's base convenience fee. Set to 10, 4, or 0 in almost
   * all cases but could be any number.
   */
  enotice_fee_pct: number | null;

  rateType: number;

  pricingPeriod?: PricingPeriod;

  archived: boolean;

  /** Round off to the nearest n column inch. null if rate isn't based on column-inch. */
  roundOff: number | null;

  /** The primary publisher organization that owns this rate */
  organization: ERef<EOrganization>;

  /** Other publishers that share this rate */
  publisherOrganizations?: ERef<EOrganization>[];

  minimum: number;

  runBased?: boolean;

  /**
   * The minimum amount to charge for a run of an ad. After this threshold is met, the rate is used.
   */
  runMinimumInCents?: number;

  // TODO: comment why we need all these even if not runBased
  rate_0: number;
  rate_1: number;
  rate_2: number;
  additionalRates?: { [key: string]: number } | null;

  /**
   * Added in IT-4475 to support multiple additional fees per rate.  The intent is to replace other
   * fee properties on the rate object with this array.
   */
  additionalFees?: AdditionalFee[];

  /** amount to charge per bold word. cannot be set if line_with_bold_words or flatBoldPricing is set. */
  bold_words?: number;

  /** amount to charge per line containing bold words. cannot be set if bold_words or flatBoldPricing is set. */
  line_with_bold_words?: number;

  /** flat amount to charge on top of the notice if there is bold in that notice. cannot be set if bold_words or line_with_bold_words is set. */
  flatBoldPricing?: number;

  /**
   * Values used to price images.
   */
  images?: {
    /**
     * The number of lines which add up to one vertical inch.
     *
     * When linesPerInch is zero (0), images are excluded from liner pricing.
     *
     * Example: if a liner ad costs $1.00/line and this is set to 5.2 then a 2.0" image would
     * cost $1.00 x 5.2 x 2.0 = $10.40
     */
    linesPerInch?: number;

    flatRate?: number;
  };

  /**
   * The number of cents for a flat rate offset fee.  The run will cost this much if measurement units <= offset.
   * Overages will be calculated at measurement units * rate.
   * TODO: Migrate all isOffset additional fees to offsetFlatRateInCents
   */
  offsetFlatRateInCents?: number | null;

  /**
   * Upper limit of measurement units (e.g. column inches) to apply the offset flat rate to. Standard pricing
   * applies once this threshold is passed.
   */
  offset?: number | null;

  folioSize?: number;
};

export type ERate = AdRate & {
  code: number;

  /**
   * An additional fee (added to enotice_fee_pct) charged by Column
   * if we do 'full-service' placement for a particular notice.
   */
  columnRepFeePct?: number;

  isGovernment: boolean;

  /** Specific advertisers associated with this rate */
  filers?: ERef<EUser>[];

  /** Specific advertiser organizations associated with this rate */
  organizations?: ERef<EOrganization>[];
  /**
   * The convenienceFeeSplit property is used to define how much of the convenience fee (usually paid, in absence of a feeSplit,
   * by the advertiser) is instead to be paid by the publisher or is waived. A 'full_waiver' convenienceFeeSplit
   * eliminates the obligation by any party to pay for the convenience fee, entirely.
   */
  convenienceFeeSplit?: FeeSplit;
  convenienceFeeCap?: number;

  /**
   * @deprecated
   * TODO: Remove this in favor of additionalFees after we migrate all rates
   * Also requires updating the FE rate creation form to use additionalFees
   * */
  additionalFee?: AdditionalFee | null;

  /**
   * @deprecated
   * TODO: Remove this in favor of prop in AdditionalFee after we migrate all rates
   * May also require surfacing this boolean in the FE rate creation form
   */
  perAffidavitFee?: boolean;

  supportsDisplay?: boolean;
  /**
   * @deprecated Historically, we have used this field to assign an affidavit
   * template, by linking directly to it's storage path. This is now handled
   * by the defaultAffidavitTemplate field.
   */
  customAffidavit?: string;
  /**
   * The affidavit template we want to use for this rate.
   */
  defaultAffidavitTemplate?: ERef<AffidavitTemplate>;
  distributeEnoticeFee?: DistributeSettings | null;
  autoInvoice?: boolean;
  requireUpfrontPayment?: boolean;
  dayRates?: DayRate[] | null;
  noticeType?: number;

  finalLineItemPricing?: boolean;
  hidePreview?: boolean;

  /**
   * @deprecated
   * Only allow for this rate to be run on specific days
   */
  allowedDays?: number[];

  /**
   * @property {string} bulkDownloadHeading HTML string that appears above a section of notices
   * with that particular rate in the bulk IDML file
   */
  bulkDownloadHeading?: string;

  /**
   * @property {string} bulkDownloadSortOrder number that controls what order the rate shows up in
   * the bulk download indesign file
   */
  bulkDownloadSortOrder?: number;

  /**
   * @property {string} multiPageAffidavits whether or not affidavits for this rate code can extend to
   * two pages
   */
  multiPageAffidavits?: boolean;
};

export type OrderRate = AdRate & {
  colorFees?: {
    flatFee?: number;
    backgroundColorFee?: number;
    borderColorFee?: number;
    textColorFee?: number;
  };
};

export type WordCountRangeRate = AdRate & {
  rateType: 15;
  wordCountRangePricing: { upTo: number | null; rate: number }[];
  perRun: boolean;
};

export const isWordCountRangeRate = (
  rate: AdRate
): rate is WordCountRangeRate =>
  rate.rateType === RateType.word_count_range.value;

export const isNoticeRate = (rate: AdRate): rate is ERate =>
  rate.product === Product.Notice;

export const isOrderRate = (rate: AdRate): rate is OrderRate =>
  !isNoticeRate(rate);

export const isRateWithWeeklyPricingPeriod = (rate: AdRate) =>
  rate.pricingPeriod?.type === PricingPeriodType.WEEKLY;

export const isRateWithDailyPricingPeriod = (rate: AdRate) => {
  return (
    !rate.pricingPeriod?.type ||
    rate.pricingPeriod?.type === PricingPeriodType.DAILY
  );
};
