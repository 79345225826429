import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { ColumnService } from 'lib/services/directory';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { getStateLabelByValue } from 'lib/utils/states';
import { logAndCaptureException } from 'utils';

export type PublicationCardData = {
  status: string;
  newspaper: ESnapshotExists<EOrganization>;
  publisherName: string;
  state: string | null;
  publicationCategory: string;
  adDeadline: string;
  publicationSchedule: string[];
};

export const newspaperOrderToPublicationCard = async (
  newspaperOrder: NewspaperOrderModel,
  filingTypeName: string
) => {
  const { response: newspaper, error: getNewspaperError } =
    await newspaperOrder.getNewspaper();
  if (getNewspaperError) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      getNewspaperError,
      'Error getting newspaper from newspaper order',
      {
        newspaperOrderpath: newspaperOrder.ref.path,
        service: ColumnService.ORDER_PLACEMENT
      }
    );
    throw getNewspaperError;
  }

  const { response: deadline, error: getDeadlineError } =
    await newspaperOrder.getDeadline();
  if (getDeadlineError) {
    logAndCaptureException(
      ColumnService.ORDER_PLACEMENT,
      getDeadlineError,
      'Error getting deadline from newspaper order',
      {
        newspaperOrderpath: newspaperOrder.ref.path,
        service: ColumnService.ORDER_PLACEMENT
      }
    );
    throw getDeadlineError;
  }
  const formattedDate = deadline.format('MMMM DD, YYYY h:mm A z') ?? '';
  const publicationCard: PublicationCardData = {
    status: newspaperOrder.modelData.status,
    newspaper,
    publisherName: newspaper.modelData.name,
    state: getStateLabelByValue(newspaper.modelData.state) || '',
    publicationCategory: filingTypeName || '',
    adDeadline: formattedDate,
    publicationSchedule: newspaperOrder.modelData.publishingDates
  };
  return publicationCard;
};
