import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import {
  Customer,
  CustomerOrganization,
  EOrganization,
  EQuerySnapshot,
  ESnapshot,
  ESnapshotExists,
  EUser,
  Note
} from 'lib/types';

import {
  combineCustomerNameFields,
  getCustomerOrganization
} from 'lib/notice/customer';
import classNames from 'classnames';
import { Badge } from 'lib/components/Badge';
import { CustomerOrganizationTableData } from 'routes/settings/publisher/customers/customerOrganizationTable/utils';
import LoadingState from 'components/LoadingState';
import { useFirestoreSnapshot } from 'lib/frontend/hooks/useFirestoreSnapshot';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import { DotIcon } from 'icons';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import Drawer from 'lib/components/Drawer';
import { getInvitesAssociatedWithEmail, isDefined } from 'lib/helpers';
import { InviteStatusTypeData } from 'lib/enums/InviteStatusType';
import { getFirebaseContext } from '../../utils/firebase';
import { CustomerDrawerDetailsTab } from './CustomerDrawerDetailsTab';
import { CustomerDrawerOrganizationTab } from './CustomerDrawerOrganizationsTab';
import { CustomerDrawerSettingsTab } from './CustomerDrawerSettingsTab';
import { CustomerDrawerFooter } from './CustomerDrawerFooter';
import NotesTab from './NotesTab/NotesTab';

const DETAILS_TAB = {
  label: 'Details',
  enabled: true,
  id: 'details'
};

const getCustomerDrawerTabsForUser = (
  user: ESnapshotExists<EUser> | undefined,
  notesQuery: EQuerySnapshot<Note> | null,
  hasOrganizationInvites: boolean
) => {
  const numberOfNotes =
    notesQuery?.docs.filter(doc => doc.data().status !== 'archived').length ||
    0;
  const tabs = [
    DETAILS_TAB,
    {
      label: 'Organization',
      enabled:
        !!user?.data().allowedOrganizations?.length || hasOrganizationInvites,
      id: 'organizations'
    },
    {
      label: 'Settings',
      enabled: true,
      id: 'settings'
    },
    {
      label: numberOfNotes > 0 ? `Notes (${numberOfNotes})` : 'Notes',
      enabled: true,
      id: 'notes'
    }
  ];

  return tabs;
};

type CustomerDrawerProps = {
  customerSnap: ESnapshotExists<Customer>;
  userSnap: ESnapshotExists<EUser>;
  activeOrganization: ESnapshot<EOrganization>;
  onCloseDrawer: () => void;
  onEditCustomer: () => void;
  setShowCustomerOrganizationDrawer: (show: boolean) => void;
  setCustomerOrganization: (customer?: CustomerOrganizationTableData) => void;
};

export default function CustomerDrawer({
  customerSnap,
  userSnap,
  activeOrganization,
  onCloseDrawer,
  onEditCustomer,
  setShowCustomerOrganizationDrawer,
  setCustomerOrganization
}: CustomerDrawerProps) {
  const [activeTab, setActiveTab] = useState<TabOption>(DETAILS_TAB);

  const [loadingCustomerOrganizations, setLoadingCustomerOrganizations] =
    useState(false);
  const [customerOrganizations, setCustomerOrganizations] =
    useState<(ESnapshotExists<CustomerOrganization> | null)[]>();
  const [allowedOrganizationNames, setAllowedOrganizationNames] = useState<
    (string | undefined)[]
  >([]);
  const [invitedOrganizatonNames, setInvitedOrganizatonNames] = useState<
    string[]
  >([]);

  const { hideDigitalAffidavits } = activeOrganization.data() || {};

  /*
   * Load the publisher and rates snaps from Firestore
   */
  const org = useFirestoreSnapshot(customerSnap?.data()?.organization);
  const customerLinerRate = useFirestoreSnapshot(
    customerSnap?.data().linerRate
  );
  const orgDefaultLinerRate = useFirestoreSnapshot(
    org?.data().defaultLinerRate
  );
  const defaultLinerRate = customerLinerRate ?? orgDefaultLinerRate;

  const customerDisplayRate = useFirestoreSnapshot(
    customerSnap?.data().displayRate
  );
  const orgDefaultDisplayRate = useFirestoreSnapshot(
    org?.data().defaultDisplayRate
  );
  const defaultDisplayRate = customerDisplayRate ?? orgDefaultDisplayRate;

  useEffect(() => {
    const getCustomerOrganizations = async () => {
      setLoadingCustomerOrganizations(true);
      // Fetch relevant organizations from allowedOrganizations
      const allowedOrganizations = userSnap?.data().allowedOrganizations || [];
      let organizationsAffiliatedWithNewspaper = await Promise.all(
        allowedOrganizations.map(async organization => {
          const organizationSnap = await organization.get();
          const customerOrgSnap = await getCustomerOrganization(
            getFirebaseContext(),
            organizationSnap,
            activeOrganization
          );
          return customerOrgSnap;
        })
      );
      organizationsAffiliatedWithNewspaper =
        organizationsAffiliatedWithNewspaper.filter(
          organization => organization !== null
        );
      const orgNames = await Promise.all(
        allowedOrganizations.map(async organization => {
          const organizationSnap = await organization.get();
          return organizationSnap.data()?.name;
        })
      );

      // Fetch relevant organization from pending invites
      const invites = await getInvitesAssociatedWithEmail(
        getFirebaseContext(),
        userSnap.data().email
      );
      setInvitedOrganizatonNames(
        invites
          .map(invite =>
            invite.data().status === InviteStatusTypeData.pending.value ||
            invite.data().status === InviteStatusTypeData.snoozed.value
              ? invite.data().organizationName
              : undefined
          )
          .filter(isDefined)
          .filter(x => !orgNames.includes(x))
      );

      setCustomerOrganizations(organizationsAffiliatedWithNewspaper);
      setAllowedOrganizationNames(orgNames);
      setLoadingCustomerOrganizations(false);
    };

    void getCustomerOrganizations();
  }, [safeStringify(customerSnap?.data()), safeStringify(userSnap?.data())]);

  const customerNotes = useFirestoreQueryListener(
    getFirebaseContext()
      .notesRef()
      .where('customer', '==', customerSnap.ref)
      .where('noteCreatorOrganization', '==', activeOrganization.ref),
    [customerSnap.id]
  );
  const tabs = getCustomerDrawerTabsForUser(
    userSnap,
    customerNotes,
    invitedOrganizatonNames.length > 0
  );

  const REGISTERED = 'Registered';
  const NOT_REGISTERED = 'Not registered';

  const customerName = combineCustomerNameFields(customerSnap) || '--';

  const header = (
    <div className="text-column-gray-500 font-semibold text-xl">
      {customerName}
    </div>
  );

  const subheader = (
    <>
      <span className="text-xs text-column-gray-400 font-medium">
        {userSnap?.data().email}
        <span className="mx-3">|</span>
      </span>
      <Badge status={userSnap?.data().lastSignInTime ? 'success' : 'critical'}>
        <div className="flex items-center">
          <DotIcon
            className={classNames({
              'text-column-red-700': !userSnap?.data().lastSignInTime,
              'text-column-green-600': !!userSnap?.data().lastSignInTime
            })}
          />
          <div className="pl-1">
            {userSnap?.data().lastSignInTime ? REGISTERED : NOT_REGISTERED}
          </div>
        </div>
      </Badge>
    </>
  );

  if (!customerSnap || !userSnap)
    return (
      <Drawer open onClose={onCloseDrawer} header={' '}>
        <LoadingState />
      </Drawer>
    );

  const customerRow = customerSnap?.data();

  const notesTabIsActive = activeTab.id === 'notes';

  return (
    <Drawer
      open
      onClose={onCloseDrawer}
      header={
        <div>
          {header}
          {subheader}
        </div>
      }
    >
      <TabGroup
        tabs={tabs}
        activeTab={activeTab}
        onClickTab={setActiveTab}
        id="customer-drawer-tabs"
      />

      <div className="flex-1 flex overflow-scroll w-full">
        {activeTab.label === 'Details' && (
          <CustomerDrawerDetailsTab
            userName={customerName ?? undefined}
            address={customerRow.address ?? undefined}
            addressLine2={customerRow.addressLine2 ?? undefined}
            phone={customerRow.phone ?? undefined}
            city={customerRow.city ?? undefined}
            state={
              typeof customerRow.state === 'number'
                ? customerRow.state
                : undefined
            }
            zipCode={customerRow.zipCode ?? undefined}
            internalID={customerRow.internalID ?? undefined}
            showOrganizationName={!!customerOrganizations?.length}
            organizationName={customerRow.organizationName}
            email={userSnap?.data().email}
          />
        )}
        {activeTab.label === 'Organization' && (
          <CustomerDrawerOrganizationTab
            customerOrganizations={customerOrganizations}
            loadingCustomerOrganizations={loadingCustomerOrganizations}
            setShowCustomerOrganizationDrawer={() => {
              setShowCustomerOrganizationDrawer(true);
              onCloseDrawer();
            }}
            setCustomerOrganizationToDisplay={setCustomerOrganization}
            allowedOrganizationNames={allowedOrganizationNames}
            invitedOrganizatonNames={invitedOrganizatonNames}
          />
        )}
        {activeTab.label === 'Settings' && (
          <CustomerDrawerSettingsTab
            linerRateDescription={defaultLinerRate?.data()?.description}
            displayRateDescription={defaultDisplayRate?.data()?.description}
            affidavitsBeforePayment={customerRow.enableAffidavitsBeforePayment}
            billingTerm={customerRow.billingTerm}
            hideDigitalAffidavits={hideDigitalAffidavits}
            allowAffidavitEmail={customerRow.allowAffidavitEmail}
          />
        )}
        {notesTabIsActive && (
          <NotesTab
            noteTopic={{
              customer: customerSnap.ref,
              noteType: 'customer-note'
            }}
            notes={customerNotes?.docs}
          />
        )}

        {/* Don't show the edit footer on the notes tab */}
        {!notesTabIsActive && (
          <CustomerDrawerFooter
            customerObjectSnap={customerSnap}
            onEditCustomer={onEditCustomer}
          />
        )}
      </div>
    </Drawer>
  );
}
