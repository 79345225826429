import React, { useState } from 'react';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { FilingType, isOrderFilingType } from 'lib/types/filingType';
import { getFirebaseContext } from 'utils/firebase';
import { TextField } from 'lib/components/TextField';
import { useBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { Alert } from 'lib/components/Alert';
import { SwitchControlledCard } from 'lib/components/Card/SwitchControlledCard';

type OrderLayoutFilingTypeSettingsProps<T> = {
  activeOrganization: ESnapshotExists<EOrganization>;
  updatedFilingType: T;
  onUpdateFilingType: (filingType: T) => void;
};

export default function OrderLayoutFilingTypeSettings<T extends FilingType>({
  activeOrganization,
  updatedFilingType,
  onUpdateFilingType
}: OrderLayoutFilingTypeSettingsProps<T>) {
  const { isLoading: loadingTemplates, value: availableTemplates } =
    useAsyncEffect({
      fetchData: async () => {
        const publisherTemplates = await getFirebaseContext()
          .adTemplatesRef()
          .where('organization', '==', activeOrganization.ref)
          .get();
        const sharedTemplates = await getFirebaseContext()
          .adTemplatesRef()
          .where(
            'publisherOrganizations',
            'array-contains',
            activeOrganization.ref
          )
          .get();
        const allTemplates = [
          ...publisherTemplates.docs,
          ...sharedTemplates.docs
        ];
        return allTemplates.filter(template => !template.data().archived);
      },
      dependencies: [activeOrganization.id]
    });

  const isCategoryWordCountEnabled = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_CATEGORY_WORD_COUNT
  );

  const orderFilingType = isOrderFilingType(updatedFilingType);

  const [wordCountEnabled, setWordCountEnabled] = useState(
    orderFilingType ? Boolean(updatedFilingType.maxWordCount) : false
  );

  if (!orderFilingType) {
    return <Alert id="alert" description="Incorrect type" />;
  }

  return (
    <CardGridLayout
      header={{
        title: 'Order Layout',
        description:
          'Configure how the order will be displayed to the advertiser.'
      }}
    >
      {isCategoryWordCountEnabled && (
        <SwitchControlledCard
          labelProps={{
            label: 'Define word count for this category?',
            description: `Establish the maximum number of words that will be allowed in this category.`,
            onChange: newValue => {
              setWordCountEnabled(newValue);
              if (!newValue) {
                onUpdateFilingType({ ...updatedFilingType, maxWordCount: 0 });
              }
            },
            value: wordCountEnabled
          }}
          header="Maximum word count"
        >
          <GridInput>
            <TextField
              id="maxWordCount"
              labelText="Maximum Word Count"
              value={String(updatedFilingType.maxWordCount)}
              onChange={value => {
                onUpdateFilingType({
                  ...updatedFilingType,
                  maxWordCount: Number(value)
                });
              }}
              type="number"
              required
            />
          </GridInput>
        </SwitchControlledCard>
      )}
      {loadingTemplates || !availableTemplates ? null : (
        <GridInput fullWidth>
          <ColumnSelect
            value={updatedFilingType.template?.id}
            options={availableTemplates.map(template => ({
              label: template.data().name,
              value: template.id
            }))}
            onChange={templateId => {
              const template = availableTemplates.find(
                template => template.id === templateId
              );
              if (!template) return;
              onUpdateFilingType({
                ...updatedFilingType,
                template: template.ref
              });
            }}
            id="order-layout-template-select"
            labelText="Order Layout Template"
            required
            allowUndefined
          />
        </GridInput>
      )}
    </CardGridLayout>
  );
}
