import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  ValidationMessageConfig,
  getCustomValidationMessage,
  useValidationEventListener
} from '../../helpers/inputValidation';

type UseValidationParams = {
  value?: string;
  inputRef: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >;
  errorText?: string;
  validationMessages?: ValidationMessageConfig;
  setValidityTracker?: Dispatch<SetStateAction<boolean>>;
  setShowErrors: (showErrors: boolean) => void;
  checkDependentValidations?: (value?: string) => string | null;
  required?: boolean;
};

export function useValidationChecks({
  value,
  inputRef,
  errorText,
  validationMessages,
  setValidityTracker,
  setShowErrors,
  checkDependentValidations,
  required
}: UseValidationParams) {
  const [currentValidationMessage, setCurrentValidationMessage] =
    useState<string>();
  useEffect(() => {
    setCurrentValidationMessage(
      getValidationMessage(
        inputRef,
        validationMessages,
        checkDependentValidations
      )
    );
  }, [value, Boolean(inputRef.current), validationMessages, required]);

  useEffect(() => {
    if (setValidityTracker) {
      setValidityTracker(!currentValidationMessage);
    }

    if (!currentValidationMessage && !errorText) {
      setShowErrors(false);
    }
  }, [currentValidationMessage, value, errorText]);

  const onValidationEvent = useCallback(() => {
    setShowErrors(true);
  }, [setShowErrors]);

  useValidationEventListener({ onValidationEvent, inputRef });

  return { currentValidationMessage };
}

function getValidationMessage(
  inputRef: React.MutableRefObject<
    HTMLInputElement | HTMLTextAreaElement | null
  >,
  validationMessages?: ValidationMessageConfig,
  checkDependentValidations?: (value?: string) => string | null
) {
  if (inputRef?.current && !inputRef.current.validity.valid) {
    const customValidationMessage = validationMessages
      ? getCustomValidationMessage(
          inputRef.current.validity,
          validationMessages
        )
      : null;

    const nativeValidationMessage = inputRef.current.validationMessage;

    return customValidationMessage || nativeValidationMessage;
  }

  const customValidationMessage = checkDependentValidations
    ? checkDependentValidations(inputRef?.current?.value)
    : null;

  return customValidationMessage || '';
}
