import { useState } from 'react';

import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { Product } from 'lib/enums';
import { ESnapshotExists, EOrganization, ERate, ERef, AdRate } from 'lib/types';
import { TableLayout } from 'lib/components/TableLayout';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getFirebaseContext } from 'utils/firebase';
import { NoticeRateService } from 'lib/services/noticeRateService';
import { AdRateService } from 'lib/services/adRateService';
import RateTableFilterDialog from './RateTableFilterDialog';
import GlobalRateSettings from '../globalRateSettings';
import RateUpdateForm from './rateUpdateForm';
import RatesTableRow from './RatesTableRow';
import RateDrawer from './ratesSettingsDrawer';
import {
  getNumActiveFromFilter,
  shouldShowRateInTable,
  DEFAULT_RATE_FILTER,
  isDefaultRate,
  RatesFilter
} from './ratesTableSettingsUtils';
import { usePublisherOrgRates } from '../hooks/usePublisherOrgRates';

type RatesTableTabProps = {
  adType: Product;
  activeOrganization: ESnapshotExists<EOrganization>;
  getInitialData: () => Promise<AdRate | ERate | undefined>;
};

/**
 * Wrapper component for the Rates table page.
 */
export default function RatesTableTab({
  adType,
  activeOrganization,
  getInitialData
}: RatesTableTabProps) {
  const [showGlobalRateSettings, setShowGlobalRateSettings] = useState(false);
  const [editedRateData, setEditedRateData] = useState<AdRate | ERate>();
  const [editedRateRef, setEditedRateRef] = useState<
    ERef<AdRate> | ERef<ERate>
  >();
  const [drawerRate, setDrawerRate] = useState<ESnapshotExists<AdRate>>();
  const [filter, setFilter] = useState<RatesFilter>(DEFAULT_RATE_FILTER);
  const [updatedFilter, setUpdatedFilter] = useState(filter);

  const context = getFirebaseContext();

  const rateService =
    adType === Product.Notice
      ? new NoticeRateService(context)
      : new AdRateService(context.adRatesRef(), adType);

  const { orgRates, loading: loadingData } = usePublisherOrgRates(
    rateService,
    activeOrganization.ref,
    { product: adType }
  );

  if (showGlobalRateSettings) {
    return (
      <GlobalRateSettings
        activeOrganization={activeOrganization}
        onClose={() => setShowGlobalRateSettings(false)}
      />
    );
  }

  if (editedRateData) {
    return (
      <RateUpdateForm
        activeOrganization={activeOrganization}
        closeForm={() => {
          setEditedRateData(undefined);
          setEditedRateRef(undefined);
        }}
        rateData={editedRateData}
        rateRef={editedRateRef}
      />
    );
  }

  const rates = orgRates.sort((a, b) => {
    if (
      isDefaultRate(activeOrganization, a.id) &&
      !isDefaultRate(activeOrganization, b.id)
    ) {
      return -1;
    }
    if (
      !isDefaultRate(activeOrganization, a.id) &&
      isDefaultRate(activeOrganization, b.id)
    ) {
      return 1;
    }
    return a.data().description.localeCompare(b.data().description);
  });

  return (
    <>
      <TableLayout<ESnapshotExists<AdRate>>
        filterable={{
          shouldShowTableItem: (rate, search) =>
            shouldShowRateInTable(rate, search, filter),
          additionalFilters: {
            applyFilterChanges: () => setFilter(updatedFilter),
            filterHasChanges: !columnObjectsAreEqual(filter, updatedFilter),
            numFiltersActive: getNumActiveFromFilter(filter),
            resetFilters: () => {
              setUpdatedFilter(DEFAULT_RATE_FILTER);
              setFilter(DEFAULT_RATE_FILTER);
            },
            renderDialog: () => (
              <RateTableFilterDialog
                onUpdate={setUpdatedFilter}
                value={updatedFilter}
              />
            )
          }
        }}
        creatable={{
          onCreate: async () => {
            const initialData = await getInitialData();

            setEditedRateData(initialData);
          },
          createButtonText: 'Add Rate'
        }}
        header={{
          subtitle: `Configure the cost of ${PRODUCT_TO_NAME[
            adType
          ].plural.toLocaleLowerCase()} in your paper.`,
          title: 'Rates'
        }}
        configurable={
          adType === Product.Notice
            ? {
                buttonText: 'Global Rate Settings',
                onClick: () => {
                  setDrawerRate(undefined);
                  setShowGlobalRateSettings(true);
                }
              }
            : undefined
        }
        archivable={{
          renderWarning: rateToArchive => ({
            header: `Archive Rate ${rateToArchive.data().description}`,
            body: 'Once archived, rates can no longer be recovered in the app. However, the Column team will still be able to recover your settings.'
          }),
          onArchive: async rateToArchive =>
            await rateToArchive.ref.update({ archived: true }),
          isArchiveDisabled: rate => isDefaultRate(activeOrganization, rate.id),
          disabledArchiveTooltip: "Can't delete default rates.",
          enabledArchiveTooltip: 'Archive this rate.'
        }}
        editable={{
          editTooltip: 'Edit this rate.',
          onEdit: rate => {
            setEditedRateData(rate.data());
            setEditedRateRef(rate.ref);
          }
        }}
        clickable={{
          onClick: rate => setDrawerRate(rate)
        }}
        columns={['Rate Name', 'Rate Type', 'Rate Per Run']}
        renderRow={rate => (
          <RatesTableRow
            activeOrganization={activeOrganization}
            rate={rate.data()}
            rateId={rate.id}
          />
        )}
        data={rates || []}
        loading={loadingData}
        id="rates-settings"
      />

      {drawerRate && (
        <RateDrawer
          activeOrganization={activeOrganization}
          setEditedRateData={setEditedRateData}
          setEditedRateRef={setEditedRateRef}
          closeDrawer={() => setDrawerRate(undefined)}
          drawerRate={drawerRate}
        />
      )}
    </>
  );
}
