import React from 'react';
import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { Popover } from '../Popover';

type ActionPopoverProps = {
  id: string;
  children: React.ReactNode;
  fullWidth?: boolean;
};

export function ActionsPopover({
  id,
  children,
  fullWidth
}: ActionPopoverProps) {
  return (
    <Popover
      id={id}
      activator={
        <button className="ml-8 text-column-gray-500 cursor-pointer rounded-full hover:text-column-primary-500">
          <EllipsisHorizontalCircleIcon className="w-5 h-5" />
        </button>
      }
      fullWidth={fullWidth}
      alignment="right"
    >
      {children}
    </Popover>
  );
}
