import { createSelector } from 'reselect';
import {
  selectAvailableOrganizations,
  selectIsOnSubdomain,
  selectIsPublisher,
  selectIsUserLoggedIn,
  selectOrgContext,
  selectUser
} from 'redux/auth';
import {
  selectDraftSnap,
  selectIsEditing,
  selectNoticeType,
  selectPreviousNoticeType,
  selectIsDisplayNoticeType,
  selectConfirmedHtml,
  selectRate,
  selectPostWithoutFormatting,
  selectFixedPrice,
  selectDisplayParams
} from 'redux/placement';
import {
  calculatePlacementSteps,
  doesSelectedNewspaperHaveNoticeTypes
} from 'routes/placeScroll/helpers/calculatePlacementSteps';
import { exists, ESnapshotExists, EOrganization, EUser, ERef } from 'lib/types';
import { getColumnRangeConfig } from 'lib/notice/columns';
import {
  NoticeType,
  FilingTypeVisibility,
  OccupationType,
  State
} from 'lib/enums';
import { EnumOutputItem } from 'lib/types/enums';
import { NoticeTypeItemType } from 'lib/enums/shared';
import { CustomNoticeFilingType } from 'lib/types/filingType';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { getRestrictedPublisherIds } from './helpers';

export const selectPlacementSteps = createSelector(
  [
    (_, shouldShowAccountIDInPlacement: boolean) =>
      shouldShowAccountIDInPlacement,
    selectUser,
    selectIsUserLoggedIn,
    selectIsPublisher,
    selectOrgContext,
    selectIsEditing
  ],
  (
    shouldShowAccountIDInPlacement,
    user,
    isUserLoggedIn,
    isPublisher,
    orgContext,
    isEditing
  ) => {
    return calculatePlacementSteps({
      user,
      isUserLoggedIn,
      isPublisher,
      orgContext,
      isEditing,
      showAccountInfoStep: shouldShowAccountIDInPlacement
    });
  }
);

export const selectHasNoticeTypeSelect = createSelector(
  [
    (_, newspaper?: ESnapshotExists<EOrganization>) => newspaper,
    selectOrgContext
  ],
  (newspaper, orgContext) =>
    doesSelectedNewspaperHaveNoticeTypes(newspaper, orgContext)
);

export const selectHasStateLevelNoticeTypes = createSelector(
  [
    (_, newspaper?: ESnapshotExists<EOrganization>) => newspaper,
    selectHasNoticeTypeSelect,
    selectOrgContext,
    selectIsOnSubdomain
  ],
  (newspaper, hasNoticeTypeSelect, orgContext, isOnSubdomain) => {
    if (hasNoticeTypeSelect) return false; // Prefer allowedNotices if it exists

    return (
      (isOnSubdomain &&
        !!orgContext &&
        !!orgContext.state &&
        !!State.by_value(orgContext.state)?.hasTypeform) ||
      (exists(newspaper) &&
        !!State.by_value(newspaper.data().state)?.hasTypeform)
    );
  }
);

export const selectShouldShowNoticeTypeFields = createSelector(
  [
    (_, newspaper?: ESnapshotExists<EOrganization>) => newspaper,
    selectHasNoticeTypeSelect,
    selectHasStateLevelNoticeTypes,
    selectIsEditing,
    selectIsPublisher
  ],
  (
    newspaper,
    hasNoticeTypeSelect,
    hasStateLevelNoticeTypes,
    isEditing,
    isPublisher
  ) => {
    return (
      (isPublisher || !isEditing) &&
      (hasNoticeTypeSelect || hasStateLevelNoticeTypes)
    );
  }
);

export const selectValidNoticeTypes = createSelector(
  [
    (_, newspaper?: ESnapshotExists<EOrganization>) => newspaper,
    selectHasStateLevelNoticeTypes,
    selectUser
  ],
  (newspaper, hasStateLevelNoticeTypes, user) => {
    if (!exists(newspaper)) {
      return;
    }

    // Some states like KS and CO have state-level prefillable notice types
    // These should only be shown as options if the newspaper
    // does not have custom notice types set
    if (hasStateLevelNoticeTypes) {
      return getFormattedNoticeTypesForState(newspaper.data().state);
    }

    return getValidNoticeTypesForPlacement(newspaper, user);
  }
);

export const selectCurrentlySelectedNoticeTypeId = createSelector(
  [selectNoticeType, selectPreviousNoticeType, selectIsDisplayNoticeType],
  (noticeType, previousNoticeType, isDisplayNoticeType) =>
    isDisplayNoticeType ? previousNoticeType : noticeType
);

export const selectCurrentlySelectedNoticeType = createSelector(
  [
    (_, newspaper?: ESnapshotExists<EOrganization>) => newspaper,
    selectValidNoticeTypes,
    selectCurrentlySelectedNoticeTypeId
  ],
  (newspaper, validNoticeTypes, currentlySelectedNoticeTypeId) => {
    const foundNoticeType = validNoticeTypes?.find(
      validNoticeType => validNoticeType.value === currentlySelectedNoticeTypeId
    );

    if (!foundNoticeType) {
      return;
    }

    return {
      ...foundNoticeType,
      isTypeform:
        foundNoticeType.value !== NoticeType.custom.value &&
        foundNoticeType.value !== NoticeType.display_ad.value &&
        !!foundNoticeType.typeform
    } as CustomNoticeFilingType & { isTypeform: boolean };
  }
);

export const selectColumnCountRangeConfig = createSelector(
  [
    (_, publisherOrganization?: ESnapshotExists<EOrganization>) =>
      publisherOrganization,
    selectCurrentlySelectedNoticeType,
    selectIsDisplayNoticeType
  ],
  (publisherOrganization, noticeType, isDisplayNotice) => {
    return getColumnRangeConfig({
      publisherOrganization,
      noticeType,
      isDisplayNotice
    });
  }
);

export const selectDisplayOnlyAds = createSelector(
  [(_, newspaper?: ESnapshotExists<EOrganization>) => newspaper],
  newspaper => {
    return !!newspaper?.data().displayOnlyAds;
  }
);

const DEPRECATED_TYPEFORM_KEY = 'yrNuK9';

export const selectShouldLoadTypeformForNoticeType = createSelector(
  [
    (_, newspaper?: ESnapshotExists<EOrganization>) => newspaper,
    selectCurrentlySelectedNoticeType,
    selectShouldShowNoticeTypeFields,
    selectHasStateLevelNoticeTypes,
    selectIsEditing,
    selectDraftSnap,
    selectPreviousNoticeType,
    selectConfirmedHtml
  ],
  (
    newspaper,
    selectedNoticeType,
    shouldShowNoticeTypeFields,
    hasStateLevelNoticeTypes,
    isEditing,
    draftSnap,
    previousNoticeType,
    confirmedHtml
  ) => {
    if (!selectedNoticeType || !shouldShowNoticeTypeFields || isEditing) {
      return false;
    }

    // if we are a custom value, don't go through typeform
    if (
      !hasStateLevelNoticeTypes &&
      selectedNoticeType.value === NoticeType.custom.value
    ) {
      return false;
    }

    // if we don't have typeform set, don't go through typeform
    if (!selectedNoticeType.typeform) {
      return false;
    }

    // don't show the default typeform anymore! This has been deprecated
    if (
      !hasStateLevelNoticeTypes &&
      selectedNoticeType.typeform === DEPRECATED_TYPEFORM_KEY
    ) {
      return false;
    }

    // If editing a display ad notice
    if (draftSnap?.data().noticeType === NoticeType.display_ad.value) {
      return false;
    }

    if (
      confirmedHtml &&
      String(previousNoticeType) === String(selectedNoticeType.value) &&
      !selectedNoticeType.isTypeform
    ) {
      return false;
    }

    return true;
  }
);

export const selectShouldLoadMadlibForNoticeType = createSelector(
  [
    (_, newspaper?: ESnapshotExists<EOrganization>) => newspaper,
    selectCurrentlySelectedNoticeType
  ],
  (newspaper, selectedNoticeType) => {
    return !!selectedNoticeType?.madlib;
  }
);

export const selectValidColumnCounts = createSelector(
  [
    (_, newspaper: ESnapshotExists<EOrganization> | undefined) => newspaper,
    selectCurrentlySelectedNoticeType,
    selectColumnCountRangeConfig
  ],
  (newspaper, currentlySelectedNoticeType, columnCountRangeConfig) => {
    const { minColumns, maxColumns } = columnCountRangeConfig;

    const validColumnCounts = [];

    for (let i = minColumns; i <= maxColumns; i++) {
      validColumnCounts.push(i);
    }

    const noticeTypeColumnCount = Number(
      currentlySelectedNoticeType?.defaultColumns
    );

    if (
      noticeTypeColumnCount &&
      !validColumnCounts.includes(noticeTypeColumnCount)
    ) {
      validColumnCounts.push(noticeTypeColumnCount);
    }

    return validColumnCounts.sort((a, b) => a - b);
  }
);

export const selectRestrictedPublisherIds = createSelector(
  [selectUser, selectIsPublisher, selectAvailableOrganizations],
  getRestrictedPublisherIds
);

export const getNewspaperDefaultRates = (
  newspaperSnap: ESnapshotExists<EOrganization> | undefined
) => {
  if (!newspaperSnap) {
    return [];
  }
  const { defaultLinerRate, defaultDisplayRate } = newspaperSnap.data();
  if (!defaultLinerRate || !defaultDisplayRate) {
    return [];
  }
  return [defaultLinerRate.id, defaultDisplayRate.id];
};

export const selectIsDefaultRate = createSelector(
  [
    (_, newspaper: ESnapshotExists<EOrganization> | undefined) => newspaper,
    selectRate
  ],
  (newspaper, rate) => {
    const newspaperDefaults = getNewspaperDefaultRates(newspaper);

    return newspaperDefaults.includes(rate?.id || '');
  }
);

export const selectShouldHideRateField = createSelector(
  [selectIsPublisher, selectPostWithoutFormatting, selectFixedPrice],
  (isPublisher, postWithoutFormatting, fixedPrice) => {
    // Rate field should not appear for publishers when notice submitted without formatting or Typeform/Madlib has default pricing
    return !isPublisher || postWithoutFormatting || fixedPrice;
  }
);

export const selectNoticeTitleFieldConfig = createSelector(
  [
    (_, newspaper: ESnapshotExists<EOrganization> | undefined) => newspaper,
    selectIsDisplayNoticeType,
    selectPostWithoutFormatting
  ],
  (newspaper, isDisplayNoticeType, postWithoutFormatting) => {
    const enableCustomHeaders = getBooleanFlag(
      LaunchDarklyFlags.ENABLE_CUSTOMISED_HEADERS
    );

    const enabled = Boolean(
      enableCustomHeaders &&
        !isDisplayNoticeType &&
        !postWithoutFormatting &&
        newspaper?.data().noticeHeaders?.enabled
    );

    const required = Boolean(
      enabled && newspaper?.data().noticeHeaders?.required
    );

    return { enabled, required };
  }
);

export const selectShouldHideColumnCountField = createSelector(
  [
    (_, newspaper: ESnapshotExists<EOrganization> | undefined) => newspaper,
    selectCurrentlySelectedNoticeType,
    selectIsPublisher,
    selectPostWithoutFormatting
  ],
  (newspaper, selectedNoticeType, isPublisher, postWithoutFormatting) => {
    // Hide when the notice is submitted without formatting
    return (
      postWithoutFormatting ||
      // Hide if the typeform/madlib notice type has set number of columns and user is an advertiser
      ((selectedNoticeType?.isTypeform || selectedNoticeType?.madlib) &&
        !!selectedNoticeType?.defaultColumns &&
        !isPublisher)
    );
  }
);

export const selectShowHeightWarning = createSelector(
  [
    (_, newspaper: ESnapshotExists<EOrganization> | undefined) => newspaper,
    selectDisplayParams
  ],
  (newspaper, displayParams) => {
    if (displayParams?.maxHeightExceeded) {
      return {
        showHeightWarning: true,
        heightWarningMessage:
          'Your content is taller than the max height of 215 inches.'
      };
    }

    const noticeIsTooTallForNewspaper =
      newspaper &&
      newspaper.data().noticeHeightThresholds?.maxNoticeHeight &&
      displayParams?.height &&
      displayParams?.height >
        (newspaper.data().noticeHeightThresholds?.maxNoticeHeight || 0);

    if (noticeIsTooTallForNewspaper) {
      return {
        showHeightWarning: true,
        heightWarningMessage:
          newspaper?.data().noticeHeightThresholds
            ?.noticeHeightExceededWarning ||
          `The maximum height of a notice placed in ${newspaper?.data().name} is
          ${newspaper?.data().noticeHeightThresholds?.maxNoticeHeight} inches.`
      };
    }

    return {
      showHeightWarning: false,
      heightWarningMessage: ''
    };
  }
);

function getValidNoticeTypesForPlacement(
  newspaper: ESnapshotExists<EOrganization>,
  user: ESnapshotExists<EUser> | null
): CustomNoticeFilingType[] | undefined {
  const { allowedNotices: noticeTypes } = newspaper.data();

  if (!noticeTypes) {
    return;
  }

  const isPublisher =
    !!user &&
    [
      OccupationType.publishing.value,
      OccupationType.press_association_manager.value
    ].includes(user.data().occupation);
  const newspaperIsAllowedOrg = user
    ?.data()
    .allowedOrganizations?.some(
      (org: ERef<EOrganization>) => org.id === newspaper.id
    );

  const showPublisherOnlyNoticeTypes = isPublisher && newspaperIsAllowedOrg;

  const allEnabledNoticeTypes = noticeTypes.filter(
    notice => notice.visibility !== FilingTypeVisibility.disabled.value
  );

  const customerEnabledNoticeTypes = allEnabledNoticeTypes.filter(
    notice => notice.visibility !== FilingTypeVisibility.publisher_only.value
  );

  const enabledNoticeTypes = showPublisherOnlyNoticeTypes
    ? allEnabledNoticeTypes
    : customerEnabledNoticeTypes;

  if (!enabledNoticeTypes.length) {
    return;
  }

  // Alphabetize the notice types by their label. All notice types
  // *should* have a label but because there is some messy ReTool-created
  // data from the past we default to empty string to be safe.
  return [...enabledNoticeTypes].sort((a, b) =>
    (a.label || '').localeCompare(b.label || '')
  );
}

function getFormattedNoticeTypesForState(
  state: number
): CustomNoticeFilingType[] {
  const allFormattedNoticeTypes: EnumOutputItem<NoticeTypeItemType>[] =
    NoticeType.rootItems();

  function getFormattedChildNoticeTypes(
    noticeType: EnumOutputItem<NoticeTypeItemType>
  ) {
    const childrenTypes = noticeType.children();
    const formattedChildrenTypes = childrenTypes.map(childType => ({
      ...childType,
      label: `${noticeType.label} - ${childType.label}`
    }));
    allFormattedNoticeTypes.push(...formattedChildrenTypes);
    // Recursively get sub-notice types until no more exist
    formattedChildrenTypes.forEach(getFormattedChildNoticeTypes);
  }

  allFormattedNoticeTypes.forEach(getFormattedChildNoticeTypes);

  return allFormattedNoticeTypes
    .filter(
      noticeType => noticeType.states?.includes(state) && noticeType.typeform
    )
    .sort((a, b) => a.label.localeCompare(b.label));
}
