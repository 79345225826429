import {
  safeGetModelArrayFromQuery,
  safeGetModelFromRef
} from '../model/getModel';
import { Product } from '../enums';
import {
  wrapErrorAsColumnError,
  InternalServerError,
  NotFoundError,
  BadRequestError
} from '../errors/ColumnErrors';
import { EFirebaseContext, EOrganization, EQuery, ERef } from '../types';
import { PublishingMedium } from '../enums/PublishingMedium';
import { ProductPublishingSettingModel } from '../model/objects/productPublishingSettingModel';
import {
  ResponseOrColumnError,
  wrapError,
  wrapSuccess
} from '../types/responses';
import { safeAsync } from '../safeWrappers';
import {
  DetailedProductPublishingSetting,
  ProductPublishingSetting,
  FetchOrCreateDetailedProductPublishingSettingOptions
} from '../types/publishingSetting';
import { PublishingSettingsService } from './publishingSettingsService';
import { ColumnService } from './directory';
import { FilingTypeService } from './filingTypeService';
import { getErrorReporter } from '../utils/errors';

export class ProductPublishingSettingsService {
  private ctx: EFirebaseContext;

  private publishingSettingsService: PublishingSettingsService;

  private filingTypeService: FilingTypeService;

  constructor(ctx: EFirebaseContext) {
    this.ctx = ctx;
    this.publishingSettingsService = new PublishingSettingsService(ctx);
    this.filingTypeService = new FilingTypeService(ctx);
  }

  public async fetchProductPublishingSettingArray(
    organizationRef: ERef<EOrganization>,
    product?: Product,
    publishingMedium?: PublishingMedium
  ): Promise<ResponseOrColumnError<ProductPublishingSettingModel[]>> {
    let productPublishingSettingsQuery: EQuery<ProductPublishingSetting> =
      this.ctx.organizationProductPublishingSettingsRef(organizationRef);
    if (product) {
      productPublishingSettingsQuery = productPublishingSettingsQuery.where(
        'product',
        '==',
        product
      );
    }
    if (publishingMedium) {
      productPublishingSettingsQuery = productPublishingSettingsQuery.where(
        'publishingMedium',
        '==',
        publishingMedium
      );
    }
    return safeGetModelArrayFromQuery(
      ProductPublishingSettingModel,
      this.ctx,
      productPublishingSettingsQuery
    );
  }

  /**
   * This function handles creating the full tree of objects including default values:
   *   - productPublishingSetting
   *   - publishingSetting
   *   - filingTypes
   */
  public async fetchOrCreateProductPublishingSetting(
    organizationRef: ERef<EOrganization>,
    product: Product,
    publishingMedium: PublishingMedium
  ): Promise<ResponseOrColumnError<ProductPublishingSettingModel>> {
    const logData = {
      organizationId: organizationRef.id,
      product,
      publishingMedium
    };
    const { response: productPublishingSetting, error: fetchError } =
      await this.fetchProductPublishingSettingArray(
        organizationRef,
        product,
        publishingMedium
      );
    if (fetchError) {
      return wrapError(fetchError);
    }
    if (productPublishingSetting.length === 1) {
      getErrorReporter().logInfo(
        'Found existing productPublishingSetting for organization',
        logData
      );
      return wrapSuccess(productPublishingSetting[0]);
    }
    if (productPublishingSetting.length > 1) {
      const error = new InternalServerError(
        'More than one productPublishingSetting found for organization'
      );
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.OBITS,
        error,
        'Failed to fetchOrCreateProductPublishingSetting',
        logData
      );
      return wrapError(
        new InternalServerError(
          'More than one productPublishingSetting found for organization'
        )
      );
    }

    const { response: filingTypes, error: filingTypesError } =
      await this.filingTypeService.createFilingTypesForProduct(
        product,
        organizationRef
      );
    if (filingTypesError) {
      return wrapError(filingTypesError);
    }
    const filingTypeRefs = filingTypes.map(filingType => filingType.ref);
    const { response: newPublishingSetting, error: publishingSettingError } =
      await this.publishingSettingsService.createPublishingSetting(
        {
          filingTypes: filingTypeRefs
        },
        organizationRef
      );
    if (publishingSettingError) {
      return wrapError(publishingSettingError);
    }
    const { response: newProductPublishingSetting, error: addError } =
      await safeAsync(() =>
        this.ctx.organizationProductPublishingSettingsRef(organizationRef).add({
          product,
          publishingMedium,
          publishingSetting: newPublishingSetting.ref
        })
      )();
    if (addError) {
      getErrorReporter().logAndCaptureCriticalError(
        ColumnService.OBITS,
        addError,
        'Failed to fetchOrCreateProductPublishingSetting - Could not add productPublishingSetting',
        logData
      );
      return wrapErrorAsColumnError(addError as Error, InternalServerError);
    }
    return safeGetModelFromRef(
      ProductPublishingSettingModel,
      this.ctx,
      newProductPublishingSetting
    );
  }

  /**
   * Helper method that handles retrieving and optionally creating returns the full tree of objects
   * including default values:
   *  - productPublishingSetting
   *  - publishingSetting
   *  - filingTypes
   */
  public fetchOrCreateDetailedProductPublishingSetting = async (
    organizationRef: ERef<EOrganization> | undefined,
    product: Product,
    publishingMedium: PublishingMedium | undefined,
    options: FetchOrCreateDetailedProductPublishingSettingOptions
  ): Promise<ResponseOrColumnError<DetailedProductPublishingSetting>> => {
    if (!organizationRef) {
      return wrapError(new BadRequestError('Newspaper snapshot not found'));
    }
    if (!publishingMedium) {
      return wrapError(new BadRequestError('Publishing medium not set'));
    }

    let productPublishingSetting: ProductPublishingSettingModel;
    if (options.shouldCreate) {
      const productPublishingSettingResponse =
        await this.fetchOrCreateProductPublishingSetting(
          organizationRef,
          product,
          publishingMedium
        );
      if (productPublishingSettingResponse.error) {
        return wrapError(productPublishingSettingResponse.error);
      }
      productPublishingSetting = productPublishingSettingResponse.response;
    } else {
      const productPublishingSettingResponse =
        await this.fetchProductPublishingSettingArray(
          organizationRef,
          product,
          publishingMedium
        );
      if (productPublishingSettingResponse.error) {
        return wrapError(productPublishingSettingResponse.error);
      }
      if (productPublishingSettingResponse.response.length === 0) {
        return wrapError(
          new NotFoundError('ProductPublishingSetting not found')
        );
      }
      if (productPublishingSettingResponse.response.length > 1) {
        const err = new InternalServerError(
          'Too many productPublishingSettings found!'
        );
        getErrorReporter().logAndCaptureCriticalError(
          ColumnService.OBITS,
          err,
          'fetchOrCreateDetailedProductPublishingSetting',
          {
            organizationRef: organizationRef.id,
            product,
            publishingMedium
          }
        );
        return wrapError(err);
      }
      [productPublishingSetting] = productPublishingSettingResponse.response;
    }

    const { response: publishingSetting, error: publishingSettingError } =
      await productPublishingSetting.fetchPublishingSetting();
    if (publishingSettingError) {
      return wrapError(publishingSettingError);
    }

    const { response: filingTypes, error: filingTypesError } =
      await publishingSetting.fetchFilingTypes();
    if (filingTypesError) {
      return wrapError(filingTypesError);
    }
    return wrapSuccess({
      productPublishingSetting,
      publishingSetting,
      filingTypes
    });
  };
}
