import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import Firebase from 'EnoticeFirebase';
import { ESnapshotExists, EOrganization } from 'lib/types';
import moment from 'moment';

export const DEFAULT_EDITOR_OPTIONS: editor.IStandaloneEditorConstructionOptions =
  {
    wordWrap: 'on',
    minimap: {
      enabled: false
    },
    language: 'html'
  };

/**
 * Loads the affidavit HTML from a source URL.
 */
export const loadAffidavitTemplateHTML = async (url: string) => {
  const affidavitTemplateHTMLResponse = await fetch(url);
  if (affidavitTemplateHTMLResponse.ok) {
    return affidavitTemplateHTMLResponse.text();
  }
  throw new Error(`Failed to load affidavit template HTML from ${url}`);
};

export const htmlToStoragePath = async (
  activeOrganization: ESnapshotExists<EOrganization>,
  affidavitHTML: string,
  type: 'column' | 'custom'
) => {
  // upload the new custom affidavit
  const uploadPath = `direct-ftp-upload/${
    activeOrganization.id
  }/${type}_affidavits/${moment().format()}_affidavit.html`;
  await Firebase.storage()
    .ref()
    .child(uploadPath)
    .put(new Blob([affidavitHTML], { type: 'text/html' }));

  return uploadPath;
};
