import { EOrganization, ERef } from 'lib/types';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getPublisherOrgTemplatesQueries } from 'lib/utils/templates';
import { getFirebaseContext } from 'utils/firebase';

export function usePublisherOrgTemplates(org: ERef<EOrganization>) {
  const { ownedTemplatesQuery, allowedTemplatesQuery } =
    getPublisherOrgTemplatesQueries(getFirebaseContext(), org);

  const ownedTemplates = useFirestoreQueryListener(
    ownedTemplatesQuery,
    [org.id],
    {
      message: `Error listening to owned templates for ${org.id}`
    }
  );
  const allowedTemplates = useFirestoreQueryListener(
    allowedTemplatesQuery,
    [org.id],
    {
      message: `Error listening to allowed templates for ${org.id}`
    }
  );

  const loading = allowedTemplates === null || ownedTemplates === null;
  const activeTemplates = [
    ...(ownedTemplates?.docs ?? []),
    ...(allowedTemplates?.docs ?? [])
  ].filter(template => !template.data().archived);

  return { activeTemplates, loading };
}
