import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { getFirebaseContext } from 'utils/firebase';
import { logWarn } from 'utils/logger';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { EResponseTypes } from 'lib/types';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AuthActions, { selectUserAuthIsSet } from 'redux/auth';
import { getOrderToken } from 'routes/ads/place/helpers/orderOperations';
import { getOrThrow } from 'lib/utils/refs';
import { VerificationForm } from './VerificationForm';

export default function FuneralHomeVerification() {
  const params = getLocationParams();

  const obituaryId = params.get('obituaryId');
  const accessCode = params.get('accessCode');
  const email = params.get('email');

  const dispatch = useAppDispatch();

  const userAuthIsSet = useAppSelector(selectUserAuthIsSet);
  const authorizeUser = async () => {
    if (userAuthIsSet || !accessCode || !email) {
      return;
    }

    const { token } = await getOrderToken(accessCode, email, {
      forFuneralHomeVerification: true,
      editVersion: undefined
    });
    dispatch(AuthActions.loginToken(token));
  };

  const {
    value,
    isLoading: isLoadingOrder,
    isError: fetchingOrderError
  } = useAsyncEffect({
    fetchData: async () => {
      if (!userAuthIsSet) {
        await authorizeUser();
        return null;
      }

      if (!obituaryId) {
        return null;
      }

      const obituarySnap = await getOrThrow(
        getFirebaseContext().obituariesRef().doc(obituaryId)
      );
      const orderId = obituarySnap.data().order.id;
      if (!orderId) {
        return null;
      }

      const result: EResponseTypes['orders/:orderId/filer-name'] =
        await api.get(`orders/${orderId}/filer-name`);

      if (result.error) {
        throw result.error;
      }

      return {
        orderData: result.response,
        obituarySnap
      };
    },
    dependencies: [obituaryId, userAuthIsSet, accessCode, email]
  });

  const { orderData, obituarySnap } = value || {};
  const obituaryData = obituarySnap?.data();

  if (!obituarySnap || isLoadingOrder) {
    return <LoadingSpinner />;
  }

  if (
    !orderData ||
    (orderData.isAnonymousOrder &&
      (!orderData.filerFirstName || !orderData.filerLastName)) ||
    !obituaryData?.deathVerification.funeralHomeName ||
    !obituaryData?.deceasedName ||
    !obituaryData?.deceasedBirthDate ||
    fetchingOrderError
  ) {
    logWarn(
      'Tried to access verification for an obituary missing information.',
      {
        obituaryId
      }
    );

    return (
      <Alert
        id="missing-verification-information"
        title="Missing Information"
        description="This obituary is missing information and cannot be verified at this time."
        status="error"
      />
    );
  }

  if (obituaryData.deathVerification.verifiedAt) {
    return (
      <Alert
        id="already-verified"
        title="Verified"
        description="This obituary has been verified."
        status="success"
      />
    );
  }

  return (
    <VerificationForm
      orderData={orderData}
      obituarySnap={obituarySnap}
      email={email}
    />
  );
}
