import React, {
  createContext,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import type { AriaAttributes } from 'react';
import { useKeydownListener } from '../hooks/useKeydownListener';
import useOutsideClickListener from '../hooks/useOutsideClickListener';

export type PopoverProps = {
  /** The id of the popover */
  id: string;
  /** The element that toggles the popover */
  activator: React.ReactElement;
  /** Set the popover content */
  children?: React.ReactNode;
  /** Define the type of popover for accessibility */
  popoverType?: AriaAttributes['aria-haspopup'];
  /** The text to display in the popover header */
  headerText?: string;
  /** The alignment of the popover relative to its activator
   * @default left
   */
  alignment?: 'left' | 'right';
  /** The position of the popover relative to its activator
   * @default bottom
   */
  position?: 'top' | 'bottom';
  /** Set the popover wrapper to full width */
  fullWidth?: boolean;
  /**
   * Stop the event from bubbling up to the parent
   * @default true
   */
  stopPropagation?: boolean;
};

type PopoverContextType = {
  setOpen: (open: boolean) => void;
  open: boolean;
};
export const PopoverContext = createContext<PopoverContextType>({
  setOpen: () => null,
  open: false
});

export function Popover({
  id,
  activator,
  children,
  popoverType,
  headerText,
  alignment = 'left',
  position = 'bottom',
  fullWidth = false,
  stopPropagation = true
}: PopoverProps) {
  const [open, setOpen] = useState(false);
  const popoverRef = useRef<HTMLElement>(null);
  const popoverRole = typeof popoverType === 'string' ? popoverType : 'menu';
  const closePopover = useCallback(() => setOpen(false), []);
  useKeydownListener('Escape', closePopover);
  useOutsideClickListener(popoverRef, closePopover);
  const popoverProviderValue = useMemo(
    () => ({ setOpen, open }),
    [setOpen, open]
  );

  function togglePopover(event: React.MouseEvent) {
    stopPropagation && event.stopPropagation();
    setOpen(!open);
  }

  const Activator = activator.type;
  const activatorProps = activator.props;
  const onClick = activatorProps.onClick || togglePopover;
  const activatorId = activatorProps.id || `${id}-toggle`;
  const panelId = `${id}-${popoverRole}`;

  const wrapperClasses = classNames('inline-block relative', {
    'w-full': fullWidth
  });

  const panelClasses = classNames(
    `absolute z-20 bg-white shadow-column-3 rounded-md border border-column-gray-150`,
    {
      hidden: !open,
      'w-full': fullWidth,
      'right-0': alignment === 'right'
    }
  );

  const positionStyle = {
    [position === 'top' ? 'bottom' : 'top']: 'calc(100% + .5rem)'
  };

  return (
    <PopoverContext.Provider key={id} value={popoverProviderValue}>
      <span className={wrapperClasses} ref={popoverRef}>
        <Activator
          id={activatorId}
          {...activatorProps}
          aria-expanded={open}
          aria-haspopup={popoverRole}
          aria-controls={panelId}
          onClick={onClick}
        />
        <div
          style={positionStyle}
          id={panelId}
          className={panelClasses}
          role={popoverRole}
          aria-labelledby={activatorId}
          hidden={!open}
          aria-hidden={!open}
        >
          {headerText && (
            <h2 className="uppercase font-semibold text-xs text-column-gray-300 px-4 pt-4">
              {headerText}
            </h2>
          )}
          {children}
        </div>
      </span>
    </PopoverContext.Provider>
  );
}
