import React from 'react';
import SettingsHeader from '../SettingsHeader';
import { FileDownload } from '../icons';

type DocumentDetails = {
  display_title: string;
  url: string;
};
function UserSettingsDocuments() {
  const documents = [
    {
      display_title: 'W-9 Form',
      url: 'https://enotice-production.imgix.net/custom-documents%2Fpermalink%2F3ee2.4086c-W9%20-%20May%202024.pdf'
    }
  ];

  return (
    <div className="bg-white sm:rounded-lg border border-gray-300 shadow">
      <SettingsHeader
        header="Documents"
        description="Download legal and financial documents from Column."
      ></SettingsHeader>
      <>
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th className="px-5 pr-6 py-3 bg-gray-50 uppercase text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                document
              </th>
              <th className="px-5 py-3 bg-gray-50 uppercase text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                actions
              </th>
            </tr>
          </thead>
          {documents && (
            <tbody className="divide-y divide-gray-200 rounded-b-lg">
              {documents &&
                documents.map((document: DocumentDetails, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="pl-5 pr-2 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900">
                        {document.display_title}
                      </td>
                      <td className="flex items-end px-6 py-6 float-right whitespace-no-wrap text-right text-sm leading-5 font-medium text-gray-900">
                        <a
                          href={document.url}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="cursor-pointer ml-4"
                        >
                          <div className="w-9/12">{FileDownload}</div>
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
      </>
    </div>
  );
}

export default UserSettingsDocuments;
