import moment from 'moment';
import {
  Customer,
  EFirebaseContext,
  ENotice,
  EOrganization,
  ESnapshotExists,
  EUser
} from '../../types';
import { ResponseOrError, wrapError, wrapSuccess } from '../../types/responses';
import { OrganizationStatus } from '../../enums';
import { getCustomer } from '../../notice/customer';
import { getNewspaperPublishedOnDate } from '../../utils/deadlines';
import { safeAsync, safeGetOrThrow } from '../../safeWrappers';

const MOMENT_FORMAT = 'YYYY-MM-DD';

export const validateCustomerExistsForNotice = async (
  filer: ESnapshotExists<EUser>,
  publisher: ESnapshotExists<EOrganization>,
  ctx: EFirebaseContext
) => {
  const { response: customer, error: customerError } =
    await safeAsync<ESnapshotExists<Customer> | null>(getCustomer)(
      ctx,
      filer,
      publisher
    );
  if (customerError)
    return wrapError(new Error('Error in trying to get customer for notice'));
  if (!customer) {
    wrapError(
      new Error(
        'Customer does not exist for notice. Customer must exist prior to publishing notice'
      )
    );
  }
  return wrapSuccess(true);
};

export const validateTestNoticeStatus = (
  notice: Partial<ENotice>,
  publisher: ESnapshotExists<EOrganization>
) => {
  if (
    publisher.data().organizationStatus ===
    OrganizationStatus.in_implementation.value
  ) {
    if (!notice.testNotice)
      return wrapError(
        new Error(
          'Organization is in implementation, but notice is not marked as test notice'
        )
      );
  }
  return wrapSuccess(true);
};

export const validateEditedAtAttributes = (notice: Partial<ENotice>) => {
  if (!notice.noticeStatus) {
    if (notice.editedAt || notice.lastEditedBy)
      return wrapError(
        new Error(
          'Notice is newly created, but editedAt and lastEditedBy are not null'
        )
      );
  }
  return wrapSuccess(true);
};

export const validateFooter = (
  notice: Partial<ENotice>,
  publisher: ESnapshotExists<EOrganization>
) => {
  if (publisher.data().oneRunFooter || publisher.data().footerFormatString) {
    if (notice.footerFormatString === '')
      return wrapError(
        new Error('Publisher has a footer set but none found on notice')
      );
  }
  return wrapSuccess(true);
};
export const validatePublicationDates = (
  notice: Partial<ENotice>,
  publisher: ESnapshotExists<EOrganization>
) => {
  if (!notice.publicationDates)
    return wrapError(
      new Error(
        'No publication dates found on notice. Notice must have publication dates'
      )
    );

  const normalizedDates = notice.publicationDates.map(date =>
    moment
      .utc(moment(date).format(MOMENT_FORMAT), MOMENT_FORMAT)
      .startOf('day')
      .add(12, 'hours')
      .toDate()
  );
  const { deadlines, deadlineOverrides = {} } = publisher.data();
  const invalidDates = normalizedDates.filter(date =>
    getNewspaperPublishedOnDate(deadlines, deadlineOverrides, date)
  );
  if (invalidDates.length > 0)
    wrapError(
      new Error(
        `Newspaper is not published on dates: ${invalidDates.join(', ')}`
      )
    );
  return wrapSuccess(true);
};
export const validateNoticeType = (
  notice: Partial<ENotice>,
  publisher: ESnapshotExists<EOrganization>
) => {
  const customNoticeFilingType = publisher
    .data()
    .allowedNotices?.find(nt => nt.value === notice.noticeType);
  if (!customNoticeFilingType)
    wrapError(
      new Error(
        `Could not find ${notice.noticeType} as an allowed notice type in ${publisher.ref}`
      )
    );
  return wrapSuccess(true);
};

export const validateNotice = async (
  notice: Partial<ENotice>,
  ctx: EFirebaseContext
): Promise<ResponseOrError<boolean>> => {
  const { response: publisher, error: publisherError } = await safeGetOrThrow(
    notice.newspaper
  );
  if (publisherError)
    return wrapError(
      new Error(
        'Publisher not found on notice. Notice must have a publisher set.'
      )
    );
  const { response: filer, error: filerError } = await safeGetOrThrow(
    notice.filer
  );
  if (filerError)
    return wrapError(
      new Error('Filer not found on notice. Notice must have a filer set')
    );

  const validators = [
    () => validateCustomerExistsForNotice(filer, publisher, ctx),
    () => validateTestNoticeStatus(notice, publisher),
    () => validateEditedAtAttributes(notice),
    () => validateFooter(notice, publisher),
    () => validatePublicationDates(notice, publisher),
    () => validateNoticeType(notice, publisher)
    // validate filedBy vs filer when filer has org
  ];

  for (const validateFunc of validators) {
    // eslint-disable-next-line no-await-in-loop
    const result = await validateFunc();
    if (result.error) return result; // return the wrapped error
  }
  return wrapSuccess(true);
};
