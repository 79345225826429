import { Form } from 'lib/components/Form';
import { TextField } from 'lib/components/TextField';
import {
  replaceNullWithUndefined,
  replaceUndefinedWithDelete
} from 'lib/helpers';
import {
  DEFAULT_DISPLAY_MAX_COLUMNS,
  DEFAULT_DISPLAY_MIN_COLUMNS,
  DEFAULT_LINER_MAX_COLUMNS,
  DEFAULT_LINER_MIN_COLUMNS
} from 'lib/notice/columns';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import React from 'react';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { getFirebaseContext } from 'utils/firebase';
import { userIsSuper } from 'utils/permissions';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';

const getNoticeSizeSettingsFromOrg = (
  organization: ESnapshot<EOrganization> | null
) => {
  if (!exists(organization)) {
    return null;
  }

  const {
    linerMinColumns = DEFAULT_LINER_MIN_COLUMNS,
    linerMaxColumns = DEFAULT_LINER_MAX_COLUMNS,
    displayMinColumns = DEFAULT_DISPLAY_MIN_COLUMNS,
    displayMaxColumns = DEFAULT_DISPLAY_MAX_COLUMNS,
    defaultColumns,
    noticeHeightThresholds
  } = replaceNullWithUndefined(organization.data());

  return {
    linerMinColumns,
    linerMaxColumns,
    displayMinColumns,
    displayMaxColumns,
    defaultColumns:
      defaultColumns ?? Math.min(linerMinColumns, displayMinColumns, 1),
    noticeHeightThresholds
  };
};

export function NoticeSizes() {
  const activeOrganization = useActiveOrganizationListener();

  const currentSettings = getNoticeSizeSettingsFromOrg(activeOrganization);

  const { onChange, onSubmitWrapper, disabled, formSettings, editable } =
    useEditableCard(currentSettings);

  const user = useAppSelector(selectUser);

  const disableNonSuper = !exists(user) || !userIsSuper(user) || disabled;

  if (!activeOrganization || !formSettings) {
    return null;
  }

  return (
    <Form
      id="publisher-settings-layout-notice-sizes"
      onSubmit={() =>
        onSubmitWrapper(async () => {
          await activeOrganization.ref.update(
            replaceUndefinedWithDelete(getFirebaseContext(), formSettings)
          );
          return { success: true };
        })
      }
      aria-label="Notice Sizes"
    >
      <CardGridLayout
        header={{
          title: 'Notice Sizes',
          description: 'Restrict the width and height of your notices.'
        }}
        editable={editable}
      >
        <GridInput>
          <TextField
            id="liner-minimum-columns"
            labelText="Minimum column width for liner notices"
            type="number"
            max={formSettings.linerMaxColumns}
            min={1}
            value={formSettings.linerMinColumns.toString()}
            onChange={stringValue =>
              onChange({ linerMinColumns: Number(stringValue) })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="liner-maximum-columns"
            labelText="Maximum column width for liner notices"
            type="number"
            max={12}
            min={formSettings.linerMinColumns}
            value={formSettings.linerMaxColumns.toString()}
            onChange={stringValue =>
              onChange({ linerMaxColumns: Number(stringValue) })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="display-minimum-columns"
            labelText="Minimum column width for display notices"
            type="number"
            max={formSettings.displayMaxColumns}
            min={1}
            value={formSettings.displayMinColumns.toString()}
            onChange={stringValue =>
              onChange({ displayMinColumns: Number(stringValue) })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="display-maximum-columns"
            labelText="Maximum column width for display notices"
            type="number"
            max={12}
            min={formSettings.displayMinColumns}
            value={formSettings.displayMaxColumns.toString()}
            onChange={stringValue =>
              onChange({ displayMaxColumns: Number(stringValue) })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="default-column-width"
            labelText="Default column width"
            type="number"
            max={Math.max(
              formSettings.linerMaxColumns,
              formSettings.displayMaxColumns
            )}
            min={Math.min(
              formSettings.linerMinColumns,
              formSettings.displayMinColumns
            )}
            value={formSettings.defaultColumns?.toString() ?? ''}
            onChange={stringValue =>
              onChange({ defaultColumns: Number(stringValue) })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="maximum-notice-height"
            labelText="Maximum notice length (inches)"
            type="number"
            min={0}
            value={
              formSettings.noticeHeightThresholds?.maxNoticeHeight.toString() ??
              ''
            }
            onChange={stringValue =>
              onChange({
                noticeHeightThresholds: {
                  ...formSettings.noticeHeightThresholds,
                  maxNoticeHeight: Number(stringValue)
                }
              })
            }
            disabled={disableNonSuper}
          />
        </GridInput>
      </CardGridLayout>
    </Form>
  );
}
