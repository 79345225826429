import { isRateWithWeeklyPricingPeriod } from '../../types/rates';
import { PricingFunction } from './types';

export const pricePerRun: PricingFunction = ({ dayRate, rateRecord }) => {
  if (isRateWithWeeklyPricingPeriod(rateRecord)) {
    // weekly pricingPeriod rates should not have dayRates but here we ensure we are not using dayRates for them
    return rateRecord.rate_0;
  }

  return dayRate || rateRecord.rate_0;
};
