import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { InputAdornment } from '@material-ui/core';
import { CalendarIcon } from '@heroicons/react/24/outline';

interface ColumnDatePickerProps {
  className?: string;
  format: string;
  momentFormat: string;
  placeholderText: string;
  value: Date | string | undefined;
  onChange: (date: Date | undefined) => void;

  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

export default function ColumnDatePicker({
  className,
  format,
  momentFormat,
  placeholderText,
  value,
  onChange,
  shouldDisableDate,
  disabled,
  fullWidth
}: ColumnDatePickerProps) {
  const dateValue = typeof value === 'string' ? moment(value).toDate() : value;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        data-testid="datePicker"
        label=""
        value={dateValue}
        format={format}
        className={'p-2'}
        disabled={disabled}
        TextFieldComponent={props => (
          <div
            className={classNames(
              'p-2 px-3 h-12 border rounded border-column-gray-200 flex items-center text-sm',
              {
                'bg-white cursor-pointer': !disabled,
                'bg-column-gray-25 text-column-gray-300 cursor-default':
                  disabled,
                'w-full': fullWidth
              },
              className ?? ''
            )}
            onClick={e => props.onClick!(e)}
          >
            <span
              className={classNames('mr-auto', {
                'text-column-gray-400': !value
              })}
            >
              {value ? moment(value).format(momentFormat) : placeholderText}
            </span>
            <InputAdornment position="end">
              <CalendarIcon
                className="w-5 h-5 text-column-gray-400"
                strokeWidth="2"
                stroke="currentColor"
              />
            </InputAdornment>
          </div>
        )}
        InputProps={{
          disableUnderline: true,
          className: ''
        }}
        autoOk
        onChange={newDate => {
          if (newDate) {
            onChange(new Date(newDate.toISOString()));
          } else {
            onChange(undefined);
          }
        }}
        shouldDisableDate={date => {
          if (shouldDisableDate) {
            return shouldDisableDate(date);
          }

          return false;
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
