import React, { useEffect, useState } from 'react';
import api from 'api';

import {
  ENotice,
  EInvoice,
  ESnapshotExists,
  EOrganization,
  ESnapshot,
  exists
} from 'lib/types';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { cancelOrRefundInvoiceForNotice } from 'utils/invoices';
import { logAndCaptureException } from 'utils';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { useHasPermission } from 'utils/useHasPermission';
import { Permissions } from 'lib/permissions/roles';
import ToastActions from 'redux/toast';
import {
  getInvoiceAmountsBreakdown,
  getPubDateAndPublicationLineItemsDifference
} from 'lib/pricing';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { LineItem } from '../../../../lib/types/invoices';
import RefundInvoiceModalInner from './RefundInvoiceModalInner';
import { getCancelOrRefundInvoiceSettings } from '../PaidReceiptButton';

export type RefundInvoiceModalOuterProps = {
  setOpen: (open: boolean) => void;
  notice: ESnapshotExists<ENotice>;
  newspaper: ESnapshotExists<EOrganization>;
  invoice: ESnapshot<EInvoice> | undefined;
  onCancelSuccess: () => void;
  launchPartialRefundAfterEdit?: boolean;
};

export type RefundUIProps = {
  header: string;
  buttonText: string;
  buttonId: string;
  body: string;
  destructive: boolean;
  refundDisabled: boolean;
  handler?: () => void;
  secondaryHandler?: () => void;
};

export default function RefundInvoiceModalOuter({
  setOpen,
  notice,
  newspaper,
  invoice,
  onCancelSuccess,
  launchPartialRefundAfterEdit
}: RefundInvoiceModalOuterProps) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  // We keep track of the last header just before the invoice disappears so that
  // when the loading state below happens there's not a flicker of text.
  const [lastHeader, setLastHeader] = useState('Delete Invoice?');

  const canVoidInvoices = useHasPermission(Permissions.INVOICES_VOID);
  const canRefundInvoices = useHasPermission(Permissions.INVOICES_REFUND);

  const lineItems = invoice?.data()?.inAppLineItems ?? [];
  const [lineItemValues, setLineItemValues] = useState<LineItem[]>(lineItems);
  const [disabled, setDisabled] = useState(false);
  // The total amount that will be returned to the advertiser
  const [refundAmount, setRefundAmount] = useState('0.00');
  const [refundReason, setRefundReason] = useState('');

  const [isFullRefund, setIsFullRefund] = useState(false);
  const [error, setError] = useState('');

  const getInvoicePubLineItemDiffs = () => {
    if (!exists(invoice)) {
      return [];
    }

    const { publicationLineItemDiffs, pubDateDiffs } =
      getPubDateAndPublicationLineItemsDifference(
        invoice,
        notice.data().publicationDates
      );

    if (publicationLineItemDiffs.length > 0) {
      if ((pubDateDiffs ?? []).length > 0) {
        // we should have no pub date diffs that are not reflected on the invoice
        return [];
      }

      return publicationLineItemDiffs;
    }

    return [];
  };

  const invoicePubLineItemDiffs = getInvoicePubLineItemDiffs() || [];

  useEffect(() => {
    if (!exists(invoice)) {
      return;
    }

    if (!columnObjectsAreEqual(invoice.data().inAppLineItems, lineItemValues)) {
      setLineItemValues(invoice.data().inAppLineItems);
    }

    let defaultRefundAmountInCents = 0;

    const setDefaultRefundAmount = () => {
      if (invoicePubLineItemDiffs.length > 0 && launchPartialRefundAfterEdit) {
        const refundAmountInCentsWithoutTaxesOrFees =
          invoicePubLineItemDiffs.reduce((acc, val) => {
            return acc + val.amount;
          }, 0);

        const { subtotalInCents, columnAmountInCents, taxesInCents } =
          getInvoiceAmountsBreakdown(invoice);
        const proportionOfSubtotal =
          refundAmountInCentsWithoutTaxesOrFees / subtotalInCents;
        const proportionateAmountOfTaxesAndFees =
          proportionOfSubtotal * (taxesInCents + columnAmountInCents);
        defaultRefundAmountInCents =
          refundAmountInCentsWithoutTaxesOrFees +
          proportionateAmountOfTaxesAndFees;
      }

      setRefundAmount((defaultRefundAmountInCents / 100).toFixed(2));
    };

    void setDefaultRefundAmount();
  }, []);

  const handledVoidAndRefundInvoice = async () => {
    if (!disabled && !error && isFullRefund) {
      setLoading(true);
      setLastHeader(header);
      try {
        await cancelOrRefundInvoiceForNotice(notice.ref, refundReason);

        dispatch(
          ToastActions.toastSuccess({
            headerText: 'Success',
            bodyText: `You've successfully voided and refunded the full amount of ${refundAmount} on this invoice.`
          })
        );
        onCancelSuccess();
      } catch (err) {
        logAndCaptureException(
          ColumnService.PAYMENTS,
          err,
          'Failed to cancel/refund invoice',
          {
            noticeId: notice.id,
            invoiceId: invoice?.id
          }
        );
      } finally {
        setLoading(false);
        setOpen(false);
      }
    }
  };

  const handleRefundInvoiceWithoutVoiding = async () => {
    if (!disabled && !error) {
      setLoading(true);
      const refund = await api.post(`payments/${notice.id}/refund`, {
        newspaperId: newspaper.id,
        refundReason,
        refundAmount,
        isInvoiceCancellation: false
      });
      if (refund.error) {
        setError(
          'An error occurred while refunding this notice. If this persists, please contact support.'
        );
        logAndCaptureException(
          ColumnService.PAYMENTS,
          refund.error,
          'Failed to refund notice',
          {
            noticeId: notice.id
          }
        );
        setLoading(false);
        return;
      }
      setLoading(false);
      dispatch(
        ToastActions.toastSuccess({
          headerText: 'Success',
          bodyText: `You've successfully refunded for this notice, and the customer will receive a refund of $${refundAmount} .`
        })
      );
      setOpen(false);
    }
  };

  const handleContactSupport = () =>
    window.open(
      `mailto:help@column.us?subject=Request to cancel Notice #${notice.id}`
    );

  const getRefundUIProps = (): RefundUIProps => {
    if (!exists(invoice)) {
      return {
        header: '',
        buttonText: '',
        buttonId: '',
        body: '',
        destructive: false,
        refundDisabled: true
      };
    }

    const { buttonDisabled, modalToDisplay } =
      getCancelOrRefundInvoiceSettings(notice, invoice, newspaper, {
        userCanRefund: canRefundInvoices,
        userCanVoid: canVoidInvoices
      }) || {};

    if ((buttonDisabled || modalToDisplay !== 'refund-invoice') && !loading) {
      return {
        header: 'Error',
        buttonText: 'Contact Support',
        buttonId: 'contact-support',
        handler: handleContactSupport,
        body: `You have reached this page in error. To refund and cancel this invoice, you will need to reach out to customer support. Email help@column.us or click below.`,
        destructive: false,
        refundDisabled: true
      };
    }

    return {
      header: 'Refund Invoice?',
      buttonText: 'Refund Amount',
      buttonId: 'refund',
      body: `The advertiser has already paid for this notice. If you wish to refund a full or partial amount on the invoice, please specify below. If you void the invoice, Column will refund the entire amount of the invoice.`,
      destructive: true,
      refundDisabled: false,
      handler: handleRefundInvoiceWithoutVoiding,
      secondaryHandler: handledVoidAndRefundInvoice
    };
  };

  // This modal clears the invoice on the notice. So at one point the
  // overall deletion is still ongoing but the invoice snapshot passed
  // in no longer exists. This makes sure the modal renders a loading
  // state in that phase.
  if (!exists(invoice)) {
    return (
      <CancelOrSubmitModal
        onClose={() => setOpen(false)}
        header={lastHeader}
        destructive
        showLoadingSpinner
        tertiaryButtonText="Back"
        disablePrimaryButton
        isParentLoading
        primaryButtonText={''}
        formId="void-invoice-form"
      >
        <div className="py-12 flex flex-col items-center">
          <LoadingSpinner />
          <div className="pt-4 text-column-gray-400">
            Please wait while we process your request...
          </div>
        </div>
      </CancelOrSubmitModal>
    );
  }

  const refundUIProps = getRefundUIProps() || {};

  const { header, body, refundDisabled } = refundUIProps;

  return (
    <>
      {refundDisabled && (
        <CancelOrSubmitModal
          onClose={() => setOpen(false)}
          header={header}
          destructive
          tertiaryButtonText="Back"
          disablePrimaryButton
          primaryButtonText={''}
          formId="void-invoice-form"
          onSubmit={() => {}}
        >
          <div className="py-12 flex flex-col items-center">
            <div className="pt-4 text-column-gray-400">{body}</div>
          </div>
        </CancelOrSubmitModal>
      )}
      {!refundDisabled && (
        <RefundInvoiceModalInner
          loading={loading}
          disabled={disabled}
          notice={notice}
          newspaper={newspaper}
          invoice={invoice}
          refundReason={refundReason}
          refundUIProps={refundUIProps}
          isFullRefund={isFullRefund}
          lineItemValues={lineItemValues}
          invoicePubLineItemDiffs={invoicePubLineItemDiffs}
          refundAmount={refundAmount}
          setOpen={setOpen}
          setRefundReason={setRefundReason}
          setDisabled={setDisabled}
          setIsFullRefund={setIsFullRefund}
          setRefundAmount={setRefundAmount}
        />
      )}
    </>
  );
}
