import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { EOrganization, exists } from 'lib/types';
import { DeadlineSettings } from 'lib/types/deadlines';
import { selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import { logInfo } from 'utils/logger';
import { userIsSuper } from 'utils/permissions';
import { useState } from 'react';
import { CancelNoticeSettings } from 'lib/types/organization';
import { removeUndefinedFields } from 'lib/helpers';
import { Product } from 'lib/enums';
import GlobalDeadlineSettings from './globalDeadlineSettings';
import { RegularDeadlines } from './RegularDeadlines';

function RegularNoticeDeadlines() {
  const user = useAppSelector(selectUser);
  const isSuperUser = user && userIsSuper(user);

  const activeOrganization = useActiveOrganizationListener();

  const [showGlobalDeadlineSettings, setShowGlobalDeadlineSettings] =
    useState(false);

  if (!exists(activeOrganization)) {
    return <LoadingSpinner />;
  }

  if (showGlobalDeadlineSettings) {
    return (
      <GlobalDeadlineSettings
        onClose={() => setShowGlobalDeadlineSettings(false)}
        onSubmit={async (
          formState: Pick<EOrganization, 'requireConfirmation'> &
            CancelNoticeSettings
        ) => {
          const {
            allowInitiated,
            requireInvoiceAtDeadline,
            requireUpfrontPaymentAtDeadline,
            requireConfirmation
          } = formState;
          await activeOrganization.ref.update(
            removeUndefinedFields({
              cancelNoticeSettings: {
                allowInitiated,
                requireUpfrontPaymentAtDeadline,
                requireInvoiceAtDeadline
              },
              requireConfirmation
            })
          );
        }}
      />
    );
  }

  const { iana_timezone, deadlines } = activeOrganization.data();

  return (
    <RegularDeadlines
      productLine={Product.Notice}
      deadlineSettings={deadlines ?? []}
      timeZone={iana_timezone}
      updateRow={async (updatedDeadlineSettings: DeadlineSettings) => {
        const { deadlines } = activeOrganization.data();
        const { dayEnum } = updatedDeadlineSettings;

        if (!deadlines) throw new Error('No deadlines found for newspaper');

        const newDeadlines = [
          ...deadlines.slice(0, dayEnum - 1),
          updatedDeadlineSettings,
          ...deadlines.slice(dayEnum)
        ];

        // This is temporary until we can add proper audit logging on org updates
        logInfo('Updating notice deadlines', {
          newDeadlines,
          dayEnum,
          deadlines,
          userId: user?.id,
          publisher: activeOrganization.id
        });
        await activeOrganization.ref.update({
          deadlines: newDeadlines
        });
      }}
      configurable={
        isSuperUser
          ? {
              buttonText: 'Global Deadline Settings',
              onClick: () => {
                setShowGlobalDeadlineSettings(true);
              }
            }
          : undefined
      }
    />
  );
}

export default RegularNoticeDeadlines;
