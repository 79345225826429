import React from 'react';
import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';

import { getOrThrow } from 'lib/utils/refs';
import {
  PRODUCTION_PROJECT,
  FIREBASE_PROJECT,
  DEMO_PROJECT
} from 'lib/constants';
import ClickableIconWrapper from 'lib/components/TableLayout/ClickableIconWrapper';
import { useSafeLoading } from 'lib/components/hooks/useSafeLoading';
import { ColumnService } from 'lib/services/directory';
import { Product } from 'lib/enums';
import { PublicationIssueWithSection } from 'lib/types/publicationIssueSection';
import { PublicationIssueModel } from 'lib/model/objects/publicationIssueModel';
import { logAndCaptureException } from 'utils';
import { getPaginationReadyOrdersForPublicationIssue } from 'services/pagination';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { safeGetOrThrow } from 'lib/safeWrappers';

const getAdminPageURL = () => {
  if (FIREBASE_PROJECT === PRODUCTION_PROJECT) {
    return 'https://retoo.lol';
  }
  if (FIREBASE_PROJECT === DEMO_PROJECT) {
    return 'https://column-admin-demo.web.app';
  }
  return 'http://localhost:3001';
};

type BuildPaginationFilesButtonProps = {
  publicationIssueWithSection: PublicationIssueWithSection;
  product: Product;
};

const loadNotices = async (publicationIssue: PublicationIssueModel) => {
  const publisher = await getOrThrow(publicationIssue.modelData.publisher);
  const adTemplate = await getOrThrow(publisher.data().adTemplate);
  const { response: notices, error: noticeError } =
    await publicationIssue.slowGetNoticesForPublicationIssue();
  if (noticeError) {
    logAndCaptureException(
      ColumnService.PAGINATION,
      noticeError,
      'Error getting notices for pagination',
      { publicationIssueId: publicationIssue.id }
    );
    throw noticeError;
  } else {
    const paginationUrl = `${getAdminPageURL()}/pagination-builder?templateID=${
      adTemplate.id
    }&noticeIDs=${notices.map(n => n.id).join(',')}&issueID=${
      publicationIssue.id
    }`;
    window.open(paginationUrl, '_blank');
  }
};

const loadOrders = async (
  publicationIssueWithSection: PublicationIssueWithSection
) => {
  const { publicationIssue, section } = publicationIssueWithSection;
  if (!section) {
    return wrapError(
      new Error('Cannot load pagination orders without section')
    );
  }
  const [publisherError, publisher] = await safeGetOrThrow(
    publicationIssue.modelData.publisher
  );
  if (publisherError) {
    return wrapError(publisherError);
  }
  const [templateError, adTemplate] = await safeGetOrThrow(
    publisher.data().adTemplate
  );
  if (templateError) {
    return wrapError(templateError);
  }
  const [ordersError, orders] =
    await getPaginationReadyOrdersForPublicationIssue(
      publicationIssue,
      section.modelData.type,
      section.modelData.publishingMedium
    );
  if (ordersError) {
    return wrapError(ordersError);
  }

  const orderIDs = orders.map(order => order.orderid).join(',');
  const paginationUrl = `${getAdminPageURL()}/pagination-builder?templateID=${
    adTemplate.id
  }&orderIDs=${orderIDs}&issueID=${publicationIssue.id}&sectionID=${
    section.id
  }`;
  window.open(paginationUrl, '_blank');
  return wrapSuccess(undefined);
};

export function BuildPaginationFilesButton({
  publicationIssueWithSection
}: BuildPaginationFilesButtonProps) {
  const { publicationIssue, section } = publicationIssueWithSection;
  const product = section?.modelData.type ?? Product.Notice;
  const [loadingPaginationFiles, loadPaginationFiles] = useSafeLoading({
    callback:
      product === Product.Notice
        ? () => loadNotices(publicationIssue)
        : () => loadOrders(publicationIssueWithSection),
    errorConfig: {
      message: 'Error loading pagination files for admin builder',
      service: ColumnService.PAGINATION,
      tags: {
        publicationIssueId: publicationIssue.id,
        sectionId: section?.id ?? 'undefined',
        product: section?.modelData.type ?? Product.Notice
      }
    }
  });

  return (
    <ClickableIconWrapper
      id={`pagination-builder-${publicationIssue.id}`}
      loading={loadingPaginationFiles}
      icon={<WrenchScrewdriverIcon className="h-5 w-5" />}
      onClick={loadPaginationFiles}
    />
  );
}
