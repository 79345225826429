import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import {
  QuestionMarkCircleIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';

import { EOrganization, ESnapshotExists, EUser, exists } from 'lib/types';
import { OccupationType } from 'lib/enums';
import SyncErrorDrawer from 'routes/notices/table/SyncErrorDrawer';
import { isPublisher } from 'lib/utils/users';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import Notifications from 'components/NotificationsDrawer/Notifications';
import AddCustomerOrganizationNavLink from './AddCustomerOrganizationNavLink';

import SettingsHeaderBarClickableIcon from './SettingsHeaderBarClickableIcon';
import HeaderBarClickableIcon from './HeaderBarClickableIcon';
import { InternalLabels } from './InternalLabels';
import HeaderNavBarLink from './HeaderNavBarLink';
import HeaderNavBarLogo from './HeaderNavBarLogo';
import { ActiveOrganizationSelect } from './ActiveOrganizationSelect';
import { useDetermineShowAutomatedAffidavitsTab } from './useDetermineShowAutomatedAffidavitsTab';
import { useDetermineShowPaymentsTab } from './useDetermineShowPaymentsTab';
import { useGetPaginationTabItems } from './useGetPaginationTabItems';
import HeaderNavBarPopover from './HeaderNavBarPopover';
import { selectOrdersTabItems } from './selectOrdersTabItems';

const HELP_CENTER_LINK = 'https://help.column.us';
export const HEADER_HEIGHT = 80;

type AppLayoutHeaderProps = {
  activeOrganization: ESnapshotExists<EOrganization> | null;
  user: ESnapshotExists<EUser> | null;
};
export default function AppLayoutHeader({
  activeOrganization,
  user
}: AppLayoutHeaderProps) {
  const [showSyncErrorDrawer, setShowSyncErrorDrawer] = useState(false);

  const showAutomatedAffidavitsTab =
    useDetermineShowAutomatedAffidavitsTab(activeOrganization);

  const paginationTabItems = useGetPaginationTabItems(activeOrganization, user);
  const showPaginationTab = !!paginationTabItems.find(item => item.enabled);

  const ordersTabItems = useAppSelector(selectOrdersTabItems);
  const showOrdersTab = !!ordersTabItems.find(item => item.enabled);

  const showPaymentsTab = useDetermineShowPaymentsTab(user);

  const displayCreateJoinOrganizationTab =
    user?.data().occupation === OccupationType.individual.value;

  const userIsPublisher = !!user && isPublisher(user);
  const enableSyncErrorTable = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_SYNC_ERROR_TABLE
  );

  return (
    <header className="h-20 flex items-center px-6 md:px-12 border-b h-20 z-20 gap-6">
      <HeaderNavBarLogo />
      <div className="flex-1 flex items-center flex-row-reverse md:flex-row justify-start gap-x-4">
        {exists(user) && !displayCreateJoinOrganizationTab && (
          <ActiveOrganizationSelect />
        )}

        <div className="hidden md:flex md:items-center md:flex-1">
          {displayCreateJoinOrganizationTab && (
            <AddCustomerOrganizationNavLink />
          )}

          {exists(user) && showOrdersTab && (
            <HeaderNavBarPopover
              label="Orders"
              id="orders-table"
              items={ordersTabItems}
            />
          )}

          {exists(user) && showPaymentsTab && (
            <HeaderNavBarLink
              path="/payments"
              label={'Invoices'}
              id="bulk-invoices"
            />
          )}

          {exists(user) && showAutomatedAffidavitsTab && (
            <HeaderNavBarLink
              path="/affidavits"
              label="Affidavits"
              id="auto-affidavits"
            />
          )}

          {exists(user) && showPaginationTab && (
            <HeaderNavBarPopover
              label="Pagination"
              id="pagination"
              items={paginationTabItems}
            ></HeaderNavBarPopover>
          )}

          {exists(user) &&
            user.data().occupation === OccupationType.publishing.value && (
              <HeaderNavBarLink
                path="/reports"
                label="Reports"
                id="reports-link"
              />
            )}
          <InternalLabels />
        </div>
        <div className="flex">
          {userIsPublisher && enableSyncErrorTable && (
            <HeaderBarClickableIcon
              id="sync-errors"
              icon={<ArrowPathIcon className="w-6 h-6" />}
              onClick={() => setShowSyncErrorDrawer(!showSyncErrorDrawer)}
              label="Sync Errors"
            />
          )}
          {exists(user) && <Notifications user={user} />}
          <HeaderBarClickableIcon
            id="help-icon"
            icon={<QuestionMarkCircleIcon className="w-6 h-6" />}
            onClick={() => window.open(HELP_CENTER_LINK, '_blank')}
            label="Help Center"
          />
          {exists(user) && <SettingsHeaderBarClickableIcon user={user} />}
        </div>
      </div>
      {showSyncErrorDrawer && !!activeOrganization && (
        <SyncErrorDrawer
          activeOrganization={activeOrganization}
          showSyncErrorDrawer
          setShowSyncErrorDrawer={setShowSyncErrorDrawer}
        />
      )}
    </header>
  );
}
