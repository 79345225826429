import React, { useState, useEffect } from 'react';
import { logAndCaptureException } from 'utils';
import Validator from 'validator';
import api from 'api';
import FreeformCModal from 'components/modals/FreeFormCModal';
import { EOrganization, ESnapshotExists } from 'lib/types';
import { ColumnService } from 'lib/services/directory';

type DwollaCustomerProps = {
  showCustomerModal: () => void;
  updateOnSuccess: () => void;
  organization: ESnapshotExists<EOrganization>;
  customerData?: any;
  customerId?: string;
};

type DwollaCustomer = {
  firstName: string;
  lastname: string;
  email: string;
  businessName: string;
};

// dwolla customer details
const INITIAL_USER_DETAILS = {
  firstName: '',
  lastname: '',
  email: '',
  businessName: ''
};

function DwollaCustomerModal({
  showCustomerModal,
  updateOnSuccess,
  organization,
  customerData
}: DwollaCustomerProps) {
  const [dwollaCustomerDetails, setDwollaCustomerDetails] =
    useState<DwollaCustomer>(INITIAL_USER_DETAILS);
  const [error, setError] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);

  const allAlphabets = (inputText: string) => {
    const letters = /^[A-Za-z]+$/;
    if (inputText === '') return true;
    if (letters.test(inputText)) return true;
    return false;
  };

  useEffect(() => {
    if (!customerData) {
      setDwollaCustomerDetails({
        ...dwollaCustomerDetails,
        businessName: organization.data().name
      });
      return;
    }
    setDwollaCustomerDetails({
      ...dwollaCustomerDetails,
      firstName: customerData.firstName,
      lastname: customerData.lastName,
      email: customerData.email,
      businessName: customerData.businessName
    });
  }, []);

  // this will create a new dwolla customer
  const addDwollaCustomer = async () => {
    try {
      setError('');
      setLoading(true);
      const response = await api.post(
        'dwolla/creating-dwolla-customer',
        dwollaCustomerDetails
      );
      setLoading(false);
      if (response.success) {
        updateOnSuccess();
      } else if (!response.success && response?.error === 'ValidationError') {
        setError(response.message);
      } else {
        setError('There is some issue while validating customer information.');
      }
    } catch (err) {
      setLoading(false);
      logAndCaptureException(
        ColumnService.PAYMENTS,
        err,
        'Failed to add Dwolla customer',
        {
          dwollaCustomerEmail: dwollaCustomerDetails.email
        }
      );
      setError('There is some issue while creating customer.');
    }
  };

  return (
    <div>
      <FreeformCModal
        header={
          !customerData ? 'Customer Information' : 'Verify Personal Information'
        }
        setOpen={() => {
          showCustomerModal();
        }}
        customStyle={'px-2'}
        noExitOutsideModal
        width={'30%'}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            if (!isValidEmail) return;
            if (
              !(
                allAlphabets(dwollaCustomerDetails.firstName) &&
                allAlphabets(dwollaCustomerDetails.lastname)
              )
            ) {
              setError('First and last name should be only alphabets.');
              return;
            }
            if (!customerData) void addDwollaCustomer();
          }}
        >
          <div className="overflow-y-auto py-2 mt-2 px-2">
            <div className="text-gray-700 mb-4 text-sm -mt-3">
              {!customerData && 'Enter your information below to get started.'}
            </div>
            <div className="flex mb-2">
              <input
                id="first-name"
                className="mr-1 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                type="text"
                placeholder="First Name *"
                required
                value={dwollaCustomerDetails.firstName}
                onChange={e =>
                  setDwollaCustomerDetails({
                    ...dwollaCustomerDetails,
                    firstName: e.target.value
                  })
                }
              />
              <input
                id="last-name"
                className="ml-1 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                type="text"
                placeholder="Last Name *"
                required
                value={dwollaCustomerDetails.lastname}
                onChange={e =>
                  setDwollaCustomerDetails({
                    ...dwollaCustomerDetails,
                    lastname: e.target.value
                  })
                }
              />
            </div>
            <div className="mb-2">
              <input
                id="email"
                className={`appearance-none rounded relative block w-full px-3 py-2 mb-0 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:z-10 sm:text-sm sm:leading-5 ${
                  !isValidEmail && dwollaCustomerDetails.email
                    ? 'border-red-400'
                    : 'focus:shadow-outline-blue focus:border-blue-300'
                }`}
                type="text"
                placeholder="Email *"
                required
                value={dwollaCustomerDetails.email}
                onChange={e => {
                  setDwollaCustomerDetails({
                    ...dwollaCustomerDetails,
                    email: e.target.value
                  });
                  setIsValidEmail(Validator.isEmail(e.target.value));
                }}
              />
              {!isValidEmail && dwollaCustomerDetails.email && (
                <p className="text-red-500 text-xs">
                  Enter valid email address.
                </p>
              )}
            </div>
            {!customerData && (
              <>
                <div className="flex items-center mb-4 mt-3">
                  <input
                    id="agreeToTerms"
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                    checked={termsChecked}
                    onChange={() => setTermsChecked(!termsChecked)}
                  />
                  <div className="ml-2 block text-sm leading-4 text-gray-900">
                    By checking this box, you agree to our{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Flegal%2Fcolumn%2FColumn%20Terms%20of%20Use.pdf?alt=media&token=e0435fa6-5a22-43e8-870e-6b37a4cc71ef"
                      className="text-blue-600 hover:underline"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://enotice-production.imgix.net/custom-documents/permalink/4953.6bc4e-Column%20Privacy%20Policy.pdf"
                      className="text-blue-600 hover:underline"
                    >
                      Privacy Policy
                    </a>
                    , as well as our partner Dwolla's{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.dwolla.com/legal/tos/"
                      className="text-blue-600 hover:underline"
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.dwolla.com/legal/privacy/"
                      className="text-blue-600 hover:underline"
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>
                </div>
                {error && (
                  <div className="text-red-500 text-sm mb-3">{error}</div>
                )}
                <button
                  className={`rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 ${
                    !termsChecked
                      ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                      : 'bg-blue-500'
                  }`}
                  type="submit"
                  disabled={loading && !termsChecked}
                >
                  <span className="flex">
                    {loading && (
                      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" />
                    )}
                    Agree & Continue
                  </span>
                </button>
              </>
            )}
          </div>
        </form>
      </FreeformCModal>
    </div>
  );
}

export default DwollaCustomerModal;
