import {
  AffidavitTemplate,
  AffidavitTemplateV1,
  AffidavitTemplateV2
} from '../../types/affidavitTemplate';

import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import {
  EFirebaseContext,
  ENotice,
  ERate,
  ERef,
  ESnapshotExists,
  EUser
} from '../../types';
import { EOrganization } from '../../types/organization';
import { CustomNoticeFilingType } from '../../types/filingType';
import { getOrThrow } from '../../utils/refs';
import { UserNoticeModel } from './userNoticeModel';
import { CustomerModel } from './customerModel';
import { OrganizationModel } from './organizationModel';

type TemplateSource = ESnapshotExists<ENotice | EOrganization | ERate | EUser>;
type ARSSource = UserNoticeModel | CustomerModel | OrganizationModel;

export class AffidavitTemplateModel extends SnapshotModel<
  AffidavitTemplate,
  typeof Collections.affidavitTemplates
> {
  get type() {
    return Collections.affidavitTemplates;
  }

  isV1(): this is SnapshotModel<
    AffidavitTemplateV1,
    typeof Collections.affidavitTemplates
  > {
    return this.modelData.version === 'v2020-01-01';
  }

  isV2(): this is SnapshotModel<
    AffidavitTemplateV2,
    typeof Collections.affidavitTemplates
  > {
    return this.modelData.version === 'v2024-03-25';
  }

  static async fromRef(ctx: EFirebaseContext, ref: ERef<AffidavitTemplate>) {
    const snap = await getOrThrow(ref);
    return new AffidavitTemplateModel(ctx, { snap });
  }

  static async fromStoragePathAndPublisher(
    ctx: EFirebaseContext,
    storagePath: string,
    publisherRef: ERef<EOrganization>,
    nameIfNotExists?: string
  ) {
    const query = await ctx
      .affidavitTemplatesRef()
      .where('storagePath', '==', storagePath)
      .where('publisher', '==', publisherRef)
      .get();

    const activeTemplates = query.docs.filter(
      template => !template.data().archived
    );

    if (activeTemplates.length > 0) {
      return this.fromRef(ctx, activeTemplates[0].ref);
    }

    const ref = ctx.affidavitTemplatesRef().doc();

    const templateData: AffidavitTemplateV1 = {
      name: nameIfNotExists ?? 'Custom Affidavit',
      storagePath,
      publisher: publisherRef,
      archived: false,
      version: 'v2020-01-01',
      isColumnManaged: false
    };

    await ref.set(templateData);

    return this.fromRef(ctx, ref);
  }

  static async fromSourceRef<T extends TemplateSource>(
    ctx: EFirebaseContext,
    sourceSnap: T,
    sourceName: string,
    publisherRef: ERef<EOrganization>
  ) {
    const { customAffidavit, defaultAffidavitTemplate } = sourceSnap.data();

    if (defaultAffidavitTemplate) {
      return this.fromRef(ctx, defaultAffidavitTemplate);
    }

    if (!customAffidavit) {
      return;
    }

    const template = await this.fromStoragePathAndPublisher(
      ctx,
      customAffidavit,
      publisherRef,
      `Custom Affidavit — ${sourceName}`
    );

    await sourceSnap.ref.update({
      defaultAffidavitTemplate: template.ref
    });

    return template;
  }

  static async fromNoticeType(
    ctx: EFirebaseContext,
    noticeType: CustomNoticeFilingType | null,
    publisherSnap: ESnapshotExists<EOrganization>
  ) {
    if (!noticeType) {
      return;
    }

    const { customAffidavit, defaultAffidavitTemplate } = noticeType;

    if (defaultAffidavitTemplate) {
      return this.fromRef(ctx, defaultAffidavitTemplate);
    }

    if (!customAffidavit) {
      return;
    }

    const template = await this.fromStoragePathAndPublisher(
      ctx,
      customAffidavit,
      publisherSnap.ref,
      `Custom Affidavit — ${noticeType.label}`
    );

    const { allowedNotices = [] } = publisherSnap.data();

    const newNoticeTypes = allowedNotices.map(nt => {
      if (nt.value === noticeType.value) {
        return {
          ...nt,
          defaultAffidavitTemplate: template.ref
        };
      }

      return nt;
    });

    await publisherSnap.ref.update({
      allowedNotices: newNoticeTypes
    });

    return template;
  }

  static async fromSourceRefNotarized<T extends ARSSource>(
    ctx: EFirebaseContext,
    source: T,
    sourceName: string,
    publisherRef: ERef<EOrganization>
  ) {
    const { affidavitReconciliationSettings } = source.modelData;

    if (!affidavitReconciliationSettings) {
      return;
    }

    const { managedAffidavitTemplateStoragePath, managedAffidavitTemplate } =
      affidavitReconciliationSettings;

    if (managedAffidavitTemplate) {
      return this.fromRef(ctx, managedAffidavitTemplate);
    }

    if (!managedAffidavitTemplateStoragePath) {
      return;
    }

    const template = await this.fromStoragePathAndPublisher(
      ctx,
      managedAffidavitTemplateStoragePath,
      publisherRef,
      `Automated Affidavit — ${sourceName}`
    );

    await source.updateAutomatedAffidavitConfiguration({
      ...affidavitReconciliationSettings,
      managedAffidavitTemplate: template.ref
    });

    return template;
  }

  static async fromNoticeTypeNotarized(
    ctx: EFirebaseContext,
    noticeType: CustomNoticeFilingType | null,
    publisher: OrganizationModel
  ) {
    if (!noticeType) {
      return;
    }

    const { affidavitReconciliationSettings } = noticeType;

    if (!affidavitReconciliationSettings) {
      return;
    }

    const { managedAffidavitTemplateStoragePath, managedAffidavitTemplate } =
      affidavitReconciliationSettings;

    if (managedAffidavitTemplate) {
      return this.fromRef(ctx, managedAffidavitTemplate);
    }

    if (!managedAffidavitTemplateStoragePath) {
      return;
    }

    const template = await this.fromStoragePathAndPublisher(
      ctx,
      managedAffidavitTemplateStoragePath,
      publisher.ref,
      `Automated Affidavit — ${noticeType.label}`
    );

    const { allowedNotices = [] } = publisher.modelData;

    const newNoticeTypes = allowedNotices.map(nt => {
      if (nt.value === noticeType.value) {
        return {
          ...nt,
          affidavitReconciliationSettings: {
            ...affidavitReconciliationSettings,
            managedAffidavitTemplate: template.ref
          }
        };
      }

      return nt;
    });

    await publisher.updateFilingTypes(newNoticeTypes);

    return template;
  }
}
