import { Product } from 'lib/enums';
import { removeUndefinedFields } from 'lib/helpers';
import { getModelFromSnapshot } from 'lib/model';
import { OrderModel } from 'lib/model/objects/orderModel';
import { UserModel } from 'lib/model/objects/userModel';
import { getCustomer } from 'lib/notice/customer';
import { safeAsync, safeGetOrThrow } from 'lib/safeWrappers';
import { ClassifiedService } from 'lib/services/classifiedService';
import { NewspaperOrderService } from 'lib/services/newspaperOrderService';
import { ObituaryService } from 'lib/services/obituaryService';
import { OrderDetailService } from 'lib/services/orderDetailService';
import { OrderService } from 'lib/services/orderService';
import { Customer, ERef, ESnapshotExists, EUser } from 'lib/types';
import { Classified } from 'lib/types/classified';
import { Obituary } from 'lib/types/obituary';
import {
  Order,
  isAdvertiserOrder,
  AdditionalAdvertiserWithOrganizationOrderInfo,
  AdditionalIndividualAdvertiserOrderInfo,
  AdditionalPublisherAsAnonymousOrderInfo,
  isPublisherAsAdvertiserOrder
} from 'lib/types/order';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { getFirebaseContext } from 'utils/firebase';

export const duplicateOrder = async (
  originalOrder: OrderModel,
  user: ESnapshotExists<EUser> | null
): Promise<ResponseOrError<ERef<Order>>> => {
  if (!user) {
    return wrapError(
      new Error('Cannot duplicate order because the user is not found.')
    );
  }

  const ctx = getFirebaseContext();

  const { product } = originalOrder.modelData;

  const initialVersion = ctx.timestamp().toMillis();

  const userModel = getModelFromSnapshot(UserModel, ctx, user);

  const { response: newOrderRef, error: createNewOrderRefError } =
    await createNewDuplicatedOrder(
      originalOrder,
      product,
      userModel,
      initialVersion
    );

  if (createNewOrderRefError) {
    return wrapError(
      new Error(
        'Cannot duplicate order because the new order ref cannot be created.'
      )
    );
  }

  if (product === Product.Classified) {
    const [errorDuplicatingClassified] = await duplicateClassifiedAd(
      originalOrder,
      newOrderRef,
      initialVersion
    );
    if (errorDuplicatingClassified) {
      return wrapError(errorDuplicatingClassified);
    }
  } else if (product === Product.Obituary) {
    const [errorDuplicatingObituary] = await duplicateObituaryAd(
      originalOrder,
      newOrderRef,
      initialVersion
    );
    if (errorDuplicatingObituary) {
      return wrapError(errorDuplicatingObituary);
    }
  }

  const [errorDuplicatingOrderDetails] = await duplicateOrderDetails(
    originalOrder,
    newOrderRef,
    initialVersion
  );

  if (errorDuplicatingOrderDetails) {
    return wrapError(errorDuplicatingOrderDetails);
  }

  const [errorDuplicatingNewspaperOrders] = await duplicateNewspaperOrders(
    originalOrder,
    newOrderRef,
    initialVersion
  );

  if (errorDuplicatingNewspaperOrders) {
    return wrapError(errorDuplicatingNewspaperOrders);
  }

  return wrapSuccess(newOrderRef);
};

const getCustomerFromAdvertiser = async (
  advertiser: ERef<EUser>,
  userModel: UserModel
) => {
  const { response: advertiserSnap, error: advertiserSnapError } =
    await safeGetOrThrow(advertiser);
  if (advertiserSnapError) {
    return wrapError(advertiserSnapError);
  }

  const { response: advertiserOrg, error: advertiserOrgSnapError } =
    await safeGetOrThrow(userModel.modelData.activeOrganization);
  if (advertiserOrgSnapError) {
    return wrapError(advertiserOrgSnapError);
  }

  const { response: customerSnap, error: customerSnapError } = await safeAsync(
    () => getCustomer(getFirebaseContext(), advertiserSnap, advertiserOrg)
  )();
  if (customerSnapError) {
    return wrapError(customerSnapError);
  }
  return wrapSuccess(customerSnap);
};

const createNewDuplicatedOrder = async (
  originalOrder: OrderModel,
  product: Product,
  userModel: UserModel,
  initialVersion: number
): Promise<ResponseOrError<ERef<Order>>> => {
  let customer: ESnapshotExists<Customer> | null = null;
  if (isAdvertiserOrder(originalOrder.modelData)) {
    const { response: customerSnap, error: customerSnapError } =
      await getCustomerFromAdvertiser(
        originalOrder.modelData.advertiser,
        userModel
      );
    if (customerSnapError) {
      return wrapError(customerSnapError);
    }
    customer = customerSnap;
  }

  const getOrderInfo = () => {
    if (isAdvertiserOrder(originalOrder.modelData)) {
      return wrapSuccess({
        advertiser: originalOrder.modelData.advertiser,
        advertiserOrganization:
          originalOrder.modelData.advertiserOrganization || null,
        advertiserCustomer:
          isPublisherAsAdvertiserOrder(originalOrder.modelData) &&
          userModel.isPublisher
            ? originalOrder.modelData.advertiserCustomer
            : customer
            ? customer.ref
            : undefined
      });
    }
    const orderContactInfo = {
      contactEmail: originalOrder.modelData.contactEmail,
      firstName: originalOrder.modelData.firstName,
      lastName: originalOrder.modelData.lastName,
      phone: originalOrder.modelData.phone
    };
    const classifiedOnlyContactInfo = {
      addressLine1: originalOrder.modelData.addressLine1,
      addressLine2: originalOrder.modelData.addressLine2,
      city: originalOrder.modelData.city,
      state: originalOrder.modelData.state,
      zip: originalOrder.modelData.zip,
      organizationName: originalOrder.modelData.organizationName
    };
    if (product === Product.Obituary) {
      return wrapSuccess({
        ...orderContactInfo
      });
    }
    return wrapSuccess({
      ...orderContactInfo,
      ...classifiedOnlyContactInfo
    });
  };

  const { response: orderInfo } = getOrderInfo();

  const extraOrderInfo:
    | AdditionalAdvertiserWithOrganizationOrderInfo
    | AdditionalIndividualAdvertiserOrderInfo
    | AdditionalPublisherAsAnonymousOrderInfo = {
    ...orderInfo,
    user: userModel.ref,
    authorizedOrganization: userModel.modelData.activeOrganization || null
  };

  const commonFields = removeUndefinedFields({
    ...extraOrderInfo,
    originalRef: originalOrder.ref
  });

  const orderService: OrderService = new OrderService(getFirebaseContext());

  const { response: newOrderRef, error: createNewOrderRefError } =
    await safeAsync(() =>
      orderService.create(commonFields, product, initialVersion)
    )();

  if (createNewOrderRefError) {
    return wrapError(createNewOrderRefError);
  }

  return wrapSuccess(newOrderRef);
};

const duplicateObituaryAd = async (
  originalOrder: OrderModel,
  newOrderRef: ERef<Order>,
  initialVersion: number
) => {
  const { response: obituaryModel, error: obituaryDataModelError } =
    await originalOrder.getObituary(originalOrder.modelData.activeVersion);

  if (obituaryDataModelError) {
    return wrapError(obituaryDataModelError);
  }

  const obituaryModelData: Obituary = {
    ...obituaryModel.modelData,
    title: obituaryModel.modelData.title || '',
    order: newOrderRef
  };

  const obituaryService: ObituaryService = new ObituaryService(
    getFirebaseContext()
  );

  const duplicatingObituary = safeAsync(() =>
    obituaryService.cloneForEditOrDuplicateFlow(
      obituaryModel.id,
      obituaryModelData,
      initialVersion
    )
  );

  const [duplicateObituaryError] = await duplicatingObituary();

  if (duplicateObituaryError) {
    return wrapError(duplicateObituaryError);
  }

  return wrapSuccess(undefined);
};

const duplicateClassifiedAd = async (
  originalOrder: OrderModel,
  newOrderRef: ERef<Order>,
  initialVersion: number
) => {
  const { response: classifiedModel, error: classifiedModelError } =
    await originalOrder.getClassified(originalOrder.modelData.activeVersion);

  if (classifiedModelError) {
    return wrapError(classifiedModelError);
  }

  const classifiedModelData: Classified = {
    ...classifiedModel.modelData,
    title: classifiedModel.modelData.title || '',
    order: newOrderRef
  };

  const classifiedService: ClassifiedService = new ClassifiedService(
    getFirebaseContext()
  );

  const duplicatingClassified = safeAsync(() =>
    classifiedService.cloneForEditOrDuplicateFlow(
      classifiedModel.id,
      classifiedModelData,
      initialVersion
    )
  );

  const [duplicateClassifiedError] = await duplicatingClassified();

  if (duplicateClassifiedError) {
    return wrapError(duplicateClassifiedError);
  }

  return wrapSuccess(undefined);
};

const duplicateOrderDetails = async (
  originalOrder: OrderModel,
  newOrderRef: ERef<Order>,
  initialVersion: number
) => {
  // duplicate orderDetails
  const orderDetailService: OrderDetailService = new OrderDetailService(
    getFirebaseContext()
  );
  const [orderDetailsModelError, orderDetailsModel] =
    await originalOrder.getOrderDetail();

  if (orderDetailsModelError || !orderDetailsModel) {
    return wrapError(
      orderDetailsModelError ||
        new Error(
          'Cannot duplicate order because the order details do not exist.'
        )
    );
  }

  const duplicatingOrderDetails = safeAsync(() =>
    orderDetailService.cloneForEditOrDuplicateFlow(
      newOrderRef,
      orderDetailsModel,
      initialVersion
    )
  );

  const [duplicatingOrderDetailsError] = await duplicatingOrderDetails();

  if (duplicatingOrderDetailsError) {
    return wrapError(duplicatingOrderDetailsError);
  }

  return wrapSuccess(undefined);
};

const duplicateNewspaperOrders = async (
  originalOrder: OrderModel,
  newOrderRef: ERef<Order>,
  initialVersion: number
) => {
  // duplicate newspaperOrders
  const newspaperOrderService: NewspaperOrderService =
    new NewspaperOrderService(getFirebaseContext());

  const getNewspaperOrderModels = safeAsync(() =>
    originalOrder.getNewspaperOrders({
      specifiedVersion: originalOrder.modelData.activeVersion
    })
  );

  const [errorOnGettingNewspaperOrderModels, newspaperOrderModels] =
    await getNewspaperOrderModels();
  if (errorOnGettingNewspaperOrderModels) {
    return wrapError(errorOnGettingNewspaperOrderModels);
  }
  const duplicatingNewspaperOrders = safeAsync(() =>
    newspaperOrderService.cloneForEditOrDuplicateFlow(
      newOrderRef,
      newspaperOrderModels,
      initialVersion
    )
  );

  const [duplicatingNewspaperOrdersError] = await duplicatingNewspaperOrders();

  if (duplicatingNewspaperOrdersError) {
    return wrapError(duplicatingNewspaperOrdersError);
  }

  return wrapSuccess(undefined);
};
