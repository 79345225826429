import React from 'react';
import {
  ArrowRightCircleIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';

import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';

import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import {
  EInvoice,
  ENotice,
  EOrganization,
  ESnapshotExists,
  FirebaseTimestamp,
  exists
} from 'lib/types';
import { centsToDollarsString } from 'lib/helpers';
import {
  hasPaymentOrPartialRefund,
  isPaidDirectToPublisher
} from 'lib/utils/invoices';
import { useAppSelector } from 'redux/hooks';
import { Alert } from 'lib/components/Alert';
import { getCancelOrRefundInvoiceSettings } from 'routes/notice/PaidReceiptButton';
import { useHasPermission } from 'utils/useHasPermission';
import { Permissions } from 'lib/permissions/roles';
import { canCancelInvoiceWithoutSupport } from 'utils/permissions';
import { getNoticePubDatesNotOnInvoice } from 'lib/pricing';

type InvoiceReminderPriceChangeProps = {
  oldPriceInCents: number;
  newPriceInCents: number;
};

function InvoiceReminderPriceChange({
  oldPriceInCents,
  newPriceInCents
}: InvoiceReminderPriceChangeProps) {
  return (
    <div className="w-full flex gap-4">
      <div className="p-4 flex-grow bg-red-100 rounded">
        <div className="text-xl font-bold text-red-600 line-through">
          ${centsToDollarsString(oldPriceInCents)}
        </div>
        <span className="font-medium text-column-gray-500">Old Price</span>
      </div>
      <div className="flex items-center">
        <ArrowRightCircleIcon className="w-8 h-8 text-column-gray-300" />
      </div>
      <div className="p-4 flex-grow bg-column-green-100 rounded">
        <div className="text-xl font-bold text-column-green-500">
          ${centsToDollarsString(newPriceInCents)}
        </div>
        <span className="font-medium text-column-gray-500">New Price</span>
      </div>
    </div>
  );
}

function InvoiceReminderPublicationDateChange() {
  return (
    <Alert
      id="invoice-reminder-pub-dates"
      status="warning"
      icon={<ExclamationTriangleIcon className="h-5 w-5" />}
      title="Publication dates have changed"
      description="The publication dates you have selected do not match the publication dates on the previous invoice."
    />
  );
}

type InvoiceReminderModalProps = {
  notice: ESnapshotExists<ENotice>;
  invoice: ESnapshotExists<EInvoice> | undefined;
  newspaper: ESnapshotExists<EOrganization> | undefined;
  oldPriceInCents: number;
  newPriceInCents: number;
  hasPriceChange: boolean;
  hasPublicationDateChange: boolean;
  placementPubDates: FirebaseTimestamp[] | null;
  enablePartialRefundsV2: boolean;
  onConfirmClicked: (options: {
    reinvoiceAfterPlacement: boolean;
    partialRefundAfterPlacement: boolean;
  }) => unknown;
  onBackClicked: () => unknown;
};

function InvoiceReminderModal({
  notice,
  invoice,
  newspaper,
  oldPriceInCents,
  newPriceInCents,
  hasPriceChange,
  hasPublicationDateChange,
  placementPubDates,
  enablePartialRefundsV2,
  onConfirmClicked,
  onBackClicked
}: InvoiceReminderModalProps) {
  const publisherName = useAppSelector(
    state => state.auth.activeOrganization?.data()?.name ?? 'the publisher'
  );
  const invoicedOutsideColumn = !!invoice?.data()?.invoiceOutsideColumn;

  const newPriceExceedsOldPrice =
    hasPriceChange && newPriceInCents > oldPriceInCents;
  const oldPriceExceedsNewPrice =
    hasPriceChange && oldPriceInCents > newPriceInCents;

  const canVoidInvoices = useHasPermission(Permissions.INVOICES_VOID);
  const canRefundInvoices = useHasPermission(Permissions.INVOICES_REFUND);

  const { buttonDisabled: refundOrVoidDisabled, modalToDisplay } =
    getCancelOrRefundInvoiceSettings(notice, invoice, newspaper, {
      userCanRefund: canRefundInvoices,
      userCanVoid: canVoidInvoices
    }) || {};

  const getHasAdditionalNoticePubDatesNotOnInvoice = () => {
    if (!exists(invoice) || !placementPubDates) {
      return false;
    }

    const additionalPubDates = getNoticePubDatesNotOnInvoice(
      invoice,
      placementPubDates
    );

    if (additionalPubDates && additionalPubDates.length > 0) {
      return true;
    }

    return false;
  };

  const hasAdditionalNoticePubDatesNotOnInvoice =
    getHasAdditionalNoticePubDatesNotOnInvoice();

  const handleSetOpen = (val: boolean) => {
    if (!val) {
      onBackClicked();
    }
  };

  const loading = !exists(invoice);

  const invoiceNotPaidByCard = invoice?.data().paymentMethod !== 'card';
  const invoiceMarkedIOC = !!invoice?.data().invoiceOutsideColumn;
  const invoiceMarkedPaid = !!invoice?.data().manualPaymentDetails;

  const renderBody = () => {
    if (loading) {
      return (
        <div className="h-48 flex items-center justify-center">
          <LoadingSpinner />
        </div>
      );
    }

    const invoiceIsPaid = hasPaymentOrPartialRefund(invoice);
    const paidDirectToPublisher = isPaidDirectToPublisher(invoice);

    return (
      <div className="py-4">
        <div className="text-column-gray-400">
          {hasPriceChange ? (
            <span>The estimated price of this notice has changed. </span>
          ) : (
            <span>Some data which appears on the invoice has changed.</span>
          )}
        </div>
        <div className="flex gap-4 py-6">
          {hasPriceChange ? (
            <InvoiceReminderPriceChange
              oldPriceInCents={oldPriceInCents}
              newPriceInCents={newPriceInCents}
            />
          ) : hasPublicationDateChange ? (
            <InvoiceReminderPublicationDateChange />
          ) : undefined}
        </div>
        <div className="text-column-gray-400">
          {!invoiceIsPaid ? (
            <span>
              The customer has not yet paid the invoice for this notice. Would
              you like to void the current invoice and create a new one?
            </span>
          ) : invoicedOutsideColumn ? (
            <span>
              You opted to issue the invoice for this notice outside of Column.
              If you wish to update the invoice to reflect the new price, please
              click "Update". Otherwise, Column may collect the incorrect fee
              amount for this notice from {publisherName}.
            </span>
          ) : paidDirectToPublisher ? (
            <span>
              The customer already paid this notice directly to {publisherName}.
              If you wish to update the invoice to reflect the new price, please
              click "Update" to create a new invoice. Otherwise, Column may
              collect the incorrect fee amount for this notice from{' '}
              {publisherName}.
            </span>
          ) : !enablePartialRefundsV2 ? (
            <span>
              {/* Invoice was paid in Column */}
              The customer already paid for this notice. If you wish to update
              the invoice to reflect the new price, please click "Update" to
              delete the current invoice and create a new one. Column will issue
              a refund to the customer for the full amount of the original
              payment.
            </span>
          ) : refundOrVoidDisabled ? (
            <span>
              If you wish to update the invoice to reflect the new price, you
              will need to reach out to Column customer support.
            </span>
          ) : (
            <span>
              {/* Invoice was paid in Column */}
              {/* New price is less than or equal to old price, and no new line items on invoice --> recourse is to potentially partially refund */}
              {oldPriceExceedsNewPrice &&
                !hasAdditionalNoticePubDatesNotOnInvoice &&
                modalToDisplay === 'refund-invoice' && (
                  <span>
                    The customer already paid for this notice. If you wish to{' '}
                    <span className="font-semibold">
                      refund $
                      {((oldPriceInCents - newPriceInCents) / 100).toFixed(2)}
                    </span>{' '}
                    on the invoice in order to reflect the new price, please
                    click "Refund" to proceed.
                  </span>
                )}
              {/* New price exceeds old price --> recourse is to void and recreate the invoice */}
              {(newPriceExceedsOldPrice ||
                (oldPriceExceedsNewPrice &&
                  hasAdditionalNoticePubDatesNotOnInvoice)) &&
                modalToDisplay === 'cancel-invoice' && (
                  <span>
                    The customer already paid for this notice. If you wish to
                    update the invoice to reflect the new price, please click
                    "Update" to delete the current invoice and create a new one.
                    Column will issue a refund to the customer for the full
                    amount of the original payment.
                  </span>
                )}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <CancelOrSubmitModal
      header="Update Invoice"
      tertiaryButtonText="Back"
      primaryButtonText={
        !enablePartialRefundsV2
          ? 'Update'
          : modalToDisplay === 'cancel-invoice' ||
            !canCancelInvoiceWithoutSupport(invoice)
          ? 'Update'
          : 'Refund'
      }
      secondaryButtonText="Skip"
      noExitOutsideModal
      onClose={() => handleSetOpen(false)}
      onSecondaryButtonClick={() => {
        onConfirmClicked({
          reinvoiceAfterPlacement: false,
          partialRefundAfterPlacement: false
        });
      }}
      onSubmit={() => {
        !enablePartialRefundsV2
          ? onConfirmClicked({
              reinvoiceAfterPlacement: true,
              partialRefundAfterPlacement: false
            })
          : onConfirmClicked(
              (hasPriceChange &&
                (invoiceNotPaidByCard ||
                  invoiceMarkedIOC ||
                  invoiceMarkedPaid)) ||
                newPriceExceedsOldPrice ||
                hasAdditionalNoticePubDatesNotOnInvoice
                ? {
                    reinvoiceAfterPlacement: true,
                    partialRefundAfterPlacement: false
                  }
                : oldPriceExceedsNewPrice
                ? {
                    reinvoiceAfterPlacement: false,
                    partialRefundAfterPlacement: true
                  }
                : {
                    reinvoiceAfterPlacement: false,
                    partialRefundAfterPlacement: false
                  }
            );
      }}
    >
      {renderBody()}
    </CancelOrSubmitModal>
  );
}

export default InvoiceReminderModal;
