import {
  Customer,
  CustomerOrganization,
  EInvoice,
  ENoticeDraft,
  EOrganization,
  ESnapshot,
  ESnapshotExists,
  exists
} from 'lib/types';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { getBooleanFlag } from 'utils/flags';
import { syncGetRequiresUpfrontPayment } from 'lib/notice/helpers';
import {
  getLastInvoiceForCustomer,
  getLastInvoiceForCustomerOrganization
} from 'lib/customers/invoices';
import { getFirebaseContext } from 'utils/firebase';
import { ApplyMinutesOffsetSettings } from 'lib/utils/dates';
import { logInfo } from 'utils/logger';

export const getDeadlineBufferMinutesForNoticePlacement = async (
  notice: ESnapshot<ENoticeDraft>,
  newspaper: ESnapshot<EOrganization> | undefined,
  customer: ESnapshotExists<Customer> | null | undefined,
  customerOrganization:
    | ESnapshotExists<CustomerOrganization>
    | null
    | undefined,
  isPublisher: boolean
): Promise<number> => {
  const enableDeadlineBuffer = getBooleanFlag(
    LaunchDarklyFlags.ENABLE_NOTICE_PLACEMENT_DEADLINE_BUFFER
  );

  const deadlineBufferMinutesForRUPNotice = 4 * 60;
  if (
    !exists(notice) ||
    !exists(newspaper) ||
    !enableDeadlineBuffer ||
    isPublisher
  ) {
    return 0;
  }

  let invoice: ESnapshotExists<EInvoice> | null =
    await getLastInvoiceForCustomerOrganization(
      getFirebaseContext(),
      customerOrganization
    );
  if (!exists(invoice)) {
    invoice = await getLastInvoiceForCustomer(getFirebaseContext(), customer);
  }

  const lastInvoiceIOC = invoice?.data()?.invoiceOutsideColumn;
  if (lastInvoiceIOC) {
    // If the last invoice was outside column, we don't need to apply the deadline buffer
    return 0;
  }

  const requireUpfrontPayment = syncGetRequiresUpfrontPayment(
    notice.data(),
    newspaper.data(),
    customer?.data(),
    customerOrganization?.data()
  );

  if (!requireUpfrontPayment) {
    return 0;
  }
  logInfo(
    `Using deadline buffer of ${deadlineBufferMinutesForRUPNotice} minutes for notice placement`,
    {
      noticeId: notice.id,
      newspaperId: newspaper.id,
      customerId: customer?.id,
      customerOrganizationId: customerOrganization?.id
    }
  );
  return deadlineBufferMinutesForRUPNotice;
};

export const getDeadlineBufferConfigFromMinutes = (
  minutes: number
): ApplyMinutesOffsetSettings => {
  return {
    offsetInMinutes: -1 * Math.abs(minutes),
    offsetAsBusinessHours: true
  };
};
