import { Product } from '../enums';
import { EOrganization, ERef, EUser, FirebaseTimestamp } from '.';
import { PublishingMedium } from '../enums/PublishingMedium';

export enum PublicationIssueStatus {
  /**
   * Soft-delete state for when the last deadline for a publication is deleted
   */
  DISABLED = 'disabled',

  /**
   * Not yet ready for pagination
   */
  PENDING = 'pending',

  /**
   * Ready for pagination
   */
  READY_FOR_PAGINATION = 'ready-for-pagination',

  /**
   * Approved by Column. Triggers email to publisher for approval.
   */
  AWAITING_APPROVAL = 'awaiting-approval',

  /**
   * Approved by the publisher. Triggers upload to publisher
   */
  APPROVED = 'approved',

  /**
   * Used by the publisher to indicate that issue notices have been laid out and
   * are ready to print.
   */

  PRINT_READY = 'print-ready',

  /**
   * Changes requested instead of approval
   */
  CHANGES_REQUESTED = 'changes-requested',

  /**
   * TODO(goodpaul): Occurs after publication date has passed
   */
  ARCHIVED = 'archived'
}

/**
 * Type to represent an issue for a publisher organization
 *
 * Contains subcollection publicationIssueAttachments and publicationIssueSections
 */
export type PublicationIssue = {
  /**
   * Currently only used for notices. Orders should use the status property on individual sections.
   */
  status: PublicationIssueStatus;
  publisher: ERef<EOrganization>;

  /**
   *  Date this issue is expected to publish in format YYYY-MM-DD. Does not track time and we assume
   *  there to be only 1 issue per publisher & publicationDate
   */
  publicationDate: string;

  /**
   * Date this issue is expected to be synced to the publisher
   */
  deadlineTimestamp: FirebaseTimestamp | null;

  createdAt: FirebaseTimestamp;
  modifiedAt: FirebaseTimestamp;

  statusChanges?: {
    status: PublicationIssueStatus;
    timestamp: FirebaseTimestamp;
  }[];

  /**
   * The user who is responsible for this publication issue
   */
  paginator?: ERef<EUser>;
};

export type CreatePublicationIssueParams = {
  // YYYY-MM-DD format
  publicationDate: string;
  publisherId: string;
};

export type PublicationIssueSearchRequest = {
  publisherIds: string[];

  // Converted to FirebaseTimestamp in this function
  deadlineTimestampFrom?: Date;
  // Converted to FirebaseTimestamp in this function
  deadlineTimestampTo?: Date;

  // YYYY-MM-DD format
  publicationDateFrom?: string;
  // YYYY-MM-DD format
  publicationDateTo?: string;

  statuses?: PublicationIssueStatus[];

  sectionType?: Product;
};

export type PublicationIssueSectionSearchRequest =
  PublicationIssueSearchRequest & {
    publishingMediums: PublishingMedium[];
  };
