import {
  all,
  take,
  takeEvery,
  call,
  put,
  race,
  fork,
  takeLatest,
  delay
} from 'redux-saga/effects';
import { appSagaSelect } from 'redux/hooks';
import { eventChannel } from 'redux-saga';
import { logAndCaptureException } from 'utils';
import * as Sentry from '@sentry/browser';

import {
  ESnapshot,
  EOrganization,
  ERef,
  EUser,
  FirebaseUser,
  exists,
  EResponseTypes,
  ESnapshotExists
} from 'lib/types';
import { push } from 'connected-react-router';
import api from 'api';
import { organizationToContextState } from 'lib/utils/organizations';
import { OrgContextState } from 'lib/types/organization';
import { getFirebaseContext } from 'utils/firebase';
import { getAllowedOrganizationSnaps } from 'lib/users';
import { handleError } from 'redux/errors.slice';
import { getLogger } from 'utils/logger';
import { getLocationParams } from 'lib/frontend/utils/browser';
import {
  shouldRunSessionRecording,
  startSessionReplay
} from 'utils/sessionRecording';
import { ColumnService } from 'lib/services/directory';
import AuthActions, { AuthTypes, authSelector, AuthState } from '../redux/auth';
import Firebase, {
  getUserClaims,
  signInWithCustomToken
} from '../EnoticeFirebase';
import { getSubdomain, getHostname } from '../utils/urls';
import {
  ENV,
  PROD,
  DEMO,
  SHOULD_RUN_CUSTOMER_IO,
  IS_LOCALHOST,
  IS_DEPLOY_PREVIEW
} from '../constants';
import { VoidAnyGenerator } from './types';
import * as flags from '../utils/flags';
import { SenderClaims } from '../../lib/types/custom';

export const UNABLE_TO_FETCH_USER = 'Unable to fetch user';

/**
 *
 * @param {snapshot} org Organization to watch
 * Firebase snapshot to watch for updates on
 */
export function* watchActiveOrg(org: ESnapshot<EOrganization> | null) {
  if (!org) return;

  const orgChannel = eventChannel(emitter =>
    org.ref.onSnapshot(emitter, err =>
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error listening to active org snapshot',
        {
          orgId: org.id
        }
      )
    )
  );
  // ignore the first update
  yield take(orgChannel);

  yield takeEvery(
    orgChannel,
    function* f(org: ESnapshot<EOrganization>): VoidAnyGenerator {
      const authState = yield* appSagaSelect(authSelector);
      const { user } = authState;
      if (!user) return;

      const userSnap = yield call(fetchUser, user.ref);
      const availableOrgs = yield call(getAllowedOrganizationSnaps, userSnap);

      yield put(AuthActions.setAvailableOrganizations(availableOrgs));
      yield put(AuthActions.setActiveOrganization(org));
    }
  );

  yield take(AuthTypes.LOGOUT_SUCCESS);
  orgChannel.close();
}

export function* listenActiveOrg(): VoidAnyGenerator {
  while (true) {
    const auth = yield* appSagaSelect(authSelector);
    yield race([
      call(watchActiveOrg, auth.activeOrganization),
      take(AuthTypes.SET_ACTIVE_ORGANIZATION)
    ]);
  }
}

export function* fetchUser(userRef: ERef<EUser>): VoidAnyGenerator {
  for (let i = 0; i < 5; i++) {
    try {
      const userSnap = yield call([userRef, userRef.get]);
      if (!userSnap.exists) throw new Error('User not found');
      return userSnap;
    } catch (err) {
      /**
       * This means we have a permanent error reaching Firestore and it's likely the user's
       * network will not allow the connection.
       *
       * We should suggest the following troubleshooting:
       * 1) Refresh the page
       * 2) If they've already refreshed and still see the error, change the setting
       */
      if (err && (err as any).code === 'unavailable') {
        logAndCaptureException(
          ColumnService.AUTH_AND_USER_MANAGEMENT,
          err,
          'User not found',
          {
            userId: userRef.id
          }
        );
        break;
      }

      // Retry fetching the user up to 5 times at 2000ms apart
      if (i < 4) {
        yield delay(2000);
      }
    }
  }
  yield put(
    yield call(handleError, {
      error: UNABLE_TO_FETCH_USER,
      service: ColumnService.AUTH_AND_USER_MANAGEMENT
    })
  );
  throw new Error('User fetch failed');
}

export function* watchActiveUser(userSnapshot: ESnapshot<EUser>) {
  const userChannel = eventChannel(emitter =>
    userSnapshot.ref.onSnapshot(emitter, err =>
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Error listening to user snapshot',
        {
          userId: userSnapshot.id
        }
      )
    )
  );
  yield takeEvery(userChannel, function* f(user: any) {
    // Update the LaunchDarkly user; do this before setting user in state, so any components that
    // re-render on user changes evaluate LD flags with the right user.
    yield call(flags.setUser, user);

    // Set the local user state
    yield put(AuthActions.setUser(user));
  });

  yield take(AuthTypes.LOGOUT_SUCCESS);
  userChannel.close();

  // Set the LaunchDarkly user to anonymous
  yield call(flags.setUser, undefined);

  // Clear the logging user
  getLogger().setUser(undefined);
}

export function* setUserParameters(
  user: FirebaseUser | null
): Generator<any, void, any> {
  try {
    const ctx = getFirebaseContext();
    /**
     * Exit early if the user is not signed in using any method
     * or is signed in anonymously
     */
    if (!user) {
      return;
    }
    yield put(AuthActions.setUserAuth(user));
    if (user.isAnonymous) {
      return;
    }
    const userRef = ctx.usersRef().doc(user.uid);
    const claims = (yield call(getUserClaims, user)) as SenderClaims;
    // Custom token method of sign-in that provides claims to individual record ids, e.g. obits
    if (claims.isClaimLogin) {
      yield put(AuthActions.setClaimLogin(true));
      if (claims.orderId) {
        yield put(AuthActions.addOrderIdClaim(claims.orderId));
      }
      return;
    }
    const userSnap = (yield call(fetchUser, userRef)) as ESnapshotExists<EUser>;

    yield put(AuthActions.setImpersonating(!!claims.impersonating));

    void userSnap.ref.update({
      firstSignInTime:
        userSnap.data().firstSignInTime ?? ctx.fieldValue().serverTimestamp(),
      lastSignInTime: ctx.fieldValue().serverTimestamp()
    });

    yield fork(watchActiveUser, userSnap);
    const userdata = userSnap.data() as EUser;
    if (!userdata.allowedOrganizations?.length) {
      yield put(AuthActions.setActiveOrganization(null));
      return;
    }

    const activeOrgQuery = getLocationParams().get('activeOrg');
    let orgFromQuery;
    if (activeOrgQuery) {
      const ref = ctx.organizationsRef().doc(activeOrgQuery);
      orgFromQuery = yield call([ref, ref.get]);

      const allowedOrganizationIds = userdata.allowedOrganizations.map(
        ref => ref.id
      );
      const userCanAccessOrgInQuery =
        orgFromQuery.exists &&
        (allowedOrganizationIds.includes(activeOrgQuery) ||
          allowedOrganizationIds.includes(orgFromQuery.data().parent?.id));
      if (!userCanAccessOrgInQuery) {
        orgFromQuery = null;
      }
    }
    let orgFromUserdata;
    if (userdata.activeOrganization) {
      orgFromUserdata = yield call([
        userdata.activeOrganization,
        userdata.activeOrganization.get
      ]);
    } else {
      orgFromUserdata = null;
    }

    const availableOrgs = yield call(getAllowedOrganizationSnaps, userSnap);
    yield put(AuthActions.setAvailableOrganizations(availableOrgs));

    yield put(
      AuthActions.setActiveOrganization(
        orgFromQuery || orgFromUserdata || availableOrgs[0]
      )
    );

    yield fork(listenActiveOrg);
  } catch (err) {
    logAndCaptureException(
      ColumnService.AUTH_AND_USER_MANAGEMENT,
      err,
      'Auth: Error in setUserParameters',
      {
        userEmail: user?.email || ''
      }
    );
  } finally {
    const { userAuth, user, isClaimLogin } = yield* appSagaSelect(authSelector);

    if (userAuth && !userAuth.isAnonymous && !user && !isClaimLogin) {
      yield take(AuthTypes.SET_USER);
    }

    yield put(AuthActions.endAuth());
  }
}

function* register() {
  yield call(setUserParameters, Firebase.auth().currentUser);
}

function* loginTokenFlow({ token }: { token: any }): VoidAnyGenerator {
  yield put(AuthActions.startAuth());
  yield call([Firebase.auth(), Firebase.auth().signOut]);
  yield call(signInWithCustomToken, token);
}

export function* anonymousLogin() {
  const { userAuth } = yield* appSagaSelect(authSelector);
  if (!userAuth) {
    try {
      yield put(AuthActions.startAuth());
      yield call([Firebase.auth(), Firebase.auth().signInAnonymously]);
      // Set the LaunchDarkly user to anonymous
      yield call(flags.setUser, undefined);
    } catch (err) {
      logAndCaptureException(
        ColumnService.AUTH_AND_USER_MANAGEMENT,
        err,
        'Auth: Error in anonymousLogin'
      );
      yield put(
        AuthActions.setAuthError('Something went wrong in anonymous sign in')
      );
    } finally {
      yield put(AuthActions.endAuth());
    }
  }
}

function* listenOnAuth() {
  const authChannel = eventChannel(emitter =>
    Firebase.auth().onAuthStateChanged(authState => {
      if (authState) {
        emitter(authState);

        const email = authState.email || '';
        const name = authState.displayName || '';

        if (ENV === PROD || ENV === DEMO) {
          Sentry.configureScope(scope =>
            scope.setUser({
              email
            })
          );
        }

        if (shouldRunSessionRecording(ENV)) {
          startSessionReplay({
            email,
            userId: authState.uid,
            name
          });
        }

        if (SHOULD_RUN_CUSTOMER_IO) {
          try {
            (window as any)._cio.identify({ id: authState.email });
          } catch (err) {
            logAndCaptureException(
              ColumnService.WEB_PLACEMENT,
              err,
              'Auth: Failed to initialize customer.io',
              {
                email
              }
            );
          }
        }
      } else {
        emitter(false);
      }
    })
  );
  yield takeEvery(authChannel, setUserParameters);
}

function* logoutFlow() {
  yield put(AuthActions.startAuth());
  yield call([Firebase.auth(), Firebase.auth().signOut]);
  yield put(AuthActions.logoutSuccess());
  sessionStorage.clear();
  yield put(push('/login'));
}

/** Get the current relevant subdomain */
export const getContextKey = () => {
  if (IS_LOCALHOST || IS_DEPLOY_PREVIEW) {
    return null;
  }

  const hostname = getHostname();
  if (['publicnoticecolorado'].indexOf(hostname) !== -1) return hostname;

  const subdomain = getSubdomain();

  // Don't count www.column.us or demo.enotice.io as valid subdomains
  if (subdomain === 'www') return null;
  if (subdomain === 'demo') return null;
  if (subdomain === 'column-staging') return null;

  return subdomain;
};

/** Make a request to get the public organization information for the subdomain */
async function getSubdomainOrgContext(): Promise<
  EResponseTypes['organizations/:subdomain/context']
> {
  const contextKey = getContextKey();
  if (!contextKey) return null;
  const orgContext: EResponseTypes['organizations/:subdomain/context'] =
    await api.get(`organizations/${contextKey}/context`);
  return orgContext;
}

/**
 * Sets the organization context from the current subdomain or hostname
 * or, if one exists, from the current active organization
 */
function* getOrgContext(): VoidAnyGenerator {
  const orgContext: OrgContextState = yield call(getSubdomainOrgContext);

  if (orgContext) {
    yield put(AuthActions.setOrgContext(orgContext));
  } else {
    const { activeOrganization } = yield take(
      AuthTypes.SET_ACTIVE_ORGANIZATION
    );
    if (activeOrganization) {
      yield put(
        AuthActions.setOrgContext(
          organizationToContextState(activeOrganization)
        )
      );
    }
  }
}

function* updateActiveOrganization({
  activeOrganization
}: {
  activeOrganization: ESnapshot<EOrganization>;
}) {
  try {
    if (activeOrganization) {
      const { user }: AuthState = yield* appSagaSelect(authSelector);
      if (user) {
        yield call([user.ref, user.ref.update], {
          activeOrganization: activeOrganization.ref
        });
      }
      yield call(getOrgContext);
    }
  } catch (err) {
    logAndCaptureException(
      ColumnService.AUTH_AND_USER_MANAGEMENT,
      err,
      'Auth: Error in updateActiveOrganization',
      {
        activeOrgId: activeOrganization.id
      }
    );
  }
}

function* initializeProductTracking({ user }: { user?: ESnapshot<EUser> }) {
  if (!exists(user)) return;

  // When using DataDog, set the user
  getLogger().setUser({ id: user.id, email: user.data().email });
}

export default function* root() {
  yield fork(getOrgContext);
  yield fork(listenOnAuth);
  yield all([
    takeEvery(AuthTypes.REGISTER, register),
    takeEvery(AuthTypes.ANONYMOUS_LOGIN, anonymousLogin),
    takeEvery(AuthTypes.LOGIN_TOKEN, action => loginTokenFlow(action as any)),
    takeEvery(AuthTypes.LOGOUT, logoutFlow),
    takeEvery(AuthTypes.SET_ACTIVE_ORGANIZATION, action =>
      updateActiveOrganization(action as any)
    ),
    takeEvery(AuthTypes.SET_USER, getOrgContext),
    takeLatest(
      [AuthTypes.SET_USER, AuthTypes.SET_ACTIVE_ORGANIZATION],
      action => initializeProductTracking(action as any)
    )
  ]);
}
