import api from 'api';
import { getCurrencySymbol } from 'lib/helpers';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import {
  EInvoice,
  ENotice,
  EOrganization,
  EResponseTypes,
  ESnapshotExists
} from 'lib/types';
import { LineItem } from 'lib/types/invoices';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useEffect } from 'react';
import InvoiceFormTable from '../billing/oldCreateInvoice/InvoiceFormTable';
import RefundInvoiceModalInnerInvoiceTotalTable from './RefundInvoiceModalInnerInvoiceTotal';

const fetchCompletedRefundLineItems = async (
  invoiceId: string
): Promise<LineItem[]> => {
  const res: EResponseTypes['invoice-transactions/:invoiceId/refund-transactions'] =
    await api.get(`invoice-transactions/${invoiceId}/refund-transactions`);
  if (res.success) {
    return res.refundLineItems;
  }
  return [];
};

type RefundInvoiceModalInnerDisplayBoxProps = {
  notice: ESnapshotExists<ENotice>;
  newspaper: ESnapshotExists<EOrganization>;
  invoice: ESnapshotExists<EInvoice>;
  lineItemValues: LineItem[];
  invoicePubLineItemDiffs: LineItem[];
  totalRefundedInCentsFromPriorInvoiceTransactions: number;
  setTotalRefundedInCentsFromPriorInvoiceTransactions: (arg0: number) => void;
};

export default function RefundInvoiceModalInnerDisplayBox({
  notice,
  newspaper,
  invoice,
  lineItemValues,
  invoicePubLineItemDiffs,
  totalRefundedInCentsFromPriorInvoiceTransactions,
  setTotalRefundedInCentsFromPriorInvoiceTransactions
}: RefundInvoiceModalInnerDisplayBoxProps) {
  const currency = getCurrencySymbol(newspaper.data().currency);

  const { value: priorRefundsLineItems } = useAsyncEffect({
    fetchData: () => fetchCompletedRefundLineItems(invoice.id),
    dependencies: [invoice.id]
  });

  useEffect(() => {
    if (priorRefundsLineItems === null) {
      setTotalRefundedInCentsFromPriorInvoiceTransactions(0);
    }
    if (!priorRefundsLineItems || priorRefundsLineItems.length === 0) {
      setTotalRefundedInCentsFromPriorInvoiceTransactions(0);
    }

    const totalRefundedInCents = priorRefundsLineItems
      ? priorRefundsLineItems.reduce((acc: number, curr: LineItem) => {
          return acc + curr.amount ?? 0;
        }, 0)
      : 0;

    setTotalRefundedInCentsFromPriorInvoiceTransactions(totalRefundedInCents);
  }, [priorRefundsLineItems]);

  return (
    <CardGridLayout
      header={{
        title: `Paid Invoice Review`,
        description: `Paid invoice for notice ${notice.id} ("${
          notice.data().referenceId
        }")`
      }}
    >
      <GridInput fullWidth>
        <InvoiceFormTable
          values={lineItemValues}
          diffValues={{
            invoicePubLineItemDiffValues: invoicePubLineItemDiffs,
            noticePubDateDiffValues: null
          }}
          currency={currency}
          handleChange={() => {}}
          preventPricingModification
        />
      </GridInput>
      <GridInput fullWidth>
        <RefundInvoiceModalInnerInvoiceTotalTable
          notice={notice}
          invoice={invoice}
          newspaper={newspaper}
          refunds={priorRefundsLineItems}
          totalRefundedInCentsFromPriorInvoiceTransactions={
            totalRefundedInCentsFromPriorInvoiceTransactions
          }
        />
      </GridInput>
    </CardGridLayout>
  );
}
