import LoadingState from 'components/LoadingState';
import { TextField } from 'lib/components/TextField';
import { State, enumToSelectInput, Product } from 'lib/enums';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { Customer, ESnapshot, ESnapshotExists, EUser, exists } from 'lib/types';
import {
  Order,
  AnonymousOrderContactInfo,
  AnonymousOrder,
  isPublisherAsAdvertiserOrder
} from 'lib/types/order';
import {
  selectActiveOrganization,
  selectIsPublisher,
  selectIsUserLoggedOut
} from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import CustomerSearch from 'routes/placeScroll/ConfirmFilerStep/CustomerSearch';
import { getUserByEmail, isEmailRegistered } from 'utils/users';
import CustomerService from 'lib/services/customerService';
import { getFirebaseContext } from 'utils/firebase';
import { useState } from 'react';
import { GridInput } from 'lib/components/Card/Grid';
import { getModelFromSnapshot } from 'lib/model';
import { CustomerModel } from 'lib/model/objects/customerModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { Alert } from 'lib/components/Alert';
import { ColumnService } from 'lib/services/directory';
import PlacementLoginModal from 'routes/placeScroll/PlacementLoginModal';
import ToastActions from 'redux/toast';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import MultiStepHeader from '../components/MultiStepHeader';
import NativeSelect from './FlowChoice/FuneralHomeSearch/CustomerCreation/NativeSelect';

export const MINIMUM_ORDER: AnonymousOrderContactInfo = {
  contactEmail: '',
  firstName: '',
  lastName: '',
  phone: ''
};

type PersonalDetailProps = {
  product: Product;
  inputData: Partial<AnonymousOrder>;
  setInputData: (inputData: Partial<AnonymousOrder>) => void;
};

function PersonalDetail({
  product,
  inputData,
  setInputData
}: PersonalDetailProps) {
  const dispatch = useAppDispatch();
  const isPublisher = useAppSelector(selectIsPublisher);
  const isLoggedOut = useAppSelector(selectIsUserLoggedOut);

  const customerRef = isPublisherAsAdvertiserOrder(inputData)
    ? inputData.advertiserCustomer
    : null;

  const activeOrganization = useAppSelector(selectActiveOrganization);

  const debouncedEmail = useDebounce(inputData.contactEmail, 500);

  const [customerExists, setCustomerExists] = useState(false);

  const [showEnterPasswordModal, setShowEnterPasswordModal] = useState(false);

  const customerService = new CustomerService(getFirebaseContext());

  const [filerOrganizationNames, setFilerOrganizationNames] = useState<
    string[]
  >([]);

  const setInputDataFromCustomer = async (
    customerSnap: ESnapshot<Customer>
  ): Promise<ResponseOrError<void>> => {
    if (!exists(customerSnap)) {
      setCustomerExists(false);

      return wrapSuccess(undefined);
    }

    const customer = getModelFromSnapshot(
      CustomerModel,
      getFirebaseContext(),
      customerSnap
    );

    const contactEmailResult = await customer.getEmail();

    if (contactEmailResult.error) {
      return contactEmailResult;
    }

    const userResult = await customer.getUser();

    if (userResult.error) {
      return userResult;
    }

    const filerOrganizationModelResult =
      await userResult.response.getAllowedOrganizations();

    if (filerOrganizationModelResult.error) {
      return filerOrganizationModelResult;
    }

    const allowedOrganizationNames = filerOrganizationModelResult.response.map(
      model => model.modelData.name
    );

    setInputData({
      ...inputData,
      firstName: customer.modelData.firstName,
      lastName: customer.modelData.lastName || '',
      phone: customer.modelData.phone || '',
      contactEmail: contactEmailResult.response,
      addressLine1: customer.modelData.address || '',
      addressLine2: customer.modelData.addressLine2 || '',
      city: customer.modelData.city || '',
      state: customer.modelData.state || 0,
      zip: customer.modelData.zipCode || '',
      organizationName: allowedOrganizationNames[0] || ''
    });

    setCustomerExists(true);

    setFilerOrganizationNames(allowedOrganizationNames);

    return wrapSuccess(undefined);
  };

  const setInputDataFromUser = async (
    userSnap: ESnapshotExists<EUser>
  ): Promise<ResponseOrError<void>> => {
    if (!activeOrganization) {
      setCustomerExists(false);

      return wrapSuccess(undefined);
    }

    const { error, response: customerSnap } =
      await customerService.getCustomerFromUserAndOrg(
        userSnap.ref,
        activeOrganization.ref
      );
    if (error) {
      return wrapSuccess(undefined);
    }

    return setInputDataFromCustomer(customerSnap);
  };

  const enableClassifiedOnlyFeature = product === Product.Classified;

  // LoginModal will only prompt to classified customers who are anonymous and have a registered email address
  const shouldShowLoginModalToAnonymousClassifiedCustomer =
    isLoggedOut &&
    inputData?.contactEmail?.includes('@') &&
    enableClassifiedOnlyFeature;

  // Customer search is only enabled for publishers when the product is classified and flag is enabled
  const enableCustomerSearchForPublisher =
    enableClassifiedOnlyFeature && isPublisher;

  const disableInputs = enableClassifiedOnlyFeature && customerExists;

  const { isError, isLoading } = useSafeAsyncEffect({
    fetchData: async () => {
      // Anonymous classified placements with a registered email address will be prompted to login
      if (shouldShowLoginModalToAnonymousClassifiedCustomer) {
        const shouldLogIn = await isEmailRegistered(
          inputData.contactEmail || ''
        );
        if (shouldLogIn) {
          setShowEnterPasswordModal(true);
          setCustomerExists(true);
          return wrapSuccess(undefined);
        }
      }
      if (customerRef) {
        const customerSnap = await customerRef.get();

        return setInputDataFromCustomer(customerSnap);
      }

      // This condition serves to prevent customer matching for obituaries
      if (!enableCustomerSearchForPublisher) {
        setCustomerExists(false);

        return wrapSuccess(undefined);
      }

      try {
        if (inputData.contactEmail?.includes('@')) {
          const userSnap = await getUserByEmail(inputData.contactEmail);

          if (exists(userSnap)) {
            return setInputDataFromUser(userSnap);
          }
        }
        setCustomerExists(false);
        return wrapSuccess(undefined);
      } catch (e) {
        return wrapError(e as Error);
      }
    },
    dependencies: [debouncedEmail],
    errorConfig: {
      message: 'Failed to load customer information',
      service: ColumnService.ORDER_PLACEMENT,
      tags: {
        customerId: customerRef?.id || '',
        email: inputData.contactEmail || ''
      }
    }
  });

  // Show loading spinner when publisher placing on behalf of existing user to fetching existing classified customer data -- appear on all fields except email
  const showLoading = enableCustomerSearchForPublisher && isLoading;

  // Show loading spinner on email field when checking email for registered classified user while placing anonymously
  const isCheckingEmailForRegisteredUserLogin =
    isLoading && isLoggedOut && enableClassifiedOnlyFeature;

  if (isError) {
    return (
      <Alert
        id="error"
        status="error"
        description="Failed to load customer info"
      />
    );
  }

  const {
    firstName = '',
    lastName = '',
    contactEmail = '',
    phone = '',
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = 0,
    zip = '',
    organizationName = ''
  } = {
    ...MINIMUM_ORDER,
    ...inputData
  };

  return (
    <>
      <MultiStepHeader
        title={isPublisher ? 'Customer Information' : 'Your Information'}
        description={
          isPublisher
            ? 'If you are submitting on behalf of a customer, please provide their contact information.'
            : 'Publications may use this to contact you throughout the publication process.'
        }
      />
      <div className="grid md:grid-cols-2 gap-3 mb-8">
        {enableCustomerSearchForPublisher && exists(activeOrganization) && (
          <GridInput fullWidth>
            <CustomerSearch
              publicationSnap={activeOrganization}
              onSelect={async selectedUser =>
                setInputDataFromUser(selectedUser)
              }
              active
            />
          </GridInput>
        )}
        <GridInput>
          <TextField
            id="first-name"
            value={firstName}
            type="text"
            onChange={value => setInputData({ ...inputData, firstName: value })}
            onBlur={() =>
              setInputData({
                ...inputData,
                firstName: firstName.trim()
              })
            }
            labelText="First name"
            placeholder="Jane"
            required
            disabled={disableInputs}
            suffix={showLoading && <LoadingState isFullScreen={false} />}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="last-name"
            value={lastName}
            type="text"
            onChange={value => setInputData({ ...inputData, lastName: value })}
            placeholder="Doe"
            onBlur={() =>
              setInputData({
                ...inputData,
                lastName: lastName.trim()
              } as Partial<Order>)
            }
            labelText="Last name"
            required
            disabled={disableInputs}
            suffix={showLoading && <LoadingState isFullScreen={false} />}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="email"
            value={contactEmail}
            type="email"
            onChange={value => {
              setInputData({
                ...inputData,
                contactEmail: value,
                advertiser: undefined,
                advertiserCustomer: undefined,
                advertiserOrganization: undefined
              } as Partial<Order>);
            }}
            labelText="Email address"
            placeholder={
              isPublisher ? 'yourclient@gmail.com' : 'youremail@gmail.com'
            }
            disabled={showEnterPasswordModal}
            required
            suffix={
              isCheckingEmailForRegisteredUserLogin && (
                <LoadingState isFullScreen={false} />
              )
            }
          />
        </GridInput>
        <GridInput>
          <TextField
            id="phone"
            value={phone}
            type="tel"
            onChange={value => setInputData({ ...inputData, phone: value })}
            labelText="Phone number"
            placeholder="(000) 000-0000"
            required={!disableInputs}
            disabled={disableInputs}
            suffix={showLoading && <LoadingState isFullScreen={false} />}
          />
        </GridInput>
        {enableClassifiedOnlyFeature && (
          <>
            <GridInput>
              <TextField
                id="address-line-1"
                value={addressLine1}
                type="text"
                onChange={value =>
                  setInputData({ ...inputData, addressLine1: value })
                }
                labelText="Street address"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              <TextField
                id="address-line-2"
                value={addressLine2}
                type="text"
                onChange={value =>
                  setInputData({ ...inputData, addressLine2: value })
                }
                labelText="Apt/Suite"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              <TextField
                id="city"
                value={city}
                type="text"
                onChange={value => setInputData({ ...inputData, city: value })}
                labelText="City"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              <NativeSelect
                id="state"
                labelText="State"
                allowUndefined
                placeholder="State"
                options={enumToSelectInput(State)}
                value={`${state ?? ''}`}
                onChange={value => {
                  setInputData({
                    ...inputData,
                    state: value ? Number(value) : undefined
                  });
                }}
                disabled={disableInputs}
              />
            </GridInput>
            <GridInput>
              <TextField
                id="zip"
                value={zip}
                type="postal-code"
                onChange={value => setInputData({ ...inputData, zip: value })}
                labelText="Zip code"
                disabled={disableInputs}
                suffix={showLoading && <LoadingState isFullScreen={false} />}
              />
            </GridInput>
            <GridInput>
              {customerExists && filerOrganizationNames?.length ? (
                <ColumnSelect
                  id="organization-name"
                  labelText="Organization"
                  value={organizationName}
                  disabled={filerOrganizationNames.length === 1}
                  options={filerOrganizationNames.map(filerOrganizationName => {
                    return {
                      label: filerOrganizationName,
                      value: filerOrganizationName
                    };
                  })}
                  onChange={value =>
                    setInputData({ ...inputData, organizationName: value })
                  }
                />
              ) : (
                <TextField
                  id="organization-name"
                  value={organizationName}
                  type="text"
                  onChange={value =>
                    setInputData({ ...inputData, organizationName: value })
                  }
                  labelText="Organization"
                  disabled={disableInputs}
                  suffix={showLoading && <LoadingState isFullScreen={false} />}
                />
              )}
            </GridInput>
          </>
        )}
      </div>
      {showEnterPasswordModal && (
        <PlacementLoginModal
          onClose={() => {
            setShowEnterPasswordModal(false);
            dispatch(
              ToastActions.toastSuccess({
                headerText: 'Successfully logged in',
                bodyText: 'You can now continue with your order.'
              })
            );
          }}
          onBackClick={() => {
            setInputData({
              ...inputData,
              contactEmail: ''
            });
            setShowEnterPasswordModal(false);
          }}
          email={inputData.contactEmail || ''}
          productType={Product.Classified}
        />
      )}
    </>
  );
}

export default PersonalDetail;
