import { Form } from 'lib/components/Form';
import { getOrganizationGateway } from 'lib/billing/gateways';
import { replaceUndefinedWithDelete } from 'lib/helpers';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import React from 'react';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { getFirebaseContext } from 'utils/firebase';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { AllowedPaymentMethods } from './AllowedPaymentMethods';
import { DistributeFeeSettings } from './DistributeFeeSettings';
import { InheritableSettingSelectInput } from '../../InheritableSettingSelectInput';

const getImplementationSettingsFromOrg = (
  organization: ESnapshot<EOrganization> | null
) => {
  if (!exists(organization)) {
    return null;
  }

  const {
    allowInvoiceOutsideColumn,
    sendInvoiceNotificationsForIOCNotices,
    disableControlUpfrontPayment,
    restrictPaymentMethods,
    distributeEnoticeFee
  } = organization.data();

  return {
    allowInvoiceOutsideColumn,
    sendInvoiceNotificationsForIOCNotices,
    disableControlUpfrontPayment,
    restrictPaymentMethods,
    distributeEnoticeFee
  };
};

export function ImplementationBillingSettings() {
  const activeOrganization = useActiveOrganizationListener();

  const currentSettings = getImplementationSettingsFromOrg(activeOrganization);

  const { editable, onChange, disabled, onSubmitWrapper, formSettings } =
    useEditableCard(currentSettings);

  const { value: gateway } = useAsyncEffect({
    fetchData: () => getOrganizationGateway(activeOrganization?.ref),
    dependencies: [activeOrganization?.id, activeOrganization?.data()?.gateway]
  });

  // Shouldn't be possible, but needed for type narrowing.
  if (!activeOrganization || !formSettings) {
    return null;
  }

  return (
    <Form
      id="publisher-settings-billing-implementation"
      onSubmit={() =>
        onSubmitWrapper(async () => {
          await activeOrganization.ref.update(
            replaceUndefinedWithDelete(getFirebaseContext(), formSettings)
          );
          return { success: true };
        })
      }
      aria-label="Implementation Settings"
    >
      <CardGridLayout
        header={{
          title: 'Implementation Settings',
          description: 'Configure implementation-only billing settings.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <LabeledSwitch
            label="Allow invoicing outside of Column?"
            description="If enabled, the publisher can enable IOC for customers, notice types, and notices."
            value={!!formSettings.allowInvoiceOutsideColumn}
            onChange={allowInvoiceOutsideColumn =>
              onChange({ allowInvoiceOutsideColumn })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <InheritableSettingSelectInput
            id="exclusive-ioc-and-rup-setting"
            labelText={'Send invoices for IOC notices?'}
            optionText={{
              yesLabel:
                'Yes — send customers emails with invoice PDFs for notices marked IOC',
              noLabel:
                'No — do not send customers emails for notices marked IOC',
              defaultLabel: 'Default — use default settings on parent (if any)'
            }}
            value={formSettings.sendInvoiceNotificationsForIOCNotices}
            onChange={sendInvoiceNotificationsForIOCNotices =>
              onChange({ sendInvoiceNotificationsForIOCNotices })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput fullWidth>
          <InheritableSettingSelectInput
            id="invoice-outside-column-email-setting"
            labelText={'Make upfront payment and IOC mutually exclusive?'}
            optionText={{
              yesLabel:
                'Yes — users cannot create invoices where RUP and IOC are both true or both false',
              noLabel:
                'No — users can create invoices with any combination of RUP and IOC',
              defaultLabel: 'Default — use default settings on parent (if any)'
            }}
            value={formSettings.disableControlUpfrontPayment}
            onChange={disableControlUpfrontPayment =>
              onChange({ disableControlUpfrontPayment })
            }
            disabled={disabled}
          />
        </GridInput>
        <DistributeFeeSettings
          distributeEnoticeFee={formSettings.distributeEnoticeFee}
          onChange={distributeEnoticeFee => onChange({ distributeEnoticeFee })}
          disabled={disabled}
        />
        {gateway === 'stripe' && (
          <AllowedPaymentMethods
            restrictPaymentMethods={formSettings.restrictPaymentMethods}
            onChange={restrictPaymentMethods =>
              onChange({ restrictPaymentMethods })
            }
            disabled={disabled}
          />
        )}
      </CardGridLayout>
    </Form>
  );
}
