import { TextField } from 'lib/components/TextField';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { FilingType } from 'lib/types/filingType';

type LabelFieldProps<T extends FilingType> = {
  onUpdateFilingType: (newValue: T) => void;
  updatedFilingType: T;
};

type FilingTypeLabelFieldProps<T extends FilingType> = LabelFieldProps<T> & {
  productLine: Product;
};

type FilingTypeLabelSelectProps<T extends FilingType> =
  FilingTypeLabelFieldProps<T>;

function FilingTypeLabel<T extends FilingType>({
  updatedFilingType,
  productLine
}: FilingTypeLabelSelectProps<T>) {
  const productNameSingular = PRODUCT_TO_NAME[productLine].singular;

  return (
    <TextField
      id="filing-type-label"
      labelText={`${productNameSingular} type name`}
      noteText="This appears in the placement flow if an advertiser visits www.column.us"
      required
      value={updatedFilingType.label}
      disabled
    />
  );
}

function NoticeTypeLabelField<T extends FilingType>({
  onUpdateFilingType,
  updatedFilingType
}: LabelFieldProps<T>) {
  return (
    <TextField
      id="filing-type-label"
      labelText="Notice type name (this appears in the placement flow)"
      required
      value={updatedFilingType.label}
      onChange={newValue =>
        onUpdateFilingType({
          ...updatedFilingType,
          label: newValue
        })
      }
    />
  );
}

export function FilingTypeLabelField<T extends FilingType>({
  onUpdateFilingType,
  updatedFilingType,
  productLine
}: FilingTypeLabelFieldProps<T>) {
  switch (productLine) {
    case Product.Notice:
      return (
        <NoticeTypeLabelField
          onUpdateFilingType={onUpdateFilingType}
          updatedFilingType={updatedFilingType}
        />
      );
    default:
      return (
        <FilingTypeLabel
          onUpdateFilingType={onUpdateFilingType}
          updatedFilingType={updatedFilingType}
          productLine={productLine}
        />
      );
  }
}
