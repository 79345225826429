import ColumnDatePicker from 'components/ColumnDatePicker';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Label } from 'lib/components/Label';
import moment from 'moment';
import React from 'react';
import {
  ALL_INVOICE_CREATION_DATES,
  CreationDatesOptionValue,
  CUSTOM_INVOICE_CREATION_DATES,
  InvoiceTableFilter,
  INVOICE_CREATION_DATE_OPTIONS,
  INVOICE_STATUSE_OPTIONS,
  LAST_MONTH_INVOICE_CREATION_DATES,
  THIS_MONTH_INVOICE_CREATION_DATES,
  INVOICE_TABLE_BULK_INVOICE_FILTER_VALUES,
  ALL_INVOICES_INVOICE_TYPE,
  BULK_INVOICES_INVOICE_TYPE,
  SINGLE_INVOICES_INVOICE_TYPE
} from './invoiceTableSettingsUtils';

type InvoicesTableFilterDialogProps = {
  setUpdatedInvoiceFilter: (filter: InvoiceTableFilter) => void;
  updatedInvoiceTableFilter: InvoiceTableFilter;
  enableInvoiceTypeFiltering: boolean;
};

export default function InvoicesTableFilterDialog({
  setUpdatedInvoiceFilter,
  updatedInvoiceTableFilter,
  enableInvoiceTypeFiltering
}: InvoicesTableFilterDialogProps) {
  const isCustomDateRange = ![
    ALL_INVOICE_CREATION_DATES,
    THIS_MONTH_INVOICE_CREATION_DATES,
    LAST_MONTH_INVOICE_CREATION_DATES,
    {}
  ].includes(updatedInvoiceTableFilter.dateRange);

  // parse the date range into a more easily typed format
  const currentDateRange = isCustomDateRange
    ? updatedInvoiceTableFilter.dateRange
    : null;

  return (
    <>
      <p className="uppercase text-column-gray-300 text-xs font-bold">
        Filter by
      </p>

      {/* Control what type of invoices we're looking at (bulk or not bulk) */}
      {enableInvoiceTypeFiltering && (
        <ColumnSelect<INVOICE_TABLE_BULK_INVOICE_FILTER_VALUES>
          id="invoice-type"
          labelText="Invoice Type"
          size="small"
          options={[
            { label: 'All', value: ALL_INVOICES_INVOICE_TYPE },
            { label: 'Bulk Invoices', value: BULK_INVOICES_INVOICE_TYPE },
            { label: 'Single Invoices', value: SINGLE_INVOICES_INVOICE_TYPE }
          ]}
          value={updatedInvoiceTableFilter.bulkInvoiceFilter}
          onChange={newValue =>
            setUpdatedInvoiceFilter({
              ...updatedInvoiceTableFilter,
              bulkInvoiceFilter: newValue
            })
          }
        />
      )}

      {/* Control what subset of publication days we're looking at */}
      <ColumnSelect
        id="status"
        labelText="Status"
        size="small"
        options={INVOICE_STATUSE_OPTIONS}
        value={updatedInvoiceTableFilter.invoiceTableStatus}
        onChange={newValue =>
          setUpdatedInvoiceFilter({
            ...updatedInvoiceTableFilter,
            invoiceTableStatus:
              newValue as InvoiceTableFilter['invoiceTableStatus']
          })
        }
      />

      {/* Control the publication dates visible in the table */}
      <ColumnSelect
        id="date-created"
        labelText="Date Created"
        size="small"
        options={INVOICE_CREATION_DATE_OPTIONS}
        value={
          isCustomDateRange
            ? CUSTOM_INVOICE_CREATION_DATES
            : updatedInvoiceTableFilter.dateRange.toString()
        }
        onChange={newValue => {
          if (newValue === CUSTOM_INVOICE_CREATION_DATES) {
            setUpdatedInvoiceFilter({
              ...updatedInvoiceTableFilter,
              dateRange: {
                start: moment().subtract(1, 'month').toDate(),
                end: moment().toDate()
              }
            });
          } else {
            setUpdatedInvoiceFilter({
              ...updatedInvoiceTableFilter,
              dateRange: newValue as CreationDatesOptionValue
            });
          }
        }}
      />

      {/* Filter on a custom date range */}
      {currentDateRange && typeof currentDateRange !== 'string' && (
        <div>
          {/* TODO: Update ColumnDatePicker to support InputAccessories */}
          <Label id="date-filter-label">Choose a date range</Label>
          <div className="flex flex-row gap-2">
            <ColumnDatePicker
              className="w-1/2"
              format="MMM dd"
              momentFormat="MMM DD"
              placeholderText="Start date"
              value={currentDateRange?.start}
              onChange={newDate =>
                newDate &&
                setUpdatedInvoiceFilter({
                  ...updatedInvoiceTableFilter,
                  dateRange: {
                    ...currentDateRange,
                    start: newDate
                  }
                })
              }
              shouldDisableDate={date =>
                !date || moment(date).isAfter(currentDateRange.end)
              }
            />
            <ColumnDatePicker
              className="w-1/2"
              format="MMM dd"
              momentFormat="MMM DD"
              placeholderText="End date"
              value={currentDateRange.end}
              onChange={newDate =>
                newDate &&
                setUpdatedInvoiceFilter({
                  ...updatedInvoiceTableFilter,
                  dateRange: {
                    ...currentDateRange,
                    end: newDate
                  }
                })
              }
              shouldDisableDate={date => !date || moment().isBefore(date)}
            />
          </div>
        </div>
      )}
    </>
  );
}
