import React, { useEffect, useState } from 'react';
import { safeStringify } from 'lib/utils/stringify';
import {
  Customer,
  CustomerOrganization,
  EOrganization,
  EQuerySnapshot,
  ERate,
  EResponseTypes,
  ESnapshot,
  ESnapshotExists,
  Note,
  exists
} from 'lib/types';
import { removeUndefinedFields } from 'lib/helpers';
import { DotIcon } from 'icons';
import { BillingTermType, Product, RoleType } from 'lib/enums';
import api from 'api';
import { CustomerOrganizationTableData } from 'routes/settings/publisher/customers/customerOrganizationTable/utils';
import TabGroup, { TabOption } from 'lib/components/Tabs';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import Drawer from 'lib/components/Drawer';
import { OrganizationTypeData } from 'lib/enums/OrganizationType';

import { getRateSnapshotFromFilingTypeLabel } from 'utils/organizations';
import { ColumnService } from 'lib/services/directory';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import { getFirebaseContext } from '../../utils/firebase';
import EditCustomerOrganizationModal from './EditCustomerOrganizationModal';
import { CustomerDrawerDetailsTab } from './CustomerDrawerDetailsTab';
import { CustomerOrganizationDrawerMembersTab } from './CustomerOrganizationDrawerMembersTab';
import { CustomerDrawerSettingsTab } from './CustomerDrawerSettingsTab';
import { CustomerOrganizationDrawerFooter } from './CustomerOrganizationDrawerFooter';
import NotesTab from './NotesTab/NotesTab';

const DETAILS_TAB: TabOption = {
  label: 'Details',
  enabled: true,
  id: 'details'
};
const MEMBERS_TAB: TabOption = {
  label: 'Members',
  enabled: false,
  id: 'members'
};
const SETTINGS_TAB: TabOption = {
  label: 'Settings',
  enabled: true,
  id: 'settings'
};

const getCustomerOrganizationDrawerTabs = (
  hasMembers: boolean,
  notesQuery: EQuerySnapshot<Note> | null
) => {
  const numberOfNotes =
    notesQuery?.docs.filter(doc => doc.data().status !== 'archived').length ||
    0;
  return [
    DETAILS_TAB,
    {
      ...MEMBERS_TAB,
      enabled: hasMembers
    },
    SETTINGS_TAB,
    {
      label: numberOfNotes > 0 ? `Notes (${numberOfNotes})` : 'Notes',
      enabled: true,
      id: 'notes'
    }
  ];
};

type CustomerOrganizationDrawerProps = {
  customerOrganizationData: CustomerOrganizationTableData;
  activeOrganization: ESnapshot<EOrganization>;
  open: boolean;
  closeDrawer: () => void;
  disableDarkenBackground?: boolean;
  setShowCustomerDrawer: (show: boolean) => void;
  setCustomerId: (id: string | null) => void;
  setCustomerOrg: (customerOrg?: CustomerOrganizationTableData) => void;
  rates: ESnapshotExists<ERate>[];
};

export default function CustomerOrganizationDrawer({
  customerOrganizationData,
  activeOrganization,
  open,
  closeDrawer,
  disableDarkenBackground,
  setShowCustomerDrawer,
  setCustomerId,
  setCustomerOrg,
  rates
}: CustomerOrganizationDrawerProps) {
  const [customerOrganizationEdits, setCustomerOrganizationEdits] =
    useState<Partial<CustomerOrganization>>();
  const [activeTab, setActiveTab] = useState(DETAILS_TAB);

  const [customerOrganizationName, setCustomerOrganizationName] = useState('');
  const [billingTerm, setBillingTerm] = useState<number | undefined>(
    BillingTermType.net_thirty.value
  );
  const [enableAffidavitsBeforePayment, setEnableAffidavitsBeforePayment] =
    useState(false);
  const [address, setAddress] = useState<string | null>();
  const [addressLine2, setAddressLine2] = useState<string | null>();
  const [city, setCity] = useState<string | null>();
  const [state, setState] = useState<number | null>();
  const [zipCode, setZipCode] = useState<string | null>();
  const [phone, setPhone] = useState<string | null>();
  const [internalID, setInternalID] = useState<string>();

  const [customers, setCustomers] =
    useState<(ESnapshotExists<Customer> | null)[]>();

  const [loadingCustomers, setLoadingCustomers] = useState(false);

  const [customerEmails, setCustomerEmails] = useState<string[]>([]);
  const [customerRoles, setCustomerRoles] = useState<string[]>([]);

  const [numberOfNoticesPlaced, setNumberOfNoticesPlaced] = useState(0);

  const [
    showEditCustomerOrganizationModal,
    setShowEditCustomerOrganizationModal
  ] = useState<boolean>();
  const ctx = getFirebaseContext();

  const isFuneralHome =
    customerOrganizationData.clientOrganization.data()?.organizationType ===
    OrganizationTypeData.funeral_home.value;

  useEffect(() => {
    const getCustomersFromCustomerOrg = async () => {
      setLoadingCustomers(true);
      const usersInOrgRes: EResponseTypes['users/get-users-from-customer-org'] =
        await api.post('users/get-users-from-customer-org', {
          customerOrganizationId:
            customerOrganizationData.customerOrganization.id
        });

      if (!usersInOrgRes.success) {
        setLoadingCustomers(false);
        return;
      }

      const userSnapshots = usersInOrgRes.userIds.map(id =>
        ctx.usersRef().doc(id)
      );

      const customerFromCustomerOrg = await Promise.all(
        userSnapshots.map(async user => {
          const customer = await ctx
            .customersRef()
            .where('user', '==', user)
            .where('organization', '==', activeOrganization.ref)
            .get();

          if (customer.docs.length) {
            return customer.docs[0];
          }
          return null;
        })
      );

      const filteredCustomerFromCustomerOrg = customerFromCustomerOrg.filter(
        customer => {
          return customer !== null;
        }
      );

      const customerFromCustomerOrgEmails = await Promise.all(
        filteredCustomerFromCustomerOrg.map(async customer => {
          const user = await customer?.data().user.get();
          return user?.data()?.email || '--';
        })
      );

      const customerFromCustomerOrgRoles = (
        await Promise.all(
          filteredCustomerFromCustomerOrg.map(async customer => {
            const user = await customer?.data().user.get();

            const userRole = user?.data()?.roles
              ? user?.data()?.roles![
                  customerOrganizationData.clientOrganization.id
                ]
              : RoleType.user.value;

            return RoleType.by_value(userRole)?.label;
          })
        )
      ).filter((role): role is string => role !== undefined);

      setCustomers(filteredCustomerFromCustomerOrg);
      setCustomerEmails(customerFromCustomerOrgEmails);
      setCustomerRoles(customerFromCustomerOrgRoles);
      setLoadingCustomers(false);
    };

    const getNumberOfNoticesPlaced = async () => {
      const notices = await ctx
        .userNoticesRef()
        .where('filedBy', '==', customerOrganizationData.clientOrganization.ref)
        .where('newspaper', '==', activeOrganization.ref)
        .get();

      setNumberOfNoticesPlaced(notices.docs.length || 0);
    };

    void getCustomersFromCustomerOrg();
    void getNumberOfNoticesPlaced();

    // Update state vars on CustomerData update
    if (customerOrganizationEdits) {
      setCustomerOrganizationName(customerOrganizationEdits.name || '--');
      setAddress(customerOrganizationEdits.address);
      setAddressLine2(customerOrganizationEdits.addressLine2);
      setCity(customerOrganizationEdits.city);
      setState(customerOrganizationEdits.state);
      setZipCode(customerOrganizationEdits.zipCode);
      setPhone(customerOrganizationEdits.phone);
      setBillingTerm(customerOrganizationEdits.billingTerm);
      setEnableAffidavitsBeforePayment(
        !!customerOrganizationEdits.enableAffidavitsBeforePayment
      );
      setInternalID(customerOrganizationEdits.internalID);
    } else {
      setCustomerOrganizationName(
        customerOrganizationData.customerOrganization.data()?.name ||
          (customerOrganizationData.clientOrganization.data()?.name !== ' ' &&
            customerOrganizationData.clientOrganization.data()?.name) ||
          '--'
      );
      setAddress(
        customerOrganizationData.customerOrganization.data()?.address ||
          customerOrganizationData.clientOrganization.data()?.address
      );
      setAddressLine2(
        customerOrganizationData.customerOrganization.data()?.addressLine2 ||
          (customerOrganizationData.customerOrganization.data()
            ?.addressLine2 !== ''
            ? customerOrganizationData.clientOrganization.data()?.addressLine2
            : '')
      );
      setCity(
        customerOrganizationData.customerOrganization.data()?.city ||
          customerOrganizationData.clientOrganization.data()?.city
      );
      setState(
        customerOrganizationData.customerOrganization.data()?.state ||
          customerOrganizationData.clientOrganization.data()?.state
      );
      setZipCode(
        customerOrganizationData.customerOrganization.data()?.zipCode ||
          customerOrganizationData.clientOrganization.data()?.zipCode
      );
      setPhone(
        customerOrganizationData.customerOrganization.data()?.phone ||
          customerOrganizationData.clientOrganization.data()?.phone
      );
      setBillingTerm(
        customerOrganizationData.customerOrganization.data()?.billingTerm
      );
      setEnableAffidavitsBeforePayment(
        !!customerOrganizationData.customerOrganization.data()
          ?.enableAffidavitsBeforePayment
      );
      setInternalID(
        customerOrganizationData.customerOrganization.data()?.internalID
      );
    }

    // Check if we need to override customer data by properties from org or user
    const updates: Partial<CustomerOrganization> = {
      name:
        (!customerOrganizationData.customerOrganization.data()?.name ||
          customerOrganizationData.customerOrganization.data()?.name === ' ') &&
        customerOrganizationData.clientOrganization.data()?.name
          ? customerOrganizationData.clientOrganization.data()?.name
          : undefined,
      address: !customerOrganizationData.customerOrganization.data()?.address
        ? customerOrganizationData.clientOrganization.data()?.address ||
          undefined
        : undefined,
      addressLine2:
        !customerOrganizationData.customerOrganization.data()?.addressLine2 &&
        customerOrganizationData.customerOrganization.data()?.addressLine2 !==
          ''
          ? customerOrganizationData.clientOrganization.data()?.addressLine2 ||
            undefined
          : undefined,
      city: !customerOrganizationData.customerOrganization.data()?.city
        ? customerOrganizationData.clientOrganization.data()?.city || undefined
        : undefined,
      state: !customerOrganizationData.customerOrganization.data()?.state
        ? customerOrganizationData.clientOrganization.data()?.state || undefined
        : undefined,
      zipCode: !customerOrganizationData.customerOrganization.data()?.zipCode
        ? customerOrganizationData.clientOrganization.data()?.zipCode ||
          undefined
        : undefined,
      phone: !customerOrganizationData.customerOrganization.data()?.phone
        ? customerOrganizationData.clientOrganization.data()?.phone || undefined
        : undefined
    };

    removeUndefinedFields(updates);
    if (Object.keys(updates).length) {
      void customerOrganizationData.customerOrganization.ref.update(updates);
    }
  }, [
    customerOrganizationEdits,
    safeStringify(customerOrganizationData.customerOrganization.data()),
    safeStringify(customerOrganizationData.clientOrganization.data())
  ]);

  const header = (
    <div className="text-column-gray-800 font-semibold text-xl">
      {customerOrganizationName || '--'}
    </div>
  );

  const subheader = (
    <div className="flex items-center">
      <div className="text-xs leading-5 text-column-gray-400 font-medium pt-1">
        ID: {internalID || '--'}
      </div>
      <div className="mx-3 h-full relative">
        <div
          className="border-l border-solid border-column-gray-400"
          style={{
            height: '14px',
            transform: 'translateY(20%)'
          }}
        ></div>
      </div>
      <div className="pt-0.25">
        <div className="billing-status whitespace-no-wrap text-center inline-flex items-center px-2 py-0.25 rounded-full text-xs font-semibold bg-column-green-50 text-column-green-500">
          <DotIcon className={'text-column-green-500'} />
          <div style={{ paddingLeft: '7px' }}>
            {numberOfNoticesPlaced} notices placed
          </div>
        </div>
      </div>
    </div>
  );

  const customerOrganizationNotes = useFirestoreQueryListener(
    getFirebaseContext()
      .notesRef()
      .where(
        'customerOrganization',
        '==',
        customerOrganizationData.customerOrganization.ref
      )
      .where('noteCreatorOrganization', '==', activeOrganization.ref),
    [customerOrganizationData.customerOrganization.id]
  );

  const tabs = getCustomerOrganizationDrawerTabs(
    customerEmails.length > 0,
    customerOrganizationNotes
  );

  const notesTabIsActive = activeTab.id === 'notes';

  const { value: defaultFuneralHomeRates } = useAsyncEffect({
    // TODO: Rework this section to take in filing type, publishing medium, and handle error state
    fetchData: async () => {
      const customerOrgData =
        customerOrganizationData.customerOrganization.data();

      const customerOrgObituaryRate = customerOrgData
        ? await customerOrgData.obituaryRate?.get()
        : undefined;

      const customerDeathNoticeRate = customerOrgData
        ? await customerOrgData.deathNoticeRate?.get()
        : undefined;

      // TODO: Pass this prop in
      const publishingMedium = PublishingMedium.Print;
      // TODO: Pass this prop in
      const filingTypeLabel = ObituaryFilingTypeNames.Obituary;
      const { response: obitRate } = await getRateSnapshotFromFilingTypeLabel(
        activeOrganization,
        Product.Obituary,
        publishingMedium,
        filingTypeLabel
      );

      return {
        obituaryRate:
          customerOrgObituaryRate?.data()?.description ??
          obitRate?.description ??
          '',
        deathNoticeRate:
          customerDeathNoticeRate?.data()?.description ??
          obitRate?.description ??
          ''
      };
    },
    dependencies: [isFuneralHome, exists(activeOrganization)],
    initialData: {
      obituaryRate: '',
      deathNoticeRate: ''
    },
    errorConfig: {
      service: ColumnService.SETTINGS_MANAGEMENT,
      message: 'Error fetching rate'
    }
  });

  return (
    <>
      <div className="relative">
        {open && !showEditCustomerOrganizationModal && (
          <Drawer
            open={open}
            onClose={closeDrawer}
            header={
              <div>
                {header}
                {subheader}
              </div>
            }
            disableDarkenBackground={disableDarkenBackground}
          >
            <>
              <TabGroup
                tabs={tabs}
                activeTab={activeTab}
                onClickTab={setActiveTab}
                id="customer-drawer-tabs"
              />

              <div className="flex-1 flex overflow-scroll">
                {activeTab.label === DETAILS_TAB.label && (
                  <CustomerDrawerDetailsTab
                    userName={customerOrganizationName}
                    address={address}
                    addressLine2={addressLine2}
                    phone={phone}
                    city={city}
                    state={state}
                    zipCode={zipCode}
                    internalID={internalID}
                    isCustomerOrganization
                  />
                )}
                {activeTab.label === MEMBERS_TAB.label && (
                  <CustomerOrganizationDrawerMembersTab
                    customers={customers}
                    loadingCustomers={loadingCustomers}
                    setShowCustomerDrawer={(show: boolean) => {
                      setShowCustomerDrawer(show);
                      closeDrawer();
                    }}
                    setCustomerIdToDisplay={setCustomerId}
                    customerEmails={customerEmails}
                    customerRoles={customerRoles}
                  />
                )}
                {activeTab.label === SETTINGS_TAB.label && (
                  <CustomerDrawerSettingsTab
                    billingTerm={billingTerm}
                    affidavitsBeforePayment={enableAffidavitsBeforePayment}
                    isCustomerOrganization
                    isFuneralHome={isFuneralHome}
                    funeralHomeRates={defaultFuneralHomeRates}
                  />
                )}
                {notesTabIsActive &&
                  exists(customerOrganizationData.customerOrganization) && (
                    <NotesTab
                      noteTopic={{
                        customerOrganization:
                          customerOrganizationData.customerOrganization.ref,
                        noteType: 'customer-organization-note'
                      }}
                      notes={customerOrganizationNotes?.docs}
                    />
                  )}

                {/* Don't show the edit footer on our notes tab */}
                {!notesTabIsActive && (
                  <CustomerOrganizationDrawerFooter
                    onEditCustomerOrganization={() =>
                      setShowEditCustomerOrganizationModal(true)
                    }
                  />
                )}
              </div>
            </>
          </Drawer>
        )}
        {showEditCustomerOrganizationModal && (
          <EditCustomerOrganizationModal
            onClose={() => {
              setShowEditCustomerOrganizationModal(false);
              closeDrawer();
              setCustomerOrg(undefined);
            }}
            setCustomerOrganization={(
              customerOrganization: Partial<CustomerOrganization>
            ) => {
              setCustomerOrganizationEdits(customerOrganization);
            }}
            customerOrganizationData={customerOrganizationData}
            activeOrganization={activeOrganization}
            rates={rates}
          />
        )}
      </div>
    </>
  );
}
