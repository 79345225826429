import React, { useState } from 'react';
import ToastActions from 'redux/toast';
import FileUpload from 'lib/components/FileUpload';
import { ColumnSelect, ColumnSelectOption } from 'lib/components/ColumnSelect';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Form } from 'lib/components/Form';
import {
  ENotice,
  EOrganization,
  ERequestTypes,
  ESnapshotExists
} from 'lib/types';
import { cdnIfy, getWordsFromNumber } from 'lib/helpers';
import { getFileTypeFromFile } from 'lib/files';
import { logAndCaptureException } from 'utils';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import {
  ALLOWED_FINALIZED_DISPLAY_TYPES,
  AllowedFinalizedDisplayType,
  isAllowedFinalizedDisplayType
} from 'lib/asyncDesign/types';
import { usePublisherModularSizes } from 'routes/placeScroll/hooks/usePublisherModularSizes';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { apiPost } from 'api/typed';
import Firebase from 'EnoticeFirebase';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import AdDetailsCard from './AdDetailsCard';

type DisplayAdActionsProps = {
  notice: ESnapshotExists<ENotice>;
  newspaper: ESnapshotExists<EOrganization>;
};

export default function DisplayAdActions({
  notice,
  newspaper
}: DisplayAdActionsProps) {
  const dispatch = useAppDispatch();

  // TODO: In the future (if this is exposed beyond Column Reps) we may want to limit
  // the min/max columns based on the newspaper template and notice type.
  const [columns, setColumns] = useState<number>();
  const [modularSizeId, setModularSizeId] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<{
    name: string;
    url: string;
    storagePath: string;
  }>();
  const [fileType, setFileType] = useState<AllowedFinalizedDisplayType>();
  const [uploadLoading, setUploadLoading] = useState(false);
  const { modularSizes, loading: modularSizesLoading } =
    usePublisherModularSizes(newspaper.ref);

  const templateId = newspaper.data().adTemplate?.id;
  const { value: templateColumnCount, isLoading: templateColumnCountLoading } =
    useAsyncEffect({
      fetchData: async () => {
        if (!templateId) {
          return undefined;
        }

        try {
          const res = await apiPost('templates/styles', {
            templateId
          });

          if (res.success) {
            return res.styles.columnCount;
          }
        } catch (e) {
          logAndCaptureException(
            ColumnService.AFFINITY_X,
            e,
            'Failed to fetch template styles',
            {
              templateId
            }
          );
        }

        return undefined;
      },
      dependencies: [templateId]
    });

  const loading =
    modularSizesLoading || templateColumnCountLoading || uploadLoading;

  const sizeOptions: ColumnSelectOption<string>[] = [
    ...modularSizes.map(i => ({
      value: i.id,
      label: i.data().name,
      description: `${i.data().width.toFixed(2)}" x ${i
        .data()
        .height.toFixed(2)}"`
    })),
    { value: '', label: 'None' }
  ];

  const columnOptions: ColumnSelectOption<string>[] = Array.from({
    length: templateColumnCount ?? 0
  }).map((v, index) => ({
    value: `${index + 1}`,
    label: getWordsFromNumber(index + 1)
  }));

  const hasModularSizes = !!modularSizes.length;

  const submitEnabled = !!uploadedFile && fileType;

  const resetForm = () => {
    setUploadedFile(undefined);
    setFileType(undefined);
    setColumns(undefined);
    setModularSizeId(undefined);
  };

  const handleSubmit = async () => {
    const fileUrl = uploadedFile?.url;
    if (!fileUrl || !fileType) {
      return;
    }

    const req: ERequestTypes['notices/async-design/upload-finalized-design'] = {
      noticeId: notice.id,
      url: fileUrl,
      fileType,
      ...(columns ? { columns } : {}),
      ...(modularSizeId ? { modularSizeId } : {})
    };

    setUploadLoading(true);
    try {
      const res = await apiPost(
        'notices/async-design/upload-finalized-design',
        req
      );

      if (res.success) {
        dispatch(
          ToastActions.toastSuccess({
            headerText: 'Success',
            bodyText: 'Display ad file updated.'
          })
        );

        resetForm();
      } else {
        dispatch(
          ToastActions.toastError({
            headerText: 'Failure',
            bodyText: res.error ?? 'There was an error uploading the display ad'
          })
        );
      }
    } catch (e) {
      logAndCaptureException(
        ColumnService.AFFINITY_X,
        e,
        'Failed to upload async design',
        {
          noticeId: req.noticeId
        }
      );
      dispatch(
        ToastActions.toastError({
          headerText: 'Failure',
          bodyText: 'There was an error uploading the display ad'
        })
      );
    } finally {
      setUploadLoading(false);
    }
  };

  const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
  return (
    <AdDetailsCard
      id="display-ad-actions"
      header={{ title: 'Display Ad Design' }}
    >
      <Form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          {!uploadedFile && (
            <FileUpload
              id={'display-ad-file'}
              acceptFileTypes={ALLOWED_FINALIZED_DISPLAY_TYPES.map(
                fileType => `.${fileType}`
              ).join(',')}
              uploadFolder={`documentcloud/display-ad-uploads/${notice.id}`}
              onFileUpload={async filesAndRefs => {
                const file = filesAndRefs[0];
                if (!file) {
                  setUploadedFile(undefined);
                  return;
                }

                const uploadFileType = getFileTypeFromFile(file.file);
                if (!isAllowedFinalizedDisplayType(uploadFileType)) {
                  return;
                }

                setFileType(uploadFileType);
                setUploadedFile({
                  name: file.file.name,
                  url: cdnIfy(file.uploadRef.fullPath, { useColumnCDN }),
                  storagePath: file.uploadRef.fullPath
                });
              }}
              storage={Firebase.storage()}
            />
          )}
          {uploadedFile && (
            <>
              <FileUploadListItem
                key={uploadedFile.storagePath}
                firebaseStoragePath={uploadedFile.storagePath}
                onDelete={() => setUploadedFile(undefined)}
                disableDelete={loading}
                storage={Firebase.storage()}
              />
              <div className="grid grid-cols-2 gap-4">
                <ColumnSelect
                  id={'select-ad-size'}
                  allowUndefined
                  options={sizeOptions}
                  value={modularSizeId}
                  disabled={!hasModularSizes || !!columns || loading}
                  onChange={value => setModularSizeId(value)}
                  labelText={'Ad Size'}
                />

                <ColumnSelect
                  id={'select-columns'}
                  labelText={'Columns'}
                  placeholder="Column width"
                  options={columnOptions}
                  allowUndefined
                  disabled={hasModularSizes || !!modularSizeId || loading}
                  value={columns?.toString() ?? ''}
                  onChange={value =>
                    setColumns(parseInt(value, 10) || undefined)
                  }
                />
              </div>
              <ColumnButton
                primary
                type="submit"
                buttonText="Upload Finalized Ad"
                disabled={!submitEnabled}
                loading={loading}
              />
            </>
          )}
        </div>
      </Form>
    </AdDetailsCard>
  );
}
