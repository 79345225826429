type ClipArtProps = {
  src: string;
  onSelect: () => void;
};

function ClipArt({ src, onSelect }: ClipArtProps) {
  return (
    <button
      className="hover:bg-column-gray-100 p-1 rounded-md"
      onClick={onSelect}
      aria-label="Select clip art"
    >
      <div className="flex max-h-48 align-center justify-center">
        <img className="object-contain" src={src} alt="" />
      </div>
    </button>
  );
}

export default ClipArt;
