import { EnumInputItem } from '../types/enums';
import { UserOccupationKey } from './OccupationType';

export const OrganizationTypeData = {
  newspaper: {
    value: 1,
    label: 'Newspaper',
    key: 'newspaper',
    defaultOccupationKey: 'publishing',
    isPublisher: true
  },
  government: {
    value: 2,
    label: 'Government Organization',
    key: 'government',
    defaultOccupationKey: 'government_official',
    isPublisher: false
  },
  press_association: {
    value: 3,
    label: 'Press Association',
    key: 'press_association',
    defaultOccupationKey: 'press_association_manager',
    isPublisher: true
  },
  law_firm: {
    value: 4,
    label: 'Law Firm',
    key: 'law_firm',
    defaultOccupationKey: 'lawyer',
    isPublisher: false
  },
  holding_company: {
    value: 5,
    label: 'Holding Company',
    key: 'holding_company',
    defaultOccupationKey: 'publishing',
    isPublisher: true
  },
  other_organization: {
    value: 6,
    label: 'Other Organization',
    key: 'other_organization',
    defaultOccupationKey: 'other_organization',
    isPublisher: false
  },
  funeral_home: {
    value: 7,
    label: 'Funeral Home',
    key: 'funeral_home',
    defaultOccupationKey: 'funeral_director',
    isPublisher: false
  }
} as const;

export type OrganizationTypeItems = typeof OrganizationTypeData;

export type OrganizationTypeEnum =
  OrganizationTypeItems[keyof OrganizationTypeItems] &
    EnumInputItem<{
      defaultOccupationKey: UserOccupationKey;
    }>;

export type OrganizationTypeValue = OrganizationTypeEnum['value'];

/**
 * Function to check if an organization type is a publisher
 * @param value - The value of the organization type to check
 * @returns boolean - True if the organization type is a publisher, false otherwise
 */
export function isOrganizationTypePublisher(
  value: OrganizationTypeValue
): boolean {
  const organizationType = Object.values(OrganizationTypeData).find(
    orgType => orgType.value === value
  );
  return organizationType?.isPublisher ?? false;
}
