import { useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  Paper,
  Avatar,
  Typography,
  Divider,
  Box,
  Theme
} from '@material-ui/core';
import { ESnapshot, EUser, exists } from 'lib/types';

import AuthAction from 'redux/auth';
import TailwindModal from 'components/TailwindModal';
import { push } from 'connected-react-router';
import { logAndCaptureMessage } from 'utils';
import { reload } from 'utils/reload';
import {
  PayInvoiceData,
  InvoicePricingData,
  InvoiceType,
  isPayOrderInvoiceData
} from 'lib/types/invoices';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { useAppDispatch } from 'redux/hooks';
import PayInvoiceTable from './components/PayInvoiceTable';

import PayInvoiceCreditCard from './components/PayInvoiceCreditCard';
import { PayInvoicePaymentMethodsType } from './PayInvoice';
import InvoiceStatusInfo from './components/InvoiceStatusInfo';
import { LatePaymentWarning } from './components/LatePaymentWarning';

import PayInvoiceButton from './components/buttons/PayInvoiceButton';
import { PayInvoicePaymentMethods } from './components/PayInvoicePaymentMethods';
import { processStripePaymentWithSavedMethod } from './helpers/processStripeInvoicePayment';
import { SavedPaymentsAccess } from './helpers/getSavedPaymentsAccess';
import { BulkInvoiceExistsWarning } from './components/BulkInvoiceExistsWarning';
import { OrderCompleteModal } from './components/OrderCompleteModal';
import { getPreventLatePaymentValue } from './helpers/preventLatePayment';

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: 'auto',
      display: 'block',
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(12),
      [theme.breakpoints.up(300 + theme.spacing(6))]: {
        width: 500,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'space-between',
      backgroundColor: '#F9FAFB' // background color of pay invoice screen
    },
    paper: {
      flexGrow: 6
    },
    leftBigBox: {
      position: 'absolute',
      width: theme.spacing(30),
      height: theme.spacing(40),
      background: '#EBF5FB',
      borderRadius: '26px',
      transform: 'skewY(-30deg)',
      top: theme.spacing(30),
      left: '0px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    leftSmallBox: {
      position: 'absolute',
      width: theme.spacing(25),
      height: theme.spacing(35),
      background: '#2D9BDB',
      borderRadius: '26px',
      transform: 'skewY(-30deg)',
      top: theme.spacing(40),
      left: theme.spacing(5),
      opacity: '0.1',
      zIndex: 100,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    rightBigBox: {
      position: 'absolute',
      width: theme.spacing(30),
      height: theme.spacing(40),
      background: '#EBF5FB',
      borderRadius: '26px',
      transform: 'skewY(-30deg)',
      top: theme.spacing(10),
      right: '0px',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    rightSmallBox: {
      position: 'absolute',
      width: theme.spacing(25),
      height: theme.spacing(35),
      background: '#2D9BDB',
      borderRadius: '26px',
      transform: 'skewY(-30deg)',
      top: theme.spacing(20),
      right: '0px',
      opacity: '0.1',
      zIndex: 100,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    avatar: {
      margin: theme.spacing(1),
      position: 'absolute',
      marginTop: theme.spacing(-4),
      width: theme.spacing(8),
      height: theme.spacing(8),
      boxShadow: '4',
      '& img': {
        height: '40px',
        width: '45px'
      }
    },
    invoiceStateSection: {
      width: '100%',
      backgroundColor: '#FAFCFE' // adds a light gray to where we have our payment methods listed
    },
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    footerText: {
      fontSize: '14px',
      color: '#697386' // gives the footer text a dark gray text
    }
  });

export type PayInvoicePageProps = {
  payInvoiceData: PayInvoiceData;
  invoicePricingData: InvoicePricingData;
  savedPaymentsAccess: SavedPaymentsAccess;
  user: ESnapshot<EUser> | null;
  enableAuthCapture: boolean;
  classes: Record<string, string>;
  product: Product;
};

function PayInvoicePage({
  payInvoiceData,
  invoicePricingData,
  savedPaymentsAccess,
  user,
  enableAuthCapture,
  classes,
  product
}: PayInvoicePageProps) {
  const dispatch = useAppDispatch();

  // This is a temporary flag while we only support non-saved cards on Payway and Elavon gateways
  const onlyShowCardPaymentForm =
    savedPaymentsAccess.disableACH && savedPaymentsAccess.disableSavedCards;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PayInvoicePaymentMethodsType | undefined
  >(onlyShowCardPaymentForm ? 'card' : undefined);
  const [selectedSavedMethodId, setSelectedSavedMethodId] = useState<string>();

  // This is a standalone modal to support custom string messages
  // TODO(goodpaul): Refactor this to use the Column modal component
  const [showLoginModal, setShowLoginModal] = useState<string | undefined>();

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');

  const { invoice } = payInvoiceData;
  const showOrderSuccessModal =
    payInvoiceData.type === InvoiceType.ORDER &&
    !invoice.isUnpaid() &&
    !invoice.isVoided();

  const { status: invoiceStatus, invoice_number: invoiceNumber } =
    invoice.modelData;

  // Get properties that only exist for public notice invoices
  const { billingName } = payInvoiceData;
  const preventLatePayment = getPreventLatePaymentValue(payInvoiceData);
  let requireUpfrontPayment = false;
  let bulkInvoiceHostedUrl;
  if (payInvoiceData.type === InvoiceType.PUBLIC_NOTICE) {
    ({ requireUpfrontPayment, bulkInvoiceHostedUrl } = payInvoiceData);
  }

  const selectPaymentClickHandler = (
    paymentMethod: PayInvoicePaymentMethodsType,
    savedMethodId?: string
  ) => {
    if (
      paymentMethod === selectedPaymentMethod &&
      savedMethodId === selectedSavedMethodId
    ) {
      // Clicked again, so deselect option
      setSelectedPaymentMethod(undefined);
      setSelectedSavedMethodId(undefined);
    } else {
      // Select option
      setSelectedPaymentMethod(paymentMethod);
      setSelectedSavedMethodId(savedMethodId);
    }
  };

  const handleSuccessfulPayment = () => {
    reload(false);
  };

  const savedMethodPayInvoiceClickHandler = async () => {
    setLoading(true);
    try {
      if (selectedPaymentMethod && selectedSavedMethodId)
        await processStripePaymentWithSavedMethod(
          selectedPaymentMethod,
          selectedSavedMethodId,
          payInvoiceData,
          invoicePricingData,
          user
        );
      handleSuccessfulPayment();
    } catch (err: any) {
      const userMessage =
        typeof err === 'string'
          ? err
          : 'There was a problem processing your payment';
      logAndCaptureMessage(err.message || err);
      setErr(userMessage);
      setLoading(false);
    }
  };

  const onOrderSubmit = () => {
    if (exists(user)) {
      dispatch(push(`/${PRODUCT_TO_NAME[product].plural.toLowerCase()}`));
    } else {
      dispatch(AuthAction.logout());
      window.location.href = '/';
    }
  };

  return (
    <>
      {showOrderSuccessModal && <OrderCompleteModal onSubmit={onOrderSubmit} />}
      <Box className={classes.container}>
        <Box className={classes.flex}>
          <Box className={classes.leftBigBox}></Box>
          <Box className={classes.leftSmallBox}></Box>
        </Box>
        <Box className={classes.flex}>
          <Box className={classes.rightBigBox}></Box>
          <Box className={classes.rightSmallBox}></Box>
        </Box>
        <main className={classes.main}>
          <LatePaymentWarning
            requireUpfrontPayment={requireUpfrontPayment}
            preventLatePayment={preventLatePayment}
            invoiceStatus={invoiceStatus}
          />
          <BulkInvoiceExistsWarning
            invoiceStatus={invoiceStatus}
            bulkInvoiceHostedUrl={bulkInvoiceHostedUrl}
          />
          <Paper className={`${classes.paper} ${classes.flex}`}>
            <Avatar
              className={classes.avatar}
              style={{ boxShadow: '0.5px 0.5px 3px', background: 'white' }}
              src="/android-chrome-512x512.png"
            ></Avatar>
            <Box>
              <Box mt={4}>
                <Typography style={{ fontSize: 20 }} align="center">
                  Invoice from Column
                </Typography>
              </Box>
              <Typography
                variant="caption"
                align="center"
                display="block"
                color="textSecondary"
              >
                Billed to {billingName}
              </Typography>
              <Typography
                variant="caption"
                align="center"
                display="block"
                color="textSecondary"
              >
                Invoice #{invoiceNumber ? invoiceNumber.toUpperCase() : 'NaN'}
              </Typography>
            </Box>

            <Box mt={4} mb={2} className={classes.invoiceStateSection}>
              <InvoiceStatusInfo
                invoice={invoice}
                invoicePricingData={invoicePricingData}
              />
              <Box mb={3} className={classes.flex}>
                {invoice.isUnpaid() && !preventLatePayment && (
                  <>
                    {!onlyShowCardPaymentForm && (
                      <Typography
                        variant="caption"
                        align="center"
                        color="textSecondary"
                      >
                        Choose how you'd like to pay.
                      </Typography>
                    )}
                    <Box mt={3} mb={2} width={345}>
                      {!onlyShowCardPaymentForm && (
                        <Box mb={3}>
                          <PayInvoicePaymentMethods
                            payInvoiceData={payInvoiceData}
                            savedPaymentsAccess={savedPaymentsAccess}
                            selectedPaymentMethod={selectedPaymentMethod}
                            selectedSavedMethodId={selectedSavedMethodId}
                            user={user}
                            selectPaymentClickHandler={
                              selectPaymentClickHandler
                            }
                            setShowLoginModal={setShowLoginModal}
                          />
                        </Box>
                      )}

                      <Box mt={1}>
                        {selectedPaymentMethod === 'card' && (
                          <PayInvoiceCreditCard
                            payInvoiceData={payInvoiceData}
                            invoicePricingData={invoicePricingData}
                            disableSavedCards={
                              savedPaymentsAccess.disableSavedCards
                            }
                            handleSuccessfulPayment={handleSuccessfulPayment}
                            enableAuthCapture={enableAuthCapture}
                          />
                        )}
                        {selectedPaymentMethod &&
                          selectedSavedMethodId &&
                          selectedPaymentMethod.startsWith('saved-') && (
                            <PayInvoiceButton
                              loading={loading}
                              type={'button'}
                              disabled={loading}
                              id="pay-invoice-stripe"
                              clickHandler={savedMethodPayInvoiceClickHandler}
                            />
                          )}
                      </Box>
                      {err && (
                        <Box mt={1} className={classes.flex}>
                          <Typography color="error" variant="caption">
                            {err}
                          </Typography>
                        </Box>
                      )}
                      {showLoginModal && (
                        <TailwindModal
                          header="You must be logged in"
                          body={showLoginModal}
                          close={() => setShowLoginModal(undefined)}
                          buttonText="Log into Column"
                          onButtonClick={() => {
                            push(
                              `/login/?redirect=/invoices/${invoice.id}/pay`
                            );
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}
              </Box>
              <Divider />
            </Box>
            <PayInvoiceTable
              invoice={invoice}
              invoicePricingData={invoicePricingData}
              coupons={
                isPayOrderInvoiceData(payInvoiceData)
                  ? payInvoiceData.coupons
                  : []
              }
            ></PayInvoiceTable>
            <Divider style={{ width: '100%' }} />
            <Box ml={4} mr={4} mt={2} mb={2} className={classes.flex}>
              <Typography className={classes.footerText} align="center">
                If you have any questions, contact Column at{' '}
                <span style={{ color: '#5469D4' }}>help@column.us</span>.
              </Typography>
            </Box>
          </Paper>
        </main>
      </Box>
    </>
  );
}

export default withStyles(styles)(PayInvoicePage);
