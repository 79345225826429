import api from 'api';
import { ERequestTypes, EResponseTypes } from 'lib/types';

export const sendPasswordResetEmail = async (email: string) => {
  const reqData: ERequestTypes['users/send-password-reset-email'] = {
    email
  };
  const resp: EResponseTypes['users/send-password-reset-email'] =
    await api.post('users/send-password-reset-email', reqData);
  return {
    success: !!resp.success,
    noUserFound: resp.noUserFound
  };
};
