import {
  CardGridLayout,
  GridInput,
  useEditableCard
} from 'lib/components/Card/Grid';
import FileUpload from 'lib/components/FileUpload';
import { FileUploadListItem } from 'lib/components/FileUpload/FileUploadListItem';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import { Form } from 'lib/components/Form';
import { InputAccessories } from 'lib/components/InputAccessories';
import { removeUndefinedFields } from 'lib/helpers';
import { EOrganization, ESnapshot, exists } from 'lib/types';
import React from 'react';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import Firebase from 'EnoticeFirebase';
import { PayToAddress } from './PayToAddress';
import { ContactInfo } from './ContactInfo';

const getInvoiceSettingsFromOrg = (
  organization: ESnapshot<EOrganization> | null
) => {
  if (!exists(organization)) {
    return null;
  }

  const { invoiceConfiguration } = organization.data();

  const {
    logo,
    payToAddress,
    showAccountNumber,
    showOrderNumber,
    contact_info,
    showTotalColumnInches,
    showNumberOfLines,
    showColumnWidth,
    showHeight
  } = invoiceConfiguration ?? {};

  return {
    contact_info,
    logo,
    payToAddress,
    showAccountNumber,
    showOrderNumber,
    showTotalColumnInches,
    showNumberOfLines,
    showColumnWidth,
    showHeight
  };
};

export function InvoiceSettings() {
  const activeOrganization = useActiveOrganizationListener();

  const currentSettings = getInvoiceSettingsFromOrg(activeOrganization);

  const { editable, onChange, disabled, onSubmitWrapper, formSettings } =
    useEditableCard(currentSettings);

  if (!activeOrganization || !formSettings) {
    return null;
  }

  return (
    <Form
      id="publisher-settings-billing-invoice-configuration"
      onSubmit={() =>
        onSubmitWrapper(async () => {
          await activeOrganization.ref.update({
            invoiceConfiguration: removeUndefinedFields(formSettings)
          });
          return { success: true };
        })
      }
      aria-label="Invoice Settings"
    >
      <CardGridLayout
        header={{
          title: 'Invoice Settings',
          description: 'Configure the invoice template for this newspaper.'
        }}
        editable={editable}
      >
        <GridInput fullWidth>
          <InputAccessories
            id="invoice-logo-upload"
            labelText="Upload logo"
            noteText="This logo will appear at the top of invoices for this newspaper. The image should be at least 25 pixels tall and wider images tend to look better."
            disabled={disabled}
          >
            {formSettings.logo && (
              <FileUploadListItem
                firebaseStoragePath={formSettings.logo.storagePath}
                onDelete={() => onChange({ logo: undefined })}
                disableDelete={disabled}
                storage={Firebase.storage()}
              />
            )}
            <FileUpload
              id="invoice-logo-upload-input"
              acceptFileTypes={['.png', '.jpg', '.jpeg']}
              uploadFolder={`logos/${activeOrganization.id}/invoices`}
              onFileUpload={async ([{ uploadRef, file }]) => {
                if (!file) {
                  return;
                }

                onChange({
                  logo: {
                    downloadUrl: await uploadRef.getDownloadURL(),
                    storagePath: uploadRef.fullPath
                  }
                });
              }}
              disabled={disabled}
              storage={Firebase.storage()}
            />
          </InputAccessories>
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Show order number on invoices"
            description="If enabled, order numbers will appear in the top-right corner of invoices."
            value={!!formSettings.showOrderNumber}
            onChange={showOrderNumber => onChange({ showOrderNumber })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Show account number on invoices"
            description="If enabled, account numbers will appear in the top-right corner of invoices."
            value={!!formSettings.showAccountNumber}
            onChange={showAccountNumber => onChange({ showAccountNumber })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Show total column inches on invoices"
            description="If enabled, total column inches will appear in the top-right corner of invoices."
            value={!!formSettings.showTotalColumnInches}
            onChange={showTotalColumnInches =>
              onChange({ showTotalColumnInches })
            }
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Show number of lines on invoices"
            description="If enabled, number of lines will appear in the top-right corner of invoices."
            value={!!formSettings.showNumberOfLines}
            onChange={showNumberOfLines => onChange({ showNumberOfLines })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Show column width on invoices"
            description="If enabled, column width will appear in the top-right corner of invoices."
            value={!!formSettings.showColumnWidth}
            onChange={showColumnWidth => onChange({ showColumnWidth })}
            disabled={disabled}
          />
        </GridInput>
        <GridInput>
          <LabeledSwitch
            label="Show height on invoices"
            description="If enabled, height will appear in the top-right corner of invoices."
            value={!!formSettings.showHeight}
            onChange={showHeight => onChange({ showHeight })}
            disabled={disabled}
          />
        </GridInput>
        <PayToAddress
          value={formSettings.payToAddress}
          onChange={payToAddress => onChange({ payToAddress })}
          disabled={disabled}
        />
        <ContactInfo
          value={formSettings.contact_info}
          onChange={contact_info => onChange({ contact_info })}
          disabled={disabled}
        />
      </CardGridLayout>
    </Form>
  );
}
