import React, { useEffect, useState } from 'react';

import { ESubscription, ESnapshotExists, exists } from 'lib/types';

type SubscriptionDrawerHeaderProps = {
  subscription: ESnapshotExists<ESubscription>;
};

/**
 * Header component for the e-edition drawer
 */
export default function SubscriptionDrawerHeader({
  subscription
}: SubscriptionDrawerHeaderProps) {
  const [localSubscription, setLocalSubscription] =
    useState<ESnapshotExists<ESubscription>>(subscription);

  useEffect(() => {
    const unsub = subscription.ref.onSnapshot(newSnapshot => {
      if (exists(newSnapshot)) setLocalSubscription(newSnapshot);
    });
    return () => unsub();
  }, [subscription.id]);

  return (
    <div className="flex items-center text-column-gray-500 font-semibold text-xl">
      <div className="mr-2">
        Subscription for {localSubscription.data().mailing?.address?.name}
      </div>
    </div>
  );
}
