import { isEqual } from 'lodash';
import {
  CalculatedInvoiceDiscount,
  Customer,
  CustomerOrganization,
  Discount,
  DiscountConfig,
  EFirebaseContext,
  ENotice,
  ESnapshot
} from '../types';
import { firestoreTimestampOrDateToDate } from '../helpers';
import { getCustomerAndCustomerOrganization } from './customer';
import { LineItemType } from '../enums';
import { LineItem } from '../types/invoices';

function isDiscountActive(discount: Discount, noticeSubmissionDate: Date) {
  const { expirationTimestamp, startTimestamp } = discount;

  const discountHasNotStarted =
    startTimestamp &&
    noticeSubmissionDate < firestoreTimestampOrDateToDate(startTimestamp);

  const discountHasExpired =
    expirationTimestamp &&
    noticeSubmissionDate > firestoreTimestampOrDateToDate(expirationTimestamp);

  return !discountHasNotStarted && !discountHasExpired;
}

export function filterExpiredDiscounts(
  discountConfig: DiscountConfig,
  date: Date
): DiscountConfig {
  const discountKeys = Object.keys(discountConfig) as Array<
    keyof DiscountConfig
  >;

  const filteredDiscounts: DiscountConfig = {};

  discountKeys.forEach(key => {
    const discount = discountConfig[key];
    if (discount && isDiscountActive(discount, date)) {
      filteredDiscounts[key] = discount;
    }
  });

  return filteredDiscounts;
}

export const getActiveDiscountConfigForCustomer = (
  customerSnap: ESnapshot<Customer> | undefined,
  customerOrganizationSnap: ESnapshot<CustomerOrganization> | undefined,
  date: Date | undefined
) => {
  const customerOrganizationConfig =
    customerOrganizationSnap?.data()?.discountConfig;
  const customerConfig = customerSnap?.data()?.discountConfig;

  const config = customerOrganizationConfig ?? customerConfig;

  const activeDate = date ?? new Date();
  const activeConfig = config
    ? filterExpiredDiscounts(config, activeDate)
    : undefined;

  return activeConfig;
};

function getEffectiveDateForNotice(
  noticeData: Pick<ENotice, 'createTime' | 'confirmedReceiptTime'>
) {
  const timestamp = noticeData.confirmedReceiptTime ?? noticeData.createTime;
  return timestamp ? firestoreTimestampOrDateToDate(timestamp) : new Date();
}

export async function getActiveDiscountConfigForNotice(
  ctx: EFirebaseContext,
  noticeData: Pick<
    ENotice,
    'newspaper' | 'filer' | 'filedBy' | 'createTime' | 'confirmedReceiptTime'
  >
): Promise<DiscountConfig | undefined> {
  const { customer, customerOrganization } =
    await getCustomerAndCustomerOrganization(ctx, noticeData);

  return getActiveDiscountConfigForCustomer(
    customer,
    customerOrganization,
    getEffectiveDateForNotice(noticeData)
  );
}

function calculateDiscountAmountInCents(
  originalAmountInCents: number,
  discount: Discount
) {
  const discountAmountInCents =
    discount.type === 'percent'
      ? Math.round((originalAmountInCents * discount.amount) / 100)
      : discount.amount;

  // If the discount would create a negative invoice, only discount by the
  // original amount
  // so that the invoice is $0
  return Math.min(discountAmountInCents, originalAmountInCents);
}

export function calculateDiscount(
  originalAmountInCents: number,
  discount: Discount
): CalculatedInvoiceDiscount {
  const amountInCents = calculateDiscountAmountInCents(
    originalAmountInCents,
    discount
  );

  const lineItemDescription =
    discount.type === 'percent' ? `Discount (${discount.amount}%)` : 'Discount';

  return {
    amountInCents,
    lineItemDescription
  };
}

export const getUpdatedDiscountLineItemsForNotice = (
  discountConfig: DiscountConfig | undefined,
  lineItems: LineItem[]
) => {
  const lineItemsWithoutDiscounts = lineItems.filter(
    (item: LineItem) => item.type !== LineItemType.discount.value
  );

  if (lineItemsWithoutDiscounts.length === 0) {
    return [];
  }

  const lastDate =
    lineItemsWithoutDiscounts[lineItemsWithoutDiscounts.length - 1].date ??
    new Date();

  if (discountConfig?.subtotal) {
    const subtotal = lineItemsWithoutDiscounts.reduce(
      (acc: number, item: LineItem) => acc + item.amount,
      0
    );
    const subtotalDiscount = calculateDiscount(
      subtotal,
      discountConfig.subtotal
    );
    if (subtotalDiscount) {
      return lineItemsWithoutDiscounts.concat({
        date: lastDate,
        amount: -subtotalDiscount.amountInCents,
        description: subtotalDiscount.lineItemDescription,
        type: LineItemType.discount.value
      });
    }
  }
  return lineItemsWithoutDiscounts;
};

export const wereDiscountLineItemsChanged = (
  oldLineItems: LineItem[],
  newLineItems: LineItem[]
) => {
  const oldLineItemsDateCorrected = oldLineItems.map(item => {
    return { ...item, date: firestoreTimestampOrDateToDate(item.date) };
  });

  const newLineItemsDateCorrected = newLineItems.map(item => {
    return { ...item, date: firestoreTimestampOrDateToDate(item.date) };
  });

  return !isEqual(oldLineItemsDateCorrected, newLineItemsDateCorrected);
};
