import React from 'react';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useFirestoreQueryListener } from 'lib/frontend/hooks/useFirestoreQueryListener';
import { getFirebaseContext } from 'utils/firebase';
import { useAppSelector } from 'redux/hooks';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import ColumnDatePicker from 'components/ColumnDatePicker';
import { exists } from 'lib/types';
import { Label } from 'lib/components/Label';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import LabeledSwitch from 'lib/components/LabeledSwitch';
import moment from 'moment-timezone';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import {
  getDateForDateStringInTimezone,
  getDateStringForDateInTimezone
} from 'lib/utils/dates';
import { EEditionUploadMethod } from 'lib/types/eedition';
import { AffidavitReconciliationSettings } from 'lib/types/organization';
import { Permissions } from 'lib/permissions/roles';
import { userHasPermission } from 'utils/permissions';
import { GlobalAffidavitControls } from './globalAffidavitControlHelpers';
import AffidavitFeeSplitSettingsCard from './AffidavitFeeSplitSettingsCard';

type ColumnManagedAffidavitsCardProps = {
  onChangeGlobalAffidavitControls: (
    newControls: GlobalAffidavitControls
  ) => void;
  globalAffidavitControls: GlobalAffidavitControls;
};

export default function ColumnManagedAffidavitsCard({
  onChangeGlobalAffidavitControls,
  globalAffidavitControls
}: ColumnManagedAffidavitsCardProps) {
  const user = useAppSelector(selectUser);
  const activeOrganization = useAppSelector(selectActiveOrganization);
  const { affidavitReconciliationSettings } = globalAffidavitControls;
  const affidavitTemplatesQuery = useFirestoreQueryListener(
    getFirebaseContext()
      .affidavitTemplatesRef()
      .where('publisher', '==', activeOrganization?.ref),
    [activeOrganization?.id]
  );

  const affidavitTemplates =
    affidavitTemplatesQuery?.docs.filter(
      affidavitTemplate =>
        !affidavitTemplate.data().archived &&
        affidavitTemplate.data().isColumnManaged
    ) || [];

  const canEditColumnManagedAffidavitSettings =
    exists(user) &&
    userHasPermission(user, Permissions.SETTINGS_AUTOMATED_AFFIDAVITS);

  return (
    <CardGridLayout header={{ title: 'Column Managed Affidavits' }}>
      <GridInput fullWidth>
        <LabeledSwitch
          label="Affidavits managed by Column?"
          description="Turn on Column's automated affidavits service for this publisher."
          value={!!affidavitReconciliationSettings.affidavitsManagedByColumn}
          onChange={newVal =>
            onChangeGlobalAffidavitControls({
              ...globalAffidavitControls,
              affidavitReconciliationSettings: {
                ...affidavitReconciliationSettings,
                affidavitsManagedByColumn: newVal
              }
            })
          }
          disabled={!canEditColumnManagedAffidavitSettings}
        />
      </GridInput>
      {canEditColumnManagedAffidavitSettings &&
        affidavitReconciliationSettings.affidavitsManagedByColumn && (
          <>
            <GridInput fullWidth>
              <LabeledSwitch
                label="Require notarization?"
                description="If enabled, Column-managed affidavits for this publisher will require notarization."
                value={!!affidavitReconciliationSettings.notarizationRequired}
                onChange={newVal =>
                  onChangeGlobalAffidavitControls({
                    ...globalAffidavitControls,
                    affidavitReconciliationSettings: {
                      ...affidavitReconciliationSettings,
                      notarizationRequired: newVal
                    }
                  })
                }
              />
            </GridInput>
            <GridInput fullWidth>
              <LabeledSwitch
                label="Require wet signature?"
                description="If enabled, Column-managed affidavits will be mailed to customers with a wet signature."
                value={
                  affidavitReconciliationSettings.notarizationVendor ===
                  'manual'
                }
                onChange={newVal =>
                  onChangeGlobalAffidavitControls({
                    ...globalAffidavitControls,
                    affidavitReconciliationSettings: {
                      ...affidavitReconciliationSettings,
                      notarizationVendor: newVal ? 'manual' : 'notarize'
                    }
                  })
                }
              />
            </GridInput>
            <GridInput fullWidth>
              <LabeledSwitch
                label="Require in-state notary?"
                description="If enabled, Column-managed affidavits will be notarized by a notary in the publication's state."
                value={!!affidavitReconciliationSettings.requiresInStateNotary}
                onChange={newVal =>
                  onChangeGlobalAffidavitControls({
                    ...globalAffidavitControls,
                    affidavitReconciliationSettings: {
                      ...affidavitReconciliationSettings,
                      requiresInStateNotary: newVal
                    }
                  })
                }
              />
            </GridInput>
            <GridInput fullWidth>
              <ColumnSelect<AffidavitReconciliationSettings['uploadMethod']>
                options={[
                  {
                    label:
                      'Column will automatically scrape their e-editions from a hosted site',
                    value: EEditionUploadMethod.SCRAPER
                  },
                  {
                    label:
                      'The newspaper will set up an automated FTP feed, uploading their e-editions after publication',
                    value: EEditionUploadMethod.FTP
                  },
                  {
                    label:
                      'The newspaper will manually upload their e-editions after publication',
                    value: EEditionUploadMethod.MANUAL
                  },
                  {
                    label: 'Not applicable',
                    value: 'not-applicable'
                  }
                ]}
                value={
                  affidavitReconciliationSettings.uploadMethod ??
                  'not-applicable'
                }
                onChange={method =>
                  onChangeGlobalAffidavitControls({
                    ...globalAffidavitControls,
                    affidavitReconciliationSettings: {
                      ...affidavitReconciliationSettings,
                      uploadMethod: method
                    }
                  })
                }
                id="edit-e-edition-upload-method"
                labelText="What is the expected upload method for this paper's e-editions?"
              />
            </GridInput>
            <GridInput fullWidth>
              <Label id="edit-reconciliation-start-date">
                <p className="mb-3">Reconciliation start date</p>
                <ColumnDatePicker
                  format="M/d/yy"
                  momentFormat="M/D/YY"
                  placeholderText="M/D/YY"
                  value={getDateStringForDateInTimezone({
                    date: affidavitReconciliationSettings.reconciliationStartDate.toDate(),
                    timezone:
                      activeOrganization?.data()?.iana_timezone ||
                      'America/Chicago'
                  })}
                  onChange={newDate => {
                    if (newDate) {
                      onChangeGlobalAffidavitControls({
                        ...globalAffidavitControls,
                        affidavitReconciliationSettings: {
                          ...affidavitReconciliationSettings,
                          reconciliationStartDate:
                            getFirebaseContext().timestampFromDate(
                              getDateForDateStringInTimezone({
                                dayString: moment(newDate).format('YYYY-MM-DD'),
                                timezone:
                                  activeOrganization?.data()?.iana_timezone ||
                                  'America/Chicago'
                              })
                            )
                        }
                      });
                    }
                  }}
                  className="p-3 border-column-gray-200 focus:border-column-primary-500 focus:shadow-outline-column-primary"
                />
              </Label>
            </GridInput>
            <GridInput fullWidth>
              <ColumnSelect
                id="column-affidavit-template"
                value={
                  affidavitReconciliationSettings.managedAffidavitTemplate?.id
                }
                labelText="Affidavit template"
                options={affidavitTemplates.map(affidavitTemplate => ({
                  label: affidavitTemplate.data().name,
                  value: affidavitTemplate.id
                }))}
                onChange={newVal =>
                  onChangeGlobalAffidavitControls({
                    ...globalAffidavitControls,
                    affidavitReconciliationSettings: {
                      ...affidavitReconciliationSettings,
                      managedAffidavitTemplate: getFirebaseContext()
                        .affidavitTemplatesRef()
                        .doc(newVal)
                    }
                  })
                }
              />
            </GridInput>
            <GridInput fullWidth>
              <CurrencyTextField
                id="affidavit-fee-in-cents"
                labelText="Affidavit automation fee"
                initialValue={
                  affidavitReconciliationSettings.automatedAffidavitFeeInCents ??
                  0
                }
                onChange={newVal =>
                  onChangeGlobalAffidavitControls({
                    ...globalAffidavitControls,
                    affidavitReconciliationSettings: {
                      ...affidavitReconciliationSettings,
                      automatedAffidavitFeeInCents: newVal
                    }
                  })
                }
              />
            </GridInput>
            <AffidavitFeeSplitSettingsCard
              onChangeGlobalAffidavitControls={onChangeGlobalAffidavitControls}
              globalAffidavitControls={globalAffidavitControls}
              affidavitReconciliationSettings={affidavitReconciliationSettings}
            />
          </>
        )}
    </CardGridLayout>
  );
}
