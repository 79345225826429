import {
  all,
  takeEvery,
  put,
  call,
  delay,
  takeLatest
} from 'redux-saga/effects';

import { ERequestTypes, ESnapshotExists, EUser } from 'lib/types';
import api from 'api';
import {
  ARCHIVED_NOTICES_TAB,
  DRAFT_NOTICES_TAB,
  CREATE_TIME,
  CONFIRMED_AT,
  ACTIVE_NOTICES_TAB
} from 'routes/notices/table/types';
import {
  SearchableNoticeRecord,
  SORT_DESCENDING,
  SORT_DIRECTIONS
} from 'lib/types/searchable';
import { keysChanged } from 'utils/diff';
import { logAndCaptureException } from 'utils';
import { getSearchableNoticeRecordFilters } from 'utils/noticeFilter';
import { appSagaSelect } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
import { authSelector, AuthState, AuthTypes } from '../redux/auth';
import { OccupationType } from '../lib/enums';
import NoticeActions, {
  NoticeTypes,
  NoticesState,
  noticesSelector
} from '../redux/notices';
import { AnyGenerator } from './types';
import { delayMs } from './helpers';

function* getNotices(): AnyGenerator<{
  success: boolean;
  page?: any;
  results?: SearchableNoticeRecord[];
}> {
  const authState: AuthState = yield* appSagaSelect(authSelector);
  const { user, isPublisher, showAllOrgsNotices } = authState;

  if (!user) {
    return { success: false, results: [] };
  }

  const noticesState: NoticesState = yield* appSagaSelect(noticesSelector);
  const {
    tab,
    sort,
    search,
    showUserOnlyNotices,
    currentPage,
    pageSize,
    noticesFilterValue
  } = noticesState;

  // Default sorting for drafts tab is by creation time of the draft
  // Default sorting for active or archived notices tab is by submission time of the notice
  // If there is a search query and the user did not specify a sort field,
  // we remove the default sorting to not override the Elastic sort based on relevance
  const defaultSort: {
    sortField: string;
    sortDirection: SORT_DIRECTIONS | '';
  } =
    tab === DRAFT_NOTICES_TAB
      ? {
          sortField: sort.field || (search ? '' : CREATE_TIME),
          sortDirection: sort.direction || (search ? '' : SORT_DESCENDING)
        }
      : {
          sortField: sort.field || (search ? '' : CONFIRMED_AT),
          sortDirection: sort.direction || (search ? '' : SORT_DESCENDING)
        };

  const isArchivedTab = tab === ARCHIVED_NOTICES_TAB;
  const isDraftTab = tab === DRAFT_NOTICES_TAB;

  // TODO(COREDEV-979): Right now we only apply filters on the active tab. In
  // the future we will apply them on other tabs.
  const applyFilters = tab === ACTIVE_NOTICES_TAB;

  const { filters, anyFilters, noneFilters } =
    getSearchableNoticeRecordFilters(noticesFilterValue);

  let sortInfo: Record<string, SORT_DIRECTIONS>[] | undefined;
  const { sortDirection, sortField } = defaultSort;
  if (sortField && sortDirection) {
    if (sortField === 'affidavitstatus') {
      /* The order of these fields matters as Elastic sorts by first field (iscancelled)
       * and for records that have the same value of this first field (iscancelled), it would sort them by
       * the second field (affidavitdisabled) and so on.`
       */
      sortInfo = [
        { iscancelled: sortDirection },
        { affidavitdisabled: sortDirection },
        { affidavitsubmitted: sortDirection },
        { showaffidavitoutsidecolumn: sortDirection },
        { lastpublicationtimestamp: sortDirection }
      ];
    } else {
      sortInfo = [{ [sortField]: sortDirection }];
    }
  }

  const postBody: ERequestTypes['search/usernotices'] = {
    current: currentPage + 1,
    size: pageSize,
    sort: sortInfo,
    search,
    isPublisher,
    showAllOrgsNotices,
    showUserOnlyNotices,
    isArchivedTab,
    isDraftTab,
    activeOrganizationId: user.data().activeOrganization?.id,
    ...(applyFilters ? { filters, anyFilters, noneFilters } : {})
  };

  try {
    const { results, page } = yield call(
      [api, api.post],
      'search/usernotices',
      postBody
    );

    return {
      results,
      page,
      success: true
    };
  } catch (e) {
    logAndCaptureException(ColumnService.ELASTIC, e, 'Error fetching notices', {
      userId: user.id
    });
    return { success: false };
  }
}

function* getQuery(): AnyGenerator<SearchableNoticeRecord[]> {
  const { success, results, page } = yield call(getNotices);
  if (!success) {
    yield put(NoticeActions.setTotal(0));
    return [];
  }

  if (results && page) {
    yield put(NoticeActions.setTotal(page.total_results));
  }

  return results;
}

export function* updateNotices(action?: { type: string }) {
  const auth: AuthState = yield* appSagaSelect(authSelector);
  if (!auth.user) return;
  if (
    auth.user.data().occupation !== OccupationType.individual.value &&
    !auth.activeOrganization
  ) {
    return;
  }

  const showLoading = action?.type !== NoticeTypes.UPDATE_NOTICES_HIDE_LOADING;
  if (showLoading) {
    yield put(NoticeActions.setFetching(true));
  }

  // This pairs with takeLatest to create a debouncing effect, see:
  // https://redux-saga.js.org/docs/recipes/#debouncing
  //
  // We don't use the native 'debounce' action because we want to show the loading
  // spinner immediately and only debounce this part of the call
  yield call(delayMs, 500);
  const notices: SearchableNoticeRecord[] = yield call(getQuery);

  yield put(NoticeActions.setNotices(notices));
  yield put(NoticeActions.setFetching(false));
}

// Resets the current page in the table to the first page upon switching tabs
function* resetPage() {
  yield put(NoticeActions.setCurrentPage(0));
}

export function* setTableParameters({
  user
}: {
  user: null | ESnapshotExists<EUser>;
}) {
  if (!user) return;

  const { noticeTablePageSize } = user.data();
  if (!noticeTablePageSize) return;

  // Setting this value causes a full Notice table refresh, so
  // we only want to do it when the value changes.
  const notices: NoticesState = yield* appSagaSelect(noticesSelector);
  if (notices.pageSize !== noticeTablePageSize) {
    yield put(NoticeActions.setPageSize(noticeTablePageSize));
  }
}

function* refreshActiveNotices() {
  while (true) {
    yield delay(60000);
    if (!window.location.pathname.startsWith('/notices/')) {
      continue;
    }

    yield put(NoticeActions.updateNoticesHideLoading());
  }
}

export function* updateUser() {
  const auth: AuthState = yield* appSagaSelect(authSelector);
  const { user, previousUser, showAllOrgsNotices } = auth;

  if (previousUser && user) {
    const diff = keysChanged(previousUser, user);

    // If the only update between the user and previousUser is lastSignInTime,
    // this likely just means they opened the site in a second tab or window
    // and we should not refresh the page.
    if (diff.length === 1 && diff[0] === 'lastSignInTime') {
      return;
    }

    // If the only update between the user and previousUser is activeOrganization,
    // while the user is still viewing all orgs view, this means they opened a notice in a new tab
    // and we should not refresh the page.
    if (
      diff.length === 1 &&
      diff[0] === 'activeOrganization' &&
      showAllOrgsNotices
    ) {
      return;
    }
  }

  yield put(NoticeActions.updateNotices());
}

export default function* root() {
  yield all([
    takeLatest(
      [
        NoticeTypes.UPDATE_NOTICES,
        NoticeTypes.UPDATE_NOTICES_HIDE_LOADING,
        NoticeTypes.SET_TAB,
        NoticeTypes.SET_CURRENT_PAGE,
        NoticeTypes.SET_PAGE_SIZE,
        NoticeTypes.SET_SEARCH,
        NoticeTypes.SET_SHOW_USER_ONLY_NOTICES,
        NoticeTypes.SET_NOTICES_FILTER_VALUE,
        NoticeTypes.SET_SORT,
        AuthTypes.SHOW_ALL_ORGS_NOTICES
      ],
      updateNotices
    ),
    takeEvery(
      [NoticeTypes.SET_TAB, NoticeTypes.SET_NOTICES_FILTER_VALUE],
      resetPage
    ),
    takeEvery(AuthTypes.SET_USER, action => setTableParameters(action as any)),
    takeEvery(AuthTypes.SET_USER, updateUser)
  ]);
  yield call(refreshActiveNotices);
}
