import React from 'react';
import NumberFormat from 'react-number-format';

type PercentFormatterProps = {
  inputRef?: any;
  onChange?: any;
};

function PercentFormatter({
  inputRef,
  onChange,
  ...other
}: PercentFormatterProps) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      decimalScale={2}
      suffix=" %"
      fixedDecimalScale
      isAllowed={values => {
        const { formattedValue, floatValue } = values;

        return formattedValue === '' || (!!floatValue && floatValue <= 100);
      }}
    />
  );
}

export default PercentFormatter;
