import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useBooleanFlag } from 'utils/flags';
import { useState } from 'react';
import { ESnapshotExists, EOrganization, exists } from 'lib/types';
import { logAndCaptureException } from 'utils';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import FullScreenModal from 'components/FullScreenModal';
import {
  CustomNoticeFilingType,
  FilingType,
  isNoticeFilingType,
  isOrderFilingType
} from 'lib/types/filingType';
import LoadingState from 'components/LoadingState';
import { userIsSuper } from 'utils/permissions';
import { useAppSelector } from 'redux/hooks';
import { selectUser } from 'redux/auth';
import { Product } from 'lib/enums';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getFirebaseContext } from 'utils/firebase';
import { NoticeRateService } from 'lib/services/noticeRateService';
import { AdRateService } from 'lib/services/adRateService';
import { last } from 'lodash';
import { ColumnService } from 'lib/services/directory';
import { removeUndefinedFields } from 'lib/helpers';
import { usePublisherOrgRates } from '../../rates/hooks/usePublisherOrgRates';
import IntegrationSettingsCard from './IntegrationSettingsCard';
import AffidavitSettingsCard from './AffidavitSettingsCard';
import BasicFilingTypePropertiesCard from './BasicFilingTypePropertiesCard';
import PublicationDatesCard from './PublicationDatesCard';
import NoticeFormatCard from './NoticeFormatCard';
import BillingSettingsCard from './BillingSettingsCard';
import { ColumnManagedAffidavitOverridesCard } from '../../affidavits/overrideControls';
import { usePublisherOrgTemplates } from '../../rates/hooks/usePublisherOrgTemplates';
import SuperUserOrderFilingTypeSettings from './SuperUserOrderFilingTypeSettings';
import OrderLayoutFilingTypeSettings from './OrderLayoutFilingTypeSettings';
import OrderFilingTypePublicationDatesCard from './OrderFilingTypePublicationDatesCard';

type NoticeSettingsProps = {
  updatedFilingType: CustomNoticeFilingType;
  setUpdatedFilingType: (filingType: CustomNoticeFilingType) => void;
  activeOrganization: ESnapshotExists<EOrganization>;
};

function StandardNoticeSettings({
  activeOrganization,
  setUpdatedFilingType,
  updatedFilingType
}: NoticeSettingsProps) {
  const { activeTemplates, loading: orgTemplatesLoading } =
    usePublisherOrgTemplates(activeOrganization.ref);

  if (orgTemplatesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <PublicationDatesCard
        activeOrganization={activeOrganization}
        onUpdateNoticeType={setUpdatedFilingType}
        updatedNoticeType={updatedFilingType}
      />
      <NoticeFormatCard
        activeOrganization={activeOrganization}
        onUpdateNoticeType={setUpdatedFilingType}
        updatedNoticeType={updatedFilingType}
        templates={activeTemplates}
      />
    </>
  );
}

function SuperUserNoticeSettings({
  updatedFilingType,
  setUpdatedFilingType
}: NoticeSettingsProps) {
  return (
    <>
      <AffidavitSettingsCard
        onUpdateNoticeType={setUpdatedFilingType}
        updatedNoticeType={updatedFilingType}
      />
      <ColumnManagedAffidavitOverridesCard
        label="notice type"
        value={updatedFilingType.affidavitReconciliationSettings}
        onChange={overrides => {
          setUpdatedFilingType({
            ...updatedFilingType,
            affidavitReconciliationSettings: removeUndefinedFields(overrides)
          });
        }}
      />
      <IntegrationSettingsCard
        onUpdateNoticeType={noticeType =>
          setUpdatedFilingType(noticeType as typeof updatedFilingType)
        }
        updatedNoticeType={updatedFilingType}
      />
    </>
  );
}

type FilingTypeFormProps<T extends FilingType> = {
  activeOrganization: ESnapshotExists<EOrganization>;
  filingType: T;
  updatedFilingType: T;
  setUpdatedFilingType: (filingType: T) => void;
  productLine: Product;
};

/**
 * Full-screen form to update properties associated with a filing type
 */
function FilingTypeUpdateForm<T extends FilingType>({
  activeOrganization,
  filingType,
  updatedFilingType,
  setUpdatedFilingType,
  productLine
}: FilingTypeFormProps<T>) {
  const user = useAppSelector(selectUser);
  const enableOrderFilingTypePublicationDateSettings = useBooleanFlag(
    LaunchDarklyFlags.ENABLE_ORDER_FILING_TYPE_PUBLICATION_DATE_SETTINGS
  );
  const context = getFirebaseContext();

  const rateService =
    productLine === Product.Notice
      ? new NoticeRateService(context)
      : new AdRateService(context.adRatesRef(), productLine);

  const { orgRates, loading: orgRatesLoading } = usePublisherOrgRates(
    rateService,
    activeOrganization.ref,
    {
      includeArchived: true,
      product: productLine
    }
  );

  if (orgRatesLoading) {
    return <LoadingState />;
  }

  const rates = orgRates.filter(rate => {
    if (filingType.rate?.id === rate.id) return true;
    return !rate.data().archived;
  });

  return (
    <>
      <BasicFilingTypePropertiesCard<T>
        onUpdateFilingType={setUpdatedFilingType}
        updatedFilingType={updatedFilingType}
        productLine={productLine}
      />
      {isNoticeFilingType(updatedFilingType) && (
        <StandardNoticeSettings
          activeOrganization={activeOrganization}
          updatedFilingType={updatedFilingType}
          setUpdatedFilingType={noticeType =>
            setUpdatedFilingType(noticeType as typeof updatedFilingType)
          }
        />
      )}
      <BillingSettingsCard
        onUpdateNoticeType={setUpdatedFilingType}
        updatedNoticeType={updatedFilingType}
        activeOrganization={activeOrganization}
        productLine={productLine}
        rates={rates}
      />
      {isNoticeFilingType(updatedFilingType) &&
        exists(user) &&
        userIsSuper(user) && (
          <SuperUserNoticeSettings
            activeOrganization={activeOrganization}
            updatedFilingType={updatedFilingType}
            setUpdatedFilingType={noticeType =>
              setUpdatedFilingType(noticeType as typeof updatedFilingType)
            }
          />
        )}

      {isOrderFilingType(updatedFilingType) && (
        <>
          {enableOrderFilingTypePublicationDateSettings && (
            <OrderFilingTypePublicationDatesCard
              updatedFilingType={updatedFilingType}
              onUpdateFilingType={setUpdatedFilingType}
            />
          )}
          <OrderLayoutFilingTypeSettings
            activeOrganization={activeOrganization}
            updatedFilingType={updatedFilingType}
            onUpdateFilingType={setUpdatedFilingType}
          />
        </>
      )}
      {!isNoticeFilingType(updatedFilingType) &&
        exists(user) &&
        userIsSuper(user) && (
          <SuperUserOrderFilingTypeSettings
            filingType={filingType}
            updatedFilingType={updatedFilingType}
            onUpdateFilingType={setUpdatedFilingType}
          />
        )}
      <div className="h-48" />
    </>
  );
}

type FilingTypeUpdateModalProps<T extends FilingType> = {
  activeOrganization: ESnapshotExists<EOrganization>;
  filingType: T;
  onClose: () => void;
  onSave: (filingType: T) => void;
  productLine: Product;
  isNewFilingType: boolean;
};

export default function FilingTypeUpdateModal<T extends FilingType>({
  activeOrganization,
  filingType,
  onClose,
  onSave,
  productLine,
  isNewFilingType
}: FilingTypeUpdateModalProps<T>) {
  const [updating, setUpdating] = useState(false);

  const [updatedFilingType, setUpdatedFilingType] = useState(filingType);

  const edited = !columnObjectsAreEqual(updatedFilingType, filingType);

  // Enable saving instantly for new filing types, and after edit for existing filing types
  const disableSave = updating || (!isNewFilingType && !edited);

  const updateFilingTypeProperties = async () => {
    setUpdating(true);
    try {
      onSave(updatedFilingType);
      onClose();
    } catch (err) {
      logAndCaptureException(
        ColumnService.WEB_PLACEMENT,
        err,
        'Unable to update filing type properties',
        {
          organizationId: activeOrganization.id
        }
      );
    }
    setUpdating(false);
  };

  const productNameSingular = last(
    PRODUCT_TO_NAME[productLine].singular.split(' ')
  );

  return (
    <FullScreenModal
      submittable={{
        buttonText: 'Save',
        disabled: !!disableSave,
        onSubmit: updateFilingTypeProperties
      }}
      headerText={
        isNewFilingType
          ? `Add ${productNameSingular} Type`
          : `Edit ${productNameSingular} Type`
      }
      onClose={onClose}
      id="filing-type-update-form"
    >
      <FilingTypeUpdateForm<T>
        activeOrganization={activeOrganization}
        filingType={filingType}
        updatedFilingType={updatedFilingType}
        setUpdatedFilingType={setUpdatedFilingType}
        productLine={productLine}
      />
    </FullScreenModal>
  );
}
