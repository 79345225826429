import ColumnDatePicker from 'components/ColumnDatePicker';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Label } from 'lib/components/Label';
import { EOrganization, ESnapshotExists } from 'lib/types';
import {
  getDateForDateStringInTimezone,
  getDateStringForDateInTimezone
} from 'lib/utils/dates';
import moment from 'moment';
import React from 'react';

export const ALL_VERIFICATION_STATUSES = 'Show all';
export const ALL_NOTICES_VERIFIED =
  'Publication days with no unverified notices';
export const MISSING_NOTICE_VERIFICATIONS =
  'Publication days with unverified notices';
export const MISSING_E_EDITION = 'Publication days without e-edition';
const VERIFICATION_STATUSES = [
  ALL_VERIFICATION_STATUSES,
  ALL_NOTICES_VERIFIED,
  MISSING_NOTICE_VERIFICATIONS,
  MISSING_E_EDITION
] as const;
export type VerificationStatus = (typeof VERIFICATION_STATUSES)[number];

const ALL_PUBLICATION_DATES = 'All publication dates';

export type AutomatedAffidavitTableFilter = {
  verificationStatus?: VerificationStatus;
  dateRange?: {
    publicationDateFrom?: string;
    publicationDateTo?: string;
  };
};

type AutomatedAffidavitsTableFilterDialogProps = {
  activeOrganization: ESnapshotExists<EOrganization>;
  editedRowFilter: AutomatedAffidavitTableFilter;
  setEditedRowFilter: (filter: AutomatedAffidavitTableFilter) => void;
};

export default function AutomatedAffidavitsTableFilterDialog({
  activeOrganization,
  editedRowFilter,
  setEditedRowFilter
}: AutomatedAffidavitsTableFilterDialogProps) {
  return (
    <>
      <p className="uppercase text-column-gray-300 text-xs font-bold">
        Filter by
      </p>

      {/* Control what subset of publication days we're looking at */}
      <ColumnSelect<VerificationStatus>
        id="verification-status"
        labelText="Verification Status"
        size="small"
        options={VERIFICATION_STATUSES.map(status => ({
          value: status,
          label: status
        }))}
        value={editedRowFilter.verificationStatus}
        onChange={newValue =>
          setEditedRowFilter({
            ...editedRowFilter,
            verificationStatus: newValue
          })
        }
      />

      {/* Control the publication dates visible in the table */}
      <ColumnSelect
        id="publication-dates"
        labelText="Publication Dates"
        size="small"
        options={[
          {
            value: ALL_PUBLICATION_DATES,
            label: ALL_PUBLICATION_DATES
          },
          {
            value: 'Custom',
            label: 'Custom Date Range'
          }
        ]}
        value={!editedRowFilter.dateRange ? ALL_PUBLICATION_DATES : 'Custom'}
        onChange={newValue => {
          if (newValue === ALL_PUBLICATION_DATES) {
            setEditedRowFilter({
              ...editedRowFilter,
              dateRange: undefined
            });
          } else {
            setEditedRowFilter({
              ...editedRowFilter,
              dateRange: {
                publicationDateFrom: getDateStringForDateInTimezone({
                  date: moment().subtract(1, 'month').toDate(),
                  timezone: activeOrganization.data().iana_timezone
                }),
                publicationDateTo: getDateStringForDateInTimezone({
                  date: new Date(),
                  timezone: activeOrganization.data().iana_timezone
                })
              }
            });
          }
        }}
      />

      {/* Filter on a custom date range */}
      {editedRowFilter.dateRange && (
        <div>
          <Label id="date-filter-label">Choose a date range</Label>
          <div className="flex flex-row gap-2 mt-2">
            <ColumnDatePicker
              className="w-1/2"
              format="MMM dd"
              momentFormat="MMM DD"
              placeholderText="Start date"
              value={
                editedRowFilter.dateRange.publicationDateFrom
                  ? getDateForDateStringInTimezone({
                      dayString: editedRowFilter.dateRange.publicationDateFrom,
                      timezone: activeOrganization.data().iana_timezone
                    })
                  : undefined
              }
              onChange={newDate =>
                newDate &&
                setEditedRowFilter({
                  ...editedRowFilter,
                  dateRange: {
                    ...editedRowFilter.dateRange,
                    publicationDateFrom: getDateStringForDateInTimezone({
                      date: newDate,
                      timezone: activeOrganization.data().iana_timezone
                    })
                  }
                })
              }
              shouldDisableDate={date => {
                if (!date) return true;
                const dateLimit = editedRowFilter.dateRange?.publicationDateTo
                  ? getDateForDateStringInTimezone({
                      dayString: editedRowFilter.dateRange.publicationDateTo,
                      timezone: activeOrganization.data().iana_timezone
                    })
                  : new Date();
                return moment(date).isAfter(moment(dateLimit));
              }}
            />
            <ColumnDatePicker
              className="w-1/2"
              format="MMM dd"
              momentFormat="MMM DD"
              placeholderText="End date"
              value={
                editedRowFilter.dateRange.publicationDateTo
                  ? getDateForDateStringInTimezone({
                      dayString: editedRowFilter.dateRange.publicationDateTo,
                      timezone: activeOrganization.data().iana_timezone
                    })
                  : undefined
              }
              onChange={newDate =>
                newDate &&
                setEditedRowFilter({
                  ...editedRowFilter,
                  dateRange: {
                    ...editedRowFilter.dateRange,
                    publicationDateTo: getDateStringForDateInTimezone({
                      date: newDate,
                      timezone: activeOrganization.data().iana_timezone
                    })
                  }
                })
              }
              shouldDisableDate={date => {
                if (!date) return true;
                const dateLimit = editedRowFilter.dateRange?.publicationDateFrom
                  ? getDateForDateStringInTimezone({
                      dayString: editedRowFilter.dateRange.publicationDateFrom,
                      timezone: activeOrganization.data().iana_timezone
                    })
                  : null;
                if (!dateLimit) return false;
                return (
                  moment(date).isBefore(dateLimit) ||
                  moment(date).isAfter(moment())
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
