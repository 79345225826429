// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import {
  ENotice,
  EOrganization,
  ERef,
  ERequestTypes,
  EResponseTypes,
  ESnapshotExists
} from 'lib/types';
import { useLoading } from 'lib/components/hooks/useLoading';
import { Tooltip } from 'lib/components/Tooltip';
import { noticeIsPublished } from 'lib/helpers';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { logAndCaptureException } from 'utils';
import api from 'api';
import { ColumnButton } from 'lib/components/ColumnButton';
import { AffinityXOrderNumber } from 'lib/integrations/affinityx/types';
import { ColumnService } from 'lib/services/directory';
import { AffinityXSyncStatus, getButtonTextFromSyncStatus } from './helpers';

type AffinityXSyncButtonProps = {
  heightIsValid: boolean;
  noticeSnap: ESnapshotExists<ENotice>;
  publicationSnap: ESnapshotExists<EOrganization>;
  syncStatus: AffinityXSyncStatus | null;
  setSyncStatus: (status: AffinityXSyncStatus) => void;
  noticeIsAfterDeadline: boolean;
  orderNumber: AffinityXOrderNumber | null;
  reqData: Omit<
    ERequestTypes['integrations/async-design/notice/:noticeId/create-build-ad-request'],
    'orderNumber'
  >;
};

const createBuildAdRequestEvent = async ({
  noticeRef,
  reqData
}: {
  noticeRef: ERef<ENotice>;
  reqData: ERequestTypes['integrations/async-design/notice/:noticeId/create-build-ad-request'];
}) => {
  try {
    const resp: EResponseTypes['integrations/async-design/notice/:noticeId/create-build-ad-request'] =
      await api.post(
        `integrations/async-design/notice/${noticeRef.id}/create-build-ad-request`,
        reqData
      );
    if (!resp.success) {
      throw new Error(resp.error);
    }
  } catch (err) {
    logAndCaptureException(
      ColumnService.AFFINITY_X,
      err,
      'Problem creating BuildAdRequestEvent on client',
      {
        noticeId: noticeRef.id,
        orderNumber: reqData.orderNumber,
        requestingUserId: reqData.requestingUserId
      }
    );
  }
};

function AffinityXSyncButton({
  heightIsValid,
  noticeSnap,
  publicationSnap,
  syncStatus,
  noticeIsAfterDeadline,
  setSyncStatus,
  orderNumber,
  reqData
}: AffinityXSyncButtonProps) {
  const [loadingSyncToAffinity, handleSyncToAffinityWithLoading] = useLoading();
  const [modalToShow, setModalToShow] = useState<
    'after-deadline-modal' | 'already-exists-modal' | null
  >(null);
  const showSyncAfterDeadlineModal = modalToShow === 'after-deadline-modal';
  const showOrderAlreadyExistsModal = modalToShow === 'already-exists-modal';
  const { hasReachedFirstPublication } = noticeIsPublished(
    noticeSnap,
    publicationSnap
  );

  const disableSync =
    hasReachedFirstPublication ||
    noticeSnap.data().isArchived ||
    !reqData.numberOfColumns ||
    !reqData.approxHeightInInches ||
    !reqData.pageCount ||
    !heightIsValid ||
    !orderNumber ||
    !noticeSnap.data().customId ||
    syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;

  const handleSyncToAffinity = async () => {
    if (disableSync) {
      return;
    }

    try {
      await createBuildAdRequestEvent({
        noticeRef: noticeSnap.ref,
        reqData: { ...reqData, orderNumber }
      });
      setSyncStatus(AffinityXSyncStatus.SYNC_IN_PROGRESS);
    } catch (err) {
      setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION);
    }

    setModalToShow(null);
  };

  const onSyncButtonClick = async () => {
    const orderAlreadyExists =
      syncStatus !== AffinityXSyncStatus.READY_TO_SYNC &&
      syncStatus !== AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION;
    if (orderAlreadyExists) {
      setModalToShow('already-exists-modal');
      return;
    }

    if (noticeIsAfterDeadline) {
      setModalToShow('after-deadline-modal');
      return;
    }

    await handleSyncToAffinityWithLoading(handleSyncToAffinity, () =>
      setSyncStatus(AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION)
    );
  };

  const buttonText = getButtonTextFromSyncStatus(syncStatus);
  const shouldShowRunDateTooltip =
    hasReachedFirstPublication &&
    [
      AffinityXSyncStatus.READY_TO_SYNC,
      AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
    ].some(status => status === syncStatus);

  return (
    <>
      {showOrderAlreadyExistsModal && (
        <CancelOrSubmitModal
          onClose={() => setModalToShow(null)}
          header="Order already exists"
          primaryButtonText="Yes, update order"
          tertiaryButtonText="Back"
          onSubmit={() => {
            if (noticeIsAfterDeadline) {
              setModalToShow('after-deadline-modal');
              return;
            }

            return handleSyncToAffinityWithLoading(handleSyncToAffinity, () =>
              setSyncStatus(
                AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
              )
            );
          }}
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            Order {orderNumber} already exists in AffinityX. Are you sure you
            want to update that order with new information?
          </div>
        </CancelOrSubmitModal>
      )}
      {showSyncAfterDeadlineModal && (
        <CancelOrSubmitModal
          onClose={() => setModalToShow(null)}
          header="Sync to AffinityX after deadline?"
          primaryButtonText="Sync"
          tertiaryButtonText="Back"
          onSubmit={() =>
            handleSyncToAffinityWithLoading(handleSyncToAffinity, () =>
              setSyncStatus(
                AffinityXSyncStatus.SYNC_FAILED_BEFORE_ORDER_CREATION
              )
            )
          }
          showLoadingSpinner
        >
          <div className="py-6 text-column-gray-400">
            The ad deadline has passed for this notice and your newspaper will
            be charged for the AffinityX order even if the designed ad is not
            complete by publication. Alternatively, you can edit the notice so
            that the ad deadline is in the future. Are you sure you would like
            to sync?
          </div>
        </CancelOrSubmitModal>
      )}
      <Tooltip
        classes="w-full"
        helpText={
          noticeSnap.data().isArchived
            ? 'You cannot sync to AffinityX because this notice has been archived. Duplicate this notice with future publication dates to place it again.'
            : !orderNumber
            ? 'You must confirm this notice to create a valid order number. If you have confirmed the notice and are still seeing this message, please reach out to help@column.us'
            : shouldShowRunDateTooltip
            ? 'You cannot sync to AffinityX because the first publication date has passed for this notice. Please edit the notice to have a future publication date.'
            : ''
        }
      >
        <ColumnButton
          id="affinity-x-sync-button"
          fullWidth
          primary
          buttonText={buttonText}
          size="lg"
          disabled={disableSync}
          onClick={onSyncButtonClick}
          loading={loadingSyncToAffinity}
          type="button"
        />
      </Tooltip>
    </>
  );
}

export default AffinityXSyncButton;
