import { isNoticePricingParameters, PricingParameters } from '.';
import { NoticeType } from '../enums';
import { AdRate, EDisplayParams, EOrganization } from '../types';
import { calculateDBPrice } from './calculateDbPrice';
import calculateFee from './calculateFee';
import { calculateBoldPrices } from './calculateSinglePeriodPrice';
import findDayRate from './findDayRate';

/**
 * The one true pricing function is createDBPricingObjectFromData, so if you're using this outside
 * of `lib/pricing`, you're probably doing something wrong!
 *
 * Calculate the "db price" in cents for a single run of a notice. This is
 * the default price unless it is modified in the invoice step.
 *
 * @deprecated SOON TO BE DEPRECATED AS OF 12/11/2024 -- Use 'calculateSinglePeriodPrice' instead
 */

const calculateSingleRunPrice = (
  pricingParameters: PricingParameters,
  displayParameters: EDisplayParams,
  newspaper: EOrganization,
  rate: AdRate,
  runNumber: number
) => {
  const numRuns = pricingParameters.publicationDates.length;
  if (runNumber >= numRuns) {
    throw new Error(
      `Cannot compute pricing for run #${runNumber} of notice with ${numRuns} runs`
    );
  }

  const publicationDate = pricingParameters.publicationDates[runNumber];
  const { dayRate } = findDayRate(rate, publicationDate);

  const columns =
    displayParameters.columns ||
    (isNoticePricingParameters(pricingParameters) &&
      pricingParameters.columns) ||
    1;

  const baseDbPrice = calculateDBPrice(
    pricingParameters,
    rate,
    displayParameters,
    numRuns,
    columns,
    dayRate,
    runNumber
  );

  // Display ads can't have bold pricing
  const boldPrice =
    isNoticePricingParameters(pricingParameters) &&
    pricingParameters.noticeType === NoticeType.display_ad.value
      ? 0
      : calculateBoldPrices(rate, displayParameters);

  const fee = calculateFee(newspaper, numRuns, runNumber) ?? 0;

  const imageFee =
    (displayParameters.images?.length ?? 0) * (rate.images?.flatRate ?? 0);

  // Round to the nearest cent at the end
  const priceInCents = Math.round(baseDbPrice + boldPrice + fee + imageFee);
  if (rate.runMinimumInCents && priceInCents < rate.runMinimumInCents) {
    return rate.runMinimumInCents;
  }
  return priceInCents;
};

export default calculateSingleRunPrice;
