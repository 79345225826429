/* eslint-disable max-classes-per-file */
import {
  WhereFilterOp,
  EQuery,
  OrderByDirection,
  EQuerySnapshot,
  EUnsubscribe,
  EDocumentData,
  ERef,
  ECollectionRef
} from '../../types/firebase';
import { isRef } from '../../model/refs';

const safeStringifyQueryValue = (value: any) => {
  if (isRef(value)) {
    return value.path;
  }

  return `${value}`;
};

export class DebugQuery<T> implements EQuery<T> {
  constructor(
    private base: EQuery<T>,
    private basePath: string,
    private clauses: string[][]
  ) {
    // ...
  }

  private wrap(newBase: EQuery<T>, newClauses: any[]) {
    return new DebugQuery<T>(newBase, this.basePath, [
      ...this.clauses,
      newClauses.map(safeStringifyQueryValue)
    ]);
  }

  where(fieldPath: string, opStr: WhereFilterOp, value: any): EQuery<T> {
    return this.wrap(this.base.where(fieldPath, opStr, value), [
      'where',
      fieldPath,
      opStr,
      value
    ]);
  }

  orderBy(
    fieldPath: Object,
    directionStr?: OrderByDirection | undefined
  ): EQuery<T> {
    return this.wrap(this.base.orderBy(fieldPath, directionStr), [
      'orderBy',
      fieldPath,
      directionStr
    ]);
  }

  limit(limit: number): EQuery<T> {
    return this.wrap(this.base.limit(limit), ['limit']);
  }

  limitToLast(limit: number): EQuery<T> {
    return this.wrap(this.base.limitToLast(limit), ['limitToLast']);
  }

  startAt(...fieldValues: any[]): EQuery<T> {
    return this.wrap(this.base.startAt(fieldValues), ['startAt', fieldValues]);
  }

  startAfter(...fieldValues: any[]): EQuery<T> {
    return this.wrap(this.base.startAfter(fieldValues), ['startAfter']);
  }

  endBefore(...fieldValues: any[]): EQuery<T> {
    return this.wrap(this.base.endBefore(fieldValues), ['endBefore']);
  }

  endAt(...fieldValues: any[]): EQuery<T> {
    return this.wrap(this.base.endAt(fieldValues), ['endAt', fieldValues]);
  }

  get(): Promise<EQuerySnapshot<T>> {
    console.log('[fs][query.get]', this.basePath, this.clauses);
    return this.base.get();
  }

  onSnapshot(
    onNext: (snapshot: EQuerySnapshot<T>) => void,
    onError?: ((error: any) => void) | undefined
  ): EUnsubscribe {
    console.log('[fs][query.onSnapshot]', this.basePath, this.clauses);
    return this.base.onSnapshot(onNext, onError);
  }
}

/**
 * Because we often use ERef as field values we can't wrap them, otherwise
 * they won't be recognized by the Firestore SDK. But we can wrap them in a proxy.
 */
const proxyDocRef = <T>(ref: ERef<T>) => {
  return new Proxy(ref, {
    get(target, property, receiver) {
      if (
        typeof property === 'string' &&
        ['get', 'onSnapshot', 'set', 'update', 'delete'].includes(property)
      ) {
        console.log(`[fs][doc.${property}]`, target.path);
      }

      return Reflect.get(target, property, receiver);
    }
  });
};

export class DebugCollectionRef<T>
  extends DebugQuery<T>
  implements ECollectionRef<T>
{
  constructor(private baseColl: ECollectionRef<T>) {
    super(baseColl, baseColl.path, []);
  }

  get id() {
    return this.baseColl.id;
  }

  get parent() {
    return this.baseColl.parent;
  }

  get path() {
    return this.baseColl.path;
  }

  doc(documentPath?: string): ERef<T> {
    if (typeof documentPath === 'string') {
      return proxyDocRef(this.baseColl.doc(documentPath));
    }
    return proxyDocRef(this.baseColl.doc());
  }

  add(data: EDocumentData<T>): Promise<ERef<T>> {
    console.log('[fs][add]', this.path);
    return this.baseColl.add(data).then(r => proxyDocRef(r));
  }
}
