import api from 'api';
import { push } from 'connected-react-router';
import { CancelOrSubmitModal } from 'lib/components/CancelOrSubmitModal';
import { ERequestTypes, EResponseTypes, exists } from 'lib/types';
import { useState } from 'react';
import AuthActions, {
  selectActiveOrganization,
  selectUser,
  selectUserAuth
} from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  POST_ORGANIZATION_REGISTRATION_ENTRYPOINT,
  POST_REGISTRATION_ENTRYPOINT
} from 'redux/router';
import {
  organizationAlreadyExists,
  updatePendingInviteStatusForAnonUsers
} from 'routes/register/helpers';
import { logAndCaptureMessage } from 'utils';
import { getFirebaseContext } from 'utils/firebase';

import { OrganizationTypeValue } from 'lib/enums/OrganizationType';
import AdvertiserRegistrationFooter from '../AdvertiserRegistrationFooter';
import RegisterOrganizationAddressForm, {
  EMPTY_ADDRESS,
  RegisterOrganizationAddress
} from './RegisterOrganizationAddressForm';
import RegisterOrganizationAlreadyExists from './RegisterOrganizationAlreadyExists';
import { createOrganization } from './createOrganization';

type OrganizationRegistrationProps = {
  organizationType: OrganizationTypeValue;
};

export default function OrganizationRegistration({
  organizationType
}: OrganizationRegistrationProps) {
  const [showCancelWarning, setShowCancelWarning] = useState(false);

  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const userAuth = useAppSelector(selectUserAuth);
  const activeOrganization = useAppSelector(selectActiveOrganization);

  const [address, setAddress] =
    useState<RegisterOrganizationAddress>(EMPTY_ADDRESS);
  const [loading, setLoading] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);
  const allFieldsValid = address.validation.valid;

  const goBackLink = () => {
    dispatch(push(POST_REGISTRATION_ENTRYPOINT));
  };

  const nextButtonClicked = async () => {
    if (!allFieldsValid) {
      return;
    }
    await handleSubmit();
  };

  const handleSubmit = async () => {
    if (!exists(user)) {
      logAndCaptureMessage('User does not exist');
      return;
    }

    setLoading(true);

    // Check if org already exists
    const orgAlreadyExists = await organizationAlreadyExists(
      organizationType,
      address.name
    );

    if (orgAlreadyExists) {
      setAlreadyExists(true);
      setLoading(false);
      return;
    }

    const newlyCreatedOrganization = await createOrganization({
      organizationType,
      name: address.name,
      address: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      state: address.state.id,
      zipCode: address.zipCode,
      phone: address.phone,
      createdBy: userAuth?.uid,
      stripeId: user.data().stripeId || activeOrganization?.data()?.stripeId,
      userName: user.data().name,
      email: user.data().email
    });

    const reqBody: ERequestTypes['users/link-filer-to-org'] = {
      // if there is an active organization, skip the notice migration
      skipNoticeMigration: Boolean(activeOrganization),
      // link to the new organization
      organizationId: newlyCreatedOrganization.id,
      uid: userAuth?.uid || ''
    };

    const resp: EResponseTypes['users/link-filer-to-org'] = await api.post(
      'users/link-filer-to-org',
      reqBody
    );

    if (!resp.success) {
      throw new Error(
        `Unable to link filer ${uid} to organization ${newlyCreatedOrganization.id}`
      );
    }

    await updatePendingInviteStatusForAnonUsers(getFirebaseContext(), user);
    await user.ref.update({
      postRegistrationComplete: true,
      activeOrganization: newlyCreatedOrganization
    });

    dispatch(AuthActions.register());
    dispatch(push(POST_ORGANIZATION_REGISTRATION_ENTRYPOINT));
    window.location.reload();
  };

  return (
    <>
      <div className="flex flex-col w-200">
        {/* This address form appear with organization search field and browser
          takes it as one form (all new organization field and search org field) and apply auto-fill (name field)
          on the search organization field as well. To prevent this, Create New Organization form should be wrapped
          into a form to considered it a seperate form.
          https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls:-the-autocomplete-attribute
        */}
        <form className="flex flex-col bg-white rounded-md px-8 pt-8 pb-12 shadow-2 border">
          <RegisterOrganizationAddressForm
            nameFieldText={'Name of organization'}
            handleInputChanged={value => setAddress(value)}
            namePlaceholderText="Name of organization"
          />
        </form>
        <AdvertiserRegistrationFooter
          id="submit-organization"
          backButtonText="Go back"
          nextButtonText="Create organization"
          onBackButtonClick={() => setShowCancelWarning(true)}
          onNextButtonClick={nextButtonClicked}
          loading={loading}
          disableNextButton={!allFieldsValid}
        />
      </div>
      {showCancelWarning && (
        <CancelOrSubmitModal
          header={'Are you sure?'}
          secondaryButtonText={'Cancel'}
          onSecondaryButtonClick={() => {
            setShowCancelWarning(false);
          }}
          onClose={() => setShowCancelWarning(false)}
          primaryButtonText={`Yes, continue`}
          onSubmit={goBackLink}
        >
          <p className="py-5">
            If you go back now, your progress will be lost. Please click "Yes,
            continue" to confirm.
          </p>
        </CancelOrSubmitModal>
      )}
      {alreadyExists && (
        <RegisterOrganizationAlreadyExists
          name={address.name}
          onBackClicked={() => setAlreadyExists(false)}
        />
      )}
    </>
  );
}
