import {
  ArrowTopRightOnSquareIcon,
  ExclamationCircleIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { UploadedFileDisplay } from '../UploadedFileDisplay';
import { getErrorReporter } from '../../utils/errors';
import { useFirebaseStorageDownloadUrl } from '../../frontend/hooks/useFirebaseStorageDownloadUrl';

type FileUploadListItemProps = {
  firebaseStoragePath?: string;
  deleteConfirmation?: () => Promise<boolean>;
  displayFileName?: string | null;
  fileExtensionString?: string;
  disableView?: boolean;
  viewButtonText?: string;
  viewButtonIcon?: JSX.Element;
  onDelete?: () => void | Promise<void> | null | undefined;
  disableDelete?: boolean;
  deleteTooltipText?: string;
  storage: firebase.storage.Storage;
};

export function FileUploadListItem({
  firebaseStoragePath,
  deleteConfirmation,
  displayFileName,
  fileExtensionString,
  disableView,
  viewButtonText = 'View',
  viewButtonIcon = <ArrowTopRightOnSquareIcon className="h-6 w-6" />,
  onDelete,
  disableDelete,
  deleteTooltipText,
  storage
}: FileUploadListItemProps) {
  const storageRef = storage.ref(firebaseStoragePath);

  const { downloadUrl, previewState } =
    useFirebaseStorageDownloadUrl(storageRef);

  const [deletionState, setDeletionState] = useState<
    'idle' | 'deleting' | 'error'
  >('idle');

  async function deleteFile() {
    try {
      const isConfirmed = deleteConfirmation
        ? await deleteConfirmation()
        : true;

      if (isConfirmed) {
        setDeletionState('deleting');
        onDelete && onDelete();
        return;
      }

      setDeletionState('idle');
    } catch (error) {
      setDeletionState('error');
      getErrorReporter().logAndCaptureError(
        ColumnService.FILE_STORAGE,
        error,
        'Unable to delete file',
        {
          storagePath: storageRef.fullPath
        }
      );
    }
  }

  const downloadUrlNotFound = previewState === 'error';

  const deletionHelpText = {
    idle: 'Delete',
    deleting: 'Deleting...',
    error: 'Unable to delete'
  }[deletionState];

  const previewHelpText = {
    idle: viewButtonText,
    loading: 'Loading preview...',
    error: 'File not found'
  }[previewState];

  return (
    <UploadedFileDisplay
      fileExtensionString={fileExtensionString || ''}
      displayFileName={displayFileName || storageRef.name}
      viewLoading={previewState === 'loading'}
      disableView={downloadUrlNotFound || !!disableView}
      viewStartIcon={
        downloadUrlNotFound ? (
          <ExclamationCircleIcon className="h-6 w-6" />
        ) : (
          viewButtonIcon
        )
      }
      viewFileUrl={downloadUrl}
      viewButtonText={previewHelpText}
      deleteOptions={
        onDelete && {
          deleteTooltipText: deleteTooltipText || '',
          deleteLoading: deletionState === 'deleting',
          disableDelete: !!disableDelete,
          deleteStartIcon: <TrashIcon className="h-6 w-6 mt-0.5" />,
          onDeleteFile: deleteFile,
          deleteButtonText: deletionHelpText
        }
      }
    />
  );
}
