import { EQuery, EQuerySnapshot, ERef, ESnapshotExists } from '../types';
import { getOrThrow } from '../utils/refs';
import {
  ResponseOrColumnError,
  ResponseOrError,
  wrapError,
  wrapSuccess
} from '../types/responses';
import { ColumnService } from '../services/directory';
import {
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { getErrorReporter } from '../utils/errors';
import { isResponseOrError } from '../helpers';

export function safeAsync<T, E extends Error = Error>(
  func: (...args: any[]) => Promise<T> | Promise<ResponseOrError<T, E>>
) {
  return async (...args: any[]): Promise<ResponseOrError<T, E>> => {
    try {
      const possibleResponseOrError = await func(...args);
      if (isResponseOrError(possibleResponseOrError)) {
        return possibleResponseOrError;
      }
      return wrapSuccess(possibleResponseOrError);
    } catch (error) {
      return wrapError(error as E);
    }
  };
}

export async function safeGetOrThrow<T>(
  ref: ERef<T> | null | undefined,
  options?: { suppressError: boolean }
): Promise<ResponseOrError<ESnapshotExists<T>, Error>> {
  const [error, snap] = await safeAsync<ESnapshotExists<T>, Error>(getOrThrow)(
    ref
  );
  if (error) {
    if (!options?.suppressError) {
      getErrorReporter().logAndCaptureError(
        ColumnService.DATABASE,
        error,
        'Error fetching document',
        {
          documentId: ref?.id || '',
          path: ref?.path || ''
        }
      );
    }
    return wrapError(error);
  }

  return wrapSuccess(snap);
}

export async function safeGetQuery<T>(
  query: EQuery<T>
): Promise<ResponseOrColumnError<EQuerySnapshot<T>>> {
  const { response: orderDetailResults, error: queryError } = await safeAsync(
    () => query.get()
  )();
  if (queryError) {
    getErrorReporter().logAndCaptureError(
      ColumnService.DATABASE,
      queryError,
      'Error fetching query results'
    );
    return wrapErrorAsColumnError(queryError, InternalServerError);
  }
  return wrapSuccess(orderDetailResults);
}
