import Joi from 'joi';
import { z } from 'zod';
import { InvoiceModel } from '../model/objects/invoiceModel';
import { PaymentGateway } from '../constants';
import { ElavonGatewaySessionResponse } from './elavon';
import { LineItem, PaymentMethods } from './invoices';
import {
  PaywayCardTransactionData,
  PaywayCardTransactionDataSchema,
  PaywayGatewaySessionResponse
} from './payway';
import { InvoiceTransactionModel } from '../model/objects/invoiceTransactionModel';
import { FirebaseTimestampSchema } from './firebase';

export type GatewayTransactionData = PaywayCardTransactionData; // To expand with other gateways

export const GatewayTransactionDataSchema = PaywayCardTransactionDataSchema;

export type GatewaySessionResponse =
  | PaywayGatewaySessionResponse
  | ElavonGatewaySessionResponse;

export const GatewaySessionResponseSchema = Joi.object({
  sessionToken: Joi.string().required()
});

export type InvoiceTransactionsCreateSessionResponse = {
  gatewaySessionResponse: GatewaySessionResponse | undefined;
  idempotencyKey: string;
};

export type InvoiceTransactionsFetchRefundTransactionsResponse =
  | {
      success: true;
      refundLineItems: LineItem[];
    }
  | {
      success: false;
      error: string;
    };

export const InvoiceTransactionsCreateSessionResponseSchema = Joi.object({
  gatewaySessionResponse: GatewaySessionResponseSchema.required(),
  idempotencyKey: Joi.string().required()
});

// TODO(goodpaul): Move InvoiceTransactionsCreateChargeRequest to zod then we can deduplicate types
export const InvoiceTransactionsAuthorizeRequestSchema = z.object({
  amountInCents: z.number(),
  idempotencyKey: z.string(),
  customerEmail: z.string(),
  invoiceId: z.string(),
  paymentMethodToken: z.string(),
  paymentMethodType: z.string(),
  paymentSessionToken: z.string().optional()
});
export type InvoiceTransactionsAuthorizeRequest = z.infer<
  typeof InvoiceTransactionsAuthorizeRequestSchema
>;

// TODO(goodpaul): Extract PaymentMethods type from invoices and move to constants
export type InvoiceTransactionsCreateChargeRequest = {
  amountInCents: number;
  customerEmail: string;
  idempotencyKey: string;
  invoiceId: string;
  paymentMethodToken: string;
  paymentMethodType: PaymentMethods;
  // paymentSessionToken is not valid for Stripe, but required for Payway & Elavon
  paymentSessionToken?: string;
  gatewayTransactionData?: GatewayTransactionData;
};

// These types fully overlap for now, but leave as separate for future flexibility
export type InvoiceTransactionsCreateAuthorizationRequest =
  InvoiceTransactionsCreateChargeRequest;

export type NoticeRefundRequest = {
  amountInCents: number;
  invoiceId: string;
  isInvoiceCancellation: boolean;
  refundReason: string;
};

export type PaymentGatewayResponse = {
  rawResponse: string;
  responseCode: string;
  responseMessage: string;
  isSuccessful: boolean;
  // Allows us to show a different message to the user
  userMessage?: string;
  sourceGatewayTransactionId?: string;
  gatewayTransactionId?: string;
  paymentMethodId?: string;
  gatewayTransactionDate?: string;
  amountInCents?: number;
  surchargeInCents?: number;
};

export const PaymentGatewayResponseSchema = Joi.object({
  rawResponse: Joi.string().required(),
  responseCode: Joi.string().required(),
  responseMessage: Joi.string().required(),
  isSuccessful: Joi.boolean().required(),
  sourceGatewayTransactionId: Joi.string(),
  gatewayTransactionId: Joi.string(),
  paymentMethodId: Joi.string(),
  gatewayTransactionDate: Joi.string(),
  amountInCents: Joi.number(),
  surchargeInCents: Joi.number()
});

export type PaymentGatewayResult = {
  responseCode: string;
  responseMessage: string;
  // Renaming this prop so we get type errors if we accidentally send back the PaymentGatewayResponse
  gatewayResultSuccessful: boolean;
  amountInCents?: number;
};

export type ColumnPaymentResult = {
  columnPaymentSuccess: boolean;
  errorMessage?: string;
};

/**
 * Used when we have an in-app result as well as a payment gateway result to send back
 */
export type ColumnPaymentResponse = {
  paymentGatewayResult: PaymentGatewayResult;
  columnPaymentResult?: ColumnPaymentResult;
};

export enum InvoiceTransactionStatus {
  Failed = 'failed',
  Initialized = 'initialized',
  Pending = 'pending',
  /**
   * Completed statuses below
   * Why not just 'completed'? Having explicit completed statuses allows us to
   * have a single discriminator for type narrowing instead of type and status
   */
  Authorized = 'authorized',
  Captured = 'captured',
  Charged = 'charged',
  Refunded = 'refunded',
  Credited = 'credited'
}

export const CompletedInvoiceTransactionStatuses = [
  InvoiceTransactionStatus.Authorized,
  InvoiceTransactionStatus.Captured,
  InvoiceTransactionStatus.Charged,
  InvoiceTransactionStatus.Refunded,
  InvoiceTransactionStatus.Credited
] as const;

export type CompletedInvoiceTransactionStatus =
  (typeof CompletedInvoiceTransactionStatuses)[number];

export enum InvoiceTransactionType {
  Charge = 'charge',
  Refund = 'refund',
  Authorize = 'authorize',
  Capture = 'capture',
  Credit = 'credit'
}

export function isCompletedInvoiceTransactionStatus(
  status: InvoiceTransactionStatus
): status is CompletedInvoiceTransactionStatus {
  return CompletedInvoiceTransactionStatuses.includes(
    status as CompletedInvoiceTransactionStatus
  );
}

const InvoiceTransactionBase = z.object({
  createdAt: FirebaseTimestampSchema,
  // The idempotency key for this transaction. Used by the FE to prevent duplicate payments
  idempotencyKey: z.string(),
  /**
   * Payment gateway for transaction, previously set only on the invoice
   * TODO(goodpaul) Remove optionality after migration
   */
  gateway: z.string().optional(),
  status: z.nativeEnum(InvoiceTransactionStatus),
  type: z.nativeEnum(InvoiceTransactionType)
});

export const InitializedInvoiceTransactionSchema =
  InvoiceTransactionBase.extend({
    status: z.literal(InvoiceTransactionStatus.Initialized)
  });

// TODO(goodpaul): Add gatewayRequest prop
export const PendingInvoiceTransactionSchema = InvoiceTransactionBase.extend({
  status: z.literal(InvoiceTransactionStatus.Pending),
  amountSubmittedInCents: z.number(),
  paymentMethodId: z.string().optional(),
  paymentMethodType: z.string().optional(),
  customerEmail: z.string().optional()
});

export const FailedInvoiceTransactionSchema = InvoiceTransactionBase.extend({
  status: z.literal(InvoiceTransactionStatus.Failed),
  amountSubmittedInCents: z.number(),
  gatewayRawResponse: z.string(),
  gatewayResponse: z.string(),
  gatewayTransactionDate: z.string().optional(),
  sourceGatewayTransactionId: z.string().optional()
});

const CompletedInvoiceTransactionSchemaBase = InvoiceTransactionBase.extend({
  status: z.enum(CompletedInvoiceTransactionStatuses),
  // The amount the payment gateway says was processed
  amountProcessedInCents: z.number(),
  // The amount we sent to the payment gateway
  amountSubmittedInCents: z.number(),
  // Records a stringified version of the gateway response
  gatewayRawResponse: z.string(),
  // The response message from the gateway
  gatewayResponse: z.string(),
  // Unique identifier for the gateway transaction, for Stripe authCapture this is the payment intent
  gatewayTransactionId: z.string(),
  // Date of gateway transaction according to the payment gateway (requires migration to make non-nullable)
  gatewayTransactionDate: z.string().optional()
});

export const ChargedInvoiceTransactionSchema =
  CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Charged),
    type: z.literal(InvoiceTransactionType.Charge),
    // Gateway reference for the payment information
    paymentMethodId: z.string(),
    // Payment method type (e.g. card, ach)
    paymentMethodType: z.string(),
    // Surcharge amount in cents, if any applied (currently, only Elavon for Lee Adpoint applies a surcharge)
    surchargeInCents: z.number().optional()
  });

export const RefundedInvoiceTransactionSchema =
  CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Refunded),
    type: z.literal(InvoiceTransactionType.Refund),
    // gatewayTransactionId referenced for current transaction e.g. charge id for a refund, not present for charges
    sourceGatewayTransactionId: z.string(),
    refundReason: z.string().optional()
  });

export const AuthorizedInvoiceTransactionSchema =
  CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Authorized),
    type: z.literal(InvoiceTransactionType.Authorize),
    customerEmail: z.string(),
    // Gateway reference for the payment information
    paymentMethodId: z.string(),
    // Payment method type (e.g. card, ach)
    paymentMethodType: z.string(),
    // TODO(goodpaul): Remove this non-optionality assertion after this prop is added to all invoice transacitons
    gateway: z.string()
  });

export const CapturedInvoiceTransactionSchema =
  CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Captured),
    type: z.literal(InvoiceTransactionType.Capture)
  });

export const CreditedInvoiceTransactionSchema =
  CompletedInvoiceTransactionSchemaBase.extend({
    status: z.literal(InvoiceTransactionStatus.Credited),
    type: z.literal(InvoiceTransactionType.Credit)
  });

// Union of all InvoiceTransaction Types
export const InvoiceTransactionSchema = z.discriminatedUnion('status', [
  InitializedInvoiceTransactionSchema,
  PendingInvoiceTransactionSchema,
  FailedInvoiceTransactionSchema,
  ChargedInvoiceTransactionSchema,
  RefundedInvoiceTransactionSchema,
  AuthorizedInvoiceTransactionSchema,
  CapturedInvoiceTransactionSchema,
  CreditedInvoiceTransactionSchema
]);

export type InitializedInvoiceTransaction = z.infer<
  typeof InitializedInvoiceTransactionSchema
>;

export type PendingInvoiceTransaction = z.infer<
  typeof PendingInvoiceTransactionSchema
>;

export type FailedInvoiceTransaction = z.infer<
  typeof FailedInvoiceTransactionSchema
>;

export type ChargedInvoiceTransaction = z.infer<
  typeof ChargedInvoiceTransactionSchema
>;

export type RefundedInvoiceTransaction = z.infer<
  typeof RefundedInvoiceTransactionSchema
>;

export type AuthorizedInvoiceTransaction = z.infer<
  typeof AuthorizedInvoiceTransactionSchema
>;

export type CapturedInvoiceTransaction = z.infer<
  typeof CapturedInvoiceTransactionSchema
>;

export type CreditedInvoiceTransaction = z.infer<
  typeof CreditedInvoiceTransactionSchema
>;

export type CompletedInvoiceTransaction =
  | ChargedInvoiceTransaction
  | RefundedInvoiceTransaction
  | AuthorizedInvoiceTransaction
  | CapturedInvoiceTransaction
  | CreditedInvoiceTransaction;

export type InvoiceTransaction = z.infer<typeof InvoiceTransactionSchema>;

type InvoiceTransactionByStatus = {
  [InvoiceTransactionStatus.Initialized]: InitializedInvoiceTransaction;
  [InvoiceTransactionStatus.Pending]: PendingInvoiceTransaction;
  [InvoiceTransactionStatus.Failed]: FailedInvoiceTransaction;
  [InvoiceTransactionStatus.Authorized]: AuthorizedInvoiceTransaction;
  [InvoiceTransactionStatus.Captured]: CapturedInvoiceTransaction;
  [InvoiceTransactionStatus.Charged]: ChargedInvoiceTransaction;
  [InvoiceTransactionStatus.Refunded]: RefundedInvoiceTransaction;
  [InvoiceTransactionStatus.Credited]: CreditedInvoiceTransaction;
};

/**
 * This mapping helps functions connect the status of an invoice transaction to its expected data
 */
type InvoiceTransactionStatusToData<T extends InvoiceTransactionStatus> =
  InvoiceTransactionByStatus[T];

export type InvoiceTransactionOfStatus<T extends InvoiceTransactionStatus> =
  InvoiceTransactionModel & {
    modelData: InvoiceTransactionStatusToData<T>;
  };

/**
 * This is a typeguard that, in conjunction with the map above, helps narrow the type of functions
 * that return an invoice transaction of a particular status
 */
export function isInvoiceTransactionOfStatus<
  T extends InvoiceTransactionStatus
>(
  model: InvoiceTransactionModel,
  status: T
): model is InvoiceTransactionModel & {
  modelData: InvoiceTransactionStatusToData<T>;
} {
  return model.modelData.status === status;
}

/* eslint-disable default-case */
/**
 * We no longer use both type and status as a discriminator and have moved to descriptive "completed" status. This function
 * translates the invoice transaction type to the equivalent "completed" status.
 * We disable the default-case lint rule so we get a compile-time error if we add a new type and forget to add it here.
 */
export function invoiceTransactionTypeToCompletedStatus(
  type: InvoiceTransactionType
): CompletedInvoiceTransactionStatus {
  switch (type) {
    case InvoiceTransactionType.Authorize:
      return InvoiceTransactionStatus.Authorized;
    case InvoiceTransactionType.Capture:
      return InvoiceTransactionStatus.Captured;
    case InvoiceTransactionType.Refund:
      return InvoiceTransactionStatus.Refunded;
    case InvoiceTransactionType.Charge:
      return InvoiceTransactionStatus.Charged;
    case InvoiceTransactionType.Credit:
      return InvoiceTransactionStatus.Credited;
  }
}
/* eslint-enable default-case */

/**
 * We convert incoming API requests to charge and authorize into this type in the BE
 */
export type ProcessPaymentData = {
  invoice: InvoiceModel;
  gateway: PaymentGateway;
  invoiceTransaction: InvoiceTransactionModel;
  paymentData: InvoiceTransactionsCreateChargeRequest;
};

/**
 * This type should only be used from the BE as its values are inherently trusted
 */
export type InvoiceTransactionsCaptureBERequest = {
  invoice: InvoiceModel;
  authorizedInvoiceTransaction: InvoiceTransactionModel & {
    modelData: AuthorizedInvoiceTransaction;
  };
};

export type PaymentClientCaptureRequest = {
  amountInCents: number;
  invoiceId: string;
  gatewayTransactionId: string;
};

export type InvoiceTransactionsCreateSessionRequest = {
  invoiceId: string;
  sessionType: InvoiceTransactionType;
};
