import { OrderModel } from 'lib/model/objects/orderModel';
import { NewspaperOrderModel } from 'lib/model/objects/newspaperOrderModel';
import { OrderDetailModel } from 'lib/model/objects/orderDetailModel';
import { useAppSelector } from 'redux/hooks';
import { selectAvailableOrganizations, selectIsPublisher } from 'redux/auth';
import DetailLayout from 'layouts/DetailLayout';
import { ObituaryDetail } from './ObituaryDetail';
import { ClassifiedDetail } from './ClassifiedDetail';
import OrderDetailDrawerTabs from './OrderDetailDrawerTabs';

type AdDetailProps = {
  order: OrderModel;
  orderDetail: OrderDetailModel;
  newspaperOrders: NewspaperOrderModel[];
  anonymousUserAuthInfo: { authEmail: string; accessCode: string } | undefined;
};

export default function AdDetailContents({
  order,
  orderDetail,
  newspaperOrders,
  anonymousUserAuthInfo
}: AdDetailProps) {
  const availablePublishers = useAppSelector(selectAvailableOrganizations);
  const isPublisher = useAppSelector(selectIsPublisher);
  const filteredOrders = newspaperOrders.filter(
    newspaperOrder =>
      !isPublisher ||
      availablePublishers.find(
        availablePublisher =>
          availablePublisher.id === newspaperOrder.modelData.newspaper.id
      )
  );
  return (
    <DetailLayout drawer={<OrderDetailDrawerTabs order={order} />}>
      {order.isObituaryOrder && (
        <ObituaryDetail
          order={order}
          orderDetail={orderDetail}
          newspaperOrders={filteredOrders}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
        />
      )}
      {order.isClassifiedOrder && (
        <ClassifiedDetail
          order={order}
          orderDetail={orderDetail}
          newspaperOrders={filteredOrders}
          anonymousUserAuthInfo={anonymousUserAuthInfo}
        />
      )}
    </DetailLayout>
  );
}
