import { Product } from 'lib/enums';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { AdRate, EOrganization, ESnapshot, exists } from 'lib/types';
import { ColumnError, NotFoundError } from 'lib/errors/ColumnErrors';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { ResponseOrError, wrapError, wrapSuccess } from 'lib/types/responses';
import { getFirebaseContext } from './firebase';

export const getRateSnapshotFromFilingTypeLabel = async (
  newspaperSnap: ESnapshot<EOrganization>,
  product: Product,
  publishingMedium: PublishingMedium,
  filingTypeLabel: string
): Promise<ResponseOrError<AdRate, ColumnError>> => {
  if (!exists(newspaperSnap)) {
    return wrapError(new NotFoundError('Newspaper not found'));
  }
  const newspaper = getModelFromSnapshot(
    OrganizationModel,
    getFirebaseContext(),
    newspaperSnap
  );
  const { response: filingTypes, error: filingTypesError } =
    await newspaper.fetchFilingTypesForProductMedium({
      product,
      publishingMedium
    });
  if (filingTypesError) {
    return wrapError(filingTypesError);
  }
  const filingType = filingTypes.find(
    type => type.modelData.label === filingTypeLabel
  );
  if (!filingType) {
    return wrapError(
      new NotFoundError(`Filing type ${filingTypeLabel} not found`)
    );
  }
  const { response: rate, error: rateError } = await filingType.getRate();
  if (rateError) {
    return wrapError(rateError);
  }
  return wrapSuccess(rate.data());
};
