import React, { useState } from 'react';
import FullScreenModal from 'components/FullScreenModal';
import { ERef, ModularSize } from 'lib/types';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { TextField } from 'lib/components/TextField';
import ToastActions from 'redux/toast';
import { getFirebaseContext } from 'utils/firebase';
import { logAndCaptureException } from 'utils';
import CurrencyTextField from 'lib/components/CurrencyTextField';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';

type ModularSizeFormProps = {
  modularSizeRef: ERef<ModularSize> | undefined;
  onClose: () => void;
  modularSize: ModularSize;
};

export function ModularSizeForm({
  modularSizeRef,
  onClose,
  modularSize
}: ModularSizeFormProps) {
  const [updatedModularSizeData, setUpdatedModularSizeData] =
    useState<ModularSize>(modularSize);

  const dispatch = useAppDispatch();

  const headerText = modularSizeRef ? 'Edit Modular Size' : 'New Modular Size';

  const saveButtonText = modularSizeRef
    ? 'Save Modular Size'
    : 'Create Modular Size';

  const onSubmit = async () => {
    try {
      if (modularSizeRef) {
        await modularSizeRef.update(updatedModularSizeData);
      } else {
        await getFirebaseContext()
          .modularSizesRef()
          .add(updatedModularSizeData);
      }

      onClose();
    } catch (e) {
      const headerText = 'Error saving modular size';

      logAndCaptureException(ColumnService.INDESIGN, e, headerText);

      dispatch(
        ToastActions.toastError({
          headerText,
          bodyText: 'Please try refreshing your page and re-submitting.'
        })
      );
    }
  };

  return (
    <FullScreenModal
      submittable={{
        buttonText: saveButtonText,
        onSubmit,
        disabled: false
      }}
      headerText={headerText}
      onClose={onClose}
      id="modular-size-form"
    >
      <CardGridLayout>
        <GridInput>
          <TextField
            id="modular-size-name"
            labelText="What would you like to call this size?"
            placeholder="ex: Half Page Horizontal"
            required
            value={updatedModularSizeData.name}
            onChange={value =>
              setUpdatedModularSizeData({
                ...updatedModularSizeData,
                name: value
              })
            }
          />
        </GridInput>
        <GridInput>
          <CurrencyTextField
            id="modular-size-price"
            labelText="What is the design fee for this size?"
            initialValue={updatedModularSizeData.designFeeInCents}
            onChange={value => {
              let newModularSizeData = updatedModularSizeData;

              if (value) {
                newModularSizeData = {
                  ...newModularSizeData,
                  designFeeInCents: value
                };
              } else {
                const { designFeeInCents, ...modularSizeWithoutFee } =
                  newModularSizeData;

                newModularSizeData = modularSizeWithoutFee;
              }

              setUpdatedModularSizeData(newModularSizeData);
            }}
            step={0.01}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="modular-size-width"
            type="number"
            labelText="What is the width (in inches) of this size?"
            min={0.01}
            required
            value={updatedModularSizeData.width.toString()}
            onChange={value =>
              setUpdatedModularSizeData({
                ...updatedModularSizeData,
                width: Number(value)
              })
            }
            step={0.01}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="modular-size-height"
            type="number"
            labelText="What is the height (in inches) of this size?"
            min={0.01}
            required
            value={updatedModularSizeData.height.toString()}
            onChange={value =>
              setUpdatedModularSizeData({
                ...updatedModularSizeData,
                height: Number(value)
              })
            }
            step={0.01}
          />
        </GridInput>
        <GridInput>
          <TextField
            id="modular-size-internal-id"
            labelText="What is the internal ID for this size?"
            value={updatedModularSizeData.internalId || ''} // empty string to avoid the uncontrolled component error from undefined val
            onChange={value =>
              setUpdatedModularSizeData({
                ...updatedModularSizeData,
                internalId: value
              })
            }
          />
        </GridInput>
      </CardGridLayout>
    </FullScreenModal>
  );
}
